import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import TextEditorItemListsApi from './TextEditorItemListsApi'
import TextEditorItemListsApiDisplay from './TextEditorItemListsApiDisplay'

let TextEditorItemListsApi = lazy(() => import('./TextEditorItemListsApi'))
TextEditorItemListsApi = suspenseComponent(TextEditorItemListsApi)

export {
  TextEditorItemListsApi,
  TextEditorItemListsApiDisplay
}
