import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import TextEditorBreadcrumbs from './TextEditorBreadcrumbs'
import TextEditorBreadcrumbsDisplay from './TextEditorBreadcrumbsDisplay'

let TextEditorBreadcrumbs = lazy(() => import('./TextEditorBreadcrumbs'))
TextEditorBreadcrumbs = suspenseComponent(TextEditorBreadcrumbs)

export {
  TextEditorBreadcrumbs,
  TextEditorBreadcrumbsDisplay
}
