import React, { Component } from 'react'
import { connect } from 'react-redux'

// css
import cssModal from '../css/modal.css'
import cssConfirm from './css/confirmAlert.css'

// Components Global
import BtnSubmit from '../../../components/button/BtnSubmit'

// redux actions
import { closeConfirm } from '../../../redux/App/AppActions'

class ConfirmAlert extends Component {
  constructor(props) {
    super(props)
  }

  componentWillUnmount() {
    this.modalClose()
  }

  modalClose = () => {
    setTimeout(() => {
      this.props.dispatch(closeConfirm(this.props.id))
    }, 0)
  }

  handleConfirm = () => {
    this.props.confirm.params.onConfirm(this.props.id, this.props.confirm.params.updateId)
  }

  render() {
    const handleStay = (e) => e.stopPropagation()
    const confirm = this.props.confirm
    let style

    // new
    if (confirm.params.type === 'error' || confirm.params.type === 'delete' || confirm.params.type === 'trash') {
      style = {
        title: confirm.params.title,
        message: confirm.params.message,
        txtColor: 'red-text text-accent-4',
        icon: confirm.params.icon,
        btnSubmit: {
          bgColor: 'red accent-4',
          icon: confirm.params.confirmIcon || confirm.params.icon,
          text: confirm.params.confirmLabel
        },
        btnCancel: {
          text: confirm.params.cancelLabel
        }
      }

    } else if (confirm.params.type === 'warning' || confirm.params.type === 'close') {
      style = {
        title: confirm.params.title,
        message: confirm.params.message,
        txtColor: 'orange-text',
        icon: confirm.params.icon,
        btnSubmit: {
          bgColor: 'orange',
          icon: confirm.params.confirmIcon || confirm.params.icon,
          text: confirm.params.confirmLabel
        },
        btnCancel: {
          text: confirm.params.cancelLabel
        }
      }

    } else if (confirm.params.type === 'success' || confirm.params.type === 'open' || confirm.params.type === 'restore') {
      style = {
        title: confirm.params.title,
        message: confirm.params.message,
        txtColor: 'color-success',
        icon: confirm.params.icon,
        btnSubmit: {
          bgColor: 'bg-success',
          icon: confirm.params.confirmIcon || confirm.params.icon,
          text: confirm.params.confirmLabel
        },
        btnCancel: {
          text: confirm.params.cancelLabel
        }
      }
    }

    return (
      <div>
        { confirm &&
          <div id="confirmAlert" className="z-depth-1" className={cssModal.block} style={{ zIndex: '999999' }} onClick={this.modalClose}>
            <div className={`${cssConfirm.centered} border-radius-5 pd-20`} onClick={handleStay}>
              <div>
                <button onClick={this.modalClose} className={`height-45 line-height-45 width-45 btn btn-white pst-absolute right-10 top-10 border-radius-circle`}>
                  <i className="material-icons black-text">close</i>
                </button>

                <table className="mg-top-30">
                  <tbody>
                    <tr>
                      <td className="width-70 pd-0">
                        <i className={`material-icons font-4-0 ${style.txtColor}`}>{style.icon}</i>
                      </td>
                      <td className="pd-0">
                        <span className="font-1-2 color-000 ">
                          {style.title}
                        </span>
                        <p
                          dangerouslySetInnerHTML={{ __html: style.message }}
                          className="mg-top-5 font-0-9"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="pd-top-15 text-right">
                <a onClick={this.modalClose} className="btn btn-white pd-left-15 pd-right-15 height-45 line-height-45 font-0-9">
                  {style.btnCancel.text}
                </a>

                <BtnSubmit
                  onClick={this.handleConfirm}
                  icon={style.btnSubmit.icon}
                  iconAlign="left"
                  text={style.btnSubmit.text}
                  className={`mg-left-15 waves-light btn height-45 line-height-45 font-0-9 pd-left-15 pd-right-15 ${style.btnSubmit.bgColor}`}
                  loadingId={this.props.id}
                />

              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default connect()(ConfirmAlert)
