import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Global
import Icon from '../../../Icon'

// Components Text Editor
import IconsListsModal from '../EditorIcon/components/IconsListsModal'


// Redux Actions
import { openModal } from '../../../../redux/App/AppActions'


class IconManage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.getValue()
    }
  }

  getValue = () => {
    const { value } = this.props
    const icon = value && JSON.parse(value)

    return {
      type: icon && icon.type || '',
      name: icon && icon.name || ''
    }
  }

  handleEditIcon = () => {
    const { id, index } = this.props
    const iconModalId = `icons-${index}-${id}`

    this.props.dispatch(openModal(iconModalId))
  }

  handleResetIcon = () => {
    this.handleSelectIcon({
      type: '',
      name: ''
    })
  }

  handleSelectIcon = (icon) => {
    setTimeout(() => {
      this.setState({ ...icon }, () => {
        this.handleOnChange()
      })
    }, 0)
  }

  handleOnChange = () => {
    if (this.props.onChange) {
      const { type, name } = this.state
      const icon = JSON.stringify({
        type,
        name
      })

      this.props.onChange(icon, this.props.index)
    }
  }

  render() {
    const { type, name } = this.state
    const iconShow = type && name

    const { id, index } = this.props
    const iconModalId = `icons-${index}-${id}`

    return (
      <div>
        <IconsListsModal
          id={iconModalId}
          getIcon={this.handleSelectIcon}
          selectLoadingId="select-icon-loading"
        />

        <div className="border-eee pd-5 border-radius-3">
          <table>
            <tbody>
              <tr>
                <td className="pd-0">
                  { iconShow ?
                    <Icon type={type} className="left i-middle font-2-0">
                      { name }
                    </Icon>
                  :
                    <div className="color-error font-0-8 pd-left-10">ไม่มีไอคอน</div>
                  }
                </td>
                <td className="pd-0 text-right">

                  { iconShow &&
                    <a
                      onClick={this.handleResetIcon}
                      className="btn-shadow-none  color-000 font-0-8 btn btn-white btn-xs mg-right-5"
                      style={{ userSelect: 'none' }}>

                      ลบไอคอน
                    </a>
                  }

                  <a
                    onClick={this.handleEditIcon}
                    className={`btn-shadow-none btn btn-xs color-fff font-0-8`}
                    style={{ userSelect: 'none' }}>

                    {iconShow ? 'แก้ไข' : 'เพิ่ม'}
                  </a>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}


export default connect()(IconManage)
