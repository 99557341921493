
export const generateTypeName = (NAME: string[]) => {
  const ADD_ONE = 'ADD_ONE_' + NAME[1]
  const ADD_REPLACE_ONE = 'ADD_REPLACE_ONE_' + NAME[1]
  const ADDS_MANY = 'ADDS_MANY_' + NAME[0]
  const ADDS_MANY_MORE = 'ADDS_MANY_MORE_' + NAME[0]
  const UPDATE_ONE = 'UPDATE_ONE_' + NAME[1]
  const REMOVE_ONE = 'REMOVE_ONE_' + NAME[1]

  return {
    ADD_ONE,
    ADD_REPLACE_ONE,
    ADDS_MANY,
    ADDS_MANY_MORE,
    UPDATE_ONE,
    REMOVE_ONE
  }
}

export const generateTypeFunctions = (NAME: string[]) => {
  const {
    ADD_ONE,
    ADDS_MANY,
    ADDS_MANY_MORE,
    UPDATE_ONE,
    ADD_REPLACE_ONE,
    REMOVE_ONE
  } = generateTypeName(NAME)

  const add_one = ({
    data,
    params
  }: {
    data: any,
    params: any
  }) => ({
    type: ADD_ONE,
    data,
    params
  })

  const adds_many = ({
    datas,
    params
  }: {
    datas: any[],
    params: any
  }) => ({
    type: ADDS_MANY,
    datas,
    params
  })

  const adds_many_more = ({
    datas,
    params
  }: {
    datas: any[],
    params: any
  }) => ({
    type: ADDS_MANY_MORE,
    datas,
    params
  })

  const update_one = ({
    data,
    updateId,
    updateCond,
    params
  }: {
    data: any,
    updateId?: string,
    updateCond?: Function,
    params: any
  }) => ({
    type: UPDATE_ONE,
    data,
    updateId,
    updateCond,
    params
  })

  const add_replace_one = ({
    data,
    updateId,
    updateCond,
    params
  }: {
    data: any,
    updateId?: string,
    updateCond?: Function,
    params: any
  }) => ({
    type: ADD_REPLACE_ONE,
    data,
    updateId,
    updateCond,
    params
  })

  const remove_one = ({
    removeId,
    removeCond,
    params
  }: {
    removeId?: string,
    removeCond?: Function,
    params: any
  }) => ({
    type: REMOVE_ONE,
    removeId,
    removeCond,
    params
  })

  return {
    add_one,
    adds_many,
    adds_many_more,
    update_one,
    add_replace_one,
    remove_one
  }
}

