import React, { Fragment } from 'react'

import { setStyleValue } from '../../components/ElementStyles/components/js/setDivStyle'

const ContentTag = (props) => {
  let { tag, edit, type, children, style } = props

  if (type === 'heading') {
    tag = tag || 'h3'
  }

  if (type === 'descriptions') {
    tag = tag || 'span'
  }

  const name = "content"
  let className

  if (edit) {
    className = "text-edit-jsx"
  } else {
    className = "text-jsx"
  }

  return (
    <Fragment>
      { tag === 'h1' &&
        <h1 className={className}>{ children }</h1>
      }

      { tag === 'h2' &&
        <h2 className={className}>{ children }</h2>
      }

      { tag === 'h3' &&
        <h3 className={className}>{ children }</h3>
      }

      { tag === 'h4' &&
        <h4 className={className}>{ children }</h4>
      }

      { tag === 'h5' &&
        <h5 className={className}>{ children }</h5>
      }

      { tag === 'h6' &&
        <h6 className={className}>{ children }</h6>
      }

      { tag === 'span' &&
        <span className={className}>{ children }</span>
      }


      <style jsx>{`
        .text-jsx, .text-edit-jsx :global(textarea) {
          ${setStyleValue({ name, style })}
        }
        .text-jsx:hover, .text-edit-jsx :global(textarea:hover) {
          ${setStyleValue({ name: `${name}-hover`, style })}
        }
        .text-jsx:active, .text-edit-jsx :global(textarea:active) {
          ${setStyleValue({ name: `${name}-active-click`, style })}
        }

        @media (min-width: 1500px) {
          .text-jsx, .text-edit-jsx :global(textarea) {
            ${setStyleValue({ name, style, vw: true })}
          }
        }

        @media (min-width: 601px) and (max-width: 1024px) {
          .text-jsx, .text-edit-jsx :global(textarea) {
            ${setStyleValue({ device: 'tablet', name, style })}
          }
        }

        @media (min-width: 50px) and (max-width: 600px) {
          .text-jsx, .text-edit-jsx :global(textarea) {
            ${setStyleValue({ device: 'mobile', name, style })}
          }
        }

      `}</style>
    </Fragment>
  )
}

export default ContentTag
