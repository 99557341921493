import React, { Component } from 'react'

// Functions
import { addStyle, removeStyle } from '../../../functions/textEditorFunctions'

// css
import css from '../css/styleSettings.css'
import css2 from './css/textarea.css'


class TextareaStyleSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  change = (e) => {
    const { _this, styleName, fnConvertValue } = this.props
    let value = e.target.value

    if (value && fnConvertValue) {
      value = fnConvertValue(value)
    }

    if (value === '') {
      removeStyle(_this, styleName)
    } else {
      addStyle(_this, {
        style: styleName,
        value
      })
    }

    this.setState({ reload: true })
  }

  reset = () => {
    let { _this, styleName } = this.props

    if (styleName) {
      removeStyle(_this, styleName)
    }
  }

  render() {
    let { _this, styleName, label, defaultValue, placeholder } = this.props
    const value = _this.state.style[styleName]
    defaultValue = defaultValue || ''

    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td className="pd-0 font-0-8">{ label }</td>
              <td className="pd-0 font-0-7 text-right">
                <a className={css.btn} onClick={this.reset}>รีเซ็ท</a>
              </td>
            </tr>
          </tbody>
        </table>

        <div className={css.boxInput}>
          <table>
            <tbody>
              <tr>
                <td className="pd-0 font-0-8">
                  <textarea
                    style={{ textAlign: 'left' }}
                    className={`${css2.textarea}`}
                    type="text"
                    onChange={this.change}
                    placeholder={placeholder}
                    value={value || defaultValue}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default TextareaStyleSettings
