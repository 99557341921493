import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import renderRoutes from '../../../../../../routes/renderRoutes'



const ModalRoutesContainer = (props) => {
  const [open, setOpen] = useState(false)
  const margin = props.margin || 0
  const { route, location } = props
  const handleStay = (e) => e.stopPropagation()

  const closeWindowModal = (e, pathClose) => {
    setOpen(false)
    const elem = document.getElementById('site-container')

    if (elem) {
      setTimeout(() => {
        document.body.style.overflow = 'auto'
      }, 200)
    }

    setTimeout(() => {
      if (pathClose) {
        props.history.push(pathClose)
      } else {
        props.history.push(props.pathClose)
      }
    }, 500)
  }

  const handleOpen = (value) => {
    const elem = document.getElementById('site-container')

    setOpen(value)

    if (elem) {
      if (value === true) {
        document.body.style.overflow = 'hidden'
      } else if (value === false) {
        document.body.style.overflow = 'auto'
      }
    }
  }

  useEffect(() => {
    const use = location.pathname.search(props.pathUseModal)

    if (use !== -1) {
      handleOpen(true)
    } else {
      handleOpen(false)
    }
  }, [location.pathname])

  return (
    <Fragment>
      <div onClick={closeWindowModal} className="modal-jsx" style={{ visibility: open && 'visible', opacity: open && '1' }}>
        <div
          onClick={handleStay}
          className={`modal-content-jsx ${props.modalClassName}`}
          style={{ visibility: open && 'visible', opacity: open && '1' }}>

          { renderRoutes({
            routes: route.routes,
            extraProps: {
              ...props.extraProps,
              windowModal: open,
              closeWindowModal
            },
            location
          }) }
        </div>
      </div>

      <style jsx>{`
        .modal-jsx {
          position: fixed;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 3;
          transition: all 0.5s;
          opacity: 0;
          visibility: hidden;
          background: rgb(43 46 56 / 90%);
          z-index: 3;
        }

        .modal-content-jsx {
          position: absolute;
          left: ${margin}px;
          right: ${margin}px;
          top: ${open ? `${margin}px` : '100%'};
          height: ${open ? `calc(100% - ${margin*2}px)` : '100%'};
          z-index: 9999;
          transition: all 0.5s;
          opacity: 1;
          visibility: hidden;
        }

        @media (min-width: 1500px) {
          .modal-content-jsx {
            left: ${margin/15}vw;
            right: ${margin/15}vw;
            top: ${open ? `${margin/15}vw` : '100%'};
            height: ${open ? `calc(100% - ${margin*2/15}vw)` : '100%'};
          }
        }

        @media (min-width: 601px) and (max-width: 1024px) {
          .modal-content-jsx {
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
          }
        }

        @media (min-width: 50px) and (max-width: 600px) {
          .modal-content-jsx {
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
          }
        }
      `}</style>
    </Fragment>
  )
}

export default connect()(ModalRoutesContainer)
