import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import cuid from 'cuid'
import renderRoutes from 'client/routes/renderRoutes'
import App from 'core/modules/App'

const AppWebsitesStructure = (props) => {
  return (
    <Fragment>
      { props.children }
    </Fragment>
  )
}

const AppWebsitesContainer = (props) => {
  useEffect(() => {
    const users_id = localStorage.getItem('users_id')

    if (!users_id) {
      const randomUserId = cuid()
      localStorage.setItem('users_id', randomUserId)
    }
  }, [])

  return (
    <App>
      <AppWebsitesStructure {...props}>
        { renderRoutes({
          routes: props.route.routes,
          location: props.location,
        }) }
      </AppWebsitesStructure>
    </App>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps)(AppWebsitesContainer)
