// Redux Actions
import { fetchAuthUserCustomer } from '../../../../../../redux/Auth/AuthActions'
import { fetchProductOneDisplay } from '../../../../../../redux/Products/ProductsActions'
import { fetchArticleDisplay } from '../../../../../../redux/Articles/ArticlesActions'
import { fetchFolderDisplay, fetchFolderDisplayById } from '../../../../../../redux/Folders/FoldersActions'
import { fetchTagDisplay, fetchTagDisplayById } from '../../../../../../redux/Tags/TagsActions'

// Values
import { getProductParams, productValue } from '../../../../../../redux/Products/ProductsValues'



export const checkAuthProfileRedirect = (_this, { page, templateData }) => {
  const { match } = _this.props

  if (page) {
    if (page.groupPageType === 'profile' || match.path === '/product-manage/:products_id') {
      const auth = _this.props.auth || 'unauthorized'

      if (auth.user === 'unauthorized' || !auth.user || (auth.user && !auth.user.customer)) {
        _this.props.history.push(`/`)

      } else {
        if (templateData) {
          if (match.path === '/product-manage/:products_id') {
            if (auth.user._id && templateData.users_id) {
              if (auth.user._id !== templateData.users_id) {
                _this.props.history.push(`/`)
              }
            } else {
              _this.props.history.push(`/`)
            }
          }
        }
      }
    }
  }
}

export const getContentGroupPage = (_this) => {
  const { params, match, singlePageData } = _this.props
  const { content_group_page_id } = match.params

  let contentGroupPage

  if (params && params.contentGroupPage && content_group_page_id && singlePageData) {
    contentGroupPage = singlePageData.contentGroupPages.filter(contentGroupPage => contentGroupPage.path === content_group_page_id)[0]
  }

  return contentGroupPage
}

export const getPage = (_this) => {
  const { pages, match } = _this.props
  const { path_1 } = match.params

  let page

  if (path_1) {
    page = pages.filter(page => page.path === path_1)[0]
  }

  return page
}

export const useTemplate = (_this) => {
  const { pages, params, product, customer, article, folder, tag, singlePageData, singlePageType, order, match, location } = _this.props
  const { path_1, pageType, products_id, articles_id, single_page_products_id, folders_path, tags_path } = match.params
  const pageIndex = pages.filter(page => page.groupPageType === 'main' && page.path === 'index')[0]
  const pageProfileIndex = pages.filter(page => page.groupPageType === 'profile' && page.path === 'profile-index')[0]

  let pageTemplate

  // page narmal
  if (pageType) {
    if (pageType) {
      pageTemplate = getPageTemplate(_this, { data: product, templateType: pageType })
    }

  } else {
    if (path_1) {
      if (match.path === '/profile/:path_1?') {
        pageTemplate = {
          page: pages.filter(page => page.groupPageType === 'profile' && page.path === path_1)[0],
          template: false,
          templateData: undefined
        }
      } else {
        const page = pages.filter(page => page.groupPageType === 'main' && page.path === path_1)[0]
        let templateData

        if (page && page.fetchType && page.fetchId) {
          if (page.fetchType === 'product-details') {
            const params = getProductParams(products_id)
            const items_id = params.products_items_id
            const product = _this.props.products.filter(product => product._id === page.fetchId)[0]
            templateData = productValue({ product, customer, items_id })
          }
        }

        pageTemplate = {
          page,
          template: false,
          templateData
        }
      }

    // page products
    } else if (products_id) {
      let templateType = 'product-details'

      if (match.path === '/product-manage/:products_id') {
        templateType = 'product-manage'
      }

      pageTemplate = getPageTemplate(_this, { data: product, templateType })

    // article
    } else if (articles_id) {
      pageTemplate = getPageTemplate(_this, { data: article, templateType: 'article-details'})

    // page folder
    } else if (folders_path) {
      let templateType = 'product-folder-details'

      if (match.path === '/article-categories/:folders_path') {
        templateType = 'article-folder-details'
      }

      pageTemplate = getPageTemplate(_this, { data: folder, templateType })

    // page tags
    } else if (tags_path) {
      let templateType = 'product-tag-details'

      if (match.path === '/article-tags/:tags_path') {
        templateType = 'article-tag-details'
      }

      pageTemplate = getPageTemplate(_this, { data: tag, templateType })

    // single page
    } else if (single_page_products_id) {
      pageTemplate = {
        page: singlePageData,
        template: false,
        templateData: singlePageData
      }

    // page index
    } else {
      if (match.url === '/profile' || match.url === '/profile/') {
        pageTemplate = {
          page: pageProfileIndex,
          template: false,
          templateData: undefined
        }
      } else {
        pageTemplate = {
          page: pageIndex,
          template: false,
          templateData: undefined
        }
      }
    }
  }

  return pageTemplate
}

export const getPageTemplate = (_this, { data, templateType }) => {
  const { pages } = _this.props

  let page

  if (data) {
    if (data.pages_id) {
      page = pages.filter(page => page._id === data.pages_id && page.status === 'open' && page.type === 'template' && page.templateType === templateType)[0]
    } else {
      page = pages.filter(page => page.status === 'open' && page.type === 'template' && page.templateType === templateType)[0]
    }
  }

  return {
    page,
    template: true,
    templateData: data
  }
}

export const fetchProduct = (_this, products_id) => {
  const params = getProductParams(products_id)
  const { dbProductsNo } = _this.props.match.params
  const dbNo = dbProductsNo || 'not-use-db'

  if (params.products_id && params.products_id !== 'm') {
    _this.props.dispatch(fetchProductOneDisplay(dbNo, params.products_id, {
      body: {
        where: `status=open`,
      }
    })).then(res => {
      if (res.success) {
        const product = res.data
        const { folders_id } = product

        fetchFolderById(_this, folders_id)
      }
    })
  }
}

export const fetchArticle = (_this, articles_id) => {
  _this.props.dispatch(fetchArticleDisplay(articles_id, {
    body: {
      where: `status=open`,
    }
  })).then(res => {
    if (res.success) {
      const article = res.data
      const { folders_id, tags } = article
      const tags_id = tags && tags[0] && tags[0].tags_id

      if (folders_id) {
        fetchFolderById(_this, folders_id)
      }

      if (tags_id) {
        fetchTagById(_this, tags_id)
      }
    }
  })
}

export const fetchAuthUser = (_this) => {
  const { site, auth } = _this.props

  if (auth && auth.user && auth.user.customer) {
    _this.props.dispatch(fetchAuthUserCustomer(site.businesses_id, {
      body: {
        where: `status=open`,
      }
    }))
  }
}

export const fetchFolder = (_this, folders_path) => {
  const { host, match } = _this.props

  let type = "product"

  if (match.path === '/article-categories/:folders_path') {
    type = "article"
  }

  _this.props.dispatch(fetchFolderDisplay(folders_path, {
    body: {
      ...host,
      where: `type=${type}`,
    }
  }))
}

export const fetchTag = (_this, tags_path) => {
  const { host, match } = _this.props

  let type = "product"

  if (match.path === '/article-tags/:tags_path') {
    type = "article"
  }

  _this.props.dispatch(fetchTagDisplay(tags_path, {
    body: {
      ...host,
      where: `type=${type}`,
    }
  }))
}

export const fetchFolderById = (_this, folders_id) => {
  _this.props.dispatch(fetchFolderDisplayById(folders_id, {
    body: {
      where: `status=open`,
    }
  }))
}



export const fetchTagById = (_this, tags_id) => {
  _this.props.dispatch(fetchTagDisplayById(tags_id, {
    body: {
      where: `status=open`,
    }
  }))
}



export const setHeader = (_this) => {
  const { page } = useTemplate(_this)

  let heightHeader

  if (page && !page.headerDefaultShow && page.headerFixedShow) {
    const navbarFixed = document.getElementById("navbar-fixed")

    if (navbarFixed && navbarFixed.offsetHeight) {
      heightHeader = navbarFixed.offsetHeight + 'px'
    }
  }

  _this.setState({ heightHeader })
}


export const handleScroll = (_this) => {
  const header = document.getElementById("page-header-display")
  const headerFixed = document.getElementById("page-header-fixed-display")
  const sticky = header && header.offsetHeight
  const contentGroupPage = getContentGroupPage(_this)
  let { page } = useTemplate(_this)

  if (contentGroupPage && contentGroupPage.headerMainShow === false) {
    page = contentGroupPage
  }

  if (page && page.headerFixedShow) {
    if (headerFixed) {
      headerFixed.style.visibility = "visible"
      headerFixed.style.opacity = "1"
    }
  }

  if (page && page.headerFixedShow) {
    if (window.pageYOffset > sticky) {
      if (header) header.style.visibility = "hidden"

      if (page && page.headerDefaultShow) {
        if (headerFixed) {
          headerFixed.style.visibility = "visible"
          headerFixed.style.opacity = "1"
        }
      }

    } else {
      if (header) header.style.visibility = "visible"

      if (page && page.headerDefaultShow) {
        if (headerFixed) {
          headerFixed.style.visibility = "hidden"
          headerFixed.style.opacity = "0"
        }
      }
    }
  }
}
