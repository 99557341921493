import React from 'react'

// Components Global
import ColorPicker from '../../../../ColorPicker'

// Functions
import { addStyle, removeStyle } from '../../../functions/textEditorFunctions'

// css
import cssgl from '../css/styleSettings.css'


const ColorSetting = (props) => {
  const { _this, label, styleName, width, left, right } = props
  const { style } = _this.state
  const color = style && style[styleName]

  const colorChange = (color) => {
    const tool = {
      style: styleName || 'color',
      value: color,
    }

    addStyle(_this, tool)
  }

  const resetColor = () => {
    removeStyle(_this, styleName || 'color')
  }

  let styleDropdown

  if (right) {
    styleDropdown ={ top: '35px', left: undefined, right: '0' }
  } else {
    styleDropdown ={ top: '35px', left: 0 }
  }

  return (
    <div className={cssgl.section}>
      <table>
        <tbody>
          <tr>
            <td className="pd-0 font-0-7">{label}</td>
            <td className="pd-0 font-0-7 text-right">
              <a className={cssgl.btn} onClick={resetColor}>ค่าเดิม</a>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="mg-top-5">
        <ColorPicker
          hiddenColorAuto
          label={label}
          color={color}
          onChange={colorChange}
          defaultValue={props.defaultValue}
          style={{ width: width || '100%' }}
          styleDropdown={styleDropdown}
          left={left}
          right={right}
        />
      </div>
    </div>
  )
}

export default ColorSetting
