import React, { Fragment } from 'react'

// Compoents Global
import TextInput from '../../../../Inputs/TextInput'
import BtnSubmit from '../../../../button/BtnSubmit'

// Components Local
import {
  BoxElement,
  ButtonSubmit
} from './components/Cart'


const FormLogin = (props) => {
  const { display, modeManage } = props

  return (
    <Fragment>
      <BoxElement name="box-email" title="กล่องอินพุดอีเมล" {...props}>
        <TextInput
          label="อีเมล"
          id="email"
          active
          placeholder="อีเมล"
          inputType="email"
          type="email"
          required="true"
          value={modeManage ? 'demo@addner.com' : ''}
          notSave={modeManage}
          msgError="รูปแบบอีเมลไม่ถูกต้อง"
        />
      </BoxElement>

      <BoxElement name="box-password" title="กล่องอินพุดรหัสผ่าน" {...props}>
        <TextInput
          label="รหัสผ่าน"
          id="password"
          placeholder="รหัสผ่าน"
          inputType="password"
          type="password"
          value={modeManage ? '123456' : ''}
          notSave={modeManage}
          required="true"
        />
      </BoxElement>

      <div className="text-right">
        <ButtonSubmit
          {...props}
          name="btn-submit"
          icon="lock_open"
          text="เข้าสู่ระบบ"
          className="btn btn-shadow-none"
        />
      </div>
    </Fragment>
  )
}

export default FormLogin
