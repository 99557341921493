import React, { Component } from 'react'

// Functions
import { addStyle, removeStyle } from '../../../functions/textEditorFunctions'

// css
import css from '../css/styleSettings.css'


class WidthSetting extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.getValue()
    }
  }

  getValue = () => {
    const { _this } = this.props
    const { style } = _this.state
    const styleName = this.props.styleName || 'width'

    let value = style[styleName]

    if (value === 'auto') {
      return {
        value: 'auto',
        unit: 'px',
        auto: true
      }
    } else {
      if (value) {
        const valueInt = parseFloat(value)
        const unit = value.replace(valueInt, '')

        return {
          value: valueInt,
          unit: unit || 'px',
          auto: false
        }
      } else {
        return {
          value: '',
          unit: 'px',
          auto: false
        }
      }
    }
  }

  handleValueChange = (e) => {
    let value = e.target.value
    value = value || 0

    this.setState({ value }, () => {
      this.addValue()
    })
  }

  addValue = () => {
    let { unit, value, auto } = this.state
    value = parseFloat(value)

    let valuePx, valueRem, valueVw

    if (auto) {
      valuePx = `auto`
      valueRem = `auto`
      valueVw = `auto`
    } else {
      if (unit === 'px') {
        valuePx = `${value}px`
        valueRem = `${value/15}rem`
        valueVw = `${value/15}vw`
      } else {
        valuePx = `${value}${unit}`
        valueRem = valuePx
        valueVw = valuePx
      }
    }

    let { _this, styleName } = this.props
    styleName = styleName || 'width'

    addStyle(_this, {
      styleMultiple: [
        { style: styleName, value: valuePx },
        { style: `${styleName}-rem`, value: valueRem },
        { style: `${styleName}-vw`, value: valueVw }
      ]
    })
  }

  handleUnitChnage = (e) => {
    const unit = e.target.value

    this.setState({ unit }, () => {
      this.addValue()
    })
  }

  reset = () => {
    let { _this, styleName } = this.props
    styleName = styleName || 'width'

    if (styleName) {
      this.setState({ value: '', unit: 'px', auto: false }, () => {
        removeStyle(_this, null, {
          styleMultiple: [
            { style: styleName },
            { style: `${styleName}-rem`},
            { style: `${styleName}-vw` }
          ]
        })
      })
    }
  }

  setAuto = () => {
    this.setState({ auto: !this.state.auto }, () => {
      if (this.state.auto) {
        this.setState({ value: 'auto' }, () => {
          this.addValue()
        })
      } else {
        this.reset()
      }
    })
  }

  render() {
    const { label } = this.props
    const { value, unit, auto } = this.state

    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td className="pd-0 font-0-7">{ label || 'ความกว้าง (width)' }</td>
              <td className="pd-0 font-0-7 text-right">
                <a className={css.btn} onClick={this.reset}>รีเซ็ท</a>
                <a className={`${css.btn} ${auto && css.active}`} onClick={this.setAuto}>อัตโนมัติ</a>
              </td>
            </tr>
          </tbody>
        </table>

        <div>
          <div className={css.boxInput}>
            <table>
              <tbody>
                <tr>
                  <td className="pd-0 font-0-8">
                    { !auto ?
                      <input
                        style={{ textAlign: 'left' }}
                        className={`${css.input} ${css.inputBorderNone} ${css.inputFirst}`}
                        type="text"
                        onChange={this.handleValueChange}
                        value={value || ''}
                      />
                    :
                      <input
                        disabled
                        style={{ textAlign: 'left' }}
                        className={`${css.inputDisabled} ${css.inputBorderNone} ${css.inputFirst}`}
                        type="text"
                        value={value || ''}
                      />
                    }
                  </td>
                  <td width="80px" className="pd-0 font-0-8 border-left-eee center">
                    { !auto ?
                      <select
                        className={`browser-default ${css.input} ${css.select} ${css.inputBorderNone}`}
                        onChange={this.handleUnitChnage}
                        value={unit}>

                        <option value="px">px</option>
                        <option value="%">%</option>
                      </select>
                    :
                      <input
                        disabled
                        style={{ textAlign: 'left' }}
                        className={`${css.inputDisabled} ${css.inputBorderNone} ${css.inputFirst}`}
                        type="text"
                        value={value || ''}
                      />
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default WidthSetting
