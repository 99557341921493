import React from 'react'


export function TextMiddle(props) {
  const style = {
    height: props.height,
    width: props.width,
    display: 'table',
    margin: (props.center ? '0 auto' : '')
  }
  const style2 = {
    height: props.height,
    display: 'table-cell',
    verticalAlign: 'middle',
    textAlign: props.align
  }

  let joinStyle2 = Object.assign(style2, props.style);

  return (
    <div style={style}>
      <div onClick={props.onClick} className={props.className} style={joinStyle2}>
        {props.children}
      </div>
    </div>
  );
}

export function DivMiddle(props) {
  const style = {
    height: props.height,
    width: props.width,
    display: 'table',
    margin: (props.center ? '0 auto' : '')
  }
  const style2 = {
    height: props.height,
    display: 'table-cell',
    verticalAlign: 'middle',
    textAlign: 'center'
  }

  let joinStyle2 = Object.assign(style2, props.style);

  return (
    <div style={{ height: '100%' }} >
      <div style={style}>
        <div onClick={props.onClick} className={props.className} style={joinStyle2}>
          {props.children}
        </div>
      </div>
    </div>
  )
}
