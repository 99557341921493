import React from 'react'

// css
import css from './css/boxImage.css'

const BoxImage = (props) => {
  const { children, paths, deleteImage, notActiveDelete, className, hover, active, style } = props

  if (paths) {
    return (
      <div className={`${css.boxImage} ${hover && css.hover} ${active && css.active} ${className}`} style={style}>
        { children }

        { !notActiveDelete &&
          <a className={css.deleteImage} onClick={deleteImage}>
            <i className="material-icons">close</i>
          </a>
        }
      </div>
    )
  } else {
    return (
      <div>{ children }</div>
    )
  }
}

export default BoxImage

