import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Global
import Switch from '../../../Inputs/Switch'

// Components Local
import EditorContainer from '../EditorContainer'
import BoxTools from '../BoxTools'
import BtnTools from '../BtnTools'
import BoxSelect from '../BoxSelect'
import { EditorText } from '../EditorText'

// Components Local
import IconsListsModal from './components/IconsListsModal'
import IconSettings from './components/IconSettings'
import StyleSettings from './components/StyleSettings'
import Icon from './components/Icon'

// Functions
import { generateClassToString } from '../../functions/textEditorFunctions'

// Actions
import { openModal } from '../../../../redux/App/AppActions'

// Functions
import { fontSizeStyleList } from '../../functions/textEditorValues'

// css
import css from './css/editorIcon.css'


class EditorIcon extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.getValue()
    }
  }

  componentDidMount() {
    this.mounted = true
    if (this.props.onRef) this.props.onRef(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cuid !== this.props.cuid) {
      if (this.props.cuid === this.props.content.cuid) {
        this.focus()
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false
    if (this.props.onRef) this.props.onRef(null)
  }

  getValue = () => {
    const { settingsDefault } = this.props
    const icon = this.props.icon && JSON.parse(this.props.icon)
    const iconDefault = {
      name: '',
      type: ''
    }

    return {
      icon: icon && icon.icon ? JSON.parse(icon.icon) : iconDefault,
      style: icon && icon.style ? JSON.parse(icon.style) : {},
      class: icon && icon.class ? JSON.parse(icon.class) : [],
      layout: icon && icon.layout,

      title: icon && icon.title,
      caption: icon && icon.caption,

      titleShow: icon && icon.titleShow !== undefined ? icon.titleShow : settingsDefault && settingsDefault.title,
      captionShow: icon && icon.captionShow !== undefined ? icon.captionShow : settingsDefault && settingsDefault.caption
    }
  }

  setValue = () => {
    this.mounted && this.setState({
      ...this.getValue()
    })
  }

  handleOpenTools = (value) => {
    this.mounted && this.setState({ openTools: value })
  }

  focus = () => {
    setTimeout(() => this.handleOpenTools(true), 0)
  }

  handleSubmit = ({ timeout }) => {
    this.props.onChange({
      index: this.props.index,
      value: {
        class: JSON.stringify(this.state.class),
        style: JSON.stringify(this.state.style),
        icon: JSON.stringify(this.state.icon),
        layout: this.state.layout,

        title: this.state.title,
        caption: this.state.caption,

        titleShow: this.state.titleShow,
        captionShow: this.state.captionShow
      },
      timeout
    })
  }

  handleGetIcon = (icon) => {
    this.mounted && this.setState({ icon }, () => {
      this.handleSubmit({ timeout: true })
    })
  }

  handleDeleteIcon = () => {
    const icon = {
      name: '',
      type: ''
    }

    this.mounted && this.setState({ icon }, () => {
      this.handleSubmit({ timeout: true })
    })
  }

  handleTitleChange = (title) => {
    this.setState({ title }, () => {
      this.handleSubmit({ timeout: true })
    })
  }

  handleCaptionChange = (caption) => {
    this.setState({ caption }, () => {
      this.handleSubmit({ timeout: true })
    })
  }

  handleIconSetting = (value, name) => {
    this.setState({ [name]: value }, () => {
      this.handleSubmit({ timeout: true })
    })
  }

  handleSetLayout = (layout) => {
    this.setState({ layout }, () => {
      this.handleSubmit({ timeout: true })
    })
  }

  openSidebar = (activeId) => {
    this.setState({ activeId: activeId })

    if (this.sidebar) {
      return this.sidebar.open()
    }
  }

  render() {
    const style = typeof this.state.style === 'string' ? {} : this.state.style
    const { openTools, activeId, titleShow, icon, layout, captionShow } = this.state
    const {
      lang,
      title,
      notDelete,
      tools,
      containerStyle,
      boxIconStyle,
      iconStyle
    } = this.props

    const className = this.state.class || []
    const iconCircle = className.filter(list => list.name === 'icon-circle')[0]

    let bgIcon, iconColor

    if (iconCircle) {
      bgIcon = style.color || '#000'
      iconColor = "#fff"
    } else {
      iconColor = style.color || '#000'
    }

    const inputTitle = (
      <div>
        { titleShow &&
          <EditorText
            type="title"
            lang={lang}
            value={this.state.title}
            onChange={this.handleTitleChange}
            openTools={openTools}
            tools={{
              titleFontSize: true
            }}
          />
        }
      </div>
    )

    const inputCaption = (
      <div>
        { captionShow &&
          <EditorText
            type="caption"
            lang={lang}
            value={this.state.caption}
            onChange={this.handleCaptionChange}
            openTools={openTools}
            tools={{
              fontSize: true
            }}
          />
        }
      </div>
    )

    const inputIcon = (
      <div
        className={`${generateClassToString(className)}`}
        style={{
          ...boxIconStyle,
          background: bgIcon
        }}>

        <i
          className="material-icons i-middle"
          style={{
            ...iconStyle,
            color: iconColor
          }}>

          { icon.name }
        </i>
      </div>
    )

    return (
      <EditorContainer
        style={containerStyle}
        openTools={this.state.openTools}
        onClick={this.handleOpenTools}>

        { !this.props.image &&
          <div className="color-333" >
            <IconsListsModal
              id={`${this.props.id}-modal-icons`}
              getIcon={this.handleGetIcon}
              focus={this.focus}
            />
          </div>
        }

        <BoxTools openTools={this.state.openTools} style={{ minWidth: '280px' }}>
          <BtnTools
            notDelete={notDelete}
            title={title}
            _this={this}
            tools={[
              {
                groups: 'font-size',
                type: 'style',
                sizeResponsive: true,
                dropdown: true,
                dropdownSize: 'xs',
                lists: [
                  {
                    label: `ขนาดไอคอน`,
                    tooltip: 'ขนาดไอคอน',
                    width: 80,
                    style: { top:' 32px', minWidth: '110px' },
                    items: [
                      { label: 'ขนาดไอคอน', show: true, title: true },
                      { divider: true, show: true },
                      { label: 'ค่าเดิม', style: 'icon-font-size', value: '', show: true, remove: true },
                      ...fontSizeStyleList('icon-font-size')
                    ]
                  }
                ],
                show: true
              },
              {
                groups: 'edit',
                type: 'modal',
                lists: [
                  { icon: 'edit', label: 'แก้ไข', modalId: `${this.props.id}-modal-icons`},
                ],
                show: icon.name
              },
              {
                lists: [
                  { icon: 'settings', label: 'ตั้งค่า', onClick: () => this.openSidebar('content') }
                ],
                show: icon.name && tools && tools.settingsMore
              },
              {
                lists: [
                  { icon: 'color_lens', label: 'สไตล์', onClick: () => this.openSidebar('style') }
                ],
                show: true
              },
            ]}
          />
        </BoxTools>

        <BoxSelect
          _this={this}
          title={title}
          mode="edit"
          activeId={activeId}
          openTools={openTools}
          onRef={sidebar => this.sidebar = sidebar}
          contentSettings={IconSettings}
          styleSettings={StyleSettings}>

          <div>
            { icon.name ?
              <div>
                { layout === 'right' &&
                  <table>
                    <tbody>
                      <tr>
                        <td className="pd-0 width-5 pd-left-5 pd-right-10">
                          <Icon icon={icon} style={style} />
                        </td>
                        <td className="pd-0">
                          { inputTitle }
                          { inputCaption }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                }

                { layout === 'center' &&
                  <div style={{ textAlign: 'center' }}>
                    <Icon icon={icon} style={style} />
                    { inputTitle }
                    { inputCaption }
                  </div>
                }

                { !layout &&
                  <div style={{ textAlign: 'center' }}>
                    <Icon icon={icon} style={style} />
                    { inputTitle }
                    { inputCaption }
                  </div>
                }

                { layout === 'left' &&
                  <table>
                    <tbody>
                      <tr>
                        <td className="pd-0">
                          { inputTitle }
                          { inputCaption }
                        </td>
                        <td className="pd-0 width-5 pd-left-10 pd-right-5">
                          <Icon icon={icon} style={style} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                }
              </div>
            :
              <div style={{ textAlign: 'center' }}>
                <a
                  onClick={() => this.props.dispatch(openModal(`${this.props.id}-modal-icons`))}
                  className="btn btn-md btn-white btn-shadow-none mg-8 font-0-9">

                  คลิกเพื่อเพิ่มไอคอน
                </a>
              </div>
            }
          </div>
        </BoxSelect>
      </EditorContainer>
    )
  }
}

export default connect()(EditorIcon)
