import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import TextEditorWidget from './TextEditorWidget'
import TextEditorWidgetDisplay from './TextEditorWidgetDisplay'

const TextEditorWidgetLazy = lazy(() => import('./TextEditorWidget'))
const TextEditorWidget = suspenseComponent(TextEditorWidgetLazy)

export {
  TextEditorWidget,
  TextEditorWidgetDisplay
}
