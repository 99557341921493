import OrderDBRoutes from './modules/Orders/OrdersDBRoutes'
import BillsDBRoutes from './modules/ฺBills/BillsDBRoutes'

const routes = [
  { name: 'orders', routes: OrderDBRoutes },
  { name: 'bills', routes: BillsDBRoutes },
]

export default routes

