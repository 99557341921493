import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import TextEditorAddToCart from './TextEditorAddToCart'
import TextEditorAddToCartDisplay from './TextEditorAddToCartDisplay'

let TextEditorAddToCart = lazy(() => import('./TextEditorAddToCart'))
TextEditorAddToCart = suspenseComponent(TextEditorAddToCart)

export {
  TextEditorAddToCart,
  TextEditorAddToCartDisplay
}
