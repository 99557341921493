import React, { Component } from 'react'

// Functions
import { addStyle, removeStyle } from '../../../functions/textEditorFunctions'

// css
import css from '../css/styleSettings.css'


class LineHeightSetting extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.getValue()
    }
  }

  getValue = () => {
    const { _this } = this.props
    const { style } = _this.state
    const styleName = this.props.styleName || 'line-height'

    let value = style[styleName]


    if (value) {
      const valueInt = parseInt(value)
      const unit = value.replace(valueInt, '')

      return {
        value: valueInt,
        unit: unit || 'px'
      }
    } else {
      return {
        value: '',
        unit: 'px'
      }
    }
  }

  handleValueChange = (e) => {
    let value = parseInt(e.target.value)
    value = value || 0

    this.setState({ value }, () => {
      this.addValue()
    })
  }

  addValue = () => {
    const { unit, value } = this.state

    let valuePx, valueRem, valueVw

    if (unit === 'px') {
      valuePx = `${value}px`
      valueRem = `${value/15}rem`
      valueVw = `${value/15}vw`
    } else {
      valuePx = `${value}${unit}`
      valueRem = valuePx
      valueVw = valuePx
    }

    let { _this, styleName } = this.props
    styleName = styleName || 'line-height'

    addStyle(_this, {
      styleMultiple: [
        { style: styleName, value: valuePx },
        { style: `${styleName}-rem`, value: valueRem },
        { style: `${styleName}-vw`, value: valueVw }
      ]
    })
  }

  handleUnitChnage = (e) => {
    const unit = e.target.value

    this.setState({ unit }, () => {
      this.addValue()
    })
  }

  reset = () => {
    let { _this, styleName } = this.props
    styleName = styleName || 'line-height'

    if (styleName) {
      this.setState({ value: '', unit: 'px' }, () => {
        removeStyle(_this, null, {
          styleMultiple: [
            { style: styleName },
            { style: `${styleName}-rem`},
            { style: `${styleName}-vw` }
          ]
        })
      })
    }
  }

  render() {
    const { label } = this.props
    const { value, unit } = this.state

    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td className="pd-0 font-0-7">{ label || 'ความสูงของบรรทัด (line-height)' }</td>
              <td className="pd-0 font-0-7 text-right">
                <a className={css.btn} onClick={this.reset}>รีเซ็ท</a>
              </td>
            </tr>
          </tbody>
        </table>

        <div>
          <div className={css.boxInput}>
            <table>
              <tbody>
                <tr>
                  <td className="pd-0 font-0-8">
                    <input
                      style={{ textAlign: 'left' }}
                      className={`${css.input} ${css.inputBorderNone} ${css.inputFirst}`}
                      type="text"
                      onChange={this.handleValueChange}
                      value={value || ''}
                    />
                  </td>
                  <td width="80px" className="pd-0 font-0-8 border-left-eee center">
                    <select
                      className={`browser-default ${css.input} ${css.select} ${css.inputBorderNone}`}
                      onChange={this.handleUnitChnage}
                      value={unit}>

                      <option value="px">px</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default LineHeightSetting
