import React from 'react'

// Components Local
import ButtonDisplay from './components/ButtonDisplay'
import BlockStyle from '../../components/BlockStyle'


const EditorButtonDisplay = (props) => {
  let {
    lang,
    apiData,
    templateData,
    dataStore,
    button,
    mode,
    loggedIn,
    closeWindowModal,
    styleProps
  } = props

  button = button && JSON.parse(button)

  let name, link, icon, style, className, apiLink

  if (button) {
    const nameLocal = button[`name_local`]

    name = button[`name_${lang}`] || nameLocal
    link = button.link ? JSON.parse(button.link) : ''
    icon = button.icon ? JSON.parse(button.icon) : ''
    style = button.style ? JSON.parse(button.style) : {}
    className = button.class ? JSON.parse(button.class) : []
  }

  return (
    <BlockStyle {...props} style={style}>
      <ButtonDisplay
        styleProps={styleProps}
        link={link}
        style={style}
        icon={icon}
        name={name}
        mode={mode}
        loggedIn={loggedIn}
        apiLink={apiLink}
        className={className}
        apiData={apiData}
        templateData={templateData}
        dataStore={dataStore}
        closeWindowModal={closeWindowModal}
        lang={lang}
      />
    </BlockStyle>
  )
}

export default EditorButtonDisplay
