import React from 'react'

// Components Global
import { LayoutsDesign } from '../../../LayoutsDesign'
import { fetchSite, updateSite, uploadImagesSite } from '../../../../redux/Sites/SitesActions'

const SideNavbarManage = (props) => {
  const { datas } = props
  const { business, setting, site, pages, pageCurrent, } = datas

  return (
    <div style={{ padding: '20px 20px 20px 50px' }}>
      <LayoutsDesign
        {...props}
        sideNavbar
        action={{
          fetch: fetchSite,
          update: updateSite,
          uploadImage: uploadImagesSite,
          params: {
            business,
            setting,
            site,
            pages,
            pageCurrent,
            data: site,
            _id: site._id,
            imagePrefix: 'sites'
          }
        }}
      />
    </div>
  )
}

export default SideNavbarManage
