import React from 'react'

// Components Local
import DivStyle from './DivStyle'


const BadgeStyleManage = (props) => {
  let { title, name, device, styleHover, styleActive, styleActiveClick } = props
  title = title || 'ป้าย Badge'

  if (!name) {
    name = name || 'badge'

    if (device && device !== 'desktop') {
      name = `${name}-${device}`
    }
  }

  return (
    <div>
      <DivStyle
        title={`${title}`}
        icon="stars"
        hidden={{
          display: true,
          heightFull: true,
          backgroundGradient: true,
          justifyContent: true,
          opacity: true,
          iconSize: true,
          iconColor: true,
          iconMargin: true,
          lineClamp: true,
          float: true,
          textAlign: true,
          positionType: true,
          zIndex: true,
        }}
        {...props}
      />
    </div>
  )
}

export default BadgeStyleManage
