import React, { Component } from 'react'
import chroma from 'chroma-js'
import { connect } from 'react-redux'

// Components Global
import Modal from '../Modals/Modal'
import BtnLoading from '../button/BtnLoading'
import ColorPicker from '../ColorPicker'

// Utils
import { getTextColor } from '../../util/getColor'

// Actions
import { closeModal } from '../../redux/App/AppActions'

// css
import css from './css/colorGradientPicker.css'


class ColorGradientPicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      colorAmount: 3,
      direction: 'to right top',
      color1: '#D16BA5',
      color2: '#86A8E7',
      color3: '#5FFBF1'
    }
  }

  componentDidMount() {
    this.mounted = true
    this.generateGradient()
  }

  componentWillUnmount() {
    this.mounted = false
  }

  setValue = () => {
    let { data } = this.props
    data = data && JSON.parse(data)

    this.mounted && this.setState({ ...data })
  }

  generateGradient = () => {
    const { direction, colorAmount, color1, color2, color3 } = this.state

    if (colorAmount === 3) {
      const colors = chroma.scale([color1, color2, color3]).mode('lch').colors(12)

      let gradientType = 'linear-gradient'

      if (direction === 'circle') gradientType = 'radial-gradient'

      const backgroundImage = `${gradientType}(${direction}, ${colors[0]}, ${colors[1]}, ${colors[2]}, ${colors[3]}, ${colors[4]}, ${colors[5]}, ${colors[6]}, ${colors[7]}, ${colors[8]}, ${colors[9]}, ${colors[10]}, ${colors[11]})`

      this.mounted && this.setState({ backgroundImage })
    }

    if (colorAmount === 2) {
      const colors = chroma.scale([color1, color2]).mode('lch').colors(5)

      let gradientType = 'linear-gradient'

      if (direction === 'circle') gradientType = 'radial-gradient'

      const backgroundImage = `${gradientType}(${direction}, ${colors[0]}, ${colors[1]}, ${colors[2]}, ${colors[3]}, ${colors[4]})`

      this.mounted && this.setState({ backgroundImage })
    }

    if (colorAmount === 1) {
      let gradientType = 'linear-gradient'

      if (direction === 'circle') gradientType = 'radial-gradient'

      const backgroundImage = `${gradientType}(${direction}, ${color1}, ${color1})`

      this.setState({ backgroundImage })
    }
  }

  handleSelectColorAmount = (colorAmount) => {
    this.mounted && this.setState({ colorAmount }, () => {
      this.generateGradient()
    })
  }

  handleSelectDirection = (direction) => {
    this.mounted && this.setState({ direction }, () => {
      this.generateGradient()
    })
  }

  handleSelectColor1 = (color1) => {
    this.mounted && this.setState({ color1 }, () => {
      this.generateGradient()
    })
  }

  handleSelectColor2 = (color2) => {
    this.mounted && this.setState({ color2 }, () => {
      this.generateGradient()
    })
  }

  handleSelectColor3 = (color3) => {
    this.mounted && this.setState({ color3 }, () => {
      this.generateGradient()
    })
  }

  handleSubmit = () => {
    const { id } = this.props
    const backgroundImageData = JSON.stringify(this.state)

    const color = {
      ...this.state,
      backgroundImageData
    }


    setTimeout(() => {
      this.props.onChange(color)
      this.props.dispatch(closeModal(id))
    }, 100)
  }

  handleClearColor = () => {
    const { id } = this.props
    this.mounted && this.setState({
      colorAmount: 3,
      direction: 'to right top',
      color1: '#D16BA5',
      color2: '#86A8E7',
      color3: '#5FFBF1'
    })

    this.props.onChange(null)

    setTimeout(() => {
      this.props.dispatch(closeModal(id))
    }, 100)
  }

  render() {
    const { id } = this.props
    const { direction, colorAmount, color1, color2, color3, backgroundImage } = this.state
    const directions = [
      {
        icon: 'arrow_upward',
        rotate: 'rotate-45',
        value: 'to right top'
      },
      {
        icon: 'arrow_forward',
        rotate: '',
        value: 'to right'
      },
      {
        icon: 'arrow_forward',
        rotate: 'rotate-45',
        value: 'to right bottom'
      },
      {
        icon: 'arrow_downward',
        rotate: '',
        value: 'to bottom'
      },
      {
        icon: 'arrow_downward',
        rotate: 'rotate-45',
        value: 'to left bottom'
      },
      {
        icon: 'arrow_back',
        rotate: '',
        value: 'to left'
      },
      {
        icon: 'arrow_back',
        rotate: 'rotate-45',
        value: 'to left top'
      },
      {
        icon: 'arrow_upward',
        rotate: '',
        value: 'to top'
      },
      {
        icon: 'refresh',
        rotate: '',
        value: 'circle'
      }
    ]

    return (
      <Modal
        id={id}
        headerTitle="สีพื้นหลัง (Gradient)"
        headerLeft
        fnStartModal={this.setValue}
        width="500px">

        <div className={css.colorGradient}>
          <div
            className={css.boxColor}
            style={{ backgroundImage }}>

          </div>

          <div className={css.boxChoose}>
            <div className={css.boxColorChoice}>
              <div>เลือกจำนวนสี</div>
              <div onClick={() => this.handleSelectColorAmount(1)} className={`${css.choice} ${colorAmount === 1 && css.activeChoice}`}>1 สี</div>
              <div onClick={() => this.handleSelectColorAmount(2)} className={`${css.choice} ${colorAmount === 2 && css.activeChoice}`}>2 สี</div>
              <div onClick={() => this.handleSelectColorAmount(3)} className={`${css.choice} ${colorAmount === 3 && css.activeChoice}`}>3 สี</div>
            </div>

            { colorAmount !== 1 &&
              <div className={css.boxDirectionSelect}>
                <div>เลือกทิศทาง</div>

                { directions.map((drt, i) => {
                  return (
                    <div
                      key={i}
                      className={`${css.directionSelect} ${direction === drt.value && css.activeDirection}`}
                      onClick={() => this.handleSelectDirection(drt.value)}>

                      <i className={`material-icons i-middle ${drt.rotate}`}>{drt.icon}</i>
                    </div>
                  )
                }) }
              </div>
            }

            <div className={css.boxColorSelect}>
              <div>เลือกสี</div>

              { colorAmount >= 1 &&
                <ColorPicker advanced color={color1} hiddenColorAuto hiddenColorNone label="สีที่ 1" onChange={this.handleSelectColor1}>
                  <div className={css.colorSelect} style={{ background: color1, color: getTextColor(color1) }}>{ color1 }</div>
                </ColorPicker>
              }

              { colorAmount >= 2 &&
                <ColorPicker advanced color={color2} hiddenColorAuto hiddenColorNone label="สีที่ 2" onChange={this.handleSelectColor2}>
                  <div className={css.colorSelect} style={{ background: color2, color: getTextColor(color2) }}>{ color2 }</div>
                </ColorPicker>
              }

              { colorAmount >= 3 &&
                <ColorPicker advanced color={color3} hiddenColorAuto hiddenColorNone label="สีที่ 3" onChange={this.handleSelectColor3}>
                  <div className={css.colorSelect} style={{ background: color3, color: getTextColor(color3) }}>{ color3 }</div>
                </ColorPicker>
              }
            </div>

            <div className={css.boxSubmit}>
              <a onClick={this.handleClearColor} className="mg-5 mg-bottom-0 waves-effect btn btn-shadow-none btn-white color-error font-1-0 border-radius-5">
                <i className="material-icons color-error left">invert_colors_off</i> ล้างสีทั้งหมด
              </a>

              <a onClick={this.handleSubmit} className="mg-5 mg-bottom-0 waves-effect btn btn-shadow-none btn-submit font-1-0 border-radius-5">
                <i className="material-icons left">check</i> ยืนยันการเลือกสี
              </a>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default connect()(ColorGradientPicker)
