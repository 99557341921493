
export const setStyle = (name, style, event) => {
  // overflow: hidden;

  return `
    overflow: ${style[`${name}-overflow`]};
    color: ${style[`${name}-color`]}${event === 'active' ? ' !important' : ''};
    background-color: ${style[`${name}-background-color`]}${event === 'active' ? ' !important' : ''};
    margin: ${style[`${name}-margin`]};
    padding: ${style[`${name}-padding`]};
    font-weight: ${style[`${name}-font-weight`]};
    box-shadow: ${style[`${name}-box-shadow`]};
    border-style: ${style[`${name}-border-style`]}${event === 'active' ? ' !important' : ''};
    border-width: ${style[`${name}-border-width`]}${event === 'active' ? ' !important' : ''};
    border-color: ${style[`${name}-border-color`]}${event === 'active' ? ' !important' : ''};
    font-size: ${style[`${name}-font-size`]}${event === 'active' ? ' !important' : ''};
    border-radius: ${style[`${name}-border-radius`]}${event === 'active' ? ' !important' : ''};
    width: ${style[`${name}-width`]};
    height: ${style[`${name}-height`]};
    line-height: ${style[`${name}-line-height`]};
    text-align: ${style[`${name}-text-align`]};

    position: ${style[`${name}-position`]};
    top: ${style[`${name}-position-absolute-top`]};
    bottom: ${style[`${name}-position-absolute-bottom`]};
    right: ${style[`${name}-position-absolute-right`]};
    left: ${style[`${name}-position-absolute-left`]};
  `
}

export const getStyle = (name, value, important) => {
  let text = ''

  /*if (value !== 'undefined' || value !== undefined || value !== '' || value !== false) {
    if (important) {
      value = `${value} !important`
    }

    text = `${name}: ${value};`
  }*/

  /*if (!isNaN(value) && value !== undefined || value !== '') {
    if (important) {
      value = `${value} !important`
    }

    text = `${name}: ${value};`
  }*/

  if (name && value !== undefined && value !== '' && value !== false) {
    if (important) {
      value = `${value} !important`
    }

    text = `${name}: ${value};`
  }

  return text
}

/*
${(mode === 'display') ? getStyle('position', style[`${name}-position`], important) : ''}
    ${(mode === 'display') ? getStyle('top', style[`${name}-position-top${vw}`], important) : ''}
    ${(mode === 'display') ? getStyle('bottom', style[`${name}-position-bottom${vw}`], important) : ''}
    ${(mode === 'display') ? getStyle('right', style[`${name}-position-right${vw}`], important) : ''}
    ${(mode === 'display') ? getStyle('left', style[`${name}-position-left${vw}`], important) : ''}
    ${(mode === 'display') ? getStyle('z-index', style[`${name}-z-index`], important) : ''}

*/

/*${mode === 'display' ? getStyle('position', style[`${name}-position`], important) : ''}
    ${getStyle('z-index', style[`${name}-z-index`], important)}
    ${getStyle('top', style[`${name}-position-top${vw}`], important)}
    ${getStyle('bottom', style[`${name}-position-bottom${vw}`], important)}
    ${getStyle('right', style[`${name}-position-right${vw}`], important)}
    ${getStyle('left', style[`${name}-position-left${vw}`], important)}*/


const marginBelow = ({ value, mode }) => {
  if (mode && value) {
    const mg = value
    const arr = mg.split(" ")

    if (arr && arr.length > 1) {
      arr.map((v, i) => {
        if (v.search('-') !== -1) {
          arr[i] = '0';
        }
      })

      let mgNew = ''

      arr.map((v, i) => {
        if (i === 0) {
          mgNew = `${v}`
        } else {
          mgNew = `${mgNew} ${v}`
        }
      })
      return mgNew
    }
  } else {
    return value
  }
}

export const setStyleValue = ({ device, name, style, event, important, vw, mode }) =>  {
  if (vw) {
    vw = '-vw'
  } else {
    vw = ''
  }

  if (!important && event === 'active') {
    important = true
  }

  if (device === 'tablet' || device === 'mobile') {
    important = true
    name = `${name}-${device}`
  }


  let value = `
    ${getStyle('cursor', style[`${name}-cursor`], important)}
    ${getStyle('color', style[`${name}-color`], important)}
    ${getStyle('text-align', style[`${name}-text-align`], important)}
    ${getStyle('font-size', style[`${name}-font-size${vw}`], important)}
    ${getStyle('font-weight', style[`${name}-font-weight`], important)}
    ${getStyle('font-style', style[`${name}-font-style`], important)}
    ${getStyle('text-decoration', style[`${name}-text-decoration`], important)}
    ${getStyle('text-shadow', style[`${name}-text-shadow${vw}`], important)}
    ${getStyle('text-indent', style[`${name}-text-indent${vw}`], important)}
    ${getStyle('line-height', style[`${name}-line-height${vw}`], important)}
    ${getStyle('display', style[`${name}-display`], important)}

    ${(mode && style[`${name}-overflow`] === 'hidden') ? '' : getStyle('overflow', style[`${name}-overflow`], important) }

    ${getStyle('background-color', style[`${name}-background-color`], important)}


    ${getStyle('margin', marginBelow({ value: style[`${name}-margin${vw}`], mode }), important)}

    ${getStyle('padding', style[`${name}-padding${vw}`], important)}

    ${getStyle('box-shadow', style[`${name}-box-shadow${vw}`], important)}
    ${getStyle('border-style', style[`${name}-border-style`], important)}
    ${getStyle('border-width', style[`${name}-border-width${vw}`], important)}
    ${getStyle('border-color', style[`${name}-border-color`], important)}

    ${getStyle('border-radius', style[`${name}-border-radius${vw}`], important)}
    ${getStyle('width', style[`${name}-width${vw}`], important)}
    ${getStyle('max-width', style[`${name}-max-width${vw}`], important)}
    ${getStyle('height', style[`${name}-height${vw}`], important)}
    ${getStyle('min-height', style[`${name}-min-height${vw}`], important)}

    ${(!mode) ? getStyle('position', style[`${name}-position`], important) : ''}
    ${(!mode) ? getStyle('top', style[`${name}-position-top${vw}`], important) : ''}
    ${(!mode) ? getStyle('bottom', style[`${name}-position-bottom${vw}`], important) : ''}
    ${(!mode) ? getStyle('right', style[`${name}-position-right${vw}`], important) : ''}
    ${(!mode) ? getStyle('left', style[`${name}-position-left${vw}`], important) : ''}
    ${(!mode) ? getStyle('z-index', style[`${name}-z-index`], important) : ''}


    ${getStyle('background-image', style[`${name}-background-gradient`], important)}

    ${getBackgroundImage({ name, style, vw, important })}
    ${getLineClamp({ name, style, important, mode })}
  `
  //.replace(/  +/g, ' ');
  return value.replace(/\n\s*\n/g, '\n')
}

export const getBackgroundImage = ({ name, style, vw='', important }) => {
  const backgroundImage = style[`${name}-background-image`]
  important = important ? ' !important' : ''


  if (backgroundImage) {
    return `
      background-image: ${backgroundImage}${important};
      background-repeat: ${style[`${name}-background-image-repeat`]}${important};
      background-size: ${style[`${name}-background-image-size`] || 'cover'}${important};
      background-attachment: ${style[`${name}-background-image-attachment`]}${important};
      background-position-x: ${style[`${name}-background-image-position-x${vw}`] || 'center'}${important};
      background-position-y: ${style[`${name}-background-image-position-y${vw}`] || 'center'}${important};
    `
  } else {
    return ``
  }
}

export const getLineClamp = ({ name, style, important, mode }) => {
  const lineClamp = style[`${name}-line-clamp`]
  important = important ? ' !important' : ''

  if (lineClamp && !mode) {
    return `
      text-overflow: ellipsis${important};
      display: -webkit-box${important};
      -webkit-line-clamp: ${lineClamp}${important};
      -webkit-box-orient: vertical${important};
      overflow: hidden${important};
    `
  } else {
    return ``
  }
}

export const setStyleResponsive = (name, style) => {
  return `
    margin: ${style[`${name}-margin-vw`]};
    padding: ${style[`${name}-padding-vw`]};
    font-size: ${style[`${name}-font-size-vw`]};
    border-radius: ${style[`${name}-border-radius-vw`]};
    width: ${style[`${name}-width-vw`]};
    height: ${style[`${name}-height-vw`]};
    line-height: ${style[`${name}-line-height-vw`]};
    top: ${style[`${name}-position-absolute-top-vw`]};
    bottom: ${style[`${name}-position-absolute-bottom-vw`]};
    right: ${style[`${name}-position-absolute-right-vw`]};
    left: ${style[`${name}-position-absolute-left-vw`]};
  `
}


export const setStyleBackgroundImage = (name, style) => {
  const backgroundImage = style[`${name}-background-image`]

  if (backgroundImage) {
    return `
      position: relative;
      z-index: 1;
      background-image: ${backgroundImage};
      background-repeat: ${style[`${name}-background-image-repeat`]};
      background-size: ${style[`${name}-background-image-size`] || 'cover'};
      background-attachment: ${style[`${name}-background-image-attachment`]};
      background-color: #000;
      background-position-x: ${style[`${name}-background-image-position-x`] || 'center'};
      background-position-y: ${style[`${name}-background-image-position-y`] || 'center'};
    `
  } else {
    return ``
  }
}

export const setStyleBackgroundImageBefore = (name, style) => {
  const backgroundImage = style[`${name}-background-image`]
  const opacity = style[`${name}-background-image-opacity`]
  const backgroundColor = style[`${name}-background-image-opacity-background-color`] || '#000'

  if (backgroundImage) {
    return `
      opacity: ${opacity};
      background-color: ${opacity && backgroundColor};
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      content: "";
      z-index: -1;
    `
  } else {
    return ``
  }
}


export const setAnimate = ({ name, style }) => {
  const animateUse = style[`${name}-animate-use`]

  let attr = {}

  if (animateUse) {
    attr = {
      'data-aos': style[`${name}-animate-type`],
      'data-aos-offset': style[`${name}-animate-offset`],
      'data-aos-delay': style[`${name}-animate-delay`],
      'data-aos-duration': style[`${name}-animate-duration`],
      'data-aos-easing': style[`${name}-animate-easing`],
      'data-aos-mirror': style[`${name}-animate-mirror`],
      'data-aos-once': style[`${name}-animate-type`],
      'data-aos-anchor-placement': style[`${name}-animate-anchor-placement`],
    }
  }

  return attr
}

