// Redux Actions
import { fetchAuthUserCustomer } from '../../../../redux/Auth/AuthActions'
import { fetchProductOneDisplay } from '../../../../redux/Products/ProductsActions'
import { fetchArticleDisplay } from '../../../../redux/Articles/ArticlesActions'
import { fetchFolderDisplay, fetchFolderDisplayById } from '../../../../redux/Folders/FoldersActions'
import { fetchTagDisplay, fetchTagDisplayById } from '../../../../redux/Tags/TagsActions'

// Values
import { getProductParams } from '../../../../redux/Products/ProductsValues'



export const checkAuthProfileRedirect = (props, { page, templateData }) => {
  const { match } = props

  if (page) {
    if (page.groupPageType === 'profile' || match.path === '/product-manage/:products_id') {
      const auth = props.auth || 'unauthorized'

      if (auth.user === 'unauthorized' || !auth.user || (auth.user && !auth.user.customer)) {
        props.history.push(`/`)

      } else {
        if (templateData) {
          if (match.path === '/product-manage/:products_id') {
            if (auth.user._id && templateData.users_id) {
              if (auth.user._id !== templateData.users_id) {
                props.history.push(`/`)
              }
            } else {
              props.history.push(`/`)
            }
          }
        }
      }
    }
  }
}

export const getContentGroupPage = (props) => {
  const { params, match, singlePageData } = props
  const { content_group_page_id } = match.params

  let contentGroupPage

  if (params && params.contentGroupPage && content_group_page_id && singlePageData) {
    contentGroupPage = singlePageData.contentGroupPages.filter(contentGroupPage => contentGroupPage.path === content_group_page_id)[0]
  }

  return contentGroupPage
}

export const getPage = (props, path_1) => {
  const { pages, match } = props

  if (!path_1) {
    path_1 = match.params.path_1
  }

  let page

  if (path_1) {
    page = pages.filter(page => page.path === path_1)[0]
  }

  return page
}

export const getPageInStore = (pages, path_1) => {
  let page

  if (path_1) {
    page = pages.filter(page => page.path === path_1)[0]
  }

  return page
}

export const getPageTemplate = (props, { data, templateType }) => {
  const { pages } = props

  let page

  if (data) {
    if (data.pages_id) {
      page = pages.filter(page => page._id === data.pages_id && page.status === 'open' && page.type === 'template' && page.templateType === templateType)[0]
    } else {
      page = pages.filter(page => page.status === 'open' && page.type === 'template' && page.templateType === templateType)[0]
    }
  }

  return {
    page,
    template: true,
    templateData: data
  }
}

export const fetchProduct = (props, _id) => {
  const params = getProductParams(_id)
  const { dbProductsNo } = props.match.params
  const dbNo = dbProductsNo || 'not-use-db'

  if (params.products_id) {
    props.dispatch(fetchProductOneDisplay(dbNo, params.products_id, {
      body: {
        where: `status=open`,
      }
    })).then(res => {
      if (res.success) {
        const product = res.data
        const { folders_id } = product

        fetchFolderById(props, folders_id)
      }
    })
  }
}

export const fetchArticle = (props, _id) => {
  props.dispatch(fetchArticleDisplay(_id, {
    body: {
      where: `status=open`,
    }
  })).then(res => {
    if (res.success) {
      const article = res.data
      const { folders_id, tags } = article
      const tags_id = tags && tags[0] && tags[0].tags_id

      if (folders_id) {
        fetchFolderById(props, folders_id)
      }

      if (tags_id) {
        fetchTagById(props, tags_id)
      }
    }
  })
}

export const fetchAuthUser = (props) => {
  const { site, auth } = props

  if (auth && auth.user && auth.user.customer) {
    props.dispatch(fetchAuthUserCustomer(site.businesses_id, {
      body: {
        where: `status=open`,
      }
    }))
  }
}

export const fetchFolder = (props, folders_path) => {
  const { host, match } = props

  let type = "product"

  if (match.path === '/article-categories/:folders_path') {
    type = "article"
  }

  props.dispatch(fetchFolderDisplay(folders_path, {
    body: {
      ...host,
      where: `type=${type}`,
    }
  }))
}

export const fetchTag = (props, tags_path) => {
  const { host, match } = props
  const { path_1 } = match.params

  let type = "product"

  if (path_1 === 'article-tags') {
    type = "article"
  }

  props.dispatch(fetchTagDisplay(tags_path, {
    body: {
      ...host,
      where: `type=${type}`,
    }
  }))
}

export const fetchFolderById = (props, folders_id) => {
  props.dispatch(fetchFolderDisplayById(folders_id, {
    body: {
      where: `status=open`,
    }
  }))
}

export const fetchTagById = (props, tags_id) => {
  props.dispatch(fetchTagDisplayById(tags_id, {
    body: {
      where: `status=open`,
    }
  }))
}

