import React from 'react'

// Components TextEditor
import Selected from '../../../components/Selected'
import {
  BtnSubmitElement
} from '../../../components/ElementStyles'

// Components Local
import StyleSettings from '../StyleSettings'


export const ButtonSubmit = (props) => {
  let { id, style, title, name, text, icon, iconFa, className, disabled } = props
  style = style || {}

  return (
    <Selected type="btn" name={name} title={`ปุ่ม${text}`} styleSettings={StyleSettings} {...props}>
      <BtnSubmitElement
        id={id}
        disabled={disabled}
        name={name}
        className={className}
        text={text}
        icon={icon}
        iconFa={iconFa}
        style={style}
      />
    </Selected>
  )
}

export const BoxElement = (props) => {
  let { style, name, title, children } = props
  style = style || {}

  return (
    <Selected type="block" name={name} title={title} styleSettings={StyleSettings} {...props}>
      { children }
    </Selected>
  )
}


