import { fetchDatas, fetchData, createData, updateData, removeData, fetchApi } from '../reduxActions'
import { generateTypeName, generateTypeFunctions, getTypePath } from '../reduxTypes'


// Export Constants
const NAME_TYPE = ['CONTRACTS', 'CONTRACT']
const name = "contracts"

// Type Names and Type Functions
export const { ADDS, ADDS_MORE, ADD, UPDATE, REMOVE } = generateTypeName(NAME_TYPE)
export const { adds, adds_more, add, update, remove } = generateTypeFunctions(NAME_TYPE)


// Export Actions
export const countContracts = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return fetchApi({
    dispatch,
    params,
    api: `${path}${name}-count/${authName}/${dbNo}/${businesses_id}`,
  })
}

export const checkContractDuplicate = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return fetchApi({
    dispatch,
    params,
    api: `${path}${name}-query/${dbNo}/${businesses_id}/check-value-duplicate?${params.query}`
  })
}

export const fetchContracts = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return fetchDatas({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchContract = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id, contracts_id } = query
  const path = getTypePath(businessType)

  return fetchData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/${contracts_id}`,
    add: add
  })
}

export const createContract = (query, params) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return createData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/create`,
    add: add
  })
}


export const updateContract = (query, params) => dispatch => {
  const { authName, dbNo, businessType, businesses_id, contracts_id, _id } = query
  const path = getTypePath(businessType)

  return updateData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/${_id || contracts_id}`,
    update: update,
    updateId: _id || contracts_id
  })
}

export const removeContract = (query, params) => dispatch => {
  const { authName, dbNo, businessType, businesses_id, contracts_id, _id } = query
  const path = getTypePath(businessType)

  return removeData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/${_id || contracts_id}`,
    remove: remove,
    deleteId: _id || contracts_id
  })
}
