// Redux Actions
import { openConfirm } from '../../../redux/App/AppActions'

// Utils
import { getTextColor } from '../../../util/getColor'


export const setUseAuth = (_this, value) => {
  const tool = {
    style: 'use-auth',
    replace: true,
    value
  }

  addStyle(_this, tool)
}

export const setHiddenPage = (_this, value) => {
  const tool = {
    style: 'hidden-page',
    replace: true,
    value
  }

  addStyle(_this, tool)
}

export const checkShowPage = ({ style, mode, props }) => {
  const hiddenPage = style['hidden-page']
  const { routeType } = props.match && props.match.params || {}

  let show = true

  if (!mode) {
    if (hiddenPage === 'modal') {
      if (routeType) {
        show = false
      }
    } else if (hiddenPage === 'page') {
      if (!routeType) {
        show = false
      }
    }
  }

  return show
}

export const checkUseAuth = ({ style, auth, loggedIn, mode, edit }) => {
  const useAuth = style['use-auth']
  const user = auth && auth.user

  let show

  // display
  if (useAuth && mode === undefined && loggedIn === undefined) {
    if (useAuth === 'show') {
      if (user && user !== 'unauthorized') {
        show = true
      } else {
        show = false
      }

    } else if (useAuth === 'hidden') {
      if (user && user !== 'unauthorized') {
        show = false
      } else {
        show = true
      }
    }

  // manage
  } else if (useAuth && loggedIn !== undefined) {
    if (edit) {
      show = true

    } else {
      if (useAuth === 'show') {
        if (loggedIn) {
          show = true
        } else {
          show = false
        }

      } else if (useAuth === 'hidden') {
        if (loggedIn) {
          show = false
        } else {
          show = true
        }
      }
    }
  } else {
    show = true
  }

  return show
}

export const addBlockFull = (_this) => {
  const blockFull = _this.state.blockFull

  _this.setState({
    blockFull: !blockFull
  }, () => {
    _this.handleSubmit({ timeout: true })

    if (_this.handleRefresh) {
      _this.handleRefresh()
    }
  })
}


export const addStyle = (_this, tool) => {
  const styleOrgin = typeof _this.state.style === 'string' ? {} : _this.state.style
  const style = { ...styleOrgin }

  if (tool.replace || tool.unselect) {
    if (style[tool.style] === tool.value) {
      style[tool.style] = undefined

      if (tool.data) {
        style[`${tool.style}-data`] = undefined
      }

      if (tool.style === 'background-color' || tool.style === 'background') {
        style['color'] = undefined
      }

      if (tool.addTextColor) {
        style[`${tool.addTextColor.style}`] = undefined
      }

    } else {
      style[tool.style] = tool.value

      if (tool.data) {
        style[`${tool.style}-data`] = tool.data
      }

      if (tool.style === 'background-color' || tool.style === 'background') {
        style['color'] = getTextColor(tool.value)
      }

      if (tool.addTextColor) {
        style[`${tool.addTextColor.style}`] = getTextColor(tool.value)
      }
    }
  } else {

    let toolStyleName

    // add multiple
    if (tool.styleMultiple) {
      tool.styleMultiple.map(val => {
        style[val.style] = val.value
      })

      toolStyleName = tool.styleMultiple[0] && tool.styleMultiple[0].style

    // add single
    } else {
      style[tool.style] = tool.value
      toolStyleName = tool.style
    }

    // add data
    if (tool.data) {
      style[`${tool.style}-data`] = tool.data
    }

    // color auto
    if (tool.style === 'background-color' || tool.style === 'background') {
      style['color'] = getTextColor(tool.value)
    }

    // add text auto
    if (tool.addTextColor) {
      style[`${tool.addTextColor.style}`] = getTextColor(tool.value)
    }

    if (toolStyleName && tool.removeGradient) {
      const styleGradient = toolStyleName.replace('-color', '-gradient')

      if (styleGradient.search('background-gradient') !== -1) {
        if (style[`${styleGradient}-data`]) delete style[`${styleGradient}-data`]
        if (style[styleGradient]) delete style[styleGradient]
      }
    }

    if (toolStyleName && tool.removeBackgroundColor) {
      const styleBackgroundColor = toolStyleName.replace('-gradient', '-color')

      if (styleBackgroundColor.search('background-color') !== -1) {
        if (style[styleBackgroundColor]) delete style[styleBackgroundColor]
      }
    }
  }

  if (_this.focus) {
    _this.focus()
  }

  const stateName = tool.stateName || 'style'
  const setStateMore = tool.setStateMore

  _this.setState({ [stateName]: style, ...setStateMore }, () => {
    _this.handleSubmit({ timeout: true, params: tool.params })
  })
}

export const removeStyle = (_this, name, tool) => {
  const styleOrgin = typeof _this.state.style === 'string' ? {} : _this.state.style
  const style = { ...styleOrgin }

  // remove multiple
  if (tool && tool.styleMultiple) {
    tool.styleMultiple.map(val => {
      if (style[val.style]) {
        delete style[val.style]
      }
    })
  }

  // remove single
  if (name) {
    delete style[name]

    if (style[`${name}-data`]) {
      delete style[`${name}-data`]
    }

    if (tool && tool.removeMore) {
      tool.removeMore.map(name => {
        if (style[name]) {
          delete style[name]
        }
      })
    }
  }

  _this.setState({ style }, () => {
    _this.handleSubmit({ timeout: true })
  })
}

export const styleActive = (style, tool, cssActive) => {
  return style[tool.style] === tool.value ? cssActive : ''
}

export const styleActiveValue = (style, lists) => {
  const styleName = lists[lists.length-1] && lists[lists.length-1].style

  if (style && styleName && style[styleName]) {
    return lists.filter(list => list.style === styleName && list.value === style[styleName])[0]
  } else {
    return undefined
  }
}

export const classActiveValue = (className, lists) => {
  const listName = lists[lists.length-1] && lists[lists.length-1].className

  if (className && listName) {
    className = className.filter(val => val.name === listName)[0]

    if (className) {
      return lists.filter(list => list.className === className.name && list.classValue === className.value)[0]
    } else {
      return undefined
    }
  } else {
    return undefined
  }
}


export const classSelected = (name, classNames, group='main') => {
  return classNames.filter(className => className.name === name && className.group === group)[0]
}

export const tagActiveValue = (tag, lists) => {
  if (tag) {
    return lists.filter(list => list.value === tag)[0]
  } else {
    return undefined
  }
}

export const inlineStyleActiveValue = (editorState, lists) => {
  if (editorState) {
    const currentStyle = editorState.getCurrentInlineStyle()

    let activeValue

    lists.map(list => {
      if (currentStyle.has(list.style) === true) {
        activeValue = list
      }
    })

    return activeValue

  } else {
    return undefined
  }
}

export const inlineStyleColorActiveValue = (editorState, colorStyleMap) => {
  if (editorState) {
    const currentStyle = editorState.getCurrentInlineStyle()
    let activeValue

    Object.keys(colorStyleMap).reduce((contentState, color) => {
      if (currentStyle.has(color) === true) {
        activeValue = {
          name: color,
          value: colorStyleMap[color] && colorStyleMap[color].color
        }
      }
    })

    return activeValue
  } else {
    return undefined
  }
}

export const classActice = (className, tool, cssActive) => {
  className = Array.isArray(className) ? className : []
  const index = className.findIndex(val => val.value === tool.classValue)

  return (index !== -1) ? cssActive : ''
}

export const tagActice = (tag, tool, cssActive) => {
  return (tag === tool.value) ? cssActive : ''
}

export const blockTypesActive = (editorState, tool, cssActive) => {
  const selection = editorState.getSelection()
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType()

  const blockTypeSplit = blockType.split('_')
  const blockType1 = blockTypeSplit[0] || ''
  const blockType2 = blockTypeSplit[1] || ''

  if ((tool.style === blockType) || (tool.style === blockType1) || (tool.style === blockType2)) {
    return cssActive
  } else {
    return ''
  }
}

export const inlineStyleActive = (editorState, tool, cssActive) => {
  const currentStyle = editorState.getCurrentInlineStyle()

  return currentStyle.has(tool.style) ? cssActive : ''
}

export const addClass = (_this, tool) => {
  const className = Array.isArray(_this.state.class) ? _this.state.class.slice() : []
  const index = className.findIndex(val => val.name === tool.className && val.value === tool.classValue)

  if (index === -1) {
    const indexValue = className.findIndex(val => val.name === tool.className)

    if (indexValue === -1) {
      const group = tool.group || 'main'

      className.push({ name: tool.className, value: tool.classValue, group })
    } else {
      className[indexValue].value = tool.classValue
    }
  } else {
    if (tool.unselect) {
      className.splice(index, 1)
    }
  }

  if (_this.focus) {
    _this.focus()
  }

  _this.setState({ class: className }, () => {
    _this.handleSubmit({ timeout: true })
  })
}

export const removeClass = (_this, name) => {
  const className = Array.isArray(_this.state.class) ? _this.state.class.slice() : []
  const index = className.findIndex(val => val.name === name)

  if (index !== -1) {
    className.splice(index, 1)
  }

  _this.setState({ class: className }, () => {
    _this.handleSubmit({ timeout: true })
  })
}

export const addTag = (_this, tool) => {
  if (_this.focus) {
    _this.focus()
  }

  _this.setState({ tag: tool.value }, () => {
    _this.handleSubmit()
  })
}

export const generateClassToString = (className) => {
  let classString = ""
  className = Array.isArray(className) ? className : []
  className.map((val, i) => classString = classString + " " + val.value)

  return classString
}

export const generateClassToStringByGroup = (className, group='main') => {
  let classString = ""
  className = Array.isArray(className) ? className : []
  className = className.filter(cls => cls.group === group)
  className.map((val, i) => classString = classString + " " + val.value)

  return classString
}

export const deleteTextEditor = ({ title, props }) => {
  props.dispatch(openConfirm(props.id, {
    title,
    message: `กดปุ่ม "${title}" ถ้าคุณต้องการ${title}`,
    type: 'delete',
    icon: 'delete',
    confirmLabel: title,
    cancelLabel: 'ยกเลิก',
    onConfirm: () => props.delete()
  }))
}

export const onToggleStyle = (e, onToggle, style) => {
  if (e) e.preventDefault()
  onToggle(style)
}

export const filterList = (filterList, lists) => {
  let listsShow = []

  if (filterList) {
    filterList.map(id => {
      const filter = lists.filter(list => list.id === id)[0]

      if (filter) listsShow.push(filter)
    })

  } else {
    listsShow = lists
  }

  return listsShow
}

export const listAlignmentsPosition = [
  {
    id: 'left',
    icon: 'format_align_left',
    tooltip: 'ชิดขวา',
    className: 'alignment',
    classValue: 'editor-block-left',
    width: 55
  },
  {
    id: 'center',
    icon: 'format_align_center',
    tooltip: 'กลาง',
    className: 'alignment',
    classValue: 'editor-block-center',
    width: 55
  },
  {
    id: 'right',
    icon: 'format_align_right',
    tooltip: 'ชิดซ้าย',
    className: 'alignment',
    classValue: 'editor-block-right',
    width: 55
  }
]

export const listAlignments = [
  { id: 'left', label: 'LEFT', width: 50, icon: 'format_align_left', unselect: true, tooltip: 'ชิดขวา', className: 'text-align', classValue: 'left' },
  { id: 'center', label: 'CENTER', width: 45, icon: 'format_align_center', unselect: true, tooltip: 'กลาง', className: 'text-align', classValue: 'center' },
  { id: 'right', label: 'RIGHT', width: 50, icon: 'format_align_right', unselect: true, tooltip: 'ชิดซ้าย', className: 'text-align', classValue: 'right' }
]

export const listHeaders = [
  { id: 'h1', label: 'H1', width: 75, tooltip: 'ขนาดหัวข้อ', style: 'header-one', value: 'h1' },
  { id: 'h2', label: 'H2', width: 75, tooltip: 'ขนาดหัวข้อ', style: 'header-two', value: 'h2' },
  { id: 'h3', label: 'H3', width: 75, tooltip: 'ขนาดหัวข้อ', style: 'header-three', value: 'h3' },
  { id: 'h4', label: 'H4', width: 75, tooltip: 'ขนาดหัวข้อ', style: 'header-four', value: 'h4' },
  { id: 'h5', label: 'H5', width: 75, tooltip: 'ขนาดหัวข้อ', style: 'header-five', value: 'h5' },
  { id: 'h6', label: 'H6', width: 75, tooltip: 'ขนาดหัวข้อ', style: 'header-six', value: 'h6' }
]
