import React, { useMemo } from 'react'
import { connect } from 'react-redux'

// Components Editor
import BlockStyle from '../components/BlockStyle'

// Components Local
import Template from './components/Template'



const TextEditorTemplateDisplay = (props) => {
  const { content, match, action, templateData, apiData } = props
  const template = content.template && JSON.parse(content.template)
  const style = template && template.style ? JSON.parse(template.style) : {}
  const className = template && template.class ? JSON.parse(template.class) : []
  const templates_id = style['templates_id']

  return (
    <BlockStyle {...props} name="template" style={style} className={className}>
      <Template {...props} style={style} templates_id={templates_id} />
    </BlockStyle>
  )
}

export default connect()(TextEditorTemplateDisplay)
