import React, { Fragment } from 'react'
import { connect } from 'react-redux'

// Components Global
import Tooltip from '../../../TooltipNew'
import Dropdown from '../../../Dropdown'
import DropdownDefault from '../../../DropdownDefault'
import ColorPicker from '../../../ColorPicker'

// Actions
import { openModal } from '../../../../redux/App/AppActions'

// Functions
import {
  addStyle,
  addClass,
  addTag,
  onToggleStyle,
  styleActive,
  classActice,
  tagActice,
  blockTypesActive,
  inlineStyleActive,
  deleteTextEditor,
  styleActiveValue,
  classActiveValue,
  tagActiveValue,
  inlineStyleActiveValue,
  inlineStyleColorActiveValue,
  removeStyle,
  removeClass,
  addBlockFull,
  setUseAuth
} from '../../functions/textEditorFunctions'


import { colorStyleMap } from '../../TextEditor/js/blockStyles'

// Utils
import { getTextColor } from '../../../../util/getColor'

// css
import css from './css/btnTools.css'


const BtnTools = (props) => {
  let { title, tools, _this, notDelete, blockFull } = props
  const useAuth = _this && _this.state.style && _this.state.style['use-auth']

  return (
    <div>
      { tools.map((tool, iTool) => {
        const { lists, dropdown, dropdownChildren, colorPicker, type, sizeResponsive, show, styleContent } = tool

        return show && lists.length !== 0 && (
          <div key={iTool} className={css.boxTools}>
            { lists.map((list, i) => {
              let items = list.items
              let btnActive, onClick, onMouseDown, stateValue, activeValue, addFn, boxBg, textColor, colorPickerValue


              /* style */
              if (type === 'style') {
                btnActive = styleActive
                stateValue = _this.state.style

                if (dropdown) {
                  list.items.map((item, i) => {
                    if (item.remove) {

                      if (sizeResponsive) {
                        items[i].onClick = () => removeStyle(_this, null, {
                          styleMultiple: [
                            { style: item.style },
                            { style: `${item.style}-rem`},
                            { style: `${item.style}-vw` }
                          ]
                        })
                      } else {
                        items[i].onClick = () => removeStyle(_this, item.style)
                      }

                    } else {
                      if (sizeResponsive) {
                        items[i].onClick = () => addStyle(_this, {
                          styleMultiple: [
                            { style: item.style, value: `${parseInt(item.value)}px` },
                            { style: `${item.style}-rem`, value: `${parseInt(item.value)/15}rem` },
                            { style: `${item.style}-vw`, value: `${parseInt(item.value)/15}vw` }
                          ]
                        })
                      } else {
                        items[i].onClick = () => addStyle(_this, item)
                      }
                    }
                  })

                  activeValue = styleActiveValue(stateValue, items)
                  activeValue = activeValue && {
                    value: activeValue.value,
                    label: activeValue.label,
                  }

                } else if (colorPicker) {
                  addFn = addStyle

                  colorPickerValue = stateValue && stateValue[list.style]

                  if (list.activeType === 'text') {
                    boxBg = '#efefef'
                    textColor = colorPickerValue
                  }

                  if (list.activeType === 'box') {
                    boxBg = colorPickerValue
                    textColor = getTextColor(colorPickerValue)
                  }

                } else {
                  onClick = () => addStyle(_this, list)
                }


              /* class */
              } else if (type === 'class') {
                btnActive = classActice
                stateValue = _this.state.class

                if (dropdown) {
                  list.items.map((item, i) => {
                    if (item.remove) {
                      items[i].onClick = () => removeClass(_this, item.className)
                    } else {
                      items[i].onClick = () => addClass(_this, item)
                    }

                    items[i].value = item.classValue
                  })

                  activeValue = classActiveValue(stateValue, items)
                  activeValue = activeValue && {
                    value: activeValue.classValue,
                    label: activeValue.label,
                  }
                } else {
                  onClick = () => addClass(_this, list)
                }


              /* tag */
              } else if (type === 'tag') {
                btnActive = tagActice
                stateValue = _this.state.tag

                if (dropdown) {
                  list.items.map((item, i) => {
                    if (item.remove) {
                      items[i].onClick = () => removeClass(_this, item.className)
                    } else {
                      items[i].onClick = () => addTag(_this, item)
                    }

                    items[i].value = item.value
                  })

                  activeValue = tagActiveValue(stateValue, items)
                  activeValue = activeValue && {
                    value: activeValue.value,
                    label: activeValue.label,
                  }
                } else {
                  onClick = () => addTag(_this, list)
                }


              /* modal */
              } else if (type === 'modal') {
                onClick = () => props.dispatch(openModal(list.modalId))


              /* blockTypes */
              } else if (type === 'blockTypes') {
                btnActive = blockTypesActive
                onMouseDown = (e) => onToggleStyle(e, tool.onToggle, list.style)
                stateValue = tool.editorState


              /* inlineStyle */
              } else if (type === 'inlineStyle') {
                btnActive = inlineStyleActive
                stateValue = tool.editorState

                if (dropdown) {
                  list.items.map((item, i) => {
                    items[i].onMouseDown = (e) => {
                      return onToggleStyle(e, tool.onToggle, item.style)
                    }
                  })

                  activeValue = inlineStyleActiveValue(stateValue, items)

                } else if (colorPicker) {
                  addFn = (_this, list) => {
                    let color = list.value

                    if (color) {
                      color = color.replace('#', '')
                      return onToggleStyle(null, tool.onToggle, 'COLOR_' + color)
                    }
                  }

                  activeValue = inlineStyleColorActiveValue(stateValue, colorStyleMap)
                  colorPickerValue = activeValue && activeValue.value

                  if (list.activeType === 'text') {
                    boxBg = '#efefef'
                    textColor = colorPickerValue
                  }

                  if (list.activeType === 'box') {
                    boxBg = colorPickerValue
                    textColor = getTextColor(colorPickerValue)
                  }
                } else {
                  onMouseDown = (e) => onToggleStyle(e, tool.onToggle, list.style)
                }

              /* other */
              } else {
                if (colorPicker) {
                  addFn = list.onClick

                  if (list.activeType === 'text') {
                    boxBg = '#efefef'
                    textColor = list.backgroundColor
                  }

                  if (list.activeType === 'box') {
                    boxBg = list.backgroundColor
                    textColor = list.backgroundColor ? getTextColor(list.backgroundColor) : '#333'
                  }
                }

                if (dropdownChildren) {

                }

                activeValue = tool.activeValue

                onClick = () => list.onClick()
              }



              return (
                <Tooltip
                  top
                  key={i}
                  msg={list.tooltip}
                  width={list.width || 80}
                  unit="px"
                  childHeight="40px"
                  style={{ fontSize: '0.8rem' }}>

                  { dropdown &&
                    <Dropdown
                      activeValue={activeValue && activeValue.value}
                      liStyle={{ minHeight: 'unset' }}
                      aStyle={{ padding: '5px 16px', fontSize: '0.8rem' }}
                      styleDropdwon={list.style || { top:' 32px', minWidth: '120px'}}
                      items={items}>

                      <div
                        className={`${css.btnTool} ${activeValue && css.active}`}
                        style={{ fontSize: '0.8rem' }}>

                        <i className={`material-icons right mg-left-3 mg-top-3`}>keyboard_arrow_down</i>
                        { activeValue && activeValue.label ? activeValue.label : list.label }
                      </div>
                    </Dropdown>
                  }

                  { dropdownChildren &&
                    <DropdownDefault
                      styleContent={{
                        padding: '2px',
                        top: '32px',
                        left: '-2px',
                        width: '250px',
                        ...styleContent
                      }}>

                      <div
                        className={`${css.btnTool} ${activeValue && css.active}`}
                        style={{ fontSize: '0.8rem' }}>

                        { list.icon &&
                          <i className={`material-icons i-middle font-1-3`} style={{ color: textColor }}>{list.icon}</i>
                        }

                        { !list.icon &&
                          <i className={`material-icons right mg-left-3 mg-top-3`}>keyboard_arrow_down</i>
                        }

                        { list.label }
                      </div>

                      <div>
                        { list.children }
                      </div>
                    </DropdownDefault>
                  }

                  { colorPicker &&
                    <ColorPicker
                      color={colorPickerValue}
                      label={list.tooltip}
                      hiddenColorNone
                      hiddenColorAuto
                      styleDropdown={{ left: '-9px' }}
                      onChange={{ addFn, _this, list }}>

                      <div
                        className={`${css.btnTool}`}
                        style={{
                          backgroundColor: boxBg
                        }}>

                        { list.icon ?
                          <i className={`material-icons i-middle font-1-3`} style={{ color: textColor }}>{list.icon}</i>
                          :
                          <span className="font-0-9 line-height-30" style={{ color: textColor }}>{list.label}</span>
                        }
                      </div>
                    </ColorPicker>
                  }


                  { !dropdown && !colorPicker && !dropdownChildren &&
                    <div
                      className={`${css.btnTool} ${btnActive && btnActive(stateValue, list, css.active)}`}
                      onClick={onClick}
                      onMouseDown={onMouseDown}>

                      {/* icon and label */}
                      { list.icon && list.label &&
                        <Fragment>
                          <i className={`material-icons i-middle font-1-2 left mg-right-5`}>{list.icon}</i>
                          <span className="font-0-8 line-height-25">{list.label}</span>
                        </Fragment>
                      }

                      {/* icon only */}
                      { list.icon && !list.label &&
                        <i className={`material-icons i-middle font-1-3`}>{list.icon}</i>
                      }

                      {/* label only */}
                      { !list.icon && list.label &&
                        <span className="font-0-8 line-height-25">{list.label}</span>
                      }
                    </div>
                  }
                </Tooltip>
              )
            }) }
          </div>
        )
      }) }

      { blockFull &&
        <div className={css.boxTools}>
          <Tooltip
            top
            msg="ขยายเต็มพื้นที่"
            width={95}
            unit="px"
            childHeight="40px"
            style={{ fontSize: '0.8rem' }}>

            <div className={`${css.btnTool} ${_this.state.blockFull && css.active}`} onClick={() => addBlockFull(_this)}>
              <i className={`material-icons i-middle font-1-2`}>arrow_back</i>
              <i className={`material-icons i-middle font-1-2`}>arrow_forward</i>
            </div>
          </Tooltip>
        </div>
      }

      <div className={css.boxTools}>
        <Tooltip
          top
          msg="แสดงเมื่อเข้าสู่ระบบ"
          width={105}
          childHeight="40px"
          style={{
            fontSize: '0.8rem'
          }}
          unit="px">

          <div
            className={`${css.btnTool} ${useAuth === 'show' ? css.active : ''}`}
            onClick={() => setUseAuth(_this, 'show') }>

            <i className="material-icons i-middle">lock</i>
          </div>
        </Tooltip>
      </div>

      <div className={css.boxTools}>
        <Tooltip
          top
          msg="ซ่อนเมื่อสู่ระบบ"
          width={90}
          childHeight="40px"
          style={{
            fontSize: '0.8rem'
          }}
          unit="px">

          <div
            className={`${css.btnTool} ${useAuth === 'hidden' ? css.active : ''}`}
            onClick={() => setUseAuth(_this, 'hidden') }>

            <i className={`material-icons i-middle font-1-3`}>block</i>
          </div>
        </Tooltip>
      </div>


      { !notDelete &&
        <div className={css.boxTools}>
          <Tooltip
            top
            msg="ลบบล็อก"
            width={55}
            unit="px"
            childHeight="40px"
            style={{ fontSize: '0.8rem' }}>

            <div className={`${css.btnTool}`} onClick={() => deleteTextEditor({ title: 'ลบ'+title+'นี้', props: _this.props })}>
              <i className={`material-icons i-middle font-1-3`}>delete</i>
            </div>
          </Tooltip>
        </div>
      }
    </div>
  )
}

export default connect()(BtnTools)
