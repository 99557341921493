import React, { Component } from 'react'

// Functions
import { addStyle, removeStyle } from '../../../functions/textEditorFunctions'

// css
import css from '../css/styleSettings.css'



class CursorSetting extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  change = (e) => {
    let { _this, styleName } = this.props
    const value = e.target.value

    styleName = styleName || 'cursor'

    if (value === '') {
      removeStyle(_this, styleName)
    } else {
      addStyle(_this, {
        style: styleName,
        value
      })
    }
  }

  reset = () => {
    let { _this, styleName } = this.props
    styleName = styleName || 'cursor'

    if (styleName) {
      removeStyle(_this, styleName)
    }
  }

  render() {
    let { _this, styleName } = this.props
    styleName = styleName || 'cursor'

    const textAlign = _this.state.style[styleName]

    const cursors = [
      'alias',
      'all-scroll',
      'auto',
      'cell',
      'context-menu',
      'col-resize',
      'copy',
      'crosshair',
      'default',
      'e-resize',
      'ew-resize',
      'grab',
      'grabbing',
      'help',
      'move',
      'n-resize',
      'ne-resize',
      'nesw-resize',
      'ns-resize',
      'nw-resize',
      'nwse-resize',
      'no-drop',
      'none',
      'not-allowed',
      'pointer',
      'progress',
      'row-resize',
      's-resize',
      'se-resize',
      'sw-resize',
      'text',
      'w-resize',
      'wait',
      'zoom-in',
      'zoom-out'
    ]

    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td className="pd-0 font-0-7">เคอร์เซอร์ (cursor)</td>
              <td className="pd-0 font-0-7 text-right">
                <a className={css.btn} onClick={this.reset}>ค่าเดิม</a>
              </td>
            </tr>
          </tbody>
        </table>

        <div>
          <div className={css.boxInput}>
            <table>
              <tbody>
                <tr>
                  <td width="50%" className="pd-0 font-0-8 center">
                    <select
                      className={`browser-default ${css.input} ${css.select}`}
                      onChange={this.change}
                      value={textAlign || ''}>

                      <option value="">ไม่มี</option>
                      { cursors.map(cursor => {
                        return (
                          <option key={cursor} value={cursor}>{cursor}</option>
                        )
                      }) }
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default CursorSetting
