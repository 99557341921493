import React, { Component } from 'react'

// Components Global
import Switch from '../../../../../Inputs/Switch'

// Components Text Editor Local
import Box from '../../../Box'

// Components Local
import IconLayout from './components/IconLayout'

// Functions
import { addStyle, removeStyle } from '../../../../functions/textEditorFunctions'


class IconSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleIconHidden = (value) => {
    const { _this } = this.props

    _this.setState({ iconHidden: value }, () => {
      _this.handleSubmit({ timeout: true })
    })
  }

  handleShowTypeChange = (value) => {
    const { _this } = this.props
    const style = 'nav-show-type'

    if (value === 'normal') {
      removeStyle(_this, style)
    } else {
      addStyle(_this, { style, value })
    }
  }

  render() {
    const { _this } = this.props
    const { style, titleShow, captionShow } = _this.state
    const { tools } = _this.props

    return (
      <div>
        { tools.settingsMore.layout &&
          <Box title="รูปแบบไอคอน" icon="vertical_split" bodyClassName="pd-10">
            <IconLayout {...this.props} />
          </Box>
        }

        { (tools.settingsMore.title || tools.settingsMore.caption) &&
          <Box title="เนื้อหา" icon="notes" bodyClassName="pd-10">
            { tools.settingsMore.title &&
              <div className="border-bottom-eee">
                <table>
                  <tbody>
                    <tr>
                      <td className="pd-0 font-0-9">แสดงหัวข้อ</td>
                      <td width="80" className="pd-0 text-right">
                        <Switch
                          notSave
                          id="titleShow"
                          value={titleShow}
                          onChange={(e) => _this.handleIconSetting(e, 'titleShow')}
                          labelHidden
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            }

            { tools.settingsMore.caption &&
              <table>
                <tbody>
                  <tr>
                    <td className="pd-0 font-0-9">แสดงคำบรรยาย</td>
                    <td width="80" className="pd-0 text-right">
                      <Switch
                        notSave
                        id="captionShow"
                        value={captionShow}
                        onChange={(e) => _this.handleIconSetting(e, 'captionShow')}
                        labelHidden
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            }
          </Box>
        }
      </div>
    )
  }
}

export default IconSettings
