import React, { Component, forwardRef, useImperativeHandle, Fragment } from 'react'
import ReactDOM from 'react-dom'

// css
import css from './css/dropdownSidebar.css'


class DropdownSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      openTransition: false
    }
  }

  componentDidMount() {
    this.mounted = true

    if (!this.props.bgBlock) {
      document.addEventListener('click', this.handleDocumentClick)
    }

    if (this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentWillUnmount() {
    this.mounted = false

    if (!this.props.bgBlock) {
      document.removeEventListener('click', this.handleDocumentClick)
    }

    this.setScrollbar(false)

    if (this.props.onRef) this.props.onRef(null)
  }

  handleDocumentClick = (e) => {
    if (!this.props.bgBlock) {

      const dropdownSidebar = document.querySelector('#dropdownSidebar')
      const confirmAlert = document.querySelector('#confirmAlert')
      const modal = document.querySelector('#modal')

      const checkDropdownSidebar = dropdownSidebar && ReactDOM.findDOMNode(dropdownSidebar).contains(e.target)
      const checkConfirmAlert = confirmAlert && ReactDOM.findDOMNode(confirmAlert).contains(e.target)
      const checkModal = modal && ReactDOM.findDOMNode(modal).contains(e.target)
      const target = ReactDOM.findDOMNode(this).contains(e.target)

      if (this.state.open) {

        if (confirmAlert) {
          if (!checkDropdownSidebar && !checkConfirmAlert && !target) {
            this.closeDropdown()
          }
        } else if (checkModal) {
          if (!checkDropdownSidebar && !checkModal && !target) {
            this.closeDropdown()
          }
        } else {
          if (!checkDropdownSidebar && !target) {
            this.closeDropdown()
          }
        }

        /*if (!ReactDOM.findDOMNode(this).contains(e.target)) {
          this.closeDropdown()
        }*/
      }

      if (this.props.close) {
        this.closeDropdown()
      }
    }
  }

  setScrollbar = (open) => {
    if (this.props.bgBlock) {
      const body = document.getElementById('site-manage-container')

      if (body) {
        if (open) {
          body.style.overflow = 'hidden'
        } else {
          body.style.overflow = 'auto'
        }
      }
    }
  }

  open = () => {
    this.openDropdown()
  }

  close = () => {
    this.closeDropdown()
  }

  openDropdown = () => {
    if (this.state.open) {
      this.closeDropdown()
    } else {
      this.setState({ open: true }, () => {
        setTimeout(() => {
          this.setState({ openTransition: true })
          this.setScrollbar(true)
        }, 100)

        if (this.props.getOpenValue) {
          this.props.getOpenValue(this.state.open)
        }
      })
    }
  }

  closeDropdown = () => {
    this.setScrollbar(false)

    this.setState({ openTransition: false }, () => {
      setTimeout(() => {
        this.mounted && this.setState({ open: false })
      }, 500)

      if (this.props.getOpenValue) {
        this.props.getOpenValue(this.state.open)
      }
    })
  }

  render() {
    const styleContent = this.props.styleContent || {}
    const style = {
      ...styleContent,
      visibility: this.state.openTransition ? 'visible' :'hidden',
      opacity: this.state.openTransition ? '1' :'0'
    }

    const styleBgBlock = {
      visibility: this.state.openTransition ? 'visible' :'hidden',
      opacity: this.state.openTransition ? '1' :'0'
    }

    return (
      <Fragment>
        { this.props.bgBlock && this.state.open &&
          <div onClick={this.closeDropdown} className={css.bgBlock} style={styleBgBlock} />
        }

        <div className={css.boxDropdown} style={this.props.style}>
          <div className={css.boxClick} onClick={this.openDropdown}>
            { this.props.children[0] }
          </div>

          { this.state.open &&
            <div id="dropdownSidebar" className={css.content} style={style}>
              { this.props.children[1] }
            </div>
          }
        </div>
      </Fragment>
    )
  }
}

export default DropdownSidebar
