import { lazy } from 'react'
import suspenseComponent from '../../../../util/suspenseComponent'

let EditorText = lazy(() => import('./EditorText'))
EditorText = suspenseComponent(EditorText)

let EditorTextDisplay = lazy(() => import('./EditorTextDisplay'))
EditorTextDisplay = suspenseComponent(EditorTextDisplay)

export {
  EditorText,
  EditorTextDisplay
}

