import React, { Fragment } from 'react'


import { setStyleValue, getStyle } from '../../../../TextEditor/components/ElementStyles/components/js/setDivStyle'


const StylesGlobal = (props) => {
  const { site } = props
  const design = site && site.design
  const style = design && design.style ? JSON.parse(design.style) : {}


  /*Array.apply(1, Array(101)).map((v, i) => {
    if (i > 0) {
      const val = `${i/10}`
      const valSp = val.split('.')

      let first = valSp[0] || 0
      let last = valSp[1] || 0
      let name = `${first}-${last}`

      const className = `.rps-font-${name}, .public-DraftEditor-content [style*="stroke-dashoffset: ${val}"] { font-size: ${i}px !important; }`

      console.log(className)
    }
  })

  console.log('-------------------------------------------')*/

  const setEvent = (name, event, important=false) => {
    return (
      `
        ${getStyle('box-shadow', style[`${name}-${event}-box-shadow`], important)}
        ${getStyle('border-style', style[`${name}-${event}-border-style`], important)}
        ${getStyle('border-width', style[`${name}-${event}-border-width`], important)}
        ${getStyle('border-color', style[`${name}-${event}-border-color`], important)}
      `
    )
  }

  return (
    <div>
      <style jsx global>{`
        .editor-input .box-input-global-jsx {
          ${style && setStyleValue({ name: 'box-input', style })}
        }
        .editor-input .input-global-jsx {
          ${style && setStyleValue({ name: 'input', style })}

            -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
                    box-sizing: border-box;
        }
        .editor-input .input-global-jsx:focus:not([readonly]) {
          ${style && setEvent('input', 'focus')}
        }
        .editor-input .input-global-jsx.valid,
        .editor-input .input-global-jsx:focus.valid {
          ${style && setEvent('input', 'valid', true)}
        }
        .editor-input .input-global-jsx.invalid,
        .editor-input .input-global-jsx:focus.invalid {
          ${style && setEvent('input', 'invalid', true)}
        }
        .editor-input .label-jsx {
          ${style && setStyleValue({ name: 'input-label', style })}
        }

        .editor-preview {
          color: ${style['body-font-color']};
          font-size: ${style['body-font-size']};
        }

        .editor-preview a {
          font-size: ${style['body-font-size']};
          color: ${style['body-a-color']};
        }
        .editor-preview a:hover {
          color: ${style['body-a-hover-color']};
          text-decoration: ${style['body-a-hover-text-decoration']};
        }
        .editor-preview a:active {
          color: ${style['body-a-active-color']};
        }

        .editor-preview h1 {
          font-size: ${style['body-h1-font-size']} !important;
          color: ${style['body-h1-font-color']} !important;
          font-weight: ${style['body-h1-font-weight']} !important;
          margin: ${style['body-h1-margin']} !important;
        }
        .editor-preview h2 {
          font-size: ${style['body-h2-font-size']} !important;
          color: ${style['body-h2-font-color']} !important;
          font-weight: ${style['body-h2-font-weight']} !important;
          margin: ${style['body-h2-margin']} !important;
        }
        h3 {
          font-size: ${style['body-h3-font-size']} !important;
          color: ${style['body-h3-font-color']} !important;
          font-weight: ${style['body-h3-font-weight']} !important;
          margin: ${style['body-h3-margin']} !important;
        }
        .editor-preview h4 {
          font-size: ${style['body-h4-font-size']} !important;
          color: ${style['body-h4-font-color']} !important;
          font-weight: ${style['body-h4-font-weight']} !important;
          margin: ${style['body-h4-margin']} !important;
        }
        .editor-preview h5 {
          font-size: ${style['body-h5-font-size']} !important;
          color: ${style['body-h5-font-color']} !important;
          font-weight: ${style['body-h5-font-weight']} !important;
          margin: ${style['body-h5-margin']} !important;
        }
        .editor-preview h6 {
          font-size: ${style['body-h6-font-size']} !important;
          color: ${style['body-h6-font-color']} !important;
          font-weight: ${style['body-h6-font-weight']} !important;
          margin: ${style['body-h6-margin']} !important;
        }

        @media (min-width: 1500px) {
          .editor-preview {
            font-size: ${style['body-font-size-vw']};
          }
          .editor-preview a {
            font-size: ${style['body-font-size-vw']};
          }
          .editor-preview h1 {
            font-size: ${style['body-h1-font-size-vw']} !important;
            margin: ${style['body-h1-margin-vw']} !important;
          }
          .editor-preview h2 {
            font-size: ${style['body-h2-font-size-vw']} !important;
            margin: ${style['body-h2-margin-vw']} !important;
          }
          .editor-preview h3 {
            font-size: ${style['body-h3-font-size-vw']} !important;
            margin: ${style['body-h3-margin-vw']} !important;
          }
          .editor-preview h4 {
            font-size: ${style['body-h4-font-size-vw']} !important;
            margin: ${style['body-h4-margin-vw']} !important;
          }
          .editor-preview h5 {
            font-size: ${style['body-h5-font-size-vw']} !important;
            margin: ${style['body-h5-margin-vw']} !important;
          }
          .editor-preview h6 {
            font-size: ${style['body-h6-font-size-vw']} !important;
            margin: ${style['body-h6-margin-vw']} !important;
          }
        }
      `}</style>
    </div>
  )
}

export default StylesGlobal
