import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// Components Local
import Navbar from './components/Navbar'


const TextEditorNavbarDisplay = (props) => {
  const lang = props.lang || 'local'
  const content = props.content
  const navbar = content.navbar && JSON.parse(content.navbar)
  const style = navbar && navbar.style ? JSON.parse(navbar.style) : {}
  const className = navbar && navbar.class ? JSON.parse(navbar.class) : []

  const image = navbar && navbar.image ? JSON.parse(navbar.image) : '',
        logoHidden = navbar && navbar.logoHidden,
        iconHidden = navbar && navbar.iconHidden

  return (
    <Navbar
      {...props}
      display
      lang={lang}
      className={className}
      style={style}
      image={image}
      logoHidden={logoHidden}
      iconHidden={iconHidden}
    />
  )
}

export default withRouter(connect()(TextEditorNavbarDisplay))
