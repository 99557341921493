import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

// Components Text Editor
import LinkDisplay from '../../../components/LinkDisplay'

// JS
import { settings, getFolberBreadcrums, getTagBreadcrums, getItemBreadcrumb } from '../../js/breadcrumbs'

// Values
import { getProductParams } from '../../../../../redux/Products/ProductsValues'

// css
import css from './css/breadcrumbs.css'


const Breadcrumbs = (props) => {
  const { lang } = props
  let { items, folder, tag, product, article, display, mode, _this, style, templateData, closeWindowModal } = props
  const { folderBreadcrumbs, tagBreadcrumbs, itemBreadcrumb, folderBreadcrumbsType, tagBreadcrumbsType, prefixUrl } = settings(style)

  let breadcrums = []

  if (folderBreadcrumbs) {
    if (!mode) {
      breadcrums = getFolberBreadcrums({ style, lang, folder, product, article, element: 'path', prefixUrl })

    } else {
      breadcrums.push(JSON.stringify({
        id: 'id-cate',
        [`name_${lang}`]: folderBreadcrumbsType ? `[${folderBreadcrumbsType}-categories]` : '[ยังไม่เลือกหมวดหมู่]',
      }))
    }

    items = [...items, ...breadcrums]
  }

  if (tagBreadcrumbs) {
    if (!mode) {
      breadcrums = getTagBreadcrums({ style, lang, tag, element: 'path', prefixUrl })

    } else {
      breadcrums.push(JSON.stringify({
        id: 'id-cate',
        [`name_${lang}`]: tagBreadcrumbsType ? `[${tagBreadcrumbsType}-tags]` : '[ยังไม่เลือกแท็ก]',
      }))
    }

    items = [...items, ...breadcrums]
  }

  if (itemBreadcrumb) {
    if (!mode) {
      breadcrums = getItemBreadcrumb({ style, lang, folder, product, article, element: 'path', prefixUrl })

    } else {
      breadcrums.push(JSON.stringify({
        id: 'id-item',
        [`name_${lang}`]: 'รายการ API',
      }))
    }

    items = [...items, ...breadcrums]
  }

  return (
    <div className="box-breadcrumbs-jsx">
      <div className={`${css.boxBreadcrumb} breadcrumbs-jsx`}>
        { items.map((item, i) => {
          item = JSON.parse(item)

          let nameLocal = item[`name_local`]
          let name = item[`name_${lang}`] || nameLocal
          let useLink

          if (folderBreadcrumbs && !itemBreadcrumb) {
            useLink = true
          } else {
            if (items.length !== i + 1) {
              useLink = true
            }
          }

          return (
            <Fragment key={item.id}>
              <div className={`${css.item} breadcrumbs-item-jsx`}>
                <div className={`${css.label} label-jsx`}>

                  { useLink ?
                    <LinkDisplay
                      mode={mode}
                      rerender={{
                        name,
                        item: JSON.stringify(item)
                      }}
                      value={item.link}
                      apiData={templateData}
                      closeWindowModal={closeWindowModal}>

                      <Fragment>{name || 'ยังไม่ระบุชื่อ'}</Fragment>
                    </LinkDisplay>
                  :
                    <span>
                      <Fragment>{name || 'ยังไม่ระบุชื่อ'}</Fragment>
                    </span>
                  }

                </div>
              </div>
            </Fragment>
          )
        }) }
      </div>

      { !display &&
        <div className="center pd-top-40">
          <a
            onClick={() => _this.openSidebar('content')}
            className="btn-shadow-none btn btn-sm btn-white pd-right-15"
            style={{ userSelect: 'none' }}>

            <Fragment>
              <i className="material-icons font-0-9 color-000 left">add</i>
              <span className="font-0-9 color-000">เพิ่มลิงก์นำทาง</span>
            </Fragment>
          </a>
        </div>
      }

      <style jsx>{`
        .box-breadcrumbs-jsx {
          text-align: ${style[`box-breadcrumbs-text-align`]};
        }
        .breadcrumbs-jsx {
          background-color: ${style[`breadcrumbs-background-color`]};
          box-shadow: ${style[`breadcrumbs-box-shadow`]};
          border-width: ${style['breadcrumbs-border-width']};
          border-style: ${style['breadcrumbs-border-style']};
          border-color: ${style['breadcrumbs-border-color']};

          padding: ${style[`breadcrumbs-padding`]};
          border-radius: ${style[`breadcrumbs-border-radius`]};
        }
        .breadcrumbs-item-jsx:before {
          color: ${style['breadcrumbs-item-icon-color']} !important;

          font-size: ${style['breadcrumbs-item-icon-font-size']} !important;
        }
        .breadcrumbs-jsx .breadcrumbs-item-jsx .label-jsx {
          background-color: ${style[`breadcrumbs-item-background-color`]};

          padding: ${style[`breadcrumbs-item-padding`]};
          border-radius: ${style[`breadcrumbs-item-border-radius`]};
        }
        .breadcrumbs-jsx .breadcrumbs-item-jsx .label-jsx :global(a),
        .breadcrumbs-jsx .breadcrumbs-item-jsx .label-jsx {
          color: ${style[`breadcrumbs-item-color`]};
          font-weight: ${style[`breadcrumbs-item-font-weight`]};

          font-size: ${style[`breadcrumbs-item-font-size`]};
        }
        .breadcrumbs-jsx .breadcrumbs-item-jsx .label-jsx:hover {
          background-color: ${style[`breadcrumbs-item-hover-background-color`]};

          padding: ${style[`breadcrumbs-item-padding`]};
          border-radius: ${style[`breadcrumbs-item-hover-border-radius`]};
        }
        .breadcrumbs-jsx .breadcrumbs-item-jsx .label-jsx:hover :global(a) {
          color: ${style[`breadcrumbs-item-hover-color`]};
          font-weight: ${style[`breadcrumbs-item-hover-font-weight`]};

          font-size: ${style[`breadcrumbs-item-hover-font-size`]};
        }

        @media (min-width: 1500px) {
          .breadcrumbs-jsx {
            padding: ${style['breadcrumbs-padding-vw']};
            border-radius: ${style[`breadcrumbs-border-radius-vw`]};
          }
          .breadcrumbs-item-jsx:before {
            font-size: ${style['breadcrumbs-item-icon-font-size-vw']} !important;
          }
          .breadcrumbs-jsx .breadcrumbs-item-jsx .label-jsx {
            padding: ${style[`breadcrumbs-item-padding-vw`]};
            border-radius: ${style[`breadcrumbs-item-border-radius-vw`]};
            font-size: ${style[`breadcrumbs-item-font-size-vw`]};
          }
          .breadcrumbs-jsx .breadcrumbs-item-jsx .label-jsx :global(a) {
            font-size: ${style[`breadcrumbs-item-font-size-vw`]};
          }
          .breadcrumbs-jsx .breadcrumbs-item-jsx .label-jsx:hover {
            padding: ${style[`breadcrumbs-item-padding-vw`]};
            border-radius: ${style[`breadcrumbs-item-hover-border-radius-vw`]};
          }
          .breadcrumbs-jsx .breadcrumbs-item-jsx .label-jsx:hover :global(a) {
            font-size: ${style[`breadcrumbs-item-hover-font-size-vw`]};
          }
        }
      `}</style>
    </div>
  )
}

const mapStateToProps = (store, props) => {
  const { match } = props
  const { folders_path, tags_path, products_id, articles_id, single_page_products_id } = match.params

  let folder, tag, product, article

  if (folders_path) {
    folder = store.folders.data.filter(folder => folder.path === folders_path)[0]
  }

  if (tags_path) {
    tag = store.tags.data.filter(tag => tag.path === tags_path)[0]
  }

  if (products_id || single_page_products_id) {
    const _id = products_id || single_page_products_id
    const params = getProductParams(_id)

    product = store.products.data.filter(product => product._id === params.products_id)[0]

    if (product) {
      folder = store.folders.data.filter(folder => folder._id === product.folders_id)[0]
    }

  } else if (articles_id) {
    article = store.articles.data.filter(article => article._id === articles_id)[0]

    if (article) {
      folder = store.folders.data.filter(folder => folder._id === article.folders_id)[0]

      if (article.tags[0] && article.tags[0].tags_id) {
        tag = store.tags.data.filter(tag => tag._id === article.tags[0].tags_id)[0]
      }
    }
  }

  return {
    folder,
    product,
    article,
    tag
  }
}

export default connect(mapStateToProps)(Breadcrumbs)
