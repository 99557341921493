import React, { Fragment } from 'react'

// convertDataApi
import { convertTextDataApi } from '../../functions/convertDataApi'

// ElementStyles
import { setStyleValue } from '../ElementStyles/components/js/setDivStyle'

// css
import css from './css/badge.css'


const BadgeDisplay = (props) => {
  let { style, name, apiData, templateData, dataStore, mode } = props
  name = name || 'badge'
  style = style || {}

  const badgeShow = style['badge-show']
  const text = style['badge-value']
  const badgeValue = convertTextDataApi({ text, apiData, templateData, dataStore })

  return (
    <Fragment>
      { ((!mode && badgeShow && badgeValue) || (mode && badgeShow)) &&
        <div className={`badge-jsx ${css.badge}`}>
          { !mode ? badgeValue : '9' }

          <style jsx>{`
            .badge-jsx {
              ${setStyleValue({ name, style })}
            }

            @media (min-width: 1500px) {
              .badge-jsx {
                ${setStyleValue({ name, style, vw: true })}
              }
            }

            @media (min-width: 601px) and (max-width: 1024px) {
              .badge-jsx {
                ${setStyleValue({ device: 'tablet', name, style })}
              }
            }

            @media (min-width: 50px) and (max-width: 600px) {
              .badge-jsx {
                ${setStyleValue({ device: 'mobile', name, style })}
              }
            }
          `}</style>
        </div>
      }
    </Fragment>
  )
}

export default BadgeDisplay
