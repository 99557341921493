import React from 'react'
import Switch from 'react-router/Switch'
import Route from 'react-router/Route'

// Auth
import Auth from '../common/Auth/Auth'



const mapRouter = (routes, extraProps) => {
  return routes.map((route, i) => (
    <Route
      key={route.key || i}
      path={route.path}
      exact={route.exact}
      strict={route.strict}
      render={props => {
        if (route.auth) {
          return (
            <Auth
              reqAuth={route.auth.reqAuth}
              reqAuthBiz={route.auth.reqAuthBiz}
              reqAuthActive={route.auth.active !== undefined ? route.auth.active : true }
              reqExpDate={route.auth.reqExpDate}
              unAuth={route.auth.unAuth}>

              <route.component
                {...props}
                {...extraProps}
                params={route.params}
                route={route}
              />
            </Auth>
          )
        } else {
          return <route.component
            {...props}
            {...extraProps}
            params={route.params}
            route={route}
          />
        }
      }
    } />
  ))
}

const paramsDefault = {
  routes: undefined,
  extraProps: {},
  switchProps: {},
  location: {},
  modal: {}
}

const renderRoutes = (params=paramsDefault) => {
  const { routes, extraProps, switchProps, location, modal } = params

  let newLocation

  if (modal && modal.isModal && modal.previousLocation) {
    newLocation = modal.previousLocation
  } else {
    newLocation = location
  }

  if (routes) {
    return (
      <Switch {...switchProps} location={newLocation}>
        { mapRouter(routes, extraProps) }
      </Switch>
    )
  } else {
    return null
  }
}

export default renderRoutes
