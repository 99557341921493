import React from 'react'

// Js
import { setStyleValue } from './js/setDivStyle'



const DivElement = (props) => {
  let { name, style, className, classNameActive, active, onClick, mode } = props

  return (
    <div
      onClick={onClick}
      className={`${className || ''} div-element-jsx ${active ? classNameActive : ''} ${active ? 'active-jsx' : ''}`}>

      { props.children }

      <style jsx>{`
        .div-element-jsx {
          ${setStyleValue({ name, style })}
        }

        .div-element-jsx:hover {
          ${setStyleValue({ name: `${name}-hover`, style, mode })}
        }

        .div-element-jsx:active {
          ${setStyleValue({ name: `${name}-active-click`, style, mode })}
        }

        .active-jsx {
          ${setStyleValue({ name: `${name}-active`, style, mode })}
        }

        .div-element-jsx > :global(img) {
          border-radius: ${style[`${name}-border-radius`]};
        }

        @media (min-width: 1500px) {
          .div-element-jsx {
            ${setStyleValue({ name, style, vw: true, mode })}
          }

          .div-element-jsx > :global(img) {
            border-radius: ${style[`${name}-border-radius`]};
          }
        }

        @media (min-width: 601px) and (max-width: 1024px) {
          .div-element-jsx {
            ${setStyleValue({ device: 'tablet', name, style, mode })}
          }
        }

        @media (min-width: 50px) and (max-width: 600px) {
          .div-element-jsx {
            ${setStyleValue({ device: 'mobile', name, style, mode })}
          }
        }
      `}</style>
    </div>
  )
}

export default DivElement
