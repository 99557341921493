import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ClipboardJS from 'clipboard'
import config from '../../../../config'

// Components GLobal
import BarcodeScannerModal from '../../../BarcodeScannerModal'

// redux actions
import { openModal, closeModal, openField, closeField, toastShow } from '../../../../redux/App/AppActions'
import { logout } from '../../../../redux/Auth/AuthActions'

// js
import { convertLinkNew } from './js/linkDisplay'
import { getDataStore } from '../../functions/textEditorEvent'

// utils
import { checkMobileDetect } from '../../../../util/checkMobileDetect'
import { AppContext } from '../../../../containers/websites/Sites/containers/SiteContainer'


const LinkDisplay = (props) => {
  const [outlinkState, setOutlinkState] = useState()

  const app = useContext(AppContext) || {}
  const { openModalState, closeModalState } = app || {}
  const btnCopy = useRef(null)
  const {
    value,
    className,
    apiLink,
    children,
    color,
    hoverTextDecoration,
    hoverColor,
    activeColor,
    apiData,
    templateData,
    dataStore,
    mode,
    linkHistoryPush,
    disabled,
    match,
    location
  } = props

  const style = props.style || {}

  let link

  if (typeof value === 'string') {
    link = value && JSON.parse(value)
  } else {
    link = value ? value : {}
  }

  let type = link && link.type || '',
      inlink = link && link.inlink || '',
      outlink = link && link.outlink || '',
      sidebarId = link && link.sidebarId || '',
      modalId = link && link.modalId || '',
      functionName = link && link.functionName || '',
      options = link && link.options || {},
      inlinkNewTab = link && link.inlinkNewTab === true ? true : undefined,
      blockScrollToTop = link && link.blockScrollToTop === true ? true : undefined,
      closeWindowModal = link && link.closeWindowModal === true ? true : undefined,
      outlinkNewTab = link && link.outlinkNewTab === true ? true : undefined,
      closeWidgitType = link && link.closeWidgitType || '',
      closeWidgitList = link && link.closeWidgitList || '',
      fieldId = link && link.fieldId || '',
      fieldEvent = link && link.fieldEvent || ''

  useEffect(() => {
    if (inlink && (apiData || match)) {
      inlink = convertLinkNew({ link: inlink, apiData, templateData, dataStore, match, location, btn: props.btn })
    }

    if (closeWindowModal && !inlink) {
      inlink = '/'
    }

    if (outlink && (apiData || templateData || match)) {
      outlink = convertLinkNew({ link: outlink, apiData, templateData, dataStore, match, location, btn: props.btn })
      setOutlinkState(outlink)
    }
  }, [
    props.match && props.match.params.lang,
    apiData && JSON.stringify(apiData),
    dataStore && JSON.stringify(dataStore),
    props.location && props.location.search,
  ])


  const InLink = (props) => {
    return (
      <Fragment>
        <span>
          { props.children }
        </span>

        <style jsx>{`
          span {
            color: ${color} !important;
          }
          span:hover {
            color: ${hoverColor} !important;
            text-decoration: ${hoverTextDecoration} !important;
          }
          span:active {
            color: ${activeColor} !important;
          }
        `}</style>
      </Fragment>
    )
  }

  const getShareLink = (type) => {
    if (typeof window !== 'undefined') {
      let linkFull = ''
      const current = window.location.href

      if (type === 'Facebook') {
        linkFull = `https://www.facebook.com/sharer/sharer.php?u=` + current
      } else if (type === 'Twitter') {
        linkFull = `https://twitter.com/share?url=` + current
      } else if (type === 'Messenger') {
        if (checkMobileDetect()) {
          linkFull = `fb-messenger://share/?link=${current}&send-dialog&app_id=${config.app.facebook.appId}`
        } else {
          linkFull = `https://www.facebook.com/v10.0/dialog/send?app_id=${config.app.facebook.appId}&display=popup&link=${current}&redirect_uri=${current}&close`
        }
      } else if (type === 'Line') {
        linkFull = `https://social-plugins.line.me/lineit/share?url=` + current
      } else if (type === 'Pinterest') {
        linkFull = `http://pinterest.com/pin/create/link/?url=` + current
      }

      return linkFull
    }
  }



  useEffect(() => {
    if (btnCopy.current) {
      const clipboard = new ClipboardJS(btnCopy.current)

      clipboard.on('success', (e) => {
        console.log(`${e.action} : ${e.text}`)
        e.clearSelection()
        props.dispatch(toastShow('คัดลอกลิงก์แล้ว'))
      })

      clipboard.on('error', (e) => {
        console.error('Action:', e.action)
        console.error('Trigger:', e.trigger)
      })
    }
  }, [])


  const goto = (url) => {
    const propsModals = props.modals
    const modals = app.modals
    const { history, linkOnClick } = props

    if (props.btn) {
      if (inlink && (apiData || match)) {
        url = convertLinkNew({ link: inlink, apiData, templateData, match, location })
      }
    }


    if (linkOnClick) {
      linkOnClick()
    }

    if (modals.length !== 0 || propsModals.length !== 0) {
      closeWidget()

      setTimeout(() => {
        history.push(url)
      }, 500)

    } else if (closeWindowModal) {
      if (props.closeWindowModal) {
        if (location.search) {
          props.closeWindowModal(null, `${url}${location.search}`)
        } else {
          props.closeWindowModal(null, url)
        }

      } else {
        console.log('closeWindowModal is undefined.')
      }

    } else {
      if (blockScrollToTop) {
        if (location.search) {
          history.push(`${url}${location.search}`)
        } else {
          history.push(url)
        }
      } else {
        history.push(url)
      }
    }

    if (typeof window !== 'undefined') {
      if (!blockScrollToTop && !closeWindowModal) {
        window.scrollTo(0, 0)
      }
    }
  }

  const widgetOpen = (id, type) => {
    if (type === 'sidebar') {
      const params = {
        apiData,
        templateData
      }

      openModalState(id, params)

    } else if (type === 'modal') {
      closeWidgetEvent()

      const params = {
        apiData,
        templateData
      }

      props.dispatch(openModal(id, params))
    }
  }

  const openFunction = (functionName) => {
    if (functionName === 'logout') {
      props.dispatch(logout({
        //loadId: 'signout',
        toastMsg: 'ออกจากระบบแล้ว'
      }))

      closeWidget()

    } else if (functionName === 'barcode-scanner') {
      props.dispatch(openModal('barcode-scanner-modal'))
    }
  }

  const onChangeBarcode = (barcode) => {
    let urlRedirect = style['barcode-url-redirect']

    if (urlRedirect) {
      urlRedirect = urlRedirect.replace(':barcode', barcode)

      goto(urlRedirect)
    }
  }

  const editField = (id, event) => {
    if (event === 'open') {
      props.dispatch(openField(id))
    } else {
      props.dispatch(closeField(id))
    }
  }

  const closeWidget = () => {
    const propsModals = props.modals
    const modals = app.modals

    if (propsModals.length !== 0) {
      propsModals.map(modal => {
        props.dispatch(closeModal(modal.id))
      })
    }

    if (modals.length !== 0) {
      modals.map(modal => {
        closeModalState(modal.id)
      })
    }

    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
  }

  const closeWidgetEvent = () => {
    const propsModals = props.modals
    const modals = app.modals

    // propsModals
    if (closeWidgitType === 'all' && propsModals.length !== 0) {
      propsModals.map(modal => {
        props.dispatch(closeModal(modal.id))
      })
    }

    if (closeWidgitType === 'list' && propsModals.length !== 0) {
      if (closeWidgitList) {
        props.dispatch(closeModal(closeWidgitList))
      }
    }

    // state modals
    if (closeWidgitType === 'all' && modals.length !== 0) {
      modals.map(modal => {
        closeModalState(modal.id)
      })
    }

    if (closeWidgitType === 'list' && modals.length !== 0) {
      if (closeWidgitList) {
        closeModalState(closeWidgitList)
      }
    }
  }

  return (
    <Fragment>
      { useMemo(() =>
        <Fragment>
          { type === 'function' && functionName === 'barcode-scanner' &&
            <BarcodeScannerModal
              id="barcode-scanner-modal"
              onChange={onChangeBarcode}
            />
          }

          { link && link.type && !disabled ?
            <Fragment>
              { type === 'inlink' ?
                <Fragment>
                  { inlinkNewTab ?
                    <Fragment>
                      { mode ?
                        <a className={className}>
                          { children }
                        </a>
                      :
                        <a className={className} href={inlink} target="_blank">
                          { children }
                        </a>
                      }
                    </Fragment>
                  :
                    <Fragment>
                      { mode ?
                        <a className={className}>
                          { children }
                        </a>
                      :
                        <Fragment>
                          { inlink ?
                            <Fragment>
                              { linkHistoryPush ?
                                <div
                                  id="link-history-push"
                                  onClick={() => goto(inlink)}
                                  style={{
                                    cursor: 'pointer',
                                    height: '100%'
                                  }}>

                                  { children }
                                </div>
                              :
                                <a style={{ cursor: 'pointer' }} className={className} onClick={() => goto(inlink)}>
                                  <InLink>
                                    { children }
                                  </InLink>
                                </a>
                              }
                            </Fragment>
                          :
                            children
                          }
                        </Fragment>
                      }
                    </Fragment>
                  }
                </Fragment>
              :
                <Fragment>
                  { mode ?
                    <a className={className}>
                      { children }
                    </a>
                  :
                    <Fragment>
                      { (type === 'outlink' || type === 'sidebar' || type === 'modal' || type === 'function' || type === 'editField') ?
                        <Fragment>
                          { type === 'outlink' && outlinkState &&
                            <a className={className} href={outlinkState} target={outlinkNewTab && '_blank'}>
                              { children }
                            </a>
                          }

                          { type === 'sidebar' && sidebarId &&
                            <a style={{ cursor: 'pointer' }} className={className} onClick={() => widgetOpen(sidebarId, 'sidebar') }>
                              { children }
                            </a>
                          }

                          { type === 'modal' && modalId &&
                            <a style={{ cursor: 'pointer' }} className={className} onClick={() => widgetOpen(modalId, 'modal') }>
                              { children }
                            </a>
                          }

                          { type === 'editField' && fieldId &&
                            <a style={{ cursor: 'pointer' }} className={className} onClick={() => editField(fieldId, fieldEvent) }>
                              { children }
                            </a>
                          }

                          { type === 'function' && functionName && functionName !== 'share' &&
                            <a style={{ cursor: 'pointer' }} className={className} onClick={() => openFunction(functionName) }>
                              { children }
                            </a>
                          }

                          { type === 'function' && functionName && functionName === 'share' &&
                            <Fragment>
                              { options && options.shareType !== 'Copy' &&
                                <a
                                  target="_blank"
                                  style={{ cursor: 'pointer' }}
                                  href={getShareLink(options && options.shareType)}
                                  className={className}>
                                    { children }
                                </a>
                              }

                              { options && options.shareType === 'Copy' && typeof window !== 'undefined' &&
                                <a
                                  data-clipboard-text={window.location.href}
                                  ref={btnCopy}
                                  style={{ cursor: 'pointer' }}
                                  className={className}>

                                  { children }
                                </a>
                              }
                            </Fragment>
                          }


                        </Fragment>
                      :
                        children
                      }
                    </Fragment>
                  }
                </Fragment>
              }

            </Fragment>
          :
            <Fragment>
              { closeWidgitType ?
                <a style={{ cursor: 'pointer' }} onClick={closeWidgetEvent}>
                  { children }
                </a>
              :
                children
              }
            </Fragment>
          }

          <style jsx>{`
            a {
              color: ${color} !important;
            }
            a:hover {
              color: ${hoverColor} !important;
              text-decoration: ${hoverTextDecoration} !important;
            }
            a:active {
              color: ${activeColor} !important;
            }
          `}</style>
        </Fragment>
      , [
        outlinkState,
        props.pathImg,
        props.mode,
        props.location && props.location.search,
        props.match && props.match.params.lang,
        props.style && JSON.stringify(props.style),
        props.apiData && JSON.stringify(props.apiData),
        props.templateData && JSON.stringify(props.templateData),
        props.dataStore && JSON.stringify(props.dataStore),
        props.rerender && JSON.stringify(props.rerender)
      ]) }
    </Fragment>
  )
}

//?afl_code=[+]sprm:afl_code

function mapStateToProps(store, props) {
  return {
    modals: store.app.modals,
    //dataStore: getDataStore({ store, props })
  }
}

export default withRouter(connect(mapStateToProps)(LinkDisplay))
