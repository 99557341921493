import moment from 'moment'
import validations from './validations'

const updateValidationsFn = (values, schemaValidations, event) => {
  let newValidations = {}

  if (event === 'update') {
    values.updated = moment().toISOString()
  }

  for (let key in values) {
    const keys = key.split('.')

    // object
    if (keys.length > 1) {
      let schema

      keys.map((k, i) => {
        if (!schema) {
          schema = schemaValidations[k]
        } else {
          schema = schema[k]
        }
      })

      newValidations[key] = schema

    // single
    } else {
      newValidations[key] = schemaValidations[key]
    }
  }

  const validation = validations(values, newValidations, event)

  return validation
}

export default updateValidationsFn
