import React from 'react'
import { connect } from 'react-redux'

// Components Local
import LoginSocials from './LoginSocials'


const LoginSocialsDisplay = (props) => {
  return (
    <LoginSocials {...props} />
  )
}

export default connect()(LoginSocialsDisplay)
