import React, { Component } from 'react'

// Functions
import { addStyle, removeStyle } from '../../../functions/textEditorFunctions'

// css
import css from '../css/styleSettings.css'


class PaddingSetting extends Component {
  constructor(props) {
    super(props)
    this.state = {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      padding: '0px 0px 0px 0px',
      link: false,
      unit: 'px'
    }
  }

  componentDidMount() {
    const { _this } = this.props
    const { style } = _this.state
    const styleName = this.props.styleName || 'padding'

    this.setValue({
      value: style[styleName],
      submit: false
    })
  }

  setValue = ({ value, submit }) => {
    if (value) {
      const unit = value.search('%') !== -1 ? '%' : 'px'
      const valueSplit = value.split(' ')
      const top = valueSplit[0] ? parseInt(valueSplit[0]) : 0
      const right = valueSplit[1] ? parseInt(valueSplit[1]) : 0
      const bottom = valueSplit[2] ? parseInt(valueSplit[2]) : 0
      const left = valueSplit[3] ? parseInt(valueSplit[3]) : 0

      let padding = `${top}px ${right}px ${bottom}px ${left}px`
      let paddingRem = `${top/15}rem ${right/15}rem ${bottom/15}rem ${left/15}rem`
      let paddingVw = `${top/15}vw ${right/15}vw ${bottom/15}vw ${left/15}vw`

      if (unit === '%') {
        padding = `${top}% ${right}% ${bottom}% ${left}%`
        paddingRem = padding
        paddingVw = padding
      }

      this.setState({ top, right, bottom, left, padding, unit }, () => {
        if (submit) {
          this.addPadding({ padding, paddingRem, paddingVw })
        }
      })
    }
  }

  handleChange = (e, position) => {
    let value = parseInt(e.target.value)
    value = value || 0

    let padding

    if (this.state.link) {
      padding = {
        top: value,
        right: value,
        bottom: value,
        left: value,
      }
    } else {
      padding = {
        [position]: value
      }
    }

    this.setState(padding, () => {
      this.converPadding()
    })
  }

  converPadding = () => {
    const { top, right, bottom, left, unit } = this.state

    let padding = `${top}px ${right}px ${bottom}px ${left}px`
    let paddingRem = `${top/15}rem ${right/15}rem ${bottom/15}rem ${left/15}rem`
    let paddingVw = `${top/15}vw ${right/15}vw ${bottom/15}vw ${left/15}vw`

    if (unit === '%') {
      padding = `${top}% ${right}% ${bottom}% ${left}%`
      paddingRem = padding
      paddingVw = padding
    }

    this.setState({ padding }, () => {
      this.addPadding({ padding, paddingRem, paddingVw })
    })
  }

  setDefaultValue = () => {
    let { defaultValue } = this.props
    const value = defaultValue || '15px 15px 15px 15px'

    this.setValue({ value, submit: true })
  }

  setValueZero = () => {
    const value = '0px 0px 0px 0px'

    this.setValue({ value, submit: true })
  }

  setLink = () => {
    this.setState({ link: !this.state.link })
  }

  addPadding = ({ padding, paddingRem, paddingVw }) => {
    const { _this } = this.props
    const styleName = this.props.styleName || 'padding'

    if (padding) {
      addStyle(_this, {
        styleMultiple: [
          { style: styleName, value: padding },
          { style: `${styleName}-rem`, value: paddingRem },
          { style: `${styleName}-vw`, value: paddingVw }
        ]
      })
    }
  }

  reset = () => {
    const { _this } = this.props
    const styleName = this.props.styleName || 'padding'

    this.setState({ top: 0, right: 0, bottom: 0, left: 0 }, () => {
      removeStyle(_this, null, {
        styleMultiple: [
          { style: styleName },
          { style: `${styleName}-rem`},
          { style: `${styleName}-vw` },

          { style: 'padding' },
          { style: `padding-rem` },
          { style: `padding-vw` }
        ]
      })
    })
  }

  handleUnitChnage = () => {
    let unit

    if (this.state.unit === 'px') {
      unit = '%'
    } else {
      unit = 'px'
    }

    this.setState({ unit }, () => {
      this.converPadding()
    })
  }

  render() {
    const { top, right, bottom, left, link, unit } = this.state

    return (
      <div className={css.paddingForm}>
        <table>
          <tbody>
            <tr>
              <td className="pd-0 font-0-7">ระยะห่าง (padding)</td>
              <td className="pd-0 font-0-7 text-right">
                <a className={css.btn} onClick={this.reset}>รีเซ๊ท</a>
                <a className={css.btn} onClick={this.setDefaultValue}>ค่าเดิม</a>
                <a className={css.btn} onClick={this.setValueZero}>ไม่มี</a>
                <a className={`${css.btn} ${link && css.active}`} onClick={this.setLink}>ลิงก์</a>
                <a className={`${css.btn} ${unit === '%' && css.active}`}  onClick={this.handleUnitChnage}>%</a>
              </td>
            </tr>
          </tbody>
        </table>

        <div>
          <div className={css.boxInput}>
            <table>
              <tbody>
                <tr>
                  <td width="25%" className="pd-0 font-0-8 center">
                    <input
                      value={top}
                      onChange={(e) => this.handleChange(e, 'top')}
                      className={`${css.input} ${css.inputBorderNone} ${css.inputFirst} center`}
                      type="text"
                    />
                  </td>
                  <td width="25%" className="pd-0 font-0-8 center">
                    <input
                      value={left}
                      onChange={(e) => this.handleChange(e, 'left')}
                      className={`${css.input} ${css.inputBorder} center`}
                      type="text"
                    />
                  </td>
                  <td width="25%" className="pd-0 font-0-8 center">
                    <input
                      value={bottom}
                      onChange={(e) => this.handleChange(e, 'bottom')}
                      className={`${css.input} ${css.inputBorder} center`}
                      type="text"
                    />
                  </td>
                  <td width="25%" className="pd-0 font-0-8 center">
                    <input
                      value={right}
                      onChange={(e) => this.handleChange(e, 'right')}
                      className={`${css.input} ${css.inputBorder} ${css.inputLast} center`}
                      type="text"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <table>
            <tbody>
              <tr>
                <td width="25%" className="pd-0 font-0-7 center">บน</td>
                <td width="25%" className="pd-0 font-0-7 center">ซ้าย</td>
                <td width="25%" className="pd-0 font-0-7 center">ล่าง</td>
                <td width="25%" className="pd-0 font-0-7 center">ขวา</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default PaddingSetting
