import React from 'react'

// Components Global
import MenuSidebarJsx from '../../../../../components/MenuSidebarJsx'

// css
import css from './css/menuAndContent.css'


const MenuAndContent = (props) => {
  const { children, menu, color } = props

  return (
    <div className="editor-container-wide mg-top-10">
      <div className={`${css.container} pd-5`}>
        <div className={`${css.sidemenu} bg-fff mg-bottom-20`}>
          <MenuSidebarJsx
            items={menu}
            activeColor={color}
          />
        </div>
        <div className={css.content}>
          <div className={`mg-left-10 mg-bottom-20 ${css.boxContent}`}>
            { children }
          </div>
        </div>
      </div>
    </div>
  )
}

export default MenuAndContent
