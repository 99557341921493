import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// Components Global
import { Row, Column } from '../../../../Columns'
import Pagination from '../../../../Pagination'
import StatePreLoading from '../../../../StatePreLoading/StatePreLoading'
import FetchData from '../../../../FetchData'

// Components Local
import { ItemContentDisplay } from '../ItemContent'

// Components Editor
import { settings as fetchSettings } from '../../../components/FetchApiSettings/js/fetchApi'

// Event
import { getColumnClassName } from '../../../functions/textEditorEvent'

// JS
import { settings } from '../../js/itemListsApi'
import { fetchDatasApi, getGroupId, getDataStore, getDataStoreSearch, getParamsFetchApi } from '../../../components/FetchApiSettings/js/fetchApi'

// Redux Values
import { productValue } from '../../../../../redux/Products/ProductsValues'

// Utils
import { getURLSearchParams } from '../../../../../util/getURLSearchParams'



const ItemListsApi = (props) => {
  const [loading, setLoading] = useState(false)
  const { content, style, match, location, history, items, searchData, action } = props
  const { business } = action.params

  const fetchDataItems = () => {
    const { style } = props
    const { pagination } = settings(style)

    if (pagination !== true) {
      fetchDatasApi(props)
    }
  }

  useEffect(() => {
    fetchDataItems()
  }, [location && location.search])


  let { pagination, search, searchAttributes } = settings(style)
  const { fetchDatas, dbName, body } = getParamsFetchApi(style, props)
  const { where, sort, limit } = body || {}
  const searchParam = getURLSearchParams(location.search)
  const groupId = getGroupId({ props, style })

  if (!searchParam.search) {
    searchAttributes = undefined
  }

  let dbNo

  if (dbName) {
    dbNo = business[`db${dbName}No`]

    if (!dbNo) {
      dbNo = 'not-use-db'
    }
  }

  const fetchAction = {
    dbNo,
    action: fetchDatas,
    id: business._id,
    where,
    countWhere: where,
    limit: limit || 10,
    sort,
    addGroupId: groupId,
    searchAttributes,
    search: true
  }

  const paginationComponent = (
    <Pagination {...props}
      id={content._id}
      match={match}
      history={history}
      location={location}
      fetchAction={fetchAction}
      itemsData={items}
      searchData={searchData}
      itemsPerPage={limit || 10}
      showPage={5}
      stateLoading={{
        setLoading
      }}
      notReloadForUrlChange>

      <ItemsAll {...props} loading={loading} />
    </Pagination>
  )

  return (
    <Fragment>
      { pagination === true ?
        <Fragment>
          { search ?
            <Fragment>
              { searchParam ?
                paginationComponent
              :
                <div>คำค้นหาต้องไม่ว่าง</div>
              }
            </Fragment>
          :
            paginationComponent
          }
        </Fragment>
      :
        <ItemsAll {...props}  />
      }
    </Fragment>
  )
}

const ItemsAll = (props) => {
  let { content, style, items, searchData, loading } = props
  const lang = props.lang || 'local'
  const { amountColumns } = settings(style)
  const { fetchApiCollName } = fetchSettings(style)
  const columnClassName = getColumnClassName(amountColumns)

  if (items && searchData && searchData.status) {
    items = searchData.data
  }

  return (
    <StatePreLoading loading={loading} style={{ position: 'relative' }}>
      <FetchData
        length={items && items.length}
        className="pd-100"
        style={{ position: 'relative' }}
        icon="archive"
        msg="ไม่มีรายการ">

        <Row className="row-dp-flex">
          { items && items.map((item, index) => {
            if (fetchApiCollName === 'products') {
              item = productValue({ product: item })
            }

            item.loopIndex = index

            return (
              <Column key={item._id} col={`column column-jsx ${columnClassName}`}>
                <ItemContentDisplay
                  {...props}
                  apiData={item}
                  groupId={content._id}
                  lang={lang}
                />
              </Column>
            )
          }) }
        </Row>
      </FetchData>
    </StatePreLoading>
  )
}

const mapStateToProps = (store, props) => {
  let items = [], searchData = {}

  const { style } = props
  const { pagination } = settings(style)
  const groupId = getGroupId({ props, style })

  if (pagination === true) {
    const datas = getDataStoreSearch(store, props, groupId)

    items = datas.items
    searchData = datas.searchData

  } else {
    items = getDataStore(store, props, groupId)
  }


  return {
    app: store.app,
    auth: store.auth,
    site: store.sites.data[0],
    folders: store.folders.data,
    items,
    searchData
  }
}


export default withRouter(connect(mapStateToProps)(ItemListsApi))


/*import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// Components Global
import { Row, Column } from '../../../../Columns'
import Pagination from '../../../../Pagination'
import StatePreLoading from '../../../../StatePreLoading/StatePreLoading'
import FetchData from '../../../../FetchData'

// Components Local
import { ItemContentDisplay } from '../ItemContent'

// Components Editor
import { settings as fetchSettings } from '../../../components/FetchApiSettings/js/fetchApi'

// Event
import { getColumnClassName } from '../../../functions/textEditorEvent'

// JS
import { settings } from '../../js/itemListsApi'
import { fetchDatasApi, getGroupId, getDataStore, getDataStoreSearch, getParamsFetchApi } from '../../../components/FetchApiSettings/js/fetchApi'

// Redux Values
import { productValue } from '../../../../../redux/Products/ProductsValues'

// Utils
import { getURLSearchParams } from '../../../../../util/getURLSearchParams'



class ItemListsApi extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  componentDidMount() {
    this.fetchDatas()

    if (this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.fetchDatas()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const propsItems = this.props.items && this.props.items.length
    const nextPropsItems = nextProps.items && nextProps.items.length

    return propsItems !== nextPropsItems ||
           this.props.searchData !== nextProps.searchData ||
           this.props.location.search !== nextProps.location.search ||
           this.state.loading !== nextState.loading
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null)
    }
  }

  fetchDatas = () => {
    const { style } = this.props
    const { pagination } = settings(style)

    if (pagination !== true) {
      fetchDatasApi(this.props)
    }
  }

  setLoading = (loading) => {
    this.setState({ loading })
  }


  render() {
    const { content, style, match, location, history, items, searchData, action } = this.props
    const { business } = action.params
    let { pagination, search, searchAttributes } = settings(style)
    const { fetchDatas, dbName, body } = getParamsFetchApi(style, this.props)
    const { where, sort, limit } = body || {}
    const searchParam = getURLSearchParams(location.search)
    const groupId = getGroupId({ props: this.props, style })

    if (!searchParam.search) {
      searchAttributes = undefined
    }

    let dbNo

    if (dbName) {
      dbNo = business[`db${dbName}No`]

      if (!dbNo) {
        dbNo = 'not-use-db'
      }
    }

    const fetchAction = {
      dbNo,
      action: fetchDatas,
      id: business._id,
      where,
      countWhere: where,
      limit: limit || 10,
      sort,
      addGroupId: groupId,
      searchAttributes,
      search: true
    }

    const paginationComponent = (
      <Pagination {...this.props}
        id={content._id}
        match={match}
        history={history}
        location={location}
        fetchAction={fetchAction}
        itemsData={items}
        searchData={searchData}
        itemsPerPage={limit || 10}
        showPage={5}
        stateLoading={{
          setLoading: this.setLoading
        }}
        notReloadForUrlChange>

        <ItemsAll {...this.props} loading={this.state.loading} />
      </Pagination>
    )

    return (
      <Fragment>
        { pagination === true ?
          <Fragment>
            { search ?
              <Fragment>
                { searchParam ?
                  paginationComponent
                :
                  <div>คำค้นหาต้องไม่ว่าง</div>
                }
              </Fragment>
            :
              paginationComponent
            }
          </Fragment>
        :
          <ItemsAll {...this.props}  />
        }
      </Fragment>
    )
  }
}

const ItemsAll = (props) => {
  let { content, style, items, searchData, loading } = props
  const lang = props.lang || 'local'
  const { amountColumns } = settings(style)
  const { fetchApiCollName } = fetchSettings(style)
  const columnClassName = getColumnClassName(amountColumns)

  if (items && searchData && searchData.status) {
    items = searchData.data
  }

  return (
    <StatePreLoading loading={loading} style={{ position: 'relative' }}>
      <FetchData
        length={items && items.length}
        className="pd-100"
        style={{ position: 'relative' }}
        icon="archive"
        msg="ไม่มีรายการ">

        <Row className="row-dp-flex">
          { items && items.map((item, index) => {
            if (fetchApiCollName === 'products') {
              item = productValue({ product: item })
            }

            item.loopIndex = index

            return (
              <Column key={item._id} col={`column column-jsx ${columnClassName}`}>
                <ItemContentDisplay
                  {...props}
                  apiData={item}
                  groupId={content._id}
                  lang={lang}
                />
              </Column>
            )
          }) }
        </Row>
      </FetchData>
    </StatePreLoading>
  )
}

const mapStateToProps = (store, props) => {
  let items = [], searchData = {}

  const { style } = props
  const { pagination } = settings(style)
  const groupId = getGroupId({ props, style })

  if (pagination === true) {
    const datas = getDataStoreSearch(store, props, groupId)

    items = datas.items
    searchData = datas.searchData

  } else {
    items = getDataStore(store, props, groupId)
  }


  return {
    app: store.app,
    auth: store.auth,
    site: store.sites.data[0],
    folders: store.folders.data,
    items,
    searchData
  }
}


export default withRouter(connect(mapStateToProps)(ItemListsApi))*/
