import React, { Component } from 'react'
import cuid from 'cuid'

import { Row, Column } from '../../Columns'

// css
import css from './css/radioGroup.css'


class RadioGroup extends Component {
  constructor(props) {
    super(props)

    const id = this.props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, '')
    const randomId = cuid()

    this.state = {
      id: randomId+id,
      value: this.props.value ? this.props.value : '',
      active: this.props.value ? true : false
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value })

      this.setIndex(this.props.value)
    }

    if (this.props.radios.length !== prevProps.radios.length) {
      this.setState({ value: '' }, () => {
        this.selectFirstChoice()
      })
    }
  }

  componentDidMount() {
    document.getElementById(this.state.id).value = this.state.value

    this.setIndex(this.state.value)
    this.selectFirstChoice()

    if (this.props.getFirstValue) {
      this.props.getFirstValue(this.state.value, this.props.id)
    }
  }

  selectFirstChoice = () => {
    const { selectFirstChoice, radios } = this.props

    if (selectFirstChoice) {
      const firstChoice = radios && radios[0]

      if (this.state.value === "") {
        if (firstChoice && firstChoice.value) {
          const e = {
            target: { value: firstChoice.value  }
          }

          this.handleChange(e, 0)
        }
      }
    }
  }

  setIndex = (value) => {
    const radios = this.props.radios
    const index = radios.findIndex(radio => radio.value == value)
    this.setState({ index }, () => {
      if (this.props.getValue) {
        const value = radios[index] && radios[index].value

        this.props.getValue(value, this.props.id)
      }
    })
  }

  handleChange = (e, key) => {
    if (!this.props.notChange) {
      this.setState({
        index: key,
        value: e.target.value,
        active: e.target.value ? true : false
      })

      document.getElementById(this.state.id).value = e.target.value
    }

    if (this.props.onChange) {
      this.props.onChange(e.target.value, this.props.id)
    }

    if (this.props.getValue) {
      this.props.getValue(e.target.value, this.props.id)
    }
  }

  render() {
    const { index } = this.state
    let { label, radios, required, styleLable, styleLabel } = this.props

    styleLabel = styleLable || styleLabel
    styleLabel = styleLabel || {}


    return (
      <div className={this.props.className} style={this.props.style}>

        { label &&
          <label style={{ fontSize: '0.9rem', marginBottom: '10px', display: 'block', color: this.state.active && '#4CAF50' }}>
            { label }

            { required && <span className="line-height-0 color-red font-1-2"> *</span>}
          </label>
        }

        <input
          className={(this.state.value && this.state.value.length === 0 && required) ? 'required invalid' : null}
          id={this.state.id}
          data-id={this.props.id}
          ref={this.state.id}
          data-not-save={this.props.notSave}
          type="hidden"
        />

        <Row className={this.props.classNameRadios}>
          { radios.map((radio, key) => {

            const style = {
              padding: '0',
              borderRadius: '5px',
              marginBottom: '5px',
            }

            return (
              <Column style={style} col={radio.column} key={`radio-${this.state.id}-${key}`}>
                <input
                  className="with-gap"
                  name={this.state.id}
                  type="radio"
                  value={radio.value}
                  onChange={(e) => this.handleChange(e, key)}
                  id={`radio-${this.state.id}-${key}`}
                  checked={ this.state.value === radio.value ? true : false }
                  disabled={this.props.disabled}
                />

                { radio.label &&
                  <label style={styleLabel} className={`${this.props.size}`} htmlFor={`radio-${this.state.id}-${key}`}>
                    { radio.label}
                  </label>
                }

                { radio.labelComponent &&
                  <label style={styleLabel} className={css.labelComponent} htmlFor={`radio-${this.state.id}-${key}`}>
                    { radio.labelComponent }
                  </label>
                }

                { !this.props.childExternal && this.state.value === radio.value && radio.child &&
                  <div style={!radio.childRemoveStyle ? { padding: '10px 5px 0px 5px', width: '100%', display: 'inline-block' } : {}}>
                    { radio.child }
                  </div>
                }
              </Column>
            )
          }) }
        </Row>

        { this.props.childExternal &&
          <div className="pd-top-15">
            { radios[index] && radios[index].child }
          </div>
        }
      </div>
    )
  }
}



export default RadioGroup
