import React, { Fragment } from 'react'

// Components Global
import LayoutTabs from '../../../LayoutTabs'

// css
import css from './css/boxSettingsSideBar.css'


const BoxSettingsSideBar = (props) => {
  let { items, sidebar } = props

  if (!items) {
    items = [
      {
        id: 'content',
        name: 'เนื้อหา',
        icon: 'notes',
        disabled: !props.content
      },
      {
        id: 'style',
        name: 'สไตล์',
        icon: 'palette',
        disabled: !props.style
      },
      {
        id: 'api',
        name: 'API',
        icon: 'code',
        disabled: !props.api
      },
      {
        id: 'advance',
        name: 'ขั้นสูง',
        icon: 'settings',
        disabled: false
      }
    ]
  }

  const activeId = props.activeId || 'content'

  return (
    <div className={css.boxSettings}>
      { sidebar ?
        <div className={css.titleAndClose}>
          <table>
            <tbody>
              <tr>
                <td width="40" className="pd-0">
                  <a className="btn btn-xxs btn-delete btn-shadow-none" onClick={sidebar && sidebar.close}>
                    <i className="material-icons color-fff">close</i>
                  </a>
                </td>
                <td className="pd-0 font-0-9 color-000 center">
                  <div style={{ marginLeft: '-40px' }}>{ props.title }</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      :
        <div className={css.title}>
          { props.title }
        </div>
      }

      <div className={sidebar ? css.bodyAndClose : css.body}>
        <LayoutTabs items={items} activeId={activeId}>
          { items.map((item, i) => {
            return (
              <Fragment key={i}>
                { props.children[i] }
              </Fragment>
            )
          }) }
        </LayoutTabs>
      </div>
    </div>
  )
}

export default BoxSettingsSideBar
