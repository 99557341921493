import React from 'react'

// Components Text Editor
import BoxSettings from '../../BoxSettings'
import {
  DisplaySetting,
  OpacitySetting,
  WidthSetting,
  HeightSetting,
  LineHeightSetting,
  FontSetting,
  FontSizeSetting,
  MarginSetting,
  PaddingSetting,
  BackgroundColorSetting,
  BorderSetting,
  BoxShadowSetting,
  BorderRadiusSetting,
  ColorSetting,
  LineClampSetting,
  FloatSetting,
  AbsoluteSetting,
  TextAlignSetting
} from '../../StyleSettings'


const InputStyle = (props) => {
  const { _this, title, icon, name, event } = props
  const hidden = props.hidden || {}

  let settings = [
    {
      hidden: (hidden.margin || event === 'focus' || event === 'valid' || event === 'invalid') ? true : false,
      component: <MarginSetting _this={_this} styleName={`${name}-margin`} defaultValue="0px 0px 0px 0px" />
    },
    {
      hidden: (hidden.padding || event === 'focus' || event === 'valid' || event === 'invalid') ? true : false,
      component: <PaddingSetting _this={_this} styleName={`${name}-padding`} defaultValue="10px 20px 10px 20px" />
    },
    {
      hidden: false,
      component: <BackgroundColorSetting _this={_this} label="สีพื้นหลัง" styleName={`${name}-background-color`} textColorName="color" />
    },
    {
      hidden: (hidden.width || event === 'focus' || event === 'valid' || event === 'invalid') ? true : false,
      component: <WidthSetting _this={_this} styleName={`${name}-width`} />
    },
    {
      hidden: (hidden.height || event === 'focus' || event === 'valid' || event === 'invalid') ? true : false,
      component: <HeightSetting _this={_this} styleName={`${name}-height`} />
    },
    {
      hidden: (hidden.lineHeight || event === 'focus' || event === 'valid' || event === 'invalid') ? true : false,
      component: <LineHeightSetting _this={_this} styleName={`${name}-line-height`} />
    },
    {
      hidden: (hidden.font || event === 'focus' || event === 'valid' || event === 'invalid') ? true : false,
      component: <FontSetting _this={_this} styleName={`${name}`} />
    },
    {
      hidden: hidden.border ? true : false,
      component: <BorderSetting _this={_this} styleName={`${name}-border`} />
    },
    {
      hidden: hidden.borderRadius ? true : false,
      component: <BorderRadiusSetting _this={_this} styleName={`${name}-border-radius`} />
    },
    {
      hidden: hidden.boxShadow ? true : false,
      component: <BoxShadowSetting _this={_this} styleName={`${name}-box-shadow`} />
    },
    {
      hidden: (hidden.textAlign || event === 'focus' || event === 'valid' || event === 'invalid') ? true : false,
      component: <TextAlignSetting _this={_this} styleName={`${name}-text-align`} />
    },
  ]

  settings = settings.filter(setting => setting.hidden !== true)

  return (
    <BoxSettings title={title} icon={icon}>

      { settings.map((setting, i) => {
        return (
          <div key={i} className={`pd-10 ${i%2 === 1 ? 'bg-fbfbfb' : '' }`}>
            { setting.component }
          </div>
        )
      }) }

    </BoxSettings>
  )
}

export default InputStyle
