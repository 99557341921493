// Redux Actions
import { openConfirm } from '../App/AppActions'
import { updateProduct, removeProduct, uploadImagesProduct } from './ProductsActions'


const title = "รายการ"


export const handleSwitchChange = ({ props, params, toastMsg }) => {
  return (id) => {
    const { business } = props
    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businessType: business.type,
      businesses_id: business._id,
      products_id: id
    }

    props.dispatch(updateProduct(query, {
      body: params,
      toastMsg,
      loadId: id,
      confirmId: id
    }))
  }
}

export const handleItemSwitchChange = ({ props, params, toastMsg }) => {
  return (id) => {
    const { business, product } = props
    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businessType: business.type,
      businesses_id: business._id,
      products_id: product._id
    }

    params.updateType = 'set'
    params.updateElement = 'items'
    params.updateId = id


    props.dispatch(updateProduct(query, {
      body: params,
      toastMsg,
      loadId: id,
      confirmId: id
    }))
  }
}

// Main
const handleChangeStatus = (props, status, toastMsg) => {
  return (id) => {
    const { business } = props
    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businessType: business.type,
      businesses_id: business._id,
      products_id: id
    }

    props.dispatch(updateProduct(query, {
      body: { status: status },
      toastMsg: toastMsg,
      loadId: id,
      confirmId: id
    }))
  }
}

const handleDelete = ({ props, toastMsg, removeRef, removeTour }) => {
  return (id) => {
    const { business } = props
    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businessType: business.type,
      businesses_id: business._id,
      products_id: id
    }

    props.dispatch(removeProduct(query, {
      body: removeRef,
      toastMsg: toastMsg,
      loadId: id,
      confirmId: id
    })).then(res => {
      if (res.success && removeTour) {
        removeTour()
      }
    })
  }
}

export const onEditModal = (id) => {
  return {
    modal: { id }
  }
}

export const onEdit = (url) => {
  return { url }
}

export const onView = (url) => {
  return { url }
}

export const onTrash = (props, id) => {
  return {
    label: `ลบ${title}`,
    onConfirm: handleChangeStatus(props, 'trash', `ลบ${title}นี้แล้ว`)
  }
}

export const onDelete = (props, removeTour) => {
  const toastMsg = `ลบ${title}นี้ อย่างถาวรแล้ว`

  return {
    label: `ลบ${title}`,
    onConfirm: handleDelete({ props, toastMsg, removeTour })
  }
}

export const onDeleteProduct = (props, removeTour) => {
  const toastMsg = `ลบ${title}นี้ อย่างถาวรแล้ว`

  return {
    label: `ลบ${title}`,
    onConfirm: handleDelete({ props, toastMsg, removeTour })
  }
}

export const onDeleteRef = (props, _id) => {
  const removeRef =  {
    removeType: 'remove-ref',
    collName: 'products',
    filterElement: 'group_products_id',
  }

  const toastMsg = `ลบ${title}นี้ อย่างถาวรแล้ว`

  return {
    label: `ลบ${title}`,
    onConfirm: handleDelete({ props, toastMsg, removeRef })
  }
}

export const onRestore = (props) => {
  return {
    label: `กู้คืน${title}`,
    onConfirm: handleChangeStatus(props, 'open', `กู้คืน${title}แล้ว`)
  }
}

export const onSwitchOpen = (props) => {
  return {
    label: `เปิดการใช้งาน${title}นี้`,
    onConfirm: handleChangeStatus(props, 'open', `เปิดการใช้งาน${title}นี้แล้ว`)
  }
}

export const onSwitchClose = (props) => {
  return {
    label: `ปิดการใช้งาน${title}นี้`,
    onConfirm: handleChangeStatus(props, 'close', `ปิดการใช้งาน${title}นี้แล้ว`)
  }
}



// Items
const handleDeleteItem = (props, toastMsg) => {
  return (id) => {
    const { business, product } = props
    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businesses_id: business._id,
      products_id: product._id
    }

    props.dispatch(updateProduct(query, {
      body: {
        scenario: 'remove-item',
        updateType: 'pull-element',
        updateElement: 'items',
        pull: {
          element: '_id',
          value: id
        }
      },
      toastMsg: toastMsg,
      loadId: id,
      confirmId: id
    }))
  }
}

export const onDeleteItem = (props) => {
  let title = "ตัวเลือกสินค้า"

  return {
    label: `ลบ${title}`,
    onConfirm: handleDeleteItem(props, `ลบ${title}นี้ อย่างถาวรแล้ว`)
  }
}



// Images Main
export const uploadImage = (props) => {
  return (id, files, album) => {
    if (files) {
      const { business, product } = props
      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businesses_id: business._id,
        products_id: product._id
      }

      const fileParams = {
        files,
        imagePrefix: 'products'
      }

      props.dispatch(uploadImagesProduct(query, fileParams, {
        body: {
          updateType: 'push',
          updateElement: 'images',
          album: album,
          profile: album === 'profile' ? true : false,
          cover: album === 'cover' ? true : false,
        },
        loadId: id,
        modalId: id,
        progressId: id
      }))
    }
  }
}

export const onDeleteImage = (props) => {
  return (id) => {
    const { business, product } = props
    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businesses_id: business._id,
      products_id: product._id
    }

    props.dispatch(updateProduct(query, {
      body: {
        updateType: 'pull-image',
        updateElement: 'images',
        deleteId: id
      },
      toastMsg: `ลบภาพนี้แล้ว`,
      loadId: id,
      confirmId: id,
      props: props
    }))
  }
}


// Images Items
export const uploadItemImage = (props) => {
  return (id, files, album) => {
    if (files) {
      const { business, product, dispatch } = props
      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businesses_id: business._id,
        products_id: product._id
      }

      const fileParams = {
        files,
        imagePrefix: 'product-items'
      }

      dispatch(uploadImagesProduct(query, fileParams, {
        body: {
          updateType: 'push-sub-element',
          updateElement: 'items',
          updateElementSub: 'items.$.images',
          updateId: props.item._id,
          album: album,
          profile: album === 'profile' ? true : false
        },
        loadId: id,
        modalId: id,
        progressId: id
      }))
    }
  }
}

export const onDeleteItemImage = (props) => {
  return (id) => {
    const { business, product } = props
    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businesses_id: business._id,
      products_id: product._id
    }

    props.dispatch(updateProduct(query, {
      body: {
        updateType: 'pull-sub-image',
        updateElement: 'items.images',
        updateElementSub: 'items.$.images',
        updateId: props.item._id,
        pullSub: {
          element: '_id',
          value: id
        }
      },
      toastMsg: `ลบภาพนี้แล้ว`,
      loadId: id,
      confirmId: id,
      props: props
    }))
  }
}


export const convertItemsToArray = ({ items, parents_id=null }) => {
  const parents = items.filter(item => item.items_id === parents_id)

  parents.map(parent => {
    if (parent.itemChoice === 'multiple') {
      parent.childrens = convertItemsToArray({ items, parents_id: parent._id })
    }
  })

  return parents
}


const getLoopItem = ({ items, items_id, parents, choiceName }) => {
  items = items || []

	const item = items.filter(item => item._id === items_id)[0]

	if (item) {
    if (choiceName) {
      item.choiceName = choiceName
    }

		parents.push(item)
	}

	if (item && item.items_id) {
		getLoopItem({
      items,
      items_id: item.items_id,
      parents,
      choiceName: item.subitemChoiceName
    })
	}

	return parents
}

export const getItemParents = ({ items, items_id, reorder, choiceName }) => {
	let parents = []
  parents = getLoopItem({ items, items_id, parents, choiceName })

  if (reorder) {
    let length = parents.length
    let newParent = []

    for (let i=length-1; i>=0; i--) {
      newParent.push(parents[i])
    }

    return newParent
  } else {
    return parents
  }
}

export const getItemImage = ({ product, item }) => {
  let images

  if (item && item.images && item.images.length !== 0) {
    images = item.images
  } else {
    const parents = getItemParents({
      items: product && product.items,
      items_id: item && item.items_id
    })

    for (let i = 0; i < parents.length; i++) {
      const parent = parents[i]

      if (parent && parent.images && parent.images.length !== 0) {
        images = parent.images
        break
      }
    }

    if (!images) {
      images = product && product.images || []
    }
  }

  return images
}



//onDeleteMutiple
export const onDeleteProductsMutiple = async ({ props }) => {
  const removeProductFn = async ({ props, products_id, actionId, toastMsg }) => {
    const { business } = props

    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businesses_id: business._id,
      businessType: business.type,
      products_id
    }

    await props.dispatch(removeProduct(query, {
      toastMsg,
      loadId: actionId,
      confirmId: actionId,
      props
    }))
  }

  const actionId = "onDeleteProductsMutiple"

  props.dispatch(openConfirm(actionId, {
    type: 'delete',
    title: `ลบสินค้า (${props.listSelectors.length}) รายการ อย่างถาวร`,
    message: `กดปุ่ม "ลบสินค้า" ถ้าคุณต้องการลบสินค้านี้`,
    icon: 'delete',
    confirmLabel: 'ลบสินค้า',
    cancelLabel: 'ยกเลิก',
    onConfirm: async () => {
      await Promise.all(props.listSelectors.map(async list => {
        await removeProductFn({
          props,
          products_id: list.value._id,
          actionId,
          toastMsg: 'ลบสินค้าแล้ว'
        })
      }))
    }
  }))
}

export const onTrashProductsMutiple = ({ props }) => {
  const trashProductFn = async ({ props, products_id, actionId, toastMsg }) => {
    const { business } = props

    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businesses_id: business._id,
      businessType: business.type,
      products_id
    }

    await props.dispatch(updateProduct(query, {
      body: { status: 'trash' },
      toastMsg: toastMsg,
      loadId: actionId,
      confirmId: actionId
    }))
  }

  const actionId = "onTrashProductsMutiple"

  props.dispatch(openConfirm(actionId, {
    type: 'delete',
    title: `ลบสินค้า (${props.listSelectors.length}) รายการ`,
    message: `กดปุ่ม "ลบสินค้า" ถ้าคุณต้องการลบสินค้านี้`,
    icon: 'delete',
    confirmLabel: 'ลบสินค้า',
    cancelLabel: 'ยกเลิก',
    onConfirm: async () => {
      await Promise.all(props.listSelectors.map(async list => {
        await trashProductFn({
          props,
          products_id: list.value._id,
          actionId,
          toastMsg: 'ลบสินค้าแล้ว'
        })
      }))
    }
  }))
}
