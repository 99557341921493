import { fetchDatas, fetchData, createData, updateData, updateDataAll, removeData, fetchApi } from '../reduxActions'
import { generateTypeName, generateTypeFunctions } from '../reduxTypes'

import uploadsForS3 from '../../util/uploadsForS3'
import { checkAppActions } from '../App/AppActions'

// Export Constants
const NAME_TYPE = ['PAGES', 'PAGE']
const name = "pages"

// Type Names and Type Functions
export const { ADDS, ADDS_MORE, ADD, UPDATE, REMOVE } = generateTypeName(NAME_TYPE)
export const { adds, adds_more, add, update, remove } = generateTypeFunctions(NAME_TYPE)


// Export Actions
export const checkValueDuplicate = (params = {}) => dispatch => {
  return fetchApi({
    dispatch,
    params,
    api: `${name}-query/check-value-duplicate?${params.query}`
  })
}

export const fetchPagesDisplay = (businesses_id, sites_id, params = {}) => dispatch => {
  return fetchDatas({
    dispatch,
    params,
    api: `${name}-display/${businesses_id}/${sites_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchPages = (businesses_id, sites_id, params = {}) => dispatch => {
  let api = `${name}/${businesses_id}`

  if (sites_id) {
    api = `${api}/${sites_id}`
  }

  return fetchDatas({
    dispatch,
    params,
    api,
    add: adds,
    addMore: adds_more
  })
}

export const fetchPage = (businesses_id, _id, params = {}) => dispatch => {
  return fetchData({
    dispatch,
    params,
    api: `${name}/${businesses_id}/${_id}`,
    add: add
  })
}

export const createPage = (businesses_id, sites_id, params) => dispatch => {
  return createData({
    dispatch,
    params,
    api: `${name}/${businesses_id}/${sites_id}/create`,
    add: add,
    order: 'last'
  })
}

export const updatePage = (businesses_id, _id, params) => dispatch => {
  return updateData({
    dispatch,
    params,
    api: `${name}/${businesses_id}/${_id}`,
    update: update,
    updateId: _id
  })
}

export const updatePagesAll = (businesses_id, sites_id, params) => dispatch => {
  return updateDataAll({
    dispatch,
    params,
    api: `pages-update-all/${businesses_id}/${sites_id}`,
    add: adds
  })
}

export const uploadImagesPage = (businesses_id, _id, files, prefix, params) => dispatch => {
  checkAppActions(dispatch, params, 'start')

  return new Promise(resolve => {
    uploadsForS3(files, prefix, params.progressId, dispatch).then(val => {
      val.map(paths => {
        params.body.paths = paths

        return dispatch(updatePage(businesses_id, _id, params)).then(res => {
          if (res.success) {
            res.paths = paths
          }

          resolve(res)
        })
      })
    })
  })
}

export const removePage = (businesses_id, _id, params) => dispatch => {
  return removeData({
    dispatch,
    params,
    api: `${name}/${businesses_id}/${_id}`,
    remove: remove,
    deleteId: _id
  })
}
