import { customerValue } from "../Customers/CustomersValues"

export const userValue = ({ user, lang='local' }) => {
  let firstNameLang, lastNameLang, name, nameLang, avatarName

  // gernaral
  if (user) {
    firstNameLang = user.firstName ? user.firstName[lang] : null
    lastNameLang = user.lastName ? user.lastName[lang] : null
    name = `${firstNameLang} ${lastNameLang}`
    nameLang = `${firstNameLang} ${lastNameLang}`
    avatarName = [firstNameLang, lastNameLang]

    return {
      ...user,
      firstNameLang,
      lastNameLang,
      nameLang,
      avatarName
    }
  }
}

export const authValue = ({ auth, lang='local' }) => {
  if (auth && auth.user && auth.user.customer) {
    auth.user.customer = customerValue({ customer: auth.user.customer, lang })
  }

  return auth
}
