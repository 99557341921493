import moment from 'moment'
import { discountFn, setLangValueFn, srcFn, vatFn, whtFn } from '../../../../common/functions/schemas'
import getIndexable from '../../functions/getIndexable'
import { items, payment } from '../Orders/OrdersDBSchemaShare'

const validations = {
  businesses_id : {
    type: String,
    required: true
  },
  orders_id : {
    type: String,
    default: null
  },
  orders_installments_id: {
    type: String,
    default: null
  },
  customers_id: {
    type: String,
    default: null
  },
  customer: {
    type: Object,
    default: null
  },
  admins_id: {
    type: String,
    default: null
  },
  admin: {
    type: Object,
    default: null
  },
  startDate: { type: String, default: () => moment().toISOString() },
  dueDate: { type: String, default: () => moment().toISOString() },
  createSystemBy: { type: String, default: 'pos', enum: ['pos', 'sell-online', 'bill'] },
  status: {
    type: String,
    default: 'draft',
    enum: ['draft', 'approved', 'completed']
  },
  type: {
    type: String,
    required: true,
    enum: ['quotation', 'invoice', 'receipt', 'taxInvoice']
  },
  seq: { type: Number },
  code: { type: String },

  projectName: setLangValueFn(),
  refNo: { type: String },

  note: setLangValueFn(),
  noteCustomer: setLangValueFn(),
  notePrivate: setLangValueFn(),

  stamp: { type: Boolean, default: false },
  electronicSignature: { type: Boolean, default: false },

  items: items(),
  discount: discountFn(),
  src: srcFn(),
  vat: vatFn(),
  wht: whtFn(),

  payment: payment(),
  installmentNo: { type: Number, default: 1 },

  vatInclude: { type: Boolean, default: false },
  totalAmountProduct: { type: Number, default: 0 },
  totalNetAmountProduct: { type: Number, default: 0 },
  totalNetAmount: { type: Number, default: 0 },
  paymentNetAmount: { type: Number, default: 0 },

  shipping: { type: Boolean, default: false },
  shippingAddress: { type: Object, default: null },
}

export default {
  indexable: getIndexable(validations),
  validations
}
