import { fetchDatas, fetchData, createData, updateData, removeData, fetchApi } from '../reduxActions'
import { generateTypeName, generateTypeFunctions, getTypePath } from '../reduxTypes'

import callApi from '../../util/apiCaller'
import uploadsForS3 from '../../util/uploadsForS3'
import { checkAppActions } from '../App/AppActions'

// Export Constants
const NAME_TYPE = ['HOLD_ORDERS', 'HOLD_ORDER']
const name = "hold-orders"

// Type Names and Type Functions
export const { ADDS, ADDS_MORE, ADD, UPDATE, REMOVE } = generateTypeName(NAME_TYPE)
export const { adds, adds_more, add, update, remove } = generateTypeFunctions(NAME_TYPE)


// Export Actions
export const fetchHoldOrders = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return fetchDatas({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchHoldOrder = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id, orders_id } = query
  const path = getTypePath(businessType)

  return fetchData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/${orders_id}`,
    add: add
  })
}

export const createHoldOrder = (query, params) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return createData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/create`,
    add: add
  })
}

export const updateHoldOrder = (query, params) => dispatch => {
  const { authName, dbNo, businessType, businesses_id, orders_id, _id } = query
  const path = getTypePath(businessType)

  return updateData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/${_id || orders_id}`,
    update: update,
    updateId: _id || orders_id
  })
}

export const removeHoldOrder = (query, params) => dispatch => {
  const { authName, dbNo, businessType, businesses_id, orders_id, _id } = query
  const path = getTypePath(businessType)

  return removeData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/${_id || orders_id}`,
    remove: remove,
    deleteId: _id || orders_id
  })
}

export const countHoldOrders = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return fetchApi({
    dispatch,
    params,
    api: `${path}${name}-count/${authName}/${dbNo}/${businesses_id}`,
  })
}
