import React, { Component } from 'react'

// Functions
import { addStyle, removeStyle } from '../../../../functions/textEditorFunctions'

// values
import { fontSizeLists } from '../../../../functions/textEditorValues'

// css
import css from '../../css/styleSettings.css'



class FontSizeSetting extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  fonSizeChange = (e) => {
    const { _this, styleName } = this.props

    if (styleName) {
      const value = parseInt(e.target.value)

      let valuePx = `${value}px`,
          valueRem = `${value/15}rem`,
          valueVw = `${value/15}vw`

      addStyle(_this, {
        styleMultiple: [
          { style: styleName, value: valuePx },
          { style: `${styleName}-rem`, value: valueRem },
          { style: `${styleName}-vw`, value: valueVw }
        ]
      })
    }
  }

  render() {
    const { _this, styleName, label } = this.props
    const { style } = _this.state


    let minLevel = fontSizeLists[0].level
    let maxLevel = fontSizeLists[fontSizeLists.length-1].level

    if (this.props.minLevel) {
      minLevel = parseInt(this.props.minLevel)
    }

    if (this.props.maxLevel) {
      maxLevel = parseInt(this.props.maxLevel)
    }

    let fontSize

    if (styleName) {
      fontSize = style && parseInt(style[styleName])
    }

    return (
      <select
        className={`browser-default ${css.input} ${css.select}`}
        onChange={this.fonSizeChange}
        value={fontSize || '0'}>

        <option value="0">{ label ||'ขนาด' }</option>

        { fontSizeLists.map((font, i) => {
          if (font.level >= minLevel && font.level <= maxLevel) {
            return (
              <option key={i} value={font.level}>
                { font.level }
              </option>
            )
          }
        }) }
      </select>
    )
  }
}

export default FontSizeSetting
