import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Global
import ModalFixed from '../../../../../Modals/ModalFixed'
import ContentPhotos from '../../../../../ContentPhotos'
import { Row, Column } from '../../../../../Columns'
import BtnLoading from '../../../../../button/BtnLoading'
import { BtnModalClose } from '../../../../../Modals/BtnModal'

// Components Sites
import SitePhotos from '../../../../../../containers/dashboard/Sites/components/SitePhotos'


// Actions
import { addLoad, removeLoad, closeModal } from '../../../../../../redux/App/AppActions'


class ModalGallerySelectImage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      images: []
    }
  }

  getValue = (value) => {
    this.setState({ select: value })
  }

  /*fnStartModal = () => {
    const { action, gallery, galleryPaths } = this.props

    const images = action.params.data.images
    const newImages = []

    if (gallery) {
      images.map(img => {
        const imgFilter = galleryPaths.filter(path => path.s === img.paths.s)[0]

        if (!imgFilter) {
          newImages.push(img)
        }
      })

      this.setState({ images: newImages })
    } else {
      this.setState({ images })
    }
  }*/

  handleSubmit = () => {
    this.props.dispatch(addLoad(this.props.id))
    this.props.selectImage(this.state.select)

    setTimeout(() => {
      this.props.dispatch(removeLoad(this.props.id))
      this.props.dispatch(closeModal(this.props.id))
    }, 300)
  }

  fnCloseModal = () => {
    if (this.props.focus)
      this.props.focus()
  }

  render() {
    const idModal = this.props.id
    const { match, action, albumImgAllOny } = this.props
    const { site, business } = action.params
    const { sites_id } = match ? match.params : {}

    return (
      <ModalFixed
        blockBgModal
        id={idModal}
        title="เลือกรูปภาพ"
        width="900px"
        padding="0"
        headerClassName="height-50"
        bodyClassName="top-50 pd-0"
        eventModalClose={this.fnCloseModal}>

        <div>
          { sites_id ?
            <div className="border-1 border-efefef border-radius-5">
              <SitePhotos
                sites_id={sites_id}
                business={business}
                getValue={this.getValue}
                select={(this.props.img || this.props.cover)}
                selectMultiple={this.props.gallery}
                galleryPaths={this.props.galleryPaths}
              />
            </div>
          :
            <div className="border-1 border-efefef border-radius-5">
              <ContentPhotos
                albumImgAllOny={albumImgAllOny}
                action={action}
                business={business}
                getValue={this.getValue}
                select={(this.props.img || this.props.cover)}
                selectMultiple={this.props.gallery}
                galleryPaths={this.props.galleryPaths}
              />
            </div>
          }
        </div>

        <div>
          <Row>
            <Column col="column col-xxs-6 col-xs-6 text-align-left">
              <BtnModalClose id={idModal}>
                <a onClick={this.fnCloseModal} className="waves-effect btn btn-flat btn-border waves-light font-1-0 color-000">
                  <i className="material-icons left">close</i> ออก
                </a>
              </BtnModalClose>
            </Column>

            <Column col="column col-xxs-6 col-xs-6 text-align-right">
              <BtnLoading
                onClick={this.handleSubmit}
                loadingId={this.props.id}
                icon="send"
                text="เลือกภาพนี้"
                className="waves-effect btn btn-shadow-none btn-submit font-1-0 color-fff"
                btnDisabled={this.state.select ? false : true }
              />
            </Column>
          </Row>
        </div>
      </ModalFixed>
    )
  }
}


export default connect()(ModalGallerySelectImage)
