import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import TextEditorVideo from './TextEditorVideo'
import TextEditorVideoDisplay from './TextEditorVideoDisplay'

let TextEditorVideo = lazy(() => import('./TextEditorVideo'))
TextEditorVideo = suspenseComponent(TextEditorVideo)




export { TextEditorVideo, TextEditorVideoDisplay }
