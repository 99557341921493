import _ from 'lodash'

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index, 1)

  destClone.splice(droppableDestination.index, 0, removed)

  const result = {}
  result[droppableSource.droppableId] = sourceClone
  result[droppableDestination.droppableId] = destClone

  return result
}

export const insert = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index, 1)

  destClone.splice(droppableDestination.index, 0, removed)

  const result = {}
  result[droppableSource.droppableId] = sourceClone
  result[droppableDestination.droppableId] = destClone

  return result
}

export const getDataIndex = (state, number) => {
  const selected = state[`items${number}_selected`]
  const items = state[`items${number}`]

  if (items && selected) {
    const index = items.findIndex(item => item._id === selected._id)

    return {
      [`index${number}`]: index !== -1 ? index : undefined
    }
  } else {
    return {
      [`index${number}`]: undefined
    }
  }
}

export const setDataNested = (_this) => {
  const items1 = Array.from(_this.state.items1)
  const { items2, items3, items4, items5, items6, items7, items8, items9, items10 } = _this.state

  const { index1 } = getDataIndex(_this.state, 1)
  const { index2 } = getDataIndex(_this.state, 2)
  const { index3 } = getDataIndex(_this.state, 3)
  const { index4 } = getDataIndex(_this.state, 4)
  const { index5 } = getDataIndex(_this.state, 5)
  const { index6 } = getDataIndex(_this.state, 6)
  const { index7 } = getDataIndex(_this.state, 7)
  const { index8 } = getDataIndex(_this.state, 8)
  const { index9 } = getDataIndex(_this.state, 9)

  // 1
  if (index1 !== undefined) {
    items1[index1].lists = items2
  }

  // 2
  if (index2 !== undefined) {
    items1[index1].lists[index2].lists = items3
  }

  // 3
  if (index3 !== undefined) {
    items1[index1].lists[index2].lists[index3].lists = items4
  }

  // 4
  if (index4 !== undefined) {
    items1[index1].lists[index2].lists[index3].lists[index4].lists = items5
  }

  // 5
  if (index5 !== undefined) {
    items1[index1].lists[index2].lists[index3].lists[index4].lists[index5].lists = items6
  }

  // 6
  if (index6 !== undefined) {
    items1[index1].lists[index2].lists[index3].lists[index4].lists[index5].lists[index6].lists = items7
  }

  // 7
  if (index7 !== undefined) {
    items1[index1].lists[index2].lists[index3].lists[index4].lists[index5].lists[index6].lists[index7].lists = items8
  }

  // 8
  if (index8 !== undefined) {
    items1[index1].lists[index2].lists[index3].lists[index4].lists[index5].lists[index6].lists[index7].lists[index8].lists = items9
  }

  // 9
  if (index9 !== undefined) {
    items1[index1].lists[index2].lists[index3].lists[index4].lists[index5].lists[index6].lists[index7].lists[index8].lists[index9].lists = items10
  }

  return items1
}

export const selectItems = (_this, number, item) => {
  const subitems = item.lists || []
  const itemsMaxLength = _this.state.itemsMaxLength
  const params = {}

  Array.apply(1, Array(itemsMaxLength + 1)).map((v, i) => {
    if (i > 0) {
      if (i === number) {
        params[`items${i+1}`] = subitems
        params[`items${i}_selected`] = item
      }

      if (i > number) {
        if (i < itemsMaxLength) {
          params[`items${i+1}`] = []
        }

        params[`items${i}_selected`] = undefined
      }
    }
  })

  _this.setState(params, () => {
    if (_this.props.getItemSelect) {
      _this.props.getItemSelect(item)
    }
  })
}

export const updateItemForDelete = (_this, id) => {
  const state = {}
  const { itemsMaxLength } = _this.state

  let number = id.split('items')[1]
  number = parseInt(number)

  Array.apply(1, Array(itemsMaxLength+1)).map((v, i) => {
    if (i > 0) {
      if (i >= number) {
        state[`items${i}_selected`] = undefined
        state[`items${i+1}`] = []
      }
    }
  })

  _this.setState(state)
}


export const countItemsSelected = (_this) => {
  let i = 0, length = 0

  for (let key in _this.state) {
    i++
    if (_this.state[`items${i}_selected`]) {
      length++
    }
  }

  return length
}


export const checkApproveMove = (_this, draggableId, source, destination) => {
  let sourceNo = source.droppableId.replace(/[^0-9]/g, '')
  let destNo = destination.droppableId.replace(/[^0-9]/g, '')

  sourceNo = parseInt(sourceNo)
  destNo = parseInt(destNo)


  let i = 0, status = true

  for (let key in _this.state) {
    i++

    const selected = _this.state[`items${i}_selected`]

    if (destNo > sourceNo) {
      if (selected) {
        if (selected._id === draggableId) {
          status = false
          break
        }
      }
    } else {
      if (selected) {
        if (selected._id === draggableId) {
          if (selected.lists && selected.lists.length !== 0) {
            if (_this.state.itemsMaxLength !== i) {
              return false
            }
          }
        }
      }
    }
  }

  return status
}

export const onDragEnd = (_this, result) => {
  const { destination, source, draggableId } = result

  if (!destination) {
    return
  }

  if (source.droppableId === destination.droppableId) {
    const items = reorder(
      _this.state[source.droppableId],
      source.index,
      destination.index
    )

    _this.setState({ [source.droppableId]: items }, () => {
      getItems(_this)
    })

  } else {
    const result = move(
      _this.state[source.droppableId],
      _this.state[destination.droppableId],
      source,
      destination
    )

    if (checkApproveMove(_this, draggableId, source, destination)) {
      _this.setState(result, () => {
        _this.setState({ items1: setDataNested(_this) }, () => {
          getItems(_this)
        })
      })
    }
  }
}

export const getItems = (_this) => {
  if (_this.props.getItems) {
    const items = convertValue(_this.state.items1)
    _this.props.getItems(items)
  }
}


export const convertValue = (items, lists=[], parent_id) => {
  if (items) {
    items.map((item, i) => {
      if (parent_id) {
        lists.push({ _id: item._id, path: item.path, sort: i+1, parent_id })
      } else {
        lists.push({ _id: item._id, path: item.path, sort: i+1 })
      }

      if (item.lists) {
        convertValue(item.lists, lists, item._id)
      }
    })

    return lists
  }
}

export const generateItems1 = ({ items, folder, navbarShow }) => {
  items = items || []

  const itemsClone = Array.from(items)


  items.map((itemTop, iTop) => {
    let item_top_parent_id = itemTop.parent_id

    if (folder) {
      item_top_parent_id = itemTop.folders_id
    }

    if (item_top_parent_id) {
      const checkParent = itemsClone.filter(item => item._id === item_top_parent_id)[0]

      if (!checkParent) {
        itemTop.parent_id = null

        if (folder) {
          itemTop.folders_id = null
        }
      }

      items.map((item, i) => {
        if (item._id === item_top_parent_id) {
          item.lists = item.lists || []

          // check data items
          item.lists.map((list, i) => {
            const checkItem = itemsClone.filter(item => item._id === list._id)[0]

            if (!checkItem) {
              item.lists.splice(i, 1)
            }
          })

          // check data item duplicate
          const duplicate = item.lists.filter(list => list._id === itemTop._id)[0]


          if (!duplicate) {
            item.lists.push(itemTop)
          }

          // filter parent
          let lists = _.orderBy(item.lists, ['sort'], ['asc'])

          // filter navbarShow
          if (navbarShow === true || navbarShow === false) {
            lists = lists.filter(item => item.navbarShow === navbarShow)
          }

          item.lists = lists
        }
      })
    }
  })

  // filter navbarShow
  if (navbarShow === true || navbarShow === false) {
    items = items.filter(item => item.parent_id === null && item.navbarShow === navbarShow)
  } else {

    if (folder) {
      items = items.filter(item => item.folders_id === null)
    } else {
      items = items.filter(item => item.parent_id === null)
    }
  }

  items = setPathFull(items)

  return items
}

export const setPathFull = (items) => {

  const setPathFull = (number, item, path, pathId) => {
    let pathFull = !path ? item.path : path + '/' + item.path
    let pathIdFull = !pathId ? item._id : pathId + '/' + item._id

    item.pathFull = pathFull
    item.pathIdFull = pathIdFull

    //console.log(pathFull)

    return {
      [`path_${number}`]: pathFull,
      [`pathId_${number}`]: pathIdFull
    }
  }

  // path 1
  items.map(item => {
    let { path_1, pathId_1 } = setPathFull(1, item)

    // path 2
    if (item.lists) {
      item.lists.map(item => {
        let { path_2, pathId_2 } = setPathFull(2, item, path_1, pathId_1)


        // path 3
        if (item.lists) {
          item.lists.map(item => {
            let { path_3, pathId_3 } = setPathFull(3, item, path_2, pathId_2)

            // path 4
            if (item.lists) {
              item.lists.map(item => {
                let { path_4, pathId_4 } = setPathFull(4, item, path_3, pathId_3)

                // path 5
                if (item.lists) {
                  item.lists.map(item => {
                    let { path_5, pathId_5 } = setPathFull(5, item, path_4, pathId_4)

                    // path 6
                    if (item.lists) {
                      item.lists.map(item => {
                        let { path_6, pathId_6 } = setPathFull(6, item, path_5, pathId_5)

                        // path 7
                        if (item.lists) {
                          item.lists.map(item => {
                            let { path_7, pathId_7 } = setPathFull(7, item, path_6, pathId_6)

                            // path 8
                            if (item.lists) {
                              item.lists.map(item => {
                                let { path_8, pathId_8 } = setPathFull(8, item, path_7, pathId_7)

                                // path 9
                                if (item.lists) {
                                  item.lists.map(item => {
                                    let { path_9, pathId_9 } = setPathFull(9, item, path_8, pathId_8)

                                    // path 10
                                    if (item.lists) {
                                      item.lists.map(item => {
                                        let { path_10, pathId_10 } = setPathFull(10, item, path_9, pathId_9)

                                      })
                                    }
                                  })
                                }
                              })
                            }
                          })
                        }
                      })
                    }
                  })
                }
              })
            }
          })
        }
      })
    }
  })

  return items
}

export const itemsDemo = () => [
  {_id: "item-1", name: 'item 1', sort: 1},
  {_id: "item-3", name: 'item 3', sort: 1, parent_id: "item-1" },
  {_id: "item-4", name: 'item 4', sort: 2, parent_id: "item-1" },
  {_id: "item-5", name: 'item 5', sort: 3, parent_id: "item-1" },
  {_id: "item-7", name: 'item 7', sort: 4, parent_id: "item-1" },
  {_id: "item-9", name: 'item 9', sort: 1, parent_id: "item-7" },
  {_id: "item-10", name: 'item 10', sort: 2, parent_id: "item-7" },
  {_id: "item-2", name: 'item 2', sort: 2},
  {_id: "item-6", name: 'item 6', sort: 3},
  {_id: "item-8", name: 'item 8', sort: 1, parent_id: "item-6" },
  {_id: "item-11", name: 'item 11', sort: 4},
  {_id: "item-12", name: 'item 12', sort: 1, parent_id: "item-11" },
  {_id: "item-13", name: 'item 13', sort: 1, parent_id: "item-12" },
  {_id: "item-14", name: 'item 14', sort: 2, parent_id: "item-12" },
  {_id: "item-15", name: 'item 15', sort: 1, parent_id: "item-14" },
]
