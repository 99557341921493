import React, { Fragment } from 'react'

// Components Text Editor
import BoxSettings from '../../BoxSettings'
import {
  SwitchStyleSettings,
  SelectStyleSettings,
  InputStyleSettings
} from '../../StyleSettings'



const DivAnimate = (props) => {
  const { _this } = props
  const title = `${props.title} (Animate)`
  const name = `${props.name}-animate`
  const use = _this.state.style[`${name}-use`]
  const group = _this.state.style[`${name}-group-type`]


  let typeOptions = []

  if (group == 'fade') {
    typeOptions = [
      { label: 'fade', value: 'fade' },
      { label: 'fade-up', value: 'fade-up' },
      { label: 'fade-down', value: 'fade-down' },
      { label: 'fade-left', value: 'fade-left' },
      { label: 'fade-right', value: 'fade-right' },
      { label: 'fade-up-right', value: 'fade-up-right' },
      { label: 'fade-up-left', value: 'fade-up-left' },
      { label: 'fade-down-right', value: 'fade-down-right' },
      { label: 'fade-down-left', value: 'fade-down-left' },
    ]

  } else if (group == 'flip') {
    typeOptions = [
      { label: 'flip-up', value: 'flip-up' },
      { label: 'flip-down', value: 'flip-down' },
      { label: 'flip-left', value: 'flip-left' },
      { label: 'flip-right', value: 'flip-right' },
    ]

  } else if (group == 'slide') {
    typeOptions = [
      { label: 'slide-up', value: 'slide-up' },
      { label: 'slide-down', value: 'slide-down' },
      { label: 'slide-left', value: 'slide-left' },
      { label: 'slide-right', value: 'slide-right' },
    ]

  } else if (group == 'zoom') {
    typeOptions = [
      { label: 'zoom-in', value: 'zoom-in' },
      { label: 'zoom-in-up', value: 'zoom-in-up' },
      { label: 'zoom-in-down', value: 'zoom-in-down' },
      { label: 'zoom-in-left', value: 'zoom-in-left' },
      { label: 'zoom-in-right', value: 'zoom-in-right' },
      { label: 'zoom-out', value: 'zoom-out' },
      { label: 'zoom-out-up', value: 'zoom-out-up' },
      { label: 'zoom-out-down', value: 'zoom-out-down' },
      { label: 'zoom-out-left', value: 'zoom-out-left' },
      { label: 'zoom-out-right', value: 'zoom-out-right' },
    ]
  }


  return (
    <BoxSettings title={title} icon="animation">
      <div className={`pd-10`}>
        <SwitchStyleSettings
          _this={_this}
          label="ใช้ Animate"
          styleName={`${name}-use`}
        />
      </div>

      { use &&
        <Fragment>
          <div className={`pd-10 bg-fbfbfb`}>
            <SelectStyleSettings
              label="กลุ่มประเภท"
              _this={_this}
              styleName={`${name}-group-type`}
              options={[
                { label: 'เลือกกลุ่มประเภท', value: '' },
                { label: 'Fade', value: 'fade' },
                { label: 'Flip', value: 'flip' },
                { label: 'Slide', value: 'slide' },
                { label: 'Zoom', value: 'zoom' },
              ]}
            />
          </div>

          { group &&
            <Fragment>
              <div className={`pd-10`}>
                <SelectStyleSettings
                  label="ประเภท"
                  _this={_this}
                  styleName={`${name}-type`}
                  options={[
                    { label: 'เลือกประเภท', value: '' },
                    ...typeOptions
                  ]}
                />
              </div>

              <div className={`pd-10 bg-fbfbfb`}>
                <SelectStyleSettings
                  label="Anchor Placement"
                  _this={_this}
                  styleName={`${name}-anchor-placement`}
                  options={[
                    { label: 'เลือก ', value: '' },
                    { label: 'top-bottom', value: 'top-bottom' },
                    { label: 'top-center', value: 'top-center' },
                    { label: 'top-top', value: 'top-top' },
                    { label: 'center-bottom', value: 'center-bottom' },
                    { label: 'center-center', value: 'center-center' },
                    { label: 'center-top', value: 'center-top' },
                    { label: 'bottom-bottom', value: 'bottom-bottom' },
                    { label: 'bottom-center', value: 'bottom-center' },
                    { label: 'bottom-top', value: 'bottom-top' },
                  ]}
                />
              </div>

              <div className={`pd-10 `}>
                <SelectStyleSettings
                  label="Easing"
                  _this={_this}
                  styleName={`${name}-easing`}
                  options={[
                    { label: 'เลือก Easing', value: '' },

                    { label: 'linear', value: 'linear' },
                    { label: 'ease', value: 'ease' },
                    { label: 'ease-in', value: 'ease-in' },
                    { label: 'ease-out', value: 'ease-out' },
                    { label: 'ease-in-out', value: 'ease-in-out' },

                    { label: 'ease-in-back', value: 'ease-in-back' },
                    { label: 'ease-out-back', value: 'ease-out-back' },
                    { label: 'ease-in-out-back', value: 'ease-in-out-back' },
                    { label: 'ease-in-sine', value: 'ease-in-sine' },
                    { label: 'ease-out-sine', value: 'ease-out-sine' },

                    { label: 'ease-in-out-sine', value: 'ease-in-out-sine' },
                    { label: 'ease-in-quad', value: 'ease-in-quad' },
                    { label: 'ease-out-quad', value: 'ease-out-quad' },
                    { label: 'ease-in-out-quad', value: 'ease-in-out-quad' },
                    { label: 'ease-in-cubic', value: 'ease-in-cubic' },

                    { label: 'ease-out-cubic', value: 'ease-out-cubic' },
                    { label: 'ease-in-out-cubic', value: 'ease-in-out-cubic' },
                    { label: 'ease-in-quart', value: 'ease-in-quart' },
                    { label: 'ease-out-quart', value: 'ease-out-quart' },
                    { label: 'ease-in-out-quart', value: 'ease-in-out-quart' },
                  ]}
                />
              </div>

              <div className={`pd-10 bg-fbfbfb`}>
                <table>
                  <tbody>
                    <tr>
                      <td className="pd-0 pd-right-5">
                        <InputStyleSettings
                          label="offset"
                          _this={_this}
                          type="text"
                          styleName={`${name}-offset`}
                        />
                      </td>
                      <td className="pd-0 pd-right-5">
                        <InputStyleSettings
                          label="delay"
                          _this={_this}
                          type="text"
                          styleName={`${name}-delay`}
                        />
                      </td>
                      <td className="pd-0">
                        <InputStyleSettings
                          label="duration"
                          _this={_this}
                          type="text"
                          styleName={`${name}-duration`}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Fragment>
          }
        </Fragment>
      }

    </BoxSettings>
  )
}

export default DivAnimate
