import Immutable from 'immutable'


export const stateSearchDefault = () => {
  return { status: false, value: '', data: [], count: 0, searchParams: [] }
}

export const stateSearch = (state, action, data, loadMore=false) => {
  if (!loadMore) {
    return {
      ...state,
      search: {
        status: action.search.status,
        value: action.search.value,
        count: action.search.count || 0,
        data: data,
        params: action.search.params
      }
    }
  } else {
    return {
      ...state,
      search: {
        status: state.search.status,
        value: state.search.value,
        count: state.search.count || 0,
        data: data,
        params: state.search.params
      }
    }
  }
}

export const stateUpdate = (state, action, actionData) => {
  let index = state.data.findIndex(data => data._id === action._id)
  let datas = state.data.filter(data => data._id === action._id)[0]
  let nested = Immutable.fromJS(state.data)
  let updated = nested.setIn([index], actionData)

  return updated.toJS()
}