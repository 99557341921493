import React, { Component, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import Modal from '../../Modals/Modal'
import { Btn } from '../../button/Button'
import AvatarEditor from 'react-avatar-editor'
import Compressor from 'compressorjs'

// redux
import { openModal, closeModal } from '../../../redux/App/AppActions'

// css
import css from './css/inputFile.css'
import { imgUrlToBase64 } from '../../../util/imgUrlToBase64'


const InputImageCrop = (props) => {
  const [file, setFile] = useState()
  const [img, setImg] = useState()
  const [scale, setScale] = useState(1)
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 })
  const [rotate, setRotate] = useState(0)
  const [preview, setPreview] = useState()
  const [croppingRect, setCroppingRect] = useState()
  const [modal, setModal] = useState(false)
  const avatarRef = useRef()
  const canvasRef = useRef()

  const handleSelect = (e) => {
    const file = e.target.files[0]
    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onloadend = (e) => {
      if (file.type === "image/jpeg" || file.type === "image/png") {
        setImg(reader.result)
        setFile(file)
        handleModalOpen()
      } else {
        alert(`ไม่สามารถอัพโหลด "${file.name}" เนื่องจาก ประเภทของไฟล์ไม่ถูกต้อง (รองรับเฉพาะ jpeg, png, jpg)`)
      }
    }
  }


  useEffect(() => {
    const imageUrl = props.imageUrl

    if (imageUrl) {
      async function getBase64() {
        const base64 = await imgUrlToBase64(imageUrl)
        const dataurl = base64 && base64.imgBase64

        if (dataurl) {
          const file = dataURLtoFile(dataurl, 'download-from-url')

          if (file) {
            setImg(dataurl)
            setFile(file)

            setTimeout(() => {
              handleModalOpen()
            }, 500)
          }
        }
      }

      getBase64()
    }
  }, [props.imageUrl])


  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }

  const handleSave = (data) => {
    const canvas = avatarRef.current.getImageScaledToCanvas()
    const newCanvas = canvas.cloneNode(true)
    const ctx = newCanvas.getContext('2d')
    //ctx.fillStyle = "#EFEFEF"
    ctx.fillStyle = "rgb(0,0,0,0)"
    ctx.fillRect(0, 0, newCanvas.width, newCanvas.height)

    ctx.drawImage(canvas, 0, 0)
    const base64Url = newCanvas.toDataURL(file.type)
    //const base64Url = newCanvas.toDataURL("image/png")

    const img = base64Url
    const rect = avatarRef.current.getCroppingRect()

    setPreview(img)
    setCroppingRect(rect)
    setModal(false)

    const fileConv = dataURLtoFile(base64Url, file.type)
    const reader = new FileReader()
    reader.readAsDataURL(fileConv)

    new Compressor(fileConv, {
      quality: props.quality || 0.5,
      maxWidth: 2500,
      maxHeight: 2500,

      success: (result) => {
        const formData = new FormData()
        formData.append('file', result, result.name)

        reader.onloadend = (e) => {
          if (fileConv.type === "image/jpeg" || fileConv.type === "image/png") {
            props.getFiles(result, reader.result)
          } else {
            alert(`ไม่สามารถอัพโหลด "${fileConv.name}" เนื่องจาก ประเภทของไฟล์ไม่ถูกต้อง (รองรับเฉพาะ jpeg, png, jpg)`)
          }
        }

        reader.readAsDataURL(result)
      },

      error: (err) => {
        console.log(err.message)
      }
    })
  }

  const handleScale = (e) => {
    const scale = parseFloat(e.target.value)
    setScale(scale)
  }

  const handleScaleMinus = () => {
    const newSale = scale - 0.005
    setScale(newSale)
  }

  const handleScalePlus = () => {
    const newSale = scale + 0.005
    setScale(newSale)
  }

  const handlePositionChange = (position) => {
    setPosition(position)
  }

  const rotateLeft = () => {
    const newRotate = (rotate - 90) % 360
    setRotate(newRotate)
  }

  const rotateRight = () => {
    const newRotate = (rotate + 90) % 360
    setRotate(newRotate)
  }

  const handleModalOpen = () => {
    props.dispatch(openModal(props.id))
  }

  const handleModalClose = () => {
    const imageUrl = props.imageUrl

    if (imageUrl) {
      props.dispatch(closeModal(props.id))
      props.dispatch(closeModal(props.mainId))
    } else {
      props.dispatch(closeModal(props.id))
    }
  }

  let style = {
    borderRadius: props.crop.circle && '50%',
    cursor: 'move'
  }

  let joinStyle = Object.assign(style, props.crop.style)

  return (
    <div>
      <input id={props.id} type="hidden" defaultValue={preview} />

      <div className={css.boxInput} style={props.style}>
        <input
          type="file"
          className={`${css.input} ${!file ? 'required invalid' : 'required valid'}`}
          onChange={handleSelect}
        />

        { props.children }
      </div>


      <Modal
        headerTitle={props.crop.title}
        headerLeft
        id={props.id}
        width="500px"
        blockBgModal
        btnModalClose={handleModalClose}>

        <div>
          <div className="center pd-15">
            <AvatarEditor
              ref={avatarRef}
              image={img}
              width={props.crop.width}
              height={props.crop.height}
              border={0}
              //backgroundColor="rgb(0, 0, 0, 0)"
              className="border-1 border-c0c0c0 line-height-0"
              //color={[255, 255, 255, 0.6]} // RGBA rgb();
              scale={parseFloat(scale)}
              style={joinStyle}
              position={position}
              rotate={parseFloat(rotate)}
              onPositionChange={handlePositionChange}
            />

            {/*<div className="center mg-top-15 font-0-9">ถ้าคุณต้องการเปลี่ยนตำแหน่งของรูปให้ กดลากบนรูปหรือกดขยายรูป</div>*/}

            <table width="100%" className="mg-top-15">
              <tbody>
                <tr>
                  <td className="pd-0 width-40 text-left">
                    <a onClick={handleScaleMinus} className="btn btn-shadow-none pd-0 btn-white width-35 height-35 line-height-35">
                      <i className="material-icons">zoom_out</i>
                    </a>
                  </td>
                  <td className="pd-0">
                    <div className="range-field center">
                      <input
                        name="scale"
                        type="range"
                        onChange={handleScale}
                        min="0.3"
                        max="2"
                        step="0.01"
                        value={scale}
                        defaultValue="1"
                      />
                    </div>
                  </td>
                  <td className="pd-0 width-40 text-right">
                    <a onClick={handleScalePlus} className="btn btn-shadow-none pd-0 btn-white width-35 height-35 line-height-35">
                      <i className="material-icons">zoom_in</i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="center">
              <a onClick={rotateLeft} className="btn font-0-8 btn-shadow-none btn-white height-25 line-height-25 mg-5">
                <i className="material-icons left">rotate_90_degrees_ccw</i> หมุนซ้าย
              </a>
              <a onClick={() => setPosition({ x: 0.5, y: 0.5 })} className="btn font-0-8 btn-shadow-none btn-white height-25 line-height-25 mg-5">
                <i className="material-icons left">format_align_center</i> กึ่งกลาง
              </a>
              <a onClick={rotateRight} className="btn font-0-8 btn-shadow-none btn-white height-25 line-height-25 mg-5">
                <i className="material-icons left">rotate_90_degrees_cw</i> หมุนขวา
              </a>
            </div>
          </div>

          <div className="border-top-1 border-top-efefef pd-10">
            <a className="btn btn-shadow-none btn-delete left" onClick={handleModalClose}>
              <i className="material-icons left">close</i> ออก
            </a>

            <a className="btn btn-shadow-none btn-success right" onClick={handleSave}>
              <i className="material-icons left">check</i> เลือกรูปนี้
            </a>

            <div style={{ clear: 'both' }} />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default connect()(InputImageCrop)

/*
class InputImageCrop extends Component {
  constructor(props) {
    super(props)
    this.state = { scale: 1 }
  }

  handleSelect = () => {
    const file = this.refs.file.files[0]
    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onloadend = (e) => {
      if (file.type === "image/jpeg" || file.type === "image/png") {
        this.setState({
          img: reader.result,
          file: file
        }, () => {
          this.handleModalOpen()
        })
      } else {
        alert(`ไม่สามารถอัพโหลด "${file.name}" เนื่องจาก ประเภทของไฟล์ไม่ถูกต้อง (รองรับเฉพาะ jpeg, png, jpg)`)
      }
    }
  }

  dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }

  handleSave = (data) => {
    const img = this.refs.avatar.getImageScaledToCanvas().toDataURL()
    const rect = this.refs.avatar.getCroppingRect()

    this.setState({ preview: img, croppingRect: rect })
    this.setState({ modal: false })

    let base64Url

    if (this.state.file.type !== 'image/png') {
      base64Url = img.replace('data:image/png;', `data:${this.state.file.type};`)
    } else {
      base64Url = img
    }

    const file = this.dataURLtoFile(base64Url, this.state.file.type)
    const reader = new FileReader()
    reader.readAsDataURL(file)

    new Compressor(file, {
      quality: 0.6,
      maxWidth: 2500,
      maxHeight: 2500,

      success: (result) => {
        const formData = new FormData()
        formData.append('file', result, result.name)

        reader.onloadend = (e) => {
          if (file.type === "image/jpeg" || file.type === "image/png") {
            this.props.getFiles(result, reader.result)
          } else {
            alert(`ไม่สามารถอัพโหลด "${file.name}" เนื่องจาก ประเภทของไฟล์ไม่ถูกต้อง (รองรับเฉพาะ jpeg, png, jpg)`)
          }
        }

        reader.readAsDataURL(result)
      },

      error: (err) => {
        console.log(err.message)
      }
    })
  }

  handleScale = () => {
    const scale = parseFloat(this.refs.scale.value)

    this.setState({ scale })
  }

  handleModalOpen = () => {
    this.props.dispatch(openModal(this.props.id))
  }

  handleModalClose = () => {
    this.props.dispatch(closeModal(this.props.id))
  }

  render() {
    let style = {
      borderRadius: this.props.crop.circle && '50%',
      border: '4px solid #d1d1d1',
      cursor: 'move',
      marginTop: '20px'
    }

    let joinStyle = Object.assign(style, this.props.crop.style)

    return (
      <div>
        <input ref={this.props.id} id={this.props.id} type="hidden" defaultValue={this.state.preview} />

        <div className={css.boxInput} style={this.props.style}>
          <input
            ref="file"
            type="file"
            className={`${css.input} ${!this.state.file ? 'required invalid' : 'required valid'}`}
            onChange={(e) => this.handleSelect(e)}
          />

          { this.props.children }
        </div>

        <Modal id={this.props.id} width="600px" blockBgModal>
          <div>
            <div style={{ paddingBottom: '20px', textAlign: 'center', lineHeight: '50px' }}>
              <b style={{ fontSize: '1.5rem'}}>
                { this.props.crop.title }
              </b>
              <p className="center">ถ้าคุณต้องการเปลี่ยนตำแหน่งของรูปให้ กดลากบนรูปหรือกดขยายรูป</p>
            </div>
            <div className="center">

              <AvatarEditor
                ref="avatar"
                image={this.state.img}
                width={this.props.crop.width}
                height={this.props.crop.height}
                border={0}
                color={[255, 255, 255, 0.6]} // RGBA
                scale={parseFloat(this.state.scale)}
                style={joinStyle}
              />

              <table width="100%">
                <tbody>
                  <tr>
                    <td width="40">
                      <i className="material-icons">zoom_out</i>
                    </td>
                    <td>
                      <p className="range-field" style={{ paddingTop: '10px' }}>
                        <input name="scale" type="range" ref="scale" onChange={this.handleScale} min="1" max="2" step="0.01" defaultValue="1" />
                      </p>
                    </td>
                    <td width="40">
                      <i className="material-icons">zoom_in</i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="right-align">
              <Btn
                icon="cancel"
                iconAlign="left"
                className="waves-effect waves-light btn red darken-2"
                onClick={this.handleModalClose}
                text="ยกเลิก"
                style={{ marginRight: '10px'}}
              />

              <Btn
                icon="check_circle"
                iconAlign="left"
                className="waves-effect waves-light btn light-green darken-1"
                onClick={this.handleSave}
                text="เลือกรูปนี้"
              />
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default connect()(InputImageCrop)
*/
