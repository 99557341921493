import React, { Component } from 'react'

// Functions
import { addStyle, removeStyle } from '../../../functions/textEditorFunctions'

// css
import css from '../css/styleSettings.css'



class SelectStyleSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  change = (e) => {
    const { _this, styleName } = this.props
    const value = e.target.value

    if (this.props.onChange) {
      this.props.onChange(styleName, value)
    }

    if (value === '') {
      removeStyle(_this, styleName)
    } else {
      addStyle(_this, {
        style: styleName,
        value
      })
    }

    this.setState({ reload: true })
  }

  reset = () => {
    let { _this, styleName } = this.props

    if (this.props.onChange) {
      this.props.onChange(styleName, '')
    }

    if (styleName) {
      removeStyle(_this, styleName)
    }
  }

  render() {
    let { _this, styleName, label, options, defaultValue } = this.props
    const value = _this.state.style[styleName]
    defaultValue = defaultValue || ''

    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td className="pd-0 font-0-8">{ label }</td>
              <td className="pd-0 font-0-7 text-right">
                <a className={css.btn} onClick={this.reset}>รีเซ็ท</a>
              </td>
            </tr>
          </tbody>
        </table>

        <div>
          <div className={css.boxInput}>
            <table>
              <tbody>
                <tr>
                  <td width="50%" className="pd-0 font-0-8 center">
                    <select
                      className={`browser-default ${css.input} ${css.select}`}
                      onChange={this.change}
                      value={value || defaultValue}>

                      { options.map((opt, i) => {
                        return (
                          <option key={i} value={opt.value}>{opt.label}</option>
                        )
                      }) }

                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default SelectStyleSettings
