import React from 'react'
import Scrollbar from 'react-perfect-scrollbar'

import css from './css/contentAndContentRight.css'

const ContentAndContentRight = (props) => {
  return (
    <div>
      <div id={props.id} className={css.body}>
        <div className={css.content} style={props.bodyStyle}>
          <Scrollbar>
            { props.children[0] }
          </Scrollbar>
        </div>
        <div className={css.contentRight}>
          <Scrollbar>
            { props.children[1] }
          </Scrollbar>
        </div>
      </div>

      <div className={css.footer}>
        { props.children[2] }
      </div>
    </div>
  )
}

export default ContentAndContentRight
