import React, { Component } from 'react'
import { connect } from 'react-redux'
import cuid from 'cuid'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import config from '../../config'

// Components Global
import Photoswipe from 'components/Photoswipe/Photoswipe'
import BtnAddImg from '../button/BtnAddImg'
import { BtnModalUploadOpen } from '../Modals/ModalUploadImages/BtnModalUpload'
import LoadImage from '../Images/LoadImage'
import { Tooltip } from '../tooltip/Tooltip'

// Redux Actions
import { openConfirm } from '../../redux/App/AppActions'

// css
import css from './css/albumManager.css'


const s3UrlOriginal = config.app.s3.urlOriginal

class AlbumManager extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectMultiple: [],
      galleryId: cuid()
    }
  }

  uploadImage = () => {
    const { profile, cover, action, dispatch } = this.props
    const params = action.params

    return (id, files, album) => {
      if (files) {
        const actionParams = {
          body: {
            updateType: params.updateType || 'push',
            updateElement: params.updateType || 'images',
            album: album,
            profile,
            cover
          },
          loadId: id,
          modalId: id,
          progressId: id
        }

        let imagesAll = this.props.images || []

        if (action.forItem) {
          actionParams.body.updateType = 'push-sub-element'
          actionParams.body.updateElement = 'items'
          actionParams.body.updateElementSub = 'items.$.images'
          actionParams.body.updateId = action.forItem._id

          imagesAll = this.props.item.images || []
        }

        if (action.useQuery) {
          const fileParams = {
            files,
            imagePrefix: params.imagePrefix
          }

          if (album === 'profile') {
            this.resetProfile(imagesAll, fileParams, actionParams, id)
          } else {
            dispatch(action.uploadImage(action.useQuery, fileParams, actionParams))
          }

        } else if (action.authName) {
          dispatch(action.uploadImage(action.authName, params.business._id, params._id, files, params.imagePrefix, actionParams))

        } else {
          dispatch(action.uploadImage(params.business._id, params._id, files, params.imagePrefix, actionParams))
        }
      }
    }
  }

  resetProfile = (imagesAll, fileParams, actionParams, id) => {
    const { action, dispatch, item } = this.props
    const useProfiles = imagesAll.filter(img => img.album === 'profile')

    if (useProfiles.length >= 1) {
      imagesAll.map(img => {
        img.profile = false
        img.cover = false

        if (img.album === 'profile') {
          img.album = 'public'
        }
      })

      const params = {
        body: {
          updateType: 'set',
          updateElement: 'items',
          updateId: item._id,
          images: imagesAll
        },
        loadId: id
      }

      dispatch(action.update(action.useQuery, params)).then(res => {
        if (res.success) {
          dispatch(action.uploadImage(action.useQuery, fileParams, actionParams))
        }
      })
    } else {
      dispatch(action.uploadImage(action.useQuery, fileParams, actionParams))
    }
  }

  onDeleteImage = () => {
    const action = this.props.action
    const params = action.params

    return (id) => {
      const actionParams = {
        body: {
          updateType: params.updateType || 'pull-image',
          updateElement: params.updateType || 'images',
          deleteId: id
        },
        toastMsg: `ลบภาพนี้แล้ว`,
        loadId: id,
        confirmId: id,
        props: this.props
      }

      if (action.forItem) {
        actionParams.body.updateType = 'pull-sub-image'
        actionParams.body.updateElement = 'items.images'
        actionParams.body.updateElementSub = 'items.$.images'
        actionParams.body.updateId = action.forItem._id,
        actionParams.body.pullSub = {
          element: '_id',
          value: id
        }

        delete actionParams.body.deleteId
      }

      if (action.useQuery) {
        this.props.dispatch(action.update(action.useQuery, actionParams))
      } else if (action.authName) {
        this.props.dispatch(action.update(action.authName, params.business._id, params._id, actionParams))
      } else {
        this.props.dispatch(action.update(params.business._id, params._id, actionParams))
      }
    }
  }

  handleDelete = (_id) => {
    this.props.dispatch(openConfirm(_id, {
      title: `ลบภาพนี้`,
      message: `กดปุ่ม "ลบภาพ" ถ้าคุณต้องการลบภาพนี้`,
      type: 'delete',
      icon: 'delete_forever',
      confirmLabel: 'ลบภาพ',
      cancelLabel: 'ยกเลิก',
      onConfirm: this.onDeleteImage(),
    }))
  }

  handleSelect = (value) => {
    let select
    let path

    if (this.state.select === value[this.props.thumbnail]) {
      select = undefined
      path = undefined
    } else {
      select = value[this.props.thumbnail]
      path = value
    }

    this.setState({ select }, () => {
      this.props.getValue(path)
    })
  }

  handleSelectMultiple = (value) => {
    const { thumbnail } = this.props
    const { selectMultiple } = this.state
    const index = selectMultiple.findIndex(path => path[thumbnail] === value[thumbnail])

    if (index !== -1) {
      selectMultiple.splice(index, 1)
    } else {
      selectMultiple.push(value)
    }

    this.setState({ selectMultiple }, () => {
      this.props.getValue(selectMultiple)
    })
  }

  checkActiveMultiple = (value) => {
    const { thumbnail } = this.props
    const { selectMultiple } = this.state

    return selectMultiple.filter(path => path[thumbnail] === value[thumbnail])[0]
  }

  render() {
    let uploadParams = {}, uploadSelectSetImageParams

    if (this.props.upload) {
      uploadParams = this.props.upload
      uploadParams.onUpload = this.uploadImage()
    }

    if (this.props.uploadSelectSetImage) {
      uploadSelectSetImageParams = this.props.uploadSelectSetImage
      uploadSelectSetImageParams.onUpload = this.uploadImage()
    }

    const btnAdd = (
      <div className={css.boxImg} style={this.props.boxImgStyle}>
        <div className={`${css.boxDiv}`}>
          <BtnModalUploadOpen id={this.props.id} upload={uploadParams} crop={this.props.crop}>
            { this.props.btnAdd &&
              <BtnAddImg text={this.props.btnAdd.text} icon={this.props.btnAdd.icon} />
            }
          </BtnModalUploadOpen>
        </div>
      </div>
    )

    return (
      <div className={css.albums}>
        { this.props.title &&
          <span className={css.title}>{this.props.title}</span>
        }

        <div className={`${css.content}`}>
          <Photoswipe galleryId={this.state.galleryId}>
            { this.props.btnAdd && this.props.btnAdd.maxFile > this.props.images.length && btnAdd }
            { this.props.btnAdd && !this.props.btnAdd.maxFile && btnAdd }

            <ReactCSSTransitionGroup
              transitionName="example"
              transitionEnterTimeout={300}
              transitionLeaveTimeout={1000}>

              { this.props.images.map((img, i) => {
                return (
                  <div key={img._id} className={`${css.boxImg}`} style={this.props.imgStyle}>
                    <div className={`boxBtnTransparent ${css.boxDiv}`}>

                      <LoadImage
                        imageCenter
                        className={css.img}
                        s3Url
                        srcOriginal={img.paths.o}
                        src={img.paths[this.props.thumbnail]}
                      />

                      { this.props.select &&
                        <div
                          onClick={() => this.handleSelect(img.paths)}
                          className={`${css.boxSelect} ${this.state.select === img.paths[this.props.thumbnail] ? css.active : ''}`}>

                          <i className="material-icons">check_circle</i>
                        </div>
                      }

                      { this.props.selectMultiple &&
                        <div
                          onClick={() => this.handleSelectMultiple(img.paths)}
                          className={`${css.boxSelect} ${this.checkActiveMultiple(img.paths) ? css.active : ''}`}>

                          <i className="material-icons">check_circle</i>
                        </div>
                      }

                      { this.props.uploadSelectSetImage &&
                        <div className={`${css.boxBtnDelete} pd-3 top-0 right-40`} style={{ position: 'absolute', zIndex: '4' }}>
                          <Tooltip hiddenLine msg="ตั้งเป็นภาพโปรไฟล์" width="80px" left="-40px" style={{ float: 'right' }}>
                            <BtnModalUploadOpen
                              id={`${this.props.id}-${img._id}`}
                              imageUrl={`${s3UrlOriginal}/${img.paths.o}`}
                              upload={uploadSelectSetImageParams}>

                              <a className={`btn-floating btn-xs-floating`}>
                                <i className="material-icons i-middle">photo_library</i>
                              </a>
                            </BtnModalUploadOpen>
                          </Tooltip>
                        </div>
                      }

                      { this.props.delete &&
                        <div className={`${css.boxBtnDelete} pd-3`} style={{ position: 'absolute', top: 0, right: 0, zIndex: '4' }}>
                          <Tooltip hiddenLine msg="ลบภาพนี้" width="70px" left="-35px" style={{ float: 'right' }}>
                            <a onClick={() => this.handleDelete(img._id)}  className={`btn-floating btn-xs-floating waves-light btn-delete`}>
                              <i className="material-icons i-middle">delete</i>
                            </a>
                          </Tooltip>
                        </div>
                      }

                    </div>
                  </div>
                )
              })}

              <div style={{ clear: 'both' }}></div>

            </ReactCSSTransitionGroup>
          </Photoswipe>
        </div>
      </div>
    )
  }
}

export default connect()(AlbumManager)
