import React from 'react'
import { withRouter } from 'react-router'

// Components Local
import ImageGallery from './components/ImageGallery'

// Components Editor
import BlockStyle from '../components/BlockStyle'


const TextEditorImageGalleryDisplay = (props) => {
  const { lang, content, apiData, templateData, mode } = props
  const imageGallery = content.imageGallery && JSON.parse(content.imageGallery)
  const className = imageGallery && imageGallery.class ? JSON.parse(imageGallery.class) : []
  const style = imageGallery && imageGallery.style ? JSON.parse(imageGallery.style) : {}
  const items = imageGallery && imageGallery.items ? JSON.parse(imageGallery.items) : []
  const api = imageGallery && imageGallery.api ? JSON.parse(imageGallery.api) : ''

  //const { classNameColumn } = settings(style)

  return (
    <BlockStyle {...props} style={style} className={className}>
      <ImageGallery
        {...props}
        style={style}
        items={items}
        gallery={imageGallery}
        api={api}
      />
    </BlockStyle>
  )
}

export default withRouter(TextEditorImageGalleryDisplay)
