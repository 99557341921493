import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'

// Config
import config from '../../../../../config'

// Compoents Global
import SocialSignIn from '../../../../SocialSignIn'

// Import Actions
import { socialSignIn } from '../../../../../redux/Auth/AuthActions'

// Components Local
import {
  ButtonSubmit
} from '../LoginSocialsElement'



const LoginSocials = (props) => {
  const { facebook, google } = config.app
  const { modeManage, id } = props
  const facebookLoadingId = `${id}-login-facebook`
  const googleLoadingId = `${id}-login-google`


  const handleSocialSignIn = (user, err) => {
    const { dispatch, location, style, action } = props
    const { business } = action.params

    if (user) {
      let loadId, redirectAfterLogin

      if (location && location.search) {
        redirectAfterLogin  = location.search.split("&")[0].replace("?", "").split("next=")[1]
      }

      let url = redirectAfterLogin && redirectAfterLogin

      if (user.providerFacebook) loadId = facebookLoadingId
      else if (user.providerGoogle) loadId = googleLoadingId

      user.businesses_id = business._id

      dispatch(socialSignIn({
        body: user,
        loadId: loadId,
        modalId: style['close-modal-id'],
        props: props,
        redirect: url && { url },
        toastMsg: 'เข้าสู่ระบบแล้ว'
      }))
    }
  }

  const Facebook = (
    <ButtonSubmit
      {...props}
      id={facebookLoadingId}
      name="btn-login-facebook"
      iconFa="fab fa-facebook-square"
      iconAlign="left"
      text="เข้าสู่ระบบด้วย FACEBOOK"
      className="btn btn-shadow-none"
    />
  )

  const Google = (
    <ButtonSubmit
      {...props}
      id={googleLoadingId}
      name="btn-login-google"
      iconFa="fab fa-google"
      iconAlign="left"
      text="เข้าสู่ระบบด้วย GOOGLE"
      className="btn btn-shadow-none"
    />
  )

  return (
    <Fragment>
      { modeManage ?
        <Fragment>
          { Facebook }
          { Google }
        </Fragment>
      :
        <Fragment>
          <SocialSignIn provider="Facebook" appId={facebook.appId} callback={handleSocialSignIn}>
            { Facebook }
          </SocialSignIn>

          <SocialSignIn provider="Google" appId={google.appId} callback={handleSocialSignIn}>
            { Google }
          </SocialSignIn>
        </Fragment>
      }
    </Fragment>
  )
}

export default withRouter(LoginSocials)
