import { fetchDatas, fetchData, fetchDataUpdate, createData, updateData, removeData, fetchApi } from '../reduxActions'
import { generateTypeName, generateTypeFunctions, getTypePath } from '../reduxTypes'

import uploadsForS3 from '../../util/uploadsForS3'
import { checkAppActions } from '../App/AppActions'

// Export Constants
const NAME_TYPE = ['ARTICLES', 'ARTICLE']
const name = "articles"

// Type Names and Type Functions
export const { ADDS, ADDS_MORE, ADD, UPDATE, REMOVE } = generateTypeName(NAME_TYPE)
export const { adds, adds_more, add, update, remove } = generateTypeFunctions(NAME_TYPE)


// Display
export const fetchArticlesDisplay = (businesses_id, params = {}) => dispatch => {
  return fetchDatas({
    dispatch,
    params,
    api: `${name}-display/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchArticleDisplay = (_id, params = {}) => dispatch => {
  return fetchData({
    dispatch,
    params,
    api: `article-display/${_id}`,
    add: add
  })
}


// Export Actions
export const checkValueDuplicate = (query, params = {}) => dispatch => {
  const { dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return fetchApi({
    dispatch,
    params,
    api: `${path}${name}-query/${dbNo}/${businesses_id}/check-value-duplicate?${params.query}`
  })
}


export const fetchArticles = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return fetchDatas({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchArticle = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id, articles_id } = query
  const path = getTypePath(businessType)

  return fetchData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/${articles_id}`,
    add: add
  })
}


export const createArticle = (query, params) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return createData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/create`,
    add: add
  })
}

export const updateArticle = (query, params) => dispatch => {
  const { authName, dbNo, businessType, businesses_id, articles_id, _id } = query
  const path = getTypePath(businessType)

  return updateData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/${_id || articles_id}`,
    update: update,
    updateId: _id || articles_id
  })
}

export const uploadImagesArticle = (query, fileParams, params) => dispatch => {
  const { files, imagePrefix } = fileParams

  checkAppActions(dispatch, params, 'start')

  return new Promise(resolve => {
    uploadsForS3(files, imagePrefix, params.progressId, dispatch).then(val => {
      val.map(paths => {
        params.body.paths = paths

        return dispatch(updateArticle(query, params)).then(res => {
          if (res.success) {
            res.paths = paths
          }

          resolve(res)
        })
      })
    })
  })
}

export const removeArticle = (query, params) => dispatch => {
  const { authName, dbNo, businessType, businesses_id, articles_id, _id } = query
  const path = getTypePath(businessType)

  return removeData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/${_id || articles_id}`,
    remove,
    deleteId: _id || articles_id
  })
}
