import React, { Component, Fragment } from 'react'

// Components Global
import { SideNavbarDisplay } from '../../../../SitesManage/SideNavbar'

// css
import css from './css/sideNav.css'


class SideNav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      openTransition: false
    }
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false

    document.body.style.overflow = null
  }

  openSideNav = () => {
    document.body.style.overflow = 'hidden'

    if (!this.props.mode) {
      if (this.state.open) {
        this.closeDropdown()
      } else {
        this.setState({ open: true }, () => {
          setTimeout(() => {
            this.setState({ openTransition: true })
          }, 100)
        })
      }
    }
  }

  closeSideNavbar = () => {
    document.body.style.overflow = null

    this.setState({ openTransition: false }, () => {
      setTimeout(() => {
        this.mounted && this.setState({ open: false })
      }, 300)
    })
  }

  render() {
    const {
      className,
      color,
      action,
      style
    } = this.props

    const { open, openTransition } = this.state
    const styleBg = {
      visibility: openTransition ? 'visible' :'hidden',
      opacity: openTransition ? '1' :'0'
    }
    const styleSideNav = {
      visibility: openTransition ? 'visible' :'hidden',
      left: openTransition ? '0' :'-300px',
    }

    const { business, setting, site, pages, pageCurrent, } = action.params

    return (
      <Fragment>
        <a onClick={this.openSideNav} className={`sidenavTrigger ${className}`}>
          <i className="material-icons">menu</i>
        </a>

        { open &&
          <Fragment>
            <div className={css.bg} style={styleBg} onClick={this.closeSideNavbar} />

            <div className={css.sideNav} style={styleSideNav}>
              <SideNavbarDisplay
                {...this.props}
                closeSideNavbar={this.closeSideNavbar}
                datas={{
                  business,
                  setting,
                  site,
                  pages,
                  pageCurrent
                }}
              />
            </div>
          </Fragment>
        }

        <style jsx>{`
          .sidenavTrigger, .sidenavTrigger i {
            font-size: ${style['hamburger-font-size']};
            color: ${style['hamburger-color']};
          }
          .sidenavTrigger:hover i {
            color: ${style['hamburger-hover-color']};
          }
        `}</style>
      </Fragment>
    )
  }
}

export default SideNav
