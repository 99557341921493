import React from 'react'
import Immutable from 'immutable'
import { DefaultDraftBlockRenderMap } from 'draft-js'

// Components Local
import { H1, H2, H3, H4, H5, H6 } from '../components/Wrapper'


export const getBlockStyle = (block) => {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote'

    case 'ALIGN_LEFT':
      return 'text-left'

    case 'ALIGN_CENTER':
      return 'text-center'

    case 'ALIGN_RIGHT':
      return 'text-right'

    default: return null
  }
}

export const colorStyleMap = {
  COLOR_b71c1c: { color: '#b71c1c' },
  COLOR_b71c1c: { color: '#b71c1c' },
  COLOR_d32f2f: { color: '#d32f2f' },
  COLOR_f44336: { color: '#f44336' },
  COLOR_e57373: { color: '#e57373' },
  COLOR_ffcdd2: { color: '#ffcdd2' },
  COLOR_880e4f: { color: '#880e4f' },
  COLOR_c2185b: { color: '#c2185b' },
  COLOR_e91e63: { color: '#e91e63' },
  COLOR_f06292: { color: '#f06292' },
  COLOR_f8bbd0: { color: '#f8bbd0' },
  COLOR_4a148c: { color: '#4a148c' },
  COLOR_7b1fa2: { color: '#7b1fa2' },
  COLOR_9c27b0: { color: '#9c27b0' },
  COLOR_ba68c8: { color: '#ba68c8' },
  COLOR_e1bee7: { color: '#e1bee7' },
  COLOR_311b92: { color: '#311b92' },
  COLOR_512da8: { color: '#512da8' },
  COLOR_673ab7: { color: '#673ab7' },
  COLOR_9575cd: { color: '#9575cd' },
  COLOR_d1c4e9: { color: '#d1c4e9' },
  COLOR_1a237e: { color: '#1a237e' },
  COLOR_303f9f: { color: '#303f9f' },
  COLOR_3f51b5: { color: '#3f51b5' },
  COLOR_7986cb: { color: '#7986cb' },
  COLOR_c5cae9: { color: '#c5cae9' },
  COLOR_0d47a1: { color: '#0d47a1' },
  COLOR_1976d2: { color: '#1976d2' },
  COLOR_2196f3: { color: '#2196f3' },
  COLOR_64b5f6: { color: '#64b5f6' },
  COLOR_bbdefb: { color: '#bbdefb' },
  COLOR_01579b: { color: '#01579b' },
  COLOR_0288d1: { color: '#0288d1' },
  COLOR_03a9f4: { color: '#03a9f4' },
  COLOR_4fc3f7: { color: '#4fc3f7' },
  COLOR_b3e5fc: { color: '#b3e5fc' },
  COLOR_006064: { color: '#006064' },
  COLOR_0097a7: { color: '#0097a7' },
  COLOR_00bcd4: { color: '#00bcd4' },
  COLOR_4dd0e1: { color: '#4dd0e1' },
  COLOR_b2ebf2: { color: '#b2ebf2' },
  COLOR_004d40: { color: '#004d40' },
  COLOR_00796b: { color: '#00796b' },
  COLOR_009688: { color: '#009688' },
  COLOR_4db6ac: { color: '#4db6ac' },
  COLOR_b2dfdb: { color: '#b2dfdb' },
  COLOR_194d33: { color: '#194d33' },
  COLOR_388e3c: { color: '#388e3c' },
  COLOR_4caf50: { color: '#4caf50' },
  COLOR_81c784: { color: '#81c784' },
  COLOR_c8e6c9: { color: '#c8e6c9' },

  COLOR_33691e: { color: '#33691e' },
  COLOR_689f38: { color: '#689f38' },
  COLOR_8bc34a: { color: '#8bc34a' },
  COLOR_aed581: { color: '#aed581' },
  COLOR_dcedc8: { color: '#dcedc8' },
  COLOR_827717: { color: '#827717' },
  COLOR_afb42b: { color: '#afb42b' },
  COLOR_cddc39: { color: '#cddc39' },
  COLOR_dce775: { color: '#dce775' },
  COLOR_f0f4c3: { color: '#f0f4c3' },
  COLOR_f57f17: { color: '#f57f17' },
  COLOR_fbc02d: { color: '#fbc02d' },
  COLOR_ffeb3b: { color: '#ffeb3b' },
  COLOR_fff176: { color: '#fff176' },
  COLOR_fff9c4: { color: '#fff9c4' },
  COLOR_ff6f00: { color: '#ff6f00' },
  COLOR_ffa000: { color: '#ffa000' },
  COLOR_ffc107: { color: '#ffc107' },
  COLOR_ffd54f: { color: '#ffd54f' },
  COLOR_ffecb3: { color: '#ffecb3' },
  COLOR_e65100: { color: '#e65100' },
  COLOR_f57c00: { color: '#f57c00' },
  COLOR_ff9800: { color: '#ff9800' },
  COLOR_ffb74d: { color: '#ffb74d' },
  COLOR_ffe0b2: { color: '#ffe0b2' },
  COLOR_bf360c: { color: '#bf360c' },
  COLOR_e64a19: { color: '#e64a19' },
  COLOR_ff5722: { color: '#ff5722' },
  COLOR_ff8a65: { color: '#ff8a65' },
  COLOR_ffccbc: { color: '#ffccbc' },
  COLOR_3e2723: { color: '#3e2723' },
  COLOR_5d4037: { color: '#5d4037' },
  COLOR_795548: { color: '#795548' },
  COLOR_a1887f: { color: '#a1887f' },
  COLOR_d7ccc8: { color: '#d7ccc8' },
  COLOR_263238: { color: '#263238' },
  COLOR_455a64: { color: '#455a64' },
  COLOR_607d8b: { color: '#607d8b' },
  COLOR_90a4ae: { color: '#90a4ae' },
  COLOR_cfd8dc: { color: '#cfd8dc' },
  COLOR_000000: { color: '#000000' },
  COLOR_525252: { color: '#525252' },
  COLOR_969696: { color: '#969696' },
  COLOR_d9d9d9: { color: '#d9d9d9' },
  COLOR_ffffff: { color: '#ffffff' }
}

export const fontSizeStyleMap = {
  FONT_SIZE_0_7: { strokeDashoffset: '0.7' },
  FONT_SIZE_0_8: { strokeDashoffset: '0.8' },
  FONT_SIZE_0_9: { strokeDashoffset: '0.9' },

  FONT_SIZE_1_0: { strokeDashoffset: '1.0' },
  FONT_SIZE_1_1: { strokeDashoffset: '1.1' },
  FONT_SIZE_1_2: { strokeDashoffset: '1.2' },
  FONT_SIZE_1_3: { strokeDashoffset: '1.3' },
  FONT_SIZE_1_4: { strokeDashoffset: '1.4' },
  FONT_SIZE_1_5: { strokeDashoffset: '1.5' },
  FONT_SIZE_1_6: { strokeDashoffset: '1.6' },
  FONT_SIZE_1_7: { strokeDashoffset: '1.7' },
  FONT_SIZE_1_8: { strokeDashoffset: '1.8' },
  FONT_SIZE_1_9: { strokeDashoffset: '1.9' },

  FONT_SIZE_2_0: { strokeDashoffset: '2.0' },
  FONT_SIZE_2_1: { strokeDashoffset: '2.1' },
  FONT_SIZE_2_2: { strokeDashoffset: '2.2' },
  FONT_SIZE_2_3: { strokeDashoffset: '2.3' },
  FONT_SIZE_2_4: { strokeDashoffset: '2.4' },
  FONT_SIZE_2_5: { strokeDashoffset: '2.5' },
  FONT_SIZE_2_6: { strokeDashoffset: '2.6' },
  FONT_SIZE_2_7: { strokeDashoffset: '2.7' },
  FONT_SIZE_2_8: { strokeDashoffset: '2.8' },
  FONT_SIZE_2_9: { strokeDashoffset: '2.9' },

  FONT_SIZE_3_0: { strokeDashoffset: '3.0' },
  FONT_SIZE_3_1: { strokeDashoffset: '3.1' },
  FONT_SIZE_3_2: { strokeDashoffset: '3.2' },
  FONT_SIZE_3_3: { strokeDashoffset: '3.3' },
  FONT_SIZE_3_4: { strokeDashoffset: '3.4' },
  FONT_SIZE_3_5: { strokeDashoffset: '3.5' },
  FONT_SIZE_3_6: { strokeDashoffset: '3.6' },
  FONT_SIZE_3_7: { strokeDashoffset: '3.7' },
  FONT_SIZE_3_8: { strokeDashoffset: '3.8' },
  FONT_SIZE_3_9: { strokeDashoffset: '3.9' },

  FONT_SIZE_4_0: { strokeDashoffset: '4.0' },
  FONT_SIZE_4_1: { strokeDashoffset: '4.1' },
  FONT_SIZE_4_2: { strokeDashoffset: '4.2' },
  FONT_SIZE_4_3: { strokeDashoffset: '4.3' },
  FONT_SIZE_4_4: { strokeDashoffset: '4.4' },
  FONT_SIZE_4_5: { strokeDashoffset: '4.5' },
  FONT_SIZE_4_6: { strokeDashoffset: '4.6' },
  FONT_SIZE_4_7: { strokeDashoffset: '4.7' },
  FONT_SIZE_4_8: { strokeDashoffset: '4.8' },
  FONT_SIZE_4_9: { strokeDashoffset: '4.9' },

  FONT_SIZE_5_0: { strokeDashoffset: '5.0' },
  FONT_SIZE_5_1: { strokeDashoffset: '5.1' },
  FONT_SIZE_5_2: { strokeDashoffset: '5.2' },
  FONT_SIZE_5_3: { strokeDashoffset: '5.3' },
  FONT_SIZE_5_4: { strokeDashoffset: '5.4' },
  FONT_SIZE_5_5: { strokeDashoffset: '5.5' },
  FONT_SIZE_5_6: { strokeDashoffset: '5.6' },
  FONT_SIZE_5_7: { strokeDashoffset: '5.7' },
  FONT_SIZE_5_8: { strokeDashoffset: '5.8' },
  FONT_SIZE_5_9: { strokeDashoffset: '5.9' },

  FONT_SIZE_6_0: { strokeDashoffset: '6.0' },
  FONT_SIZE_6_1: { strokeDashoffset: '6.1' },
  FONT_SIZE_6_2: { strokeDashoffset: '6.2' },
  FONT_SIZE_6_3: { strokeDashoffset: '6.3' },
  FONT_SIZE_6_4: { strokeDashoffset: '6.4' },
  FONT_SIZE_6_5: { strokeDashoffset: '6.5' },
  FONT_SIZE_6_6: { strokeDashoffset: '6.6' },
  FONT_SIZE_6_7: { strokeDashoffset: '6.7' },
  FONT_SIZE_6_8: { strokeDashoffset: '6.8' },
  FONT_SIZE_6_9: { strokeDashoffset: '6.9' },

  FONT_SIZE_7_0: { strokeDashoffset: '7.0' },
  FONT_SIZE_7_1: { strokeDashoffset: '7.1' },
  FONT_SIZE_7_2: { strokeDashoffset: '7.2' },
  FONT_SIZE_7_3: { strokeDashoffset: '7.3' },
  FONT_SIZE_7_4: { strokeDashoffset: '7.4' },
  FONT_SIZE_7_5: { strokeDashoffset: '7.5' },
  FONT_SIZE_7_6: { strokeDashoffset: '7.6' },
  FONT_SIZE_7_7: { strokeDashoffset: '7.7' },
  FONT_SIZE_7_8: { strokeDashoffset: '7.8' },
  FONT_SIZE_7_9: { strokeDashoffset: '7.9' },

  FONT_SIZE_8_0: { strokeDashoffset: '8.0' },
  FONT_SIZE_8_1: { strokeDashoffset: '8.1' },
  FONT_SIZE_8_2: { strokeDashoffset: '8.2' },
  FONT_SIZE_8_3: { strokeDashoffset: '8.3' },
  FONT_SIZE_8_4: { strokeDashoffset: '8.4' },
  FONT_SIZE_8_5: { strokeDashoffset: '8.5' },
  FONT_SIZE_8_6: { strokeDashoffset: '8.6' },
  FONT_SIZE_8_7: { strokeDashoffset: '8.7' },
  FONT_SIZE_8_8: { strokeDashoffset: '8.8' },
  FONT_SIZE_8_9: { strokeDashoffset: '8.9' },

  FONT_SIZE_9_0: { strokeDashoffset: '9.0' },
}


export const customStyleMap = {
  ...colorStyleMap,
  ...fontSizeStyleMap
}


export const blockRenderMap = DefaultDraftBlockRenderMap.merge(
  Immutable.Map({
    'header-one_left': { wrapper: <H1 align="left" /> },
    'header-one_center': { wrapper: <H1 align="center" /> },
    'header-one_right': { wrapper: <H1 align="right" /> },

    'header-two_left': { wrapper: <H2 align="left" /> },
    'header-two_center': { wrapper: <H2 align="center" /> },
    'header-two-right': { wrapper: <H2 align="right" /> },

    'header-three_left': { wrapper: <H3 align="left" /> },
    'header-three_center': { wrapper: <H3 align="center" /> },
    'header-three_right': { wrapper: <H3 align="right" /> },

    'header-four_left': { wrapper: <H4 align="left" /> },
    'header-four_center': { wrapper: <H4 align="center" /> },
    'header-four_right': { wrapper: <H4 align="right" /> },

    'header-five_left': { wrapper: <H5 align="left" /> },
    'header-five_center': { wrapper: <H5 align="center" /> },
    'header-five_right': { wrapper: <H5 align="right" /> },

    'header-six_left': { wrapper: <H6 align="left" /> },
    'header-six_center': { wrapper: <H6 align="center" /> },
    'header-six_right': { wrapper: <H6 align="right" /> },
  })
)
