import React, { Fragment } from 'react'
import { connect } from 'react-redux'

// Components Global
import ModalAlert from 'components/modal/ModalAlert'
import ConfirmAlert from 'components/Modals/ConfirmAlert'
import Toast from 'components/toast/Toast'
import ModalUploadImages from 'components/Modals/ModalUploadImages'

// Import Actions
import { closeAlert } from 'core/redux/App/AppActions'


const App = (props) => {
  const handleCloseAlert = () => {
    props.dispatch(closeAlert())
  }

  return (
    <Fragment>
      { props.children }

      <Toast />

      <ModalAlert
        width="450px"
        data={props.app.alert}
        open={props.app.alert.show}
        close={handleCloseAlert}
        buttonText="ตกลง"
      />

      { props.progresses.map((progress, i) => {
        return <ModalUploadImages key={i} id={progress.id} upload={progress.upload} />
      } )}


      {/* props.progresses.map((progress, i) => {
        return <ProgressesList key={i} id={progress.id} progress={progress} />
      } )*/}


      { props.confirms.map((confirm, i) => {
        return <ConfirmAlert key={i} id={confirm.id} confirm={confirm} />
      } )}
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    host: state.host,
    app: state.app,
    progresses: state.progresses.data,
    confirms: state.app.confirms
  }
}

export default connect(mapStateToProps)(App)
