import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import TextEditorSidebar from './TextEditorSidebar'
import TextEditorSidebarDisplay from './TextEditorSidebarDisplay'

let TextEditorSidebar = lazy(() => import('./TextEditorSidebar'))
TextEditorSidebar = suspenseComponent(TextEditorSidebar)

export {
  TextEditorSidebar,
  TextEditorSidebarDisplay
}
