import React, { Component } from 'react'

// Functions
import { addStyle, removeStyle } from '../../../functions/textEditorFunctions'

// Components Local
import SelectFontWeight from './components/SelectFontWeight'

// css
import cssgl from '../css/styleSettings.css'



class FontWeightSetting extends Component {
  resetFont = () => {
    const { _this } = this.props
    const style = this.props.styleName || 'font-weight'

    removeStyle(_this, style)
  }

  fontChange = (e) => {
    const { _this } = this.props
    const style = this.props.styleName || 'font-weight'
    const value = e.target.value

    if (value === '') {
      removeStyle(_this, style)
    } else {
      addStyle(_this, { style, value })
    }
  }

  render() {
    const { _this } = this.props
    const { style } = _this.state
    const styleName = this.props.styleName || 'font-weight'

    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td className="pd-0 font-0-7">ตัวหนา</td>
              <td className="pd-0 font-0-7 text-right">
                <a className={cssgl.btn} onClick={this.resetFont}>ค่าเดิม</a>
              </td>
            </tr>
          </tbody>
        </table>

        <div>
          <div className={cssgl.boxInput}>
            <table>
              <tbody>
                <tr>
                  <td width="50%" className="pd-0 font-0-8 center">
                    <SelectFontWeight {...this.props} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default FontWeightSetting
