import React, { Fragment, useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { AppContext } from '../../../../../containers/websites/Sites/containers/SiteContainer'


// css files
import css from './css/sidebar.css'


const SidebarDisplay = (props) => {
  const [open, setOpen] = useState(false)
  const [openTransition, setOpenTransition] = useState(false)
  const app = useContext(AppContext) || {}
  const { modals, closeModalState } = app
  const modal = modals.filter(modal => modal.id === props.id)[0]

  const openModalFn = () => {
    document.body.style.overflow = 'hidden'
    setOpen(true)
    setTimeout(() => {
      setOpenTransition(true)
    }, 100)
  }

  const closeModalFn = () => {
    setOpenTransition(false)
    setTimeout(() => {
      setOpen(false)
    }, 600)
  }

  const modalClose = () => {
    setTimeout(() => {
      document.body.style.overflow = null
    }, 400)

    closeModalState(props.id)
  }

  useEffect(() => {
    if (modal) {
      openModalFn()
    } else {
      closeModalFn()
    }

    return () => {
      modal && modalClose()
    }
  }, [modal])


  let { children, width, position, style } = props
  let widthVw

  if (style['sidebar-width']) {
    width = style['sidebar-width']
  }

  if (style['sidebar-width-vw']) {
    widthVw = style['sidebar-width-vw']
  }

  if (!width) {
    width = '300px'
  }

  if (!widthVw) {
    widthVw = '20vw'
  }

  width = width || '300px'
  position = position || 'right'
  style = style || {}

  const styleBg = {
    visibility: openTransition ? 'visible' :'hidden',
    opacity: openTransition ? '1' :'0'
  }

  const right = position === 'right' && (openTransition ? '0' : `-${width}`)
  const left = position === 'left' && (openTransition ? '0' : `-${width}`)
  const rightVw = position === 'right' && (openTransition ? '0' : `-${widthVw}`)
  const leftVw = position === 'left' && (openTransition ? '0' : `-${widthVw}`)

  let top, bottom

  if (position === 'right' || position === 'left') {
    top = 0
  }

  if (position === 'top') {
    top = openTransition ? '0' :'-100%'
  }

  if (position === 'bottom') {
    bottom = openTransition ? '0' :'-100%'
  }

  const hiddenBtnClose = style['sidebar-hidden-btn-close']
  const styleSidebar = {
    visibility: openTransition ? 'visible' :'hidden',
    top,
    bottom,
    /*right,
    left*/
  }


  return (
    <Fragment>
      { open &&
        <Fragment>
          <div className={css.bg} style={styleBg} onClick={modalClose} />

          <div className={`${css.sidebar} sidebar-jsx`} style={styleSidebar}>

            { !hiddenBtnClose &&
              <div className={css.boxBtnClose}>
                <div onClick={modalClose} className={`${css.btnClose}`}>
                  <i className="material-icons black-text">close</i>
                </div>
              </div>
            }

            { children }

            <style jsx>{`
              .sidebar-jsx {
                background-color: ${style['sidebar-background-color']};
                width: ${width};
                right: ${right};
                left: ${left};
                bottom: ${position === 'top' && 'unset'};
              }

              @media (min-width: 1500px) {
                .sidebar-jsx {
                  width: ${widthVw};
                  right: ${rightVw};
                  left: ${leftVw};
                }
              }

              @media (max-width: ${width}) {
                .sidebar-jsx {
                  width: 100% !important;
                }
              }

              @media (max-width: ${widthVw}) {
                .sidebar-jsx {
                  width: 100% !important;
                }
              }
            `}</style>
          </div>
        </Fragment>
      }
    </Fragment>
  )
}

export default withRouter(connect()(SidebarDisplay))
