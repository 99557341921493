import React from 'react'

// Components Text Editor
import BlockStyleSettings from '../../../components/BlockStyleSettings'
import ElementStylesManage from '../../../components/ElementStylesManage'

const StyleSettings = (props) => {
  const { _this, name } = props

  return (
    <div>
      <BlockStyleSettings name={name} _this={_this} />

      <ElementStylesManage {...props} />
    </div>
  )
}

export default StyleSettings
