import React from 'react'
import DivAnimate from './DivAnimate'

// Components Local
import DivStyle from './DivStyle'

const DivElementManage = (props) => {
  const { title, device, name, styleHover, styleActive, styleActiveClick } = props

  return (
    <div>
      <DivStyle {...props} />

      { styleHover &&
        <DivStyle {...props} event="hover" title={`${title}เมื่อเมาส์ชี้ (hover)`} name={`${name}-hover`} />
      }

      { styleActive &&
        <DivStyle {...props} event="active" title={`${title}เมื่อแอคทีฟ (active)`} name={`${name}-active`} />
      }

      { styleActiveClick &&
        <DivStyle {...props} event="active-click" title={`${title}เมื่อกด (click)`} name={`${name}-active-click`} />
      }

      { device == 'desktop' &&
        <DivAnimate {...props} />
      }
    </div>
  )
}

export default DivElementManage
