import { fetchDatas, fetchData, createData, updateData, removeData, fetchApi } from '../reduxActions'
import { generateTypeName, generateTypeFunctions } from '../reduxTypes'

import uploadsForS3 from '../../util/uploadsForS3'
import { checkAppActions } from '../App/AppActions'

// Export Constants
const NAME_TYPE = ['ATTRIBUTES', 'ATTRIBUTE']
const name = "attributes"

// Type Names and Type Functions
export const { ADDS, ADDS_MORE, ADD, UPDATE, REMOVE } = generateTypeName(NAME_TYPE)
export const { adds, adds_more, add, update, remove } = generateTypeFunctions(NAME_TYPE)



// Export Actions
export const checkValueDuplicate = (params = {}) => dispatch => {
  return fetchApi({
    dispatch,
    params,
    api: `${name}-query/check-value-duplicate?${params.query}`
  })
}

export const fetchAttributesDisplay = (businesses_id, params = {}) => dispatch => {
  return fetchDatas({
    dispatch,
    params,
    api: `${name}-display/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchAttributeDisplay = (_id, params = {}) => dispatch => {
  return fetchData({
    dispatch,
    params,
    api: `attribute-display/${_id}`,
    add: add
  })
}

export const fetchAttributes = (auth_name, businesses_id, params = {}) => dispatch => {
  return fetchDatas({
    dispatch,
    params,
    api: `${name}/${auth_name}/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchAttribute = (auth_name, businesses_id, _id, params = {}) => dispatch => {
  return fetchData({
    dispatch,
    params,
    api: `${name}/${auth_name}/${businesses_id}/${_id}`,
    add: add
  })
}

export const createAttribute = (auth_name, businesses_id, params) => dispatch => {
  return createData({
    dispatch,
    params,
    api: `${name}/${auth_name}/${businesses_id}/create`,
    add: add,
    order: 'last'
  })
}

export const updateAttribute = (auth_name, businesses_id, _id, params) => dispatch => {
  return updateData({
    dispatch,
    params,
    api: `${name}/${auth_name}/${businesses_id}/${_id}`,
    update: update,
    updateId: _id
  })
}


export const uploadImagesAttribute = (auth_name, businesses_id, _id, files, prefix, params) => dispatch => {
  checkAppActions(dispatch, params, 'start')

  return new Promise(resolve => {
    uploadsForS3(files, prefix, params.progressId, dispatch).then(val => {
      val.map(paths => {
        params.body.paths = paths

        return dispatch(updateAttribute(auth_name, businesses_id, _id, params)).then(res => {
          if (res.success) {
            res.paths = paths
          }

          resolve(res)
        })
      })
    })
  })
}

export const removeAttribute = (auth_name, businesses_id, _id, params) => dispatch => {
  return removeData({
    dispatch,
    params,
    api: `${name}/${auth_name}/${businesses_id}/${_id}`,
    remove: remove,
    deleteId: _id
  })
}
