// Import Actions
import {
  /*DEFAULT,
  MODAL,
  MANAGEMENT,
  LAST_DATA,*/
  SET_LANG,
  LOADING,
  SUCCESS,
  TOAST,
  ALERT,
  ADD_PARAM,
  HTTP_STATUS,
  ADD_TRANSITION,
  OPEN_MODAL, CLOSE_MODAL,
  OPEN_FIELD, CLOSE_FIELD,
  ADD_FORM, REMOVE_FORM,
  ADD_LOAD, REMOVE_LOAD,
  OPEN_CONFIRM, CLOSE_CONFIRM,
  ADD_FILE_PROGRESS, UPDATE_FILE_PROGRESS, REMOVE_FILE_PROGRESS
} from './AppActions'

// Initial State
const initialState = {
  /*modal: false,
  management: { open: false },
  lastData: null,*/
  lang: 'local',
  loading: { show: false },
  success: { show: false },
  alert: { show: false, type: null, message: null },
  toast: { show: false, message: null },
  transition: {},
  modals: [],
  fields: [],
  forms: [],
  loads: [],
  confirms: [],
  params: [],
  progresses: [],
  httpStatus: { code: 200 }
}

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    /*case DEFAULT:
      return { ...state, success: { show: false } }

    case MANAGEMENT:
      return { ...state, success: { show: false }, management: { open: action.management } }

    case MODAL:
      return { ...state, modal: action.status }

    case LAST_DATA:
      return { ...state, lastData: action.data }*/

    case SET_LANG:
      return { ...state, lang: action.lang }

    case LOADING:
      return { ...state, loading: { show: action.loading } }

    case SUCCESS:
      return { ...state, loading: { show: false }, success: { show: action.success } }

    case ALERT:
      return { ...state, loading: { show: false }, alert: action.data }

    case TOAST:
      return { ...state, toast: action.message }

    case HTTP_STATUS:
      return { ...state, loading: { show: false }, httpStatus: { code: action.code }}

    case ADD_TRANSITION:
      return { ...state, transition: action.params }

    case ADD_PARAM:
      let iParams = state.params.map(param => param.name).indexOf(action.name)

      if (iParams !== -1) {
        let stateParams = state.params
        stateParams[iParams].value = action.value

        return { ...state, params: stateParams }

      } else {
        return { ...state, params: [{ name: action.name, value: action.value }, ...state.params] }
      }

    // state confirms
    case OPEN_CONFIRM:
      let confirm = state.confirms.map(confirm => confirm.id === action.id)[0]

      if (!confirm)
        return { ...state, confirms: [{ id: action.id, params: action.params }] }
      else
        return { ...state, confirms: state.confirms }

    case CLOSE_CONFIRM:
      return { ...state, confirms: state.confirms.filter(confirm => confirm.id !== action.id) }





    // state modals
    case OPEN_MODAL:
      let modal = state.modals.map(modal => modal.id === action.id)[0]

      if (!modal) return { ...state, modals: [{ id: action.id, params: action.params }, ...state.modals] }
      else return { ...state, modals: state.modals }

    case CLOSE_MODAL:
      return { ...state, modals: state.modals.filter(modal => modal.id !== action.id) }



    // state fields
    case OPEN_FIELD:
      let field = state.fields.map(field => field === action.id)[0]

      if (!field) return { ...state, fields: [action.id, ...state.fields] }
      else return { ...state, fields: state.fields }

    case CLOSE_FIELD:
      return { ...state, fields: state.fields.filter(field => field !== action.id) }



    // state forms
    case ADD_FORM:
      let index = state.forms.map(form => form.id).indexOf(action.id)

      if (index !== -1) {
        let stateForm = state.forms
        stateForm[index].data = Object.assign(stateForm[index].data, action.form)

        return { ...state, forms: stateForm }

      } else {
        return { ...state, forms: [{ id: action.id, data: action.form }, ...state.forms] }
      }

    case REMOVE_FORM:
      return { ...state, forms: state.forms.filter(form => form.id !== action.id) }



    // state loads
    case ADD_LOAD:
      let load = state.loads.map(val => val === action.id)[0]

      if (!load) {
        return { ...state, loads: [action.id, ...state.loads] }
      } else {
        return { ...state, loads: state.loads }
      }

    case REMOVE_LOAD:
      const loads = state.loads.filter(load => load !== action.id)

      return { ...state, loads }



    // state default
    default:
      return state
  }
}

// Export Reducer
export default AppReducer
