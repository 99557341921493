import React, { Component } from 'react'

// Functions
import { addStyle, removeStyle } from '../../../functions/textEditorFunctions'

// css
import css from '../css/styleSettings.css'


class BorderRadiusSetting extends Component {
  constructor(props) {
    super(props)
    this.state = {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      borderRadius: '0px 0px 0px 0px',
      unit: 'px',
      link: false
    }
  }

  componentDidMount() {
    const { _this } = this.props
    const { style } = _this.state
    const styleName = this.props.styleName || 'border-radius'

    this.setValue({
      value: style[styleName],
      submit: false
    })
  }

  setValue = ({ value, submit }) => {
    if (value) {
      const valueSplit = value.split(' ')

      const unitText = valueSplit[0]
      let unit = 'px'

      if (unitText) {
        const unitInt = parseInt(unitText)
        unit = unitText.replace(unitInt, '')
      }

      const top = valueSplit[0] ? parseInt(valueSplit[0]) : 0
      const right = valueSplit[1] ? parseInt(valueSplit[1]) : 0
      const bottom = valueSplit[2] ? parseInt(valueSplit[2]) : 0
      const left = valueSplit[3] ? parseInt(valueSplit[3]) : 0

      let borderRadius, borderRadiusRem, borderRadiusVw

      if (unit === 'px') {
        borderRadius = `${top}px ${right}px ${bottom}px ${left}px`
        borderRadiusRem = `${top/15}rem ${right/15}rem ${bottom/15}rem ${left/15}rem`
        borderRadiusVw = `${top/15}vw ${right/15}vw ${bottom/15}vw ${left/15}vw`

      } else if (unit === '%') {
        borderRadius = `${top}% ${right}% ${bottom}% ${left}%`
        borderRadiusRem = borderRadius
        borderRadiusVw = borderRadius
      }

      this.setState({ top, right, bottom, left, borderRadius, unit }, () => {
        if (submit) {
          this.setBorderRadius({ borderRadius, borderRadiusRem, borderRadiusVw })
        }
      })
    }
  }

  handleChange = (e, position) => {
    let value = parseInt(e.target.value)
    value = value || 0

    let borderRadius

    if (this.state.link) {
      borderRadius = {
        top: value,
        right: value,
        bottom: value,
        left: value,
      }
    } else {
      borderRadius = {
        [position]: value
      }
    }

    this.setState(borderRadius, () => {
      this.converBorderRadius()
    })
  }

  converBorderRadius = () => {
    const { top, right, bottom, left, unit } = this.state

    let borderRadius, borderRadiusRem, borderRadiusVw

    if (unit === 'px') {
      borderRadius = `${top}px ${right}px ${bottom}px ${left}px`
      borderRadiusRem = `${top/15}rem ${right/15}rem ${bottom/15}rem ${left/15}rem`
      borderRadiusVw = `${top/15}vw ${right/15}vw ${bottom/15}vw ${left/15}vw`

    } else if (unit === '%') {
      borderRadius = `${top}% ${right}% ${bottom}% ${left}%`
      borderRadiusRem = borderRadius
      borderRadiusVw = borderRadius
    }


    this.setState({ borderRadius }, () => {
      this.setBorderRadius({ borderRadius, borderRadiusRem, borderRadiusVw })
    })
  }

  setDefaultValue = () => {
    let { defaultValue } = this.props
    const value = defaultValue || '15px 15px 15px 15px'

    this.setValue({ value, submit: true })
  }

  reset = () => {
    const { _this } = this.props
    const styleName = this.props.styleName || 'border-radius'

    this.setState({ top: 0, right: 0, bottom: 0, left: 0 }, () => {
      removeStyle(_this, null, {
        styleMultiple: [
          { style: styleName },
          { style: `${styleName}-rem`},
          { style: `${styleName}-vw` }
        ]
      })
    })
  }

  setLink = () => {
    this.setState({ link: !this.state.link })
  }

  setUnit = () => {
    let { unit } = this.state

    if (unit === 'px') {
      unit = '%'
    } else {
      unit = 'px'
    }

    this.setState({ unit }, () => {
      this.converBorderRadius()
    })
  }

  setBorderRadius = ({ borderRadius, borderRadiusRem, borderRadiusVw }) => {
    const { _this } = this.props
    const styleName = this.props.styleName || 'border-radius'

    if (borderRadius) {
      addStyle(_this, {
        styleMultiple: [
          { style: styleName, value: borderRadius },
          { style: `${styleName}-rem`, value: borderRadiusRem },
          { style: `${styleName}-vw`, value: borderRadiusVw }
        ]
      })
    }
  }

  render() {
    const { top, right, bottom, left, unit, link } = this.state

    return (
      <div className={css.borderRadiusForm}>
        <table>
          <tbody>
            <tr>
              <td className="pd-0 font-0-7">ขอบโค้ง</td>
              <td className="pd-0 font-0-7 text-right">
                <a className={css.btn} onClick={this.reset}>รีเซ็ท</a>
                <a className={`${css.btn} ${link && css.active}`} onClick={this.setLink}>ลิงก์</a>
                <a className={`${css.btn} ${unit === '%' && css.active}`} onClick={this.setUnit}>เปอร์เซ็น</a>
              </td>
            </tr>
          </tbody>
        </table>

        <div>
          <div className={css.boxInput}>
            <table>
              <tbody>
                <tr>
                  <td width="25%" className="pd-0 font-0-8 center">
                    <input
                      value={top}
                      onChange={(e) => this.handleChange(e, 'top')}
                      className={`${css.input} ${css.inputBorderNone} ${css.inputFirst} center`}
                      type="text"
                    />
                  </td>
                  <td width="25%" className="pd-0 font-0-8 center">
                    <input
                      value={left}
                      onChange={(e) => this.handleChange(e, 'left')}
                      className={`${css.input} ${css.inputBorder} center`}
                      type="text"
                    />
                  </td>
                  <td width="25%" className="pd-0 font-0-8 center">
                    <input
                      value={right}
                      onChange={(e) => this.handleChange(e, 'right')}
                      className={`${css.input} ${css.inputBorder} center`}
                      type="text"
                    />
                  </td>
                  <td width="25%" className="pd-0 font-0-8 center">
                    <input
                      value={bottom}
                      onChange={(e) => this.handleChange(e, 'bottom')}
                      className={`${css.input} ${css.inputBorder} ${css.inputLast} center`}
                      type="text"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <table>
            <tbody>
              <tr>
                <td width="25%" className="pd-0 font-0-7 center">ซ้ายบน</td>
                <td width="25%" className="pd-0 font-0-7 center">ซ้ายล่าง</td>
                <td width="25%" className="pd-0 font-0-7 center">ขวาบน</td>
                <td width="25%" className="pd-0 font-0-7 center">ขวาล่าง</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default BorderRadiusSetting
