import React from 'react'

// Components Global
import { LayoutsDesign } from '../../../../LayoutsDesign'


const ItemContentManage = (props) => {
  return (
    <LayoutsDesign
      {...props}
      bodyColorNone
    />
  )
}

export default ItemContentManage
