import React, { Fragment, useMemo } from 'react'

// Components Local
import { SectionItemDisplay } from '../SectionItem'

// Functions
import { getSections, getContainer } from '../../../redux/Sites/SitesFunctions'


const SectionsListDisplay = (props) => {
  const container = getContainer(props)
  const sections = getSections(props)

  const style = container && container.style ? JSON.parse(container.style) : {}
  const backgroundImage = style && style['background-image']
  const { bodyColorNone } = props

  return (
    <Fragment>
      { sections.length !== 0 &&
        <div
          className="sectionsListDisplay"
          style={{
            minHeight: '100%',
            backgroundColor: !backgroundImage && !bodyColorNone && '#fff',
            backgroundImage
          }}>

          { sections.map((section, i) => {
            return (
              <SectionItemDisplay
                {...props}
                key={section._id}
                section={section}
                sectionNo={i+1}
              />
            )
          }) }
        </div>
      }
    </Fragment>
  )

  /*return (
    <Fragment>
      { useMemo(() => {
        return (
          <Fragment>
            { sections.length !== 0 &&
              <div
                className="sectionsListDisplay"
                style={{
                  minHeight: '100%',
                  backgroundColor: !backgroundImage && !bodyColorNone && '#fff',
                  backgroundImage
                }}>

                { sections.map((section, i) => {
                  return (
                    <SectionItemDisplay
                      {...props}
                      key={section._id}
                      section={section}
                      sectionNo={i+1}
                    />
                  )
                }) }
              </div>
            }
          </Fragment>
        )
      }, [
        props.match && JSON.stringify(props.match),
        props.location && JSON.stringify(props.location),
        props.apiData && JSON.stringify(props.apiData),
        props.templateData && JSON.stringify(props.templateData),
        props.auth && JSON.stringify(props.auth),
        props.action && props.action.params && JSON.stringify(props.action.params),
      ]) }
    </Fragment>
  )*/
}


export default SectionsListDisplay
