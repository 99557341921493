export const getGeo = (geo, lang) => {
  lang = lang || 'local'

  let addressShort, addressFull

  if (geo) {
    let { address, district, amphoe, province, zipcode } = geo

    if (address && district && amphoe && province && zipcode) {
      address = address[lang]
      district = district[lang]
      amphoe = amphoe[lang]
      province = province[lang]

      let dFull, aFull, pFull
      let dShort, aShort, pShort

      if (province === 'กรุงเทพมหานคร') {
        dFull = `แขวง${district}`
        aFull = `เขต${amphoe}`
        pFull = `${province}`

        dShort = `${district}`
        aShort = `${district}`
        pShort = `${province}`

      } else {
        dFull = `ตำบล${district}`
        aFull = `อำเภอ${amphoe}`
        pFull = `จังหวัด${province}`

        dShort = `ต.${district}`
        aShort = `อ.${amphoe}`
        pShort = `จ.${province}`
      }

      addressShort = `${address} ${dShort} ${aShort} ${pShort} ${zipcode}`
      addressFull = `${address} ${dFull} ${aFull} ${pFull} ${zipcode}`
    }
  }

  return {
    addressShort,
    addressFull
  }
}

export const getGeoFull = (geo, lang) => {
  const { addressFull } = getGeo(geo, lang) || {}

  return addressFull
}

export const getGeoShort = (geo, lang) => {
  const { addressShort } = getGeo(geo, lang) || {}

  return addressShort
}


export const getGeoOnly = ({ geo }) => {
  if (geo) {
    const { d, a, p, z } = geo

    let dFull, aFull, pFull
    let dShort, aShort, pShort

    if (p === 'กรุงเทพมหานคร') {
      dFull = `แขวง${d}`
      aFull = `เขต${a}`
      pFull = `${p}`

      dShort = `${d}`
      aShort = `${a}`
      pShort = `${p}`

    } else {
      dFull = `ตำบล${d}`
      aFull = `อำเภอ${a}`
      pFull = `จังหวัด${p}`

      dShort = `ต.${d}`
      aShort = `อ.${a}`
      pShort = `จ.${p}`
    }

    const short = `${dShort} ${aShort} ${pShort} ${z}`
    const full = `${dFull} ${aFull} ${pFull} ${z}`

    return {
      short, full
    }
  } else {
    return {
      short: null, full: null
    }
  }
}
