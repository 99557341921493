import moment from 'moment'
import { ObjectID } from 'bson'


const validationsFn = (values, validations, event) => {
  let error
  let newValues = {}

  if (event === 'create') {
    const dateNow = moment().toISOString()
    validations._id = { type: String, required: true, default: new ObjectID().toString() }
    validations.created = { type: String, required: true, default: dateNow }
    validations.updated = { type: String, required: true, default: dateNow }
  } else if (event === 'update') {
    validations.updated = { type: String, required: true, default: moment().toISOString() }
  }

  for (let key in validations) {
    if (!error) {
      let value = values[key]
      const validation = validations[key]


      if (validation && validation.type && validation.type.name) {
        if (value === undefined || value === '' || value === null) {
          if (validation.default !== undefined) {
            if (typeof validation.default === 'function') {
              value = validation.default()
            } else {
              value = validation.default
            }
          }
        }

        if (validation.required) {
          if (value === undefined || value === '' || value === null) {
            error = `${key} is required`
          }
        }

        const typeName = validation.type.name

        if (!error) {
          // String
          if (typeName === 'String') {
            if (typeof value === 'string' || typeof value === 'number') {
              value = validation.type(value)
            } else {
              if (value !== undefined && value !== null) {
                console.log(value)
                error = `${key} is type not match ${typeName}`
              }
            }

          // Number
          } else if (typeName === 'Number') {
            if (typeof value === 'number') {
              value = validation.type(value)

              if (isNaN(value)) {
                error = `${key} is Number is NaN`
              }
            } else {
              if (value !== undefined && value !== null) {
                error = `${key} is type not match ${typeName}`
              }
            }
          }

        // Object
        } else if (typeName === 'Object') {
          if (typeof value === 'object') {
            value = validation.type(value)

            if (isNaN(value)) {
              error = `${key} is Number is NaN`
            }
          } else {
            if (value !== undefined && value !== null) {
              console.log(value)
              error = `${key} is type not match ${typeName}`
            }
          }
        }

        // enum
        if (!error) {
          if (validation.enum) {
            if (value !== undefined && value !== null) {
              const check = validation.enum.filter(v => v === value)[0]

              if (!check) {
                error = `${key} is type not match ${JSON.stringify(validation.enum)}`
              }
            }
          }
        }

        if (!error) {
          newValues[key] = value
        }

      // array
      } else {
        if (validation[0]) {

          if (event === 'update-push') {
            validation[0]._id = { type: String, required: true, default: new ObjectID().toString() }
          }

          newValues[key] = []

          if (value !== undefined) {
            value.map((v, i) => {
              if (!error) {
                let obj

                if (event === 'update-set') {
                  let newValidation = {}

                  for (let keyV in v) {
                    const keysV = keyV.split('.')

                    // object
                    if (keysV.length > 1) {
                      let schema

                      keysV.map((k, i) => {
                        if (!schema) {
                          schema = validation[0][k]
                        } else {
                          schema = schema[k]
                        }

                        if (i+1 === keysV.length) {
                          newValidation[keyV] = schema
                        }
                      })

                    // single
                    } else {
                      for (let keyValid in validation[0]) {
                        if (keyV === keyValid) {
                          newValidation[keyV] = validation[0][keyV]
                        }
                      }
                    }
                  }

                  obj = validationsFn(v || {}, newValidation)
                } else {
                  obj = validationsFn(v || {}, validation[0])
                }

                if (obj.error) {
                  error = `${key}[${i}].${obj.error}`
                } else {
                  newValues[key].push(obj.values)
                }
              }
            })
          }

        } else {
          const obj = validationsFn(value || {}, validation)

          if (obj.error) {
            error = `${key}.${obj.error}`
          } else {
            newValues[key] = obj.values
          }
        }
      }
    }
  }

  if (error) {
    newValues = undefined
  }

  return {
    values: newValues,
    error
  }
}

export default validationsFn
