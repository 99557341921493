import React, { Component } from 'react'

// css
import css from './css/tabs.css'

class TabItems extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeId: this.props.activeId
    }
  }

  setActiveId = (id) => {
    this.setState({ activeId: id })
  }

  render() {
    const { listStyle } = this.props

    return (
      <div className={css.container} className={this.props.className}>
        <ul className={css.tabs}>
          { this.props.items.map((item, i) => {
            if (!item.hidden) {
              return (
                <li key={i} className={`${css.tab} ${css.disabled}`} style={listStyle || {}}>
                  <a
                    onClick={ () => this.setActiveId(item.id) }
                    style={{ width: item.width }}
                    className={`waves-effect ${item.className}`}>

                    <div className={css.textMiddle}>
                      <div>
                        { item.iconBlock &&
                          <i className="material-icons dp-block mg-bottom-10 font-2-0 i-middle">{item.iconBlock}</i>
                        }

                        { item.icon &&
                          <i className="material-icons font-1-5 left">{item.icon}</i>
                        }

                        <span>{item.name}</span>
                      </div>
                    </div>
                  </a>

                  { this.state.activeId === item.id &&
                    <div className={css.active}></div>
                  }
                </li>
              )
            }
          }) }
        </ul>

        <div>
          { this.props.children.length ?
            this.props.children.map((children, i) => {
              return (
                <div key={i}>
                  { children.props.id === this.state.activeId && children }
                </div>
              )
            })
          :
            this.props.children
          }
        </div>
      </div>
    )
  }
}

export default TabItems
