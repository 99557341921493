import React, { Fragment, useEffect, useMemo, useState } from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import AOS from 'aos'
//import 'regenerator-runtime/runtime'

import renderRoutes from '../../../../../routes/renderRoutes'
import config from '../../../../../config'

// Compoents Local
import { WidgetsDisplay } from '../../../Widgets'
import { ContentGroupPageDisplay } from '../../../ContentGroupPage'

import StylesGlobal from '../StylesGlobal'
import PageHeader from './components/PageHeader'
import PageFooter from './components/PageFooter'

// Redux Actions
import { fetchProductOneDisplay } from '../../../../../redux/Products/ProductsActions'
import { fetchArticleDisplay } from '../../../../../redux/Articles/ArticlesActions'
import { fetchFolderDisplay } from '../../../../../redux/Folders/FoldersActions'
import { fetchTagDisplay } from '../../../../../redux/Tags/TagsActions'

// Values
import { productValue, getProductParams } from '../../../../../redux/Products/ProductsValues'
import { articleValue } from '../../../../../redux/Articles/ArticlesValues'

// js
import { useTemplate, handleScroll, setHeader } from './js/siteMainDisplay'

// functions
import {
  getContentGroupPage,
  checkAuthProfileRedirect,
  fetchAuthUser,
  fetchProduct,
  fetchArticle,
  fetchFolder,
  fetchTag,
  getPage,
  getPageInStore
} from '../../functions/siteFetchDatas'

// css
import css from './css/siteDisplayContainer.css'
import 'aos/dist/aos.css';

const s3Url = config.app.s3.urlOriginal

const SiteMainDisplayContainer = (props) => {
  const [heightHeader, setHeightHeader] = useState()
  const { business, setting, pages, site, singlePageData, singlePageType, location, match } = props
  const { page, templateData, template } = useMemo(() => useTemplate(props), [
    JSON.stringify(props.location),
    props.product && props.product._id,
    props.folder && props.folder._id,
    props.tag && props.tag._id
  ])


  useEffect(() => {
    const { path_1, path_2 } = match.params

    if (path_1 && path_2 === 'm') {
      let url = location.pathname.replace(`/${path_1}/m/`, '')
      props.history.push(`/${url}`)
    }

    AOS.init();
  }, [])


  checkAuthProfileRedirect(props, { page, templateData })

  const useScroll = () => {
    handleScroll(props, { page })
  }

  useEffect(() => {
    window.addEventListener('scroll', useScroll)
    init()

    return () => {
      window.removeEventListener('scroll', useScroll)
    }
  }, [location.pathname])


  const init = () => {
    const { match, product, folder, tag } = props
    const { path_1, path_2, single_page_products_id } = match.params

    handleScroll(props, { page })
    setHeader(props, setHeightHeader, page)

    if (path_2 && path_2 !== 'm') {
      // folder
      if (path_1 === 'product-categories' || path_1 === 'article-categories') {
        if (!folder) {
          fetchFolder(props, path_2)
        }

      // tag
      } else if (path_1 === 'product-tags' || path_1 === 'article-tags') {
        if (!tag) {
          fetchTag(props, path_2)
        }

      // product
      } else if (path_1 === 'product-details') {
        fetchProduct(props, path_2)

      // article
      } else if (path_1 === 'article-details') {
        fetchArticle(props, path_2)
      }

    } else {

      // product
      if (single_page_products_id) {
        fetchProduct(props, single_page_products_id)

      // customer
      } else if (match.path === '/profile/:path_1?') {
        fetchAuthUser(props)

      } else {
        const page = getPage(props, path_1 || 'index')

        if (page && page.fetchType && page.fetchId) {
          if (page.fetchType === 'product-details') {
            if (!product) {
              fetchProduct(props, page.fetchId)
            }
          }
        }
      }
    }
  }


  const image = site.images.filter(img => img.album === 'favicon')[0]
  const path = image && image.paths && image.paths.o
  const favicon = path && `${s3Url}/${path}`
  const contentGroupPage = getContentGroupPage(props)

  const rerender = [
    props.cart && JSON.stringify(props.cart),
    props.auth && JSON.stringify(props.auth),
    props.singlePageData && JSON.stringify(props.singlePageData),
    props.location && JSON.stringify(props.location),
    props.singlePageType,
    props.product && props.product._id,
    props.folder && props.folder._id,
    props.tag && props.tag._id,
  ]

  return (
    <Fragment>
      { useMemo(() =>
        <Helmet>
          { favicon &&
            <link rel="shortcut icon" type="image/png" href={favicon} />
          }
        </Helmet>, [])
      }

      { useMemo(() => <StylesGlobal site={site} />, []) }

      { useMemo(() =>
        <div id="site-container" className={css.container}>
          { contentGroupPage ?
            <PageHeader
              {...props}
              css={css}
              page={page}
              pageHeader={contentGroupPage}
              templateData={templateData}
              contentGroupPage={contentGroupPage}
            />
          :
            <PageHeader
              {...props}
              css={css}
              page={page}
              pageHeader={page}
              templateData={templateData}
            />
          }

          { page &&
            <div
              id="page-content-display"
              className={css.content}
              style={{
                paddingTop: heightHeader
              }}>

              { contentGroupPage ?
                <ContentGroupPageDisplay
                  {...props}
                  groupId={contentGroupPage._id}
                  datas={{
                    business,
                    setting,
                    site,
                    pages,
                    pageCurrent: page,
                    singlePageData,
                    singlePageType,
                    templateData
                  }}
                />
              :
                <Fragment>
                  { (page && !template) || (page && template && templateData) ?
                    <Fragment>
                      { renderRoutes({
                        routes: props.route.routes,
                        extraProps: {
                          business,
                          setting,
                          site,
                          pages,
                          page,
                          templateData
                        },
                        location: props.location,
                      }) }
                    </Fragment>
                  :
                    <h1>404 Not Found</h1>
                  }
                </Fragment>
              }
            </div>
          }

          <div id="widgets">
            { page &&
              <WidgetsDisplay
                {...props}
                datas={{
                  business,
                  setting,
                  site,
                  pages,
                  pageCurrent: page,
                  singlePageData,
                  singlePageType,
                  templateData
                }}
              />
            }
          </div>

          { contentGroupPage ?
            <PageFooter
              {...props}
              css={css}
              page={page}
              pageFooter={contentGroupPage}
              templateData={templateData}
              contentGroupPage={contentGroupPage}
            />
          :
            <PageFooter
              {...props}
              css={css}
              page={page}
              pageFooter={page}
              templateData={templateData}
            />
          }

          { site.fbCustomerChat && site.fbCustomerChat.status === 'open' && site.fbCustomerChat.page_id &&
            <div
              className="fb-customerchat"
              attribution="setup_tool"
              page_id={site.fbCustomerChat.page_id}
              logged_in_greeting="สวัสดี มีอะไรให้เราช่วยไหม"
              logged_out_greeting="สวัสดี มีอะไรให้เราช่วยไหม">
            </div>
          }
        </div>
      , rerender) }
    </Fragment>
  )
}

SiteMainDisplayContainer.fetchData = (store, match) => {
  const state = store.getState()
  const host = state.host
  const {
    path_1,
    path_2,
    path_3,
    single_page_products_id,
    dbProductsNo,
  } = match.params

  if (path_2 && path_2 !== 'm') {
    // folder
    if (path_1 === 'product-categories' || path_1 === 'article-categories') {
      let type

      if (path_1 === 'product-categories') type = 'product'
      else if (path_1 === 'article-categories') type = "article"

      return store.dispatch(fetchFolderDisplay(path_2, {
        body: { ...host, where: `type=${type}` }
      }))

    // tags
    } else if (path_1 === 'product-tags' || path_1 === 'article-tags') {
      let type

      if (path_1 === 'product-tags') type = 'product'
      else if (path_1 === 'article-tags') type = "article"

      return store.dispatch(fetchTagDisplay(path_2, {
        body: {
          ...host,
          where: `type=${type}`,
        }
      }))

    // page for product
    } else if (path_1 && path_2 === 'item' && path_3) {
      const params = getProductParams(path_3)

      if (params.products_id && params.products_id !== 'm') {
        return store.dispatch(fetchProductOneDisplay(dbProductsNo || 'not-use-db', params.products_id))
      }

    // product
    } else if (path_1 === 'product-details') {
      const params = getProductParams(path_2)

      if (params.products_id && params.products_id !== 'm') {
        return store.dispatch(fetchProductOneDisplay(dbProductsNo || 'not-use-db', params.products_id))
      }

    // article
    } else if (path_1 === 'article-details') {
      return store.dispatch(fetchArticleDisplay(path_2))
    }

  // product single page
  } else if (single_page_products_id) {
    const params = getProductParams(single_page_products_id)

    if (params.products_id && params.products_id !== 'm') {
      return store.dispatch(fetchProductOneDisplay(dbProductsNo || 'not-use-db', params.products_id))
    }
  }
}


const mapStateToProps = (store, props) => {
  const { site, customer, match } = props
  const pages = store.pages.data.filter((page => page.sites_id === site._id))
  const products = store.products.data

  const { path_1, path_2, path_3, single_page_products_id } = match.params

  let product, article, folder, tag, singlePageData, singlePageType

  if (path_2 && path_2 !== 'm') {
    // folder
    if (path_1 === 'product-categories' || path_1 === 'article-categories') {
      folder = store.folders.data.filter(folder => folder.path === path_2)[0]

    // tag
    } else if (path_1 === 'product-tags' || path_1 === 'article-tags') {
      tag = store.tags.data.filter(tag => tag.path === path_2)[0]

    // page for product
    } else if (path_1 && path_2 === 'item' && path_3) {
      const params = getProductParams(path_3)
      product = store.products.data.filter(product => product._id === params.products_id)[0]
      product = productValue({ product, customer, items_id: params.products_items_id })

    // product
    } else if (path_1 === 'product-details') {
      const params = getProductParams(path_2)
      product = store.products.data.filter(product => product._id === params.products_id)[0]
      product = productValue({ product, customer, items_id: params.products_items_id })

    // article
    } else if (path_1 === 'article-details') {
      article = store.articles.data.filter(article => article._id === path_2)[0]
      article = articleValue({ article })
    }

  } else {
    // product single page
    if (single_page_products_id) {
      const params = getProductParams(single_page_products_id)

      product = store.products.data.filter(product => product._id === params.products_id)[0]
      product = productValue({ product, customer, items_id: params.products_items_id })

      singlePageData = product
      singlePageType = 'product'

    } else {
      const page = getPageInStore(pages, path_1 || 'index')

      if (page && page.fetchType && page.fetchId) {
        if (page.fetchType === 'product-details') {
          product = products.filter(product => product._id === page.fetchId)[0]
          product = productValue({ product, customer })
        }
      }
    }
  }

  return {
    app: store.app,
    host: store.host,
    auth: store.auth,
    cart: store.carts.data[0],
    business: store.businesses.data[0],
    customer: store.customers && store.customers.data && store.customers.data[0],
    setting: store.settings.data[0],
    pages,
    singlePageData,
    singlePageType,
    product,
    products,
    article,
    folder,
    tag
  }
}

export default connect(mapStateToProps)(SiteMainDisplayContainer)
