import callApi from '../../util/apiCaller';

// Export Constants
export const ADD_PROFILE = '_ADD_PROFILE';

// Export Actions type
export const _ADD_PROFILE = profile => ({ type: ADD_PROFILE, profile })

// Export Actions function
export const fetchUser = username => dispatch => {
  return callApi(`users/${username}`).then(res => {
    dispatch(_ADD_PROFILE(res.profile))
  })
}
