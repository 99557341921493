import React, { Component } from 'react'

// Components Global
import ListsExpand from '../../../../ListsExpand'
import { generateItems1 } from '../../../../DragDropMenu/functions/dragDropMenuFunctions'

// Fuctions
import {
  getPathActive
} from '../../../../SitesManage/Sites/functions/siteManageFunctions'

class SideNavbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sideNav: false
    }
  }

  handleOpenSideNav = () => {
    this.setState({ sideNav: true })
  }

  render() {
    const lang = this.props.lang || 'local'
    const {
      action,
      closeSideNavbar,
      mode,
      style,
      model,
      iconHidden
    } = this.props

    const { pages } = action.params

    let lists = generateItems1({ items: pages, navbarShow: true })
    lists = lists.filter(page => page.type === 'page')

    const pathActive = getPathActive(this.props, 'path')

    let styleLength = 0

    for (let key in style) {
      if (key) styleLength++
    }

    return (
      <div>
        <ListsExpand
          editor
          style={styleLength !== 0 && style || undefined}
          lang={lang}
          mode={mode}
          onClick={closeSideNavbar}
          activeValue={pathActive}
          lists={lists}
          listsAll={pages}
          pathType="path"
          linkStart={``}
          model={model}
          iconHidden={iconHidden}
        />
      </div>
    )
  }
}

export default SideNavbar
