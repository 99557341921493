// Import Actions
import { SET_HOST } from './HostActions'

// Initial State
const initialState = {}

const HostReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOST:
      return { ...state, host: { url: action.host } }

    default:
      return state
  }
};

// Export Reducer
export default HostReducer
