// Export Constants
export const ADD_PROGRESS = 'ADD_PROGRESS'
export const UPDATE_PROGRESS = 'UPDATE_PROGRESS'
export const REMOVE_PROGRESS = 'REMOVE_PROGRESS'

export const ADD_FILE_PROGRESS = 'ADD_FILE_PROGRESS'
export const UPDATE_FILE_PROGRESS = 'UPDATE_FILE_PROGRESS'
export const REMOVE_FILE_PROGRESS = 'REMOVE_FILE_PROGRESS'

// Export type
//export const addProgress = (id, submit, album=null) => ({ type: ADD_PROGRESS, id, submit, album})
export const addProgress = (id, upload) => ({ type: ADD_PROGRESS, id, upload})
export const updateProgress = (id, status) => ({ type: UPDATE_PROGRESS, id, status })
export const removeProgress = id => ({ type: REMOVE_PROGRESS, id })

export const addFileProgress = (id, file) => ({ type: ADD_FILE_PROGRESS, id, file })
export const updateFileProgress = (id, index, status) => ({ type: UPDATE_FILE_PROGRESS, id, index, status })
export const removeFileProgress = (id, index) => ({ type: REMOVE_FILE_PROGRESS, id, index })


