import {
  inlineStyleActiveValue
} from './textEditorFunctions';


export const fontSizeLists = [
  { level: 7, value: 'rps-font-0-7' },
  { level: 8, value: 'rps-font-0-8' },
  { level: 9, value: 'rps-font-0-9' },

  { level: 10, value: 'rps-font-1-0' },
  { level: 11, value: 'rps-font-1-1' },
  { level: 12, value: 'rps-font-1-2' },
  { level: 13, value: 'rps-font-1-3' },
  { level: 14, value: 'rps-font-1-4' },
  { level: 15, value: 'rps-font-1-5' },
  { level: 16, value: 'rps-font-1-6' },
  { level: 17, value: 'rps-font-1-7' },
  { level: 18, value: 'rps-font-1-8' },
  { level: 19, value: 'rps-font-1-9' },

  { level: 20, value: 'rps-font-2-0' },
  { level: 21, value: 'rps-font-2-1' },
  { level: 22, value: 'rps-font-2-2' },
  { level: 23, value: 'rps-font-2-3' },
  { level: 24, value: 'rps-font-2-4' },
  { level: 25, value: 'rps-font-2-5' },
  { level: 26, value: 'rps-font-2-6' },
  { level: 27, value: 'rps-font-2-7' },
  { level: 28, value: 'rps-font-2-8' },
  { level: 29, value: 'rps-font-2-9' },

  { level: 30, value: 'rps-font-3-0' },
  { level: 31, value: 'rps-font-3-1' },
  { level: 32, value: 'rps-font-3-2' },
  { level: 33, value: 'rps-font-3-3' },
  { level: 34, value: 'rps-font-3-4' },
  { level: 35, value: 'rps-font-3-5' },
  { level: 36, value: 'rps-font-3-6' },
  { level: 37, value: 'rps-font-3-7' },
  { level: 38, value: 'rps-font-3-8' },
  { level: 39, value: 'rps-font-3-9' },

  { level: 40, value: 'rps-font-4-0' },
  { level: 41, value: 'rps-font-4-1' },
  { level: 42, value: 'rps-font-4-2' },
  { level: 43, value: 'rps-font-4-3' },
  { level: 44, value: 'rps-font-4-4' },
  { level: 45, value: 'rps-font-4-5' },
  { level: 46, value: 'rps-font-4-6' },
  { level: 47, value: 'rps-font-4-7' },
  { level: 48, value: 'rps-font-4-8' },
  { level: 49, value: 'rps-font-4-9' },

  { level: 50, value: 'rps-font-5-0' },
  { level: 51, value: 'rps-font-5-1' },
  { level: 52, value: 'rps-font-5-2' },
  { level: 53, value: 'rps-font-5-3' },
  { level: 54, value: 'rps-font-5-4' },
  { level: 55, value: 'rps-font-5-5' },
  { level: 56, value: 'rps-font-5-6' },
  { level: 57, value: 'rps-font-5-7' },
  { level: 58, value: 'rps-font-5-8' },
  { level: 59, value: 'rps-font-5-9' },

  { level: 60, value: 'rps-font-6-0' },
  { level: 61, value: 'rps-font-6-1' },
  { level: 62, value: 'rps-font-6-2' },
  { level: 63, value: 'rps-font-6-3' },
  { level: 64, value: 'rps-font-6-4' },
  { level: 65, value: 'rps-font-6-5' },
  { level: 66, value: 'rps-font-6-6' },
  { level: 67, value: 'rps-font-6-7' },
  { level: 68, value: 'rps-font-6-8' },
  { level: 69, value: 'rps-font-6-9' },

  { level: 70, value: 'rps-font-7-0' },
  { level: 71, value: 'rps-font-7-1' },
  { level: 72, value: 'rps-font-7-2' },
  { level: 73, value: 'rps-font-7-3' },
  { level: 74, value: 'rps-font-7-4' },
  { level: 75, value: 'rps-font-7-5' },
  { level: 76, value: 'rps-font-7-6' },
  { level: 77, value: 'rps-font-7-7' },
  { level: 78, value: 'rps-font-7-8' },
  { level: 79, value: 'rps-font-7-9' },

  { level: 80, value: 'rps-font-8-0' },
  { level: 81, value: 'rps-font-8-1' },
  { level: 82, value: 'rps-font-8-2' },
  { level: 83, value: 'rps-font-8-3' },
  { level: 84, value: 'rps-font-8-4' },
  { level: 85, value: 'rps-font-8-5' },
  { level: 86, value: 'rps-font-8-6' },
  { level: 87, value: 'rps-font-8-7' },
  { level: 88, value: 'rps-font-8-8' },
  { level: 89, value: 'rps-font-8-9' },

  { level: 90, value: 'rps-font-9-0' },
  { level: 91, value: 'rps-font-9-1' },
  { level: 92, value: 'rps-font-9-2' },
  { level: 93, value: 'rps-font-9-3' },
  { level: 94, value: 'rps-font-9-4' },
  { level: 95, value: 'rps-font-9-5' },
  { level: 96, value: 'rps-font-9-6' },
  { level: 97, value: 'rps-font-9-7' },
  { level: 98, value: 'rps-font-9-8' },
  { level: 99, value: 'rps-font-9-9' },

  { level: 100, value: 'rps-font-10-0' }
]


export const fontSizeClassName = (className) => {
  fontSizeLists.map(list => {
    list.label = list.level
    list.className = className
    list.classValue = list.value
    list.show = true
    list.replace = true
  })

  return fontSizeLists
}

export const fontSizeStyleList = (styleName) => {
  fontSizeLists.map(list => {
    list.label = list.level
    list.style = styleName
    list.value = list.level + 'px'
    list.show = true
    list.replace = true
  })

  return fontSizeLists
}

export const fontSizeTextEditor = (editorState) => {
  const titles = [
    { label: 'ขนาดตัวอักษร', show: true, title: true },
    { divider: true, show: true },
  ]

  const lists = [
    { label: 7, style: 'FONT_SIZE_0_7', value: 'FONT_SIZE_0_7', show: true },
    { label: 8, style: 'FONT_SIZE_0_8', value: 'FONT_SIZE_0_8', show: true },
    { label: 9, style: 'FONT_SIZE_0_9', value: 'FONT_SIZE_0_9', show: true },

    { label: 10, style: 'FONT_SIZE_1_0', value: 'FONT_SIZE_1_0', show: true },
    { label: 11, style: 'FONT_SIZE_1_1', value: 'FONT_SIZE_1_1', show: true },
    { label: 12, style: 'FONT_SIZE_1_2', value: 'FONT_SIZE_1_2', show: true },
    { label: 13, style: 'FONT_SIZE_1_3', value: 'FONT_SIZE_1_3', show: true },
    { label: 14, style: 'FONT_SIZE_1_4', value: 'FONT_SIZE_1_4', show: true },
    { label: 15, style: 'FONT_SIZE_1_5', value: 'FONT_SIZE_1_5', show: true },
    { label: 16, style: 'FONT_SIZE_1_6', value: 'FONT_SIZE_1_6', show: true },
    { label: 17, style: 'FONT_SIZE_1_7', value: 'FONT_SIZE_1_7', show: true },
    { label: 18, style: 'FONT_SIZE_1_8', value: 'FONT_SIZE_1_8', show: true },
    { label: 19, style: 'FONT_SIZE_1_9', value: 'FONT_SIZE_1_9', show: true },

    { label: 20, style: 'FONT_SIZE_2_0', value: 'FONT_SIZE_2_0', show: true },
    { label: 21, style: 'FONT_SIZE_2_1', value: 'FONT_SIZE_2_1', show: true },
    { label: 22, style: 'FONT_SIZE_2_2', value: 'FONT_SIZE_2_2', show: true },
    { label: 23, style: 'FONT_SIZE_2_3', value: 'FONT_SIZE_2_3', show: true },
    { label: 24, style: 'FONT_SIZE_2_4', value: 'FONT_SIZE_2_4', show: true },
    { label: 25, style: 'FONT_SIZE_2_5', value: 'FONT_SIZE_2_5', show: true },
    { label: 26, style: 'FONT_SIZE_2_6', value: 'FONT_SIZE_2_6', show: true },
    { label: 27, style: 'FONT_SIZE_2_7', value: 'FONT_SIZE_2_7', show: true },
    { label: 28, style: 'FONT_SIZE_2_8', value: 'FONT_SIZE_2_8', show: true },
    { label: 29, style: 'FONT_SIZE_2_9', value: 'FONT_SIZE_2_9', show: true },

    { label: 30, style: 'FONT_SIZE_3_0', value: 'FONT_SIZE_3_0', show: true },
    { label: 31, style: 'FONT_SIZE_3_1', value: 'FONT_SIZE_3_1', show: true },
    { label: 32, style: 'FONT_SIZE_3_2', value: 'FONT_SIZE_3_2', show: true },
    { label: 33, style: 'FONT_SIZE_3_3', value: 'FONT_SIZE_3_3', show: true },
    { label: 34, style: 'FONT_SIZE_3_4', value: 'FONT_SIZE_3_4', show: true },
    { label: 35, style: 'FONT_SIZE_3_5', value: 'FONT_SIZE_3_5', show: true },
    { label: 36, style: 'FONT_SIZE_3_6', value: 'FONT_SIZE_3_6', show: true },
    { label: 37, style: 'FONT_SIZE_3_7', value: 'FONT_SIZE_3_7', show: true },
    { label: 38, style: 'FONT_SIZE_3_8', value: 'FONT_SIZE_3_8', show: true },
    { label: 39, style: 'FONT_SIZE_3_9', value: 'FONT_SIZE_3_9', show: true },

    { label: 40, style: 'FONT_SIZE_4_0', value: 'FONT_SIZE_4_0', show: true },
    { label: 41, style: 'FONT_SIZE_4_1', value: 'FONT_SIZE_4_1', show: true },
    { label: 42, style: 'FONT_SIZE_4_2', value: 'FONT_SIZE_4_2', show: true },
    { label: 43, style: 'FONT_SIZE_4_3', value: 'FONT_SIZE_4_3', show: true },
    { label: 44, style: 'FONT_SIZE_4_4', value: 'FONT_SIZE_4_4', show: true },
    { label: 45, style: 'FONT_SIZE_4_5', value: 'FONT_SIZE_4_5', show: true },
    { label: 46, style: 'FONT_SIZE_4_6', value: 'FONT_SIZE_4_6', show: true },
    { label: 47, style: 'FONT_SIZE_4_7', value: 'FONT_SIZE_4_7', show: true },
    { label: 48, style: 'FONT_SIZE_4_8', value: 'FONT_SIZE_4_8', show: true },
    { label: 49, style: 'FONT_SIZE_4_9', value: 'FONT_SIZE_4_9', show: true },

    { label: 50, style: 'FONT_SIZE_5_0', value: 'FONT_SIZE_5_0', show: true },
    { label: 51, style: 'FONT_SIZE_5_1', value: 'FONT_SIZE_5_1', show: true },
    { label: 52, style: 'FONT_SIZE_5_2', value: 'FONT_SIZE_5_2', show: true },
    { label: 53, style: 'FONT_SIZE_5_3', value: 'FONT_SIZE_5_3', show: true },
    { label: 54, style: 'FONT_SIZE_5_4', value: 'FONT_SIZE_5_4', show: true },
    { label: 55, style: 'FONT_SIZE_5_5', value: 'FONT_SIZE_5_5', show: true },
    { label: 56, style: 'FONT_SIZE_5_6', value: 'FONT_SIZE_5_6', show: true },
    { label: 57, style: 'FONT_SIZE_5_7', value: 'FONT_SIZE_5_7', show: true },
    { label: 58, style: 'FONT_SIZE_5_8', value: 'FONT_SIZE_5_8', show: true },
    { label: 59, style: 'FONT_SIZE_5_9', value: 'FONT_SIZE_5_9', show: true },

    { label: 60, style: 'FONT_SIZE_6_0', value: 'FONT_SIZE_6_0', show: true },
    { label: 61, style: 'FONT_SIZE_6_1', value: 'FONT_SIZE_6_1', show: true },
    { label: 62, style: 'FONT_SIZE_6_2', value: 'FONT_SIZE_6_2', show: true },
    { label: 63, style: 'FONT_SIZE_6_3', value: 'FONT_SIZE_6_3', show: true },
    { label: 64, style: 'FONT_SIZE_6_4', value: 'FONT_SIZE_6_4', show: true },
    { label: 65, style: 'FONT_SIZE_6_5', value: 'FONT_SIZE_6_5', show: true },
    { label: 66, style: 'FONT_SIZE_6_6', value: 'FONT_SIZE_6_6', show: true },
    { label: 67, style: 'FONT_SIZE_6_7', value: 'FONT_SIZE_6_7', show: true },
    { label: 68, style: 'FONT_SIZE_6_8', value: 'FONT_SIZE_6_8', show: true },
    { label: 69, style: 'FONT_SIZE_6_9', value: 'FONT_SIZE_6_9', show: true },

    { label: 70, style: 'FONT_SIZE_7_0', value: 'FONT_SIZE_7_0', show: true }
  ]

  const active = inlineStyleActiveValue(editorState, lists)

  if (active) {
    const defaultList = [{
      label: 'ค่าเดิม',
      style: active.style,
      value: active.value,
      activeHidden: true,
      show: true
    }]

    return [
      ...titles,
      ...defaultList,
      ...lists
    ]

  } else {
    return [
      ...titles,
      ...lists
    ]
  }
}
