import React, { Fragment } from 'react'
import renderRoutes from '../../../../routes/renderRoutes'

// Components
import MenuAndContent from '../components/MenuAndContent'

const CustomerMenuContainer = (props) => {
  const { route, site, business, customer, auth, location } = props
  const { structure } = site
  const menu = [
    {
      name: 'ข้อมูลของฉัน',
      nameShort: 'ข้อมูล',
      icon: 'account_circle',
      link: `/customer/profile`,
      exact: true,
    },
    {
      name: 'รูปภาพของฉัน',
      nameShort: 'รูปภาพ',
      icon: 'photo',
      link: `/customer/profile/photos`
    },
    {
      name: 'ที่อยู่ของฉัน',
      nameShort: 'ที่อยู่',
      icon: 'room',
      link: `/customer/profile/addresses`
    },
    {
      name: 'โค้ดส่วนลดของฉัน',
      nameShort: 'โค้ดส่วนลด',
      icon: 'aspect_ratio',
      link: `/customer/profile/coupons`
    },
    {
      name: 'ลูกทีมของฉัน',
      nameShort: 'ลูกทีม',
      icon: 'groups',
      link: `/customer/profile/team`,
      exact: true,
    },
  ]

  return (
    <Fragment>
      <MenuAndContent menu={menu} color={structure && structure.color}>
        { customer &&
          <div>
            { renderRoutes({
              routes: route.routes,
              extraProps: { site, business, customer, auth },
              location
            }) }
          </div>
        }
      </MenuAndContent>
    </Fragment>
  )
}

export default CustomerMenuContainer
