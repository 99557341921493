import React from 'react'

// Components Global
import { LayoutsDesignDisplay } from '../../../LayoutsDesign'


const SideNavbarDisplay = (props) => {
  const { datas, match, mode, location, history, closeSideNavbar } = props
  const { business, setting, site, pages, pageCurrent } = datas

  return (
    <LayoutsDesignDisplay
      display
      sideNavbar
      mode={mode}
      match={match}
      location={location}
      history={history}
      pages={pages}
      closeSideNavbar={closeSideNavbar}
      action={{
        params: {
          business,
          setting,
          site,
          pages,
          data: site,
          pageCurrent,
          _id: site._id
        }
      }}
    />
  )
}

export default SideNavbarDisplay
