import React, { Fragment, useMemo } from 'react'
import { connect } from 'react-redux'

// Components Local
import { EditorImageDisplay } from '../components/EditorImage'
import { getDataStore } from '../functions/textEditorEvent'

// Components Editor
import BlockStyle from '../components/BlockStyle'


const TextEditorImageDisplay = (props) => {
  const { content, mode, apiData, templateData, dataStore, type, lang,  } = props
  const image = content.image && JSON.parse(content.image)
  const className = image && image.class ? JSON.parse(image.class) : []
  const style = image && image.style ? JSON.parse(image.style) : {}


  /*return (
    <Fragment>
      { useMemo(() => {
        return (
          <BlockStyle {...props} style={style} className={className}>
            <EditorImageDisplay
              {...props}
              lang={lang || 'local'}
              type={type}
              image={content.image}
              content={content}
              templateData={templateData}
              apiData={apiData}
              dataStore={dataStore}
            />
          </BlockStyle>
        )
      }, [props.match && props.match.url])}
    </Fragment>
  )*/

  return (
    <Fragment>
      { useMemo(() => {
        return (
          <BlockStyle {...props} style={style} className={className}>
            <EditorImageDisplay
              {...props}
              lang={lang || 'local'}
              type={type}
              image={content.image}
              content={content}
              templateData={templateData}
              apiData={apiData}
              dataStore={dataStore}
            />
          </BlockStyle>
        )
      }, [
        props.match && JSON.stringify(props.match),
        props.location && JSON.stringify(props.location),
        props.apiData && JSON.stringify(props.apiData),
        props.templateData && JSON.stringify(props.templateData),
        props.auth && JSON.stringify(props.auth)
      ]) }
    </Fragment>
  )
}

const mapStateToProps = (store, props) => {
  return {
    dataStore: getDataStore({ store, props })
  }
}

export default connect(mapStateToProps)(TextEditorImageDisplay)
