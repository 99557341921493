import Immutable from 'immutable'


const datasReplaceByGroupId = (state, actionDatas, params) => {
  const storeDatas = state && state.data && state.data.slice()
  const group = actionDatas && actionDatas[0] && actionDatas[0]
  const { groupId, groupIdReplace } = group || {}

  if (groupId) {
    if (actionDatas.length !== 0) {
      const findData = storeDatas.filter(data => data.groupId === groupId)[0]

      if (findData) {
        if (groupIdReplace) {
          return actionDatas

        } else {
          const { ungroupDataId } = params

          if (ungroupDataId) {
            const ungroupData = storeDatas.filter(data => data._id === ungroupDataId)[0]
            const storeDatasNew = storeDatas.filter(data => data.groupId !== groupId)

            if (ungroupData) {
              if (ungroupData.groupId) {
                delete ungroupData.groupId

                const checkDuplicate = storeDatasNew.filter(data => data._id === ungroupData._id)[0]

                if (!checkDuplicate) {
                  storeDatasNew.push(ungroupData)
                }
              }
            }

            return storeDatasNew.concat(actionDatas)

          } else {
            return storeDatas
          }
        }

      } else {
        return storeDatas.concat(actionDatas)
      }
    } else {
      return storeDatas
    }
  } else {
    return actionDatas
  }
}

export const stateSearchDefault = () => {
  return { status: false, value: '', data: [], count: 0, searchParams: [] }
}

export const stateAddDatas = (state, action, actionDatas) => {
  if (action.search) {
    return stateSearch(state, action, actionDatas)
  } else {
    const datas = datasReplaceByGroupId(state, actionDatas, action.params)

    return { data: datas, search: stateSearchDefault() }
  }
}

export const stateAddMoreDatas = (state, action, actionDatas) => {
  if (action.search) {
    return stateSearch(state, action, state.search.data.concat(actionDatas), true)
  } else {
    return { data: state.data.concat(actionDatas), search: stateSearchDefault() }
  }
}

export const stateAddData = (state, action, actionData, order='first') => {
  if (state.search && state.search.status) {

    let data

    if (order === 'first') {
      data = [actionData, ...state.data]
    } else if (order === 'last') {
      data = [...state.data, actionData]
    }

    return {
      ...state,
      data,
      search: { ...state.search, data: [actionData, ...state.search.data] }
    }
  } else {
    let data

    if (order === 'first') {
      data = [actionData, ...state.data]
    } else if (order === 'last') {
      data = [...state.data, actionData]
    }

    return { ...state, data }
  }
}

export const stateUpdate = (state, action, actionData) => {
  if (state.search && state.search.status) {
    return { ...state,
      data: update(state, action, actionData),
      search: { ...state.search, data: update(state, action, actionData, true) }
    }
  } else {
    return { ...state,
      data: update(state, action, actionData),
    }
  }
}


export const stateDelete = (state, action) => {
  if (action.groupId) {
    const data = state.data.filter(v => {
      if (v._id === action._id && v.groupId === action.groupId) {
        return false
      } else {
        return true
      }
    })

    return {
      data,
      search: stateSearchDefault()
    }

  } else {
    if (typeof action._id === 'string') {
      return {
        data: state.data.filter(data => data._id !== action._id),
        search: stateSearchDefault()
      }
    } else if (typeof action._id === 'object') {
      const _id = action._id

      let cond = 'data => ', i = 0

      for (let key in _id) {
        if (i === 0) {
          cond = `${cond} data._id.${key} !== '${_id[key]}'`
        } else {
          cond = `${cond} && data._id.${key} !== '${_id[key]}'`
        }

        i++
      }

      return {
        data: state.data.filter(eval(cond)),
        search: stateSearchDefault()
      }
    }
  }
}



const stateSearch = (state, action, data, loadMore=false) => {
  if (!loadMore) {
    return {
      ...state,
      search: {
        status: action.search.status,
        value: action.search.value,
        count: action.search.count || 0,
        data: data,
        params: action.search.params
      }
    }
  } else {
    return {
      ...state,
      search: {
        status: state.search.status,
        value: state.search.value,
        count: state.search.count || 0,
        data: data,
        params: state.search.params
      }
    }
  }
}

const update = (state, action, actionData, search=false) => {
  let stateDtate

  if (search) stateDtate = state.search.data
  else stateDtate = state.data

  if (action.groupId) {
    const index = stateDtate.findIndex(data => data._id === action._id && data.groupId === action.groupId)
    const datas = stateDtate.filter(data => data._id === action._id && data.groupId === action.groupId)[0]
    const nested = Immutable.fromJS(stateDtate)
    const updated = nested.setIn([index], actionData)

    return updated.toJS()

  } else {
    const index = stateDtate.findIndex(data => data._id === action._id)
    const datas = stateDtate.filter(data => data._id === action._id)[0]
    const nested = Immutable.fromJS(stateDtate)
    const updated = nested.setIn([index], actionData)

    return updated.toJS()
  }
}
