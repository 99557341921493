import {
  stateSearchDefault,
  stateAddOneData,
  stateAddReplaceOneData,
  stateAddsManyDatas,
  stateAddsManyMoreDatas,
  stateUpdateOneData,
  stateRemoveOneData
} from 'core/utils/reduxManage/stateManage'

import {
  ADD_ONE,
  ADD_REPLACE_ONE,
  ADDS_MANY,
  ADDS_MANY_MORE,
  UPDATE_ONE,
  REMOVE_ONE
} from './ConversationMessagesActions'


const initialState = {
  data: [],
  dataGroups: [],
  search: stateSearchDefault(),
}

const ConversationMessagesReducer = (state: any = initialState, action: any) => {
  const actionDatas = action.datas
  const actionData = action.data

  switch (action.type) {
    case ADD_ONE:
      return stateAddOneData(state, action, actionData)

    case ADD_REPLACE_ONE:
      return stateAddReplaceOneData(state, action, actionData)

    case ADDS_MANY:
      return stateAddsManyDatas(state, action, actionDatas)

    case ADDS_MANY_MORE:
      return stateAddsManyMoreDatas(state, action, actionDatas)

    case UPDATE_ONE:
      return stateUpdateOneData(state, action, actionData)

    case REMOVE_ONE:
      return stateRemoveOneData(state, action, actionData)

    default:
      return state
  }
}

export default ConversationMessagesReducer
