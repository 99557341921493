import React from 'react'
import { connect } from 'react-redux'
import LazyLoad from 'react-lazyload'

// Components Editor
import BlockStyle from '../components/BlockStyle'

// Components Local
import Carousel from './components/Carousel'
import { getLazyLoadParams } from '../../../util/getLazyLoadParams'


const TextEditorCarouselDisplay = (props) => {
  const lang = props.lang || 'local'
  const content = props.content
  const carousel = content.carousel && JSON.parse(content.carousel)
  const style = carousel && carousel.style ? JSON.parse(carousel.style) : {}
  const className = carousel && carousel.class ? JSON.parse(carousel.class) : []
  const items = carousel && carousel.items ? JSON.parse(carousel.items) : []
  const api = carousel && carousel.api ? JSON.parse(carousel.api) : ''
  const lazyLoadParams = getLazyLoadParams(props)

  return (
    <LazyLoad {...lazyLoadParams}>
      <BlockStyle {...props} style={style} className={className}>
        <Carousel
          {...props}
          display
          lang={lang}
          className={className}
          style={style}
          items={items}
          api={api}
        />
      </BlockStyle>
    </LazyLoad>
  )
}

export default connect()(TextEditorCarouselDisplay)
