import React from "react"

// Components Global
import { LayoutsDesignDisplay } from '../../../LayoutsDesign'

const PagePartsDisplay = (props) => {
  const { groupId } = props
  const { business, setting, site, page } = props.datas

  return (
    <LayoutsDesignDisplay
      {...props}
      display
      groupId={groupId}
      bodyColorNone
      action={{
        params: {
          business,
          setting,
          site,
          data: page,
          _id: page._id
        }
      }}
    />
  )
}

export default PagePartsDisplay
