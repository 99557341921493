import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import TextEditorTabs from './TextEditorTabs'
import TextEditorTabsDisplay from './TextEditorTabsDisplay'

let TextEditorTabs = lazy(() => import('./TextEditorTabs'))
TextEditorTabs = suspenseComponent(TextEditorTabs)

export {
  TextEditorTabs,
  TextEditorTabsDisplay
}
