import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import TextEditorModal from './TextEditorModal'
import TextEditorModalDisplay from './TextEditorModalDisplay'

let TextEditorModal = lazy(() => import('./TextEditorModal'))
TextEditorModal = suspenseComponent(TextEditorModal)

export {
  TextEditorModal,
  TextEditorModalDisplay
}
