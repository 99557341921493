const regex = /^:+[a-zA-Z0-9_]+\(+[a-zA-Z_|0-9]+\)/g

const getParams = (path, endpoint) => {
  const pathSlipt = path.split('/')
  const endpointSlipt = endpoint.split('/')

  const params = {}

  pathSlipt.map((v, i) => {
    const search = v.search(':')

    if (search === 0) {
      let key = v
      let value = endpointSlipt[i]

      if (key.search(regex) !== -1) {
        let keySplit = key.split('(')
        key = keySplit[0]
        key = key.replace(':', '')

        let enums = keySplit[1]
        enums = enums.replace(')', '')
        enums = enums.split('|')
        value = enums.filter(v => v === value)[0]

      } else {
        key = key.replace(':', '')
      }

      params[key] = value
    }
  })

  return params
}

const convertPath = (path, params) => {
  let newPath = ''
  const pathSplit = path.split('/')

  pathSplit.map(v => {
    if (v) {
      const search = v.search(':')

      if (search === 0) {
        let key

        if (v.search(regex) !== -1) {
          key = v.split('(')
          key = key[0]
          key = key.replace(':', '')

        } else {
          key = v.replace(':', '')
        }

        newPath = `${newPath}/${params[key]}`
      } else {
        newPath = `${newPath}/${v}`
      }
    }
  })

  return newPath
}

const Router = async ({ routes, endpoint, method, body }) => {
  endpoint = `/${endpoint}`
  routes = routes.filter(route => route.method === method)

  let route

  routes.map(r => {
    if (!route) {
      const params = getParams(r.path, endpoint)
      const newPath = convertPath(r.path, params)

      if (endpoint === newPath) {
        r.params = params
        route = r
      }
    }
  })

  if (route) {
    return await route.action({ body, params: route.params })
  } else {
    return {
      success: false,
      error: 'route is not match'
    }
  }
}

export default Router
