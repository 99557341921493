import React from 'react'
import { connect } from 'react-redux'

// Components Local
import Sidebar from './components/Sidebar'


const TextEditorSidebarDisplay = (props) => {
  const { content } = props
  const sidebar = content.sidebar && JSON.parse(content.sidebar)
  const style = sidebar && sidebar.style ? JSON.parse(sidebar.style) : {}

  return (
    <Sidebar {...props} style={style} />
  )
}

export default connect()(TextEditorSidebarDisplay)
