import React from 'react'
import ProfileAvatar from '../ProfileAvatar'
import css from './css/datasList.css'

const DatasList = (props) => {
  const items = props.items.filter(item => item.hidden !== true)

  return (
    <div className={`${css.boxList} ${props.className}`} style={props.style}>
      { props.type === 'basic' ?
        <div>
          { items.map((item, i) => {
            return (
              <div key={i} className={`text-left pd-10 ${i != 0 ? '' : 'border-top-1 border-top-efefef'}`}>
                <div className="font-0-7">{item.label} :</div>
                <div className="font-0-9 pd-top-5">{item.value || '-'}</div>
              </div>
            )
          }) }
        </div>
      :
        <table>
          <tbody>
            { items.map((item, i) => {

              let tdIconWidth = props.tdIconWidth ? props.tdIconWidth : '40'
              let avatarWidth = parseInt(tdIconWidth) - 5

              if (!item.hidden) {
                let icon, iconClassName

                if (item.iconFa) {
                  icon = ''
                  iconClassName = `${item.iconFa} ${item.iconClassName} font-1-7`
                } else {
                  icon = item.icon || props.defaultIcon
                  iconClassName = `material-icons ${item.iconClassName} font-1-7`
                }

                return (
                  <tr key={i}>
                    { (item.icon || item.iconFa || props.defaultIcon) &&
                      <td className={`pd-5 center width-${tdIconWidth} ${props.labelTop ? 'vertical-top pd-top-10' : ''}`}>
                        <i style={{ color: item.iconColor }} className={iconClassName}>
                          {icon}
                        </i>
                      </td>
                    }

                    { item.profileAvatar &&
                      <td className={`pd-5 center width-${tdIconWidth}`}>
                        <div style={{ width: `${avatarWidth}px`, height: `${avatarWidth}px`}}>
                          <ProfileAvatar
                            circle={item.profileAvatar.circle}
                            images={item.profileAvatar.images}
                            imageSize="s"
                            color={item.profileAvatar.color}
                            texts={item.profileAvatar.texts}
                            textSize={item.profileAvatar.textSize}
                          />
                        </div>
                      </td>
                    }

                    { item.iconCircle &&
                      <td className={`pd-5 center width-${tdIconWidth}`}>
                        <div style={{ background: item.bgColor, width: `${avatarWidth}px`, height: `${avatarWidth}px`, lineHeight: `${avatarWidth}px` }} className="center color-fff border-radius-50">
                          <i className={`material-icons i-middle ${item.iconClassName}`}>{item.iconCircle}</i>
                        </div>
                      </td>
                    }

                    <td className={`pd-5 ${item.className} ${ i !== 0 && 'border-top-1 border-top-eee'}`}>
                      { (item.labelTop || props.labelTop) ?
                        <div>
                          <div className="font-0-7" style={{ color: '#7d7d7d' }} dangerouslySetInnerHTML={{ __html: item.label }} />
                          <div style={{ whiteSpace: 'pre-wrap' }} className="font-0-9 pd-top-5" dangerouslySetInnerHTML={{ __html: item.value || props.defaultValue }} />
                        </div>
                      :
                        <div>
                          <div style={{ whiteSpace: 'pre-wrap' }} className="font-0-9" dangerouslySetInnerHTML={{ __html: item.value || props.defaultValue }} />
                          <div className="font-0-7 pd-top-5" style={{ color: '#7d7d7d' }} dangerouslySetInnerHTML={{ __html: item.label }} />
                        </div>
                      }

                    </td>
                    <td className="pd-0 width-10"></td>
                  </tr>
                )
              }

            }) }
          </tbody>
        </table>
      }
    </div>
  )
}

export default DatasList
