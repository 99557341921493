import React from 'react'
import { connect } from 'react-redux'

// Components Global
import { BtnModalOpen } from '../BtnModal'
import ModalDefault from '../ModalDefault'

// Components Local
import ContentAndContentRight from './components/ContentAndContentRight'

// Redux Actions
import { closeModal } from '../../../redux/App/AppActions'

// css files
import css from './css/modal.css'


const ModalFixed = (props) => {
  const modalClose = () => {
    setTimeout(() => {
      props.dispatch(closeModal(props.id))

      if (props.fnCloseModal) {
        props.fnCloseModal()
      }

      if (props.eventModalClose) {
        props.eventModalClose()
      }
    }, 0)
  }

  return (
    <div>
      { props.btnOpen &&
        <BtnModalOpen id={props.id}>
          {props.btnOpen}
        </BtnModalOpen>
      }

      <ModalDefault fixed {...props}>
        <div className={`${css.fullLayout} overflow-hidden`}>

          <div className={`${css.header} border-bottom-ddd border-bottom-1 ${props.headerClassName || ''}`} style={props.headerStyle}>
            <table>
              <tbody>
                <tr>
                  <td className="pd-10 font-1-1">
                    {props.title}
                  </td>
                  <td className="pd-5 width-50">
                    <a onClick={modalClose} className="right btn btn-white btn-shadow-none border-ddd border-radius-circle width-40 height-40 center line-height-40 pd-0">
                      <i className="material-icons font-1-8 color-333">close</i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>

            { props.headerComponent && props.headerComponent }
          </div>

          { !props.contentAndContentRight &&
            <div>
              <div id={props.id} className={`${css.body} ${props.bodyClassName || ''}`} style={props.bodyStyle}>
                { props.children[0] }
              </div>

              <div className={`${css.footer} border-top-ddd border-top-1 ${props.footerClassName || ''}`} style={props.footerStyle}>
                { props.children[1] }
              </div>
            </div>
          }

          { props.contentAndContentRight &&
            <ContentAndContentRight {...props} />
          }
        </div>
      </ModalDefault>
    </div>
  )
}

export default connect()(ModalFixed)
