import React from 'react'
import { connect } from 'react-redux'

// Components Local
import Tabs from './components/Tabs'


const TextEditorTabsDisplay = (props) => {
  const lang = props.lang || 'local'
  const content = props.content
  const tabs = content.tabs && JSON.parse(content.tabs)

  const style = tabs && tabs.style ? JSON.parse(tabs.style) : {}
  const className = tabs && tabs.class ? JSON.parse(tabs.class) : []
  const items = tabs && tabs.items ? JSON.parse(tabs.items) : []

  return (
    <Tabs
      {...props}
      display
      lang={lang}
      items={items}
      style={style}
      className={className}
    />
  )
}

export default connect()(TextEditorTabsDisplay)
