import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import Bowser from 'bowser'

import css from './css/tabsTop.css'

class MenuTabs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrollbarShow: false,
      active: this.props.activeId
    }
  }

  componentDidMount() {
    this.getStyle()
    this.handleResize()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.event !== this.props.event) {
      this.getStyle()
    }
  }

  getStyle = () => {
    setTimeout(() => {
      let elem = document.getElementById('scrollbar')

      if (elem && elem.clientWidth !== 0 && elem.clientWidth !== 0) {
        if (elem.clientWidth < elem.scrollWidth) {
          this.setState({ scrollbarShow: true })
        } else {
          this.setState({ scrollbarShow: false })
        }
      }
    }, 0)
  }

  handleResize = () => {
    window.onresize = (event) => {
      this.getStyle()
    }
  }

  getActiveId = (id) => {
    this.setState({ active: id })
  }

  render() {
    const { circle } = this.props
    let style


    if (Bowser.mobile || Bowser.tablet) {
      style = {}
    } else {
      if (this.props.items.length > 1) {
        style = { paddingBottom: this.state.scrollbarShow ? '60px' : '0' }
      } else {
        style = {}
      }
    }


    let joinStyle = {...style, ...this.props.style}

    const items = this.props.items.filter(item => !item.title)

    return (
      <Fragment>
        { circle ?
          <ul id="scrollbar" className={`${css.tabsCircle} ${this.props.className}`} style={joinStyle}>
            { items.map((item, i) => {
              return (
                <li key={i} className={`${css.tab} ${item.disabled && css.disabled}`}>
                  <a
                    onClick={() => !item.disabled && this.getActiveId(item.id) }
                    style={this.props.styleLink}
                    className={`${!item.disabled && 'waves-effect'} ${this.state.active === item.id && css.activeCircle}`}>

                    <i className="material-icons i-middle">{item.icon}</i>
                  </a>
                </li>
              )
            }) }
          </ul>
        :
          <ul id="scrollbar" className={`${css.tabs} ${this.props.className}`} style={joinStyle}>
            { items.map((item, i) => {
              return (
                <li key={i} className={`${css.tab} ${item.disabled && css.disabled}`}>
                  <a
                    onClick={() => !item.disabled && this.getActiveId(item.id) }
                    style={this.props.styleLink}
                    className={`${!item.disabled && 'waves-effect'} ${this.state.active === item.id && css.active}`}>

                    <i className="material-icons left i-middle">{item.icon}</i> {item.nameShort ? item.nameShort : item.name}
                  </a>
                </li>
              )
            }) }
          </ul>
        }

        <Fragment>
          { this.props.children.length ?
            this.props.children.map((children, i) => {
              return (
                <Fragment key={i}>
                  { children.props.id === this.state.active && children }
                </Fragment>
              )
            })
          :
            this.props.children
          }
        </Fragment>
      </Fragment>
    )
  }
}

export default MenuTabs
