import React, { Fragment, useMemo } from 'react'
import { lazy } from 'react'
import { connect } from 'react-redux'

// utils
import { getLang } from '../../../util/getLang'

import suspenseComponent from '../../../util/suspenseComponent'

// Components local
import { TextEditorImageDisplay } from '../../TextEditor/TextEditorImage'
import { TextEditorImageGalleryDisplay } from '../../TextEditor/TextEditorImageGallery'
import { TextEditorButtonsDisplay } from '../../TextEditor/TextEditorButtons'
import { TextEditorImageSliderDisplay } from '../../TextEditor/TextEditorImageSlider'
import { TextEditorLineDisplay } from '../../TextEditor/TextEditorLine'
import { TextEditorSpaceDisplay } from '../../TextEditor/TextEditorSpace'
import { TextEditorIconDisplay } from '../../TextEditor/TextEditorIcon'

import { TextEditorVideoDisplay } from '../../TextEditor/TextEditorVideo'
import { TextEditorTableDisplay } from '../../TextEditor/TextEditorTable'
import { TextEditorNavbarDisplay } from '../../TextEditor/TextEditorNavbar'
import { TextEditorSideNavbarDisplay } from '../../TextEditor/TextEditorSideNavbar'
import { TextEditorTabsDisplay } from '../../TextEditor/TextEditorTabs'
import { TextEditorListsDisplay } from '../../TextEditor/TextEditorLists'
import { TextEditorItemListsApiDisplay } from '../../TextEditor/TextEditorItemListsApi'
import { TextEditorHeadingOrDescriptionsDisplay } from '../../TextEditor/TextEditorHeadingOrDescriptions'
import { TextEditorAddToCartDisplay } from '../../TextEditor/TextEditorAddToCart'
import { TextEditorCarouselDisplay } from '../../TextEditor/TextEditorCarousel'
import { TextEditorColumnsDisplay } from '../../TextEditor/TextEditorColumns'
import { TextEditorBreadcrumbsDisplay } from '../../TextEditor/TextEditorBreadcrumbs'
import { TextEditorSidebarDisplay } from '../../TextEditor/TextEditorSidebar'
import { TextEditorModalDisplay } from '../../TextEditor/TextEditorModal'
import { TextEditorFormLoginDisplay } from '../../TextEditor/TextEditorFormLogin'

import { TextEditorShoppingCartManageDisplay } from '../../TextEditor/TextEditorShoppingCartManage'

import { TextEditorLoginSocialsDisplay } from '../../TextEditor/TextEditorLoginSocials'
import { TextEditorWidgetDisplay } from '../../TextEditor/TextEditorWidget'
import { TextEditorTemplateDisplay } from '../../TextEditor/TextEditorTemplate'
import { TextEditorContentDescriptionsDisplay } from '../../TextEditor/TextEditorContentDescriptions'


//import { TextEditorShoppingCartDisplay } from '../../TextEditor/TextEditorShoppingCart'
//import { TextEditorCheckoutDisplay } from '../../TextEditor/TextEditorCheckout'
//import { TextEditorGoogleMapDisplay } from '../../TextEditor/TextEditorGoogleMap'

let TextEditorGoogleMapDisplay = lazy(() => import('../../TextEditor/TextEditorGoogleMap/TextEditorGoogleMapDisplay'))
TextEditorGoogleMapDisplay = suspenseComponent(TextEditorGoogleMapDisplay)

let TextEditorContentDisplay = lazy(() => import('../../TextEditor/TextEditorContent/TextEditorContentDisplay'))
TextEditorContentDisplay = suspenseComponent(TextEditorContentDisplay)

let TextEditorShoppingCartDisplay = lazy(() => import('../../TextEditor/TextEditorShoppingCart/TextEditorShoppingCartDisplay'))
TextEditorShoppingCartDisplay = suspenseComponent(TextEditorShoppingCartDisplay)

let TextEditorCheckoutDisplay = lazy(() => import('../../TextEditor/TextEditorCheckout/TextEditorCheckoutDisplay'))
TextEditorCheckoutDisplay = suspenseComponent(TextEditorCheckoutDisplay)

let TextEditorFormDisplay = lazy(() => import('../../TextEditor/TextEditorForm/TextEditorFormDisplay'))
TextEditorFormDisplay = suspenseComponent(TextEditorFormDisplay)

let TextEditorInputDisplay = lazy(() => import('../../TextEditor/TextEditorInput/TextEditorInputDisplay'))
TextEditorInputDisplay = suspenseComponent(TextEditorInputDisplay)

let TextEditorDropdownDisplay = lazy(() => import('../../TextEditor/TextEditorDropdown/TextEditorDropdownDisplay'))
TextEditorDropdownDisplay = suspenseComponent(TextEditorDropdownDisplay)

let TextEditorInputAlbumDisplay = lazy(() => import('../../TextEditor/TextEditorInputAlbum/TextEditorInputAlbumDisplay'))
TextEditorInputAlbumDisplay = suspenseComponent(TextEditorInputAlbumDisplay)

let TextEditorEditFieldDisplay = lazy(() => import('../../TextEditor/TextEditorEditField/TextEditorEditFieldDisplay'))
TextEditorEditFieldDisplay = suspenseComponent(TextEditorEditFieldDisplay)

let TextEditorCodeDisplay = lazy(() => import('../../TextEditor/TextEditorCode/TextEditorCodeDisplay'))
TextEditorCodeDisplay = suspenseComponent(TextEditorCodeDisplay)


const ContentBlockDisplay = (props) => {

  const { block, match, action, actionNested, section, mode, loggedIn, header, headerFixed, apiData, dataStore, site, closeSideNavbar, closeWindowModal } = props
  const { templateData } = action.params
  const { _id } = block

  let lang = 'local'

  if (mode) {
    lang = props.lang || 'local'
  } else {
    if (match && match.params) {
      lang = match.params.lang || 'local'
    }
  }

  action.params.site = site

  const editorParams = {
    lang,
    id: _id,
    content: block,
    action,
    actionNested,
    section,
    mode,
    loggedIn,
    templateData,
    apiData,
    dataStore,
    match,
    closeSideNavbar,
    closeWindowModal
  }

  const changeComponent = [
    mode,
    loggedIn && JSON.stringify(loggedIn),
    props.match && JSON.stringify(props.match),
    props.apiData && JSON.stringify(props.apiData),
    props.templateData && JSON.stringify(props.templateData),
    props.dataStore && JSON.stringify(props.dataStore),
    props.action.params && JSON.stringify(props.action.params)
  ]

  return (
    <Fragment>
      { block.type === 'code' && props.match &&
        useMemo(() => <TextEditorCodeDisplay {...editorParams} />, [props.action.params && JSON.stringify(props.action.params)])
      }

      { block.type === 'edit-field' &&
        <TextEditorEditFieldDisplay {...editorParams} />
      }

      { block.type === 'input-album' &&
        <TextEditorInputAlbumDisplay {...editorParams} />
      }

      { block.type === 'dropdown' &&
        <TextEditorDropdownDisplay {...editorParams} />
      }

      { block.type === 'input' &&
        <TextEditorInputDisplay {...editorParams} />
      }

      { block.type === 'form' &&
        <TextEditorFormDisplay {...editorParams} />
      }

      { block.type === 'navbar' &&
        <TextEditorNavbarDisplay
          {...editorParams}
          header={header}
          headerFixed={headerFixed}
        />
      }

      { block.type === 'content-descriptions' &&
        <TextEditorContentDescriptionsDisplay {...editorParams} />
      }

      { block.type === 'template' &&
        <TextEditorTemplateDisplay {...editorParams} />
      }

      { block.type === 'widget' &&
        <TextEditorWidgetDisplay {...editorParams} />
      }

      { block.type === 'login-socials' &&
        <TextEditorLoginSocialsDisplay {...editorParams} />
      }

      { block.type === 'checkout' &&
        <TextEditorCheckoutDisplay {...editorParams} />
      }

      { block.type === 'shopping-cart-manage' &&
        <TextEditorShoppingCartManageDisplay {...editorParams} />
      }

      { block.type === 'shopping-cart' &&
        <TextEditorShoppingCartDisplay {...editorParams} />
      }

      { block.type === 'form-login' &&
        <TextEditorFormLoginDisplay {...editorParams} />
      }

      { block.type === 'modal' &&
        <TextEditorModalDisplay {...editorParams} />
      }

      { block.type === 'sidebar' &&
        <TextEditorSidebarDisplay {...editorParams} />
      }

      { block.type === 'breadcrumbs' &&
        <TextEditorBreadcrumbsDisplay {...editorParams} />
      }

      { block.type === 'columns' &&
        useMemo(() => <TextEditorColumnsDisplay {...editorParams} />, changeComponent)
      }

      {/* block.type === 'columns' &&
        <TextEditorColumnsDisplay {...editorParams} />
    */}

      { block.type === 'carousel' &&
        <TextEditorCarouselDisplay {...editorParams} />
      }

      { block.type === 'add-to-cart' &&
        <TextEditorAddToCartDisplay {...editorParams} />
      }

      { block.type === 'descriptions' &&
        <TextEditorHeadingOrDescriptionsDisplay {...editorParams} type="descriptions" />
      }

      { block.type === 'heading' &&
        <TextEditorHeadingOrDescriptionsDisplay {...editorParams} type="heading" />
      }

      { block.type === 'item-lists-api' &&
        <TextEditorItemListsApiDisplay {...editorParams} />
      }

      { block.type === 'lists' &&
        <TextEditorListsDisplay {...editorParams} />
      }

      { block.type === 'tabs' &&
        <TextEditorTabsDisplay {...editorParams} />
      }

      { block.type === 'side-navbar' &&
        <TextEditorSideNavbarDisplay
          {...props}
          lang={lang}
          content={block}
          mode={mode}
          match={match}
        />
      }

      { block.type === 'video' &&
        <TextEditorVideoDisplay {...editorParams} />
      }

      { block.type === 'google-map' &&
        <TextEditorGoogleMapDisplay {...editorParams} />
      }

      { block.type === 'icon' &&
        <TextEditorIconDisplay {...editorParams} />
      }

      { block.type === 'gallery' &&
        <TextEditorImageGalleryDisplay {...editorParams} />
      }

      { block.type === 'table' &&
        <TextEditorTableDisplay {...editorParams} />
      }

      { block.type === 'button' &&
        <TextEditorButtonsDisplay {...editorParams} />
      }

      { block.type === 'line' &&
        <TextEditorLineDisplay {...editorParams} />
      }

      { block.type === 'space' &&
        <TextEditorSpaceDisplay {...editorParams} />
      }

      { block.type === 'cover' &&
        <TextEditorImageDisplay {...editorParams} type="cover" />
      }

      { block.type === 'image' &&
        useMemo(() => <TextEditorImageDisplay {...editorParams} type="image" />, changeComponent)
      }

      { block.type === 'image-content' &&
        <TextEditorImageDisplay {...editorParams} type="image-content" />
      }

      { block.type === 'image-slider' &&
        useMemo(() => <TextEditorImageSliderDisplay {...editorParams} />, [])
      }

      { block.type === 'title' &&
        <TextEditorContentDisplay {...editorParams} type="title" />
      }

      { block.type === 'paragraph' &&
        <TextEditorContentDisplay {...editorParams} />
      }
    </Fragment>
  )
}

const mapStateToProps = (state, props) => {
  const { lang } = getLang({ state, props })

  return {
    lang
  }
}

export default connect(mapStateToProps)(ContentBlockDisplay)
