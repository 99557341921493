import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

// components local
import ContentBlockDisplay from './ContentBlockDisplay'

const ContentBlockLazy = lazy(() => import('./ContentBlock'))
const ContentBlock = suspenseComponent(ContentBlockLazy)

export { ContentBlock, ContentBlockDisplay }
