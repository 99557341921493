
export const generateTypeName = (NAME) => {
  const ADDS = 'ADD_' + NAME[0]
  const ADDS_MORE = 'ADD_MORE_' + NAME[0]
  const ADD = 'ADD_' + NAME[1]
  const UPDATE = 'UPDATE_' + NAME[1]
  const REMOVE = 'REMOVE_' + NAME[1]

  return { ADDS, ADDS_MORE, ADD, UPDATE, REMOVE }
}

export const generateTypeFunctions = (NAME) => {
  const { ADDS, ADDS_MORE, ADD, UPDATE, REMOVE } = generateTypeName(NAME)

  const adds = (datas, search=false, params=null) => ({ type: ADDS, datas, search, params })
  const adds_more = (datas, search=false) => ({ type: ADDS_MORE, datas, search })
  const add = (data, order) => ({ type: ADD, data, order })
  const update = (data, _id, event=null) => ({ type: UPDATE, data, _id, event })
  const remove = (_id) => ({ type: REMOVE, _id })

  return { adds, adds_more, add, update, remove }
}

export const getTypePath = (businessType) => {
  let path = ''

  if (businessType === 'rubber') {
    path = `${businessType}/`
  }

  return path
}


