import { ADD_PROFILE } from './ProfileActions';

// Initial State
const initialState = {};

const ProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PROFILE:
      return action.profile;

    default:
      return state;
  }
};

// Export Reducer
export default ProfileReducer;
