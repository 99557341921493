import moment from 'moment'

// Values
import { getPaymentMethodsList } from '../Settings/SettingsValues'


export const businessValue = ({ business, lang='local' }) => {
  let companyName, nameLang, branchName, avatarName, address, phone, tel, mobile, telShort, mobileShort

  // general
  if (business) {
    companyName = business.companyName ? business.companyName[lang] : null
    nameLang = business.name ? business.name[lang] : null
    branchName = business.branchName ? business.branchName[lang] : null
    avatarName = nameLang ? [nameLang] : []

    // contact
    address = business.address ? business.address[lang] : null
    phone = business.phone && business.phone.number ? `${business.phone.number}` : null
    tel = business.tel && business.tel.number ? `${business.tel.number}` : null
    mobile = business.mobile && business.mobile.number ? `${business.mobile.number}` : null
    telShort = business.tel && business.tel.number ? `${business.tel.number}` : null
    mobileShort = business.mobile && business.mobile.number ? `${business.mobile.number}` : null
  }

  return {
    ...business,
    nameLang,
    companyName,
    branchName,
    avatarName,
    address,
    phone,
    tel,
    mobile,
    telShort,
    mobileShort
  }
}


export const expirationDate = (packages) => {
  if (packages) {
    let data = packages

    if (data.name !== null) {
      let now = moment(new Date())
      let expirationDate = moment(data.expirationDate)
      let duration = moment.duration(expirationDate.diff(now))
      let minutes = duration.asMinutes()

      if (minutes < 0) {
        return true
      } else {
        return false
      }

    } else {
      return true
    }
  }
}

export const businessShippingFeeValue = ({ business, shippingFee, shipping_fees_id, lang='local' }) => {
  if (business && business.shippingFees && shipping_fees_id) {
    shippingFee = business.shippingFees.filter(shippingFee => shippingFee._id === shipping_fees_id)[0]
  }

  let cashOnDelivery, nameLang, pickupLocationNameLang

  if (shippingFee) {
    cashOnDelivery = shippingFee.cashOnDelivery
    nameLang = shippingFee.name ? shippingFee.name[lang] : null

    let statusLang

    if (cashOnDelivery) {
      const { status } = cashOnDelivery

      if (status === true) {
        statusLang = 'ชำระเงินปลายทางได้'
      } else {
        statusLang = 'ไม่รองรับการชำระเงินปลายทาง'
      }
    }

    if (shippingFee.pickupLocation === 'addressPickup') {
      pickupLocationNameLang = 'จัดส่งสินค้าตามที่อยู่'
    } else if (shippingFee.pickupLocation === 'branchOtherPickup') {
      pickupLocationNameLang = nameLang
    } else if (shippingFee.pickupLocation === 'inStorePickup') {
      pickupLocationNameLang = nameLang
    } else {
      pickupLocationNameLang = 'ไม่มีการจัดส่ง'
    }

    cashOnDelivery = {
      ...cashOnDelivery,
      statusLang
    }
  }

  return {
    ...shippingFee,
    cashOnDelivery,
    nameLang,
    pickupLocationNameLang
  }
}

export const getShippingAddress = ({ address, pickupLocation }) => {
  let short

  if (address) {
    const { addressShort, nameLang, telFull, branchNameLang, branchNo } = address

    if (pickupLocation === 'inStorePickup') {
      short = `<b>${nameLang}</b> | ${telFull}<br />${addressShort}`

    } else if (pickupLocation === 'branchOtherPickup') {
      short = `<b>${nameLang}</b> | ${telFull}<br />${branchNameLang ? `สาขา${branchNameLang} ${branchNo ? `(${branchNo})` : ''}` : ''} ${addressShort}`

    } else if (pickupLocation === 'addressPickup') {
      short = `<b>${nameLang}</b> | ${telFull}<br />${addressShort}`

    } else {
      short = `<b>${nameLang}</b> | ${telFull}<br />${branchNameLang ? `สาขา${branchNameLang} ${branchNo ? `(${branchNo})` : ''}` : ''} ${addressShort}`
    }

  } else {
    if (pickupLocation === 'inStorePickup') {
      short = `<span class="color-error">*** กรุณาเพิ่มข้อมูลผู้ซื้อ</span>`
    } else if (pickupLocation === 'branchOtherPickup') {
      short = `<span class="color-error">*** กรุณาเพิ่มที่อยู่สาขาที่รับของ</span>`
    } else if (pickupLocation === 'addressPickup') {
      short = `<span class="color-error">*** กรุณาเพิ่มที่อยู่จัดส่ง</span>`
    }
  }

  return {
    addressShort: short
  }
}

export const businessPaymentMethodValue = ({ business, payment_methods_id, lang='local' }) => {
  const payments = getPaymentMethodsList({ paymentMethods: business.paymentMethods, lang })
  const paymentMethod = payments.filter(payment => payment.id === payment_methods_id)[0]

  return paymentMethod
}


export const getSystemsAttributes = ({ attributes }) => {
  const systemsAttributes = []

  if (attributes) {
    attributes = attributes.filter(attr => (attr.type === 'product' || attr.type === 'article' || attr.type === 'note') && attr.showMain === true)

    attributes.map(attr => {
      let path

      if (attr.type === 'product') {
        path = 'products-attr'
      } else if (attr.type === 'article') {
        path = 'articles-attr'
      } else if (attr.type === 'note') {
        path = 'notes'
      }

      systemsAttributes.push({
        id: attr.path,
        name: attr.name.local,
        link: `/${path}/${attr.path}/all/all`,
        attribute: attr,
        status: true
      })
    })
  }

  return systemsAttributes
}

export const systemsList = ({ business, attributes }) => {
  const { systems, type } = business
  const systemsAttributes = getSystemsAttributes({ attributes })

  const check = (name) => {
    let status = false

    if (systems && systems[name]) {
      status = systems[name].status
    }

    return status
  }

  let systemsAll

  if (type === 'rubber') {
    systemsAll = [
      {
        id: 'purchases',
        name: 'ซื้อน้ำยางสด',
        link: '/rubber-purchases/all/all/all',
        status: true
      },
      {
        id: 'rubber-payments',
        name: 'จ่ายชำระหนี้',
        link: '/rubber-payments/unpaid/all',
        status: true
      },
      {
        id: 'rubber-receivables',
        name: 'บัญชีลูกหนี้',
        link: '/rubber-receivables/all/all',
        status: true
      },
      {
        id: 'field-latex-sell-on-cash',
        name: 'ขายน้ำยางสด',
        link: '/rubber-sales/field-latex/all/sell-on-cash/sales/all',
        status: true
      },
      {
        id: 'rubber-sheet-make',
        name: 'ทำยางแผ่น',
        link: '/rubber-sales/rubber-sheet-make/all/make/sales/all',
        status: true
      },
      {
        id: 'rubber-sheet-sell-on-cash',
        name: 'ขายยางแผ่น (ขายสด)',
        link: '/rubber-sales/rubber-sheet/all/sell-on-cash/sales/all',
        status: true
      },
      {
        id: 'rubber-sheet-contract',
        name: 'ขายยางแผ่น (ล่วงหน้า)',
        link: '/rubber-contracts/all/all',
        status: true
      },
      {
        id: 'rubber-sheet-deposit',
        name: 'ขายยางแผ่น (ฝากยาง)',
        link: '/rubber-deposits/all/all',
        status: true
      },
      {
        id: 'rubber-receipt',
        name: 'ใบสำคัญรับเงิน',
        link: '/rubber-receipts/all/all/all',
        status: true
      },

      {
        id: 'rubber-sheet-stock',
        name: 'สต็อกยาง',
        link: '/rubber-inventories/inventory/field-latex/all',
        status: true
      },


      {
        id: 'rubber-products',
        name: 'ประกาศราคายาง',
        link: '/rubber-products/all',
        status: true
      },

      {
        id: 'customer-sellers',
        name: 'เจ้าของสวน/ผู้ขายยาง',
        link: '/rubber-customer-sellers/all',
        status: true
      },
      {
        id: 'customer-buyers',
        name: 'โรงงาน/ผู้ซื้อยาง',
        link: '/rubber-customer-buyers/all',
        status: true
      },
      {
        id: 'rubber-reports',
        name: 'รายงาน',
        link: '/rubber-reports/purchases',
        status: true
      },
      {
        id: 'admins',
        name: 'ผู้ดูและบบ',
        link: '/admins/all/all',
        status: true
      },
      {
        id: 'settings',
        name: 'ตั้งค่าระบบจัดการยาง',
        link: '/common-settings',
        status: true
      },

      /*{
        id: 'settings',
        name: 'จัดการข้อมูลกิจการ',
        link: '/settings',
        status: true
      }*/
    ]
  } else {

    systemsAll = [
      /*{
        id: 'sales',
        name: 'ระบบขาย',
        link: '/sales',
        status: check('sales')
      },*/

      /*{
        id: 'sales',
        name: 'ขายหน้าร้าน',
        link: '/pos/sales/all',
        status: check('sales')
      },*/
      {
        id: 'sell-online',
        name: 'ขายออนไลน์',
        link: '/sell-online/all/all',
        status: check('sales')
      },

      /*{
        id: 'sales-notes',
        name: 'บันทึกการขาย',
        link: '/sales-notes',
        icon: require('resources/icons/sales-notes-96.png')
      },
      {
        id: 'reserve-tours',
        name: 'รายการจองทัวร์',
        link: '/reserve-tours',
        icon: require('resources/icons/Calendar-96.png')
      },*/
      /*{
        id: 'tours',
        name: 'โปรแกรมทัวร์',
        link: '/tours',
        icon: require('resources/icons/Tours-96.png')
      },*/
      {
        id: 'products',
        name: 'สินค้า',
        link: '/products/all/all',
        status: check('products')
      },
      {
        id: 'vouchers',
        name: 'คูปองส่วนลด',
        link: '/vouchers',
        status: check('vouchers')
      },
      /*{
        id: 'services',
        name: 'สินค้า',
        link: '/services',
        icon: require('resources/icons/services-96.png')
      },
      {
        id: 'places',
        name: 'สถานที่ท่องเที่ยว',
        link: '/places',
        icon: require('resources/icons/places-96.png')
      },
      {
        id: 'sales-notes',
        name: 'บันทึกการขาย',
        link: '/sales-notes',
        icon: require('resources/icons/sales-notes-96.png')
      },*/
      {
        id: 'bills',
        name: 'บัญชี',
        link: '/bills/quotation/all',
        status: check('bills')
      },
      {
        id: 'articles',
        name: 'บทความ',
        link: '/articles/all/all',
        status: check('articles')
      },

      /*{
        id: 'hotels',
        name: 'รายชื่อโรงแรม',
        link: '/hotels',
        icon: require('resources/icons/Hotels-96.png')
      },*/
      /*{
        id: 'tour-booking',
        name: 'รายการจองทัวร์',
        link: '/tour-booking',
        icon: require('resources/icons/Calendar-96.png')
      },*/
      /*{
        id: 'tasks',
        name: 'ตารางงาน',
        link: '/tasks',
        icon: require('resources/icons/Tasks-96.png')
      },*/
      {
        id: 'customer-affiliates',
        name: 'ตัวแทนจำหน่าย',
        link: '/customer-affiliates/all/all',
        status: check('customers')
      },
      {
        id: 'customers',
        name: 'รายชื่อลูกค้า',
        link: '/customers/all/all',
        status: check('customers')
      },
      /*{
        name: 'ใบเสนอราคา',
        link: '/',
        icon: require('./Quotation-96.png')
      },
      {
        name: 'ใบวางบิล',
        link: '/',
        icon: require('./Bill-96.png')
      },
      {
        name: 'ใบแจ้งหนี้/ใบเสร็จรับเงิน',
        link: '/',
        icon: require('./Invoice-96.png')
      },
      {
        name: 'ปฏิทินการจอง',
        link: '/',
        icon: require('./Calendar-96.png')
      },
      {
        name: 'บันทึกการโทร',
        link: '/',
        icon: require('./Office-Phone-96.png')
      },
      {
        name: 'บันทึกการแชท',
        link: '/',
        icon: require('./Chat-96.png')
      },*/
      /*{
        id: 'report',
        name: 'รายงาน',
        link: '/',
        icon: require('resources/icons/Reports-96.png')
      },*/
      ...systemsAttributes,
      {
        id: 'sites',
        name: 'จัดการเว็บไซต์',
        link: '/sites',
        status: check('sites')
      },
      {
        id: 'admins',
        name: 'ผู้ดูและบบ',
        link: '/admins/all/all',
        status: check('admins')
      },
      {
        id: 'attributes',
        name: 'จัดการคุณลักษณะ',
        link: '/attributes',
        status: check('attributes')
      },
      {
        id: 'users',
        name: 'ผู้ใช้งาน',
        link: '/users/all/all',
        status: check('admins')
      },
      {
        id: 'settings',
        name: 'จัดการข้อมูลกิจการ',
        link: '/settings',
        status: check('settings')
      }
    ]
  }

  return systemsAll
}
