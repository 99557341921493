import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import config from '../../config'

// Redux Actions
import { fetchBusinessByUsername } from '../../redux/Businesses/BusinessesActions'
import { getAuth, reSignIn } from '../../redux/Auth/AuthActions'
import { expirationDate } from '../../redux/Businesses/BusinessesValues'

class Auth extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loadAuth: false
    }
  }

  componentDidMount() {
    this.reqAuth()
    this.reqUnAuth()
    this.reqAuthBiz()
  }

  reqAuth = () => {
    if (this.props.reqAuth) {
      if (this.checkAuth()) {
        this.checkAuthActive(this.props.auth.user)
      } else {
        this.reCheckAuth()
      }
    }
  }

  reqUnAuth = () => {
    if (this.props.unAuth) {
      if (this.checkAuth()) {
        this.props.history.push(`/`)
      } else {
        this.reCheckAuth()
      }
    }
  }

  checkAuth = () => {
    let auth = this.props.auth || 'unauthorized'

    if (auth.user === 'unauthorized') return false
    else return true
  }

  reqAuthBiz = () => {
    if (this.props.reqAuthBiz) {
      if (this.checkAuth()) {
        this.checkAuthBiz(this.props.auth.user)
      } else {
        this.reCheckAuth()
      }
    }
  }

  checkAuthBiz = (user) => {
    if (this.props.reqAuthBiz) {
      this.checkAuthActive(user)

      if (this.findBiz(user) === undefined) {
        this.reCheckAuthBiz()
      } else {
        this.checkExpDate()
      }
    }
  }

  reCheckAuth = () => {
    this.props.dispatch(getAuth()).then((res) => {
      if (res.success) {
        this.checkAuthActive(res.data)
        this.checkAuthBiz(res.data)

        if (this.props.unAuth) {
          this.props.history.push(`/`)
        }
      } else {
        if (!this.props.unAuth) {
          this.props.history.push(`/signin?next=${this.props.location.pathname}`)
        }
      }
    })
  }

  reCheckAuthBiz = () => {
    this.props.dispatch(reSignIn()).then((res) => {
      if (res.success) {
        if (this.findBiz(res.data) === undefined) {
          this.props.history.push(`/error/403`)
        } else {
          this.checkExpDate()
        }
      } else {
        this.props.history.push(`/error/403?code=error`)
      }
    })
  }

  checkAuthActive = (user) => {
    if (this.props.reqAuthActive) {
      if (user.active === false) {
        this.props.history.push(`/confirm-account`)
      }
    }
  }


  checkBiz = (auth) => {
    if (this.props.reqAuthBiz) {
      if (auth) {
        if (this.findBiz(this.props.auth.user) === undefined) {
          return false
        } else {
          const { businesses, match } = this.props
          const { username } = match.params
          const business = businesses.filter(business => business.username === username)[0]

          if (expirationDate(business.package)) {
            return false
          } else {
            return true
          }
        }
      } else {
        return false
      }
    }
  }

  checkExpDate = () => {
    if (this.props.reqExpDate) {
      let businesses = this.props.businesses
      let username = this.props.match.params.username
      let business = businesses.filter(business => business.username === username)[0]

      if (business === undefined) {
        this.reCheckExpDate()
      } else {
        if (expirationDate(business.package)) {
          this.reCheckExpDate()
        }
      }
    }
  }

  reCheckExpDate = () => {
    let username = this.props.match.params.username

    this.props.dispatch(fetchBusinessByUsername(username)).then((res) => {
      if (res.success) {
        let businesses = this.props.businesses
        let business = businesses.filter(business => business.username === username)[0]

        if (business === undefined) {
          this.props.history.push(`/${username}/packages`)
        } else {
          if (expirationDate(business.package)) {
            this.props.history.push(`/${username}/packages`)
          }
        }
      } else {
        console.log('error fetch business !!!')
      }
    })
  }

  findBiz = (user) => {
    const username = this.props.match.params.username

    if (user.role === 'admin') {
      return true
    } else {
      return user.businessesAuth.filter(business => business.username === username)[0]
    }
  }

  render() {
    const auth = this.checkAuth()
    const authBiz = this.checkBiz(auth)
    const redirectAfterLogin = this.props.location.pathname

    if (this.props.reqAuth) {
      return (
        <Fragment>
          { auth && this.props.children }
        </Fragment>
      )

    } else if (this.props.reqAuthBiz) {
      return (
        <Fragment>
          { authBiz && this.props.children }
        </Fragment>
      )

    } else if (this.props.unAuth) {
      return this.props.children

    } else {
      return this.props.children
    }
  }
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
    businesses: state.businesses.data
  }
}

export default withRouter(connect(mapStateToProps)(Auth))
