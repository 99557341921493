import React, { useEffect, useState } from 'react'

// utils
import callApi from '../../../../../util/apiCaller'


let amount = 6582*100

const OmisePage = (props) => {
  const [OmiseCard, setOmiseCard] = useState()

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://cdn.omise.co/omise.js"
    script.async = true

    document.body.appendChild(script)
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setOmiseCard(window.OmiseCard)
    }
  }, [typeof window !== 'undefined' && window.OmiseCard])

  useEffect(() => {
    if (OmiseCard) {
      OmiseCard.configure({
        publicKey: 'pkey_test_5pawe76eoyl2b8xryb4',
        image: 'https://s3-ap-southeast-1.amazonaws.com/original-images.apps.com/sites/2021/09/16/cktmhri98000k0grsa42jymo9_o.png',
        currency: 'THB',
        locale: 'th',
        frameLabel: 'นกไทย',
        submitLabel: `ชำระ`,
        otherPaymentMethods: []
      })
    }
  }, [OmiseCard])


  const payment = () => {
    OmiseCard.open({
      amount,
      onCreateTokenSuccess: (token) => {
        checkoutcreditCard(token)
      },
      onFormClosed: () => {
        console.log('error')
      },
    })
  }

  const checkoutcreditCard = (token) => {
    const body = {
      name: 'wichan areemarn',
      email: 'l_wichan@hotmail.com',
      amount,
      token
    }

    return callApi('omise/checkout-credit-card', 'put', body).then(res => {
      if (res.success) {
        console.log(res.data)
      } else {
        console.log(res)
      }
    })
  }

  return (
    <div>
      { OmiseCard &&
        <button id="checkout-button" onClick={payment}>ชำระเงิน</button>
      }
    </div>
  )
}

export default OmisePage
