
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toastHide } from '../../redux/App/AppActions'
import { ToastContainer, toast } from 'react-toastify'
import '../../themes/dist/css/react-toastify.global.css'


const Msg = ({ icon, msg, closeToast, toastProps }) => (
  <div>
    <i className="material-icons left">{icon}</i> {msg}
  </div>
)

class Toast extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate() {
    const { app } = this.props
    const toastMsg = app && app.toast || {}
    const { show, msgType, message } = toastMsg

    if (show) {
      let toastFn, icon

      if (msgType === 'error') {
        toastFn = toast.error
        icon = 'cancel'
      } else {
        toastFn = toast
        icon = 'check_circle'
      }

      toastFn(<Msg icon={icon} msg={message} />, {
        position: "bottom-left",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      })

      this.props.dispatch(toastHide())
    }
  }

  render() {
    return (
      <ToastContainer />
    )
  }
}

function mapStateToProps(state) {
  return {
    app: state.app,
    auth: state.auth
  }
}

export default connect(mapStateToProps)(Toast)
