import React, { Fragment } from 'react'
import { NavLink, withRouter } from 'react-router-dom'

// css
import css from './css/menuSidebarJsx.css'


const MenuSidebarJsx = (props) => {
  const activeStyle = {
    borderLeft: `3px solid ${props.activeColor}`,
    color: props.activeColor
  }

  return (
    <ul className={css.menu}>
      { props.items.map((item, key) => {
          return (
            <Fragment key={key}>
              { item.title ?
                <li className={css.title} style={{ background: '#eee' }}>
                  <span>{item.nameShort || item.name}</span>
                </li>
              :
                <li>
                  <NavLink exact={item.exact} to={item.link} activeStyle={activeStyle}>
                    <div className={css.boxListMenu}>

                      { item.badge && item.badge.amount > 0 &&
                        <div className="hidden-lg hidden-xlg center pd-left-25">
                          <b className="badge-bg font-13" style={{ lineHeight: 'initial', background: item.badge.bg }}>
                            {item.badge.amount}
                          </b>
                        </div>
                      }

                      <i className="material-icons left i-middle mg-right-20">{item.icon}</i>

                      <span className={css.nameFull}>{item.name}</span>
                      <span className={css.nameShort}>{item.nameShort || item.name}</span>

                      { item.badge && item.badge.amount > 0 &&
                        <div className="hidden-xxs hidden-xs hidden-sm hidden-md height-45 line-height-45 right pd-right-10">
                          <b className="badge-bg font-13" style={{ lineHeight: 'initial', background: item.badge.bg }}>
                            {item.badge.amount}
                          </b>
                        </div>
                      }
                    </div>
                  </NavLink>
                </li>
              }
            </Fragment>
          )
        })
      }
    </ul>
  )
}

export default withRouter(MenuSidebarJsx)
