import React from 'react'
import { connect } from 'react-redux'

// Components Local
import { SectionsList, SectionsListDisplay  } from './SectionsList'


const LayoutsDesign = (props) => {
  if (props.display) {
    return (
      <SectionsListDisplay {...props} />
    )
  } else {
    return (
      <SectionsList {...props} />
    )
  }
}

export default connect()(LayoutsDesign)
