import React, { Fragment, useEffect, useMemo, useRef } from 'react'
import Helmet from 'react-helmet'
import ReactGA from 'react-ga'
import { connect } from 'react-redux'

// Components Global
import { PageDisplay } from '../../../../components/SitesManage/Pages'

// Containers
import PageModalRoutesContainer from './Components/PageModalRoutesContainer'




const PageDisplayPage = (props) => {
  const { business, setting, site, page, templateData, params } = props
  const lang = props.lang || 'local'

  let seo, data

  if (templateData) {
    if (page.fetchId) {
      data = page
      seo = page.seo
    } else {
      data = templateData
      seo = templateData.seo
    }
  } else {
    data = page
    seo = page.seo
  }

  const title = seo && seo.title && seo.title[lang]
  const description = seo && seo.description && seo.description[lang]
  const keywords = seo && seo.keywords && seo.keywords[lang]
  const imageUrl = seo && seo.imageUrl
  const rerender = [
    props.location && props.location.pathname,
    props.location && props.location.search
  ]

  useMemo(() => {
    if (typeof window !== 'undefined') {
      //window.scrollTo(0, 0)
      if (site.googleAnalyticsCode) {
        ReactGA.initialize(site.googleAnalyticsCode)
        ReactGA.pageview(window.location.pathname + window.location.search)
      }
    }
  }, [rerender])

  //console.log(previous)


  return (
    <Fragment>
      { useMemo(() =>
        <Helmet>
          <title>{title || data.name && data.name[lang]}</title>

          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
          <meta name="robots" content="index, follow" />

          <meta property="og:title" content={title || data.name && data.name[lang]} />
          <meta property="og:description" content={description} />
          <meta property="og:type" content="website" />

          { imageUrl &&
            <meta property="og:image" content={imageUrl} />
          }

          { site.googleSiteVerificationCode &&
            <meta name="google-site-verification" content={site.googleSiteVerificationCode} />
          }

          { site.googleAnalyticsCodeNew &&
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${site.googleAnalyticsCodeNew}`}></script>
          }

          { site.googleAnalyticsCodeNew &&
            <script>{`
              window.dataLayer = window.dataLayer || [];
              function gtag(){
                dataLayer.push(arguments);
              }
              gtag('js', new Date());
              gtag('config', '${site.googleAnalyticsCodeNew}');
            `}
            </script>
          }
        </Helmet>
      , [
        seo && JSON.stringify(seo),
        data.name && JSON.stringify(data.name),
        data.title && JSON.stringify(data.title),
      ]) }


      <Fragment>
        <PageDisplay
          {...props}
          datas={{
            business,
            setting,
            site,
            page,
            templateData
          }}
        />

        { params && params.pageModal &&
          <PageModalRoutesContainer
            {...props}
            pathClose={params.pageModal.pathClose}
            pathUseModal={params.pageModal.pathUseModal}
            //modalClassName="border-radius-5 bg-f5f5f5"
            margin={0}
            extraProps={{
              business,
              setting,
              site,
              page,
              templateData
            }}
          />
        }
      </Fragment>
    </Fragment>
  )
}

function mapStateToProps(store, props) {
  return {
    modals: store.app.modals
  }
}

export default connect(mapStateToProps)(PageDisplayPage)
