import IndexedDB from '../../IndexedDB'

export const addItem = async (req) => {
  const { _id, businesses_id } = req.params
  const { productItem } = req.body

  let order = await IndexedDB.fetchOne('orders', { where: { _id, businesses_id } })

  if (order.success) {
    order = order.data
    const item = order.items.filter(v => v.productItem._id === productItem._id)[0]

    // set item
    if (item) {
      const qty = item.qty + req.body.qty
      return await IndexedDB.updateArraySet('orders', { _id, element: 'items', updateId: item._id, values: { qty } })

    // set item
    } else {
      return await IndexedDB.updateArrayPush('orders', { _id, element: 'items', values: req.body })
    }

  } else {
    return order.error
  }
}

export const removeItem = async (req) => {
  const { _id, businesses_id } = req.params
  const { deleteId } = req.body
  const element = 'items'
  const obj = {
    where: {
      _id,
      businesses_id
    },
    element,
    arrayWhere: {
      _id: deleteId
    }
  }

  const rs = await IndexedDB.fetchArrayOne('orders', obj)

  if (rs.success) {
    const order = rs.data
    const item = rs.item
    const stockStatus = await updateStock({ order, item })

    if (stockStatus.success) {
      return await IndexedDB.updateArrayPull('orders', { _id, element, deleteId })
    } else {
      return stockStatus.error
    }
  } else {
    return order.error
  }
}

const updateStock = async () => {
  return {
    success: true
  }
}
