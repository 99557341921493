import React, { Fragment } from 'react'

const TextEditorSpaceDisplay = ({ content }) => {
  const space = content.space && JSON.parse(content.space)
  const style = space && space.style ? JSON.parse(space.style) : {}

  return (
    <Fragment>
      <div className="space-jsx" />

      <style jsx>{`
          .space-jsx {
            height: ${style['height']}
          }

          @media (min-width: 1500px) {
            .space-jsx {
              height: ${style['height-vw']}
            }
          }
        `}</style>
    </Fragment>
  )
}

export default TextEditorSpaceDisplay
