import React, { Component, Fragment } from 'react'


// Components Text Editor
import BoxSettings from '../BoxSettings'

import {
  SwitchStyleSettings,
  SelectStyleSettings,
  InputStyleSettings
} from '../StyleSettings'


class UpdateUrlSearchSetting extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { _this } = this.props
    const { style } = _this.state
    const updateUrlSearch = style['updateUrlSearch']
    const updateUrlSearchEvent = style['updateUrlSearchEvent']
    const updateUrlSearchValueType = style['updateUrlSearchValueType']

    let labelValue = 'กำหนดค่า (value)'

    if (updateUrlSearchValueType === 'api') {
      labelValue = 'กำหนดค่าคีย์ key'
    }

    return (
      <BoxSettings title="อัปเดต URL" icon="link">
        <div className="pd-10 pd-top-0">
          <SwitchStyleSettings
            _this={_this}
            label="เปิดการอัพเดต URL"
            styleName="updateUrlSearch"
          />
        </div>

        { updateUrlSearch &&
          <Fragment>
            <div className="pd-10 bg-fafafa">
              <SelectStyleSettings
                label="ประเภทอีเว้นท์ (event)"
                _this={_this}
                styleName="updateUrlSearchEvent"
                options={[
                  { label: 'เลือกประเภท', value: '' },
                  { label: 'เมื่อคลิด (click)', value: 'click' },
                  { label: 'เมื่อเมาส์ชี้ (mouseEnter)', value: 'mouseEnter' }
                ]}
              />

              { updateUrlSearchEvent === 'mouseEnter' &&
                <div className="mg-top-10">
                  <SwitchStyleSettings
                    _this={_this}
                    label="เมื่อเอาเมาส์ออกให้ลบค่าคีย์"
                    styleName="mouseLeaveRemove"
                  />
                </div>
              }
              <div>
              </div>
            </div>

            <div className="pd-10">
              <InputStyleSettings
                label="คีย์ (key)"
                _this={_this}
                type="text"
                styleName="updateUrlSearchKey"
              />
            </div>

            <div className="pd-10 bg-fafafa">
              <SelectStyleSettings
                label="ประเภทค่า (event)"
                _this={_this}
                styleName="updateUrlSearchValueType"
                options={[
                  { label: 'กำหนดเอง', value: '' },
                  { label: 'ค่าอินเด็กซ์', value: 'index' },
                  { label: 'ค่า API', value: 'api' }
                ]}
              />
            </div>

            { updateUrlSearchValueType !== 'index' &&
              <div className="pd-10">
                <InputStyleSettings
                  label={labelValue}
                  _this={_this}
                  type="text"
                  styleName="updateUrlSearchValue"
                />
              </div>
            }
          </Fragment>
        }
      </BoxSettings>
    )
  }
}

export default UpdateUrlSearchSetting

