import React from 'react'
import config from '../../../config'

// Components Local
import { RowItemDisplay } from '../RowItem'
import BackgroundVideoDisplay from './components/BackgroundVideoDisplay'
import BackgroundImageDisplay from './components/BackgroundImageDisplay'

import {
  checkShowPage,
  generateClassToString
} from '../../TextEditor/functions/textEditorFunctions'

// css
import css from './css/sectionItem.css'
import { getApi, getImagePath } from '../../TextEditor/functions/textEditorEvent'
import { getData } from '../../TextEditor/components/ApiSettings/js/apiSettings'


const SectionItemDisplay = (props) => {
  const { section, action, edit, mode, site, apiData, templateData, dataStore } = props
  const { layouts } = action.params.data
  const className = section.class ? JSON.parse(section.class) : []
  const style = section.style ? JSON.parse(section.style) : {}
  const api = section.api ? JSON.parse(section.api) : {}

  const layoutsGroup = layouts.filter(layout => layout.sectionId === section.sectionId)
  const backgroundColor = style['section-background-color']
  //const backgroundImage = style['section-background-image']
  const backgroundGradient = style['section-background-gradient']
  const backgroundVideo = style['section-background-video']

  const showPage = checkShowPage({ style, props, mode })

  let backgroundImage = style['section-background-image']

  if (style['api']) {
    const content = { api: style['api'] }
    const api = getApi(content, { api: section.api })
    const data = getData({ api, apiData, templateData, dataStore })
    const paths = getImagePath({ api, site, apiData, templateData, dataStore, content, data })

    if (paths && paths.o) {
      const s3Url = config.app.s3.url
      backgroundImage = paths.o && `url(${s3Url}/${paths.o})`

      style['section-background-image'] = backgroundImage
    }
  }


  if (showPage) {
    return (
      <section
        className={`editor-container section-jsx ${css.sectionContainerDisplay} ${generateClassToString(className)}`}
        style={{
          minHeight: edit !== undefined && '50px',
          backgroundImage: backgroundGradient,
          backgroundColor
        }}>

        { section && backgroundVideo &&
          <BackgroundVideoDisplay mode={mode} style={style} />
        }

        { section && backgroundImage &&
          <BackgroundImageDisplay {...props} mode={mode} style={style} backgroundImage={backgroundImage} />
        }

        <div className={`${css.contents} section-item-container section-item-container-jsx`}>
          { layoutsGroup.map(layout => {
            return (
              <RowItemDisplay {...props} key={layout._id} layout={layout} />
            )
          }) }
        </div>

        <style jsx>{`
          .section-jsx {
            min-height: ${style['section-min-height']} !important;

          }
          .section-jsx .section-item-container-jsx {
            padding: ${style['section-padding']};
          }

          @media (min-width: 601px) and (max-width: 1024px) {
            .section-jsx {
              min-height: ${style['section-tablet-min-height']} !important;
            }
            .section-jsx .section-item-container-jsx {
              padding: ${style['section-tablet-padding']};
            }
          }

          @media (min-width: 50px) and (max-width: 600px) {
            .section-jsx {
              min-height: ${style['section-mobile-min-height']} !important;
            }
            .section-jsx .section-item-container-jsx {
              padding: ${style['section-mobile-padding']};
            }
          }

          @media (min-width: 1500px) {
            .section-jsx {
              min-height: ${style['section-min-height-vw']} !important;
            }
            .section-jsx .section-item-container-jsx {
              padding: ${style['section-padding-vw']};
            }
          }
        `}</style>

        { props.children }
      </section>
    )
  } else {
    return (
      <div></div>
    )
  }
}

export default SectionItemDisplay

