import React, { Component } from 'react'

// Components Global
import { Row, Column } from '../../../../Columns'

// Components Editor
import DivBlockStyle from '../../../components/DivBlockStyle'

import TabLists from './components/TabLists'
import TabContent from './components/TabContent'

import TabsHeaderContent from '../TabsHeaderContent'
import TabsFooterContent from '../TabsFooterContent'

// Functions
import { getSections } from '../../../../../redux/Sites/SitesFunctions'

import {
  setStyle,
  setStyleResponsive,
  setStyleBackgroundImage,
  setStyleBackgroundImageBefore
} from '../../../components/ElementStyles/components/js/setDivStyle'


class Tabs extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.startActiveId()
  }

  startActiveId = () => {
    const { items } = this.props
    const id = items && items[0] && JSON.parse(items[0]).id

    if (id) {
      this.setActiveId(id)
    }
  }

  setActiveId = (id) => {
    this.setState({ activeId: id })
  }

  render() {
    const lang = this.props.lang || 'local'
    const { items, style } = this.props
    const { activeId } = this.state
    const model = style['tab-menu-position'] || 'top-left'

    let itemSeleted = items.filter(item => JSON.parse(item).id === activeId)[0]
    itemSeleted = itemSeleted && JSON.parse(itemSeleted)

    const sections = itemSeleted && getSections(this.props, { byGroupId: itemSeleted.id })

    return (
      <div>
        { (model === 'top-left' || model === 'top-center' || model === 'top-right' || model === 'top-full') &&
          <div>
            <TabLists {...this.props} lang={lang} setActiveId={this.setActiveId} activeId={activeId} />

            <TabContent {...this.props} lang={lang} sections={sections} itemSeleted={itemSeleted} />
          </div>
        }

        { model === 'left' &&
          <Row className="row-dp-flex">
            <Column col="column col-xxs-12 col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xlg-3">
              <DivBlockStyle name="tab-menu-block" styleJsx={style} style={{ width: '100%', height: '100%' }}>
                <TabsHeaderContent {...this.props} lang={lang} />
                <TabLists {...this.props} lang={lang} setActiveId={this.setActiveId} activeId={activeId} />
                <TabsFooterContent {...this.props} lang={lang} />
              </DivBlockStyle>
            </Column>
            <Column col="column col-xxs-12 col-xs-6 col-sm-6 col-md-8 col-lg-9 col-xlg-9">
              <DivBlockStyle name="tab-content-block" styleJsx={style} style={{ width: '100%', height: '100%' }}>
                <TabContent {...this.props} lang={lang} sections={sections} itemSeleted={itemSeleted} />
              </DivBlockStyle>
            </Column>
          </Row>
        }

        { model === 'right' &&
          <Row className="row-dp-flex">
            <Column col="column col-xxs-12 col-xs-6 col-sm-6 col-md-8 col-lg-9 col-xlg-9">
              <DivBlockStyle name="tab-menu-block" styleJsx={style} style={{ width: '100%', height: '100%' }}>
                <TabContent {...this.props} lang={lang} sections={sections} itemSeleted={itemSeleted} />
              </DivBlockStyle>
            </Column>
            <Column col="column col-xxs-12 col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xlg-3">
              <DivBlockStyle name="tab-content-block" styleJsx={style} style={{ width: '100%', height: '100%' }}>
                <TabsHeaderContent {...this.props} lang={lang} />
                <TabLists {...this.props} lang={lang} setActiveId={this.setActiveId} activeId={activeId} />
                <TabsFooterContent {...this.props} lang={lang} />
              </DivBlockStyle>
            </Column>
          </Row>
        }


      </div>
    )
  }
}

export default Tabs
