import React from 'react';
import { Link } from 'react-router-dom';

export function BtnLink (props) {
  const style = {
    margin: props.margin
  };
  return (
    <Link to={props.to} className={props.className} style={style}>
      {props.icon ?
        <i className={`material-icons ${props.iconAlign}`}>{props.icon}</i>
      : null }
      {props.text}
    </Link>
  );
}

export function Btn (props) {
  const style = {
    margin: props.margin
  }

  let joinStyle = Object.assign(style, props.style)
  let iconAlign = (props.iconAlign ? props.iconAlign : 'left')

  return (
    <span>
      { props.to ?
        <Link className={props.className} to={props.to} style={joinStyle}>
          { props.icon && <i className={`material-icons ${iconAlign}`}>{props.icon}</i> }
          { props.text }
        </Link>
      :
        <a className={props.className} onClick={props.onClick} style={joinStyle}>
          { props.icon && <i className={`material-icons ${iconAlign}`}>{props.icon}</i> }
          { props.text }
        </a>
      }
    </span>
  )
}

export function BtnCircleClose (props) {
  const style = {
    position: 'absolute',
    right: '0',
    top: '0',
    margin: '10px'
  };

  let joinStyle = Object.assign(style, props.style);

  return (
    <div>
      { props.to ?
        <Link to={props.to} onClick={props.click} style={style} className="btn-floating btn-flat btn-large waves-effect waves-light">
          <i className="material-icons black-text">close</i>
        </Link>
      :
        <button onClick={props.onClick} style={style} className="btn-floating btn-flat btn-large waves-effect waves-light">
          <i className="material-icons black-text">close</i>
        </button>
      }
    </div>
  );
}
