import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Global
import ModalFixed from '../../../../../Modals/ModalFixed'
import { Row, Column } from '../../../../../Columns'
import BtnLoading from '../../../../../button/BtnLoading'
import TabItems from '../../../../../TabItems'
import TextInput from '../../../../../Inputs/TextInput'

// Actions
import { closeModal } from '../../../../../../redux/App/AppActions'

// Databases
import { getIconsAll } from '../../../../../../../databases/icons/material'

// css
import css from './css/iconsListsModal.css'


class IconsListsModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      fontAwesome: '',
      icon: {
        type: '',
        name: ''
      }
    }
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  getValue = (value) => {
    this.mounted && this.setState({ select: value })
  }

  fnStartModal = () => {
    this.mounted && this.setState({
      show: false,
      icon: { type: '', name: '' }
    }, () => {
      setTimeout(() => {
        this.setState({
          show: true,
          iconsLists: getIconsAll()
        })
      }, 500)
    })
  }

  handleSelectIcon = (type, name) => {
    setTimeout(() => {
      this.mounted && this.setState({
        icon: { type, name }
      })
    }, 0)
  }

  fontAwesomeOnChange = (e) => {
    const type = 'font-awesome'
    let name = e.target.value

    name = name.replace('<i class="', '').replace('"></i>', '')

    this.setState({ fontAwesome: name }, () => {
      this.handleSelectIcon(type, name)
    })
  }


  handleSubmit = () => {
    setTimeout(() => {
      this.props.getIcon(this.state.icon)
      this.props.dispatch(closeModal(this.props.id))
      this.mounted && this.setState({ show: false })

      if (this.props.focus) {
        this.props.focus()
      }
    }, 0)

  }

  fnCloseModal = () => {
    setTimeout(() => {
      this.mounted && this.setState({ show: false })
      this.props.dispatch(closeModal(this.props.id))

      if (this.props.focus) {
        this.props.focus()
      }
    }, 0)
  }

  render() {
    const { iconsLists } = this.state
    const { id } = this.props

    return (
      <ModalFixed
        id={id}
        title="เลือกไอคอน"
        width="1200px"
        headerClassName="height-50"
        bodyClassName="top-50 pd-0"
        padding="0"
        fnStartModal={this.fnStartModal}
        eventModalClose={this.fnCloseModal}>

        <div className="height-full">
          <TabItems
            activeId="material-icons"
            styleLink={{ borderRight: '0', borderTop: '0px solid #efefef' }}
            listStyle={{ width: '50%' }}
            items={[
              {
                id: 'material-icons',
                name: 'Material Icons',
                icon: 'offline_bolt',
                exact: true
              },
              {
                id: 'font-awesome',
                name: 'Font Awesome',
                icon: 'flag',
              },
            ]}>

            <div id="material-icons" className="height-full pd-10">
              { this.state.show === true ?
                <div className="pd-bottom-50">
                  { iconsLists && iconsLists.categories.map((category, iCate) => {
                    return (
                      <div key={iCate} className={css.containerIcon}>
                        <div className={`${css.title} pd-left-5 color-333 font-1-0`}>
                          { category.name }
                        </div>

                        <Row>
                          { category.icons.map((icon, i) => {
                            return (
                              <Column key={i} col="column col-xxs-1" className="pd-3 color-333">
                                <div
                                  className={`${css.boxIcon} ${this.state.icon.name === icon.id ? css.active : ''}`}
                                  onClick={() => this.handleSelectIcon('material-icons', icon.id)}>

                                  <div>
                                    <i className="material-icons font-1-5">{icon.id}</i>
                                  </div>
                                  <div className="font-0-8 text-ellipsis mg-top-5">{icon.id}</div>
                                </div>
                              </Column>
                            )
                          }) }
                        </Row>
                      </div>
                    )
                  })}
                </div>
              :
                <div className="box-middle height-full center font-1-2 pd-top-100">
                  <div>กำลังโหลดไอคอน...</div>
                </div>
              }
            </div>

            <div id="font-awesome" className="pd-10 pd-top-100">

              <div className="pd-30 border-efefef border-radius-5 mg-center" style={{ maxWidth: '600px' }}>
                <TextInput
                  id="icon"
                  label="ไอดีไอคอน"
                  placeholder="ไอดีไอคอน"
                  type="text"
                  value={this.state.fontAwesome}
                  onChange={this.fontAwesomeOnChange}
                />

                <div>
                  <a href="https://fontawesome.com/icons" target="_blank">ดูตารางไอคอน font-awesome</a>
                </div>
              </div>

            </div>
          </TabItems>
        </div>

        <div>
          <Row>
            <Column col="column col-xxs-6 col-xs-6 text-align-left">
              <a onClick={this.fnCloseModal}
                className="waves-effect btn btn-white btn-shadow-none width-100  color-000 font-1-0">
                <i className="material-icons left">close</i> ออก
              </a>
            </Column>

            <Column col="column col-xxs-6 col-xs-6 text-align-right">
              <BtnLoading
                onClick={this.handleSubmit}
                loadingId={this.props.selectLoadingId || 'site-update-loading'}
                icon="send"
                text="เลือกไอคอนนี้"
                className="waves-effect btn btn-shadow-none btn-submit color-fff font-1-0"
                btnDisabled={this.state.icon.name ? false : true }
              />
            </Column>
          </Row>
        </div>
      </ModalFixed>
    )
  }
}


export default connect()(IconsListsModal)
