import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import config from '../../../../config'

// Components Global
import Img from '../../../Img'


// Components Local
import EditorContainer from '../EditorContainer'
import BoxTools from '../BoxTools'
import BtnTools from '../BtnTools'
import BoxSelect from '../BoxSelect'
import BoxImage from '../BoxImage'
import BoxUploadImage from '../BoxUploadImage'

import { EditorText } from '../EditorText'

// Components Local
import ImageSettings from './components/ImageSettings'
import StyleSettings from './components/StyleSettings'
import {
  ImageComponent,
  ImageCoverComponent,
  ImageAvatarComponent
} from './components/ImageType'

// Functions
import { getApi } from '../../functions/textEditorEvent'

// css
import css from './css/editorImage.css'


const s3Url = config.app.s3.urlOriginal


class EditorImage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      display: true,
      ...this.getValue()
    }
  }

  componentDidMount() {
    this.mounted = true
  }

  componentDidUpdate(prevProps) {
    if (prevProps.image !== this.props.image) {
      this.setValue()
    }

    if (prevProps.cuid !== this.props.cuid) {
      if (this.props.cuid === this.props.content.cuid) {
        this.focus()
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  getValue = () => {
    const { settingsDefault, content } = this.props

    let image = this.props.image
        image = image && JSON.parse(image)

    return {
      class: image && image.class ? JSON.parse(image.class) : [],
      style: image && image.style ? JSON.parse(image.style) : {},
      paths: image && image.paths ? JSON.parse(image.paths) : '',
      link: image && image.link ? JSON.parse(image.link) : {},
      api: getApi(content, image),
      caption: image && image.caption,

      captionShow: image && image.captionShow !== undefined ? image.captionShow : settingsDefault && settingsDefault.caption,
      opacityShow: image && image.opacityShow !== undefined ? image.opacityShow : settingsDefault && settingsDefault.opacity
    }
  }

  setValue = () => {
    this.mounted && this.setState({
      ...this.getValue()
    })
  }

  handleOpenTools = (value) => {
    this.mounted && this.setState({ openTools: value })
  }

  focus = () => {
    setTimeout(() => this.handleOpenTools(true), 0)
  }

  handleSelectImage = (paths) => {
    this.setState({ paths }, () => {
      this.handleSubmit({ timeout: false })
      this.focus()
    })
  }

  handleSubmit = ({ timeout }) => {
    this.props.onChange({
      index: this.props.index,
      value: {
        class: JSON.stringify(this.state.class),
        style: JSON.stringify(this.state.style),
        paths: JSON.stringify(this.state.paths),
        link: JSON.stringify(this.state.link),
        api: JSON.stringify(this.state.api),
        caption: this.state.caption,
        captionShow: this.state.captionShow,
        opacityShow: this.state.opacityShow
      },
      timeout
    })
  }

  uploadImage = () => {
    const action = this.props.action

    return (id, files, album) => {
      if (files) {
        if (action.useQuery) {
          const fileParams = {
            files,
            imagePrefix: action.params.imagePrefix
          }

          this.props.dispatch(action.uploadImage(action.useQuery, fileParams, {
            body: {
              updateType: 'push',
              updateElement: 'images',
              album: album
            },
            loadId: id,
            modalId: id,
            progressId: id
          })).then(res => {
            if (res.paths) {
              this.handleSelectImage(res.paths)
            }
          })

        } else if (action.authName) {
          this.props.dispatch(action.uploadImage(action.authName, action.params.business._id, action.params._id, files, action.params.imagePrefix, {
            body: {
              updateType: 'push',
              updateElement: 'images',
              album: album
            },
            loadId: id,
            modalId: id,
            progressId: id
          })).then(res => {
            if (res.paths) {
              this.handleSelectImage(res.paths)
            }
          })

        } else {
          this.props.dispatch(action.uploadImage(action.params.business._id, action.params._id, files, action.params.imagePrefix, {
            body: {
              updateType: 'push',
              updateElement: 'images',
              album: album
            },
            loadId: id,
            modalId: id,
            progressId: id
          })).then(res => {
            if (res.paths) {
              this.handleSelectImage(res.paths)
            }
          })
        }
      }
    }
  }

  handleDeleteImage = () => {
    this.handleSelectImage('')
  }

  handleCaptionChange = (caption) => {
    this.setState({ caption }, () => {
      this.handleSubmit({ timeout: true })
    })
  }

  handleImageSetting = (value, name) => {
    this.setState({ [name]: value }, () => {
      this.handleSubmit({ timeout: true })
    })
  }

  handleApiChange = (api) => {
    this.setState({ api }, () => {
      this.handleSubmit({ timeout: true })
    })
  }

  openSidebar = (activeId) => {
    this.setState({ activeId: activeId })

    if (this.sidebar) {
      return this.sidebar.open()
    }
  }

  setMode = (value) => {
    this.setState({ display: value })
  }

  render() {
    const lang = this.props.lang || 'local'
    const { style, api, activeId, openTools, paths, captionShow, opacityShow } = this.state
    const { tools, mode, content, notDelete, type, boxImageActive, notActiveDelete } = this.props

    let title

    if (type === 'image') {
      title = "รูปภาพ"
    } else if (type === 'cover') {
      title = "ภาพหน้าปก"
    } else if (type === 'image-gallery') {
      title = "แกลลอรี่"
    } else if (type === 'image-thumbnail') {
      title = "รูปภาพ"
    }

    const inputCaption = (
      <div>
        { captionShow &&
          <EditorText
            mode={mode}
            type="caption"
            lang={lang}
            value={this.state.caption}
            onChange={this.handleCaptionChange}
            openTools={openTools}
            tools={{
              fontSize: true
            }}
          />
        }
      </div>
    )


    const backgroundImageComponent = (
      <div
        className={opacityShow ? css.coverOpatity : css.cover}
        style={{
          display: 'grid',
          width: '100%',
          minHeight: style && style['min-height'] || '250px',
          backgroundImage: paths.o && `url(${s3Url}/${paths.o})`
        }} />
    )

    const imageGalleryComponent = (
      <div className={css.imageGallery}>
        <Img s3UrlOriginal src={paths.o} />

        { captionShow &&
          <div className={css.boxCaption}>
            <div className={css.caption}>
              { inputCaption }
            </div>
          </div>
        }
      </div>
    )


    return (
      <EditorContainer
        style={{ height: type === 'image-gallery' && '100%' }}
        openTools={openTools}
        onClick={this.handleOpenTools}>

        <BoxTools openTools={openTools} style={{ minWidth: '50px' }}>
          <BtnTools
            notDelete={notDelete}
            title={title}
            _this={this}
            tools={[
              {
                groups: 'alignments',
                type: 'style',
                lists: [
                  { icon: 'format_align_left', width: 50, tooltip: 'ชิดซ้าย', style: 'block-text-align', value: 'left', unselect: true },
                  { icon: 'format_align_center', width: 45, tooltip: 'กลาง', style: 'block-text-align', value: 'center', unselect: true, },
                  { icon: 'format_align_right', width: 50, tooltip: 'ชิดขวา', style: 'block-text-align', value: 'right', unselect: true }
                ],
                show: tools && tools.alignments
              },
              {
                lists: [
                  { icon: 'edit', label: 'แก้ไข', onClick: () => this.openSidebar('content') },
                ],
                show: tools && tools.edit
              },
              {
                lists: [
                  { icon: 'color_lens', label: 'สไตล์', onClick: () => this.openSidebar('style') }
                ],
                show: (type !== 'image-thumbnail') ? true : false
              },
              {
                lists: [
                  { label: 'ตั้งค่า API', onClick: () => this.openSidebar('api') }
                ],
                show: content && content.api || false
              },
              {
                lists: [
                  { label: 'โหมดแสดง', icon: 'remove_red_eye', onClick: () => this.setMode(true) }
                ],
                show: (type === 'cover' && this.state.display === false)
              },
              {
                lists: [
                  { label: 'โหมดแก้ไขเนื้อหา', icon: 'edit', onClick: () => this.setMode(false) }
                ],
                show: (type === 'cover' && this.state.display === true)
              },
              {
                groups: 'close',
                lists: [
                  { icon: 'close', width: 50, tooltip: 'ลบ', onClick: this.props.remove },
                ],
                show: this.props.remove
              }
            ]}
          />
        </BoxTools>

        <BoxSelect
          _this={this}
          mode={mode}
          name="image"
          title={title}
          type={type}
          apiSettings={content && content.api}
          activeId={activeId}
          onRef={sidebar => this.sidebar = sidebar}
          contentSettings={ImageSettings}
          styleSettings={StyleSettings}
          apiChange={this.handleApiChange}
          openTools={openTools}
          style={{
            padding: '0',
            zIndex: this.props.zIndex,
            position: 'relative',
            height: (paths.o && type === 'image-gallery') && '100%'
          }}>

          <BoxImage
            paths={paths.o}
            active={paths && (boxImageActive || openTools)}
            deleteImage={this.handleDeleteImage}
            notActiveDelete={notActiveDelete}
            style={{
              height: type === 'image-gallery' && '100%'
            }}>

            { paths && type === 'background-image' && backgroundImageComponent }
            { paths && type === 'image-gallery' && imageGalleryComponent }

            { (paths || type === 'cover') ?
              <Fragment>
                { (type === 'image' || type === 'image-thumbnail') &&
                  <ImageComponent match={this.props.match} mode={mode} paths={paths} style={style} />
                }

                { type === 'cover' &&
                  <ImageCoverComponent
                    {...this.props}
                    display={this.state.display}
                    paths={paths}
                    style={style}
                    opacityShow={opacityShow}
                  />
                }
              </Fragment>
            :
              <Fragment>
                <ImageAvatarComponent mode={mode} style={style} />
              </Fragment>
            }

            { !style['avatar-show'] && content && content.api && type !== 'cover' &&
              <div className="center" style={{ width: '100%', lineHeight: '200px', height: '200px', background: '#fafafa' }}>
                { api && api.type ?
                  <Fragment>
                    ประเภท { api.type }

                    { api.type === 'custom' &&
                      <span>
                        [{ api.keyFull && api.keyFull }]
                      </span>
                    }
                  </Fragment>
                :
                  'ยังไม่ตั้งค่า API'
                }
              </div>
            }
          </BoxImage>
        </BoxSelect>

        { ((content && !content.api) || (type === 'image-thumbnail')) &&
          <BoxUploadImage
            {...this.props}
            img
            paths={paths}
            openTools={openTools}
            selectImage={this.handleSelectImage}
            focus={this.focus}
            index={this.props.index}
            upload={{
              album: 'gallery',
              multiple: false,
              maxFile: 1,
              onUpload: this.uploadImage()
            }}
          />
        }
      </EditorContainer>
    )
  }
}

export default connect()(EditorImage)
