import React, { Component } from 'react'


// Components Local
import InputBoxShadow from './components/InputBoxShadow'

// Functions
import { addStyle, removeStyle } from '../../../functions/textEditorFunctions'

// css
import css from '../css/styleSettings.css'


const boxShadowDefault = {
  x: 0,
  y: 0,
  blur: 0,
  spread: 0,
  color: '#ddd',
  type: ''
}

const defaultValue = [
  { ...boxShadowDefault }
]

const shadowLevel = [
  { level: 0, value: '' },
  { level: 1, value: '0px 0px 1px 0px #ddd, 0px 1px 0px -1px #ddd, 0px 0px 5px 0px #ddd' },
  { level: 2, value: '0px 2px 2px 0px #ddd, 0px 3px 1px -2px #ddd, 0px 1px 5px 0px #ddd' },
  { level: 3, value: '0px 2px 3px 0px #ddd, 0px 3px 8px -2px #ddd, 0px 1px 8px 0px #ddd' },
  { level: 4, value: '0px 3px 4px 0px #ddd, 0px 3px 5px 0px #ddd, 0px 1px 5px 0px #ddd' },
  { level: 5, value: '0px 4px 5px 0px #ddd, 0px 1px 10px 0px #ddd, 0px 2px 4px -1px #ddd' },
  { level: 6, value: '0px 7px 5px 0px #ddd, 0px 3px 11px 2px #ddd, 0px 5px 11px -2px #ddd' },
  { level: 7, value: '0px 8px 17px 2px #ddd, 0px 3px 14px 2px #ddd, 0px 5px 5px -3px #ddd' },
  { level: 8, value: '0px 16px 24px 2px #ddd, 0 6px 30px 5px #ddd, 0 8px 10px -7px #ddd' },
  { level: 9, value: '0px 22px 35px 3px #ddd, 0px 8px 17px 4px #ddd, 0px 10px 20px -6px #ddd' },
  { level: 10, value: '0px 24px 38px 3px #ddd, 0px 9px 46px 8px #ddd, 0px 11px 15px -7px #ddd' }
]

class BoxShadowSetting extends Component {
  constructor(props) {
    super(props)
    this.state = {
      boxShadow: '',
      boxShadows: defaultValue
    }
  }

  componentDidMount() {
    const { _this } = this.props
    const { style } = _this.state
    const styleName = this.props.styleName || 'box-shadow'
    const boxShadow = style[styleName] || ''

    setTimeout(() => {
      const level = this.handleGetLevel()

      if (level === undefined) {
        this.setState({ advanced: true })
      }
    }, 0)

    this.setStateBoxShadows({ boxShadow, useState: false, submit: false })
  }

  setStateBoxShadows = ({ boxShadow, useState, submit, replaceDefault }) => {
    const boxShadows = []
    const items = boxShadow ? boxShadow.split(",") : []

    if (items.length > 1) {
      items.map((items, i) => {
        const item = this.getItemValue({
          value: items,
          index: i,
          useState,
          replaceDefault
        })

        boxShadows.push(item)
      })
    }

    if (replaceDefault) {
      return boxShadows
    } else {
      this.converBoxShadows({
        boxShadows,
        submit
      })
    }
  }

  getItemValue = ({ value, index, useState, replaceDefault }) => {
    value = value.trim()

    const split = value.split(" ")
    const { boxShadows } = this.state
    const boxShadow = boxShadows[index]

    let x, y, blur, spread, color, type

    color = boxShadow && boxShadow.color || '#ddd'
    type = boxShadow && boxShadow.type || ''

    // outside
    if (split.length === 5) {
      x = parseInt(split[0])
      y = parseInt(split[1])
      blur = parseInt(split[2])
      spread = parseInt(split[3])
      color = useState ? color : split[4]
      type = useState ? type : ''

    // inside
    } else if (split.length === 6) {
      x = parseInt(split[0])
      y = parseInt(split[1])
      blur = parseInt(split[2])
      spread = parseInt(split[3])
      color = useState ? color : split[4]
      type = useState ? type : split[5]
    }

    if (replaceDefault) {
      color = '#ddd'
      type = ''
    }

    return {
      x, y, blur, spread, color, type, value
    }
  }

  getBoxShadow = (shadow) => {
    let { x, y, blur, spread, color, type } = shadow

    if (type) {
      type = ` ${type}`
    }

    return `${x}px ${y}px ${blur}px ${spread}px ${color}${type}`
  }

  converBoxShadows = ({ boxShadows, submit, returnData }) => {
    let text = ""

    boxShadows.map((shadow, i) => {
      const boxShadow = this.getBoxShadow(shadow)

      if (i === 0) {
        text = boxShadow
      } else {
        text = text + ', ' + boxShadow
      }
    })

    if (returnData) {
      return text
    } else {
      this.setState({ boxShadows, boxShadow: text }, () => {
        if (submit) {
          this.handleSubmit({
            boxShadow: this.state.boxShadow,
            submit
          })
        }
      })
    }
  }

  handleSubmit = ({ boxShadow, submit }) => {
    const { _this } = this.props
    const styleName = this.props.styleName || 'box-shadow'

    if (boxShadow) {
      if (submit) {
        addStyle(_this, { style: styleName, value: boxShadow })
      }
    } else {
      this.handleRemoveBoxShadow()
    }
  }

  handleTypeChange = (e, index) => {
    const type = e.target.value
    const { advanced } = this.state
    const boxShadows = this.state.boxShadows.slice()
    const item = boxShadows[index]
    const newValue = { ...item, type }

    if (advanced) {
      boxShadows[index] = newValue
    } else {
      boxShadows.map((val, i) => {
        boxShadows[i] = {
          ...boxShadows[i],
          type
        }
      })
    }

    this.converBoxShadows({
      boxShadows,
      submit: true
    })
  }

  handleLevelChange = (e) => {
    const value = parseInt(e.target.value)

    if (value === 0) {
      this.handleRemoveBoxShadow()
    } else {
      const filter = shadowLevel.filter(shadow => shadow.level === value)[0]

      this.setStateBoxShadows({
        boxShadow: filter.value,
        useState: true,
        submit: true
      })
    }
  }

  handleColorChange = (color, index) => {
    const { advanced } = this.state
    const boxShadows = this.state.boxShadows.slice()
    const item = boxShadows[index]
    const newValue = { ...item, color }

    if (advanced) {
      boxShadows[index] = newValue
    } else {
      boxShadows.map((val, i) => {
        boxShadows[i] = {
          ...boxShadows[i],
          color
        }
      })
    }

    this.converBoxShadows({
      boxShadows,
      submit: true
    })
  }

  hadleValueChange = (e, type, index) => {
    let value = parseInt(e.target.value)
    value = value || 0

    const boxShadows = this.state.boxShadows.slice()
    const item = boxShadows[index]
    const newValue = { ...item, [type]: value }

    boxShadows[index] = newValue

    this.converBoxShadows({
      boxShadows,
      submit: true
    })
  }

  handleRemoveBoxShadow = () => {
    const { _this } = this.props
    const styleName = this.props.styleName || 'box-shadow'

    this.setState({
      boxShadow: undefined,
      boxShadows: []
    }, () => {
      removeStyle(_this, styleName)
    })
  }

  hadleRemoveShadow = (index) => {
    const boxShadows = this.state.boxShadows.slice()
    boxShadows.splice(index, 1)

    setTimeout(() => {
      this.converBoxShadows({
        boxShadows,
        submit: true
      })
    }, 0)
  }

  hadleAddShadow = () => {
    const boxShadows = this.state.boxShadows.slice()
    length = boxShadows.length

    if (length === 0) length = length+1

    boxShadows.push({
      x: 0,
      y: 0,
      blur: 4*length,
      spread: 0,
      color: '#ddd',
      type: ''
    })

    this.converBoxShadows({
      boxShadows,
      submit: true
    })
  }

  handleSetAdvanced = (value) => {
    this.setState({ advanced: value })
  }

  handleGetLevel = () => {
    let { boxShadow } = this.state
    let level

    if (boxShadow === '') {
      level = 0
    } else {
      const boxShadows = this.setStateBoxShadows({
        boxShadow,
        useState: false,
        submit: false,
        replaceDefault: true
      })

      boxShadow = this.converBoxShadows({
        boxShadows,
        submit: false,
        returnData: true
      })

      const filter = shadowLevel.filter(shadow => shadow.value === boxShadow)[0]

      if (filter) {
        level = filter.level
      }
    }

    return level
  }

  render() {
    const { boxShadows, advanced } = this.state
    const level = this.handleGetLevel()

    return (
      <div className="text-left">
        <table>
          <tbody>
            <tr>
              <td className="pd-0 font-0-7">เงากล่อง</td>
              <td className="pd-0 font-0-7 text-right">
                <a className={css.btn} onClick={this.handleRemoveBoxShadow}>ไม่มีเงา</a>
                <a className={`${css.btn} ${!advanced && css.active}`} onClick={() => this.handleSetAdvanced(false)}>ปกติ</a>
                <a className={`${css.btn} ${advanced && css.active}`} onClick={() => this.handleSetAdvanced(true)}>ขั้นสูง</a>
              </td>
            </tr>
          </tbody>
        </table>

        <div>
          { !advanced ?
            <InputBoxShadow
              index={0}
              boxShadow={boxShadows[0]}
              level={level}
              shadowLevel={shadowLevel}
              shadowTypeChange={this.handleTypeChange}
              shadowLevelChange={this.handleLevelChange}
              shadowColorChange={this.handleColorChange}
            />
          :
            <div>
              { boxShadows.map((shadow, i) => {
                return (
                  <InputBoxShadow
                    key={i}
                    index={i}
                    boxShadow={shadow}
                    advanced
                    shadowTypeChange={this.handleTypeChange}
                    shadowColorChange={this.handleColorChange}
                    valueChange={this.hadleValueChange}
                    removeShadow={this.hadleRemoveShadow}
                  />
                )
              }) }

              <div className="center pd-top-10">
                <a onClick={this.hadleAddShadow} className="btn btn-xxs btn-shadow-none pd-right-10">
                  <i className="material-icons left">add</i> เพิ่มเงา
                </a>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

export default BoxShadowSetting
