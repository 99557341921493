// Actions
import { authValue, userValue } from 'client/redux/Users/UsersValues'
import { customerValue } from 'client/redux/Customers/CustomersValues'
import { businessValue } from 'client/redux/Businesses/BusinessesValues'
import { cartValue } from 'client/redux/Carts/CartsValues'

// util
import { getNumeral } from '../../../util/getNumeral'
import { getGeoFull, getGeoShort } from '../../../util/getGeo'
import { getDate } from '../../../util/getDateTime'

// Functions
import { addStyle, removeStyle } from './textEditorFunctions'
import { useContext } from 'react'
import { AppContext } from '../../../containers/websites/Sites/containers/SiteContainer'
import { getAlbumImages } from '../components/ApiSettings/js/apiSettings'


export const jsonParse = (value) => {
  let data

  if (typeof value === 'string') {
    data = value && JSON.parse(value)
  } else {
    data = value ? value : {}
  }

  return data
}


export const getValue = (props, name) => {
  const content = props.content
  const value = content[name] && JSON.parse(content[name])

  return {
    [name]: content[name],
    style: value && value.style ? JSON.parse(value.style) : {},
    class: value && value.class ? JSON.parse(value.class) : [],
    items: value && value.items ? JSON.parse(value.items) : [],
  }
}


export const addStateItem = (_this, { stateName, value, params, setStateMore }) => {
  const items = _this.state[stateName].slice()

  items.push(JSON.stringify(value))

  _this.setState({ [stateName]: items, ...setStateMore }, () => {
    _this.handleSubmit({ timeout: true, params })
  })
}

export const removeStateItem = (_this, { stateName, index, params, setStateMore }) => {
  if (index !== undefined) {
    const items = _this.state[stateName].slice()
    items.splice(index, 1)

    _this.setState({ [stateName]: items, ...setStateMore }, () => {
      _this.handleSubmit({ timeout: true, params })
      if (_this.focus) _this.focus()
    })
  } else {
    console.log('index is undefined')
  }
}



export const updateStateItem = (_this, { stateName, index, value, mergeValue, timeout, params, setStateMore }) => {
  if (index !== undefined) {
    const items = _this.state[stateName].slice()

    let newValue

    if (mergeValue) {
      const itemSelect = JSON.parse(items[index])
      newValue = {
        ...itemSelect,
        ...value
      }
    } else {
      newValue = value
    }

    items[index] = JSON.stringify(newValue)

    _this.setState({ [stateName]: items, ...setStateMore }, () => {
      _this.handleSubmit({ timeout, params })
      if (_this.focus) _this.focus()
    })
  } else {
    console.log('index is undefined')
  }
}



export const submit = (_this, { value, timeout=true }) => {
  const { form, content, fnSubmit } = _this.props

  if (form) {
    clearTimeout(_this.timer)

    if (!_this.loading) {
      _this.loading = true

      setTimeout(() => {
        //_this.props.dispatch(addLoad('site-update-loading'))
      }, 0)
    }

    if (timeout) {
      _this.timer = setTimeout(() => {
        if (value) {
          fnSubmit({ _id: content._id, value })
        } else {
          form.props.submit()
        }

        _this.loading = false
      }, 3000)

    } else {
      if (value) {
        fnSubmit({ _id: content._id, value })
      } else {
        form.props.submit()
      }

      _this.loading = false
    }
  } else {
    console.log('form is undefined')
  }
}



export const getBorderColor = (style, styleName='border') => {
  let value = 0
  let color = '#ddd'

  if (style[styleName]) {
    const border = style[styleName]
    const borderSplit = border.split('#')

    if (borderSplit[0]) {
      value = parseInt(borderSplit[0])
    }

    if (borderSplit[1]) {
      color = `#${borderSplit[1]}`
    }
  }

  return { value, color }
}

export const getColumnClassName = (amountColumns) => {
  const getColumn = (column) => {
    column = parseInt(column)

    let className = ''

    if (column === 1) className = '12'
    else if (column === 2) className = '6'
    else if (column === 3) className = '4'
    else if (column === 4) className = '3'
    else if (column === 5) className = '2-4'
    else if (column === 6) className = '2'

    return className
  }

  const xxs = amountColumns && amountColumns.xxs || '1',
        xs = amountColumns && amountColumns.xs || '1',
        sm = amountColumns && amountColumns.sm || '1',
        md = amountColumns && amountColumns.md || '2',
        lg = amountColumns && amountColumns.lg || '3',
        xlg = amountColumns && amountColumns.xlg || '4'

  const xxsClassName = `col-xxx-${getColumn(xxs)}`
  const xsClassName = `col-xs-${getColumn(xs)}`
  const smClassName = `col-sm-${getColumn(sm)}`
  const mdClassName = `col-md-${getColumn(md)}`
  const lgClassName = `col-lg-${getColumn(lg)}`
  const xlgClassName = `col-xlg-${getColumn(xlg)}`

  return `${xxsClassName} ${xsClassName} ${smClassName} ${mdClassName} ${lgClassName} ${xlgClassName}`
}


export const getApi = (content, data) => {
  const api = data && data.api && JSON.parse(data.api)

  let apiValue

  if (api && content && content.api) {
    apiValue = {
      typeApi: api && api.typeApi ? api.typeApi : 'api',
      dataStoreName: api && api.dataStoreName || '',

      label_local: api && api.label_local || '',
      label_en: api && api.label_en || '',

      prefix_local: api && api.prefix_local || '',
      prefix_en: api && api.prefix_en || '',

      suffix_local: api && api.suffix_local || '',
      suffix_en: api && api.suffix_en || '',

      key: api && api.key || '',
      keyFull: api && api.keyFull || '',
      index: api && api.index || '',
      subkey: api && api.subkey || '',
      type: api && api.type || '',

      album: api && api.album || '',
      albumAdvanced: api && api.albumAdvanced || '',
      albumCustomName: api && api.albumCustomName || '',
      dateFormat: api && api.dateFormat || '',

      modalId: api && api.modalId || '',
      modalTypeApi: api && api.modalTypeApi || '',
    }
  }

  return apiValue
}

export const getApiKeyFull = ({ api, keyFull, data }) => {
  let value

  if (api) {
    keyFull = api.keyFull
  }

  if (keyFull && data) {
    const keySplit = keyFull.split('.')

    if (keySplit.length === 0) {
      if (data[keyFull]) {
        value = data[keyFull]
      }

    } else {
      let newValue = data

      keySplit.map(keyValue => {
        if (newValue[keyValue] !== undefined) {
          newValue = newValue[keyValue]
          value = newValue
        } else {
          value = ''
        }
      })
    }
  }

  return value
}

export const getDataStore = ({ store }) => {
  const { auth, customers, businesses, carts, app } = store
  const user = auth && auth.user
  const business = businesses && businesses.data && businesses.data.length !== 0 && businesses.data[0]
  const cart = carts && carts.data && carts.data.length !== 0 && carts.data[0]

  let customer = user && user.customer

  if (customer && customers.data) {

    const updateCustomer = customers.data.filter(data => data && data._id === customer._id)[0]

    if (updateCustomer) {
      customer = updateCustomer
    }
  }

  return {
    auth: auth && authValue({ auth }),
    user: user && userValue({ user }),
    customer: customer && customerValue({ customer }),
    business: business && businessValue({ business }),
    cart: cart && cartValue({ cart }),
    modals: app.modals
  }
}


export const getDataApi = ({ api, apiData, templateData, dataStore }) => {
  const app = useContext(AppContext) || {}
  const { modals } = app

  const { typeApi, dataStoreName, modalId, modalTypeApi } = api || {}

  let data

  if (typeApi === undefined || typeApi === 'api') {
    data = apiData
  }

  if (typeApi === 'api-template' || typeApi === 'api-template-array') {
    data = templateData
  }

  if (typeApi === 'data-store' && dataStoreName && dataStore) {
    if (dataStoreName === 'user.customer') {
      data = dataStore.user && dataStore.user.customer

    } else if (dataStoreName === 'modal' && modalId && modalTypeApi) {
      const modal = modals && modals.filter && modals.filter(modal => modal.id === modalId)[0]
      data = modal && modal.params && modal.params[modalTypeApi] || {}
    } else {
      data = dataStore[dataStoreName]
    }
  }

  return data
}

export const getApiValue = ({ api, apiData, apiForArray, apiForArrayValue, templateData, dataStore, lang='local'}) => {
  let value, valueArray

  const { typeApi, key, keyFull, index, subkey, type, dateFormat } = api || {}
  const data = getDataApi({ api, apiData, templateData, dataStore })


  if (keyFull) {
    if (typeApi === 'api-template-array') {
      const keySplit = keyFull.split('.$.')
      const api = {}

      if (keySplit[0] && keySplit[1]) {
        if (apiForArray) {
          api.keyFull = keySplit[0]
          valueArray = getApiKeyFull({ api, data })
        }

        if (apiForArrayValue) {
          api.keyFull = keySplit[1]
          value = getApiKeyFull({ api, data })
        }
      }

    } else {
      value = getApiKeyFull({ api, data })

      if (value) {
        if (type === 'lang' || type === 'string-lang') {
          const valueLocal = value['local'] || ''
          value = value[lang] || valueLocal

          if (typeof value !== 'string') {
            value = JSON.stringify(value)
          }
        }
      }
    }

  } else {
    if (key && data) {
      // key array object
      if (key && index && subkey) {
        if (data[api.key] && data[api.key][api.index] && data[api.key][api.index][api.subkey]) {
          value = data[api.key][api.index][api.subkey]
        }
      }

      // key array
      if (key && index && !subkey) {
        if (data[api.key] && data[api.key][api.index]) {
          value = data[api.key][api.index]
        }
      }

      // key object
      if (key && !index && subkey) {
        if (data[api.key] && data[api.key][api.subkey]) {
          value = data[api.key][api.subkey]
        }
      }

      // key only
      if (key && !index && !subkey) {
        if (data[api.key]) {
          value = data[api.key]
        }
      }
    }
  }


  if (value) {
    if (type === 'string' || type === '') {
      if (typeof value !== 'string') {
        value = JSON.stringify(value)
      }

    } else if (type === 'lang' || type === 'string-lang') {
      if (value[lang] !== undefined) {
        value = value[lang]

        console.log('----------------')
        console.log(value)

        if (typeof value !== 'string') {
          value = JSON.stringify(value)
        }
      }

    } else if (type === 'number') {
      value = getNumeral(value, 'auto')

    } else if (type === 'geo-full') {
      value = getGeoFull(value, lang)

    } else if (type === 'geo-short') {
      value = getGeoShort(value, lang)

    } else if (type === 'date-time' && dateFormat) {
      const formatSp = dateFormat.split(', ')

      let format = dateFormat || 'DD-MM-YYYY'
      let lg = 'th'

      if (formatSp[0]) {
        format = formatSp[0]
      }

      if (formatSp[1]) {
        lg = formatSp[1]
      }

      if (format && lg) {
        value = getDate(value, format, lg)
      }
    }
  }


  if (typeof value !== 'string') {
    value = JSON.stringify(value)
  }

  if (value === '{}') {
    value = ""
  }

  if (valueArray) {
    value = valueArray
  }

  return value
}






export const getStyleItems = (_this, { style, styleName }) => {
  let items = []
  let newItems = []

  if (_this) {
    style = _this.state.style
  }

  if (style[styleName]) {
    items = JSON.parse(style[styleName]) || []

    items.map(item => {
      newItems.push(JSON.parse(item))
    })
  }

  return items
}

export const addStyleItem = (_this, { styleName, value }) => {
  const items = getStyleItems(_this, { styleName })
  items.push(JSON.stringify(value))

  addStyle(_this, {
    style: styleName,
    value: JSON.stringify(items)
  })
}

export const updateStyleItem = (_this, { styleName, index, value, mergeValue }) => {
  if (index !== undefined) {
    const items = getStyleItems(_this, { styleName })

    let newValue

    if (mergeValue) {
      const itemSelect = JSON.parse(items[index])
      newValue = {
        ...itemSelect,
        ...value
      }
    } else {
      newValue = value
    }

    items[index] = JSON.stringify(newValue)

    addStyle(_this, {
      style: styleName,
      value: JSON.stringify(items)
    })
  } else {
    console.log('index is undefined')
  }
}

export const removeStyleItem = (_this, { styleName, index }) => {
  if (index !== undefined) {
    const items = getStyleItems(_this, { styleName })
    items.splice(index, 1)

    addStyle(_this, {
      style: styleName,
      value: JSON.stringify(items)
    })
  } else {
    console.log('index is undefined')
  }
}


export const getImagePath = ({ content, data, api, site, apiData, templateData, dataStore }) => {
  let paths, imageSelect

  if (api.type === 'custom' && api.keyFull) {
    let dataImages = data && getApiKeyFull({ api, data })

    if (dataImages && !dataImages.length) {
      dataImages = []
    }

    imageSelect = dataImages && dataImages[0]

  } else if (api.type === 'album') {
    const albumImages = getAlbumImages({ content, api, apiData, templateData, dataStore })

    imageSelect = albumImages && albumImages[0]


  } else {
    let dataImages = data && data.images || []

    if (api.type === 'profile' || api.type === 'cover') {
      imageSelect = dataImages.filter(img => img[api.type] === true)[0]

    } else if (api.type === 'profile-item-profile') {
      if (data) {
        imageSelect = data.images.filter(img => img.album === 'profile' && img.profile === true)[0]

        let singleItem

        if (data.items.length <= 1) {
          singleItem = data.items.filter(item => item.itemChoice === 'single')[0]
        }

        if (singleItem) {
          if (!imageSelect) {
            imageSelect = singleItem.images.filter(img => img.album === 'profile' && img.profile === true)[0]
          }
        }
      }
    } else {
      imageSelect = dataImages.filter(img => img.album === api.type)[0]
    }


    if (!imageSelect && site) {
      let apiTypeName = api.type

      if (api.type === 'profile-item-profile') {
        apiTypeName = "profile"
      }

      imageSelect = site.images.filter(img => img.album === `${apiTypeName}-not-found`)[0]
    }
  }

  if (imageSelect) {
    paths = imageSelect.paths
  }

  return paths
}

