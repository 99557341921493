import React, { Fragment, useMemo } from 'react'


import {
  setStyleValue,
  setAnimate,
  setStyleBackgroundImage,
  setStyleBackgroundImageBefore
} from '../ElementStyles/components/js/setDivStyle'

const DivBlockStyle = (props) => {
  const { edit, mode } = props
  const { onClick, onMouseEnter, onMouseLeave } = props.event || {}
  const style = props.style || {}
  const styleJsx = props.styleJsx || {}
  const name = props.name
  const backgroundImage = styleJsx[`${name}-background-image`]
  const className = props.className || ''

  const styleDefault = useMemo(() => setStyleValue({ name, style: styleJsx, mode }), [styleJsx && JSON.stringify(styleJsx)])
  const styleDefaultVW = useMemo(() => setStyleValue({ name, style: styleJsx, vw: true, mode }), [styleJsx && JSON.stringify(styleJsx)])
  const styleTablet = useMemo(() => setStyleValue({ device: 'tablet', name, style: styleJsx, mode }), [styleJsx && JSON.stringify(styleJsx)])
  const styleMobile = useMemo(() => setStyleValue({ device: 'mobile', name, style: styleJsx, mode }), [styleJsx && JSON.stringify(styleJsx)])
  const attrAnimate = useMemo(() => setAnimate({ name, style: styleJsx }), [styleJsx && JSON.stringify(styleJsx)])

  const bgImg = useMemo(() => setStyleBackgroundImage(name, styleJsx), [styleJsx && JSON.stringify(styleJsx)])
  const bgImgBefore = useMemo(() => setStyleBackgroundImageBefore(name, styleJsx), [styleJsx && JSON.stringify(styleJsx)])


  return (
    <Fragment>
      <div
        {...attrAnimate}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={`block-jsx ${className} ${backgroundImage ? 'background-image-jsx' : ''}`}
        style={style}>

        { props.children }

        <style jsx>{`
          .block-jsx {
            ${styleDefault}

            height: ${(props.logo || props.type === 'image-gallery') && '100%'};

            ${!edit && `
              float: ${styleJsx[`${name}-float`]};
            `}

            ${!mode && `
              position: ${styleJsx[`${name}-position`]};
            `}
          }

          @media (min-width: 1500px) {
            .block-jsx {
              ${styleDefaultVW}
            }
          }

          @media (min-width: 601px) and (max-width: 1024px) {
            .block-jsx {
              ${styleTablet}
            }
          }

          @media (min-width: 50px) and (max-width: 600px) {
            .block-jsx {
              ${styleMobile}
            }
          }

          .background-image-jsx {
            ${bgImg}
          }
          .background-image-jsx:before {
            ${bgImgBefore}
          }
        `}</style>
      </div>
    </Fragment>
  )
}

export default React.memo(DivBlockStyle)
