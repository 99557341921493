import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

// Compoents Global
import Form from '../../../../Form'
import getFormValue from '../../../../../util/getFormValue'

// Components Local
import FormLogin from './FormLogin'

// Import Actions
import { signIn } from '../../../../../redux/Auth/AuthActions'


class FormLoginDisplay extends Component {
  constructor(props) {
    super(props)
    this.state = {  }
  }

  handleSubmit = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(this.refs[formName])
    const { style, business } = this.props

    params.businesses_id = business._id

    if (params) {
      this.props.dispatch(signIn({
        body: params,
        loadId: formName,
        modalId: style['close-modal-id'],
        errorAlert: true,
        toastMsg: 'เข้าสู่ระบบแล้ว'
      }))
    }
  }

  render() {
    const { id, modeManage } = this.props
    //const modeManage = display

    return (
      <Fragment>
        { modeManage ?
          <FormLogin display id={id} {...this.props} />
        :
          <Form
            ref={id}
            name={id}
            recheckForm
            submit={(e) => this.handleSubmit(e, id)}>

            <FormLogin display id={id} {...this.props} />
          </Form>
        }
      </Fragment>
    )
  }
}

export default connect()(FormLoginDisplay)
