import React from 'react'
import css from './css/tooltip.css'


const TooltipNew = (props) => {
  const width = props.width || 120
  const unit = props.unit || 'px'

  let tooltipText, top, bottom

  if (props.top) {
    tooltipText = css.tooltipTextTop
    bottom = props.childHeight

  } else if (props.right) {
    tooltipText = css.tooltipTextRight

  } else if (props.bottom) {
    tooltipText = css.tooltipTextBottom
    top = props.childHeight

  } else if (props.left) {
    tooltipText = css.tooltipTextLeft
  }

  const propsStyle = props.style || {}
  const style = {
    ...propsStyle,
    width: width + unit,
    marginLeft: (props.top || props.bottom) && (-width/2) + unit,
    visibility: props.display && 'visible',
    top,
    bottom
  }

  if (props.msg) {
    return (
      <div className={`${css.tooltip} ${props.className}`}>
        {props.children}

        <span className={tooltipText} style={style}>{props.msg}</span>
      </div>
    )
  } else {
    return (
      <div>
        {props.children}
      </div>
    )
  }
}

export default TooltipNew
