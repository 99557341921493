import React from 'react'
import { connect } from 'react-redux'

// Components Local
import SideNavbar from './components/SideNavbar'


const TextEditorSideNavbarDisplay = (props) => {
  const lang = props.lang || 'local'
  const content = props.content
  const sideNavbar = content.sideNavbar && JSON.parse(content.sideNavbar)
  const style = sideNavbar && sideNavbar.style ? JSON.parse(sideNavbar.style) : {}
  const className = sideNavbar && sideNavbar.class ? JSON.parse(sideNavbar.class) : []

  const model = sideNavbar && sideNavbar.model
  const iconHidden = sideNavbar && sideNavbar.iconHidden

  return (
    <SideNavbar
      {...props}
      display
      lang={lang}
      className={className}
      style={style}
      model={model}
      iconHidden={iconHidden}
    />
  )
}

export default connect()(TextEditorSideNavbarDisplay)
