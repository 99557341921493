import React from 'react'
import { connect } from 'react-redux'

// Components Local
import Modal from './components/Modal'


const TextEditorModalDisplay = (props) => {
  const { content } = props
  const modal = content.modal && JSON.parse(content.modal)
  const style = modal && modal.style ? JSON.parse(modal.style) : {}

  return (
    <Modal {...props} style={style} />
  )
}

export default connect()(TextEditorModalDisplay)
