import React, { Component, Fragment, useMemo } from 'react'

// Components Global
import { generateItems1 } from '../../../../DragDropMenu/functions/dragDropMenuFunctions'
import { LayoutsDesign } from '../../../../LayoutsDesign'

// Components Editor
import { EditorImageDisplay } from '../../../components/EditorImage'
import Template from '../../../TextEditorTemplate/components/Template'

// Components Local
import DropdownLists from '../DropdownLists'
import MenuIcon from '../MenuIcon'
import SideNav from '../SideNav'

// Functions
import { getSections } from '../../../../../redux/Sites/SitesFunctions'

// Functions
import { generateClassToStringByGroup } from '../../../functions/textEditorFunctions'

// css
import css from './css/navbar.css'

// js
import { setStyleValue } from '../../../components/ElementStyles/components/js/setDivStyle'


const Navbar = (props) => {
  const lang = props.lang || 'local'
  const { action, section, mode, loggedIn, header, headerFixed, content } = props
  const groupId = `design-content-${content._id}`

  const { pages, pageCurrent, data } = action.params
  let pagesLists = generateItems1({ items: pages, navbarShow: true })
  pagesLists = pagesLists.filter(page => page.type === 'page')

  const { className, style, image, logoHidden, iconHidden } = props
  const designType = style['nav-design-type']
  const templates_id = style['templates_id']

  let heightPx = style['height'] || '50px'

  let listHoverColor = style['list-hover-color'] || '#000'
  let listHoverBackground = style['list-hover-background'] || '#eee'

  let listActiveColor = style['list-active-color'] || '#000'
  let listActiveBackground = style['list-active-background'] || '#eee'

  let paths

  if (image && image.paths) {
    paths = JSON.parse(image.paths)
  }

  const gotoPath = (path) => {
    if (!props.mode) {
      if (path === 'index') {
        path = '/'
      } else {
        path = `/${path}`
      }

      props.history.push(`${path}`)
    }
  }

  const pathCurrent = pageCurrent && pageCurrent.path

  let navId

  if (header) {
    navId = 'navbar'
  } else if (headerFixed) {
    navId = 'navbar-fixed'
  }

  if (style['nav-show-type'] === 'fixed') {
    navId = 'navbar-fixed'
  }

  const styleDefault = useMemo(() => setStyleValue({ name: 'nav', style }), [style && JSON.stringify(style)])
  const styleDefaultVW = useMemo(() => setStyleValue({ name: 'nav', style, vw: true }), [style && JSON.stringify(style)])
  const styleTablet = useMemo(() => setStyleValue({ device: 'tablet', name: 'nav', style }), [style && JSON.stringify(style)])
  const styleMobile = useMemo(() => setStyleValue({ device: 'mobile', name: 'nav', style }), [style && JSON.stringify(style)])

  return (
    <Fragment>
      <div
        id={navId}
        className={`${css.nav}  nav ${generateClassToStringByGroup(className, 'main')}`}>

        <div id={`design-${content._id}`} className={`editor-navbar-container ${css.navWrapper} ${generateClassToStringByGroup(className, 'container')}`}>

          { designType === 'custom' &&
            <LayoutsDesign
              display
              bodyColorNone
              mode={mode}
              loggedIn={loggedIn}
              action={action}
              content={content}
              section={section}
              groupId={groupId}
              sections={getSections(props, { byGroupId: groupId })}
            />
          }

          { designType === 'template' && templates_id &&
            <Template {...props} templates_id={templates_id} />
          }

          { designType !== 'custom' && designType !== 'template' &&
            <Fragment>

              <SideNav
                {...props}
                paths={paths}
                lists={pagesLists}
                className={`${css.sidenavTrigger}`}
                style={style}
              />

              { !logoHidden &&
                <a
                  className={`brandLogo ${css.brandLogo}`}
                  style={{ color: style && style['color'] }}>

                  { paths ?
                    <div className={css.boxLogo}>
                      <EditorImageDisplay
                        logo
                        type="image"
                        mode={mode}
                        style={{ height: '100%' }}
                        image={JSON.stringify(image)}
                      />
                    </div>
                  :
                    <span>LOGO</span>
                  }
                </a>
              }

              <ul className={`menu ${css.main} ${css.menu} ${generateClassToStringByGroup(className, 'menu')}`}>
                { pagesLists.map(page => {
                  return (
                    <Fragment key={page._id}>
                      <li className={`list ${page.lists && css.dropdown}`}>
                        <a
                          onClick={() => gotoPath(page.path)}
                          className={`${page.path === pathCurrent ? 'active' : ''}`}>

                          <div>
                            { !iconHidden &&
                              <MenuIcon item={page} />
                            }

                            { page.name[lang] }

                            { page.lists &&
                              <i className="material-icons right mg-left-5 mg-right--7">keyboard_arrow_down</i>
                            }
                          </div>
                        </a>

                        { page.lists &&
                          <div className={`${css.dropdownContent}`}>
                            <DropdownLists
                              lang={lang}
                              items={page.lists}
                              height={heightPx}
                              listHoverColor={listHoverColor}
                              listHoverBackground={listHoverBackground}
                              listActiveColor={listActiveColor}
                              listActiveBackground={listActiveBackground}
                              gotoPath={gotoPath}
                              pageCurrent={pageCurrent}
                            />
                          </div>
                        }
                      </li>
                    </Fragment>
                  )
                }) }
              </ul>
            </Fragment>
          }
        </div>
      </div>

      <style jsx>{`
        .nav {
          ${styleDefault}

          height: ${style['nav-height'] || '50px'};
          background-color: ${style['nav-background-color']};
          box-shadow: ${style['nav-shadow']};
          color: ${style['nav-color']};
          border-radius: ${style['nav-border-radius']};
          border-width: ${style['nav-border-width']};
          border-color: ${style['nav-border-color']};
          border-style: ${style['nav-border-style']};
          position: ${!mode && style['nav-show-type']};
          z-index: ${!mode && style['nav-show-type'] && '999999'};
        }

        @media (min-width: 1500px) {
          .nav {
            ${styleDefaultVW}
          }
        }

        @media (min-width: 601px) and (max-width: 1024px) {
          .nav {
            ${styleTablet}
          }
        }

        @media (min-width: 50px) and (max-width: 600px) {
          .nav {
            ${styleMobile}
          }
        }

        .nav ul.menu > li {
          border-radius: ${style['list-border-radius']};
          margin: ${style['list-margin']};
          padding: ${style['list-padding']};
          background-color: ${style['list-background-color']};
        }
        .nav ul.menu > li, .nav ul.menu > li > a, .nav ul.menu > li > a > i, .nav .brandLogo {
          color: ${style['list-color']};
          font-size: ${style['list-font-size']};
        }


        // ------------ list hover -----------
        .nav ul.menu > li:hover {
          border-radius: ${style['list-hover-border-radius']};
          margin: ${style['list-hover-margin']};
          padding: ${style['list-hover-padding']};
          background-color: ${style['list-hover-background-color']};
        }
        .nav ul.menu > li:hover a,
        .nav ul.menu > li:hover a > i {
          border-radius: ${style['list-hover-border-radius']} !important;
          color: ${style['list-hover-color']};
          font-size: ${style['list-hover-font-size']};
          border-style: ${style['list-hover-border-style']} !important;
          border-width: ${style['list-hover-border-width']} !important;
          border-color: ${style['list-hover-border-color']} !important;
        }


        // ------------ list active -----------
        .nav ul.menu > li > a.active {
          border-radius: ${style['list-active-border-radius']} !important;
          margin: ${style['list-active-margin']} !important;
          padding: ${style['list-active-padding']} !important;
          background-color: ${style['list-active-background-color']} !important;
          border-style: ${style['list-active-border-style']} !important;
          border-width: ${style['list-active-border-width']} !important;
          border-color: ${style['list-active-border-color']} !important;
        }
        .nav ul.menu > li.list > a.active,
        .nav ul.menu > li.list > a.active > i {
          color: ${style['list-active-color']} !important;
          font-size: ${style['list-active-font-size']} !important;
        }


        @media (min-width: 1500px) {
          .nav {
            height: ${style['nav-height-vw'] || '3.333vw'};
            border-radius: ${style['nav-border-radius-vw']};
          }
          .nav ul.menu > li > a {
            border-radius: ${style['list-border-radius-vm']} !important;
            margin: ${style['list-margin-vm']} !important;
            padding: ${style['list-padding-vm']} !important;
          }
          .nav ul.menu > li, .nav ul.menu > li > a, .nav ul.menu > li > a > i, .nav .brandLogo {
            font-size: ${style['list-font-size-vw']};
          }

          // ------------ list hover -----------
          .nav ul.menu > li:hover {
            border-radius: ${style['list-hover-border-radius-vw']};
            margin: ${style['list-hover-margin-vw']};
            padding: ${style['list-hover-padding-vw']};
          }
          .nav ul.menu > li:hover a,
          .nav ul.menu > li:hover a > i {
            border-radius: ${style['list-hover-border-radius-vw']} !important;
            font-size: ${style['list-hover-font-size-vw']};
          }
        }

        .nav ul.position-left {
          float: left;
        }
        .nav ul.position-center {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          -webkit-transform: translateX(-50%);
        }
        .nav ul.position-right {
          float: right;
        }
      `}</style>
    </Fragment>
  )
}

export default Navbar
