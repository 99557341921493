import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import SectionsList from './SectionsList'
import SectionsListDisplay from './SectionsListDisplay'

const SectionsListLazy = lazy(() => import('./SectionsList'))
const SectionsList = suspenseComponent(SectionsListLazy)

export {
  SectionsList,
  SectionsListDisplay
}
