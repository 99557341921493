// Config
import config from '../../../config'

const { facebook, google } = config.app

const userDefault = {
  providerGoogle: undefined,
  providerGoogleId: undefined,
  providerFacebook: undefined,
  providerFacebookId: undefined,
  firstName: {
    local: ''
  },
  lastName: {
    local: ''
  },
  email: '',
  gender: '',
  birthday: '',
  profilePicUrl:''
}

const fake = {
  email: "l_wichan@hotmail.com",
  first_name: "Wichan",
  last_name: "Areemarn",
  picture: {
    data: {
      url: "https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=3459128020788634&height=50&width=50&ext=1600491639&hash=AeQAZJPmqQVeKfho"
    }
  },
  providerFacebook: true,
  id: "3459128020788634"
}


export const loaderSocial = ({ id, provider, appId, callback }) => {
  if(provider=="Google") {
    //loader.loadGoogleSdk(id, appId, () => googleLoginSuccess(callback), socialLoginInvokeFailure)
    loader.loadGoogleSdk(appId)
  } else if(provider=='Facebook') {
    loader.loadFacebookSdk(appId)
  }
}

export const loadSocialDefault = ({ id, provider, callback }) => {
  if (provider === 'facebook-signin' || provider === 'google-signin') {
    let appId

    if (provider === 'facebook-signin') {
      provider = "Facebook"
      appId = facebook.appId
    } else {
      provider = "Google"
      appId = google.appId
    }

    loaderSocial({
      id,
      provider,
      appId,
      callback
    })
  }
}


export const googleLogin = ({ callback }) => {
  const user = userDefault
  const profile = gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile()
  const authResponse = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true)

  user.providerGoogle = true
  user.providerGoogleId = profile.getId()
  user.firstName.local = profile.getGivenName()
  user.lastName.local = profile.getFamilyName()
  user.email = profile.getEmail()
  user.profilePicUrl = profile.getImageUrl()

  callback(user, null)
}


export const loginFacebook = ({ appId, provider, callback }) => {
  if (provider == "Facebook") {
    if (!appId) {
      appId = facebook.appId
    }

    if (process.env.NODE_ENV === 'development') {
      console.log('Facebook Login development mode')
      facebookLoginSuccess({ res: fake, callback })

    } else {
      FB.init({
        appId,
        xfbml : true,
        version : 'v2.7'
      })

      FB.getLoginStatus((response) => {
        if (response.status === 'connected') {
          console.log('connected')
          console.log(response)

          FB.api('/me', { fields: 'email, name, id, first_name, last_name, picture, gender' }, (res) => {
            facebookLoginSuccess({ res: { ...res, ...response }, callback })
          })

        } else {
          FB.login((response) => {
            console.log('FB.login')
            console.log(response)

            if (response.authResponse) {
              FB.api('/me', { fields: 'email, name, id, first_name, last_name, picture, gender' }, (res) => {
                facebookLoginSuccess({ res: { ...res, ...response }, callback })
              })
            }
          },{ scope:'email' })
        }
      })
    }
  }
}

export const socialLoginInvokeFailure = (err) => {
  console.log(err)
}


export const facebookLoginSuccess = ({ res, callback }) => {
  if (!res) {
    console.log('res is undefined !!!')
    alert('res is undefined !!!')

  } else if (res.error) {
    console.log(res.error)
    alert(res.error)

  } else {
    const user = userDefault
    user.providerFacebook = true
    user.providerFacebookId = res.id
    user.firstName.local = res.first_name
    user.lastName.local = res.last_name
    user.email = res.email
    user.gender = res.gender
    user.profilePicUrl = res.picture.data.url

    callback(user, null)
  }
}



export const loginGoole = ({ callback }) => {
  gapi.auth2.getAuthInstance().signIn().then(() => {
    const user = userDefault
    const profile = gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile()

    user.providerGoogle = true
    user.providerGoogleId = profile.getId()
    user.firstName.local = profile.getGivenName()
    user.lastName.local = profile.getFamilyName()
    user.email = profile.getEmail()
    user.profilePicUrl = profile.getImageUrl()

    callback(user, null)

  }).catch(err => {
    console.log(err)
    alert('error getAuthInstance')
    alert(JSON.stringify(err))
  })
}

export const googleLoginSuccess = (callback) => {
  const user = userDefault
  const profile = gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile()
  const authResponse = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true)

  user.providerGoogle = true
  user.providerGoogleId = profile.getId()
  user.firstName.local = profile.getGivenName()
  user.lastName.local = profile.getFamilyName()
  user.email = profile.getEmail()
  user.profilePicUrl = profile.getImageUrl()

  callback(user, null)
}


export const loader = {
  /*loadGoogleSdk: ((cid, appId, fn, err) => {
    var js = document.createElement('script')
    js.src='https://apis.google.com/js/platform.js'
    js.id = 'gapi-client'
    js.onload = (() => {
      gapi.load('auth2', () => {
        if(!window.gapi.auth2.getAuthInstance()) {
          window.gapi.auth2.init({ client_id: appId })
        }
        window.gapi.auth2.getAuthInstance().attachClickHandler(cid, {}, fn, err)
      })
    })

    if(document.getElementsByTagName('script').length == 0) {
      document.appendChild(js)
    } else {
      document.getElementsByTagName('script')[0].parentNode.appendChild(js)
    }
  }),*/

  loadGoogleSdk: ((appId) => {
    var js = document.createElement('script')
    js.src='https://apis.google.com/js/platform.js'
    js.id = 'gapi-client'

    js.onload = (() => {
      gapi.load('auth2', () => {
        if(!window.gapi.auth2.getAuthInstance()) {
          window.gapi.auth2.init({ client_id: appId })
        }
      }, (err) => {
        console.log(err)
        alert('auth2 erorr')
      })
    })

    if(document.getElementsByTagName('script').length == 0) {
      document.appendChild(js)
    } else {
      document.getElementsByTagName('script')[0].parentNode.appendChild(js)
    }
  }),

  loadFacebookSdk: ((appId) => {
    let id = 'fb-client'
    let js
    let fjs = document.getElementsByTagName('script')[0]

    if (document.getElementById(id)) {
      return
    }

    js = document.createElement('script')
    js.id = id
    js.src = "//connect.facebook.net/en_US/all.js"
    js.onLoad = ()=> {
      window.fbAsyncInit = function() {
      FB.init({
        appId      : appId,
        xfbml      : true,
        version    : 'v2.7'
      })
    }
   }

   fjs.parentNode.insertBefore(js, fjs)
 })
}
