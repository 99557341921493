import React, { Fragment } from 'react'
import { connect } from 'react-redux'

// Components Local
import { EditorTextDisplay } from '../EditorText'
import BlockStyle from '../BlockStyle'

// Components Local
import {
  ImageGallery,
  ImageComponent,
  ImageCoverComponent,
  ImageAvatarComponent
} from './components/ImageType'

import { getData, getAlbumImages } from '../ApiSettings/js/apiSettings'

// Functions
import { generateClassToString } from '../../functions/textEditorFunctions'
import { getApi, getApiKeyFull } from '../../functions/textEditorEvent'

// css
import css from './css/editorImage.css'


const EditorImageDisplay = (props) => {
  let { image, sites, mode, content, apiData, templateData, dataStore, type, lang, logo, closeWindowModal } = props
  let data, imageSelect


  const site = sites && sites[0]

  lang = lang || 'local'

  image = image && JSON.parse(image)

  let className = image && image.class ? JSON.parse(image.class) : [],
      style = image && image.style ? JSON.parse(image.style) : {},
      paths = image && image.paths ? JSON.parse(image.paths) : '',
      link = image && image.link ? JSON.parse(image.link) : '',
      caption = image && image.caption,
      captionShow = image && image.captionShow,
      opacityShow = image && image.opacityShow

  const api = getApi(content, image)
  const inputCaption = paths && captionShow && (
    <EditorTextDisplay
      type="caption"
      lang={lang}
      value={caption}
    />
  )

  if (content && content.api && api && api.typeApi && api.type) {
    data = getData({ api, apiData, templateData, dataStore })

    if (api.type === 'custom' && api.keyFull) {
      let dataImages = data && getApiKeyFull({ api, data })

      if (dataImages && !dataImages.length) {
        dataImages = []
      }

      imageSelect = dataImages && dataImages[0]

    } else if (api.type === 'album') {
      const albumImages = getAlbumImages({ content, api, apiData, templateData, dataStore })

      imageSelect = albumImages && albumImages[0]


    } else {
      let dataImages = data && data.images || []

      if (api.type === 'profile' || api.type === 'cover') {
        imageSelect = dataImages.filter(img => img[api.type] === true)[0]

      } else if (api.type === 'profile-item-profile') {
        if (data) {
          imageSelect = data.images.filter(img => img.album === 'profile' && img.profile === true)[0]

          let singleItem

          if (data.items.length <= 1) {
            singleItem = data.items.filter(item => item.itemChoice === 'single')[0]
          }

          if (singleItem) {
            if (!imageSelect) {
              imageSelect = singleItem.images.filter(img => img.album === 'profile' && img.profile === true)[0]
            }
          }
        }
      } else {
        imageSelect = dataImages.filter(img => img.album === api.type)[0]
      }


      if (!imageSelect && site) {
        let apiTypeName = api.type

        if (api.type === 'profile-item-profile') {
          apiTypeName = "profile"
        }

        imageSelect = site.images.filter(img => img.album === `${apiTypeName}-not-found`)[0]
      }
    }

    if (imageSelect) {
      paths = imageSelect.paths
    }
  }

  return (
    <BlockStyle
      {...props}
      type={type}
      logo={logo}
      style={style}>

      { (type === 'image-gallery' || logo) ?
        <div style={{ height: '100%' }}>
          { type === 'image' &&
            <Fragment>
              { paths ?
                <ImageComponent
                  logo={logo}
                  mode={mode}
                  apiData={apiData}
                  link={JSON.stringify(link)}
                  paths={paths}
                  style={style}
                  match={props.match}
                  closeWindowModal={closeWindowModal}
                  lazyDisabled={props.lazyDisabled}
                />
              :
                <ImageAvatarComponent mode={mode} style={style} data={data} link={JSON.stringify(link)} />
              }
            </Fragment>
          }

          { type === 'image-gallery' &&
            <div
              className={`${css.imageGallery} ${generateClassToString(className)}`}
              style={{ ...style, display: 'flex', alignItems: 'center' }}>

              <ImageGallery
                {...props}
                gallery
                display
                mode={mode}
                paths={paths}
                style={style}
              />

              { captionShow &&
                <div className={css.boxCaption}>
                  <div className={css.caption}>
                    { inputCaption }
                  </div>
                </div>
              }
            </div>
          }
        </div>
      :
        <Fragment>
          { type === 'image' &&
            <Fragment>
              { paths ?
                <ImageComponent
                  logo={logo}
                  mode={mode}
                  apiData={apiData}
                  link={JSON.stringify(link)}
                  paths={paths}
                  style={style}
                  match={props.match}
                  lazyDisabled={props.lazyDisabled}
                  closeWindowModal={closeWindowModal}
                />
              :
                <ImageAvatarComponent mode={mode} style={style} data={data} link={JSON.stringify(link)} />
              }
            </Fragment>
          }

          { type === 'cover' &&
            <ImageCoverComponent
              {...props}
              display
              mode={mode}
              paths={paths}
              style={style}
              link={JSON.stringify(link)}
              opacityShow={opacityShow}
            />
          }
        </Fragment>
      }
    </BlockStyle>
  )
}

const mapStateToProps = (store, props) => {
  return {
    sites: store.sites.data
  }
}

export default connect(mapStateToProps)(EditorImageDisplay)
