import cuid from 'cuid'

export const settings = (style) => {
  const folderBreadcrumbs = style['folderBreadcrumbs'] !== undefined ? style['folderBreadcrumbs'] : false
  const tagBreadcrumbs = style['tagBreadcrumbs'] !== undefined ? style['tagBreadcrumbs'] : false
  const folderBreadcrumbsType = style['folderBreadcrumbsType'] !== undefined ? style['folderBreadcrumbsType'] : ''
  const tagBreadcrumbsType = style['tagBreadcrumbsType'] !== undefined ? style['tagBreadcrumbsType'] : ''
  const itemBreadcrumb = style['itemBreadcrumb'] !== undefined ? style['itemBreadcrumb'] : ''

  let prefixUrl = ''

  if (folderBreadcrumbsType === 'product') {
    if (folderBreadcrumbs) {
      prefixUrl = '/product-categories'
    } else if (tagBreadcrumbs) {
      prefixUrl = '/product-tags'
    }

  } else if (folderBreadcrumbsType === 'service') {
    prefixUrl = '/service-categories'
  } else if (folderBreadcrumbsType === 'tour') {
    prefixUrl = '/tour-categories'
  } else if (folderBreadcrumbsType === 'place') {
    prefixUrl = '/place-categories'

  } else if (folderBreadcrumbsType === 'article') {
    if (folderBreadcrumbs) {
      prefixUrl = '/article-categories'
    } else if (tagBreadcrumbs) {
      prefixUrl = '/article-tags'
    }
  }

  return {
    folderBreadcrumbs,
    itemBreadcrumb,
    folderBreadcrumbsType,
    prefixUrl,
    tagBreadcrumbs,
    tagBreadcrumbsType
  }
}

export const getItemBreadcrumb = ({ lang='local', folder, product, article, element='_id', prefixUrl='' }) => {
  let breadcrums = []

  if (product) {
    breadcrums.push(JSON.stringify({
      id: cuid(),
      [`name_${lang}`]: product.name[lang],
      link: JSON.stringify({
        type: 'inlink',
        inlink: `/product-details/${product._id}`,
        outlink: '',
        inlinkNewTab: false,
        outlinkNewTab: false
      })
    }))
  }

  if (article) {
    breadcrums.push(JSON.stringify({
      id: cuid(),
      [`name_${lang}`]: article.title[lang],
      link: JSON.stringify({
        type: 'inlink',
        inlink: `/article-details/${article._id}`,
        outlink: '',
        inlinkNewTab: false,
        outlinkNewTab: false
      })
    }))
  }

  return breadcrums
}


export const getFolberBreadcrums = ({ lang='local', folder, product, article, element='_id', prefixUrl='' }) => {
  let breadcrums = []

  if (folder && folder.parents) {
    let parents = folder.parents
    let length = parents.length
    let newParent = []

    for (let i=length-1; i>=0; i--) {
      newParent.push(parents[i])
    }

    newParent.push(folder)

    newParent.map(parent => {
      const breadcrum = {
        id: cuid(),
        [`name_${lang}`]: parent.name[lang],
        link: JSON.stringify({
          type: 'inlink',
          inlink: `${prefixUrl}/${parent[element]}`,
          outlink: '',
          inlinkNewTab: false,
          outlinkNewTab: false
        })
      }

      breadcrums.push(JSON.stringify(breadcrum))
    })

    /*if (product) {
      breadcrums.push(JSON.stringify({
        id: cuid(),
        [`name_${lang}`]: product.name[lang],
        link: JSON.stringify({
          type: 'inlink',
          inlink: `/product-details/${product._id}`,
          outlink: '',
          inlinkNewTab: false,
          outlinkNewTab: false
        })
      }))
    }*/
  } else {
    if (folder) {

      const breadcrum = {
        id: cuid(),
        [`name_${lang}`]: folder.name[lang],
        link: JSON.stringify({
          type: 'inlink',
          inlink: `${prefixUrl}/${folder[element]}`,
          outlink: '',
          inlinkNewTab: false,
          outlinkNewTab: false
        })
      }

      breadcrums.push(JSON.stringify(breadcrum))
    }
  }


  /*if (product) {
    breadcrums.push(JSON.stringify({
      id: cuid(),
      [`name_${lang}`]: product.name[lang],
      link: JSON.stringify({
        type: 'inlink',
        inlink: `/product-details/${product._id}`,
        outlink: '',
        inlinkNewTab: false,
        outlinkNewTab: false
      })
    }))
  }

  if (article) {
    breadcrums.push(JSON.stringify({
      id: cuid(),
      [`name_${lang}`]: article.title[lang],
      link: JSON.stringify({
        type: 'inlink',
        inlink: `/article-details/${article._id}`,
        outlink: '',
        inlinkNewTab: false,
        outlinkNewTab: false
      })
    }))
  }*/

  return breadcrums
}


export const getTagBreadcrums = ({ lang='local', tag, element='_id', prefixUrl='' }) => {
  let breadcrums = []

  if (tag) {
    const breadcrum = {
      id: cuid(),
      [`name_${lang}`]: tag.name[lang],
      link: JSON.stringify({
        type: 'inlink',
        inlink: `${prefixUrl}/${tag[element]}`,
        outlink: '',
        inlinkNewTab: false,
        outlinkNewTab: false
      })
    }

    breadcrums.push(JSON.stringify(breadcrum))
  }

  return breadcrums
}
