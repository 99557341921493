import apiCaller from '../util/apiCaller'
import apiCallerIndexedDB from '../IndexedDB/functions/apiCallerIndexedDB'
import { checkAppActions, errorAlert } from './App/AppActions'


// Actions
import { adds as add_folders } from './Folders/FoldersActions'
import { adds_more as add_folders_more } from './Folders/FoldersActions'


const AddMultipleToStore = ({ datas, addMultiple, dispatch }) => {
  addMultiple.map(addSingle => {
    const data = datas.filter(data => data.storeName === addSingle.storeName)[0]

    if (data) {
      dispatch(addSingle.add(data.value))
    }
  })
}

const AddRefsToStore = ({ data, addRefs, dispatch }) => {
  addRefs.map(ref => {
    let dataRef = data[ref.element]
    dataRef = dataRef && dataRef._id && dataRef

    if (dataRef) {
      dataRef = [dataRef]

      dispatch(ref.adds(dataRef))
    }
  })
}


export const fetchDatas = async ({ dispatch, params, api, add, addMore }) => {
  checkAppActions(dispatch, params, 'start')

  const useDB = params.useDB || 'server'

  let call

  if (useDB === 'server') {
    call = apiCaller

  } else if (useDB === 'client') {
    call = apiCallerIndexedDB
  }

  return call(api, 'get', params.body).then(res => {
    if (res.success) {

      if (params.search && res.count) {
        params.search.count = res.count
      }

      if (params.addMore) {

        // add group id
        if (!params.notSaveStore) {
          if (params.addGroupId) {
            let datas = res.data

            datas.map(data => {
              data.groupId = params.addGroupId

              if (params.addGroupIdReplace === true) {
                data.groupIdReplace = true
              }
            })

            dispatch(addMore(datas, params.search))

          } else {
            dispatch(addMore(res.data, params.search))
          }
        }

      } else {
        if (!params.notSaveStore) {

          // add group id
          if (params.addGroupId) {
            let datas = res.data

            datas.map(data => {
              data.groupId = params.addGroupId

              if (params.addGroupIdReplace === true) {
                data.groupIdReplace = true
              }
            })

            if (datas.length !== 0) {
              dispatch(add(datas, params.search, params))
            }

          } else {
            dispatch(add(res.data, params.search))
          }
        }
      }



      checkAppActions(dispatch, params, 'success', res)
    } else {
      if (!params.notShowErrorAlert) {
        dispatch(errorAlert(res))
      }

      checkAppActions(dispatch, params, 'error')
    }

    return res
  })
}


export const fetchData = async ({ dispatch, params={}, api, add, addMultiple, addRefs }) => {
  checkAppActions(dispatch, params, 'start')

  const useDB = params.useDB || 'server'

  let call

  if (useDB === 'server') {
    call = apiCaller

  } else if (useDB === 'client') {
    call = apiCallerIndexedDB
  }

  return call(api, 'get', params.body).then(res => {
    if (res.success) {
      if (!params.notStore) {
        if (addMultiple) {
          AddMultipleToStore({
            dispatch,
            addMultiple,
            datas: res.datas
          })

        } else if (addRefs) {
          dispatch(add(res.data))

          AddRefsToStore({
            dispatch,
            addRefs,
            data: res.data
          })

        } else {
          dispatch(add(res.data))
        }
      }

      checkAppActions(dispatch, params, 'success', res)
    } else {

      if (!params.notShowErrorAlert) {
        dispatch(errorAlert(res))
      }

      checkAppActions(dispatch, params, 'error')
    }

    return res
  })
}

export const fetchApi = async ({ dispatch, params={}, api }) => {
  checkAppActions(dispatch, params, 'start')

  const useDB = params.useDB || 'server'

  let call

  if (useDB === 'server') {
    call = apiCaller

  } else if (useDB === 'client') {
    call = apiCallerIndexedDB
  }

  return call(api, 'get', params.body).then(res => {
    if (res.success) {
      if (!params.notStore) {
        checkAppActions(dispatch, params, 'success', res)
      }
    } else {
      dispatch(errorAlert(res))
      checkAppActions(dispatch, params, 'error')
    }

    return res
  })
}


export const fetchQuery = async ({ dispatch, params={}, api, add }) => {
  checkAppActions(dispatch, params, 'start')

  const useDB = params.useDB || 'server'

  let call

  if (useDB === 'server') {
    call = apiCaller

  } else if (useDB === 'client') {
    call = apiCallerIndexedDB
  }

  return call(api, 'get', params.body).then(res => {
    if (res.success) {
      if (!params.notStore) dispatch(add(res.data))

      checkAppActions(dispatch, params, 'success', res)
    } else {
      dispatch(errorAlert(res))
      checkAppActions(dispatch, params, 'error')
    }

    return res
  })
}


export const fetchDataUpdate = async ({ dispatch, params={}, api, update, updateId }) => {
  checkAppActions(dispatch, params, 'start')

  const useDB = params.useDB || 'server'

  let call

  if (useDB === 'server') {
    call = apiCaller

  } else if (useDB === 'client') {
    call = apiCallerIndexedDB
  }

  return call(api, 'get', params.body).then(res => {
    if (res.success) {
      dispatch(update(res.data, updateId, params.body))
      checkAppActions(dispatch, params, 'success', res)
    } else {
      dispatch(errorAlert(res))
      checkAppActions(dispatch, params, 'error')
    }

    return res
  })
}

export const createData = async ({ dispatch, params, api, add, order, addMultiple, addRefs }) => {
  checkAppActions(dispatch, params, 'start')

  const useDB = params.useDB || 'server'

  let call

  if (useDB === 'server') {
    call = apiCaller

  } else if (useDB === 'client') {
    call = apiCallerIndexedDB
  }

  return call(api, 'put', params.body).then(res => {
    if (res.success) {
      if (!params.notSaveStore) {
        if (addMultiple) {
          AddMultipleToStore({
            dispatch,
            addMultiple,
            datas: res.datas
          })

        } else if (addRefs) {
          dispatch(add(res.data))

          AddRefsToStore({
            dispatch,
            addRefs,
            data: res.data
          })

        } else {
          dispatch(add(res.data, order))
        }
      }


      checkAppActions(dispatch, params, 'success', res)
    } else {

      if (!params.notShowErrorAlert) {
        dispatch(errorAlert(res))
      }

      checkAppActions(dispatch, params, 'error')
    }

    return res
  })
}

export const updateData = async ({ dispatch, params, api, update, updateId }) => {
  checkAppActions(dispatch, params, 'start')

  const useDB = params.useDB || 'server'

  let call

  if (useDB === 'server') {
    call = apiCaller

  } else if (useDB === 'client') {
    call = apiCallerIndexedDB
  }

  return call(api, 'put', params.body).then(res => {
    if (res.success) {
      if (!params.notSaveStore) {
        dispatch(update(res.data, updateId, params.body))
      }

      checkAppActions(dispatch, params, 'success', res)
    } else {

      if (!params.notShowErrorAlert) {
        dispatch(errorAlert(res))
      }

      checkAppActions(dispatch, params, 'error')
    }

    return res
  })
}

export const updateDataMany = async ({ dispatch, params, api }) => {
  checkAppActions(dispatch, params, 'start')

  const useDB = params.useDB || 'server'

  let call

  if (useDB === 'server') {
    call = apiCaller

  } else if (useDB === 'client') {
    call = apiCallerIndexedDB
  }

  return call(api, 'put', params.body).then(res => {
    if (res.success) {
      /*if (!params.notSaveStore) {
        dispatch(update(res.data, updateId, params.body))
      }*/

      checkAppActions(dispatch, params, 'success', res)
    } else {

      if (!params.notShowErrorAlert) {
        dispatch(errorAlert(res))
      }

      checkAppActions(dispatch, params, 'error')
    }

    return res
  })
}

export const updateDataAll = async ({ dispatch, params={}, api, add, addMore }) => {
  checkAppActions(dispatch, params, 'start')

  const useDB = params.useDB || 'server'

  let call

  if (useDB === 'server') {
    call = apiCaller

  } else if (useDB === 'client') {
    call = apiCallerIndexedDB
  }

  return call(api, 'put', params.body).then(res => {
    if (res.success) {

      if (params.search && res.count) {
        params.search.count = res.count
      }

      if (params.addMore) {
        dispatch(addMore(res.data, params.search))
      } else {
        dispatch(add(res.data, params.search))
      }

      checkAppActions(dispatch, params, 'success', res)
    } else {
      dispatch(errorAlert(res))
      checkAppActions(dispatch, params, 'error')
    }

    return res
  })
}

export const deleteData = async ({ dispatch, params, api, remove, deleteId }) => {
  checkAppActions(dispatch, params, 'start')

  const useDB = params.useDB || 'server'

  let call

  if (useDB === 'server') {
    call = apiCaller

  } else if (useDB === 'client') {
    call = apiCallerIndexedDB
  }

  return call(api, 'delete').then(res => {
    if (res.success) {
      dispatch(remove(deleteId))
      checkAppActions(dispatch, params, 'success', res)
    } else {
      dispatch(errorAlert(res))
      checkAppActions(dispatch, params, 'error')
    }

    return res
  })
}

export const removeData = async ({ dispatch, params, api, remove, deleteId }) => {
  checkAppActions(dispatch, params, 'start')

  const useDB = params.useDB || 'server'

  let call

  if (useDB === 'server') {
    call = apiCaller

  } else if (useDB === 'client') {
    call = apiCallerIndexedDB
  }

  return call(api, 'delete', params.body).then(res => {
    if (res.success) {
      dispatch(remove(deleteId))
      checkAppActions(dispatch, params, 'success', res)
    } else {
      dispatch(errorAlert(res))
      checkAppActions(dispatch, params, 'error')
    }

    return res
  })
}



