import React from 'react'
import css from './css/loadingIcons.css'

const LoadingIcons = (props) => {
  var size

  if (props.size === 'small') {
    var size = css.laSm
  } else if (props.size === '2x') {
    var size = css.la2x
  } else if (props.size === '3x') {
    var size = css.la3x

  } else {
    var size = ""
  }

  return (
    <div className={css.box} style={props.style}>

      { props.name === 'laBallClipRotate' &&
        <div className={`${css.laBallClipRotate} ${size}`} style={{ width: props.width, height: props.height }}>
          <div style={{ width: props.width, height: props.height }}></div>
        </div>
      }

      { props.name === 'laLineScale' &&
        <div className={`${css.laLineScale} ${size}`} style={{ width: props.width, height: props.height }}>
          <div style={{ width: props.width, height: props.height }}></div>
          <div style={{ width: props.width, height: props.height }}></div>
          <div style={{ width: props.width, height: props.height }}></div>
          <div style={{ width: props.width, height: props.height }}></div>
          <div style={{ width: props.width, height: props.height }}></div>
        </div>
      }

      { props.name === 'laBallPulse' &&
        <div className={`${css.laBallPulse} ${size}`} style={{ width: props.width, height: props.height }}>
          <div style={{ width: props.width, height: props.height }}></div>
          <div style={{ width: props.width, height: props.height }}></div>
          <div style={{ width: props.width, height: props.height }}></div>
        </div>
      }
    </div>
  )
}

export default LoadingIcons