import Immutable from 'immutable'
import { 
  ADD_HOTELS,
  ADD_HOTEL,
  UPDATE_HOTEL,
  DELETE_HOTEL
} from './HotelsActions'


// Initial State
const initialState = { data: [] }

const HotelsReducer = (state=initialState, action) => {
  switch (action.type) {

    case ADD_HOTELS:
      return {
        data: action.hotels
      }

    case ADD_HOTEL:
      return {
        data: [action.hotel, ...state.data]
      }
    
    case DELETE_HOTEL:
      return { 
        data: state.data.filter(hotel => hotel._id !== action._id)
      }

    case UPDATE_HOTEL:
      const index = state.data.findIndex(hotel => hotel._id === action._id)
      const datas = state.data.filter(hotel => hotel._id === action._id)[0]
      const nested = Immutable.fromJS(state.data)
      const updated = nested.setIn([index], action.hotel)

      return { ...state, data: updated.toJS() }

    default:
      return state
  }
}


// Export Reducer
export default HotelsReducer
