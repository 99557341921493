import { addDataOne, addsDatasMany, updateDataOne } from 'core/redux/reduxActionsV2'
import { generateTypeName, generateTypeFunctions } from 'core/redux/reduxTypesV2'


// Export Constants
const NAME_TYPE = ['CONVERSATION_ROOMS', 'CONVERSATION_ROOM']
const name = "conversation-rooms"

// Type Names and Type Functions
export const {
  ADD_ONE,
  ADD_REPLACE_ONE,
  ADDS_MANY,
  ADDS_MANY_MORE,
  UPDATE_ONE,
  REMOVE_ONE,
} = generateTypeName(NAME_TYPE)

export const {
  add_one,
  add_replace_one,
  adds_many,
  adds_many_more,
  update_one,
  remove_one
} = generateTypeFunctions(NAME_TYPE)

export const addConversationRoomOne = add_one
export const addReplaceConversationRoomOne = add_replace_one
export const removeConversationRoomOne = remove_one

// Export Actions
export const fetchQueryConversationRoomOne = (query, params = {}) => dispatch => {
  const { authName, businesses_id, conversation_rooms_id, _id } = query

  return addDataOne({
    dispatch,
    params,
    api: `${name}-one/${authName}/${businesses_id}/${_id || conversation_rooms_id}`,
    add_one
  })
}

export const fetchQueryConversationRoomsMany = (query, params = {}) => dispatch => {
  const { authName, businesses_id } = query

  return addsDatasMany({
    dispatch,
    params,
    api: `${name}-many/${authName}/${businesses_id}`,
    adds_many,
    adds_many_more
  })
}

export const updateQueryConversationRoomOne = (query, params) => dispatch => {
  const { authName, businesses_id, conversation_rooms_id, _id } = query

  return updateDataOne({
    dispatch,
    params,
    api: `${name}-one/${authName}/${businesses_id}/${_id || conversation_rooms_id}`,
    update_one,
    updateId: _id || conversation_rooms_id
  })
}

/*export const removeConversationRoom = (query, params) => dispatch => {
  const { authName, businesses_id, conversation_rooms_id, _id } = query

  return removeData({
    dispatch,
    params,
    api: `${name}-one/${authName}/${businesses_id}/${_id || conversation_rooms_id}`,
    remove: remove,
    deleteId: _id || conversation_rooms_id
  })
}*/





