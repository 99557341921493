import React, { Component, Fragment } from 'react'

// Components Global
import RadioGroup from '../../../Inputs/RadioGroup'

// css
import css from './css/apiSettings.css'

class ApiSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.getValue()
    }
  }

  getValue = () => {
    const { _this } = this.props
    const { api } = _this.state

    return {
      typeApi: api && api.typeApi ? api.typeApi : 'api',
      dataStoreName: api && api.dataStoreName || '',
      label_local: api && api.label_local || '',
      label_en: api && api.label_en || '',
      key: api && api.key || '',
      keyFull: api && api.keyFull || '',
      index: api && api.index || '',
      subkey: api && api.subkey || '',
      type: api && api.type || '',
      prefix_local: api && api.prefix_local || '',
      prefix_en: api && api.prefix_en || '',
      suffix_local: api && api.suffix_local || '',
      suffix_en: api && api.suffix_en || '',
      album: api && api.album || '',
      albumAdvanced: api && api.albumAdvanced || '',
      albumCustomName: api && api.albumCustomName || '',
      dateFormat: api && api.dateFormat || '',
      modalId: api && api.modalId || '',
      modalTypeApi: api && api.modalTypeApi || ''
    }
  }

  handleTextChange = (e, name) => {
    const lang = this.props.lang || 'local'

    let value = e.target.value

    this.setState({ [`${name}_${lang}`]: value }, () => {
      this.onChange()
    })
  }

  handleKeyChange = (e) => {
    let key = e.target.value

    this.setState({ key }, () => {
      this.onChange()
    })
  }

  handleKeyFullChange = (e) => {
    let keyFull = e.target.value

    this.setState({ keyFull }, () => {
      this.onChange()
    })
  }

  handleSubkeyChange = (e) => {
    let subkey = e.target.value

    this.setState({ subkey }, () => {
      this.onChange()
    })
  }

  handleIndexChange = (e) => {
    let index = e.target.value

    this.setState({ index }, () => {
      this.onChange()
    })
  }

  handleTypeChange = (e) => {
    let type = e.target.value

    this.setState({ type }, () => {
      this.onChange()
    })
  }

  handleDataStoreNameChange = (e) => {
    let dataStoreName = e.target.value

    this.setState({ dataStoreName }, () => {
      this.onChange()
    })
  }

  handleAlbumChange = (e) => {
    let album = e.target.value

    this.setState({ album }, () => {
      this.onChange()
    })
  }

  handleAlbumCustomNameChange = (e) => {
    let albumCustomName = e.target.value

    this.setState({ albumCustomName }, () => {
      this.onChange()
    })
  }

  handleTypeApiChange = (typeApi) => {
    this.setState({ typeApi }, () => {
      this.onChange()
    })
  }

  handleModalIdChange = (e) => {
    let modalId = e.target.value

    this.setState({ modalId }, () => {
      this.onChange()
    })
  }

  handleModalTypeApiChange = (e) => {
    let modalTypeApi = e.target.value

    this.setState({ modalTypeApi }, () => {
      this.onChange()
    })
  }

  handleDateFormatChange = (e) => {
    let dateFormat = e.target.value

    this.setState({ dateFormat }, () => {
      this.onChange()
    })
  }

  handleAlbumAdvancedChange = (e) => {
    let albumAdvanced = e.target.value

    this.setState({ albumAdvanced }, () => {
      this.onChange()
    })
  }

  onChange = () => {
    const {
      typeApi,
      dataStoreName,
      label_local,
      label_en,
      key,
      keyFull,
      index,
      subkey,
      type,
      prefix_local, prefix_en,
      suffix_local, suffix_en,
      album,
      albumAdvanced,
      albumCustomName,
      dateFormat,
      modalId,
      modalTypeApi
    } = this.state

    this.props.onChange({
      typeApi,
      dataStoreName,
      label_local,
      label_en,
      key,
      keyFull,
      index,
      subkey,
      type,
      prefix_local,
      prefix_en,
      suffix_local,
      suffix_en,
      album,
      albumAdvanced,
      albumCustomName,
      dateFormat,
      modalId,
      modalTypeApi
    })
  }

  render() {
    const lang = this.props.lang || 'local'
    const prefix = this.state[`prefix_${lang}`]
    const suffix = this.state[`suffix_${lang}`]
    const { typeApi, dataStoreName, key, keyFull, index, subkey, type, album, albumAdvanced, albumCustomName, dateFormat, modalId, modalTypeApi } = this.state
    const { _this } = this.props

    let { content } = _this.props

    if (this.props.contectType === 'section') {
      content = {
        type: this.props.type
      }
    }


    const SelectDataStore = (
      <div>
        <div className="font-0-8">ชื่อข้อมูลใน Store</div>
        <select
          onChange={this.handleDataStoreNameChange}
          value={dataStoreName || ''}
          className={`browser-default ${css.input}`}>

          <option value="">-- เลือก</option>
          <option value="user">ข้อมูลผู้ใช้งาน (user)</option>
          <option value="user.customer">ข้อมูลผู้ใช้งานลูกค้า (user.customer)</option>
          <option value="customer">ข้อมูลลูกค้า (customer)</option>
          <option value="business">ข้อมูลธุรกิจ (business)</option>
          <option value="cart">ตะกร้าสินค้า (cart)</option>
          <option value="modal">โมเดล (modal)</option>
        </select>

        { dataStoreName === 'modal' &&
          <div>
            <div className="pd-top-10">
              <div className="font-0-8">โมเดลไอดี (modal ID)</div>
              <input
                className={css.input}
                type="text"
                value={modalId}
                onChange={this.handleModalIdChange}
                placeholder="โมเดลไอดี"
                style={{
                  border: '1px solid #ddd !important'
                }}
              />
            </div>

            <div className="pd-top-10">
              <div className="font-0-8">ประเภทการดึงข้อมูล</div>
              <select
                onChange={this.handleModalTypeApiChange}
                value={modalTypeApi || ''}
                className={`browser-default ${css.input}`}>

                <option value="">ประเภทการดึงข้อมูล</option>
                <option value="apiData">API (ค่าเดิม)</option>
                <option value="templateData">API Template</option>
              </select>
            </div>
          </div>
        }
      </div>
    )

    return (
      <Fragment>
        <Block title="ประเภทการดึงข้อมูล" icon="usb">
          <div>
            <RadioGroup
              id="typeApi"
              notSave
              value={typeApi}
              styleLabel={{
                fontSize: '0.8rem'
              }}
              onChange={this.handleTypeApiChange}
              radios={[
                {
                  value: 'api',
                  label: 'API (ค่าเดิม)'
                },
                {
                  value: 'api-template',
                  label: 'API Template'
                },
                {
                  value: 'api-template-array',
                  label: 'API Template Array'
                },
                {
                  value: 'data-store',
                  label: 'ข้อมูลใน Store',
                  child: SelectDataStore
                }
              ]}
            />
          </div>
        </Block>



        { (
            content.type === 'heading' ||
            content.type === 'descriptions' ||
            content.type === 'button' ||
            content.type === 'lists'
          ) &&

          <Block title="เนื้อหา" icon="notes">
            <div>
              <div>
                <div className="font-0-8">คีย์ (key)</div>
                <input
                  className={css.input}
                  type="text"
                  value={key}
                  onChange={this.handleKeyChange}
                  placeholder="คีย์"
                  style={{
                    border: '1px solid #ddd !important'
                  }}
                />
              </div>

              <div>
                <div className="font-0-8 mg-top-10">คีย์แบบเต็ม (key full)</div>
                <input
                  className={css.input}
                  type="text"
                  value={keyFull}
                  onChange={this.handleKeyFullChange}
                  placeholder="คีย์แบบเต็ม"
                  style={{
                    border: '1px solid #ddd !important'
                  }}
                />
              </div>

              { content.type !== 'button' &&
                <Fragment>
                  <div>
                    <div className="font-0-8 mg-top-10">อินเด็กซ์ (index)</div>
                    <select
                      onChange={this.handleIndexChange}
                      value={index || ''}
                      className={`browser-default ${css.input}`}>

                      <option value="">ไม่มี</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                    </select>
                  </div>
                  <div>
                    <div className="font-0-8 mg-top-10">คีย์ย่อย (subkey)</div>
                    <input
                      className={css.input}
                      type="text"
                      value={subkey}
                      onChange={this.handleSubkeyChange}
                      placeholder="คีย์ย่อย"
                      style={{
                        border: '1px solid #ddd !important'
                      }}
                    />
                  </div>
                  <div>
                    <div className="font-0-8 mg-top-10">ประเภทข้อมูล (type)</div>
                    <select
                      onChange={this.handleTypeChange}
                      value={type || ''}
                      className={`browser-default ${css.input}`}>

                      <option value="string">ตัวอักษร</option>
                      <option value="lang">ตัวอักษร (รองรับหลายภาษา)</option>
                      <option value="number">ตัวเลข (1,000)</option>
                      <option value="geo-full">ที่อยู่ (แบบเต็ม)</option>
                      <option value="geo-short">ที่อยู่ (แบบสั้น)</option>

                      <option value="date-time">เวลา</option>
                    </select>
                  </div>

                  { type === 'date-time' &&
                    <div>
                      <div className="font-0-8 mg-top-10">รูปแบบวันที่ (Date Format)</div>
                      <input
                        className={css.input}
                        type="text"
                        value={dateFormat}
                        onChange={this.handleDateFormatChange}
                        placeholder="คำนำหน้า"
                        style={{
                          border: '1px solid #ddd !important'
                        }}
                      />
                    </div>
                  }

                  <div>
                    <div className="font-0-8 mg-top-10">คำนำหน้า (prefix)</div>
                    <input
                      className={css.input}
                      type="text"
                      value={prefix}
                      onChange={(e) => this.handleTextChange(e, 'prefix')}
                      placeholder="คำนำหน้า"
                      style={{
                        border: '1px solid #ddd !important'
                      }}
                    />
                  </div>
                </Fragment>
              }


              <div>
                <div className="font-0-8 mg-top-10">{ content.type === 'button' ? 'ลิงก์ต่อท้าย' : 'คำต่อท้าย' } (suffix)</div>
                <input
                  className={css.input}
                  type="text"
                  value={suffix}
                  onChange={(e) => this.handleTextChange(e, 'suffix')}
                  placeholder={ content.type === 'button' ? 'ลิงก์ต่อท้าย' : 'คำต่อท้าย' }
                  style={{
                    border: '1px solid #ddd !important'
                  }}
                />
              </div>

            </div>
          </Block>
        }

        { (content.type === 'image' || content.type === 'cover') &&
          <Fragment>
            <Block title="ประเภทรูปภาพ" icon="usb" className="mg-bottom-10">
              <div>
                <div>
                  <div className="font-0-8 mg-top-10">ประเภทรูปภาพ (type)</div>
                  <select
                    onChange={this.handleTypeChange}
                    value={type || ''}
                    className={`browser-default ${css.input}`}>

                    <option value="">เลือกประเภท</option>
                    <option value="profile">ภาพโปรไฟล์ (profile)</option>
                    <option value="profile-item-profile">ภาพโปรไฟล์หรือไอเทมโปรไฟล์ (profile)</option>
                    <option value="cover">ภาพหน้าปก (cover)</option>
                    <option value="album">เลือกจากอัลบัม</option>
                    <option value="custom">กำหนดคีย์</option>
                  </select>
                </div>

                { type === 'custom' &&
                  <div>
                    <div className="font-0-8 mg-top-10">คีย์แบบเต็ม (key full)</div>
                    <input
                      className={css.input}
                      type="text"
                      value={keyFull}
                      onChange={this.handleKeyFullChange}
                      placeholder="คีย์แบบเต็ม"
                      style={{
                        border: '1px solid #ddd !important'
                      }}
                    />
                  </div>
                }
              </div>
            </Block>
          </Fragment>
        }

        { (content.type === 'carousel' || content.type === 'gallery' || type === 'album') &&
          <Fragment>
            <Block title="จัดการรูปภาพ" icon="usb" className="mg-bottom-10">
              <div>
                <div>
                  <div className="font-0-8 mg-top-10">เลือกอัลบัมรูป</div>
                  <select
                    onChange={this.handleAlbumChange}
                    value={album || ''}
                    className={`browser-default ${css.input}`}>

                    <option value="">เลือกอัลบัม</option>
                    <option value="all">ทั้งหมด</option>
                    <option value="product-item">ไอเท็มสินค้า</option>
                    <option value="product-item-auto">ไอเท็มสินค้าอัตโนมัติ</option>
                    <option value="custom">กำหนดเอง</option>
                    <option value="advanced">ขั้นสูง</option>
                  </select>
                </div>

                { (album === 'custom' || album === 'product-item') &&
                  <div>
                    <div className="font-0-8 mg-top-10">ชื่ออัลบัม</div>
                    <input
                      className={css.input}
                      type="text"
                      value={albumCustomName}
                      onChange={this.handleAlbumCustomNameChange}
                      placeholder="ชื่ออัลบัม"
                      style={{
                        border: '1px solid #ddd !important'
                      }}
                    />
                  </div>
                }

                { album === 'advanced' &&
                  <div>
                    <div className="font-0-8 mg-top-10">ขั้นสูง</div>
                    <input
                      className={css.input}
                      type="text"
                      value={albumAdvanced}
                      onChange={this.handleAlbumAdvancedChange}
                      placeholder="images=plubic,items.images=plubic"
                      style={{
                        border: '1px solid #ddd !important'
                      }}
                    />
                  </div>
                }
              </div>
            </Block>
          </Fragment>
        }
      </Fragment>
    )
  }
}


const Block = (props) => {
  const { icon, title, children, className } = props

  return (
    <div className={`mg-top-10 pd-top-5 bg-fff border-top-eee border-bottom-eee ${className}`}>
      <table>
        <tbody>
          <tr>
            <td width="40" className="pd-0 center">
              <i className="material-icons">{icon}</i>
            </td>
            <td className="pd-0">{title}</td>
          </tr>
        </tbody>
      </table>

      <div className="border-top-eee pd-10">
        { children }
      </div>
    </div>
  )
}

export default ApiSettings
