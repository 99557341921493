import { getPriceProduct } from '../Orders/OrdersPriceValues'


// dbs
import provincesTh from '../../../databases/geo/provinces/provincesTH.json'


export const getShippingFee = ({ orderItems, shippingFee, priceProductsAll, geo }) => {
  let shippingPrice = 0, packagingPrice = 0

  if (shippingFee) {
    // shipping fee values
    const { weight, productsTotalPrice, perUnit, perToUnit } = shippingFee
    const elementPrice = getElementPrice({ shippingFee, geo })

    // per unit
    if (perUnit && !perToUnit) {
      const perUnitPrice = getPerUnitPrice({ shippingFee, elementPrice, orderItems, geo })

      shippingPrice = perUnitPrice.shippingPrice
      packagingPrice = perUnitPrice.packagingPrice
    }

    if (perUnit && perToUnit) {
      const perUnitPrice = getPerToUnitPrice({ shippingFee, elementPrice, orderItems, geo })

      shippingPrice = perUnitPrice.shippingPrice
      packagingPrice = perUnitPrice.packagingPrice
    }

    // per weight
    if (weight) {
      const perWeightPrice = getPerWeightPrice({ shippingFee, elementPrice, orderItems, geo })

      shippingPrice = perWeightPrice.shippingPrice
      packagingPrice = perWeightPrice.packagingPrice
    }



    // productsTotalPrice
    /*if (productsTotalPrice) {
      const itemsAll = items.filter(item => {
        item = JSON.parse(item)
        const itemProductsTotalPrice = item.productsTotalPrice && Number(item.productsTotalPrice)

        return (
          priceProductsAll >= itemProductsTotalPrice
        )
      })

      const minPrice = Math.min(...itemsAll.map(item => {
        item = JSON.parse(item)

        return item[elementPrice]
      }))

      let item = itemsAll.filter(item => {
        item = JSON.parse(item)
        const price = item[elementPrice] && Number(item[elementPrice])

        return (
          minPrice === price
        )
      })[0]


      if (elementPrice && item) {
        item = JSON.parse(item)

        shippingPrice = item[elementPrice] ? Number(item[elementPrice] || 0) : 0
        packagingPrice = item.packagingPrice ? Number(item.packagingPrice || 0) : 0
      }
    }*/


    // shippingPrice
    // shippingPrice = shippingFee.shippingPrice + shippingPrice
  }

  return {
    productWeight: 0,
    shippingPrice,
    packagingPrice,
    shippingPriceTotal: shippingPrice+packagingPrice
  }
}

const getPerWeightPrice = ({ shippingFee, elementPrice, orderItems, geo }) => {
  let qty = 0, productWeight = 0, shippingPrice = 0, packagingPrice = 0
  let shippingPriceProduct = 0, packagingPriceProduct = 0
  let productPriceToal = 0

  orderItems.map(item => {
    const { products_items_id, products_id } = item
    const product = products_id
    const folder = product && product.folders_id

    const productShippingFee = getDataShippingFee({ product, shippingFee })
    const folderShippingFee = getDataShippingFee({ folder, shippingFee })

    // price for product or folder
    if (productShippingFee || folderShippingFee) {
      const productWeight = getProductsWeight({ item })
      const shippingFee = productShippingFee ? productShippingFee : folderShippingFee
      const elementPrice = getElementPrice({ shippingFee, geo })
      const productPriceToal = getPriceProduct({ product, products_items_id, qty: item.qty })

      const value = calculatePerWeightPrice({ qty: item.qty, productWeight, shippingFee, elementPrice, productPriceToal })
      shippingPriceProduct += value.shippingPrice
      packagingPriceProduct += value.packagingPrice

    // main
    } else {
      qty += item.qty
      productWeight += getProductsWeight({ item })
      productPriceToal += getPriceProduct({ product, products_items_id, qty: item.qty })
    }
  })


  // price for main
  const value = calculatePerWeightPrice({ qty, productWeight, shippingFee, elementPrice, productPriceToal })
  const shippingPriceMain = value.shippingPrice
  const packagingPriceMain = value.packagingPrice

  shippingPrice = shippingPriceMain + shippingPriceProduct
  packagingPrice = packagingPriceMain + packagingPriceProduct

  return {
    shippingPrice,
    packagingPrice
  }
}

const calculatePerWeightPrice = ({ qty, productWeight, shippingFee, elementPrice, productPriceToal }) => {
  const { MOA } = shippingFee
  const extraCharge = shippingFee.extraCharge || 0
  const items = shippingFee && shippingFee.items ? JSON.parse(shippingFee.items) : []
  let shippingPrice = 0, packagingPrice = 0

  if (qty > 0) {
    let item = items.filter(item => {
      item = JSON.parse(item)
      const itemWeight = item.weight && Number(item.weight)

      return productWeight <= itemWeight
    })[0]

    if (!item) {
      const maxWeight = Math.max.apply(Math, items.map(item => JSON.parse(item).weight))

      item = items.filter(item => {
        item = JSON.parse(item)
        const weight = item.weight && Number(item.weight)

        return weight === maxWeight
      })[0]
    }

    if (elementPrice && item) {
      item = JSON.parse(item)

      shippingPrice += item[elementPrice] ? Number(item[elementPrice] || 0) : 0
      packagingPrice += item.packagingPrice ? Number(item.packagingPrice || 0) : 0
    }

    shippingPrice = shippingPrice + extraCharge

    // Minimum Order Amount
    if (MOA && MOA.status && MOA.value) {
      if (productPriceToal >= MOA.value) {
        shippingPrice = 0
      }
    }
  }

  return {
    shippingPrice,
    packagingPrice
  }
}


const getPerUnitPrice = ({ shippingFee, elementPrice, orderItems, geo }) => {
  let qty = 0, shippingPrice = 0, packagingPrice = 0
  let shippingPriceProduct = 0, packagingPriceProduct = 0
  let productPriceToal = 0

  orderItems.map(item => {
    const { products_items_id, products_id } = item
    const product = products_id
    const folder = product && product.folders_id
    const productShippingFee = getDataShippingFee({ product, shippingFee })
    const folderShippingFee = getDataShippingFee({ folder, shippingFee })

    // price for product or folder
    if (productShippingFee || folderShippingFee) {
      const { qty } = item
      const shippingFee = productShippingFee ? productShippingFee : folderShippingFee
      const elementPrice = getElementPrice({ shippingFee, geo })
      const productPriceToal = getPriceProduct({ product, products_items_id, qty })

      const value = calculatePerUnitPrice({ qty, shippingFee, elementPrice, productPriceToal })
      shippingPriceProduct += value.shippingPrice
      packagingPriceProduct += value.packagingPrice

    // main
    } else {


      qty += item.qty
      productPriceToal += getPriceProduct({ product, products_items_id, qty: item.qty })
    }
  })


  // price for main
  const value = calculatePerUnitPrice({ qty, shippingFee, elementPrice, productPriceToal })
  const shippingPriceMain = value.shippingPrice
  const packagingPriceMain = value.packagingPrice

  shippingPrice = shippingPriceMain + shippingPriceProduct
  packagingPrice = packagingPriceMain + packagingPriceProduct

  return {
    shippingPrice,
    packagingPrice
  }
}

const getPerToUnitPrice = ({ shippingFee, elementPrice, orderItems, geo }) => {
  let qty = 0, shippingPrice = 0, packagingPrice = 0
  let shippingPriceProduct = 0, packagingPriceProduct = 0
  let productPriceToal = 0

  orderItems.map(item => {
    const { products_items_id, products_id } = item
    const product = products_id
    const folder = product && product.folders_id
    const productShippingFee = getDataShippingFee({ product, shippingFee })
    const folderShippingFee = getDataShippingFee({ folder, shippingFee })

    // price for product or folder
    if (productShippingFee || folderShippingFee) {
      const { qty } = item
      const shippingFee = productShippingFee ? productShippingFee : folderShippingFee
      const elementPrice = getElementPrice({ shippingFee, geo })
      const productPriceToal = getPriceProduct({ product, products_items_id, qty })

      const value = calculatePerToUnitPrice({ qty, shippingFee, elementPrice, productPriceToal })
      shippingPriceProduct += value.shippingPrice
      packagingPriceProduct += value.packagingPrice

    // main
    } else {
      qty += item.qty
      productPriceToal += getPriceProduct({ product, products_items_id, qty: item.qty })
    }
  })


  // price for main
  const value = calculatePerToUnitPrice({ qty, shippingFee, elementPrice, productPriceToal })
  const shippingPriceMain = value.shippingPrice
  const packagingPriceMain = value.packagingPrice

  shippingPrice = shippingPriceMain + shippingPriceProduct
  packagingPrice = packagingPriceMain + packagingPriceProduct

  return {
    shippingPrice,
    packagingPrice
  }
}

const calculatePerUnitPrice = ({ qty, shippingFee, elementPrice, productPriceToal }) => {
  const { MOA } = shippingFee
  const extraCharge = shippingFee.extraCharge || 0
  let shippingPrice = 0, packagingPrice = 0

  if (qty > 0) {
    Array.apply(1, Array(qty)).map((v, i) => {
      const num = i + 1
      let item = getItemPerUnit({ num, shippingFee })

      if (elementPrice && item) {
        item = JSON.parse(item)

        shippingPrice += item[elementPrice] ? Number(item[elementPrice] || 0) : 0
        packagingPrice += item.packagingPrice ? Number(item.packagingPrice || 0) : 0
      }
    })

    shippingPrice = shippingPrice + extraCharge

    // Minimum Order Amount
    if (MOA.status && MOA.value) {
      if (productPriceToal >= MOA.value) {
        shippingPrice = 0
      }
    }
  }

  return {
    shippingPrice,
    packagingPrice
  }
}

const calculatePerToUnitPrice = ({ qty, shippingFee, elementPrice, productPriceToal }) => {
  const { MOA } = shippingFee
  const extraCharge = shippingFee.extraCharge || 0
  let shippingPrice = 0, packagingPrice = 0

  if (qty > 0) {
    const items = shippingFee.items && JSON.parse(shippingFee.items) || []
    const itemPerToUnit = items.filter(item => {
      item = JSON.parse(item)
      const perUnit = item.perUnit && Number(item.perUnit)
      const perToUnit = item.perToUnit && Number(item.perToUnit)

      if (perUnit && perToUnit) {
        return qty >= perUnit && qty <= perToUnit
      } else if (perUnit && !perToUnit) {
        return qty >= perUnit
      }
    })[0]

    if (itemPerToUnit) {
      const item = JSON.parse(itemPerToUnit)

      shippingPrice = item[elementPrice] ? Number(item[elementPrice] || 0) : 0
      packagingPrice = item.packagingPrice ? Number(item.packagingPrice || 0) : 0
    }

    shippingPrice = shippingPrice + extraCharge

    // Minimum Order Amount
    if (MOA.status && MOA.value) {
      if (productPriceToal >= MOA.value) {
        shippingPrice = 0
      }
    }
  }

  return {
    shippingPrice,
    packagingPrice
  }
}

const getItemPerUnit = ({ num, shippingFee }) => {
  if (shippingFee && num > 0) {
    const items = shippingFee.items && JSON.parse(shippingFee.items) || []
    const itemPerUnit = items.filter(item => {
      item = JSON.parse(item)
      const perUnit = item.perUnit && Number(item.perUnit)

      return perUnit === num
    })[0]

    if (!itemPerUnit) {
      return getItemPerUnit({ num: num - 1, shippingFee })
    } else {
      return itemPerUnit
    }
  }
}


const getElementPrice = ({ shippingFee, geo }) => {
  let { inProvinces, inZones, nearZones, typePrice } = shippingFee

  // items
  inProvinces = inProvinces ? JSON.parse(inProvinces) : []
  inZones = inZones ? JSON.parse(inZones) : []
  nearZones = nearZones ? JSON.parse(nearZones) : []

  // inProvincePrice, outProvincePrice, inZonePrice, outZonePrice, nearZonePrice, farZonePrice
  let elementPrice

  // price single
  if (typePrice === 'single') {
    elementPrice = 'price'

  // inProvincePrice, outProvincePrice
  } else if (typePrice === 'in-out-province') {
    if (geo && geo.p) {
      const find = inProvinces.filter(province => JSON.parse(province).nameTh === geo.p)[0]

      if (find) {
        elementPrice = 'inProvincePrice'
      } else {
        elementPrice = 'outProvincePrice'
      }
    }

  // inZonePrice, outZonePrice
  } else if (typePrice === 'in-out-zone') {
    if (geo && geo.p) {
      const { provinces } = provincesTh[0]
      const province = provinces.filter(province => province.nameTh === geo.p)[0]

      if (province && province.regionCode) {
        const find = inZones.filter(zone => JSON.parse(zone).id === province.regionCode)[0]

        if (find) {
          elementPrice = 'inZonePrice'
        } else {
          elementPrice = 'outZonePrice'
        }
      }
    }

  // inZonePrice, nearZonePrice, farZonePrice
  } else if (typePrice === 'in-near-far-zone') {
    if (geo && geo.p) {
      const { provinces } = provincesTh[0]
      const province = provinces.filter(province => province.nameTh === geo.p)[0]

      if (province && province.regionCode) {
        const findInZone = inZones.filter(zone => JSON.parse(zone).id === province.regionCode)[0]

        if (findInZone) {
          elementPrice = 'inZonePrice'
        } else {
          const findNearZone = nearZones.filter(zone => JSON.parse(zone).id === province.regionCode)[0]

          if (findNearZone) {
            elementPrice = 'nearZonePrice'
          } else {
            elementPrice = 'farZonePrice'
          }
        }
      }
    }
  }

  return elementPrice
}


// get Data Shipping Fee
const getDataShippingFee = ({ product, folder, shippingFee }) => {
  let data

  if (product) data = product
  else if (folder) data = folder

  return data && data.shippingFees && data.shippingFees.filter(fee => fee.businesses_shipping_fees_id === shippingFee._id && fee.open === true)[0]
}


// prices weight all
export const getProductsWeight = ({ item }) => {
  let weight = 0

  const product = item.products_id
  const qty = item.qty
  const products_items_id = item.products_items_id
  const productItem = getProductItem({ product, products_items_id })

  if (productItem) {
    weight = productItem.weight * qty
    weight = parseFloat(weight.toFixed(2))
  }

  return weight
}

export const getProductsWeightAll = ({ orderItems }) => {
  let weight = 0

  orderItems.map(item => {
    weight += getProductsWeight({ item })
  })

  weight = parseFloat(weight.toFixed(2))

  return weight
}

// get product item
export const getProductItem = ({ product, products_items_id }) => {
  return product && product.items && product.items.filter(item => item._id === products_items_id)[0]
}
