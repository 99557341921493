import { fetchDatas, fetchData, createData, updateData, removeData, fetchApi } from '../reduxActions'
import { generateTypeName, generateTypeFunctions, getTypePath } from '../reduxTypes'

import uploadsForS3 from '../../util/uploadsForS3'
import { checkAppActions } from '../App/AppActions'

// Export Constants
const NAME_TYPE = ['CUSTOMERS', 'CUSTOMER']
const name = "customers"

// Type Names and Type Functions
export const { ADDS, ADDS_MORE, ADD, UPDATE, REMOVE } = generateTypeName(NAME_TYPE)
export const { adds, adds_more, add, update, remove } = generateTypeFunctions(NAME_TYPE)


// Display
export const fetchCustomerForUser = (businesses_id, params = {}) => dispatch => {
  return fetchData({
    dispatch,
    params,
    api: `customer-for-user/${businesses_id}`,
    add: adds
  })
}

export const fetchCustomersDisplay = (businesses_id, params = {}) => dispatch => {
  return fetchDatas({
    dispatch,
    params,
    api: `${name}-display/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchCustomerDisplay = (businesses_id, _id, params = {}) => dispatch => {
  return fetchData({
    dispatch,
    params,
    api: `${name}-display/${businesses_id}/${_id}`,
    add: add
  })
}


// Export Actions
export const countCustomers = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return fetchApi({
    dispatch,
    params,
    api: `${path}${name}-count/${authName}/${dbNo}/${businesses_id}`,
  })
}

export const checkCustomerDuplicate = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return fetchApi({
    dispatch,
    params,
    api: `${path}${name}-query/${businesses_id}/check-value-duplicate?${params.query}`
  })
}

export const fetchCustomers = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return fetchDatas({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchCustomer = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id, customers_id } = query
  const path = getTypePath(businessType)

  return fetchData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${businesses_id}/${customers_id}`,
    add: add
  })
}

export const createCustomer = (query, params) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return createData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${businesses_id}/create`,
    add: add
  })
}

export const updateCustomer = (query, params) => dispatch => {
  const { authName, dbNo, businessType, businesses_id, customers_id, _id } = query
  const path = getTypePath(businessType)

  return updateData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${businesses_id}/${_id || customers_id}`,
    update: update,
    updateId: _id || customers_id
  })
}

export const uploadImagesCustomer = (query, fileParams, params) => dispatch => {
  const { files, imagePrefix } = fileParams

  checkAppActions(dispatch, params, 'start')
  uploadsForS3(files, imagePrefix, params.progressId, dispatch).then(val => {
    val.map(paths => {
      params.body.paths = paths

      dispatch(updateCustomer(query, params))
    })
  })
}

export const removeCustomer = (query, params) => dispatch => {
  const { authName, dbNo, businessType, businesses_id, customers_id, _id } = query
  const path = getTypePath(businessType)

  return removeData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${businesses_id}/${_id || customers_id}`,
    remove: remove,
    deleteId: _id || customers_id
  })
}





