import Dexie from 'dexie'
import moment from 'moment'

import validations from './functions/validations'
import updateValidations from './functions/updateValidations'
import { collections, schemas} from './schemas'


const config = {
  dbName: 'addner-db'
}

const db = new Dexie(config.dbName)
const DBUndefined = {
  success: false,
  code: 'db is undefined'
}

const init = () => {
  db.version(1).stores(collections)
  db.open().then(function (db) {
    window.db = db
  }).catch (function (err) {
    console.log(err)
  })
}

const create = async (coll, { values }) => {
  const db = window.db

  if (db) {
    const schema = schemas.filter(schema => schema.coll === coll)[0]

    if (schema) {
      const validation = validations(values, schema.validations, 'create')

      if (validation.values) {
        const id = await db[coll].add(validation.values)
        const data = await db[coll].get(id)

        return { success: true, data }
      } else {
        return { success: false, code: 'validations is error', error: validation.error }
      }
    } else {
      return { success: false, code: 'schema is undefined' }
    }
  } else {
    return DBUndefined
  }
}

const updateOne = async (coll, { _id, values }) => {
  const db = window.db

  if (db) {
    const schema = schemas.filter(schema => schema.coll === coll)[0]

    if (schema) {
      const validation = updateValidations(values, schema.validations, 'update')

      if (validation.values) {
        const id = await db[coll].where('_id').equals(_id).modify(validation.values)

        if (id) {
          const data = await db[coll].where({ _id }).first()

          if (data) {
            return { success: true, data }
          } else {
            return { success: false, code: 'data not found' }
          }

        } else {
          return { success: false, code: 'id not found' }
        }
      } else {
        return { success: false, code: 'validations is error', error: validation.error }
      }
    } else {
      return { success: false, code: 'schema is undefined' }
    }
  } else {
    return DBUndefined
  }
}


const updateArrayPush = async (coll, { _id, element, values }) => {
  const db = window.db

  if (db) {
    const schema = schemas.filter(schema => schema.coll === coll)[0]

    if (schema) {

      if (values.scenario) delete values.scenario
      if (values.updateElement) delete values.updateElement
      if (values.updateType) delete values.updateType

      values = {
        [element]: [values]
      }

      const validation = updateValidations(values, schema.validations, 'update-push')


      if (validation.values) {
        const push = (data) => {
          return data[element].push(validation.values[element][0])
        }

        let id = await db[coll].where('_id').equals(_id).modify(push)
        id = await db[coll].where('_id').equals(_id).modify({ updated: moment().toISOString()})

        if (id) {
          const data = await db[coll].where({ _id }).first()

          if (data) {
            return { success: true, data }
          } else {
            return { success: false, code: 'data not found' }
          }

        } else {
          return { success: false, code: 'id not found' }
        }
      } else {
        return { success: false, code: 'validations is error', error: validation.error }
      }
    } else {
      return { success: false, code: 'schema is undefined' }
    }
  } else {
    return DBUndefined
  }
}


const updateArraySet = async (coll, { _id, element, updateId, values }) => {
  const db = window.db

  if (db) {
    const schema = schemas.filter(schema => schema.coll === coll)[0]

    if (schema) {
      if (values.scenario) delete values.scenario
      if (values.updateElement) delete values.updateElement
      if (values.updateType) delete values.updateType
      if (values.updateId) delete values.updateId

      values = {
        [element]: [values]
      }

      const validation = updateValidations(values, schema.validations, 'update-set')

      if (validation.values) {
        const data = await db[coll].where({ _id }).first()

        if (data) {
          const items = data[element]
          const index = items.findIndex(v => v._id === updateId)

          if (index > -1) {
            let values = validation.values[element]
            values = values && values[0]
            let obj = {}

            for (let key in values) {
              obj[`${element}.${index}.${key}`] = values[key]
            }

            const status = await db[coll].where('_id').equals(_id).modify(obj)

            if (status) {
              const status = await db[coll].where('_id').equals(_id).modify({ updated: moment().toISOString()})

              if (status) {
                const data = await db[coll].where({ _id }).first()

                if (data) {
                  return { success: true, data }
                } else {
                  return { success: false, code: 'data not found' }
                }

              } else {
                return { success: false, code: 'update set updated error' }
              }

            } else {
              return { success: false, code: 'update set error' }
            }

          } else {
            return { success: false, code: 'index not found' }
          }
        } else {
          return { success: false, code: 'data not found' }
        }
      } else {
        return { success: false, code: 'validations is error', error: validation.error }
      }
    } else {
      return { success: false, code: 'schema is undefined' }
    }
  } else {
    return DBUndefined
  }
}


const updateArrayPull = async (coll, { _id, element, deleteId }) => {
  const db = window.db

  if (db) {
    const schema = schemas.filter(schema => schema.coll === coll)[0]

    if (schema) {
      const pull = (data) => {
        const items = data[element]
        const index = items.findIndex(v => v._id === deleteId)

        if (index > -1) {
          items.splice(index, 1)
        } else {
          console.log('pull item index is undefined')
        }

        return items
      }

      let id = await db[coll].where('_id').equals(_id).modify(pull)
      id = await db[coll].where('_id').equals(_id).modify({ updated: moment().toISOString()})

      if (id) {
        const data = await db[coll].where({ _id }).first()

        if (data) {
          return { success: true, data }
        } else {
          return { success: false, code: 'data not found' }
        }

      } else {
        return { success: false, code: 'id not found' }
      }

    } else {
      return { success: false, code: 'schema is undefined' }
    }
  } else {
    return DBUndefined
  }
}


const deleteOne = async (coll, { _id }) => {
  const db = window.db

  if (db) {
    const id = await getID(coll, _id)

    if (id) {
      const data = await db[coll].delete(id)

      return { success: true, data }
    } else {
      return { success: false, code: 'id not found' }
    }
  } else {
    return DBUndefined
  }
}



const fetchOne = async (coll, { where }) => {
  const db = window.db

  if (db) {
    const data = await db[coll].where(where).first()

    if (data) {
      return { success: true, data }
    } else {
      return { success: false, code: 'data not found' }
    }
  } else {
    return DBUndefined
  }
}

const fetchArrayOne = async (coll, { where, element, arrayWhere }) => {
  const db = window.db

  if (db) {
    const data = await db[coll].where(where).first()

    if (data) {
      let items = data[element]

      for (let key in arrayWhere) {
        items = items.filter(item => eval(`item.${key}`) === arrayWhere[key])
      }

      if (items && items[0]) {
        return { success: true, data: { data, item: items[0] } }
      } else {
        return { success: false, code: 'item not found' }
      }

    } else {
      return { success: false, code: 'data not found' }
    }
  } else {
    return DBUndefined
  }
}

const fetchMany = async (coll, { where }) => {
  const db = window.db

  if (db) {
    const data = await db[coll].where(where).toArray()
    return { success: true, data }
  } else {
    return DBUndefined
  }
}

const getID = async (coll, _id) => {
  const db = window.db

  if (db) {
    const data = await db[coll].where({ _id }).first()
    return data && data.id
  } else {
    return DBUndefined
  }
}


const updateCondition = async (coll, { req }) => {
  const { params, body } = req
  const { updateType, updateElement, deleteId, updateId } = body
  const { _id } = params
  const element = updateElement

  if (updateType === 'push') {
    return await updateArrayPush(coll, { _id, element, values: body })
  } else if (updateType === 'pull') {
    return await updateArrayPull(coll, { _id, element, deleteId })
  } else if (updateType === 'set') {
    return await updateArraySet(coll, { _id, element, updateId, values: body })
  } else {
    return await updateOne(coll, { _id, values: body })
  }
}


export default {
  init,
  create,
  updateOne,
  updateArrayPush,
  updateArrayPull,
  updateArraySet,
  deleteOne,
  fetchOne,
  fetchArrayOne,
  fetchMany,
  updateCondition,
}

/*

const add = async () => {
  const value = {
    name: 'wichan areemarn',
    age: 32.66,
    street: '137/3',
    tall: 170,
    animal: 'dog',
    setting: {
      a: 500,
      b: 100,
      c: 999
    },
    items: [
      { x: 10, y: 30, z: 40 },
      { x: 20, y: 50, z: 98 },
    ]
  }

  const data = await IndexedDB.create('friends', { value })
  console.log(data)
}

const update = async () => {
  const _id = '6233078a4db9e14a8e41dfc9'
  const values = {
    name: 'wichan areemarn 666',
    age: 19
  }

  const data = await IndexedDB.updateOne('friends', { _id, values })
  console.log(data)
}

const remove = async () => {
  const _id = '623164bb4120c589ce56ab49'
  const data = await IndexedDB.deleteOne('friends', { _id })
  console.log(data)
}

const fetchOne = async () => {
  const where = {
    _id: '623164bb4120c589ce56ab49'
  }

  const data = await IndexedDB.fetchOne('friends', { where })
  console.log(data)
}

const fetchMany = async () => {
  const where = {
    age: 25
  }

  const data = await IndexedDB.fetchMany('friends', { where })
  console.log(data)
}*/
