import React, { Fragment } from 'react'
import css from './css/avatar.css'

const Avatar = (props) => {
  const { align } = props
  const styleJsx = props.styleJsx || {}

  let texts = props.texts || []
  let text = ""

  if (texts && texts.length !== 0 && typeof texts !== 'string') {
    texts.map((t, i) => {
      if (t && i <= 1) {
        let newText = t.replace(/[^a-zA-Z0-9ก-ฮ]/g, '')
        text = text+newText.charAt(0)
      }
    })
  } else if (texts) {
    text = texts
  } else {
    text = "NO"
  }

  return (
    <Fragment>
      <div
        className={`avatar-jsx ${css.typeAvatarNew} ${props.className} ${props.circle && 'box-circle'}`}
        style={{
          background: props.color,
          margin: align === 'center' && '0 auto',
          float: align === 'right' && align
        }}>

        <div className={css.boxAvatar}>
          <span className={props.fontClassName || ''} style={{ fontSize: props.textSize }}>{ text }</span>
        </div>
      </div>

      <div style={{ clear: 'both' }} />

      <style jsx>{`
        .avatar-jsx {
          width: ${styleJsx.width} !important;
          height: ${styleJsx.height} !important;
          border-style: ${styleJsx.borderStyle} !important;
          border-width: ${styleJsx.borderWidth} !important;
          border-color: ${styleJsx.borderColor} !important;
          border-radius: ${styleJsx.borderRadius} !important;
        }
        .avatar-jsx span {
          font-size: ${styleJsx.fontSize} !important;
        }

        @media (min-width: 1500px) {
          .avatar-jsx {
            width: ${styleJsx.widthVw || styleJsx.width} !important;
            height: ${styleJsx.heightVw || styleJsx.height} !important;
            border-radius: ${styleJsx.borderRadiusVw} !important;
          }
          .avatar-jsx span {
            font-size: ${styleJsx.fontSizeVw} !important;
          }
        }
      `}</style>
    </Fragment>
  )
}

export default Avatar
