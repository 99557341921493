import React from 'react'

// Components Global
import Icon from '../../../Icon'
import MenuIcon from '../../../TextEditor/TextEditorNavbar/components/MenuIcon'

// css
import css from './css/itemList.css'


const ItemList = (props) => {
  const { model, item, expand, lang, iconHidden, childNo } = props

  return (
    <div className={css.item} style={{ marginLeft: model !== 'data-tree' && `${childNo*30}px`}}>

      { !iconHidden &&
        <div className={css.icon}>
          <MenuIcon item={item} />
        </div>
      }

      <div className={css.name} style={{ left: iconHidden && '0' }}>
        <div className="text-ellipsis">{ item && item.name[lang] }</div>
      </div>

      { item.lists && item.lists.length !== 0 &&
        <div className={css.arrow}>
          <Icon type="material-icons" className="right i-middle">
            { expand ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }
          </Icon>
        </div>
      }
    </div>
  )
}

export default ItemList
