// @multi lang

import React, { Component } from 'react'
import { connect } from 'react-redux'


// Components Local
import EditorContainer from '../EditorContainer'
import BoxTools from '../BoxTools'
import BtnTools from '../BtnTools'
import BoxSelect from '../BoxSelect'

import ButtonManage from './components/ButtonManage'
import StyleSettings from './components/StyleSettings'
import ButtonDisplay from './components/ButtonDisplay'

// Functions
import { getApi } from '../../functions/textEditorEvent'


class EditorButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.getValue()
    }
  }

  componentDidMount() {
    this.mounted = true
  }

  componentDidUpdate(prevProps) {
    if (prevProps.button !== this.props.button) {
      this.setState({
        ...this.getValue()
      })
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  handleOpenTools = (value) => {
    this.mounted && this.setState({ openTools: value })
  }

  getValue = () => {
    const { content } = this.props
    const button = this.props.button && JSON.parse(this.props.button)

    return {
      button: this.props.button,
      name_local: button && button.name_local || '',
      name_en: button && button.name_en || '',
      url: button && button.url || '',
      icon: button && button.icon ? JSON.parse(button.icon) : "",
      link: button && button.link ? JSON.parse(button.link) : "",
      style: button && button.style ? JSON.parse(button.style) : {},
      class: button && button.class ? JSON.parse(button.class) : [],
      api: getApi(content, button)
    }
  }

  handleSubmit = ({ timeout }) => {
    this.props.onChange({
      index: this.props.index,
      value: {
        class: JSON.stringify(this.state.class),
        style: JSON.stringify(this.state.style),
        api: JSON.stringify(this.state.api),
        icon: JSON.stringify(this.state.icon),
        link: JSON.stringify(this.state.link),
        name_local: this.state.name_local,
        name_en: this.state.name_en,
        url: this.state.url,
      },
      timeout
    })
  }

  handleNameChange = (name) => {
    const { lang } = this.props

    this.mounted && this.setState({ [`name_${lang}`]: name }, () => {
      this.handleSubmit({ timeout: true })
    })
  }

  handleIconChange = (icon) => {
    this.mounted && this.setState({ icon }, () => {
      this.handleSubmit({ timeout: true })
    })
  }

  handleLinkChange = (link) => {
    this.mounted && this.setState({ link }, () => {
      this.handleSubmit({ timeout: true })
    })
  }

  focus = () => {
    this.handleOpenTools(true)
  }

  handleApiChange = (api) => {
    this.setState({ api }, () => {
      this.handleSubmit({ timeout: true })
    })
  }

  openSidebar = (activeId) => {
    this.setState({ activeId: activeId })

    if (this.sidebar) {
      return this.sidebar.open()
    }
  }

  render() {
    const style = typeof this.state.style === 'string' ? {} : this.state.style
    const { mode, lang, loggedIn, content } = this.props
    const { openTools, link, icon, api, activeId } = this.state
    const name = this.state[`name_${lang}`]
    const title = `ปุ่มกด (${lang})`

    return (
      <EditorContainer
        openTools={openTools}
        className="box-btn-jsx"
        onClick={this.handleOpenTools}>

        <BoxTools
          openTools={openTools}
          style={{ minWidth: '350px'}}>

          <BtnTools
            notDelete
            title={title}
            _this={this}
            tools={[
              {
                lists: [
                  { icon: 'edit', label: 'แก้ไข', onClick: () => this.openSidebar('content') }
                ],
                show: true
              },
              {
                lists: [
                  { icon: 'color_lens', label: 'สไตล์', onClick: () => this.openSidebar('style') }
                ],
                show: true
              },
              {
                lists: [
                  { label: 'ตั้งค่า API', onClick: () => this.openSidebar('api') }
                ],
                show: content && content.api || false
              },
              {
                groups: 'delete',
                lists: [
                  { icon: 'delete', width: 60, tooltip: 'ลบปุ่มกด', onClick: this.props.remove },
                ],
                show: this.props.remove
              }
            ]}
          />
        </BoxTools>

        <BoxSelect
          _this={this}
          title={title}
          contentSettings={ButtonManage}
          styleSettings={StyleSettings}
          apiSettings={content && content.api}
          apiChange={this.handleApiChange}
          activeId={activeId}
          onRef={sidebar => this.sidebar = sidebar}
          openTools={this.state.openTools}
          style={{ padding: '0' }}>

          <ButtonDisplay
            link={link}
            lang={lang}
            style={style}
            icon={icon}
            name={name}
            mode={mode}
            loggedIn={loggedIn}
            className={this.state.class}
          />
        </BoxSelect>

        <style jsx>{`
          .box-btn-jsx {
            background: red;
          }
        `}</style>
      </EditorContainer>
    )
  }
}

export default connect()(EditorButton)
