import React, { Component } from 'react'
import Required from '../Required'
import InputDisabled from '../InputDisabled'
import cuid from 'cuid'

import { setStyleValue, getStyle } from '../../TextEditor/components/ElementStyles/components/js/setDivStyle'


class TextInput extends Component {
  constructor(props) {
    super(props)

    const id = this.props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, '')
    const randomId = cuid()

    this.state = {
      id: randomId+id,
      value: this.props.value ? this.props.value : ''
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value || '' })
    }
  }

  handleChange = (e) => {
    /*this.setState({ value: e.target.value || '' }, () => {
      if (this.props.onChange) {
        this.props.onChange(e)
      }
    })*/

    if (!this.props.notChange) {
      if (this.props.onChange || this.props.onChange === null) {
        this.props.onChange(e)
      } else {
        this.setState({ value: e.target.value || '' })
      }
    }
  }

  render() {
    const { styleName, styleJsx, styleGlobal, inputForSite } = this.props
    const name = styleName
    const setEvent = (event, important=false) => {
      return (
        `
          ${getStyle('box-shadow', styleJsx[`${name}-${event}-box-shadow`], important)}
          ${getStyle('border-style', styleJsx[`${name}-${event}-border-style`], important)}
          ${getStyle('border-width', styleJsx[`${name}-${event}-border-width`], important)}
          ${getStyle('border-color', styleJsx[`${name}-${event}-border-color`], important)}
        `
      )
    }

    return (
      <div className={`${styleGlobal ? 'box-input-global-jsx' : ''} ${this.props.className || ''}`}>
        { !this.props.disabled ?
          <Required
            id={this.state.id}
            value={this.state.value}
            label={this.props.label}
            unitName={this.props.unitName}
            labelHidden={this.props.labelHidden}
            type={this.props.type}
            required={this.props.required}
            iconValid={this.props.iconValid}
            style={this.props.style}
            styleName={styleName}
            styleJsx={styleJsx}
            labelClassName={this.props.labelClassName}
            notColorValid={this.props.notColorValid}
            className={this.props.requiredClassName || this.props.className || ''}>

            <input
              type={this.props.inputType}
              id={this.state.id}
              data-id={this.props.id}
              data-not-save={this.props.notSave}
              data-label={this.props.useLabel && this.props.label}
              data-type={this.props.dataType || this.props.inputType}
              data-use-attr={this.props.useAttr}
              data-use-attr-id={this.props.useAttrId}
              ref={this.state.id}
              value={this.state.value}
              className={`${inputForSite ? 'input-site' : 'input'} ${styleGlobal ? ' input-global-jsx' : ''} input-jsx ${this.props.required ? 'required' : ''} ${this.props.inputClassName || ''}`}
              placeholder={this.props.placeholder}
              onChange={this.handleChange}
              onBlur={this.props.onBlur}
              onFocus={this.props.onFocus}
              style={this.props.inputStyle}
            />

            <style jsx>{`
              .input-jsx {
                ${styleJsx && setStyleValue({ name, style: styleJsx })}

                outline: none;
                -webkit-box-sizing: border-box;
                  -moz-box-sizing: border-box;
                        box-sizing: border-box;
              }
              .input-jsx:focus:not([readonly]) {
                ${styleJsx && setEvent('focus')}
              }
              .input-jsx.valid,
              .input-jsx:focus.valid {
                ${styleJsx && setEvent('valid', true)}
              }
              .input-jsx.invalid,
              .input-jsx:focus.invalid {
                ${styleJsx && setEvent('invalid', true)}
              }

              @media (min-width: 1500px) {
                .input-jsx {
                  ${styleJsx && setStyleValue({ name, style: styleJsx, vw: true })}
                }
                .input-jsx:focus:not([readonly]) {
                  ${styleJsx && getStyle('box-shadow', styleJsx[`${name}-focus-box-shadow-vw`])}
                  ${styleJsx && getStyle('border-width', styleJsx[`${name}-focus-border-width-vw`])}
                }
                .input-jsx.valid {
                  ${styleJsx && getStyle('box-shadow', styleJsx[`${name}-valid-box-shadow-vw`], true)}
                  ${styleJsx && getStyle('border-width', styleJsx[`${name}-valid-border-width-vw`], true)}
                }
                .input-jsx.invalid {
                  ${styleJsx && getStyle('box-shadow', styleJsx[`${name}-invalid-box-shadow-vw`], true)}
                  ${styleJsx && getStyle('border-width', styleJsx[`${name}-invalid-border-width-vw`], true)}
                }
              }

              @media (min-width: 601px) and (max-width: 1024px) {
                .input-jsx {
                  ${styleJsx && setStyleValue({ device: 'tablet', name, style: styleJsx })}
                }
              }

              @media (min-width: 50px) and (max-width: 600px) {
                .input-jsx {
                  ${styleJsx && setStyleValue({ device: 'mobile', name, style: styleJsx })}
                }
              }
            `}</style>

          </Required>
        :
          <InputDisabled {...this.props} />
        }
      </div>
    )
  }
}

export default TextInput
