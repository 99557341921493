// Redux Actions
import { fetchProductsAllDisplay } from '../../../../../redux/Products/ProductsActions'
import { fetchArticlesDisplay } from '../../../../../redux/Articles/ArticlesActions'
import { fetchFoldersDisplay } from '../../../../../redux/Folders/FoldersActions'
import { fetchAttributesDisplay } from '../../../../../redux/Attributes/AttributesActions'

// Utils
import { getURLSearchParams } from '../../../../../util/getURLSearchParams'

// js
import { convertLinkNew } from '../../../components/LinkDisplay/js/linkDisplay'

export const fetchSettings = (style) => {
  return settings(style)
}

export const settings = (style) => {
  const fetchApiType = style['fetchApiType'] !== undefined ? style['fetchApiType'] : ''
  const fetchApiGroupType = style['fetchApiGroupType'] !== undefined ? style['fetchApiGroupType'] : ''
  const fetchApiWhere = style['fetchApiWhere'] !== undefined ? style['fetchApiWhere'] : ''
  const fetchApiOrderByName = style['fetchApiOrderByName'] !== undefined ? style['fetchApiOrderByName'] : '_id'

  let fetchApiOrderBySort = style['fetchApiOrderBySort'] !== undefined ? style['fetchApiOrderBySort'] : 'DESC'

  if (fetchApiOrderBySort === 'DESC') {
    fetchApiOrderBySort = '-'
  } else {
    fetchApiOrderBySort = ''
  }

  const fetchApiLimitType = style['fetchApiLimitType'] !== undefined ? style['fetchApiLimitType'] : 'limit'
  const fetchApiLimit = style['fetchApiLimit']
  const fetchApiOrderBy = `${fetchApiOrderBySort}${fetchApiOrderByName}`
  const fetchTotalAll = style['fetchTotalAll']

  let fetchApiParamsUrl = style['fetchApiParamsUrl']

  if (fetchApiParamsUrl) {
    if (fetchApiParamsUrl === 'true') {
      fetchApiParamsUrl = true
    }

    if (fetchApiParamsUrl === 'false') {
      fetchApiParamsUrl = false
    }
  } else {
    fetchApiParamsUrl = false
  }

  let fetchApiCollName

  if (fetchApiType === 'product-product' || fetchApiType === 'product-service') {
    fetchApiCollName = 'products'

  } else if (fetchApiType === 'folder-product' || fetchApiType === 'folder-subfolder-product') {
    fetchApiCollName = 'folders'

  } else if (fetchApiType === 'article') {
    fetchApiCollName = 'articles'

  } else if (fetchApiType === 'folder-article' || fetchApiType === 'folder-subfolder-article') {
    fetchApiCollName = 'folders'

  } else if (fetchApiType === 'attribute') {
    fetchApiCollName = 'attributes'
  }


  return {
    fetchApiType,
    fetchApiCollName,
    fetchApiGroupType,
    fetchApiWhere,
    fetchApiOrderByName,
    fetchApiOrderBySort,
    fetchApiOrderBy,
    fetchApiLimitType,
    fetchApiLimit,
    fetchApiParamsUrl,
    fetchTotalAll
  }
}

export const getWhereSearchParam = (location) => {
  let whereSearch

  if (location) {
    const { search } = location
    const searchParams = new URLSearchParams(search)

    for (let p of searchParams) {
      if (p && p[0] && p[1]) {
        let attr = p[0]
        let value = searchParams.get(attr)

        if (attr.search('where_') !== -1 && value !== 'all') {
          attr = attr.replace('where_', '')

          if (whereSearch) {
            whereSearch = `${whereSearch},${attr}=${value}`
          } else {
            whereSearch = `${attr}=${value}`
          }
        }
      }
    }
  }

  return whereSearch
}

export const convertWhere = (where, { params, auth }) => {
  if (where) {
    const split = where.split(',')

    split.map(val => {
      if (val) {
        const valSplit = val.split('=:')

        if (valSplit && valSplit[1]) {
          const element = valSplit[1]

          let value

          if (valSplit[1].indexOf('auth_user.') !== -1) {
            const element_auth = valSplit[1].replace('auth_user.', '')

            if (auth && auth.user) {
              value = auth.user[element_auth]
            }

          } else {
            value = params[element]
          }

          if (value) {
            where = where.replace(`:${element}`, value)
          }
        }
      }
    })
  }

  return where
}


export const getParamsFetchApi = (style, props) => {
  let {
    fetchApiType,
    fetchApiGroupType,
    fetchApiWhere,
    fetchApiOrderBy,
    fetchApiLimitType,
    fetchApiLimit,
    fetchApiParamsUrl,
    fetchTotalAll
  } = settings(style)

  if (fetchApiWhere) {
    const { apiData, templateData, match, location, mode } = props


    if (!mode) {
      if (apiData || templateData || match) {
        fetchApiWhere = convertLinkNew({ link: fetchApiWhere, apiData, templateData, match, location })
      }
    } else {
      fetchApiWhere = ''
    }
  }

  let folders_id, folders_path

  if (props.match) {
    folders_id = props.match.params.folders_id
    folders_path = props.match.params.folders_path


    const { path_1, path_2 } = props.match.params

    if ((path_1 === 'product-categories' && path_2) || (path_1 === 'article-categories' && path_2)) {
      folders_path = path_2
    }
  }

  let fetchDatas, dbName, where, storeName
  let andWhere = fetchApiWhere ? `,${fetchApiWhere}` : ''

  if (props.match) {
    andWhere = convertWhere(andWhere, {
      params: props.match.params,
      auth: props.auth
    })
  }

  let whereSearch = getWhereSearchParam(props.location)

  if (fetchApiType === 'product-product' || fetchApiType === 'product-service') {
    let subtype

    if (fetchApiType === 'product-product') {
      subtype = 'product'
    } else if (fetchApiType === 'product-service') {
      subtype = 'service'
    }

    fetchDatas = fetchProductsAllDisplay
    where = `status=open,type=product,subtype=${subtype}${andWhere}`
    storeName = 'products'
    dbName = 'Products'

    if (whereSearch) {
      where = `${where},${whereSearch}`
    }

  } else if (fetchApiType === 'article') {
    fetchDatas = fetchArticlesDisplay
    where = `status=open${andWhere}`
    storeName = 'articles'

  } else if (fetchApiType === 'attribute') {
    fetchDatas = fetchAttributesDisplay
    where = `status=open${andWhere}`
    storeName = 'attributes'

  } else if (fetchApiType === 'folder-product' || fetchApiType === 'folder-article') {
    let type

    if (fetchApiType === 'folder-product') {
      type = 'product'
    } else if (fetchApiType === 'folder-article') {
      type = 'article'
    }

    fetchDatas = fetchFoldersDisplay
    where = `status=open,type=${type}`
    storeName = 'folders'

    if (fetchApiParamsUrl) {
      where = `${where},folders_id=${folders_id || null}`
    }

    where = `${where}${andWhere}`

  } else if (fetchApiType === 'folder-subfolder-product' || fetchApiType === 'folder-subfolder-article') {
    let type

    if (fetchApiType === 'folder-subfolder-product') {
      type = 'product'
    } else if (fetchApiType === 'folder-subfolder-article') {
      type = 'article'
    }

    fetchDatas = fetchFoldersDisplay
    where = `status=open,type=${type}`
    storeName = 'folders'

    if (fetchApiParamsUrl) {
      where = `${where}`
    }

    where = `${where}${andWhere}`
  }

  const { site, folders } = props

  if (folders_path && site && folders) {
    const folder = folders.filter(folder => folder.path === folders_path)[0]

    where = `${where},businesses_id=${site.businesses_id}`

    if (fetchApiParamsUrl) {
      if (fetchTotalAll) {
        where = `${where},folders.folders_id=${folder._id}`
      } else {
        where = `${where},folders_id=${folder._id}`
      }
    }
  }

  const { templateData } = props
  let ungroupDataId

  if (fetchApiGroupType && templateData) {
    if (fetchApiGroupType === 'similar') {
      const { _id, folders } = templateData

      let whereSimilar = `_id=!${_id}`

      if (folders && folders[0]) {
         const folder = folders[0]

         whereSimilar = `${whereSimilar},folders.folders_id=${folder.folders_id}`
      }

      ungroupDataId = _id
      where = `${where},${whereSimilar}`
    }
  }


  if (fetchApiType && fetchDatas) {
    let limit

    if (fetchApiLimitType && fetchApiLimitType === 'limit') {
      limit = fetchApiLimit || 8
    }

    const body = {
      where,
      limit,
      sort: fetchApiOrderBy
    }

    if (!limit) {
      delete body.limit
    }
    return {
      fetchDatas,
      dbName,
      body,
      storeName,
      ungroupDataId
    }

  } else {
    return {}
  }
}

export const getGroupId = ({ props, style }) => {
  let { content } = props
  const searchParam = getURLSearchParams(window.location.search)

  let groupId = content._id

  if (style && style['tabOpen'] && style['tabAttribute']) {
    const tabAttribute = style['tabAttribute']
    const tab_id = searchParam[`${tabAttribute}`]

    if (tab_id) {
      groupId = `${groupId}-${tab_id}`
    }
  }

  return groupId
}

export const getDataStore = (store, props, groupId) => {
  const { style, content } = props
  const { storeName } = getParamsFetchApi(style, props)

  if (!groupId) {
    groupId = content._id
  }

  //let groupId = getGroupId({ props })

  if (storeName) {
    const datas = store[storeName].data || []

    return datas.filter(data => data.groupId === groupId) || []
  }
}

export const getDataStoreSearch = (store, props, groupId) => {
  const { style } = props
  const { storeName } = getParamsFetchApi(style, props)

  if (!groupId) {
    groupId = content._id
  }

  //let groupId = getGroupId({ props, style })

  if (storeName) {
    const datas = store[storeName]
    let items = datas.data || []
    let searchData = datas.search || {
      status: false,
      count: 0,
      data: []
    }
    let itemsSearch = searchData.data || []

    items = items.filter(data => data.groupId === groupId) || []
    //itemsSearch = itemsSearch.filter(data => data.groupId === content._id) || []

    return {
      items,
      searchData
    }
  }
}


export const fetchDatasApi = (props) => {
  const { style, action, content, dispatch } = props

  if (style['fetchApiType']) {
    const { data, business } = action.params
    const { fetchDatas, dbName, body, ungroupDataId } = getParamsFetchApi(style, props)

    if (fetchDatas) {
      if (dbName) {
        let dbNo = business[`db${dbName}No`]

        if (!dbNo) {
          dbNo = 'not-use-db'
        }

        if (dbNo) {
          dispatch(fetchDatas(dbNo, business._id, {
            body,
            ungroupDataId,
            addGroupId: content._id,
            loadId: `item-lists-api-${content._id}`
          }))
        }

      } else {
        dispatch(fetchDatas(data.businesses_id, {
          body,
          ungroupDataId,
          addGroupId: content._id,
          loadId: `item-lists-api-${content._id}`
        }))
      }
    }
  }
}
