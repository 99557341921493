import { createNote, updateNote, removeNote } from '../../../../../redux/Notes/NotesActions'
import { createProduct, updateProduct, removeProduct } from '../../../../../redux/Products/ProductsActions'
import { createCustomer, updateCustomer, removeCustomer } from '../../../../../redux/Customers/CustomersActions'
import { createArticle, updateArticle, removeArticle } from '../../../../../redux/Articles/ArticlesActions'
import { updateAuthUserCustomer } from '../../../../../redux/Auth/AuthActions'


export const actionDbOptions = (title) => {
  return [
    { label: title, value: '' },
    { label: 'สินค้า (user)', value: 'product-for-user' },
    { label: 'บทความ (user)', value: 'article-for-user' },
    { label: 'ลูกค้า (user)', value: 'customer-for-user' },

    { label: 'ผู้ใช้งาน user-customer (auth)', value: 'auth-user-customer' },

    { label: 'บันทึก (user)', value: 'note-for-user' },
    { label: 'บันทึก (ไม่ต้องเข้าสู่ระบบ)', value: 'note-for-unauth' }
  ]
}

export const getAction = (name) => {
  let authName, create, update, remove, useQuery

  if (name === 'product-for-user') {
    authName = "user"
    create = createProduct
    update = updateProduct
    remove = removeProduct
    useQuery = true

  } else if (name === 'article-for-user') {
    authName = "user"
    create = createArticle
    update = updateArticle
    remove = removeArticle
    useQuery = false

  } else if (name === 'customer-for-user') {
    authName = "user"
    create = createCustomer
    update = updateCustomer
    remove = removeCustomer
    useQuery = true

  } else if (name === 'auth-user-customer') {
    authName = "user"
    update = updateAuthUserCustomer

  } else if (name === 'note-for-user' || name === 'note-for-unauth') {
    if (name === 'note-for-user') authName = "user"
    else if (name === 'note-for-unauth') authName = "unauth"

    create = createNote
    update = updateNote
    remove = removeNote
    useQuery = false
  }

  return { authName, create, update, remove, useQuery }
}

export const convertUrlSearchToObj = (search) => {
  let obj = {}

  if (search) {
    const params = new URLSearchParams(search)

    for (let p of params) {
      const attribute = p[0]
      const value = p[1]

      obj[attribute] = value
    }
  }

  return obj
}

export const getData = ({ api, condition, apiData, templateData, dataStore, props }) => {
  let data

  let type, dataStoreName

  if (condition) {
    type = condition.dataType
    dataStoreName = condition.dataStoreName

  } else if (api) {
    type = api.typeApi
    dataStoreName = api.dataStoreName
  }

  if (type) {
    if (type === 'api') {
      data = apiData

    } else if (type === 'api-template') {
      data = templateData

    } else if (type === 'data-store' && dataStore && dataStoreName) {
      if (dataStoreName === 'user.customer') {
        data = dataStore.user && dataStore.user.customer
      } else {
        data = dataStore[dataStoreName]
      }

    } else if (type === 'url-search' && props.location) {
      data = convertUrlSearchToObj(props.location.search)

    } else if (type === 'location' && props.location) {
      data = props.location

    } else if (type === 'match-params' && props.match) {
      data = props.match.params

    } else if (type === 'lang' && props.match && dataStore && dataStore.business) {
      let { langLocal } = dataStore.business
      let { lang } = props.match.params

      if (!lang) {
        lang = langLocal.toLowerCase()
      }

      data = { lang }
    }
  }

  /*if (condition) {
    if (condition.dataType === 'api') {
      data = apiData

    } else if (condition.dataType === 'api-template') {
      data = templateData

    } else if (condition.dataType === 'data-store' && dataStore && condition.dataStoreName) {
      data = dataStore[api.dataStoreName]

    } else if (condition.dataType === 'url-search' && props.location) {
      data = convertUrlSearchToObj(props.location.search)
    }

  } else {
    if (api.typeApi === 'api') {
      data = apiData

    } else if (api.typeApi === 'api-template') {
      data = templateData

    } else if (api.typeApi === 'data-store' && dataStore && api.dataStoreName) {
      data = dataStore[api.dataStoreName]

    } else if (condition.typeApi === 'url-search' && props.location) {
      data = convertUrlSearchToObj(props.location.search)
    }
  }*/

  return data
}

export const getAlbumImages = ({ content, api, apiData, templateData, dataStore }) => {
  let images

  if (content && content.api) {
    const data = getData({ api, apiData, templateData, dataStore })

    if (data) {
      if (api.album === 'all') {
        images = data.images

      } else if (api.album === 'product-item') {
        images = data.item && data.item.itemImages || []

        if (api.albumCustomName) {
          const albumCustomName = api.albumCustomName === 'null' ? null : api.albumCustomName
          images = images.filter(img => img.album === albumCustomName)
        }

      } else if (api.album === 'custom' && api.albumCustomName) {
        images = data.images && data.images.filter(img => img.album === api.albumCustomName)

      } else if (api.album === 'product-item-auto') {
        const product = data
        const productItem = product.item
        images = []

        let single, singleItem

        if (product && productItem) {
          if (product.items.length <= 1) {
            single = true
            singleItem = product.items.filter(item => item.itemChoice === 'single')[0]
          }

          const imgsProfile = productItem.itemImages.filter(img => img.album === 'profile' && img.profile === true)
          const imgsPublic = productItem.itemImages.filter(img => img.album === 'public')

          images.push(...imgsProfile, ...imgsPublic)
        }

      } else if (api.album === 'advanced' && api.albumAdvanced) {
        let splitAlbums = api.albumAdvanced.split(',')
        images = []

        splitAlbums.map(album => {
          let split = album.split('=')

          if (split[0] && split[1]) {
            let key = split[0]
            let value = split[1]
            let dots = key.split('.')

            if (dots.length === 1) {
              const imgs = data[key].filter(img => img.album === value)
              images.push(...imgs)

            } else if (dots.length === 2) {
              const dot = dots[0]
              const dot2 = dots[1]
              const imgs = data[dot][dot2].filter(img => img.album === value)
              images.push(...imgs)
            }
          }
        })
      }
    }
  }

  return images
}

export  const getSingleImage = ({ content, api, apiData, templateData, dataStore }) => {
  let image

  if (content && content.api) {
    const data = getData({ api, apiData, templateData, dataStore })

    if (data) {

    }
  }

  return image
}
