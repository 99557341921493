import React, { Fragment } from 'react'

// Components Global
import { LayoutsDesignDisplay } from '../../../../LayoutsDesign'

import Template from '../../../TextEditorTemplate/components/Template'


const ItemContentDisplay = (props) => {
  const { style } = props
  const templates_id = style['templates_id']

  return (
    <Fragment>
      { templates_id ?
        <Template {...props} templates_id={templates_id} />
      :
        <LayoutsDesignDisplay
          {...props}
          display
          bodyColorNone
        />
      }
    </Fragment>
  )
}

export default ItemContentDisplay

