import React from 'react'

// Components Global
import { LayoutsDesignDisplay } from '../../../../LayoutsDesign'

// Functions
import { getSections } from '../../../../../redux/Sites/SitesFunctions'


const TabsFooterContent = (props) => {
  const lang = props.lang || 'local'
  const { content } = props
  const groupId = `tabsFooterContent-${content._id}`
  const sections = getSections(props, { byGroupId: groupId })

  return (
    <div id={groupId}>
      <LayoutsDesignDisplay
        {...props}
        display
        bodyColorNone
        groupId={groupId}
        sections={sections}
        lang={lang}
      />
    </div>
  )
}

export default TabsFooterContent
