import { ADD_PACKAGES } from './PackagesActions'

// Initial State
const initialState = { data: [] }

const PackagesReducer = (state=initialState, action) => {
  switch (action.type) {

    case ADD_PACKAGES:
      return {
        data: action.packages
      }

    default:
      return state
  }
}

export default PackagesReducer