import React from 'react'

// Components Text Editor
import BoxSettings from '../../BoxSettings'
import {
  FontSetting,
  LineHeightSetting,
  TextShadowSetting,
  TextAlignSetting,
  TextIndentSetting,
  MarginSetting,
  PaddingSetting,
  CursorSetting
} from '../../StyleSettings'


const TextStyle = (props) => {
  const { _this, name, title } = props
  const hidden = props.hidden || {}

  let settings = [
    {
      hidden: hidden.fontStyle ? true : false,
      component: <FontSetting _this={_this} styleName={`${name}`} />
    },
    {
      hidden: hidden.textAlign ? true : false,
      component: <TextAlignSetting _this={_this} styleName={`${name}-text-align`} />
    },
    {
      hidden: hidden.lineHeight ? true : false,
      component: <LineHeightSetting _this={_this} styleName={`${name}-line-height`} />
    },
    {
      hidden: hidden.textShadow ? true : false,
      component: <TextShadowSetting _this={_this} styleName={`${name}-text-shadow`} name={name} />
    },
    {
      hidden: hidden.textIndent ? true : false,
      component: <TextIndentSetting _this={_this} styleName={`${name}-text-indent`} name={name} />
    },
    {
      hidden: hidden.margin ? true : false,
      component: <MarginSetting _this={_this} styleName={`${name}-margin`} defaultValue="0px 0px 0px 0px" />
    },
    {
      hidden: hidden.padding ? true : false,
      component: <PaddingSetting _this={_this} styleName={`${name}-padding`} defaultValue="10px 20px 10px 20px" />
    },
    {
      hidden: hidden.cursor ? true : false,
      component: <CursorSetting _this={_this} styleName={`${name}-cursor`} defaultValue="10px 20px 10px 20px" />
    },
  ]

  return (
    <BoxSettings title={title} icon="format_size">
      { settings.map((setting, i) => {
        return (
          <div key={i} className={`pd-10 ${i%2 === 1 ? 'bg-fbfbfb' : '' }`}>
            { setting.component }
          </div>
        )
      }) }
    </BoxSettings>
  )
}

export default TextStyle
