import React from 'react'

// Components Global
import BtnJsxSubmit from '../../../../button/BtnJsxSubmit'



const BtnSubmitElement = (props) => {
  const { id, iconOnly } = props

  let iconClassName

  if (iconOnly) {
    iconClassName = ""
  } else {
    iconClassName = "left"
  }

  return (
    <BtnJsxSubmit
      {...props}
      loadingId={id}
      iconClassName={iconClassName}
    />
  )
}

export default BtnSubmitElement
