import cuid from 'cuid'
import { discountFn } from '../../../../common/functions/schemas'

export const payment = () => ({
  type: {
    type: String,
    default: null,
    enum: [null, '', 'cash', 'check', 'bankTransfer', 'creditCard', 'cashOnDelivery', 'inStorePayment']
  },
  status: {
    type: String,
    default: 'unpaid',
    enum: ['unpaid', 'waiting-confirmed-paid', 'incorrect', 'waiting-paid', 'waiting-cash-on-delivery', 'paid', 'success']
  },
  slip: { type: String },
  paidAmount: { type: Number },
  pricePaymentFee: { type: Number },
  paidDate: { type: Date, default: null },
  bankAccount: { type: Object, default: {} },
  creditCard: {
    bankCode: { type: String, default: null }
  },
  check: {
    bankCode: { type: String, default: null },
    checkNo: { type: String, default: null },
    checkDate: { type: Date, default: null }
  }
})

export const items = () => [{
  cuid: { type: String, default: () => cuid() },
  qty: { type: Number, required: true },
  price: { type: Number, required: true },
  discount: discountFn(),
  type: {
    type: String,
    required: true,
    enum: ['product', 'service', 'shipping-fee', 'payment-fee']
  },
  product: { type: Object, required: true },
  productItem: { type: Object, required: true }
}]
