import moment from 'moment'
import { discountFn, setLangValueFn, srcFn, vatFn, whtFn } from '../../../../common/functions/schemas'
import getIndexable from '../../functions/getIndexable'
import { items, payment } from './OrdersDBSchemaShare'


const validations = {
  businesses_id : {
    type: String,
    required: true
  },

  // admin
  admins_id: {
    type: String,
    default: null
  },
  admin: { type: Object, default: null },

  // customer
  customers_id: {
    type: String,
    default: null
  },
  customer: { type: Object, default: null },

  createSystemBy: { type: String, default: 'sell-online', enum: ['pos', 'sell-online'] },
  channelSales: {
    type: String,
    default: 'pos',
    enum: ['pos', 'web', 'sale-page', 'sell-online']
  },
  eCommerce: { type: Boolean, default: false },
  status: {
    type: String,
    default: 'draft',
    enum: ['draft', 'open', 'paid', 'confirmed', 'completed', 'canceled', 'refunded']
  },
  paymentStatus: {
    type: String,
    default: 'unpaid',
    enum: ['unpaid', 'partial-paid', 'waiting-paid', 'waiting-confirmed-paid', 'waiting-cash-on-delivery', 'paid', 'refund']
  },

  shipping: { type: Boolean, default: false },
  shippingStatus: { type: String, default: null, enum: [null, 'preparing', 'sending', 'successed'] },
  shippingTrackingNumber: { type: String, default: null },
  shippingReceivedDate: { type: Date },

  // online
  shippingAddress: { type: Object, default: null },
  shippingMethod: { type: Object, default: null },
  productAccepted: { type: Boolean, default: false },
  productAcceptedDate: { type: Date },
  note: setLangValueFn(),
  noteCustomer: setLangValueFn(),
  contacted: {
    note: { type: String },
  },
  canceled: {
    note: { type: String },
  },
  refunded: {
    note: { type: String },
    price: { type: Number },
  },
  seq: { type: Number },
  code: { type: String },
  items: items(),
  installments: [
    {
      no: { type: Number, default: 1 },
      multiple: { type: Boolean, default: false },
      created: { type: Date, default: () => moment().toISOString() },
      startDate: { type: Date, default: () => moment().toISOString() },
      dueDate: { type: Date, default: () => moment().toISOString() },
      price: { type: Number, default: null },
      tip: { type: Number, default: null },
      note: { type: String },
      payment: payment()
    }
  ],
  discount: discountFn(),
  src: srcFn(),
  vat: vatFn(),
  wht: whtFn(),

  totalAmountProduct: { type: Number, default: 0 },
  totalNetAmountProduct: { type: Number, default: 0 },
  totalNetAmount: { type: Number, default: 0 },
  paymentNetAmount: { type: Number, default: 0 },
  cartPaymemt: { type: Object },

  title: { type: String },
  descriptions: { type: String },
}

export default {
  indexable: getIndexable(validations),
  validations
}
