import React from 'react'

// Components Text Editor
import BlockStyleSettings from '../../../BlockStyleSettings'
import ImageElementManage from '../../../ElementStylesManage/components/ImageElementManage'


const StyleSettings = (props) => {
  const { _this, name } = props

  return (
    <div>
      <BlockStyleSettings _this={_this} device={props.device} />

      <ImageElementManage
        {...props}
        styleHover
        styleActiveClick
        name={name}
        title="รูปภาพ"
        icon="photo"
        hidden={{
          opacity: true
        }}
      />
    </div>
  )
}

export default StyleSettings
