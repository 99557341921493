import fetch from 'isomorphic-fetch'
import cookie from 'react-cookie'
import config from '../config'

const API_URL = config.app.apiUrl

export default function callApi(endpoint, method = 'get', body) {
  let URL

  if ((method === 'get' || method === 'delete') && body) {
    let params = Object.entries(body).map(([key, val]) => `${key}=${val}`).join('&')

    URL = `${API_URL}/${endpoint}?${params}`
    body = undefined

  } else {
    URL = `${API_URL}/${endpoint}`
  }

  return fetch(URL, {
    headers: {
      'content-type': 'application/json',
      'authorization': cookie.load('token')
    },
    method,
    body: JSON.stringify(body),
  })
  .then(response => response.json().then(json => ({ json, response })))
  .then(({ json, response }) => {
    if (!response.ok) {
      return Promise.reject(json)
    }

    return json
  })
  .then(
    response => response,
    error => error
  )
}
