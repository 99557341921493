import { fetchDatas, fetchData, createData, updateData, removeData } from '../reduxActions'
import { generateTypeName, generateTypeFunctions } from '../reduxTypes'

import uploadsForS3 from '../../util/uploadsForS3'
import { checkAppActions } from '../App/AppActions'

// Export Constants
const NAME_TYPE = ['PLACES', 'PLACE']
const name = "places"

// Type Names and Type Functions
export const { ADDS, ADDS_MORE, ADD, UPDATE, REMOVE } = generateTypeName(NAME_TYPE)
export const { adds, adds_more, add, update, remove } = generateTypeFunctions(NAME_TYPE)


// Export Actions
export const fetchPlaces = (businesses_id, params = {}) => dispatch => {
  return fetchDatas({
    dispatch,
    params,
    api: `${name}/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchPlace = (businesses_id, _id, params = {}) => dispatch => {
  return fetchData({
    dispatch,
    params,
    api: `${name}/${businesses_id}/${_id}`,
    add: add
  })
}

export const createPlace = (businesses_id, params) => dispatch => {
  return createData({
    dispatch,
    params,
    api: `${name}/${businesses_id}/create`,
    add: add
  })
}

export const updatePlace = (businesses_id, _id, params) => dispatch => {
  return updateData({
    dispatch,
    params,
    api: `${name}/${businesses_id}/${_id}`,
    update: update,
    updateId: _id
  })
}

export const uploadImagesPlace = (businesses_id, _id, files, prefix, params) => dispatch => {
  checkAppActions(dispatch, params, 'start')

  return new Promise(resolve => {
    uploadsForS3(files, prefix, params.progressId, dispatch).then(val => {
      val.map(paths => {
        params.body.paths = paths

        return dispatch(updatePlace(businesses_id, _id, params)).then(res => {
          if (res.success) {
            res.paths = paths
          }

          resolve(res)
        })
      })
    })
  })
}

export const removePlace = (businesses_id, _id, params) => dispatch => {
  return removeData({
    dispatch,
    params,
    api: `${name}/${businesses_id}/${_id}`,
    remove: remove,
    deleteId: _id
  })
}
