import callApi from '../../util/apiCaller'
import { checkAppActions } from '../App/AppActions'

// Export Constants
export const ADD_PACKAGES = 'ADD_PACKAGES'

// Export Type
export const add_packages = packages => ({ type: ADD_PACKAGES, packages })


// Export Actions
export const fetchPackages = (params={}) => dispatch => {
  checkAppActions(dispatch, params, 'start')

  return callApi(`packages`, 'get', params.body).then(res => {
    if (res.success) {
      dispatch(add_packages(res.data))
      checkAppActions(dispatch, params, 'success')
    } else {
      dispatch(errorAlert(res.message))
      checkAppActions(dispatch, params, 'error')
    }
  })
}

