import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import css from './css/dropdown.css'

class Dropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }

  componentDidMount() {
    if (this.props.hover) {
      document.addEventListener('mousemove', this.handleDocumentClick)
    } else {
      document.addEventListener('click', this.handleDocumentClick)
    }
  }

  /*componentWillReceiveProps(nextProps) {
    if (nextProps.items !== this.props.items) {

    }
  }*/

  componentWillUnmount() {
    if (this.props.hover) {
      document.removeEventListener('mousemove', this.handleDocumentClick)
    } else {
      document.removeEventListener('click', this.handleDocumentClick)
    }
  }

  handleDocumentClick = (e) => {
    if (this.state.open) {
      if (!ReactDOM.findDOMNode(this).contains(e.target)) {
        this.closeDropdown()
      }
    }
  }

  openDropdown = () => {
    if (!this.state.open) {
      this.setState({ open: true })
    }
  }

  closeDropdown = () => {
    if (this.state.open) {
      this.setState({ open: false })
    }
  }

  handleClick = () => {
    if (!this.props.hover) {
      this.setState({ open: !this.state.open })
    }
  }

  onMouseEnter = () => {
    if (this.props.hover) {
      this.openDropdown()
    }
  }

  onSelectItem = (e, item) => {
    if (item.onClick) {
      item.onClick()
    }

    if (item.onMouseDown) {
      item.onMouseDown(e)
    }

    if (this.props.getActiveLabel) {
      this.props.getActiveLabel(item.label)
    }

    this.setState({ open: false, label: item.label })
  }

  getActiveLabel = () => {
    let label

    if (this.state.label) {
      label = this.state.label
    }

    return label
  }

  checkActive = (item) => {
    if (this.props.active) {
      if (item.active) {
        return css.active
      } else {
        if (!item.activeHidden) {
          if (item.label === this.state.label) {
            return css.active
          } else {
            if (this.props.autoActive) {
              const items = this.props.items && this.props.items[0]
              const label = items && items.label

              if (!this.state.label && item.label === label) {

                if (this.props.getActiveLabel) {
                  this.props.getActiveLabel(label)
                }

                return css.active
              } else {
                return ''
              }

            } else {
              return ''
            }
          }
        } else {
          return ''
        }
      }
    } else if (this.props.activeValue) {
      if (!item.activeHidden) {
        if (this.props.activeValue === item.value) {
          return css.active
        }
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  render() {
    const styleDropdwon = {
      left: this.props.left && '0',
      visibility: this.state.open ? 'visible' : 'hidden',
      opacity: this.state.open ? '1' : '0',
      ...this.props.styleDropdwon || {},
    }

    return (
      <div className={`${css.boxDropdown}`} style={this.props.containerStyle}>
        <div
          className={css.boxClick}
          onClick={this.handleClick}
          onMouseEnter={this.onMouseEnter}>

          { this.props.children }
        </div>


        <ul className={`${css.dropdown} mg-bottom-30 ${this.props.ulClassName || ''}`} style={styleDropdwon}>
          { this.props.items.map((item, i) => {
            return item.show && (
              <li
                key={i}
                style={{
                  background: this.checkActive(item) && this.props.activeColor && this.props.activeColor,
                  ...this.props.liStyle || {}
                }}
                className={`${this.checkActive(item)} ${item.divider && css.divider} ${item.title && css.title} ${item.listClassName || ''} ${this.props.listClassName || ''}`}>

                { !item.divider && !item.title &&


                  <a
                    style={this.props.aStyle}
                    className={`${item.linkClassName || ''} ${this.props.linkClassName || ''}`}
                    onClick={(e) => item.onClick && this.onSelectItem(e, item)}
                    onMouseDown={(e) => item.onMouseDown && this.onSelectItem(e, item)}>

                    { item.icon &&
                      <i className={`material-icons left ${item.iconClassName || ''} ${this.props.iconClassName || ''}`}>{item.icon}</i>
                    }

                    { item.label }
                  </a>
                }

                { item.title &&
                  <span>
                    { item.label }
                  </span>
                }
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

export default Dropdown
