export const settings = (style) => {
  const xxsResponsive = style['Responsive'] !== undefined ? style['Responsive'] : 1
  const xsResponsive = style['xsResponsive'] !== undefined ? style['xsResponsive'] : 1
  const smResponsive = style['smResponsive'] !== undefined ? style['smResponsive'] : 2
  const mdResponsive = style['mdResponsive'] !== undefined ? style['mdResponsive'] : 3
  const lgResponsive = style['lgResponsive'] !== undefined ? style['lgResponsive'] : 4
  const xlgResponsive = style['xlgResponsive'] !== undefined ? style['xlgResponsive'] : 4
  const templates_id = style['templates_id']

  const amountColumns = {
    xxs: xxsResponsive,
    xs: xsResponsive,
    sm: smResponsive,
    md: mdResponsive,
    lg: lgResponsive,
    xlg: xlgResponsive
  }

  const pagination = style['pagination'] !== undefined ? style['pagination'] : false
  const search = style['search'] !== undefined ? style['search'] : false
  const searchAttrs = style['search-attributes'] !== undefined ? style['search-attributes'] : 'name.local'
  const sAttrSplit = searchAttrs.split(',')

  let searchAttributes

  if (search) {
    searchAttributes = []

    sAttrSplit.map(value => {
      const name = value.replace(' ', '')

      searchAttributes.push({ name, options: 'i' })
    })
  }

  return {
    search,
    searchAttributes,
    pagination,
    templates_id,
    amountColumns,
    xxsResponsive,
    xsResponsive,
    smResponsive,
    mdResponsive,
    lgResponsive,
    xlgResponsive
  }
}
