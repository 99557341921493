import { fetchDatas, fetchData, fetchDataUpdate, createData, updateData, removeData, fetchApi } from '../reduxActions'
import { generateTypeName, generateTypeFunctions } from '../reduxTypes'

import uploadsForS3 from '../../util/uploadsForS3'
import { checkAppActions } from '../App/AppActions'

// Export Constants
const NAME_TYPE = ['NOTES', 'NOTE']
const name = "notes"


// Type Names and Type Functions
export const { ADDS, ADDS_MORE, ADD, UPDATE, REMOVE } = generateTypeName(NAME_TYPE)
export const { adds, adds_more, add, update, remove } = generateTypeFunctions(NAME_TYPE)


// Display
export const fetchNotesDisplay = (businesses_id, params = {}) => dispatch => {
  return fetchDatas({
    dispatch,
    params,
    api: `${name}-display/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchNoteDisplay = (businesses_id, _id, params = {}) => dispatch => {
  return fetchData({
    dispatch,
    params,
    api: `note-display/${businesses_id}/${_id}`,
    add: add
  })
}

// Export Actions
export const checkValueDuplicate = (params = {}) => dispatch => {
  return fetchApi({
    dispatch,
    params,
    api: `${name}-query/check-value-duplicate?${params.query}`
  })
}


export const fetchNotes = (auth_name, businesses_id, params = {}) => dispatch => {
  return fetchDatas({
    dispatch,
    params,
    api: `${name}/${auth_name}/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchNote = (auth_name, businesses_id, _id, params = {}) => dispatch => {
  return fetchData({
    dispatch,
    params,
    api: `${name}/${auth_name}/${businesses_id}/${_id}`,
    add: add
  })
}

export const createNote = (auth_name, businesses_id, params) => dispatch => {
  return createData({
    dispatch,
    params,
    api: `${name}/${auth_name}/${businesses_id}/create`,
    add: add
  })
}

export const updateNote = (auth_name, businesses_id, _id, params) => dispatch => {
  return updateData({
    dispatch,
    params,
    api: `${name}/${auth_name}/${businesses_id}/${_id}`,
    update: update,
    updateId: _id
  })
}

export const uploadImagesNote = (auth_name, businesses_id, _id, files, prefix, params) => dispatch => {
  checkAppActions(dispatch, params, 'start')

  return new Promise(resolve => {
    uploadsForS3(files, prefix, params.progressId, dispatch).then(val => {
      val.map(paths => {
        params.body.paths = paths

        return dispatch(updateNote(auth_name, businesses_id, _id, params)).then(res => {
          if (res.success) {
            res.paths = paths
          }

          resolve(res)
        })
      })
    })
  })
}

export const removeNote = (auth_name, businesses_id, _id, params) => dispatch => {
  return removeData({
    dispatch,
    params,
    api: `${name}/${auth_name}/${businesses_id}/${_id}`,
    remove: remove,
    deleteId: _id
  })
}
