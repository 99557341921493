import React, { Suspense } from 'react'

const suspenseComponent = (Component) => {
  if (typeof window !== 'undefined') {
    return props => (
      <Suspense fallback={<div></div>}>
        <Component {...props} />
      </Suspense>
    )
  } else {
    return props => (
      <div></div>
    )
  }
}

export default suspenseComponent
