
export const getNumeral = (number, decimal=2) => {
  if (number) {
    if (decimal === 'auto') {
      if (number%1 === 0) {
        return number.toLocaleString('en-US', { minimumFractionDigits: 0 })
      } else {
        return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      }
    } else {
      return number.toLocaleString('en-US', { minimumFractionDigits: decimal, maximumFractionDigits: decimal })
    }
  } else {
    if (decimal === 2) return '0.00'
    else return '0'
  }
}

export const getFloat = ({ value, decimal, display, decimalFull }) => {
  if (!isNaN(value) && value !== '') {
    value = value.toString()

    const split = value.split('.')
    let valueInt = split[0]
    let valueDecimal = split[1]

    const getInt = (value) => {
      value = parseFloat(value)
      return value.toLocaleString('en-US')
    }

    const getDecimal = (value, decimal, display, decimalFull) => {
      decimal = parseInt(decimal)
      value = value.substring(0, decimal)

      if (display && decimalFull) {
        const zero = decimal - value.length

        if (zero) {
          Array.apply(1, Array(zero)).map((v, i) => {
            value = `${value}0`
          })
        }
      }

      return value
    }

    if (valueInt && valueDecimal === undefined) {
      value = parseInt(value)
      value = getInt(value)

      if (display && decimalFull) {
        value = `${getInt(valueInt)}.${getDecimal('0', decimal, display, decimalFull)}`
      }

    } else if (valueInt && valueDecimal === '') {
      valueInt = parseInt(valueInt)

      if (display) {
        if (decimalFull) {
          value = `${getInt(valueInt)}.${getDecimal('0', decimal, display, decimalFull)}`
        } else {
          value = `${getInt(valueInt)}`
        }
      } else {
        value = `${getInt(valueInt)}.`
      }
    } else if (valueInt && valueDecimal) {
      valueInt = parseInt(valueInt)
      value = `${getInt(valueInt)}.${getDecimal(valueDecimal, decimal, display, decimalFull)}`
    }
  }

  return value
}


export const numberRound = ({ number, digit, round='auto' }) => {
  number = number || 0

  const x = Math.pow(10, digit)
  let value

  if (round === 'down') {
    value = Math.floor(number * x) / x
  } else if (round === 'up') {
    value = Math.ceil(number * x) / x
  } else if (round === 'auto') {
    value = Math.round(number * x) / x
  }

  return value
}


export const getNumeralFn = ({ value, convert, maxDigit, decimal, decimalFull, notMinus, valueForSplace, maxValue, minValue }) => {

  // fn
  const checkMinMax = ({ value, maxValue, minValue }) => {
    if (maxValue) {
      maxValue = parseFloat(maxValue)

      if (maxValue) {
        value = value <= maxValue ? value : maxValue
      }
    }

    /*if (minValue !== undefined) {
      const minValue = parseFloat(minValue)

      if (minValue) {
        value = value >= minValue ? value : minValue
      }
    }*/

    return value
  }

  const checkMaxDigit = ({ value, maxDigit }) => {
    if (maxDigit && value) {
      value = value.toString()

      const split = value.split('.')
      let valueInt = split[0]
      let valueDecimal = split[1]

      if (valueInt) {
        valueInt = valueInt.substr(0, maxDigit)
      }

      if (valueDecimal !== undefined) {
        value = `${valueInt}.${valueDecimal}`
      } else {
        value = valueInt
      }
    }

    return value
  }

  // fn
  const checkDecimal = ({ value, decimal }) => {
    if (decimal) {
      value = value.toString()

      const split = value.split('.')
      let valueInt = split[0]
      let valueDecimal = split[1]

      if (valueInt && !valueDecimal) {
        value = parseFloat(valueInt)

      } else if (valueInt && valueDecimal) {
        value = parseFloat(value)
      }
    }

    return value
  }

  // fn
  const setValueFn = ({ value, convert, decimal }) => {
    if (value === '') {
      return {
        value: '',
        valueFormat: ''
      }

    } else if (value === '-') {
      return {
        value: '',
        valueFormat: '-'
      }

    } else if (!isNaN(value)) {
      value = checkMinMax({ value, maxValue, minValue })
      value = checkMaxDigit({ value, maxDigit })

      return {
        value: checkDecimal({ value, decimal }),
        valueFormat: getValueFormat({ value, convert, decimal, decimalFull })
      }
    } else {
      return {
        value: '',
        valueFormat: ''
      }
    }
  }

  // fn
  const getValueFormat = ({ value, convert, decimal, decimalFull }) => {
    if (decimal) {
      return getFloat({
        value,
        display: convert,
        decimal,
        decimalFull
      })

    } else {
      value = parseFloat(value)
      return value.toLocaleString('en-US')
    }
  }

  // main
  if (value) {
    value = value.toString()
    value = value.replaceAll(',', '')
  }

  if (notMinus && value) {
    value = value.replaceAll('-', '')
  }


  let valueLength = 0

  if (!isNaN(value) && value !== null) {
    valueLength = value.length
  }

  if (value !== '' && !isNaN(value) && valueLength !== 0) {
    const pattern = /^(-)?([0-9]*)([.])?([0-9]*)$/g

    if (pattern.test(value)) {
      return setValueFn({ value, convert, decimal })
    }
  } else if (value === '-') {
    return setValueFn({ value: '-', convert, decimal })

  } else if (value === '') {
    if (valueForSplace) {
      return setValueFn({ value: valueForSplace, convert, decimal })
    } else {
      return setValueFn({ value: '', convert, decimal })
    }
  }
}
