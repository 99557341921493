import React from 'react'

// Components Text Editor
import BoxSettings from '../../BoxSettings'
import {
  DisplaySetting,
  OpacitySetting,
  WidthSetting,
  HeightSetting,
  LineHeightSetting,
  FontSetting,
  FontSizeSetting,
  MarginSetting,
  PaddingSetting,
  BackgroundColorSetting,
  BackgroundGradientSetting,
  BorderSetting,
  BoxShadowSetting,
  BorderRadiusSetting,
  ColorSetting,
  LineClampSetting,
  FloatSetting,
  TextAlignSetting,
  JustifyContentSetting,
  HeightFullSetting,
  PositionTypeSetting,
  PositionValueSetting,
  ZIndexSetting,
  ValueSetting,
  CursorSetting,
  TextShadowSetting,
  OverflowSetting
} from '../../StyleSettings'



const DivStyle = (props) => {
  const { _this, title, icon, name } = props
  const hidden = props.hidden || {}

  let settings = [
    {
      hidden: hidden.margin ? true : false,
      component: <MarginSetting _this={_this} styleName={`${name}-margin`} defaultValue="0px 0px 0px 0px" />
    },
    {
      hidden: hidden.padding ? true : false,
      component: <PaddingSetting _this={_this} styleName={`${name}-padding`} defaultValue="10px 20px 10px 20px" />
    },
    {
      hidden: hidden.display ? true : false,
      component: <DisplaySetting _this={_this} styleName={`${name}-display`} />
    },
    {
      hidden: false,
      component: <BackgroundColorSetting _this={_this} label="สีพื้นหลัง" styleName={`${name}-background-color`} textColorName="color" />
    },
    {
      hidden: hidden.backgroundGradient ? true : false,
      component: <BackgroundGradientSetting _this={_this} styleName={`${name}-background-gradient`} textColorName="color" />
    },

    {
      hidden: hidden.heightFull ? true : false,
      component: <HeightFullSetting _this={_this} styleName={`${name}-height-full`} />
    },
    {
      hidden: hidden.justifyContent ? true : false,
      component: <JustifyContentSetting _this={_this} styleName={`${name}-justify-content`} />
    },


    {
      hidden: hidden.opacity ? true : false,
      component: <OpacitySetting _this={_this} styleName={`${name}-opacity`} />
    },
    {
      hidden: hidden.width ? true : false,
      component: <WidthSetting _this={_this} styleName={`${name}-width`} />
    },
    {
      hidden: hidden.maxWidth ? true : false,
      component: <WidthSetting _this={_this} label="ความกว้างสูงสุด (max-width)" styleName={`${name}-max-width`} />
    },
    {
      hidden: hidden.minWidth ? true : false,
      component: <WidthSetting _this={_this} label="ความกว้างต่ำสุด (min-width)" styleName={`${name}-min-width`} />
    },
    {
      hidden: hidden.height ? true : false,
      component: <HeightSetting _this={_this} styleName={`${name}-height`} />
    },
    {
      hidden: hidden.lineHeight ? true : false,
      component: <LineHeightSetting _this={_this} styleName={`${name}-line-height`} />
    },
    {
      hidden: hidden.font ? true : false,
      component: <FontSetting _this={_this} styleName={`${name}`} />
    },
    {
      hidden: hidden.textShadow ? true : false,
      component: <TextShadowSetting _this={_this} styleName={`${name}-text-shadow`} name={name} />
    },


    {
      hidden: hidden.iconSize ? true : false,
      component: <FontSizeSetting label="ขนาดไอคอน" _this={_this} styleName={`${name}-icon-size`} />
    },
    {
      hidden: hidden.iconColor ? true : false,
      component: <ColorSetting label="สีไอคอน" _this={_this} styleName={`${name}-icon-color`} />
    },
    {
      hidden: hidden.iconMargin ? true : false,
      component: <MarginSetting label="ระยะห่างไอคอน" _this={_this} styleName={`${name}-icon-margin`} defaultValue="0px 0px 0px 0px" />
    },
    {
      hidden: hidden.float ? true : false,
      component: <FloatSetting label="ตำแหน่งไอคอน (float)" _this={_this} styleName={`${name}-icon-float`} />
    },
    {
      hidden: hidden.display ? true : false,
      component: <DisplaySetting label="การแสดงผลไอคอน (display)" _this={_this} styleName={`${name}-icon-display`} />
    },


    {
      hidden: hidden.border ? true : false,
      component: <BorderSetting _this={_this} styleName={`${name}-border`} />
    },
    {
      hidden: hidden.borderRadius ? true : false,
      component: <BorderRadiusSetting _this={_this} styleName={`${name}-border-radius`} />
    },
    {
      hidden: hidden.boxShadow ? true : false,
      component: <BoxShadowSetting _this={_this} styleName={`${name}-box-shadow`} />
    },
    {
      hidden: hidden.lineClamp ? true : false,
      component: <LineClampSetting _this={_this} styleName={`${name}-line-clamp`} />
    },
    {
      hidden: hidden.float ? true : false,
      component: <FloatSetting _this={_this} styleName={`${name}-float`} />
    },
    {
      hidden: hidden.textAlign ? true : false,
      component: <TextAlignSetting _this={_this} styleName={`${name}-text-align`} />
    },

    {
      hidden: hidden.cursor ? true : false,
      component: <CursorSetting _this={_this} styleName={`${name}-cursor`} />
    },

    {
      hidden: hidden.overflow ? true : false,
      component: <OverflowSetting _this={_this} styleName={`${name}-overflow`} />
    },

    {
      hidden: hidden.positionType ? true : false,
      component: <PositionTypeSetting _this={_this} styleName={`${name}-position`} />
    },
    {
      hidden: hidden.positionValue ? true : false,
      component: <PositionValueSetting _this={_this} styleName={`${name}-position`} />
    },
    {
      hidden: hidden.positionValue ? true : false,
      component: <ValueSetting label="ตำแหน่งบน" _this={_this} styleName={`${name}-position-top`} />
    },
    {
      hidden: hidden.positionValue ? true : false,
      component: <ValueSetting label="ตำแหน่งล่าง" _this={_this} styleName={`${name}-position-bottom`} />
    },
    {
      hidden: hidden.positionValue ? true : false,
      component: <ValueSetting label="ตำแหน่งซ้าย" _this={_this} styleName={`${name}-position-left`} />
    },
    {
      hidden: hidden.positionValue ? true : false,
      component: <ValueSetting label="ตำแหน่งขวา" _this={_this} styleName={`${name}-position-right`} />
    },
    {
      hidden: hidden.zIndex ? true : false,
      component: <ZIndexSetting _this={_this} styleName={`${name}-z-index`} />
    }
  ]

  settings = settings.filter(setting => setting.hidden !== true)

  return (
    <BoxSettings title={title} icon={icon}>

      { settings.map((setting, i) => {
        return (
          <div key={i} className={`pd-10 ${i%2 === 1 ? 'bg-fbfbfb' : '' }`}>
            { setting.component }
          </div>
        )
      }) }

    </BoxSettings>
  )
}

export default DivStyle
