import Router from '../../functions/routerIndexedDB'
import * as Ctrl from './BillsDBController'


const routers = async (endpoint, method, body) => {
  const routes = [
    { path: '/bills/:auth_name(admin|user|unauth)/:dbNo/:businesses_id', method: 'get', action: Ctrl.fetchAllColl },
    { path: '/bills/:auth_name(admin|user|unauth)/:dbNo/:businesses_id/create', method: 'put', action: Ctrl.createColl },
    { path: '/bills/:auth_name(admin|user|unauth)/:dbNo/:businesses_id/:_id', method: 'get', action: Ctrl.createColl },
    { path: '/bills/:auth_name(admin|user|unauth)/:dbNo/:businesses_id/:_id', method: 'put', action: Ctrl.updateColl },
    { path: '/bills/:auth_name(admin|user|unauth)/:dbNo/:businesses_id/:_id', method: 'delete', action: Ctrl.deleteColl }
  ]

  return await Router({ routes, endpoint, method, body })
}

export default routers
