import React from 'react'
import { Link } from 'react-router-dom'

// Components Global
import { BtnModalOpen } from '../Modals/BtnModal'

export const Btn = (props) => {
  let theme = props.theme
  let btnColor
  let btn

  if (props.btnType === "success") {
    btnColor = "btn"
  } else {
    if (theme === 'dark') {
      btnColor = "grey darken-3 btn"
    } else {
      btnColor = "btn"
    }
  }

  if (props.to) {
    btn = (
      <Link to={props.to} className={`waves-effect waves-light btn-shadow-none ${btnColor} ${props.className}`} style={props.style}>
        { props.children }
      </Link>
    )
  } else if (props.modalId) {
    btn = (
      <BtnModalOpen id={props.modalId}>
        <button className={`waves-effect waves-light btn-shadow-none ${btnColor} ${props.className}`} style={props.style}>
          { props.children }
        </button>
      </BtnModalOpen>
    )

  } else {
    btn = (
      <a className={`waves-effect waves-light ${btnColor} ${props.size}`} style={props.style}>
        { props.children }
      </a>
    )
  }

  return btn
}


export const Text = (props) => {
  let theme = props.theme
  let text
  let className

  if (theme === 'dark') {
    className = "tm-dark-text-color"
  } else {
    className = "tm-text-color"
  }

  if (props.html) {
    text = (
      <span
        dangerouslySetInnerHTML={{__html: props.html }}
        className={`${props.className} ${className}`}
        style={props.style} />
    )
  } else {
    text = (
      <span className={`${props.className} ${className}`} style={props.style}>
        { props.children }
      </span>
    )
  }

  return text
}


export const MaterialIcon = (props) => {
  let theme = props.theme
  let className

  if (theme === 'dark') {
    className = "tm-dark-text-color"
  } else {
    className = "tm-text-color"
  }

  let icon = (
    <i className={`material-icons ${props.className} ${className}`} style={props.style}>{props.icon}</i>
  )

  return icon
}
