import React, { Component } from 'react'

// Functions
import { addClass, removeClass, addStyle, removeStyle } from '../../../functions/textEditorFunctions'

// values
import { fontSizeLists } from '../../../functions/textEditorValues'

// Components Local
import FontSizeSelect from './components/FontSizeSelect'

// css
import css from '../css/styleSettings.css'


class FontSizeSetting extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  resetFontSize = () => {
    const { _this, styleName } = this.props

    if (styleName) {
      removeStyle(_this, styleName)
    }
  }

  render() {
    const { label } = this.props

    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td className="pd-0 font-0-7">{ label ||'ขนาดตัวอักษร' }</td>
              <td className="pd-0 font-0-7 text-right">
                <a className={css.btn} onClick={this.resetFontSize}>รีเซ็ท</a>
              </td>
            </tr>
          </tbody>
        </table>

        <div>
          <div className={css.boxInput}>
            <table>
              <tbody>
                <tr>
                  <td width="50%" className="pd-0 font-0-8 center">
                    <FontSizeSelect {...this.props} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default FontSizeSetting
