// -------------------------- product price all ----------------------
export const getPriceProductsAll = ({ items }) => {
  let price = 0

  if (items) {
    items.map(pd => {
      const product = pd.products_id
      const { products_items_id, qty } = pd

      if (products_items_id) {
        price += getPriceProduct({ product, products_items_id, qty })
      }
    })
  }

  price = parseFloat(price.toFixed(2))

  return price
}

export const getPriceProduct = ({ product, products_items_id, qty }) => {
  const item = getProductItem({ product, products_items_id })
  let priceProduct = getItemPriceTotal(item, qty)
  let priceTotal = priceProduct
  priceTotal = priceTotal && parseFloat(priceTotal.toFixed(2))

  return priceTotal
}

export const getProductItem = ({ product, products_items_id }) => {
  return product && product.items && product.items.filter(item => item._id === products_items_id)[0]
}

const getItemPriceTotal = (item, qty) => {
  if (item) {
    let price = getItemPrice(item)

    return price * qty
  }
}

const getItemPrice = (item) => {
  if (item) {
    return getSalePrice(item.discount, item.price)
  }
}

const getSalePrice = (discount, price) => {
  let salePrice

  if (discount) {
    if (discount.type === 'currency') {
      salePrice = price - discount.value

    } else if (discount.type === 'percent') {
      salePrice = price - (price * discount.value / 100)
    }
  } else {
    salePrice = price
  }

  return salePrice
}
// -------------------------- product price all ----------------------
