import React from 'react'

// Components Global
import { BtnModalOpen } from '../../../Modals/BtnModal'
import { BtnModalUploadOpen } from '../../../Modals/ModalUploadImages/BtnModalUpload'

// Components Local
import ModalGallerySelectImage from './components/ModalGallerySelectImage'


const BoxUploadImage = (props) => {
  const {
    id,
    focus,
    selectImage,
    content,
    paths,
    img,
    cover,
    gallery,
    galleryPaths,
    openTools,
    action,
    upload,
    crop,
    index,
    match
  } = props

  const showAddImage = (
    (img && !paths) ||
    (cover && !paths) ||
    (gallery && openTools && galleryPaths.length !== 0) ||
    (gallery && !openTools && galleryPaths.length === 0) ||
    (gallery && openTools && galleryPaths.length === 0)
  )

  let galleryModalId = `gallery-${id}`
  let uploadModalId = `upload-${id}`

  if (index !== undefined) {
    galleryModalId = `gallery-${id}-${index}`
    uploadModalId = `upload-${id}-${index}`
  }

  return (
    <div>
      <ModalGallerySelectImage
        match={match}
        img={img}
        cover={cover}
        gallery={gallery}
        id={galleryModalId}
        action={action}
        selectImage={selectImage}
        content={content}
        galleryPaths={galleryPaths}
        focus={focus}
        index={index}
      />

      <div
        style={{
          zIndex: '1',
          position: 'relative',
          background: '#ddd',
          width: '100%',
          padding: '0',
          marginTop: paths && gallery && '10px',
          height: showAddImage ? 'auto': '0',
          opacity: showAddImage ? '1': '0',
          padding: showAddImage ? '10px': '0',
          visibility: showAddImage ? 'visible': 'hidden'
        }}>

        <div className="center">
          <div className="dp-inline-block mg-bottom-10">
            <i className={`material-icons font-2-5`}>add_photo_alternate</i>
          </div>
          <div className="font-1-0 color-000 font-0-9 text-overflow-ellipsis">เพิ่มรูปภาพ</div>
          <div className="mg-top-10">

            <BtnModalUploadOpen className="dp-inline-block" id={uploadModalId} upload={upload} crop={crop}>
              <a className="waves-effect color-000 font-0-9 waves-light btn-shadow-none btn btn-xs btn-default mg-3">
                <i className="material-icons left">cloud_upload</i>
                <span className="color-000 font-0-9">อัปโหลด</span>
              </a>
            </BtnModalUploadOpen>

            <BtnModalOpen id={galleryModalId}>
              <a className="waves-effect color-000 font-0-9 waves-light btn-shadow-none btn btn-xs btn-default mg-3">
                เลือกรูปภาพ
              </a>
            </BtnModalOpen>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BoxUploadImage

