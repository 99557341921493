import React from 'react'

export function Row (props) {
  const style = {
    marginTop: '0px',
    marginBottom: '0',
    textAlign: props.align
  }

  let joinStyle = Object.assign(style, props.style)
  let className = (props.className ? props.className : '')

  return (
    <div className={`row ${className}`} style={joinStyle}>
      {props.children}
    </div>
  )
}

export function Column(props) {
  const style = {
    height: props.height,
    lineHeight: props.height,
    padding: '0',
  }

  let joinStyle = Object.assign(style, props.style);
  let center = (props.align ? props.align : '')

  return (
    <div className={`${props.col || ''} ${props.className || ''} ${center}`} style={joinStyle}>
      {props.children}
    </div>
  )
}
