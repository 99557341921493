import React, { Component } from 'react'
import { connect } from 'react-redux'
import cuid from 'cuid'

// Redux Actions
import { openConfirm } from '../../../redux/App/AppActions'

// css
import css from './css/switch.css'

class Switch extends Component {
  constructor(props) {
    super(props)

    const id = this.props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, '')
    const randomId = cuid()
    const { valueOpen, valueOff, value, number } = props

    let checked

    if (valueOpen && valueOff) {
      checked = value === valueOpen ? true : false

    } else {
      if (number) {
        checked = value === 1 ? true : false
      } else {
        checked = value ? value : false
      }
    }

    this.state = {
      id: randomId+id,
      checked
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      let checked

      if (this.props.number) {
        checked = this.props.value === 1 ? true : false
      } else {
        checked = this.props.value ? this.props.value : false
      }

      this.setState({ checked })
    }
  }

  handleOpenConfirm = (confirm) => {
    this.props.dispatch(openConfirm(this.props.id, {
      title: `${confirm.label}`,
      message: `กดปุ่ม "${confirm.label}" ถ้าคุณต้องการ${confirm.label}`,
      type: confirm.type,
      icon: confirm.icon,
      confirmLabel: confirm.label,
      cancelLabel: 'ยกเลิก',
      onConfirm: confirm.onConfirm,
    }))
  }

  handelOnChane = (e, checked) => {
    if (!this.props.confirm) {
      if (checked) {
        this.setState({ checked: false }, () => {
          if (this.props.getValue) {
            this.props.getValue(this.state.checked, this.state.id, this.props.id, this.props.valueOff)
          }

          if (this.props.onChange) {
            this.props.onChange(this.state.checked, this.state.id, this.props.id, this.props.valueOff)
          }

          if (this.props.confirm) {
            this.handleOpenConfirm(this.props.confirm)
          }
        })
      } else {
        this.setState({ checked: true }, () => {
          if (this.props.getValue) {
            this.props.getValue(this.state.checked, this.state.id, this.props.id, this.props.valueOpen)
          }

          if (this.props.onChange) {
            this.props.onChange(this.state.checked, this.state.id, this.props.id, this.props.valueOpen)
          }

          if (this.props.confirm) {
            this.handleOpenConfirm(this.props.confirm)
          }
        })
      }
    } else {
      this.handleOpenConfirm(this.props.confirm)
    }
  }

  render() {
    const propsStyle = this.props.style || {}
    const style = { height: '33px', lineHeight: '33px', ...propsStyle }
    const { valueOpen, valueOff } = this.props

    let value

    if (valueOpen && valueOff) {
      if (this.state.checked === true) {
        value = valueOpen
      } else {
        value = valueOff
      }

    } else {
      if (this.props.number) {
        value = this.state.checked === true ? 1 : 0
      } else {
        value = this.state.checked
      }
    }

    return(
      <div className={`switch ${css.switch}`} style={style}>
        <label className="f5">

          { !this.props.labelHidden &&
            <span>{ this.props.labelOff || 'ปิด' }</span>
          }

          <input
            onChange={(e) => this.handelOnChane(e, this.state.checked)}
            id={this.state.id}
            data-id={this.props.id}
            ref={this.state.id}
            checked={this.state.checked}
            value={value}
            data-not-save={this.props.notSave}
            type="checkbox"
          />


          <span className={`lever ${css.lever}`}></span>

          { !this.props.labelHidden &&
            <span>{ this.props.labelOpen || 'เปิด' }</span>
          }
        </label>
      </div>
    )
  }
}

export default connect()(Switch)
