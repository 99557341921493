import React from 'react'
import { connect } from 'react-redux'

// Components Editor
import BlockStyle from '../components/BlockStyle'

// Components local
import Lists from './components/Lists'

const TextEditorListsDisplay = (props) => {
  const { content, mode, action, closeSideNavbar } = props
  const { pageCurrent } = action.params
  const lang = props.lang || 'local'
  const lists = content.lists && JSON.parse(content.lists)
  const style = lists && lists.style ? JSON.parse(lists.style) : {}
  const className = lists && lists.class ? JSON.parse(lists.class) : []
  const items = lists && lists.items ? JSON.parse(lists.items) : []
  const api = lists && lists.api ? JSON.parse(lists.api) : ''

  return (
    <BlockStyle {...props} style={style} className={className}>
      <Lists
        {...props}
        pageCurrent={pageCurrent}
        linkOnClick={closeSideNavbar}
        mode={mode}
        lang={lang}
        items={items}
        style={style}
        className={className}
        lists={lists}
        api={api}
      />
    </BlockStyle>
  )
}

export default connect()(TextEditorListsDisplay)
