
export const generateTypeName = (NAME) => {
  const ADDS = 'ADD_' + NAME[0]
  const ADDS_GROUP = 'ADD_GROUP' + NAME[0]
  const ADDS_MORE = 'ADD_MORE_' + NAME[0]
  const ADD = 'ADD_' + NAME[1]
  const UPDATE = 'UPDATE_' + NAME[1]
  const REMOVE = 'REMOVE_' + NAME[1]

  return { ADDS, ADDS_GROUP, ADDS_MORE, ADD, UPDATE, REMOVE }
}

export const generateTypeFunctions = (NAME) => {
  const { ADDS, ADDS_GROUP, ADDS_MORE, ADD, UPDATE, REMOVE } = generateTypeName(NAME)

  const adds = ({ datas, search=false, params=null }) => ({ type: ADDS, datas, search, params })
  const adds_group = ({ datas, search=false, params=null, groupId }) => ({ type: ADDS_GROUP, datas, search, params, groupId })
  const adds_more = ({ datas, search=false }) => ({ type: ADDS_MORE, datas, search })
  const add = ({ data, order }) => ({ type: ADD, data, order })
  const update = ({ data, _id, event=null, groupId }) => ({ type: UPDATE, data, _id, event, groupId })
  const remove = ({ _id, groupId }) => ({ type: REMOVE, _id, groupId })

  return { adds, adds_group, adds_more, add, update, remove }
}


