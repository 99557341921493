export const validateYouTubeUrl = (url) =>{
  if (url != undefined || url != '') {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/
    const match = url.match(regExp)

    if (match && match[2].length == 11) {
      return 'https://www.youtube.com/embed/' + match[2]
    } else {
      return false
    }
  } else {
    return false
  }
}

export const generateUrlVideo = (params) => {
  let { url, play, autoplay, rel, modestbranding, controls, mute, fs } = params

  if (url) {
    url = validateYouTubeUrl(url)

    autoplay = play ? 1 : autoplay

    if (url) {
      return `${url}?autoplay=${autoplay}&rel=${rel}&modestbranding=${modestbranding}&controls=${controls}&mute=${mute}&fs=${fs}`
    } else {
      return false
    }
  } else {
    return false
  }
}
