import React, { Component } from 'react'

// Components Global
import ColorPicker from '../../../../ColorPicker'

// Functions
import { removeClass, addStyle, removeStyle } from '../../../functions/textEditorFunctions'

// Components
import FontSizeSelect from '../FontSizeSetting/components/FontSizeSelect'
import SelectFontWeight from '../FontWeightSetting/components/SelectFontWeight'
import TextDecorationSetting from '../TextDecorationSetting'

// css
import css from '../css/styleSettings.css'


class BoxShadowSetting extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleReset = () => {
    const { _this, styleName } = this.props

    removeStyle(_this, null, {
      styleMultiple: [
        { style: `${styleName}-size` },
        { style: `${styleName}-color` },
        { style: `${styleName}-weight` },
        { style: `${styleName}-text-decoration` },

        { style: `${styleName}-font-size` },
        { style: `${styleName}-font-size-rem` },
        { style: `${styleName}-font-size-vm` },
      ]
    })
  }

  colorChange = (color) => {
    const { _this, styleName } = this.props

    addStyle(_this, {
      style: `${styleName}-color`,
      value: color,
    })
  }

  render() {
    const { _this, styleName } = this.props
    const { style } = _this.state
    const colorName = `${styleName}-color`
    const color = style[colorName]

    return (
      <div className="text-left">
        <table>
          <tbody>
            <tr>
              <td className="pd-0 font-0-7">ตัวอักษร</td>
              <td className="pd-0 font-0-7 text-right">
                <a className={css.btn} onClick={this.handleReset}>รีเซ็ต</a>
              </td>
            </tr>
          </tbody>
        </table>

        <div>
          <div className={css.boxInput}>
            <table>
              <tbody>
                <tr>
                  <td width="25%" className="pd-0 font-0-8 center border-right-eee">
                    <FontSizeSelect
                      {...this.props}
                      styleName={`${styleName}-font-size`}
                    />
                  </td>
                  <td width="25%" className="pd-0 font-0-8 center border-right-eee">
                    <SelectFontWeight {...this.props} styleName={`${styleName}-font-weight`} />
                  </td>
                  <td width="25%" className="pd-0 font-0-8 center border-right-eee">
                    <TextDecorationSetting {...this.props} styleName={`${styleName}-text-decoration`} />
                  </td>
                  <td width="25%" className="pd-0 line-height-0 center">
                    <ColorPicker
                      right="70px"
                      hiddenColorAuto
                      label="สีตัวอักษร"
                      onChange={this.colorChange}
                      styleDropdown={{ top: '37px', left: undefined, right: '0px' }}
                      style={{ width: '100%' }}>

                      <div className={css.boxColorSeleted}>
                        <div
                          className={`${css.colorSeleted} ${css.inputLast}`}
                          style={{ background: color }}
                        />
                      </div>
                    </ColorPicker>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <table>
            <tbody>
              <tr>
                <td width="25%" className="pd-0 font-0-7 center">ขนาด</td>
                <td width="25%" className="pd-0 font-0-7 center">ตัวหนา</td>
                <td width="25%" className="pd-0 font-0-7 center">ขีดเส้น</td>
                <td width="25%" className="pd-0 font-0-7 center">สี</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default BoxShadowSetting
