import React from 'react'

// Components Local
import { EditorTextDisplay } from '../components/EditorText'


const TextEditorTable = ({ content, lang }) => {
  lang = lang || 'local'

  const table = content.table && JSON.parse(content.table),
        rows = table && table.rows ? JSON.parse(table.rows) : [],
        style = table && table.style ? JSON.parse(table.style) : {},
        className = table && table.class ? JSON.parse(table.class) : []

  return (
    <div>
      <table className="table-border">
        <tbody>
          { rows.map((row, iRow) => {
            const columns = row.columns ? JSON.parse(row.columns) : []

            return (
              <tr key={iRow}>
                { columns.map((column, iColumn) => {
                  return (
                    <td key={iColumn} style={{ padding: '0' }}>
                      <EditorTextDisplay
                        type="paragraph"
                        lang={lang}
                        value={column[`value_${lang}`]}
                      />
                    </td>
                  )
                }) }
              </tr>
            )
          }) }
        </tbody>
      </table>
    </div>
  )
}

export default TextEditorTable
