import { fetchDatas, fetchData, createData, updateData, removeData } from '../reduxActions'
import { generateTypeName, generateTypeFunctions } from '../reduxTypes'

import uploadsForS3 from '../../util/uploadsForS3'
import { checkAppActions } from '../App/AppActions'

// Export Constants
const NAME_TYPE = ['SERVICES', 'SERVICE']
const name = "products"

// Type Names and Type Functions
export const { ADDS, ADDS_MORE, ADD, UPDATE, REMOVE } = generateTypeName(NAME_TYPE)
export const { adds, adds_more, add, update, remove } = generateTypeFunctions(NAME_TYPE)


// Export Actions
export const fetchProducts = (businesses_id, params = {}) => dispatch => {
  return fetchDatas({ 
    dispatch, 
    params, 
    api: `${name}/${businesses_id}`,
    add: adds, 
    addMore: adds_more
  })
}

export const fetchProduct = (businesses_id, _id, params = {}) => dispatch => {
  return fetchData({ 
    dispatch, 
    params, 
    api: `${name}/${businesses_id}/${_id}`,
    add: add
  })
}

export const createProduct = (businesses_id, params) => dispatch => {
  return createData({ 
    dispatch, 
    params, 
    api: `${name}/${businesses_id}/create`, 
    add: add
  })
}

export const updateProduct = (businesses_id, _id, params) => dispatch => {
  return updateData({ 
    dispatch, 
    params, 
    api: `${name}/${businesses_id}/${_id}`,
    update: update,
    updateId: _id
  })
}

export const uploadImagesProduct = (businesses_id, products_id, files, prefix, params) => dispatch => {
  checkAppActions(dispatch, params, 'start')
  uploadsForS3(files, prefix, params.progressId, dispatch).then(val => {
    val.map(paths => {
      params.body.paths = paths
      dispatch(updateProduct(businesses_id, products_id, params))
    })
  })
}

export const removeProduct = (businesses_id, _id, params) => dispatch => {
  return removeData({
    dispatch, 
    params, 
    api: `${name}/${businesses_id}/${_id}`,
    remove: remove,
    deleteId: _id
  })
}