import Immutable from 'immutable'
import {
  ADD_PROGRESS,
  UPDATE_PROGRESS,
  REMOVE_PROGRESS,
  ADD_FILE_PROGRESS,
  UPDATE_FILE_PROGRESS,
  REMOVE_FILE_PROGRESS
} from './ProgressesActions'

// Initial State
const initialState = {
  data: []
}

const AppReducer = (state = initialState, action) => {
  switch (action.type) {

    // state progress
    case ADD_PROGRESS:
      const progress = state.data.filter(progress => progress.id === action.id)[0]

      if (!progress)
        return { data: [{ id: action.id, upload: action.upload, album: action.upload.album, background: false, files: [] }, ...state.data] }
      else {
        if (progress.album !== action.album) {
          const iAp = state.data.map(progress => progress.id).indexOf(action.id)
          const orig = Immutable.fromJS(state.data)
          const updated = orig.setIn([iAp, 'album'], action.album)
          
          return { ...state, data: updated.toJS() }
        } else {
          return { ...state }
        }
      }
        

    case UPDATE_PROGRESS:
      let iUp = state.data.map(progress => progress.id).indexOf(action.id)

      if (iUp !== -1) {
        const orig = Immutable.fromJS(state.data)
        const updated = orig.setIn([iUp, 'background'], action.status)

        return { ...state, data: updated.toJS() }
        console.log('dddd')
      } else {
        console.log('yyy')
        return  { ...state }
      }


    case REMOVE_PROGRESS:
      return { data: state.data.filter(progress => progress.id !== action.id) }


    // state file progress
    case ADD_FILE_PROGRESS:
      let indexProgress = state.data.map(progress => progress.id).indexOf(action.id)

      

      if (indexProgress !== -1) {
        const nested = Immutable.fromJS(state.data)
        const updated = nested.updateIn([indexProgress, 'files'], list => list.push(action.file))

        return { ...state, data: updated.toJS() }
      } else {
        return  { ...state }
      }

    case UPDATE_FILE_PROGRESS:
      let indexUpdateProgress = state.data.map(progress => progress.id).indexOf(action.id)

      if (indexUpdateProgress !== -1) {
        const orig = Immutable.fromJS(state.data)
        const updated = orig.setIn([indexUpdateProgress, 'files', action.index, 'progress'], action.status.progress)
                            .setIn([indexUpdateProgress, 'files', action.index, 'status'], action.status.status)

        return { ...state, data: updated.toJS() }
      } else {
        return  { ...state }
      }

    case REMOVE_FILE_PROGRESS:
      const indexRemoveProgress = state.data.map(progress => progress.id).indexOf(action.id)

      if (indexRemoveProgress !== -1) {
        const nested = Immutable.fromJS(state.data)
        const updated = nested.updateIn([indexRemoveProgress, 'files'], list => list.splice(action.index, 1))
        
        return { ...state, data: updated.toJS() }
      } else {
        return  { ...state }
      }
      

    // state default
    default:
      return state
  }
}

// Export Reducer
export default AppReducer
