const getIndexable = (validations) => {
  let indexable = '++id, &_id, created, updated'

  for (let key in validations) {
    if (validations[key]) {

      if (validations[key][0]) {
        key = `*${key}`
      }

      indexable = `${indexable}, ${key}`
    }
  }

  return indexable
}

export default getIndexable
