import React from 'react'

// Components Global
import ColorPicker from '../../../../ColorPicker'

// Functions
import { addStyle, removeStyle } from '../../../functions/textEditorFunctions'

// css
import css from '../css/styleSettings.css'


const OpacitySetting = (props) => {
  const { _this, styleName, } = props
  const { style } = _this.state
  const backgroundColorName = `${styleName}-background-color`
  const backgroundColor = style[backgroundColorName] || '#000000'
  const opacity = style[styleName] || ''

  const changeOpatity = (e) => {
    const value = e.target.value

    addStyle(_this, {
      style: styleName,
      value
    })
  }

  const handleBorderColorChange = (color) => {
    changeBackgroundColor(color)
  }

  const changeBackgroundColor = (color) => {
    addStyle(_this, {
      style: backgroundColorName,
      value: color
    })
  }

  const reset = () => {
    removeStyle(_this, null, {
      styleMultiple: [
        { style: styleName },
        { style: backgroundColorName }
      ]
    })
  }

  return (
    <div className="text-left">
      <table>
        <tbody>
          <tr>
            <td className="pd-0 font-0-7">ความทึบแสง (opacity)</td>
            <td className="pd-0 font-0-7 text-right">
              <a className={css.btn} onClick={reset}>รีเซ็ท</a>
            </td>
          </tr>
        </tbody>
      </table>

      <div className={css.boxInput}>
        <table>
          <tbody>
            <tr>
              <td className="pd-0 font-0-8 center">
                <select
                  className={`browser-default ${css.input} ${css.select} ${css.inputBorderNone}`}
                  onChange={changeOpatity}
                  value={opacity}>

                  <option value="">เลือก</option>
                  <option value="0">0</option>
                  <option value="0.1">1</option>
                  <option value="0.2">2</option>
                  <option value="0.3">3</option>
                  <option value="0.4">4</option>
                  <option value="0.5">5</option>
                  <option value="0.6">6</option>
                  <option value="0.7">7</option>
                  <option value="0.8">8</option>
                  <option value="0.9">9</option>
                  <option value="1">10</option>
                </select>
              </td>

              <td width="30%" className="pd-0 center line-height-0 border-left-eee">
                <ColorPicker
                  right
                  label="สีเส้นขอบ"
                  onChange={handleBorderColorChange}
                  styleDropdown={{ top: '35px', left: undefined, right: '0' }}
                  style={{ width: '100%' }}>

                  <div className={css.boxColorSeleted}>
                    <div
                      className={`${css.colorSeleted} ${css.inputLast}`}
                      style={{ background: backgroundColor }}
                    />
                  </div>
                </ColorPicker>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default OpacitySetting
