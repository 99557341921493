import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import ColumnItem from './ColumnItem'
import ColumnItemDisplay from './ColumnItemDisplay'

const ColumnItemLazy = lazy(() => import('./ColumnItem'))
const ColumnItem = suspenseComponent(ColumnItemLazy)

export {
  ColumnItem,
  ColumnItemDisplay
}
