import { fetchDatas, fetchData, createData, updateData, removeData, fetchApi } from '../reduxActions'
import { generateTypeName, generateTypeFunctions, getTypePath } from '../reduxTypes'


// Export Constants
const NAME_TYPE = ['DEPOSITS', 'DEPOSIT']
const name = "deposits"

// Type Names and Type Functions
export const { ADDS, ADDS_MORE, ADD, UPDATE, REMOVE } = generateTypeName(NAME_TYPE)
export const { adds, adds_more, add, update, remove } = generateTypeFunctions(NAME_TYPE)


// Export Actions
export const countDeposits = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return fetchApi({
    dispatch,
    params,
    api: `${path}${name}-count/${authName}/${dbNo}/${businesses_id}`,
  })
}

export const fetchDeposits = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return fetchDatas({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchDeposit = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id, deposits_id } = query
  const path = getTypePath(businessType)

  return fetchData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/${deposits_id}`,
    add
  })
}

export const createDeposit = (query, params) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return createData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/create`,
    add
  })
}


export const updateDeposit = (query, params) => dispatch => {
  const { authName, dbNo, businessType, businesses_id, deposits_id, _id } = query
  const path = getTypePath(businessType)

  return updateData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/${_id || deposits_id}`,
    update: update,
    updateId: _id || deposits_id
  })
}

export const removeDeposit = (query, params) => dispatch => {
  const { authName, dbNo, businessType, businesses_id, deposits_id, _id } = query
  const path = getTypePath(businessType)

  return removeData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/${_id || deposits_id}`,
    remove: remove,
    deleteId: _id || deposits_id
  })
}
