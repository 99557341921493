import React from 'react'

// Components Global
import { LayoutsDesign } from '../../../../LayoutsDesign'

// Functions
import { getSections } from '../../../../../redux/Sites/SitesFunctions'


const Columns = (props) => {
  const lang = props.lang || 'local'
  const { items, display, _this, style } = props

  return (
    <div>
      <div className="columns-jsx">
        <table>
          <tbody>
            <tr>
              { items.map((item, i) => {
                item = JSON.parse(item)

                return (
                  <Td
                    {...props}
                    key={item.id}
                    i={i}
                    item={item}
                    style={style}
                  />
                )
              }) }
            </tr>
          </tbody>
        </table>

        <style jsx>{`
          .columns-jsx {
            width: ${style['columns-width'] || '100%'};
          }

          @media (min-width: 1500px) {
            .columns-jsx {
              width: ${style['columns-width-vw'] || '100%'};
            }
          }
        `}</style>
      </div>

      { !display &&
        <div className="center width-full pd-top-40">
          <a
            onClick={() => _this.openSidebar('content')}
            className="btn-shadow-none btn btn-sm btn-white pd-right-15 text-ellipsis"
            style={{ userSelect: 'none' }}>

            <i className="material-icons font-0-9 color-000 left">add</i>
            <span className="font-0-9 color-000">เพิ่มคอลัมน์</span>
          </a>
        </div>
      }
    </div>
  )
}

const Td = (props) => {
  let { item, i, style } = props
  const sections = getSections(props, { byGroupId: item.id })
  const no = i+1
  const bg = i%2 ? '#ddd' : '#fbfbfb'

  return (
    <td
      id={item.id}
      className={`column-${no}-jsx`}
      style={{
        padding: sections.length === 0 ? '10px' : '0',
        background: sections.length === 0 && bg,
        borderRadius: '0'
      }}>

      <LayoutsDesign
        {...props}
        display
        bodyColorNone
        groupId={item.id}
        sections={sections}
      />

      <style jsx>{`
        .column-${no}-jsx {
          width: ${style[`column-${no}-width`]};
          vertical-align: ${style['columns-vertical-align']};
        }

        @media (min-width: 1500px) {
          .column-${no}-jsx {
            width: ${style[`column-${no}-width-vw`]};
          }
        }

        @media (min-width: 601px) and (max-width: 1024px) {
          .column-${no}-jsx {
            width: ${style[`column-tablet-${no}-width`]};
          }
        }

        @media (min-width: 50px) and (max-width: 600px) {
          .column-${no}-jsx {
            width: ${style[`column-mobile-${no}-width`]};
          }
        }
      `}</style>
    </td>
  )
}

export default Columns
