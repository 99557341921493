import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import TextEditorLists from './TextEditorLists'
import TextEditorListsDisplay from './TextEditorListsDisplay'

let TextEditorLists = lazy(() => import('./TextEditorLists'))
TextEditorLists = suspenseComponent(TextEditorLists)

export {
  TextEditorLists,
  TextEditorListsDisplay
}
