import React from 'react'

// Components Local
import DivStyle from './DivStyle'


const BtnElementManage = (props) => {
  const { title, name, styleHover, styleActive, styleActiveClick } = props

  return (
    <div>
      <DivStyle {...props} />

      { styleHover &&
        <DivStyle {...props} event="hover" title={`${title}เมื่อเมาส์ชี้ (hover)`} name={`${name}-hover`} />
      }

      { styleActive &&
        <DivStyle {...props} event="active" title={`${title}เมื่อแอคทีฟ (active)`} name={`${name}-active`} />
      }

      { styleActiveClick &&
        <DivStyle {...props} event="active-click" title={`${title}เมื่อคลิก`} name={`${name}-active-click`} />
      }
    </div>
  )
}

export default BtnElementManage
