import React from 'react'

// Components Local
import DivStyle from './DivStyle'

const ImgElementManage = (props) => {
  const { title, name, styleHover, styleActive } = props
  const hidden = {
    margin: true,
    padding: true,
    opacity: true,
    lineHeight: true,
    font: true,
    iconSize: true,
    iconColor: true,
    iconMargin: true
  }

  return (
    <div>
      <DivStyle {...props} hidden={hidden} />

      { styleHover &&
        <DivStyle {...props} hidden={hidden} event="hover" title={`${title}เมื่อเมาส์ชี้ (hover)`} name={`${name}-hover`} />
      }

      { styleActive &&
        <DivStyle {...props} hidden={hidden} event="active" title={`${title}เมื่อแอคทีฟ (active)`} name={`${name}-active`} />
      }
    </div>
  )
}

export default ImgElementManage
