import React, { Component, Fragment } from 'react'
import moment from 'moment'

// css
import css from './css/required.css'

// Util
import { getNumeral } from '../../../util/getNumeral'

import { setStyleValue } from '../../TextEditor/components/ElementStyles/components/js/setDivStyle'


class Required extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: this.props.value !== undefined ? this.props.value : '',
      type: this.props.type,
      required: this.props.required,
      status: null,
      msgInvalid: ''
    }
  }

  componentDidMount() {
    this.checkRequired()
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value }, () => {
        this.checkRequired(this.props.value === '' ? true : false)
      })
    }

    if (this.props.required !== prevProps.required) {
      this.setState({ required: this.props.required }, () => {
        this.checkRequired(true)
      })
    }

    if (this.props.remoteStatus !== prevProps.remoteStatus) {
      this.setState({ remoteStatus: this.props.value }, () => {
        this.checkRequired(true)
      })
    }
  }

  setDefault = () => {
    let type = this.props.type

    if (type !== 'disabled') {
      let elem = document.getElementById(this.props.id)
      let elemClass

      if (this.props.classId !== undefined) {
        elemClass = document.getElementsByClassName(this.props.classId)
      }


      this.setState({ status: null, msgInvalid: '' }, () => {
        elem && elem.classList.remove("invalid")
        elem && elem.classList.remove("valid")
      })
    }
  }

  setValid = (showError) => {
    let type = this.props.type

    if (type !== 'disabled') {
      let elem = document.getElementById(this.props.id)
      let elemClass

      /*if (this.props.xxx) {
        console.log(elem)
      }*/

      if (this.props.classId !== undefined) {
        elemClass = document.getElementsByClassName(this.props.classId)
      }

      if (showError) {
        this.setState({ status: 'valid', msgInvalid: '' }, () => {

          if (elem) {
            elem.classList.remove("invalid")

            if (!this.props.notColorValid) {
              elem.classList.add("valid")
            }
          }

          if (elemClass) {
            Array.apply(1, Array(elemClass.length)).map((e, i) => {
              elemClass[i].style.borderBottom = "1px solid #4CAF50"
              elemClass[i].style.boxShadow = "0 1px 0 0 #4CAF50"
            })
          }

        })
      }
    }
  }

  setInvalid = (showError, msg) => {
    setTimeout(() => {
      let type = this.props.type

      if (type !== 'disabled') {
        let elem = document.getElementById(this.props.id)
        let elemClass

        if (this.props.classId !== undefined) {
          elemClass = document.getElementsByClassName(this.props.classId)
        }

        if (showError) {
          this.setState({ status: 'invalid', msgInvalid: msg }, () => {

            elem.classList.remove("valid")
            elem.classList.add("invalid")


            if (elemClass) {
              Array.apply(1, Array(elemClass.length)).map((e, i) => {
                elemClass[i].style.borderBottom = "1px solid #F44336"
                elemClass[i].style.boxShadow = "0 1px 0 0 #F44336"
              })
            }
          })
        }
      }
    }, 0)
  }

  checkRequired = (showError=false) => {
    let type = this.props.type

    if (type !== 'disabled') {
      const value = this.state.value !== undefined ? this.state.value : ''

      if (this.state.required) {
        if (value === "" || value === null || value.length === 0) {
          this.setInvalid(showError, 'ต้องไม่ว่าง')
        } else {
          this.checkType(showError)
        }

      } else {
        if (value === "" || value === null || value.length === 0) {
          this.setDefault()
        } else {
          this.checkType(showError)
        }
      }
    }
  }

  checkDigitMin = (showError, value) => {
    const digitMin = this.props.digitMin
    const msg = this.props.digitMinErrorMsg ? this.props.digitMinErrorMsg : `ต้องมีจำนวนตัวอักษรอย่างน้อย ${digitMin} หลัก`

    if (value.toString().length < digitMin) {
      this.setInvalid(showError, msg)
    } else {
      this.setValid(true)
    }
  }

  checkDigitMax = (showError, value) => {
    const digitMax = this.props.digitMax
    const msg = this.props.digitMaxErrorMsg ? this.props.digitMaxErrorMsg : `ต้องมีจำนวนตัวอักษรไม่เกิน ${digitMax} หลัก`

    if (value.toString().length > digitMax) {
      this.setInvalid(showError, msg)
    } else {
      this.setValid(true)
    }
  }

  checkDigitMinMax = (showError, value) => {
    const digitMin = this.props.digitMinMax[0]
    const digitMax = this.props.digitMinMax[1]
    const msg = this.props.digitMinMaxErrorMsg ? this.props.digitMinMaxErrorMsg : `ต้องมีจำนวนตัวอักษร ${digitMin} - ${digitMax} หลัก`

    if (value.toString().length < digitMin || value.toString().length > digitMax) {
      this.setInvalid(showError, msg)
    } else {
      this.setValid(true)
    }
  }

  checkDigit = (showError, value) => {
    const digit = this.props.digit
    const msg = this.props.digitErrorMsg ? this.props.digitErrorMsg : `ต้องมีจำนวนตัวอักษร ${digit} หลัก`

    if (value.toString().length < digit) {
      this.setInvalid(showError, msg)
    } else {
      this.setValid(true)
    }
  }

  checkDate = (showError, value) => {
    const pattern = /^(\d{2})\/(\d{2})\/(\d{4})$/g
    const msg = 'รูปแบบวันที่ไม่ถูกต้อง'

    if (pattern.test(value)) {
      const split = value.split('/')
      value = `${split[2]}-${split[1]}-${split[0]}`

      const date = moment(value).format('YYYY-MM-DD')

      if (date === 'Invalid date') {
        this.setInvalid(showError, msg)
      } else {
        this.setValid(true)
      }
    } else {
      this.setInvalid(showError, msg)
    }
  }

  checkType = (showError) => {
    const regex = this.props.regex

    let type = this.state.type
    let value = this.state.value
    let pattern
    let msg

    if (type === 'phoneNumber') {
      pattern = new RegExp("^[0-9]{" + 9 + ","+ 13 +"}$")
      msg = 'รูปแบบเบอร์โทรไม่ถูกต้อง'

    } else if (type === 'email') {
      pattern = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
      msg = 'รูปแบบอีเมลไม่ถูกต้อง'

    } else if (type === 'username') {
      pattern = /^([\w-]+(?:\.[\w-]+)*)$/i
      msg = 'รูปแบบไม่ถูกต้อง'

    } else if (type === 'regex' && regex) {
      pattern = regex.pattern
      msg = regex.msg

    } else {
      pattern = /^./
      msg = ''
    }

    if (this.props.remote) {
      let remoteStatus = this.props.remoteStatus

      if (remoteStatus === 'res-success') {
        this.setValid(true)
      } else if (remoteStatus === 'res-none') {
        this.setInvalid(showError, msg)
      } else if (remoteStatus === 'res-duplicate') {
        this.setInvalid(showError, msg)
      } else if (remoteStatus === 'catch') {
        this.setInvalid(showError, msg)
      } else if (remoteStatus === 'error') {
        this.setInvalid(showError, msg)
      } else if (remoteStatus === 'loading') {
        this.setInvalid(showError, msg)
      }

    } else {
      let maxValue = this.props.maxValue
      let minValue = this.props.minValue
      let maxMsg = this.props.maxValueErrorMsg ? this.props.maxValueErrorMsg : `ต้องมีค่าไม่เกิน ${getNumeral(maxValue)}`
      let minMsg = this.props.minValueErrorMsg ? this.props.minValueErrorMsg : `ต้องมีค่ามากกว่า ${getNumeral(minValue)} หรือเท่ากับ ${getNumeral(minValue)}`

      if (maxValue && !minValue) {
        if (parseFloat(value) > parseFloat(maxValue)) this.setInvalid(showError, maxMsg)
        else this.setValid(true)

      } else if (!maxValue && minValue) {
        if (parseFloat(value) < parseFloat(minValue)) this.setInvalid(showError, minMsg)
        else this.setValid(true)

      } else if (maxValue && minValue) {
        if (parseFloat(value) < parseFloat(minValue)) {
          this.setInvalid(showError, minMsg)
        } else if (parseFloat(value) > parseFloat(maxValue)) {
          this.setInvalid(showError, maxMsg)
        } else {
          this.setValid(true)
        }

      } else if (type === 'date') {
        this.checkDate(showError, value)
      } else {
        if (pattern.test(value)) {
          if (this.props.digit) {
            this.checkDigit(showError, value)
          } else if (this.props.digitMin) {
            this.checkDigitMin(showError, value)
          } else if (this.props.digitMax) {
            this.checkDigitMax(showError, value)
          } else if (this.props.digitMinMax) {
            this.checkDigitMinMax(showError, value)
          } else {
            this.setValid(true)
          }
        } else {
          this.setInvalid(showError, msg)
        }
      }
    }
  }

  render() {
    let label

    if (this.state.status === 'invalid') {
      label = css.invalid
    } else if (this.state.status === 'valid') {
      label = !this.props.notColorValid && css.valid
    }

    const { styleName, styleJsx } = this.props
    const name = styleName + '-label'

    return (
      <div className={`${css.container} ${styleName ? 'mg-bottom-0' : ''} ${this.props.className || ''}`} style={this.props.style}>

        { !this.props.labelHidden && this.props.label &&
          <label
            id={`label-${this.props.id}`}
            className={`${label || ''} label-jsx ${this.props.labelClassName || ''}`}
            style={{ color: this.props.type === 'disabled' && '#9e9e9e' }}>

            {this.props.label} {this.props.unitName && this.props.unitName}
            {this.state.required && <span style={{ color: this.props.disabled && '#9e9e9e' }}> *</span>}
          </label>
        }

        <div className={`${css.boxInput}`} onChange={this.onChange} style={{ display: 'grid' }}>
          { this.props.children }

          { this.state.status === 'valid' && this.props.iconValid && !this.props.remote &&
            <div className={css.boxIcon}>
              <i className="material-icons font-1-5" style={{ color: '#4CAF50' }}>check_circle</i>
            </div>
          }

          {/* remote status is loading */}
          { this.props.remote && this.props.remoteStatus === 'loading' &&
              <div className={css.boxIcon} style={this.props.statusStyle}>
                <div className="la-ball-clip-rotate la-sm width-20 height-20" style={{ color: '#7cb342'}}>
                  <div className="width-20 height-20" style={{ borderWidth: '2px'}}></div>
                </div>
              </div>
          }

          {/* remote status is success */}
          { this.props.remote && this.props.remoteStatus === 'res-success' &&
            <div className={css.boxIcon} style={this.props.statusStyle}>
              <i className="material-icons font-1-5" style={{ color: '#4CAF50' }}>check_circle</i>
            </div>
          }
        </div>



        { !this.props.msgHidden &&
          <Fragment>
            { this.state.status === 'invalid' && !this.props.remote &&
              <div className={css.msqInvalid}>
                {this.props.label || ''} {this.state.msgInvalid}
              </div>
            }

            {/* remote status is loading */}
            { this.props.remote && this.props.remoteStatus === 'loading' &&
              <div className={css.msqInvalid}>
                { this.props.remote.msgLoading ? this.props.remote.msgLoading : 'กำลังโหลดข้อมูล...' }
              </div>
            }

            {/* remote status is error */}
            { this.props.remote && this.props.remoteStatus === 'error' && this.state.msgInvalid &&
              <div className={css.msqInvalid}>
                {this.props.label || ''} {this.state.msgInvalid}
              </div>
            }

            {/* remote status is error */}
            { this.props.remote && this.props.remoteStatus === 'catch' &&
              <div className={css.msqInvalid}>
                { this.props.remote.msgCatch ? this.props.remote.msgCatch : 'ไม่สามารถเชื่อมต่อไปยังเซิร์ฟเวอร์ได้' }
              </div>
            }

            {/* remote status is res-error */}
            { this.props.remote && this.props.remoteStatus === 'res-none' &&
              <div className={css.msqInvalid}>
                { this.props.remote.msgNone ? this.props.remote.msgNone : `ไม่สามารถใช้ ${this.props.label || ''} นี้ได้` }
              </div>
            }

            {/* remote status is duplicate */}
            { this.props.remote && this.props.remoteStatus === 'res-duplicate' &&
              <div className={css.msqInvalid}>
                { this.props.remote.msgDuplicate ? this.props.remote.msgDuplicate : `ไม่สามารถใช้ ${this.props.label || ''} นี้ได้` }
              </div>
            }
          </Fragment>
        }

        <style jsx>{`
          .label-jsx {
            ${styleJsx && setStyleValue({ name, style: styleJsx })}
          }

          @media (min-width: 1500px) {
            .label-jsx {
              ${styleJsx && setStyleValue({ name, style: styleJsx, vw: true })}
            }
          }

          @media (min-width: 601px) and (max-width: 1024px) {
            .label-jsx {
              ${styleJsx && setStyleValue({ device: 'tablet', name, style: styleJsx })}
            }
          }

          @media (min-width: 50px) and (max-width: 600px) {
            .label-jsx {
              ${styleJsx && setStyleValue({ device: 'mobile', name, style: styleJsx })}
            }
          }
        `}</style>
      </div>
    )
  }
}

export default Required
