import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import TextEditorLine from './TextEditorLine'
import TextEditorTemplateDisplay from './TextEditorTemplateDisplay'

let TextEditorTemplate = lazy(() => import('./TextEditorTemplate'))
TextEditorTemplate = suspenseComponent(TextEditorTemplate)

export {
  TextEditorTemplate,
  TextEditorTemplateDisplay
}
