import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import TextEditorIcon from './TextEditorIcon'
import TextEditorIconDisplay from './TextEditorIconDisplay'

let TextEditorIcon = lazy(() => import('./TextEditorIcon'))
TextEditorIcon = suspenseComponent(TextEditorIcon)

export {
  TextEditorIcon,
  TextEditorIconDisplay
}
