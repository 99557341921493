import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

// Components Local
import ItemList from './components/ItemList'

// css
import css from './css/listsExpand.css'


const NewLink = (props) => {
  const { to, block, stopPropagation, children } = props
  const link = to === '/index' ? '/' : to

  if (!stopPropagation && !block) {
    return (
      <Link to={link}>
        { children }
      </Link>
    )
  } else {
    return (
      <a style={{ cursor: 'pointer' }}>
        { children }
      </a>
    )
  }
}


class ListsExpand extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.expandActive()
  }

  setChecked = (id, value) => {
    let newState = {}
    newState['checked_'+id] = value

    this.setState(newState, () => {
      this.checkRequired(id)
    })
  }

  handleExpand = (index) => {
    // close
    if (this.state[`expand_${index}`]) {
      this.setState({ [`expandTransition_${index}`]: false }, () => {
        setTimeout(() => {
          this.setState({ [`expand_${index}`]: false })
        }, 250)
      })

    // open
    } else {
      this.setState({ [`expand_${index}`]: true }, () => {
        setTimeout(() => {
          this.setState({ [`expandTransition_${index}`]: true })
        }, 50)
      })
    }
  }

  loopExpandActive = (_id, pages=[]) => {
    const { listsAll, lists } = this.props
    const page = listsAll.filter(list => list._id === _id)[0]

    if (page) {
      pages.push(page)

      if (page.parent_id) {
        this.loopExpandActive(page.parent_id, pages)
      } else {
        pages.map((page, i) => {
          const index = lists.findIndex(list => list._id === page._id)



          if (index !== -1) {
            this.handleExpand(index)
          }
        })
      }
    }
  }

  expandActive = () => {
    const { activeValue, listsAll, pathType } = this.props
    const page = listsAll && listsAll.filter(list => list[pathType]  === activeValue)[0]

    if (page) {
      this.loopExpandActive(page._id)
    }
  }

  handleOnClick = ({ list, index }) => {
    const { clickParent, mode } = this.props

    if (list.lists && list.lists.length !== 0) {
      if (clickParent) {
        if (!mode && this.props.onClick) {
          this.props.onClick()
        }
      } else {
        this.handleExpand(index)
      }
    } else {
      if (!mode && this.props.onClick) {
        this.props.onClick()
      }
    }
  }

  render() {
    const lang = this.props.lang || 'local'
    const {
      notFirst,
      lists,
      listsAll,
      linkStart,
      pathType,
      hiddenBtnExpand,
      activeValue,
      mode,
      clickParent,
      onClick,
      style,
      iconHidden,
      model,
      childNo
    } = this.props


    let no = childNo || 0

    let listColor = style && style['list-color']
    let listBackground = style && style['list-background-color']

    let listHoverColor = style && style['list-hover-color']
    let listHoverBackground = style && style['list-hover-background-color']

    let listActiveColor = style && style['list-active-color']
    let listActiveBackground = style && style['list-active-background-color']

    let listHeight, heightBoxContent

    if (model === 'data-tree') {
      listHeight = '44px'
      heightBoxContent = '40px'

      listBackground = listBackground || '#fff'
      listHoverColor = listHoverColor || '#039be5'
      listHoverBackground = listHoverBackground || '#eaf7ff'
      listActiveColor = listActiveColor || '#fff'
      listActiveBackground = listActiveBackground || '#039be5'

    } else {
      listHeight = '45px'
      heightBoxContent = '100%'
    }

    return (
      <div>
        { lists.map((list, index) => {
          const expand = this.state[`expand_${index}`]
          const expandTransition = this.state[`expandTransition_${index}`]
          const link = `${linkStart}/${list[pathType]}`
          const data = listsAll.filter(item => item._id === list._id)[0]
          const stopPropagation = list.lists && list.lists.length !== 0 && !clickParent

          let activeClass

          if (activeValue === list[pathType]) {
            activeClass = 'active'
          } else {
            if (mode) {
              if ('index' === list[pathType]) {
                activeClass = 'active'
              }
            }
          }

          return (
            <div key={index}>
              <div
                className={css.list}
                style={{
                  height: listHeight
                }}>

                { model === 'data-tree' &&
                  <div
                    className={css.boxLine}
                    style={{ top: index === 0 && '-3px', bottom: index === 0 && '-3px' }}>

                    <div className={css.box1} style={{ borderLeft: !notFirst && index == 0 && '0' }} />
                    <div className={css.box2} style={{ borderLeft: lists.length === index+1 && '0' }} />

                    { list.lists && list.lists.length !== 0 && !hiddenBtnExpand &&
                      <div className={css.expand} onClick={() => this.handleExpand(index) }>
                        <i className="material-icons i-middle">
                          { expand ? 'remove' : 'add' }
                        </i>
                      </div>
                    }
                  </div>
                }

                { list.component ?
                  <div className={`boxContent ${css.boxContentComponent} ${activeClass}`}>
                    { list.component }
                  </div>
                :
                  <div>
                    { link ?
                      <NewLink to={link} block={mode} stopPropagation={stopPropagation}>
                        <div
                          onClick={() => this.handleOnClick({ list, index })}
                          className={`boxContent ${css.boxContent} ${activeClass}`}
                          style={{
                            left: model !== 'data-tree' && '0',
                            borderRadius: model !== 'data-tree' && '0',
                            border: model !== 'data-tree' && '0',
                            height: heightBoxContent
                          }}>

                          <ItemList model={model} childNo={no} expand={expand} iconHidden={iconHidden} lang={lang} item={data} />
                        </div>
                      </NewLink>
                    :
                      <div
                        className={`boxContent ${css.boxContent} ${activeClass}`}
                        style={{
                          left: model !== 'data-tree' && '0',
                          height: heightBoxContent
                        }}>

                        <ItemList model={model} childNo={no} expand={expand} iconHidden={iconHidden} lang={lang} item={data} />
                      </div>
                    }
                  </div>
                }
              </div>

              { list.lists && list.lists.length !== 0 && expand &&
                <div
                  className={css.boxList}
                  style={{ maxHeight: expandTransition ? '1000px' :'0' }}>

                  <div
                    className={css.boxSubList}
                    style={{
                      marginLeft: model !== 'data-tree' && '0',
                      paddingLeft: model !== 'data-tree' && '0',
                      borderLeft: (lists.length === index+1 || model !== 'data-tree') && '0'
                    }}>

                    <ListsExpand
                      notFirst
                      lang={lang}
                      mode={mode}
                      model={model}
                      iconHidden={iconHidden}
                      clickParent={clickParent}
                      onClick={onClick}
                      activeValue={activeValue}
                      lists={list.lists}
                      listsAll={listsAll}
                      pathType={pathType}
                      linkStart={linkStart}
                      style={style}
                      childNo={no + 1}
                    />
                  </div>
                </div>
              }
            </div>
          )
        }) }

        <style jsx>{`
          .active {
            color: ${listActiveColor} !important;
            background-color: ${listActiveBackground} !important;
          }
          .boxContent {
            color: ${listColor};
            background-color: ${listBackground};
          }
          .boxContent:hover {
            color: ${listHoverColor};
            background-color: ${listHoverBackground};
          }
        `}</style>
      </div>
    )
  }
}

export default connect()(ListsExpand)
