import moment from 'moment'

export const getDate = (date, format, lang='th', bbYear=false) => {
  moment.locale(lang)

  if (date) {
    if (bbYear) {
      return toBBYear(moment(date), format)
    } else {
      return moment(date).format(format)
    }
  } else {
    return '-'
  }
}

export const dateDiff = (startDate, endDate, format) => {
  let start = moment(startDate)
  let end = moment(endDate)

  return end.diff(start, format)
}

export const dateDuration = (startDate, endDate, format, lang) => {
  require("moment-duration-format")

  let start = moment(startDate)
  let end = moment(endDate)
  let seconds = end.diff(start, 'seconds')

  if (seconds < 0) {
    return moment.duration(0, 'seconds').format("d [วัน] 00:00:00 [ช.ม.]")
  } else {
    return moment.duration(seconds, 'seconds').format("d [วัน] hh:mm:ss [ช.ม.]")
  }
}

export const checkExpirationDate = (startDate, endDate) => {
  let start = moment(startDate)
  let end = moment(endDate)
  let seconds = end.diff(start, 'seconds')

  if (seconds < 0) {
    return true
  } else {
    return false
  }
}

export const toBBYear = (moment, format) => {
  let christianYear = moment.format('YYYY')
  let buddhishYear = (parseInt(christianYear) + 543).toString()

  return moment
    .format(format.replace('YYYY', buddhishYear).replace('YY', buddhishYear.substring(2, 4)))
    .replace(christianYear, buddhishYear)
}
