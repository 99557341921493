import React, { Component, Fragment } from 'react'

// Functions
import { addStyle, removeStyle } from '../../../functions/textEditorFunctions'

// css
import css from '../css/styleSettings.css'


class BackgroundPositionSetting extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.getValue()
    }
  }

  getValue = () => {
    const { _this } = this.props
    const { style } = _this.state
    const styleName = this.props.styleName

    let position = style[`${styleName}`] || ''
    let unit = ''

    const searchPx = position.search('px')
    const searchPercent = position.search('%')

    if (searchPx !== -1) {
      unit = 'px'
      position = parseInt(position)
    }

    if (searchPercent !== -1) {
      unit = '%'
      position = parseInt(position)
    }

    return {
      position,
      unit
    }
  }

  handlePositionChange = (e) => {
    const { unit } = this.state

    let value = e.target.value

    if (unit === 'px' || unit === '%') {
      value = parseInt(value)
    }

    this.setState({ position: value }, () => {
      this.addValue()
    })
  }

  addValue = () => {
    const { unit, position } = this.state

    let valuePosition, valuePositionRem, valuePositionVw

    if (unit === '' || unit === 'string') {
      valuePosition = `${position}`
      valuePositionRem = `${position}`
      valuePositionVw = `${position}`

    } else if (unit === 'px') {
      valuePosition = `${position}px`
      valuePositionRem = `${position/15}rem`
      valuePositionVw = `${position/15}vw`

    }  else if (unit === '%') {
      valuePosition = `${position}%`
      valuePositionRem = `${position}%`
      valuePositionVw = `${position}%`
    }

    let { _this, styleName } = this.props
    styleName = styleName

    addStyle(_this, {
      styleMultiple: [
        { style: `${styleName}`, value: valuePosition },
        { style: `${styleName}-rem`, value: valuePositionRem },
        { style: `${styleName}-vw`, value: valuePositionVw },
      ]
    })
  }

  handleUnitChnage = (e) => {
    const unit = e.target.value

    let position

    if (unit === 'string') {
      position = 'center'

    } else if (unit === 'px') {
      position = 0

    } else if (unit === '%') {
      position = 0
    }

    this.setState({ unit, position }, () => {
      this.addValue()
    })
  }

  reset = () => {
    let { _this, styleName } = this.props
    styleName = styleName

    if (styleName) {
      this.setState({ position: '', unit: '' }, () => {
        removeStyle(_this, null, {
          styleMultiple: [
            { style: `${styleName}`},
            { style: `${styleName}-rem`},
            { style: `${styleName}-vw`}
          ]
        })
      })
    }
  }

  render() {
    const { label, positionType } = this.props
    const { position, unit } = this.state

    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td className="pd-0 font-0-7">{ label }</td>
              <td className="pd-0 font-0-7 text-right">
                <a className={css.btn} onClick={this.reset}>รีเซ็ท</a>
              </td>
            </tr>
          </tbody>
        </table>

        <div>
          <div className={css.boxInput}>
            <table>
              <tbody>
                <tr>
                  <td width="70%" className="pd-0 font-0-7 center">

                    { (unit === '' || unit === 'string') ?
                      <select
                        className={`browser-default ${css.input} ${css.borderTop0} ${css.borderLeft0}`}
                        onChange={this.handlePositionChange}
                        value={position || ''}>

                        <option value="">เลือกตำแหน่ง</option>

                        { positionType === "x" &&
                          <Fragment>
                            <option value="left">ซ้าย</option>
                            <option value="center">กลาง</option>
                            <option value="right">ขวา</option>
                          </Fragment>
                        }

                        { positionType === "y" &&
                          <Fragment>
                            <option value="top">บน</option>
                            <option value="center">กลาง</option>
                            <option value="bottom">ล่าง</option>
                          </Fragment>
                        }
                      </select>
                    :
                      <input
                        style={{ textAlign: 'left' }}
                        className={`${css.input} ${css.borderRight1}`}
                        type="text"
                        onChange={this.handlePositionChange}
                        value={position || ''}
                      />
                    }
                  </td>
                  <td width="30%" className="pd-0 font-0-7 center">
                    <select
                      className={`browser-default ${css.input} ${css.select}`}
                      onChange={this.handleUnitChnage}
                      value={unit || 'string'}>

                      <option value="string">ตำแหน่ง</option>
                      <option value="px">px</option>
                      <option value="%">%</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/*<div>
          <div className={css.boxInput}>
            <table>
              <tbody>
                <tr>
                  <td className="pd-0 font-0-8">
                    { !auto ?
                      <input
                        style={{ textAlign: 'left' }}
                        className={`${css.input} ${css.inputBorderNone} ${css.inputFirst}`}
                        type="text"
                        onChange={this.handleValueChange}
                        value={value || ''}
                      />
                    :
                      <input
                        disabled
                        style={{ textAlign: 'left' }}
                        className={`${css.inputDisabled} ${css.inputBorderNone} ${css.inputFirst}`}
                        type="text"
                        value={value || ''}
                      />
                    }
                  </td>
                  <td width="80px" className="pd-0 font-0-8 border-left-eee center">
                    { !auto ?
                      <select
                        className={`browser-default ${css.input} ${css.select} ${css.inputBorderNone}`}
                        onChange={this.handleUnitChnage}
                        value={unit}>

                        <option value="px">px</option>
                        <option value="%">%</option>
                      </select>
                    :
                      <input
                        disabled
                        style={{ textAlign: 'left' }}
                        className={`${css.inputDisabled} ${css.inputBorderNone} ${css.inputFirst}`}
                        type="text"
                        value={value || ''}
                      />
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
                  </div>*/}
      </div>
    )
  }
}

export default BackgroundPositionSetting
