export const customerValue = ({ customer, lang='local' }) => {
  let firstNameLang, lastNameLang, companyNameLang, branchNameLang, nameLang, businessNameLang, contactNameLang, avatarName, addressLang, telFull, mobileFull
  let statusName, statusIcon, statusColor
  let telShort, mobileShort, corporateNameLang

  // gernaral
  if (customer) {
    firstNameLang = customer.firstName ? customer.firstName[lang] : null
    lastNameLang = customer.lastName ? customer.lastName[lang] : null
    businessNameLang = customer.businessName ? customer.businessName[lang] : null
    companyNameLang = customer.companyName ? customer.companyName[lang] : null
    branchNameLang = customer.branchName ? customer.branchName[lang] : null

    if (firstNameLang && lastNameLang) {
      contactNameLang = `${firstNameLang} ${lastNameLang}`
    } else if (firstNameLang && !lastNameLang) {
      contactNameLang = `${firstNameLang}`
    }

    if (!businessNameLang) {
      if (companyNameLang) {
        businessNameLang = companyNameLang
      } else {
        businessNameLang = contactNameLang
      }
    }

    if (customer.corporate) {
      if (businessNameLang) {
        nameLang = businessNameLang
      }
    } else {
      nameLang = businessNameLang ? businessNameLang : contactNameLang
    }

    if (nameLang) {
      avatarName = nameLang.split(' ')
    }

    // contact
    addressLang = customer.address ? customer.address[lang] : null
    telShort = customer.tel && customer.tel.number ? `${customer.tel.number}` : null
    telFull = customer.tel && customer.tel.number ? `${customer.tel.number}` : null

    mobileShort= customer.mobile && customer.mobile.number ? `${customer.mobile.number}` : null
    mobileFull = customer.mobile && customer.mobile.number ? `${customer.mobile.number}` : null


    // Status
    if (customer.status === 'open') {
      if (lang === 'local') statusName = 'เปิด'
      statusIcon = "public"
      statusColor = "success"

    } else if (customer.status === 'close') {
      if (lang === 'local') statusName = 'ปิด'
      statusIcon = "lock"
      statusColor = "warning"

    } else if (customer.status === 'trash') {
      if (lang === 'local') statusName = 'ถังขยะ'
      statusIcon = "delete"
      statusColor = "error"
    }

    if (customer.corporate) {
      corporateNameLang = 'นิติบุคล'
    } else {
      corporateNameLang = 'บุคลธรรมดา'
    }
  }

  return {
    ...customer,
    firstNameLang, lastNameLang, companyNameLang, nameLang, branchNameLang, contactNameLang, businessNameLang, corporateNameLang,
    avatarName,
    addressLang,
    telFull, mobileFull,
    statusName, statusIcon, statusColor,
    mobileShort, telShort
  }
}


export const customerAddressValue = ({ customer, address, addresses_id, lang='local' }) => {
  let nameLang, telFull, branchNameLang, addressLang, addressShort, addressFull

  if (addresses_id) {
    address = customer.addresses && customer.addresses.filter(address => address._id === addresses_id)[0]
  }

  if (address) {
    const tel = address.tel

    nameLang = address.name ? address.name[lang] : null
    telFull = tel && `(${tel.code}) ${tel.number}`
    branchNameLang = address.branchName ? address.branchName[lang] : null
    addressLang = address.address ? address.address[lang] : null

    if (address.geo) {
      const { d, a, p, z } = address.geo
      let dFull, aFull, pFull
      let dShort, aShort, pShort

      if (p === 'กรุงเทพมหานคร') {
        dFull = `แขวง${d}`
        aFull = `เขต${a}`
        pFull = `${p}`

        dShort = `${d}`
        aShort = `${a}`
        pShort = `${p}`

      } else {
        dFull = `ตำบล${d}`
        aFull = `อำเภอ${a}`
        pFull = `จังหวัด${p}`

        dShort = `ต.${d}`
        aShort = `อ.${a}`
        pShort = `จ.${p}`
      }

      addressShort = `${addressLang} ${dShort} ${aShort} ${pShort} ${z}`
      addressFull = `${addressLang} ${dFull} ${aFull} ${pFull} ${z}`
    }

    return {
      ...address,
      nameLang,
      telFull,
      branchNameLang,
      addressLang,
      addressShort,
      addressFull
    }
  }
}
