import React, { Component, Fragment } from 'react'
import config from '../../../../../../config'

// Js
import { setStyleValue } from '../../../ElementStyles/components/js/setDivStyle'

// imgs
import imageNotFound from './imgs/image-not-found.png'
import imageLoding from './imgs/image-not-found.png'

const s3Url = config.app.s3.url
const s3UrlOriginal = config.app.s3.urlOriginal


class Img extends Component {
  constructor(props) {
    super(props)
    this.state = {
      src: '',
      v: 0
    }
  }

  componentDidMount() {
    this.getImgUrl()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.src !== this.props.src) {
      this.getImgUrl()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.src !== this.props.src ||
           nextState.src !== this.state.src ||
           nextState.sizeWidth !== this.state.sizeWidth ||
           nextProps.styleJsx !== this.props.styleJsx

  }

  /*onError = () => {
    const { srcOrigin } = this.state

    this.setState({ src: srcOrigin })
  }*/

  onError = () => {
    if (this.state.v < 8) {
      setTimeout(() => {
        this.setState({ v: this.state.v +1 })
      }, 2000)
    } else {
      this.setState({ src: this.state.srcOrigin })
    }
  }

  onLoad = () => {
    this.setState({ src: imageLoding })
  }

  getImgUrl = () => {
    const { paths, imageTypeSize } = this.props
    const path = paths[`${imageTypeSize}`]
    const pathWebP = paths[`${imageTypeSize}_webp`]

    const src = path && `${s3Url}/${path}`
    const srcOrigin = path && `${s3UrlOriginal}/${path}`
    const srcWebP = pathWebP && `${s3Url}/${pathWebP}`

    this.setState({ src, srcOrigin, srcWebP }, () => {
      if (this.state.src) {
        const img = new Image()
        img.src = this.state.src

        img.onload = () => {
          this.setState({
            sizeWidth: img.width,
            sizeHeight: img.height
          })
        }
      }
    })
  }



  render() {
    const { src, srcWebP, v, sizeWidth, sizeHeight } = this.state
    let { width, height, className, style, styleJsx, logo, gallery } = this.props

    styleJsx = styleJsx || {}

    if (logo) {
      width = styleJsx[`image-width`] || 'auto'
      height = styleJsx[`image-height`] || '100%'
    } else {
      width = styleJsx[`image-width`] || '100%'
      height = styleJsx[`image-height`] || '100%'
    }

    const name = "image"

    const imageComp = (
      <picture>
        { srcWebP &&
          <source srcSet={`${srcWebP}?v=${v}`} type="image/webp" />
        }

        <source srcSet={`${src}?v=${v}`} type="image/jpeg" />

        <img
          width={width}
          height={height}
          style={style}
          className={`img-jsx ${className || ''}`}
          onError={() => this.onError()}
          src={`${src}?v=${v}`}
        />
      </picture>
    )

    let imageSizeWidth, imageSizeHeight

    if (styleJsx['image-size-width']) {
      imageSizeWidth = styleJsx['image-size-width']
    } else {
      imageSizeWidth = sizeWidth
    }

    if (styleJsx['image-size-height']) {
      imageSizeHeight = styleJsx['image-size-height']
    } else {
      imageSizeHeight = sizeHeight
    }

    return (
      <Fragment>
        {/* <picture>
          { srcWebP &&
            <source srcSet={`${srcWebP}?v=${v}`} type="image/webp" />
          }

          <source srcSet={`${src}?v=${v}`} type="image/jpeg" />

          <img
            width={width}
            height={height}
            style={style}
            className={`img-jsx ${className || ''}`}
            onError={() => this.onError()}
            src={`${src}?v=${v}`}
          />
        </picture> */}

        { gallery ?
          <a
            className="photoSwipeLightbox"
            href={src}
            data-pswp-width={imageSizeWidth}
            data-pswp-height={imageSizeHeight}
            target="_blank">

            { imageComp }
          </a>
        :
          imageComp
        }


        <style jsx>{`
          .img-jsx {
            ${setStyleValue({ name, style: styleJsx })}

            width: ${width} !important;
            height: ${height} !important;
          }
          .img-jsx:hover {
            ${setStyleValue({ name: `${name}-hover`, style: styleJsx })}
          }
          .img-jsx:active {
            ${setStyleValue({ name: `${name}-active-click`, style: styleJsx })}
          }

          @media (min-width: 1500px) {
            .img-jsx {
              ${setStyleValue({ name, style: styleJsx, vw: true })}
            }
          }
        `}</style>

      </Fragment>
    )
  }
}

export default Img
