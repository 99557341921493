import http from 'http'
import JsBarcode from 'jsbarcode'


export const imgUrlToBase64 = (imgUrl) => {
  return new Promise(resolve => {
    http.get(imgUrl, (res) => {
      if (res.statusCode === 200) {
        res.setEncoding('base64')

        let body = "data:" + res.headers["content-type"] + ";base64,"
        let fileData

        res.on('data', (data) => {
          body += data
        })

        res.on('end', () => {
          const img = new Image()
          img.onload = function() {
            fileData = {
              width: this.width,
              height: this.height
            }

            resolve({ imgBase64: body, fileData })
          }

          img.src = body
        })
      } else {
        resolve(null)
      }
    })
  })
}


export const imgUrlMultipleToBase64 = (images) => {
  return new Promise(resolve => {
    images.map((img, i) => {
      imgUrlToBase64(img.url).then((data) => {
        images[i].base64 = data.imgBase64
        images[i].fileData = data.fileData

        if (images.length === i+1) {
          resolve(images)
        }
      })
    })
  })
}


export const barcodeToBase64 = (text, options) => {
  return new Promise(resolve => {
    const canvas = document.createElement("canvas")

    try {
      new JsBarcode(canvas, text, options)
      resolve(canvas.toDataURL("image/png"))
    } catch (e) {
      console.error(e)
      resolve(null)
    }
  })
}

export const barcodesMultipleToBase64 = (barcodes) => {
  return new Promise(resolve => {
    barcodes.map((barcode, i) => {
      barcodeToBase64(barcode.text, barcode.options).then(imgBase64 => {

        barcodes[i].base64 = imgBase64

        if (barcodes.length === i+1) {
          resolve(barcodes)
        }
      })
    })
  })
}
