import React, { Fragment } from 'react'

// Compoents Local
import { HeaderDisplay } from '../../../../../Header'
import { HeaderFixedDisplay } from '../../../../../HeaderFixed'
import { ContentGroupPageDisplay } from '../../../../../ContentGroupPage'


const PageHeader = (props) => {
  const {
    css,
    business,
    setting,
    site,
    pages,
    page,
    pageHeader,
    contentGroupPage,
    singlePageData,
    singlePageType,
    templateData
  } = props

  return (
    <Fragment>
      { pageHeader && pageHeader.headerShow &&
        <Fragment>

          { pageHeader.headerDefaultShow &&
            <header
              id="page-header-display"
              style={{ zIndex: '3' }}>

              { contentGroupPage && contentGroupPage.headerMainShow === false ?
                <ContentGroupPageDisplay
                  {...props}
                  groupId={contentGroupPage._id + '-header'}
                  datas={{
                    business,
                    setting,
                    site,
                    pages,
                    pageCurrent: page,
                    singlePageData,
                    singlePageType,
                    templateData
                  }}
                />
              :
                <HeaderDisplay
                  {...props}
                  datas={{
                    business,
                    setting,
                    site,
                    pages,
                    pageCurrent: page,
                    singlePageData,
                    singlePageType
                  }}
                />
              }
            </header>
          }

          { pageHeader.headerFixedShow &&
            <header
              id="page-header-fixed-display"
              className={css.headerFixed}
              style={{
                zIndex: '2',
                position: 'fixed',
                left: '0',
                right: '0'
              }}>

              { contentGroupPage && contentGroupPage.headerMainShow === false ?
                <ContentGroupPageDisplay
                  {...props}
                  groupId={contentGroupPage._id + '-header-fixed'}
                  datas={{
                    business,
                    setting,
                    site,
                    pages,
                    pageCurrent: page,
                    singlePageData,
                    singlePageType,
                    templateData
                  }}
                />
              :
                <HeaderFixedDisplay
                  {...props}
                  datas={{
                    business,
                    setting,
                    site,
                    pages,
                    pageCurrent: page,
                    singlePageData,
                    singlePageType
                  }}
                />
              }
            </header>
          }

        </Fragment>
      }
    </Fragment>
  )
}

export default PageHeader


