import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Local
import Modal from '../Modals/Modal'
import TextShadowPicker from './components/TextShadowPicker'

// Utils
import { getTextColor } from '../../util/getColor'

// Actions
import { closeModal } from '../../redux/App/AppActions'


class TextShadowCSSGenerator extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: []
    }
  }

  componentDidMount() {
    this.mounted = true
    this.converValueToItems()
  }

  setValue = () => {

  }

  converValueToItems = () => {
    const textShadow = this.props.value || "2px 2px 2px rgba(0, 0, 0, 1)"
    const textShadowVw = this.props.valuevw || "0.2vw 0.2vw 0.2vw rgba(0, 0, 0, 1)"

    const values = textShadow.split('),')
    const valuesVw = textShadowVw.split('),')
    const items = []

    values.map(value => {
      const search = value.indexOf(')')
      const searchSpace = value.indexOf(' ')

      if (search === -1) {
        value = value + ')'
      }

      if (searchSpace === 0) {
        value = value.replace(' ', '')
      }

      items.push({ textShadow: value })
    })

    valuesVw.map((value, i) => {
      const search = value.indexOf(')')
      const searchSpace = value.indexOf(' ')

      if (search === -1) {
        value = value + ')'
      }

      if (searchSpace === 0) {
        value = value.replace(' ', '')
      }

      items[i].textShadowVw = value
    })

    this.mounted && this.setState({ items })
  }

  addItem = () => {
    const items = this.state.items.slice()
    const itemLast = items[items.length - 1]

    let { textShadow, textShadowVw } = itemLast || {}
    textShadow = textShadow || '2px 2px 2px rgba(0, 0, 0, 1)'
    textShadowVw = textShadowVw || '0.2vw 0.2vw 0.2vw rgba(0, 0, 0, 1)'

    items.push({ textShadow, textShadowVw })

    this.mounted && this.setState({ items })
  }

  handleChangeTextShadow = (index, textShadow, textShadowVw) => {
    const items = this.state.items.slice()

    items[index] = {
      textShadow,
      textShadowVw
    }

    this.mounted && this.setState({ items })
  }

  removeItem = (index) => {
    if (index !== undefined) {
      const items = this.state.items.slice()
      items.splice(index, 1)

      this.mounted && this.setState({ items })
    } else {
      console.log('index is undefined')
    }
  }

  convertTextShadowString = () => {
    const { items } = this.state
    let textShadow = '', textShadowVw = ''

    items.map((item, i) => {
      let value, valueVw

      if (items.length === i+1) {
        value = `${item.textShadow}`
        valueVw = `${item.textShadowVw}`
      } else {
        value = `${item.textShadow},`
        valueVw = `${item.textShadowVw},`
      }

      textShadow = `${textShadow} ${value}`
      textShadowVw = `${textShadow} ${valueVw}`
    })

    return {
      textShadow,
      textShadowVw
    }
  }

  handleSubmit = () => {
    const { id } = this.props
    const { textShadow, textShadowVw } = this.convertTextShadowString()

    this.props.onChange(textShadow, textShadowVw)
    this.props.dispatch(closeModal(id))
  }


  render() {
    let { color, id } = this.props
    color = color || '#333'

    const { items } = this.state
    const { textShadow, textShadowVw } = this.convertTextShadowString()

    return (
      <Modal
        id={id}
        headerTitle="เงาตัวอักษร"
        headerLeft
        fnStartModal={this.setValue}
        width="500px">

        <div className="pd-20">
          <div
            style={{
              color,
              textShadow,
              background: '#efefef'
            }}
            className="center border-1 border-efefef border-radius-5 font-50 mg-bottom-20 width-full pd-20">

            ตัวอย่าง
          </div>

          { items.map((item, i) => {
            return (
              <TextShadowPicker
                key={i}
                index={i}
                onChange={this.handleChangeTextShadow}
                removeItem={this.removeItem}
                value={item.textShadow}
              />
            )
          }) }

          <div className="center">
            <a onClick={this.addItem} className="btn color-fff btn-shadow-none pd-right-15 height-35 line-height-35">
              <i className="material-icons font-15 left color-fff">add</i> เพิ่มเงา
            </a>
          </div>

          <div className="pd-top-30 text-right">
            <a onClick={this.handleSubmit} className="width-full color-fff btn btn-shadow-none btn-submit font-1-0">
              <i className="material-icons color-fff left">check</i> ยืนยันการเลือกเงา
            </a>
          </div>
        </div>
      </Modal>
    )
  }
}

export default connect()(TextShadowCSSGenerator)
