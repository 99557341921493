import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'


// Components Global
import { LayoutsDesignDisplay } from '../../../../LayoutsDesign'


const Template = (props) => {
  let { templates_id, action, templateData, apiData, dataStore, sites, content, style } = props
  const { business, rowDatas } = action.params
  const site = sites[0]

  if (content && content.api) {
    if (rowDatas) {
      const index = style['rowDataIndex'] && parseInt(style['rowDataIndex'])

      if (index !== undefined && !isNaN(index)) {
        const data = rowDatas[index]

        if (data) {
          apiData = data
        } else {
          //console.log('data undefined')
        }
      } else {
        //console.log('rowDataIndex undefined')
      }
    } else {
      //console.log('rowDatas undefined')
    }
  }

  const actionNested  = {
    params: {
      business,
      site,
      data: site,
      _id: site._id,
      templateData,
      apiData,
      dataStore,
      rowDatas
    }
  }

  return (
    <Fragment>
      { templates_id && site &&
        <LayoutsDesignDisplay
          {...props}
          display
          header={undefined}
          headerFixed={undefined}
          bodyColorNone
          groupId={templates_id}
          actionNested={actionNested}
          action={actionNested}
          apiData={apiData}
        />
      }
    </Fragment>
  )
}

const mapStateToProps = (store, props) => {
  return {
    sites: store.sites.data
  }
}

export default withRouter(connect(mapStateToProps)(Template))
