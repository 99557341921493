import React from 'react'

// Components Global
import ColorPicker from '../../../../../ColorPicker'

// css
import css from './css/inputBoxShadow.css'


const InputBoxShadow = (props) => {
  const {
    index,
    boxShadow,
    level,
    shadowLevelChange,
    shadowTypeChange,
    shadowColorChange,
    valueChange,
    removeShadow,
    advanced,
    shadowLevel
  } = props

  const handleShadowColorChange = (color) => {
    shadowColorChange(color, index)
  }

  return (
    <div>
      <div className={css.boxInput}>
        <table>
          <tbody>
            <tr>
              <td width={advanced ? '25%' : '33.33%'} className="pd-0 font-0-8 center border-right-eee">
                <select
                  className={`browser-default ${css.input} ${css.select} ${css.inputBorderNone}`}
                  onChange={(e) => shadowTypeChange(e, index)}
                  value={boxShadow && boxShadow.type || ''}>

                    <option value="">ด้านนอก</option>
                    <option value="inset">ด้านใน</option>
                </select>
              </td>

              { advanced &&
                <td width="12%" className="pd-0 font-0-8 center border-right-eee">
                  <input
                    value={boxShadow && boxShadow.x || '0'}
                    onChange={(e) => valueChange(e, 'x', index)}
                    className={`${css.input} ${css.inputBorderNone} ${css.inputFirst}`}
                    type="text"
                  />
                </td>
              }

              { advanced &&
                <td width="12%" className="pd-0 font-0-8 center border-right-eee">
                  <input
                    value={boxShadow && boxShadow.y || '0'}
                    onChange={(e) => valueChange(e, 'y', index)}
                    className={`${css.input} ${css.inputBorderNone} ${css.inputFirst}`}
                    type="text"
                  />
                </td>
              }

              { advanced &&
                <td width="12%" className="pd-0 font-0-8 center border-right-eee">
                  <input
                    value={boxShadow && boxShadow.blur || '0'}
                    onChange={(e) => valueChange(e, 'blur', index)}
                    className={`${css.input} ${css.inputBorderNone} ${css.inputFirst}`}
                    type="text"
                  />
                </td>
              }

              { advanced &&
                <td width="12%" className="pd-0 font-0-8 center border-right-eee">
                  <input
                    value={boxShadow && boxShadow.spread || '0'}
                    onChange={(e) => valueChange(e, 'spread', index)}
                    className={`${css.input} ${css.inputBorderNone} ${css.inputFirst}`}
                    type="text"
                  />
                </td>
              }

              { shadowLevelChange &&
                <td width="33.33%" className="pd-0 font-0-8 center">
                  <select
                    className={`browser-default ${css.input} ${css.select} ${css.inputBorderNone}`}
                    onChange={(e) => shadowLevelChange(e, index)}
                    value={level || '0'}>

                    { shadowLevel.map((v, i) => {
                      return (
                        <option key={i} value={i}>
                          { 'ระดับ ' + i }
                        </option>
                      )
                    }) }
                  </select>
                </td>
              }

              <td width={advanced ? '17%' : '33.33%'} className="pd-0 center line-height-0 border-left-eee">
                <ColorPicker
                  advanced
                  right
                  label="สีเงา"
                  onChange={handleShadowColorChange}
                  styleDropdown={{ top: '35px', left: undefined, right: '0' }}
                  style={{ width: '100%' }}>

                  <div className={css.boxColorSeleted}>
                    <div
                      className={`${css.colorSeleted} ${!advanced && css.inputLast}`}
                      style={{ background: boxShadow && boxShadow.color || '#ddd' }}
                    />
                  </div>
                </ColorPicker>
              </td>

              { advanced &&
                <td onClick={() => removeShadow(index)} width="10%" className={`pd-0 font-0-8 center line-height-0 border-left-eee ${css.btnDelete}`}>
                  <i className="material-icons font-1-2">close</i>
                </td>
              }
            </tr>
          </tbody>
        </table>
      </div>

      <table>
        { advanced &&
          <tbody>
            <tr>
              <td width="25%" className="pd-0 font-0-7 center">ตำแหน่ง</td>
              <td width="12%" className="pd-0 font-0-7 center">x</td>
              <td width="12%" className="pd-0 font-0-7 center">y</td>
              <td width="12%" className="pd-0 font-0-7 center">เบลอ</td>
              <td width="12%" className="pd-0 font-0-7 center">แผ่</td>
              <td width="17%" className="pd-0 font-0-7 center">สี</td>
              <td width="10%" className="pd-0 font-0-7 center"></td>
            </tr>
          </tbody>
        }
      </table>
    </div>
  )
}

export default InputBoxShadow
