import React from 'react'

// Components Local
import { EditorTextDisplay } from '../EditorText'

import BlockStyle from '../BlockStyle'

// Components Local
import Icon from './components/Icon'


const EditorIconDisplay = (props) => {
  const lang = props.lang || 'local'
  const iconValue = props.icon && JSON.parse(props.icon)
  const { containerStyle } = props

  const className = iconValue && iconValue.class ? JSON.parse(iconValue.class) : [],
        style = iconValue && iconValue.style ? JSON.parse(iconValue.style) : {},
        icon = iconValue && iconValue.icon ? JSON.parse(iconValue.icon) : '',
        layout = iconValue && iconValue.layout ? iconValue.layout : 'center',

        title = iconValue && iconValue.title,
        caption = iconValue && iconValue.caption,

        titleShow = iconValue && iconValue.titleShow,
        captionShow = iconValue && iconValue.captionShow

  const iconCircle = className.filter(list => list.name === 'icon-circle')[0]

  let bgIcon, iconColor

  if (iconCircle) {
    bgIcon = style.color || '#000'
    iconColor = "#fff"
  } else {
    iconColor = style.color || '#000'
  }


  const inputTitle = titleShow && (
    <EditorTextDisplay
      type="title"
      lang={lang}
      value={title}
    />
  )

  const inputCaption = captionShow && (
    <EditorTextDisplay
      type="caption"
      lang={lang}
      value={caption}
    />
  )

  const inputIcon = (
    <Icon icon={icon} style={style} />
  )

  return (
    <div className={containerStyle}>
      <BlockStyle {...props} style={style} className={className}>
        { layout === 'right' &&
          <table>
            <tbody>
              <tr>
                <td className="pd-0 width-5 pd-left-5 pd-right-10">
                  { inputIcon }
                </td>
                <td className="pd-0">
                  { inputTitle }
                  { inputCaption }
                </td>
              </tr>
            </tbody>
          </table>
        }

        { layout === 'center' &&
          <div style={{ textAlign: 'center' }}>
            { inputIcon }
            { inputTitle }
            { inputCaption }
          </div>
        }

        { !layout &&
          <div style={{ textAlign: 'center' }}>
            { inputIcon }
            { inputTitle }
            { inputCaption }
          </div>
        }

        { layout === 'left' &&
          <table>
            <tbody>
              <tr>
                <td className="pd-0">
                  { inputTitle }
                  { inputCaption }
                </td>
                <td className="pd-0 width-5 pd-left-10 pd-right-5">
                  { inputIcon }
                </td>
              </tr>
            </tbody>
          </table>
        }
      </BlockStyle>
    </div>
  )
}

export default EditorIconDisplay
