import React from "react"

// Components Global
import { LayoutsDesignDisplay } from '../../../LayoutsDesign'

const HeaderFixedDisplay = (props) => {
  const { business, setting, site, pages, pageCurrent, singlePageData } = props.datas

  return (
    <LayoutsDesignDisplay
      {...props}
      display
      headerFixed
      bodyColorNone
      action={{
        params: {
          business,
          setting,
          site,
          pages,
          data: singlePageData ? singlePageData : site,
          pageCurrent,
          _id: singlePageData ? singlePageData._id : site._id,
        }
      }}
    />
  )
}

export default HeaderFixedDisplay
