import React, { Component } from 'react'

// Components Global
import ImageCenter from '../ImageCenter'
import Avatar from '../Avatar'

// Files
import loading from './imgs/cloud_load_150x150.gif'
import css from './css/loadImage.css'

class Image extends Component {
  constructor(props) {
    super(props)
    this.state = {
      src: this.props.src,
      v: 0
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.src !== this.state.src) {
      this.setState({ src: nextProps.src })
    }
  }

  handleOnLoad = () => {
    this.setState({ status: true })
  }

  handleOnError = () => {
    if (this.props.src) {
      if (this.state.v < 8) {
        setTimeout(() => {
          this.setState({
            v: this.state.v +1
          })
        }, 2000)
      } else {
        this.setState({ error: true })
      }
    }
  }

  render() {
    const style = { position: 'relative', zIndex: '1', height: '100%', width: '100%', lineHeight: '0' }
    const joinStyle = Object.assign(style, this.props.style)
    let imgSrc

    if (this.state.v === 0) imgSrc = this.state.src
    else imgSrc = `${this.state.src}?v=${this.state.v +1 }`

    return (
      <div className={this.props.className} style={joinStyle}>
        { this.state.src ?
          <div style={{ height: '100%', lineHeight: '0' }}>
            <ImageCenter
              className={this.props.className || ''}
              circle={this.props.circle}
              s3Url={this.props.s3Url}
              s3UrlOriginal={this.props.s3UrlOriginal}
              onLoad={this.handleOnLoad}
              onError={this.handleOnError}
              alt={this.props.alt}
              imgStyle={this.props.imgStyle}
              src={imgSrc}
              showImage
              srcOriginal={this.props.srcOriginal}
            />

            { !this.state.status &&
              <div className={css.boxLoading}>
                <ImageCenter
                  circle={this.props.circle}
                  src={loading}
                  imgStyle={this.props.imgStyle}
                />
              </div>
            }

            { this.state.error &&
              <div style={{ position: 'absolute', top: '0', left: '0', right: '0', bottom: '0' }}>
                <div style={{ height: '100%', width: '100%', border: '1px solid #efefef', display: 'table', background: '#fff' }}>
                  <div style={{ textAlign: 'center', height: '100%', width: '100%', display: 'table-cell', verticalAlign: 'middle' }}>
                    <i className="material-icons" style={{ color: '#bdbdbd', fontSize: '3rem' }}>block</i>
                  </div>
                </div>
              </div>
            }
          </div>
        :
          <div className={css.boxNotImg}>
            <div className={css.typeIcon}>
              <div className={css.boxIcon}>
                <i className="material-icons">photo</i>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default Image
