import { fetchDatas, fetchData, createData, updateData, removeData, fetchApi } from '../reduxActions'
import { generateTypeName, generateTypeFunctions, getTypePath } from '../reduxTypes'

import callApi from '../../util/apiCaller'
import uploadsForS3 from '../../util/uploadsForS3'
import { checkAppActions } from '../App/AppActions'

// Export Constants
const NAME_TYPE = ['ORDERS', 'ORDER']
const name = "orders"

// Type Names and Type Functions
export const { ADDS, ADDS_MORE, ADD, UPDATE, REMOVE } = generateTypeName(NAME_TYPE)
export const { adds, adds_more, add, update, remove } = generateTypeFunctions(NAME_TYPE)

// Actions
import { adds as addCarts } from '../Carts/CartsActions'

// Export Actions
export const fetchOrders = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return fetchDatas({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchOrder = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id, orders_id } = query
  const path = getTypePath(businessType)

  return fetchData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/${orders_id}`,
    add: add
  })
}

export const createOrder = (query, params) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return createData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/create`,
    add: add
  })
}

export const createOrderForUser = ({ authName, dbNo, businesses_id }, params) => dispatch => {
  return createData({
    dispatch,
    params,
    api: `${name}/${authName}/${dbNo}/${businesses_id}/create`,
    addMultiple: [
      { storeName: 'orders', add },
      { storeName: 'carts', add: addCarts }
    ]
  })
}

export const updateOrder = (query, params) => dispatch => {
  const { authName, dbNo, businessType, businesses_id, orders_id, _id } = query
  const path = getTypePath(businessType)

  return updateData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/${_id || orders_id}`,
    update: update,
    updateId: _id || orders_id
  })
}

export const removeOrder = (query, params) => dispatch => {
  const { authName, dbNo, businessType, businesses_id, orders_id, _id } = query
  const path = getTypePath(businessType)

  return removeData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/${_id || orders_id}`,
    remove: remove,
    deleteId: _id || orders_id
  })
}

export const uploadImagesOrder = (query, fileParams, params) => dispatch => {
  const { files, imagePrefix } = fileParams

  checkAppActions(dispatch, params, 'start')
  uploadsForS3(files, imagePrefix, params.progressId, dispatch).then(val => {
    val.map(paths => {
      params.body.paths = paths
      dispatch(updateOrder(query, params))
    })
  })
}

export const checkOrderPaymentRefNoDuplicate = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return fetchApi({
    dispatch,
    params,
    api: `${path}${name}-query/${businesses_id}/check-ref-no-duplicate?${params.query}`
  })
}


export const countOrdersByGroup = ({ dbNo }, params={}) => dispatch => {
  return callApi(`count-orders-by-group/${dbNo}`, 'put', params.body).then(res => {
    return res
  })
}

export const countOrdersEcommerceByGroup = ({ dbNo }, params={}) => dispatch => {
  return callApi(`count-orders-ecommerce-by-group/${dbNo}`, 'put', params.body).then(res => {
    return res
  })
}

export const countOrders = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return fetchApi({
    dispatch,
    params,
    api: `${path}${name}-count/${authName}/${dbNo}/${businesses_id}`,
  })
}
