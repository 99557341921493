import React from 'react'

// Components Global
import CustomerProfileAvater from './CustomerProfileAvater'

// Values
import { customerValue } from '../../../../../../redux/Customers/CustomersValues'


const CustomerShort = (props) => {
  let { customer, avatarOnLy } = props

  if (customer && !customer.nameLang) {
    customer = customerValue({ customer })
  }

  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td className="pd-5 center" width="50" height="40">
              <div className="width-40 height-40 mg-center">
                <CustomerProfileAvater customer={customer} fontClassName="font-1-1" />
              </div>
            </td>

            { !avatarOnLy &&
              <td className="pd-5">
                <div className="width-120 text-ellipsis">
                  <span className="dp-block font-1-0">
                    {customer ? customer.nameLang : '-'}
                  </span>

                  { customer && customer.mobile ?
                    <span className="dp-block font-0-8 color-sub">{customer.mobileFull}</span>
                  :
                    <span className="dp-block font-0-8 color-sub">ลูกค้า</span>
                  }
                </div>
              </td>
            }
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default CustomerShort
