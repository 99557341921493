import React, { Fragment, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'

// Components Global
import { Row } from '../../Columns'

import {
  generateClassToString,
  checkUseAuth,
  checkShowPage
} from '../../TextEditor/functions/textEditorFunctions'

// Components Local
import { ColumnItemDisplay } from '../ColumnItem'

// Components Editor
import { checkCondition } from '../../TextEditor/components/ConditionSetting/js/conditionSetting'

import {
  setStyleValue
} from '../../TextEditor/components/ElementStyles/components/js/setDivStyle'


// JS
import { fetchDatasApi, getDataStore } from '../../TextEditor/components/FetchApiSettings/js/fetchApi'

// css
import css from './css/rowItem.css'


const RowItemDisplay = (props) => {
  const { layout, rowClass, auth, mode, loggedIn, apiData, templateData, dataStore, match } = props
  const { column1, column2, column3, column4, column5, column6 } = layout
  const style = layout.style ? JSON.parse(layout.style) : {}
  const className = layout.class ? JSON.parse(layout.class) : []
  const name = 'row'

  // style
  const styleDefault = useMemo(() => setStyleValue({ name, style, mode }), [style && JSON.stringify(style)])
  const styleDefaultVW = useMemo(() => setStyleValue({ name, style, vw: true, mode }), [style && JSON.stringify(style)])
  const styleTablet = useMemo(() => setStyleValue({ device: 'tablet', name, style, mode }), [style && JSON.stringify(style)])
  const styleMobile = useMemo(() => setStyleValue({ device: 'mobile', name, style, mode }), [style && JSON.stringify(style)])

  const check = useMemo(() => checkUseAuth({ style, auth, loggedIn, mode }), [
    style && style['use-auth'],
    auth && auth.user && auth.user._id
  ])

  const showPage = checkShowPage({ style, props, mode })
  const show = checkCondition({ style, mode, apiData, templateData, dataStore, props })


  useEffect(() => {
    if (style['fetchApiType']) {
      fetchDatasApi({
        ...props,
        style: style,
        content: layout
      })
    }
  }, [])

  if (props.items && props.items.length !== 0) {
    props.action.params.rowDatas = props.items
  }

  return (
    <Fragment>
      { useMemo(() =>
        <Fragment>
          { (check && show && showPage) &&
            <div className={`${css.layoutRowDisplay} row-jsx ${rowClass || ''} ${generateClassToString(className)}`}>
              <Row className="row-dp-flex">
                { column1 && column1.column &&
                  <ColumnItemDisplay
                    {...props}
                    no={1}
                    columnNo="column1"
                    column={column1}
                  />
                }

                { column2 && column2.column &&
                  <ColumnItemDisplay
                    {...props}
                    no={2}
                    columnNo="column2"
                    column={column2}
                  />
                }

                { column3 && column3.column &&
                  <ColumnItemDisplay
                    {...props}
                    no={3}
                    columnNo="column3"
                    column={column3}
                  />
                }

                { column4 && column4.column &&
                  <ColumnItemDisplay
                    {...props}
                    no={4}
                    columnNo="column4"
                    column={column4}
                  />
                }

                { column5 && column5.column &&
                  <ColumnItemDisplay
                    {...props}
                    no={5}
                    columnNo="column5"
                    column={column5}
                  />
                }

                { column6 && column6.column &&
                  <ColumnItemDisplay
                    {...props}
                    no={6}
                    columnNo="column6"
                    column={column6}
                  />
                }
              </Row>

              <style jsx>{`
                .row-jsx {
                  ${styleDefault}
                }

                @media (min-width: 1500px) {
                  .row-jsx {
                    ${styleDefaultVW}
                  }
                }

                @media (min-width: 601px) and (max-width: 1024px) {
                  .row-jsx {
                    ${styleTablet}
                  }
                }

                @media (min-width: 50px) and (max-width: 600px) {
                  .row-jsx {
                    ${styleMobile}
                  }
                }
              `}</style>
            </div>
          }
        </Fragment>
      , [
        props.match && JSON.stringify(props.match),
        props.location && JSON.stringify(props.location),
        props.apiData && JSON.stringify(props.apiData),
        props.templateData && JSON.stringify(props.templateData),
        props.auth && JSON.stringify(props.auth),
        props.action && props.action.params && JSON.stringify(props.action.params),
        props.dataStore && JSON.stringify(props.dataStore),
      ]) }
    </Fragment>
  )
}

const mapStateToProps = (store, props) => {
  const { layout } = props
  const style = layout.style ? JSON.parse(layout.style) : {}

  /*props.style = style
  props.content = layout*/

  const items = getDataStore(store, {
    ...props,
    style,
    content: layout
  })

  return {
    auth: store.auth,
    items
  }
}

export default connect(mapStateToProps)(RowItemDisplay)

