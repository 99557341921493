import React, { Fragment } from 'react'

import { setStyleValue } from '../../TextEditor/components/ElementStyles/components/js/setDivStyle'

const InputEditorDisplay = (props) => {
  const { editor, className, value, name, styleJsx } = props


  if (editor) {
    return (
      <div className="ql-editor-jsx">
        <div
          style={{ overflowY: 'hidden' }}
          className={`${className || ''} ql-editor`}
          dangerouslySetInnerHTML={{ __html: value }} />

        <style jsx>{`
          .ql-editor-jsx :global(.ql-editor) {
            padding: 0;
          }

          .ql-editor-jsx :global(img) {
            max-width: 100%;
          }
          .ql-editor-jsx :global(iframe) {
            width: 100%;
            height: 500px;
          }

          .ql-editor-jsx :global(p),
          .ql-editor-jsx :global(ol),
          .ql-editor-jsx :global(ul),
          .ql-editor-jsx :global(pre),
          .ql-editor-jsx :global(blockquote) {
            ${setStyleValue({ name: 'text', style: styleJsx })}
          }

          .ql-editor-jsx :global(a) {
            ${setStyleValue({ name: 'link', style: styleJsx })}
          }
          .ql-editor-jsx :global(a:hover) {
            ${setStyleValue({ name: 'link-hover', style: styleJsx })}
          }

          @media (min-width: 1500px) {
            .ql-editor-jsx :global(p),
            .ql-editor-jsx :global(ol),
            .ql-editor-jsx :global(ul),
            .ql-editor-jsx :global(pre),
            .ql-editor-jsx :global(blockquote) {
              ${setStyleValue({ name: 'text', style: styleJsx, vw: true })}
            }

            .ql-editor-jsx :global(iframe) {
              height: 33.33vw;
            }
          }

          @media (min-width: 601px) and (max-width: 1024px) {
            .ql-editor-jsx :global(p),
            .ql-editor-jsx :global(ol),
            .ql-editor-jsx :global(ul),
            .ql-editor-jsx :global(pre),
            .ql-editor-jsx :global(blockquote) {
              ${setStyleValue({ device: 'tablet', name: 'text', style: styleJsx })}
            }

            .ql-editor-jsx :global(iframe) {
              height: 350px;
            }
          }

          @media (min-width: 50px) and (max-width: 600px) {
            .ql-editor-jsx :global(p),
            .ql-editor-jsx :global(ol),
            .ql-editor-jsx :global(ul),
            .ql-editor-jsx :global(pre),
            .ql-editor-jsx :global(blockquote) {
              ${setStyleValue({ device: 'mobile', name: 'text', style: styleJsx })}
            }

            .ql-editor-jsx :global(iframe) {
              height: 250px;
            }
          }
        `}</style>
      </div>
    )
  } else {
    return (
      <div
        className={`${props.className || ''} ql-editor`}
        dangerouslySetInnerHTML={{ __html: props.value }}
      />
    )
  }
}


export default InputEditorDisplay
