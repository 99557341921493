import React from 'react'

const TextElement = (props) => {
  const { name, style } = props

  return (
    <div className="text-element-jsx">
      { props.children }

      <style jsx>{`
        .text-element-jsx {
          color: ${style[`${name}-color`]};
          text-align: ${style[`${name}-text-align`]};
          font-weight: ${style[`${name}-font-weight`]};
          font-style: ${style[`${name}-font-style`]};
          font-size: ${style[`${name}-font-size`]};
          text-decoration: ${style[`${name}-text-decoration`]};
        }

        @media (min-width: 1500px) {
          .text-element-jsx {
            font-size: ${style[`${name}-font-size-vw`]};
          }
        }
      `}</style>
    </div>
  )
}

export default TextElement
