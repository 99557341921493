const getParamsValue = (paramsWhere, query = {}) => {
  paramsWhere = paramsWhere || ''

  let splitWhere = paramsWhere.split(',')

  splitWhere.map((where, i) => {
    let split = where.split('=')

    if (split[0] && split[1]) {
      let attribute = split[0]
      let value = split[1]
      let valueSplit = value.split('!')

      if (valueSplit[0] === '' && valueSplit[1]) {
        let valueNe = convertValue(valueSplit[1])

        if (valueNe === 'null') {
          valueNe = null
        } else if (valueNe === 'undefined') {
          valueNe = undefined
        } else if  (valueNe === 'true') {
          valueNe = true
        } else if  (valueNe === 'false') {
          valueNe = false
        }

        query[attribute] = { $ne: valueNe }
      } else {

        if (value === 'null') {
          value = null
        } else if (value === 'undefined') {
          value = undefined
        } else if  (value === 'true') {
          value = true
        } else if  (value === 'false') {
          value = false
        } else {
          value = convertValue(value)
        }

        query[attribute] = value
      }
    }
  })

  return query
}


const convertValue = (value) => {
  if (value) {
    const regexNumber = /^number+\(+[0-9]+\)/g
    const regexNumberReplace = /number+\(|\)/g

    const regexObj = /^ObjectId+\(+[0-9a-z]+\)/g
    const regexObjReplace = /ObjectId+\(|\)/g

    if (value.search(regexNumber) !== -1) {
      value = value.replace(regexNumberReplace, '')
      value = parseFloat(value)

    } else if (value.search(regexObj) !== -1) {
      value = value.replace(regexObjReplace, '')
    }
  }

  return value
}

export default getParamsValue
