import { stateSearchDefault, stateSearch, stateUpdate } from '../reducerUtil'
import { ADD_TOURS, ADD_TOUR, UPDATE_TOUR, DELETE_TOUR } from './ToursActions'


// Initial State
const initialState = { 
  data: [],
  search: stateSearchDefault(),
}

const ToursReducer = (state=initialState, action) => {
  let actionDatas = action.tours
  let actionData = action.tour

  switch (action.type) {
    case ADD_TOURS:
      if (action.search) return stateSearch(state, action, actionDatas)
      else return { data: actionDatas, search: initialState.search }

    case ADD_TOUR:
      return { ...state, data: [actionData, ...state.data] }

    case DELETE_TOUR:
      return { 
        data: state.data.filter(data => data._id !== action._id)
      }
    
    case UPDATE_TOUR:
      return { ...state, data: stateUpdate(state, action, actionData) }

    default:
      return state
  }
}

export default ToursReducer
