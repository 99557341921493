import React, { Fragment } from 'react'

// Components Local
import TextElementManage from './components/TextElementManage'
import DivElementManage from './components/DivElementManage'
import ImgElementManage from './components/ImgElementManage'
import BtnElementManage from './components/BtnElementManage'
import InputElementManage from './components/InputElementManage';


const ElementStylesManage = (props) => {
  const { type, name } = props

  let title, icon

  if (type === 'text') {
    title = "ตัวอักษร"
    icon = "title"

  } else if (type === 'div') {
    title = "กล่อง"
    icon = "aspect_ratio"

  } else if (type === 'img') {
    title = "รูปภาพ"
    icon = "photo"

  } else if (type === 'btn') {
    title = "ปุ่มกด"
    icon = "crop_7_5"

  } else if (type === 'number-picker') {
    title = "ปุ่มลบและบวก"
    icon = "crop_7_5"
  }

  return (
    <Fragment>
      { type === 'text' &&
        <TextElementManage {...props} title={title} icon={icon} />
      }

      { type === 'div' &&
        <DivElementManage styleHover styleActive {...props} title={title} icon={icon} />
      }

      { type === 'img' &&
        <ImgElementManage styleHover {...props} title={title} icon={icon} />
      }

      { type === 'btn' &&
        <BtnElementManage styleHover styleActiveClick {...props} title={title} icon={icon} />
      }

      { type === 'input' &&
        <InputElementManage {...props} title={title} icon={icon} />
      }

      { type === 'number-picker' &&
        <Fragment>
          <DivElementManage {...props} name={`${name}-input`} title="อินพุดตัวเลข" icon="input" />
          <BtnElementManage {...props} name={`${name}-btn-minus`} title="ปุ่มลบ" icon="remove_circle_outline" />
          <BtnElementManage {...props} name={`${name}-btn-plus`} title="ปุ่มบวก" icon="add_circle_outline" />
        </Fragment>
      }
    </Fragment>
  )
}

export default ElementStylesManage
