import React, { Component, Fragment } from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
//import 'regenerator-runtime/runtime'

import renderRoutes from '../../../../../routes/renderRoutes'
import config from '../../../../../config'

// Compoents Local
import { WidgetsDisplay } from '../../../Widgets'
import { ContentGroupPageDisplay } from '../../../ContentGroupPage'

import StylesGlobal from '../StylesGlobal'
import PageHeader from './components/PageHeader'
import PageFooter from './components/PageFooter'

// Redux Actions
import { fetchProductOneDisplay } from '../../../../../redux/Products/ProductsActions'
import { fetchArticleDisplay } from '../../../../../redux/Articles/ArticlesActions'
import { fetchFolderDisplay } from '../../../../../redux/Folders/FoldersActions'
import { fetchTagDisplay } from '../../../../../redux/Tags/TagsActions'

// Values
import { productValue, getProductParams } from '../../../../../redux/Products/ProductsValues'
import { articleValue } from '../../../../../redux/Articles/ArticlesValues'

// js
import {
  checkAuthProfileRedirect,
  getContentGroupPage,
  useTemplate,
  handleScroll,
  setHeader,
  fetchProduct,
  fetchArticle,
  fetchFolder,
  fetchTag,
  fetchAuthUser,
  getPage,
} from './js/siteDisplay'

// css
import css from './css/siteDisplayContainer.css'

const s3Url = config.app.s3.urlOriginal


class SiteDisplayContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { match } = this.props
    const { products_id, articles_id, single_page_products_id, folders_path, tags_path } = match.params

    window.addEventListener('scroll', this.handleScroll)
    setHeader(this)

    // product
    if (products_id || single_page_products_id) {
      if (products_id) {
        fetchProduct(this, products_id)

      } else if (single_page_products_id) {
        fetchProduct(this, single_page_products_id)
      }

    // article
    } else if (articles_id) {
      fetchArticle(this, articles_id)

    // folder
    } else if (folders_path) {
      fetchFolder(this, folders_path)

    // tag
    } else if (tags_path) {
      fetchTag(this, tags_path)

    // customer
    } else if (match.path === '/profile/:path_1?') {
      fetchAuthUser(this)

    } else {
      const page = getPage(this)

      if (page && page.fetchType && page.fetchId) {
        if (page.fetchType === 'product-details') {
          fetchProduct(this, page.fetchId)
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      setHeader(this)

      const { match } = this.props
      const { folders_path, tags_path } = match.params

      if (folders_path) {
        fetchFolder(this, folders_path)

      } else if (tags_path) {
        fetchTag(this, tags_path)
      } else {
        const page = getPage(this)

        if (page && page.fetchType && page.fetchId) {
          if (page.fetchType === 'product-details') {
            fetchProduct(this, page.fetchId)
          }
        }
      }
    }

    /*if (this.props.auth.user !== prevProps.auth.user) {

      console.log(this.props.auth.user)

      fetchAuthUser(this)
    }*/

    this.handleScroll()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    handleScroll(this)
  }

  /*shouldComponentUpdate(nextProps, nextState) {
    return this.props.match.url !== nextProps.match.url
  }*/

  /*shouldComponentUpdate(nextProps, nextState) {
    return this.props.auth !== nextProps.auth ||
           this.props.singlePageData !== nextProps.singlePageData ||
           this.props.singlePageType !== nextProps.singlePageType ||
           this.props.product !== nextProps.product ||
           this.props.article !== nextProps.article ||
           this.props.folder !== nextProps.folder ||
           this.props.tag !== nextProps.tag ||
           this.props.match.url !== nextProps.match.url ||
           this.props.app.modals.length !== nextProps.app.modals.length
  }*/

  render() {
    const { business, setting, pages, site, singlePageData, singlePageType } = this.props
    const { heightHeader } = this.state
    const { page, templateData, template } = useTemplate(this)

    checkAuthProfileRedirect(this, { page, templateData })

    const image = site.images.filter(img => img.album === 'favicon')[0]
    const path = image && image.paths && image.paths.o
    const favicon = path && `${s3Url}/${path}`
    const contentGroupPage = getContentGroupPage(this)

    return (
      <Fragment>
        <Helmet>
          { favicon &&
            <link rel="shortcut icon" type="image/png" href={favicon} />
          }
        </Helmet>

        <StylesGlobal site={site} />

        <div id="site-container" className={css.container}>
          { contentGroupPage ?
            <PageHeader
              {...this.props}
              css={css}
              page={page}
              pageHeader={contentGroupPage}
              templateData={templateData}
              contentGroupPage={contentGroupPage}
            />
          :
            <PageHeader
              {...this.props}
              css={css}
              page={page}
              pageHeader={page}
              templateData={templateData}
            />
          }

          { page &&
            <div
              id="page-content-display"
              className={css.content}
              style={{
                paddingTop: heightHeader
              }}>

              { contentGroupPage ?
                <ContentGroupPageDisplay
                  {...this.props}
                  groupId={contentGroupPage._id}
                  datas={{
                    business,
                    setting,
                    site,
                    pages,
                    pageCurrent: page,
                    singlePageData,
                    singlePageType,
                    templateData
                  }}
                />
              :
                <Fragment>
                  { (page && !template) || (page && template && templateData) ?
                    <Fragment>
                      { renderRoutes({
                        routes: this.props.route.routes,
                        extraProps: {
                          business,
                          setting,
                          site,
                          pages,
                          page,
                          templateData
                        },
                        location: this.props.location,
                      }) }
                    </Fragment>
                  :
                    <h1>404 Not Found</h1>
                  }
                </Fragment>
              }
            </div>
          }

          <div id="widgets">
            { page &&
              <WidgetsDisplay
                {...this.props}
                datas={{
                  business,
                  setting,
                  site,
                  pages,
                  pageCurrent: page,
                  singlePageData,
                  singlePageType,
                  templateData
                }}
              />
            }
          </div>

          { contentGroupPage ?
            <PageFooter
              {...this.props}
              css={css}
              page={page}
              pageFooter={contentGroupPage}
              templateData={templateData}
              contentGroupPage={contentGroupPage}
            />
          :
            <PageFooter
              {...this.props}
              css={css}
              page={page}
              pageFooter={page}
              templateData={templateData}
            />
          }

          { site.fbCustomerChat && site.fbCustomerChat.status === 'open' && site.fbCustomerChat.page_id &&
            <div
              className="fb-customerchat"
              attribution="setup_tool"
              page_id={site.fbCustomerChat.page_id}
              logged_in_greeting="สวัสดี มีอะไรให้เราช่วยไหม"
              logged_out_greeting="สวัสดี มีอะไรให้เราช่วยไหม">
            </div>
          }
        </div>
      </Fragment>
    )
  }
}

SiteDisplayContainer.fetchData = (store, match) => {
  const state = store.getState()
  const host = state.host
  const {
    products_id,
    articles_id,
    single_page_products_id,
    folders_path,
    tags_path,
    dbProductsNo,
    successed_orders_id,
    orders_id
  } = match.params


  // fetch product
  if (products_id || single_page_products_id) {
    const _id = products_id || single_page_products_id
    const params = getProductParams(_id)

    if (params.products_id && params.products_id !== 'm') {
      return store.dispatch(fetchProductOneDisplay(dbProductsNo || 'not-use-db', params.products_id))
    }

  // fetch article
  } else if (articles_id) {
    return store.dispatch(fetchArticleDisplay(articles_id))

  // fetch folder
  } else if (folders_path) {
    let type = "product"

    if (match.path === '/article-categories/:folders_path') {
      type = "article"
    }

    return store.dispatch(fetchFolderDisplay(folders_path, {
      body: {
        ...host,
        where: `type=${type}`,
      }
    }))

  // fetch folder
  } else if (tags_path) {
    let type = "product"

    if (match.path === '/article-tags/:tags_path') {
      type = "article"
    }

    return store.dispatch(fetchTagDisplay(tags_path, {
      body: {
        ...host,
        where: `type=${type}`,
      }
    }))
  }
}


const mapStateToProps = (store, props) => {
  const { site, customer, match } = props
  const { pageType, products_id, articles_id, single_page_products_id, folders_path, tags_path } = match.params


  let product, article, folder, tag, singlePageData, singlePageType

  if (pageType) {
    if (pageType === 'product-details') {
      const _id = products_id
      const params = getProductParams(_id)

      product = store.products.data.filter(product => product._id === params.products_id)[0]
      product = productValue({ product, customer, items_id: params.products_items_id })
    }

  } else {
    if (products_id || single_page_products_id) {
      const _id = products_id || single_page_products_id
      const params = getProductParams(_id)

      product = store.products.data.filter(product => product._id === params.products_id)[0]
      product = productValue({ product, customer, items_id: params.products_items_id })

      if (single_page_products_id) {
        singlePageData = product
        singlePageType = 'product'
      }

    } else if (articles_id) {
      article = store.articles.data.filter(article => article._id === articles_id)[0]
      article = articleValue({ article })

    } else if (folders_path) {
      folder = store.folders.data.filter(folder => folder.path === folders_path)[0]

    } else if (tags_path) {
      tag = store.tags.data.filter(tag => tag.path === tags_path)[0] || {}
    }
  }

  return {
    app: store.app,
    host: store.host,
    auth: store.auth,
    business: store.businesses.data[0],
    customer: store.customers && store.customers.data && store.customers.data[0],
    setting: store.settings.data[0],
    pages: store.pages.data.filter((page => page.sites_id === site._id)),
    singlePageData,
    singlePageType,
    product,
    products: store.products.data,
    article,
    folder,
    tag
  }
}

export default connect(mapStateToProps)(SiteDisplayContainer)
