import React, { Fragment } from 'react'

// Components Local
import InputStyle from './InputStyle'
import TextStyle from './TextStyle'

const InputElementManage = (props) => {
  const { title, name, label, styleFocus } = props

  return (
    <div>
      { label ?
        <Fragment>
          <TextStyle {...props} />
        </Fragment>
      :
        <Fragment>
          <InputStyle {...props} />

          <InputStyle {...props} event="focus" title={`${title}เมื่อโฟกัส (focus)`} name={`${name}-focus`} />
          <InputStyle {...props} event="valid" title={`${title}เมื่อถูกต้อง (valid)`} name={`${name}-valid`} />
          <InputStyle {...props} event="invalid" title={`${title}เมื่อไม่ถูกต้อง (invalid)`} name={`${name}-invalid`} />
        </Fragment>
      }
    </div>
  )
}

export default InputElementManage
