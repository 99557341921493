export const stateSearchDefault = () => {
  return { status: false, value: '', data: [], count: 0, searchParams: [] }
}

export const stateAddOneData = (state: any, action: any, actionData: any) => {
  return addOne({ state, action, actionData })
}

export const stateAddReplaceOneData = (state: any, action: any, actionData: any) => {
  return addReplaceOne({ state, action, actionData })
}

export const stateAddsManyDatas = (state: any, action: any, actionDatas: any) => {
  return addsMany({ state, action, actionDatas })
}

export const stateAddsManyMoreDatas = (state: any, action: any, actionDatas: any) => {
  return addsMany({ state, action, actionDatas, addsMore: true })
}

export const stateUpdateOneData = (state: any, action: any, actionData: any) => {
  return updateOne({ state, action, actionData })
}

export const stateRemoveOneData = (state: any, action: any, actionData: any) => {
  return removeOne({ state, action, actionData })
}


const getStateDatas = (state: any) => {
  let storeDatas = state && state.data && state.data.slice()
  let searchDatas = state && state.search && state.search.data && state.search.data.slice()
  let storeDataGroups = state && state.dataGroups && state.dataGroups.slice()

  return {
    storeDatas,
    storeDataGroups,
    searchDatas
  }
}

const getIndexGroup = (dataGroups: any, groupId: any) => {
  return dataGroups && dataGroups.findIndex((g: any) => g.groupId === groupId)
}

const setSearchDatas = ({
  search,
  addsMore,
  actionDatas,
  searchDatas
}: {
  search: any,
  addsMore: any,
  actionDatas: any,
  searchDatas: any
}) => {
  if (addsMore) {
    searchDatas = searchDatas.concat(actionDatas)
  } else {
    searchDatas = actionDatas
  }

  return {
    status: search.status,
    value: search.value,
    data: searchDatas,
    count: search.count || 0,
    params: search.params
  }
}

const setDatas = ({
  addsMore,
  actionDatas,
  datas
}: {
  addsMore: any,
  actionDatas: any,
  datas: any
}) => {
  if (addsMore) {
    datas = datas.concat(actionDatas)
  } else {
    datas = actionDatas
  }

  return datas
}

const setSearchDataOne = ({
  search,
  actionData,
  searchDatas,
  order="first"
}: {
  search: any,
  actionData: any,
  searchDatas: any,
  order: any
}) => {
  if (order === 'first') {
    searchDatas = [actionData, ...searchDatas]
  } else if (order === 'last') {
    searchDatas = [...searchDatas, actionData]
  }

  return {
    status: search.status,
    value: search.value,
    data: searchDatas,
    count: search.count || 0,
    params: search.params
  }
}

const setDataOne = ({
  actionData,
  datas,
  order="first"
}: {
  actionData: any,
  datas: any,
  order: any
}) => {
  if (order === 'first') {
    datas = [actionData, ...datas]
  } else if (order === 'last') {
    datas = [...datas, actionData]
  }

  return datas
}

const updateDataOne = ({
  actionData,
  datas,
  updateId,
  updateCond,
  notFoundAddData,
  sort
}: {
  actionData: any,
  datas: any,
  updateId: any,
  updateCond: any,
  notFoundAddData: any,
  sort?: any
}) => {
  if (updateId) {
    const index = datas.findIndex((data: any) => data._id === updateId)

    if (index !== -1) {
      datas[index] = actionData

    } else if (notFoundAddData) {
      notFoundAddData()
    }

  } else if (updateCond) {
    const index = datas.findIndex((data: any) => updateCond(data))

    if (index !== -1) {
      datas[index] = actionData
    } else if (notFoundAddData) {
      notFoundAddData()
    }
  }

  if (sort) {
    datas.sort((a: any, b: any) => sort(a, b))
  }

  return datas
}

const updateSearchDataOne = ({
  search,
  actionData,
  searchDatas,
  updateId,
  updateCond,
  notFoundAddData
}: {
  search: any,
  actionData: any,
  searchDatas: any,
  updateId: any,
  updateCond: any,
  notFoundAddData: any
}) => {
  if (updateId) {
    const index = searchDatas.findIndex((data: any) => data._id === updateId)

    if (index !== -1) {
      searchDatas[index] = actionData
    } else if (notFoundAddData) {
      notFoundAddData()
    }

  } else if (updateCond) {
    const index = searchDatas.findIndex((data: any) => updateCond(data))

    if (index !== -1) {
      searchDatas[index] = actionData
    } else if (notFoundAddData) {
      notFoundAddData()
    }
  }

  return {
    status: search.status,
    value: search.value,
    data: searchDatas,
    count: search.count || 0,
    params: search.params
  }
}

const removeDataOne = ({
  datas,
  removeId,
  removeCond
}: {
  datas: any,
  removeId: any,
  removeCond: any
}) => {
  if (removeId) {
    const index = datas.findIndex((data: any) => data._id === removeId)

    if (index !== -1) {
      datas.splice(index, 1)
    }

  } else if (removeCond) {
    const index = datas.findIndex((data: any) => removeCond(data))

    if (index !== -1) {
      datas.splice(index, 1)
    }
  }

  return datas
}

const removeSearchDataOne = ({
  search,
  searchDatas,
  removeId,
  removeCond
}: {
  search: any,
  searchDatas: any,
  removeId: any,
  removeCond: any
}) => {
  if (removeId) {
    const index = searchDatas.findIndex((data: any) => data._id === removeId)

    if (index !== -1) {
      searchDatas.splice(index, 1)
    }

  } else if (removeCond) {
    const index = searchDatas.findIndex((data: any) => removeCond(data))

    if (index !== -1) {
      searchDatas.splice(index, 1)
    }
  }

  return {
    status: search.status,
    value: search.value,
    data: searchDatas,
    count: search.count || 0,
    params: search.params
  }
}

const addOne = ({
  state,
  action,
  actionData
}: {
  state: any,
  action: any,
  actionData: any
}) => {
  const { params } = action
  const { search } = params
  const { store } = params || {}
  const order = store && store.addOrder || 'first'

  let { storeDatas, storeDataGroups, searchDatas } = getStateDatas(state)
  let searchValue: any = stateSearchDefault()

  // for groupId
  if (store && store.groupId) {
    const groupIndex = getIndexGroup(storeDataGroups, store.groupId)

    // by index
    if (groupIndex !== -1) {

      // for search
      if (search) {
        if (storeDataGroups) {
          storeDataGroups[groupIndex].search = setSearchDataOne({
            search,
            actionData,
            searchDatas: storeDataGroups[groupIndex].search.data,
            order
          })
        }

      // for data
      } else {
        if (storeDataGroups) {
          storeDataGroups[groupIndex].search = stateSearchDefault()
          storeDataGroups[groupIndex].data = setDataOne({
            actionData,
            datas: storeDataGroups[groupIndex].data,
            order
          })
        }
      }

    // add new
    } else {
      storeDataGroups.push({
        groupId: store.groupId,
        data: actionData,
        search: stateSearchDefault()
      })
    }

  // no groupId
  } else {
    if (search) {
      searchValue = setSearchDatas({ search, addsMore: false, actionDatas: [actionData], searchDatas })
    } else {
      storeDatas = setDataOne({
        actionData,
        datas: storeDatas,
        order
      })
    }
  }

  return {
    data: storeDatas,
    dataGroups: storeDataGroups || [],
    search: searchValue
  }
}

// addReplaceOne
const addReplaceOne = ({
  state,
  action,
  actionData
}: {
  state: any,
  action: any,
  actionData: any
}) => {
  const notFoundAddData = addOne({ state, action, actionData })
  return updateOne({ state, action, actionData, notFoundAddData })
}

//updateOne
const updateOne = ({
  state,
  action,
  actionData,
  notFoundAddData
}: {
  state: any,
  action: any,
  actionData: any,
  notFoundAddData?: any
}) => {
  const { params, updateId, updateCond } = action
  const { search } = params
  const { store } = params || {}

  let { storeDatas, storeDataGroups, searchDatas } = getStateDatas(state)
  let searchValue: any = stateSearchDefault()

  // for groupId
  if (store && store.groupId) {
    const groupIndex = getIndexGroup(storeDataGroups, store.groupId)

    // by index
    if (groupIndex !== -1) {
      // for search
      if (search) {
        if (storeDataGroups) {
          storeDataGroups[groupIndex].search = updateSearchDataOne({
            search,
            actionData,
            searchDatas: storeDataGroups[groupIndex].search.data,
            updateId,
            updateCond,
            notFoundAddData
          })
        }

      // for data
      } else {
        if (storeDataGroups) {
          storeDataGroups[groupIndex].search = stateSearchDefault()
          storeDataGroups[groupIndex].data = updateDataOne({
            actionData,
            datas: storeDataGroups[groupIndex].data,
            updateId,
            updateCond,
            notFoundAddData
          })
        }
      }
    }

  // no groupId
  } else {
    if (search) {
      searchValue = setSearchDatas({ search, addsMore: false, actionDatas: [actionData], searchDatas })
    } else {
      storeDatas = updateDataOne({
        actionData,
        datas: storeDatas,
        updateId,
        updateCond,
        notFoundAddData,
        sort: store && store.sort
      })
    }
  }

  return {
    data: storeDatas,
    dataGroups: storeDataGroups || [],
    search: searchValue
  }
}

// removeOne
const removeOne = ({
  state,
  action,
  actionData
}: {
  state: any,
  action: any,
  actionData: any
}) => {
  const { params, removeId, removeCond } = action
  const { search } = params
  const { store } = params || {}

  let { storeDatas, storeDataGroups, searchDatas } = getStateDatas(state)
  let searchValue: any = stateSearchDefault()

  // for groupId
  if (store && store.groupId) {
    const groupIndex = getIndexGroup(storeDataGroups, store.groupId)

    // by index
    if (groupIndex !== -1) {
      // for search
      if (search) {
        if (storeDataGroups) {
          storeDataGroups[groupIndex].search = removeSearchDataOne({
            search,
            searchDatas: storeDataGroups[groupIndex].search.data,
            removeId,
            removeCond
          })
        }

      // for data
      } else {
        if (storeDataGroups) {
          storeDataGroups[groupIndex].search = stateSearchDefault()
          storeDataGroups[groupIndex].data = removeDataOne({
            datas: storeDataGroups[groupIndex].data,
            removeId,
            removeCond
          })
        }
      }
    }

  // no groupId
  } else {
    if (search) {
      searchValue = setSearchDatas({ search, addsMore: false, actionDatas: [actionData], searchDatas })
    } else {
      storeDatas = removeDataOne({
        datas: storeDatas,
        removeId,
        removeCond
      })
    }
  }

  return {
    data: storeDatas,
    dataGroups: storeDataGroups || [],
    search: searchValue
  }
}

const addsMany = ({
  state,
  action,
  actionDatas,
  addsMore
}: {
  state: any,
  action: any,
  actionDatas: any,
  addsMore?: any
}) => {
  const { params } = action
  const { search } = params
  const { store } = params || {}

  let { storeDatas, storeDataGroups, searchDatas } = getStateDatas(state)
  let searchValue: any = stateSearchDefault()

  // for groupId
  if (store && store.groupId) {
    const groupIndex = getIndexGroup(storeDataGroups, store.groupId)

    // by index
    if (groupIndex !== -1) {

      // for search
      if (search) {
        if (storeDataGroups) {
          storeDataGroups[groupIndex].search = setSearchDatas({
            search,
            addsMore,
            actionDatas,
            searchDatas: storeDataGroups[groupIndex].search.data
          })
        }

      // for data
      } else {
        if (storeDataGroups) {
          storeDataGroups[groupIndex].search = stateSearchDefault()
          storeDataGroups[groupIndex].data = setDatas({
            addsMore,
            actionDatas,
            datas: storeDataGroups[groupIndex].data
          })
        }

        // search is undefined
        /*if (storeDataGroups[groupIndex].search == undefined) {
          storeDataGroups[groupIndex].search = stateSearchDefault()
        }*/
      }

    // add new
    } else {
      storeDataGroups.push({
        groupId: store.groupId,
        data: actionDatas,
        search: stateSearchDefault()
      })
    }

  // no groupId
  } else {
    if (search) {
      searchValue = setSearchDatas({ search, addsMore, actionDatas, searchDatas })
    } else {
      storeDatas = setDatas({
        addsMore,
        actionDatas,
        datas: storeDatas
      })
    }
  }

  return {
    data: storeDatas,
    dataGroups: storeDataGroups || [],
    search: searchValue
  }
}
