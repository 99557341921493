import React, { Suspense } from 'react'

const ReactSuspense = (props) => {
  if (typeof window !== 'undefined') {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        { props.children }
      </Suspense>
    )
  } else {
    return (
      <div></div>
    )
  }
}

export default ReactSuspense
