import React from 'react'
import { connect } from 'react-redux'

// Components Local
import Breadcrumbs from './components/Breadcrumbs'

// Components Editor
import BlockStyle from '../components/BlockStyle'


const TextEditorBreadcrumbsDisplay = (props) => {
  const { lang } = props
  const content = props.content
  const breadcrumbs = content.breadcrumbs && JSON.parse(content.breadcrumbs)
  const style = breadcrumbs && breadcrumbs.style ? JSON.parse(breadcrumbs.style) : {}
  const className = breadcrumbs && breadcrumbs.class ? JSON.parse(breadcrumbs.class) : []
  const items = breadcrumbs && breadcrumbs.items ? JSON.parse(breadcrumbs.items) : []

  return (
    <BlockStyle {...props} style={style} className={className}>
      <Breadcrumbs
        {...props}
        display
        lang={lang}
        className={className}
        style={style}
        items={items}
      />
    </BlockStyle>
  )
}

export default connect()(TextEditorBreadcrumbsDisplay)
