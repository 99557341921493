import React, { Component } from 'react'

// Components Global
import RadioGroup from '../../../../Inputs/RadioGroup'

// Functions
import { addStyle, removeStyle } from '../../../functions/textEditorFunctions'


class RadioGroupStyleSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  change = (value) => {
    const { _this, styleName } = this.props

    if (value === '') {
      removeStyle(_this, styleName)
    } else {
      addStyle(_this, {
        style: styleName,
        value
      })
    }
  }

  reset = () => {
    let { _this, styleName } = this.props

    if (styleName) {
      removeStyle(_this, styleName)
    }
  }

  render() {
    let { _this, styleName, label, radios, defaultValue } = this.props
    const value = _this.state.style[styleName]
    defaultValue = defaultValue || ''

    return (
      <div className="pd-10">
        <div className="pd-0 font-0-8">{label}</div>
        <div className="pd-0 mg-top-10">
          <RadioGroup
            notSave
            id={styleName}
            value={value || defaultValue}
            styleLabel={{
              fontSize: '0.8rem'
            }}
            onChange={this.change}
            radios={radios}
          />
        </div>
      </div>
    )
  }
}

export default RadioGroupStyleSettings
