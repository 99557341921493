import React from 'react'

// css
import css from './css/boxTools.css'

const BoxTools = (props) => {
  const { openTools, style, onClick, children } = props

  return (
    <div className={css.boxTools}>
      <div
        className={css.tools}
        onClick={onClick}
        style={{
          ...style,
          opacity: openTools === true && '1',
          visibility: openTools === true && 'visible'
        }}>

        { children }
      </div>
    </div>
  )
}

export default BoxTools
