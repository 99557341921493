import apiCaller from 'client/core/utils/apiCaller'
import { checkAppActions, errorAlert } from 'core/redux/App/AppActions'
import { Dispatch } from 'react'
import "regenerator-runtime/runtime"



const AddMultipleToStore = ({
  datas, addMultiple, dispatch
}: {
  datas: any[],
  addMultiple: any[],
  dispatch: Dispatch<any>
}) => {
  addMultiple.map(addSingle => {
    const data = datas.filter(data => data.storeName === addSingle.storeName)[0]

    if (data) {
      dispatch(addSingle.add(data.value))
    }
  })
}

const AddRefsToStore = ({
  data,
  addRefs,
  dispatch,
} : {
  data: any,
  addRefs: any[],
  dispatch: Dispatch<any>
}) => {
  addRefs.map(ref => {
    let dataRef = data[ref.element]
    dataRef = dataRef && dataRef._id && dataRef

    if (dataRef) {
      dataRef = [dataRef]

      dispatch(ref.adds(dataRef))
    }
  })
}

export const addsDatasMany = async ({
  dispatch,
  params,
  api,
  clean,
  adds_many,
  adds_many_more
}: {
  dispatch: Dispatch<any>,
  params: any,
  api: string,
  clean?: boolean,
  adds_many: Function,
  adds_many_more: Function
}) => {
  if (clean) {
    dispatch(adds_many({ datas: [], params }))
  } else {
    checkAppActions(dispatch, params, 'start')

    return apiCaller(api, 'get', params.body).then((res: any) => {
      if (res.success) {
        const datas = res.data

        if (params.search && res.count) {
          params.search.count = res.count
        }

        if (!params.notSaveStore) {
          if (params.addsManyMore) {
            dispatch(adds_many_more({ datas, params }))
          } else {
            dispatch(adds_many({ datas, params }))
          }
        }

        checkAppActions(dispatch, params, 'success', res)
      } else {
        if (!params.notShowErrorAlert) {
          dispatch(errorAlert(res))
        }

        checkAppActions(dispatch, params, 'error')
      }

      return res
    })
  }
}

export const addDataOne = async ({
  dispatch,
  params,
  api,
  add_one,
  addMultiple,
  addRefs
}: {
  dispatch: any,
  params: any,
  api: string,
  add_one: Function,
  addMultiple?: any[],
  addRefs?: any[],
}) => {
  checkAppActions(dispatch, params, 'start')

  return apiCaller(api, 'put', params.body).then((res: any) => {
    if (res.success) {
      const data = res.data

      if (!params.notSaveStore) {
        if (addMultiple) {
          AddMultipleToStore({
            dispatch,
            addMultiple,
            datas: res.datas
          })

        } else if (addRefs) {
          dispatch(add_one({ data, params }))

          AddRefsToStore({
            dispatch,
            addRefs,
            data
          })

        } else {
          dispatch(add_one({ data, params }))
        }
      }


      checkAppActions(dispatch, params, 'success', res)
    } else {

      if (!params.notShowErrorAlert) {
        dispatch(errorAlert(res))
      }

      checkAppActions(dispatch, params, 'error')
    }

    return res
  })
}

export const fetchUpdateDataOne = async ({
  dispatch, params, api, update_one, updateId, updateCond
}: {
  dispatch: Dispatch<any>,
  params: any,
  api: string,
  update_one: Function,
  updateId: string,
  updateCond?: Function
}) => {
  checkAppActions(dispatch, params, 'start')

  return apiCaller(api, 'get', params.body).then((res: any) => {
    if (res.success) {
      const data = res.data

      if (!params.notSaveStore) {
        dispatch(update_one({ data, updateId, updateCond, params }))
      }

      checkAppActions(dispatch, params, 'success', res)
    } else {

      if (!params.notShowErrorAlert) {
        dispatch(errorAlert(res))
      }

      checkAppActions(dispatch, params, 'error')
    }

    return res
  })
}

export const updateDataOne = async ({
  dispatch, params, api, update_one, updateId, updateCond
}: {
  dispatch: Dispatch<any>,
  params: any,
  api: string,
  update_one: Function,
  updateId: string,
  updateCond?: Function
}) => {
  checkAppActions(dispatch, params, 'start')

  return apiCaller(api, 'put', params.body).then((res: any) => {
    if (res.success) {
      const data = res.data

      if (!params.notSaveStore) {
        dispatch(update_one({ data, updateId, updateCond, params }))
      }

      checkAppActions(dispatch, params, 'success', res)
    } else {

      if (!params.notShowErrorAlert) {
        dispatch(errorAlert(res))
      }

      checkAppActions(dispatch, params, 'error')
    }

    return res
  })
}

export const removeDataOne = async ({
  dispatch, params, api, remove_one, removeId, removeCond
}: {
  dispatch: Dispatch<any>,
  params: any,
  api: string,
  remove_one: Function,
  removeId: string,
  removeCond?: Function
}) => {
  checkAppActions(dispatch, params, 'start')

  return apiCaller(api, 'delete', params.body).then((res: any) => {
    if (res.success) {
      dispatch(remove_one({ removeId, removeCond, params }))
      checkAppActions(dispatch, params, 'success', res)
    } else {
      dispatch(errorAlert(res))
      checkAppActions(dispatch, params, 'error')
    }

    return res
  })
}

export const updateApi = async ({
  dispatch,
  params={},
  api
}: {
  dispatch: Dispatch<any>,
  params: any,
  api: string
}) => {
  checkAppActions(dispatch, params, 'start')

  return apiCaller(api, 'put', params.body).then((res: any) => {
    if (res.success) {
      if (!params.notStore) {
        checkAppActions(dispatch, params, 'success', res)
      }
    } else {
      dispatch(errorAlert(res))
      checkAppActions(dispatch, params, 'error')
    }

    return res
  })
}

export const fetchApi = async ({
  dispatch,
  params={},
  api
}: {
  dispatch: Dispatch<any>,
  params: any,
  api: string
}) => {
  checkAppActions(dispatch, params, 'start')

  return apiCaller(api, 'get', params.body).then((res: any) => {
    if (res.success) {
      checkAppActions(dispatch, params, 'success', res)
    } else {
      dispatch(errorAlert(res))
      checkAppActions(dispatch, params, 'error')
    }

    return res
  })
}




