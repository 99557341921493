import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import TextEditorImageGallery from './TextEditorImageGallery'
import TextEditorImageGalleryDisplay from './TextEditorImageGalleryDisplay'

let TextEditorImageGallery = lazy(() => import('./TextEditorImageGallery'))
TextEditorImageGallery = suspenseComponent(TextEditorImageGallery)

export {
  TextEditorImageGallery,
  TextEditorImageGalleryDisplay
}
