import React, { Component } from 'react'

import LinkManage from '../../LinkManage'

// Functions
import { addStyle, removeStyle } from '../../../functions/textEditorFunctions'

// css
import css from '../css/styleSettings.css'


class LinkSaveStyleSetting extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.getValue()
    }
  }

  getValue = () => {
    const { _this } = this.props
    const { style } = _this.state
    const styleName = this.props.styleName || 'link'
    const link = style[styleName]

    return {
      link
    }
  }

  handleValueChange = (link) => {
    let { _this, styleName } = this.props
    styleName = styleName || 'link'

    addStyle(_this, {
      style: styleName,
      value: link
    })
  }

  reset = () => {
    let { _this, styleName } = this.props
    styleName = styleName || 'link'

    if (styleName) {
      this.setState({ link: '' }, () => {
        removeStyle(_this, styleName)
      })
    }
  }

  render() {
    const { label } = this.props
    const { link } = this.state

    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td className="pd-0 font-0-8">{ label || 'จัดการลิงก์' }</td>
              <td className="pd-0 font-0-7 text-right">
                <a className={css.btn} onClick={this.reset}>รีเซ็ท</a>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="pd-top-10">
          <LinkManage
            value={link}
            onChange={this.handleValueChange}
          />
        </div>
      </div>
    )
  }
}

export default LinkSaveStyleSetting
