import React, { Fragment } from 'react'

// Components Global
import Icon from '../../../../../Icon'

// Components Text
import LinkDisplay from '../../../LinkDisplay'
import BadgeDisplay from '../../../BadgeDisplay'
import { BtnElement, BtnSubmitElement } from '../../../ElementStyles'


// Functions
import { generateClassToStringByGroup } from '../../../../functions/textEditorFunctions'


const ButtonDisplay = (props) => {
  let { name, lang, link, apiData, templateData, dataStore, style, mode, className, styleProps, closeWindowModal } = props
  const icon = props.icon && JSON.parse(props.icon)

  style = style || {}

  const notDisabled = style['btn-not-disabled']
  const badgeShow = style['badge-show']
  const iconOnly = style['btn-icon-only']
  const iconPosition = style['btn-icon-position'] || 'left'

  let position = ''

  if (!iconOnly) {
    position = iconPosition
  }

  const iconStyle = {
    color: style['button-icon-color'],
    fontSize: style['button-icon-size'],
    fontSizeVw: style['button-icon-size-vw'],
    margin: style['button-icon-margin'],
    marginVw: style['button-icon-margin-vw']
  }

  let linkParse

  if (typeof link === 'string') {
    linkParse = link && JSON.parse(link)
  } else {
    linkParse = link ? link : {}
  }


  return (
    <Fragment>
      { linkParse.type === 'submit' && linkParse.formId ?
        <BtnSubmitElement
          id={linkParse.formId}
          disabled
          notDisabled={notDisabled}
          name="button"
          className={className}
          text={name}
          iconOnly={iconOnly}
          mode={mode}
          icon={icon.type === 'material-icons' && icon.name}
          iconFa={icon.type === 'font-awesome' && icon.name}
          style={style}
        />
      :
        <LinkDisplay
          rerender={{
            lang,
            name,
            badgeShow,
            icon: JSON.stringify(icon),
            style: JSON.stringify(style)
          }}
          btn
          style={style}
          value={link}
          mode={mode}
          apiData={apiData}
          templateData={templateData}
          closeWindowModal={closeWindowModal}
          dataStore={dataStore}>

          <BtnElement
            name="button"
            mode={mode}
            className={`btn btn-shadow-none ${generateClassToStringByGroup(className, 'button')}`}
            style={style}>

            { icon &&
              <Icon
                type={icon.type}
                style={iconStyle}
                className={`i-middle ${position} ${icon.type === 'material-icons' ? 'font-1-5' : ''} ${generateClassToStringByGroup(className, 'button-icon')}`}>

                { icon.name }
              </Icon>
            }

            { !iconOnly &&
              <Fragment>
                { name ? name : 'คลิกเพื่อแก้ไข...' }
              </Fragment>
            }

            { badgeShow &&
              <BadgeDisplay
                style={style}
                value={link}
                mode={mode}
                apiData={apiData}
                templateData={templateData}
                dataStore={dataStore}
              />
            }

          </BtnElement>
        </LinkDisplay>
      }
    </Fragment>
  )
}

export default ButtonDisplay
