import { SIGN_UP, SIGN_IN, SIGN_OUT, GET_AUTH } from './AuthActions'

// Initial State
const initialState = {
  user: 'unauthorized'
}

const AuthReducer = (state=initialState, action) => {
  switch (action.type) {
    case SIGN_IN:
      return { ...state, user: action.auth }

    case SIGN_UP:
      return { ...state, user: action.auth }

    case SIGN_OUT:
      return { ...state, user: action.auth }

    case GET_AUTH:
      return { ...state, user: action.auth }

    default:
      return state
  }
}

// Export Reducer
export default AuthReducer
