import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import config from '../../../config'

// css
import css from './css/siteStructure.css'

const s3Url = config.app.s3.urlOriginal

const SiteStructure = (props) => {
  const { site, title, titleLink, params, match } = props
  const { structure } = site
  const year = new Date().getFullYear()

  const image = site.images.filter(img => img.album === 'logo')[0]
  const path = image && image.paths && image.paths.o
  const logo = path && `${s3Url}/${path}`
  const salePage = params && params.salePage

  let domain, linkHome

  if (site.domain) {
    domain = domain
  } else {
    domain = 'www.addner.com'
  }




  return (
    <div className={`${css.container}`}>
      <header className={`${css.header}`}>
        <div className={`height-25`} style={{ background: structure.color, color: structure.colorContrast }}>
          <div className="pd-left-10 pd-right-10">
            <div className="editor-container-wide">

              <div className="row line-height-0 mg-bottom-0">
                <div className="line-height-0 center-xxs center-xs column col-xxs-12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xlg-6">
                  { !salePage &&
                    <ul className="mg-0 font-12 dp-inline-block line-height-25">
                      <li className="float-left">
                        <i className="material-icons float-left i-middle font-15 mg-right-5">call</i> {structure.tel}
                      </li>
                    </ul>
                  }
                </div>
                <div className="text-right hidden-xxs hidden-xs line-height-0 column col-xxs-12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xlg-6">
                  { !salePage &&
                    <ul className="mg-0 font-12 dp-inline-block line-height-25">
                      <li className="float-left pd-left-10">
                        <Link to="/about" style={{ color: structure.colorContrast }}>
                          <i className="material-icons float-left i-middle font-15 mg-right-5">info</i> เกี่ยวกับ
                        </Link>
                      </li>
                      <li className="float-left pd-left-10">
                        <Link to="/contact" style={{ color: structure.colorContrast }}>
                          <i className="material-icons float-left i-middle font-15 mg-right-5">call</i> ติดต่อ
                        </Link>
                      </li>
                    </ul>
                  }
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className={`height-55 ${css.bottomHeader}`}>
          <div className="editor-container-wide">
            <div className="pd-left-10 pd-right-10">
              <div className={`${css.boxLogo}`}>
                <Link to={!salePage && '/'}>
                  <img className="height-35 width-auto mg-top-10" src={logo} />
                </Link>
              </div>
              <div className={`${css.boxTitle} hidden-xxs hidden-xs pd-left-20`}>
                <div
                  className={`border-left-2 pd-left-20 height-35 line-height-35 mg-top-10 font-20`}
                  style={{
                    color: structure.color,
                    borderLeftColor: structure.color
                  }}>
                    { titleLink ?
                      <Link to={titleLink}>{title}</Link>
                    :
                      title
                    }
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className={`${css.content} pd-top-80`}>
        { props.children }
      </div>

      <footer className={css.footer}>
        <div className={`center ${css.boxFooter}`}>
          <div className="editor-container-wide pd-5">
            <span className="font-13">Copyright @{year} {domain}</span>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default SiteStructure
