import { generateTypeName, generateTypeFunctions } from '../reduxTypes2'


// Export Constants
const NAME_TYPE = ['DATAS', 'DATA']

// Type Names and Type Functions
export const { ADDS, ADDS_GROUP, ADDS_MORE, ADD, UPDATE, REMOVE } = generateTypeName(NAME_TYPE)
export const { adds, adds_group, adds_more, add, update, remove } = generateTypeFunctions(NAME_TYPE)


export const addDatas = ({ datas }) => dispatch => {
  return dispatch(adds({ datas }))
}

export const addMoreDatas = ({ datas, groupId }) => dispatch => {
  return dispatch(adds_more({ datas }))
}

export const addDatasGroup = ({ datas, groupId }) => dispatch => {
  return dispatch(adds_group({ datas, groupId }))
}

export const addData = ({ _id, pageNo, groupId, value }) => dispatch => {
  const data = {
    _id, pageNo, groupId, value
  }

  return dispatch(add({ data }))
}

export const updateData = ({ _id, groupId, data }) => dispatch => {
  return dispatch(update({ _id, groupId, data }))
}

export const removeData = ({ _id, groupId }) => dispatch => {
  return dispatch(remove({ _id, groupId }))
}

export const removeDataAll = ({ datas, groupId }) => dispatch => {
  datas = datas.filter(data => data.groupId !== groupId)
  return dispatch(adds({ datas }))
}
