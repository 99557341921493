import React from 'react'

// Components Global
import Required from '../Required'

const InputDisabled = (props) => {
  const { digit, value, valueFormat, inputClassName, inputStyle } = props

  let inputHidden

  if (valueFormat) {
    inputHidden = true
  }



  return (
    <Required
      {...props}
      id={props.id}
      type="disabled"
      className={props.requiredClassName}
      labelClassName={props.labelClassName}
      label={props.label}
      unitName={props.unitName}
      required={props.required}
      default={props.value}>

      { valueFormat &&
        <input
          disabled
          value={valueFormat}
          className={`input ${inputClassName}`}
          style={inputStyle}
        />
      }

      <input
        disabled
        data-id={props.id}
        data-not-save={props.notSave}
        value={value !== undefined ? value : ''}
        className={`input ${!inputHidden && inputClassName}`}
        style={inputStyle}
        type={inputHidden && 'hidden'}
      />
    </Required>
  )
}

export default InputDisabled
