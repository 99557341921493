// Redux Actions
import {
  updateSite,
  removeSite
} from './SitesActions'

// Redux Actions
import { openConfirm } from '../App/AppActions'

const title = "รายการ"

// Main
const handleChangeStatus = (props, status, toastMsg) => {
  return (id) => {
    props.dispatch(updateSite(props.business._id, id, {
      body: { status: status },
      toastMsg: toastMsg,
      loadId: id,
      confirmId: id
    }))
  }
}

const handleDelete = (props, toastMsg) => {
  return (id) => {
    props.dispatch(removeSite(props.business._id, id, {
      toastMsg: toastMsg,
      loadId: id,
      confirmId: id
    }))
  }
}

export const onEditModal = (id) => {
  return {
    modal: { id }
  }
}

export const onEdit = (url) => {
  return { url }
}

export const onView = (url) => {
  return { url }
}

export const onTrash = (props, id) => {
  return {
    label: `ลบ${title}`,
    onConfirm: handleChangeStatus(props, 'trash', `ลบ${title}นี้แล้ว`)
  }
}

export const onDelete = (props) => {
  return {
    label: `ลบ${title}`,
    onConfirm: handleDelete(props, `ลบ${title}นี้ อย่างถาวรแล้ว`)
  }
}

export const onRestore = (props) => {
  return {
    label: `กู้คืน${title}`,
    onConfirm: handleChangeStatus(props, 'open', `กู้คืน${title}แล้ว`)
  }
}

export const onSwitchOpen = (props) => {
  return {
    label: `เปิดการใช้งาน${title}นี้`,
    onConfirm: handleChangeStatus(props, 'open', `เปิดการใช้งาน${title}นี้แล้ว`)
  }
}

export const onSwitchClose = (props) => {
  return {
    label: `ปิดการใช้งาน${title}นี้`,
    onConfirm: handleChangeStatus(props, 'close', `ปิดการใช้งาน${title}นี้แล้ว`)
  }
}


export const getSections = (props, tool={ byGroupId: false }) => {
  const { action, header, headerFixed, sideNavbar, widgets, footer, body, groupId } = props

  let { sections } = action.params.data
  sections = sections || []


  if (tool && tool.byGroupId) {
    sections = sections.filter(section => section.groupId === tool.byGroupId)

  } else {
    if (header) {
      sections = sections.filter(section => section.header === true)

    } else if (headerFixed) {
      sections = sections.filter(section => section.headerFixed === true)

    } else if (sideNavbar) {
      sections = sections.filter(section => section.sideNavbar === true)

    } else if (widgets) {
      sections = sections.filter(section => section.widgets === true)

    } else if (footer) {
      sections = sections.filter(section => section.footer === true)

    } else if (body) {
      sections = sections.filter(section => section.body === true)

    } else if (groupId) {
      sections = sections.filter(section => section.groupId === groupId)
    }
  }

  return sections
}

export const getContainer = (props) => {
  const { action, header, headerFixed, sideNavbar, widgets, footer, body, groupId } = props
  const { data } = action.params
  const { containers, containerHeader, containerHeaderFixed, containerSideNavbar, containerWidgets, containerFooter, containerBody } = data

  let container

  if (header) {
    container = containerHeader

  } else if (headerFixed) {
    container = containerHeaderFixed

  } else if (sideNavbar) {
    container = containerSideNavbar

  } else if (widgets) {
    container = containerWidgets

  } else if (footer) {
    container = containerFooter

  } else if (body) {
    container = containerBody

  } else if (groupId) {
    container = containers && containers.filter(con => con.groupId === groupId)[0]
  }

  return container
}

export const handleDeleteSection = ({ props, section, byGroupId, byGroupSectionId, fnRemove }) => {
  const { action } = props

  let actionId, title, pullLayout, pullSection, pullContainer

  // by grourp id
  if (byGroupId) {
    actionId = byGroupId
    title = 'ลบรายการนี้'

    pullLayout = {
      element: 'groupId',
      value: byGroupId
    }

    pullSection = {
      element: 'groupId',
      value: byGroupId
    }

    pullContainer = {
      element: 'groupId',
      value: byGroupId
    }

  // by grourp sections id
  } else if (byGroupSectionId) {
    actionId = byGroupSectionId
    title = 'ลบเซคชั่นนี้'

    pullLayout = {
      element: 'groupSectionId',
      value: byGroupSectionId
    }

    pullSection = {
      element: 'groupSectionId',
      value: byGroupSectionId
    }

    pullContainer = {
      element: 'groupSectionId',
      value: byGroupSectionId
    }

  // by section _id
  } else {
    actionId = section._id
    title = 'ลบเซคชั่นนี้'

    pullLayout = {
      element: 'sectionId',
      value: section.sectionId
    }

    pullSection = {
      element: '_id',
      value: section._id
    }
  }

  props.dispatch(openConfirm(actionId, {
    title,
    message: `กดปุ่ม "${title}" ถ้าคุณต้องการ${title}`,
    type: 'delete',
    icon: 'delete',
    confirmLabel: title,
    cancelLabel: 'ยกเลิก',
    onConfirm: () => {
      if (action.useQuery) {
        return props.dispatch(action.update(action.useQuery, {
          body: {
            updateType: 'pull-element',
            updateElement: 'layouts',
            notCheckImage: true,
            pull: pullLayout
          },
          loadId: actionId,

        })).then(res => {
          if (res.success) {
            props.dispatch(action.update(action.useQuery, {
              body: {
                updateType: 'pull-element',
                updateElement: 'sections',
                notCheckImage: true,
                pull: pullSection
              },
              loadId: actionId,
              confirmId: actionId

            })).then(res => {
              if (res.success) {
                if (pullContainer) {
                  props.dispatch(action.update(action.useQuery, {
                    body: {
                      updateType: 'pull-element',
                      updateElement: 'containers',
                      notCheckImage: true,
                      pull: pullContainer
                    },
                    loadId: actionId,
                    confirmId: actionId

                  })).then(res => {
                    if (res.success && fnRemove) {
                      fnRemove()
                    }
                  })
                } else {
                  if (fnRemove) {
                    fnRemove()
                  }
                }
              }
            })
          }
        })

      } else if (action.authName) {
        return props.dispatch(action.update(action.authName, action.params.business._id, action.params._id, {
            body: {
              updateType: 'pull-element',
              updateElement: 'layouts',
              notCheckImage: true,
              pull: pullLayout
            },
            loadId: actionId,

          })).then(res => {
            if (res.success) {
              props.dispatch(action.update(action.authName, action.params.business._id, action.params._id, {
                body: {
                  updateType: 'pull-element',
                  updateElement: 'sections',
                  notCheckImage: true,
                  pull: pullSection
                },
                loadId: actionId,
                confirmId: actionId

              })).then(res => {
                if (res.success) {
                  if (pullContainer) {
                    props.dispatch(action.update(action.authName, action.params.business._id, action.params._id, {
                      body: {
                        updateType: 'pull-element',
                        updateElement: 'containers',
                        notCheckImage: true,
                        pull: pullContainer
                      },
                      loadId: actionId,
                      confirmId: actionId

                    })).then(res => {
                      if (res.success && fnRemove) {
                        fnRemove()
                      }
                    })
                  } else {
                    if (fnRemove) {
                      fnRemove()
                    }
                  }
                }
              })
            }
          })

      } else {
        return props.dispatch(action.update(action.params.business._id, action.params._id, {
            body: {
              updateType: 'pull-element',
              updateElement: 'layouts',
              notCheckImage: true,
              pull: pullLayout
            },
            loadId: actionId,

          })).then(res => {
            if (res.success) {
              props.dispatch(action.update(action.params.business._id, action.params._id, {
                body: {
                  updateType: 'pull-element',
                  updateElement: 'sections',
                  notCheckImage: true,
                  pull: pullSection
                },
                loadId: actionId,
                confirmId: actionId

              })).then(res => {
                if (res.success) {
                  if (pullContainer) {
                    props.dispatch(action.update(action.params.business._id, action.params._id, {
                      body: {
                        updateType: 'pull-element',
                        updateElement: 'containers',
                        notCheckImage: true,
                        pull: pullContainer
                      },
                      loadId: actionId,
                      confirmId: actionId

                    })).then(res => {
                      if (res.success && fnRemove) {
                        fnRemove()
                      }
                    })
                  } else {
                    if (fnRemove) {
                      fnRemove()
                    }
                  }
                }
              })
            }
          })
      }
    }
  }))
}


// Items
const handleDeleteTemplate = (props, toastMsg) => {
  return (id) => {
    props.dispatch(updateSite(props.business._id, props.site._id, {
      body: {
        updateType: 'pull-element',
        updateElement: 'templates',
        pull: {
          element: '_id',
          value: id
        }
      },
      toastMsg: toastMsg,
      loadId: id,
      confirmId: id
    }))
  }
}

const handleDeleteContentGroupPage = (props, toastMsg) => {
  return (id) => {
    const { business, actionSinglePage } = props
    const { update, authName, data, useQuery } = actionSinglePage

    if (useQuery) {
      useQuery._id = data._id

      props.dispatch(update(useQuery, {
        body: {
          updateType: 'pull-element',
          updateElement: 'contentGroupPages',
          pull: {
            element: '_id',
            value: id
          }
        },
        toastMsg: toastMsg,
        loadId: id,
        confirmId: id
      }))

    } else if (authName) {
      props.dispatch(update(authName, business._id, data._id, {
        body: {
          updateType: 'pull-element',
          updateElement: 'contentGroupPages',
          pull: {
            element: '_id',
            value: id
          }
        },
        toastMsg: toastMsg,
        loadId: id,
        confirmId: id
      }))

    } else {
      props.dispatch(update(business._id, data._id, {
        body: {
          updateType: 'pull-element',
          updateElement: 'contentGroupPages',
          pull: {
            element: '_id',
            value: id
          }
        },
        toastMsg: toastMsg,
        loadId: id,
        confirmId: id
      }))
    }
  }
}



export const onDeleteTemplate = (props) => {
  let title = "เทมเพลต"

  return {
    label: `ลบ${title}`,
    onConfirm: handleDeleteTemplate(props, `ลบ${title}นี้ อย่างถาวรแล้ว`)
  }
}

export const onDeleteContentGroupPage = (props) => {
  let title = "หน้าเพจ"

  return {
    label: `ลบ${title}`,
    onConfirm: handleDeleteContentGroupPage(props, `ลบ${title}นี้ อย่างถาวรแล้ว`)
  }
}







