import React from 'react'
import { connect } from 'react-redux'

// Redux Actions
import { openModal, closeModal } from '../../../redux/App/AppActions'
import { addProgress, removeProgress } from '../../../redux/Progresses/ProgressesActions'

const ModalOpen = (props) => {
  const handleClickOpenModal = () => {
    if (props.progress) {
      props.dispatch(addProgress(props.id))
    }

    props.dispatch(openModal(props.id, props.params))
  }

  const style = { display: 'initial' }
  const styleJoin = Object.assign(style, props.style)

  return (
    <div onClick={handleClickOpenModal} style={styleJoin}>
      { props.children }
    </div>
  )
}

const ModalClose = (props) => {
  const handleClickCloseModal = () => {
    if (props.progress) {
      props.dispatch(removeProgress(props.id))
    }

    setTimeout(() => {
      props.dispatch(closeModal(props.id))
    }, 0)
  }

  const style = { display: 'initial' }
  const styleJoin = Object.assign(style, props.style)

  return (
    <div onClick={handleClickCloseModal} style={styleJoin}>
      { props.children }
    </div>
  )
}

export const BtnModalOpen = connect()(ModalOpen)
export const BtnModalClose = connect()(ModalClose)
