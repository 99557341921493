import React, { Fragment, useMemo } from 'react'
import LazyLoad from 'react-lazyload'
import config from '../../../../../../config'

// Components Global
import Avatar from '../../../../../Images/Avatar'
import { LayoutsDesign } from '../../../../../LayoutsDesign'

// Components Local
import Img from '../Img'
import ImgDisplay from '../ImgDisplay'

// Components Editor
import LinkDisplay from '../../../LinkDisplay'

// Functions
import { getSections } from '../../../../../../redux/Sites/SitesFunctions'

// css
import css from './css/editorImage.css'
import { getLazyLoadParams } from '../../../../../../util/getLazyLoadParams'

// utils
import { getURLSearchParams, updateURLSearchParams } from '../../../../../../util/getURLSearchParams'

const s3Url = config.app.s3.urlOriginal




export const ImageComponent = (props) => {
  const { mode, style, link, apiData, templateData, closeWindowModal } = props

  const lazyLoadParams = getLazyLoadParams(props)

  let lazyDisabled = style && style['lazy-disabled']

  if (props.lazyDisabled) {
    lazyDisabled = true
  }

  return (
    <Fragment>
      { mode ?
        <Image {...props} />
      :
        <Fragment>
          { !lazyDisabled ?
            <LazyLoad {...lazyLoadParams}>
              { link && link !== '{}' ?
                <LinkDisplay
                  linkHistoryPush
                  closeWindowModal={closeWindowModal}
                  value={link}
                  mode={mode}
                  apiData={apiData}
                  templateData={templateData}>

                  <Image {...props} />
                </LinkDisplay>
              :
                <Image {...props} />
              }
            </LazyLoad>
          :
            <Fragment>
              { link && link !== '{}' ?
                <LinkDisplay
                  linkHistoryPush
                  closeWindowModal={closeWindowModal}
                  value={link}
                  mode={mode}
                  apiData={apiData}
                  templateData={templateData}>

                  <Image {...props} />
                </LinkDisplay>
              :
                <Image {...props} />
              }
            </Fragment>
          }
        </Fragment>
      }
    </Fragment>
  )
}

export const ImageCoverComponent = (props) => {
  const { mode, style, link, apiData, templateData, closeWindowModal, paths } = props
  const lazyDisabled = style && style['lazy-disabled']
  const lazyLoadParams = getLazyLoadParams(props)

  return (
    <Fragment>
      { mode ?
        <ImageCover {...props} />
      :
        <Fragment>
          { !lazyDisabled ?
            <LazyLoad {...lazyLoadParams}>
              <LinkDisplay pathImg={paths && paths.o} linkHistoryPush closeWindowModal={closeWindowModal} value={link} mode={mode} apiData={apiData} templateData={templateData}>
                <ImageCover {...props} />
              </LinkDisplay>
            </LazyLoad>
          :
            <LinkDisplay pathImg={paths && paths.o} linkHistoryPush closeWindowModal={closeWindowModal} value={link} mode={mode} apiData={apiData} templateData={templateData}>
              <ImageCover {...props} />
            </LinkDisplay>
          }
        </Fragment>
      }
    </Fragment>
  )
}

/*export const ImageCoverComponent = (props) => {
  return (
    <Fragment>
      { props.mode ?
        <ImageCover {...props} />
      :
        <LazyLoad {...LazyLoadParams}>
          <ImageCover {...props} />
        </LazyLoad>
      }
    </Fragment>
  )
}*/


export const ImageAvatarComponent = (props) => {
  const lang = 'local'
  const { mode, style, link, apiData, data, templateData, closeWindowModal } = props
  const avatarShow = style && style['avatar-show']
  const align = style['block-text-align']
  const styleJsx = {
    width: style[`image-width`] || '100%',
    height: style[`image-height`] || '100%',
    widthVw: style[`image-width-vw`],
    heightVw: style[`image-height-vw`],
    borderStyle: style[`image-border-style`],
    borderWidth: style[`image-border-width`],
    borderColor: style[`image-border-color`],
    borderRadius: style[`image-border-radius`],
    borderRadiusVw: style[`image-border-radius-vw`],
    fontSize: style[`image-font-size`],
    fontSizeVw: style[`image-font-size-vw`]
  }

  let avatarName

  if (data && data.avatarName) {
    avatarName = data.avatarName
  } else {
    if (data) {
      let nameLang = data.name ? data.name[lang] : null
      const firstNameLang = data.firstName ? data.firstName[lang] : null
      const lastNameLang = data.lastName ? data.lastName[lang] : null
      const companyNameLang = data.companyName ? data.companyName[lang] : null

      if (nameLang) {
        nameLang = nameLang
        avatarName = [nameLang]
      }

      if (firstNameLang && lastNameLang) {
        nameLang = `${firstNameLang} ${lastNameLang}`
        avatarName = [firstNameLang, lastNameLang]
      }

      if (companyNameLang) {
        nameLang = companyNameLang
        avatarName = [companyName]
      }
    }
  }

  return (
    <div style={{ width: '100%', height: '100%', lineHeight: '0' }}>
      { avatarShow &&
        <Fragment>
          { mode ?
            <Avatar
              color={data && data.avatarColor}
              texts={avatarName}
              textSize="1.1rem"
              styleJsx={styleJsx}
              align={align}
            />
          :
            <LinkDisplay linkHistoryPush closeWindowModal={closeWindowModal} value={link} mode={mode} apiData={apiData}  templateData={templateData}>
              <Avatar
                color={data && data.avatarColor}
                texts={avatarName}
                textSize="1.1rem"
                styleJsx={styleJsx}
                align={align}
              />
            </LinkDisplay>
          }
        </Fragment>
      }
    </div>
  )
}

export const ImageGallery = (props) => {
  const lazyLoadParams = getLazyLoadParams(props)
  /*const { location } = props

  let params = getURLSearchParams(location.search)
  params = updateURLSearchParams({ search: location.search, name: 'gallery', value: props.index })

  console.log(params)

  const link = {
    type: 'inlink',
    inlink: `${location.pathname}`,
    blockScrollToTop: true
  }*/

  return (
    <Fragment>
      { props.mode ?
        <Image {...props} />
      :
        <LazyLoad {...lazyLoadParams}>
          <Image {...props} />
        </LazyLoad>
      }
    </Fragment>
  )
}

export const Image = (props) => {
  const { paths, style, logo, gallery, mode } = props
  const imageTypeSize = style['image-type-size'] || 'o'

  return (
    <Fragment>
      { useMemo(() =>
        <Fragment>
          { gallery ?
            <Img
              logo={logo}
              s3UrlOriginal
              paths={paths}
              imageTypeSize={imageTypeSize}
              width="100%"
              style={{ verticalAlign: 'middle' }}
              styleJsx={style}
              gallery={gallery}
            />
          :
            <Fragment>
              { mode ?
                <Img
                  logo={logo}
                  s3UrlOriginal
                  paths={paths}
                  imageTypeSize={imageTypeSize}
                  style={{ verticalAlign: 'middle', objectFit: 'cover' }}
                  styleJsx={style}
                />
              :
                <ImgDisplay
                  logo={logo}
                  s3UrlOriginal
                  paths={paths}
                  imageTypeSize={imageTypeSize}
                  style={{ verticalAlign: 'middle', objectFit: 'cover' }}
                  styleJsx={style}
                />
              }
            </Fragment>
          }
        </Fragment>
      , [paths && paths.o]) }
    </Fragment>
  )
}

export const ImageCover = (props) => {
  const { paths, display, content, style, mode } = props
  const sections = getSections(props, { byGroupId: content._id })
  const position = style['cover-content-position'] || 'center'

  let backgroundImage = paths.o && `url(${s3Url}/${paths.o})`

  if (!paths.o && content.api) {
    backgroundImage = `url(https://dummyimage.com/1500x600/bababa/949294.jpg)`
  }

  let stylePostion = {}

  if (!mode || mode === 'view') {
    if (position === 'top') {
      stylePostion = {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
      }
    } else if (position === 'bottom') {
      stylePostion = {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
      }
    }
  }

  return (
    <div
      id={content._id}
      className={`image-cover-jsx ${css.coverOpatity}`}
      style={{
        display: 'grid',
        width: '100%',
        backgroundImage
      }}>

      <div
        style={{
          zIndex: '7',
          ...stylePostion
        }}>

        <LayoutsDesign
          {...props}
          display={display}
          bodyColorNone
          groupId={content._id}
          sections={sections}
        />
      </div>

      <style jsx>{`
        .image-cover-jsx {
          min-height: ${style['image-height'] || '250px'};
          border-radius: ${style[`image-border-radius`]};
        }
        .image-cover-jsx:before {
          background-color: ${style['image-opacity-background-color'] || '#000'};
          opacity: ${style['image-opacity'] || '0.5'};
          border-radius: ${style[`image-border-radius`]};
        }

        @media (min-width: 1500px) {
          .image-cover-jsx {
            min-height: ${style['image-height-vw'] || '250px'};
            border-radius: ${style[`image-border-radius-vw`]};
          }
          .image-cover-jsx:before {
            border-radius: ${style[`image-border-radius`]};
          }
        }
      `}</style>
    </div>
  )
}
