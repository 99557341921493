import callApi from '../../util/apiCaller'
import uploadsForS3 from '../../util/uploadsForS3'

import {
  checkAppActions,
  errorAlert,
  httpStatus
} from '../App/AppActions'

// Export Constants
export const ADD_TOURS = 'ADD_TOURS'
export const ADD_TOUR = 'ADD_TOUR'
export const UPDATE_TOUR = 'UPDATE_TOUR'
export const DELETE_TOUR = 'DELETE_TOUR'


// Export Type
export const add_tours = (tours, search=false) => ({ type: ADD_TOURS, tours, search })
export const add_tour = tour => ({ type: ADD_TOUR, tour })
export const update_tour = (tour, _id, event=null) => ({ type: UPDATE_TOUR, tour, _id, event })
export const delete_tour = (_id) => ({ type: DELETE_TOUR, _id })


// Export Actions
export const fetchTours = (businesses_id, params) => dispatch => {
  checkAppActions(dispatch, params, 'start')

  return callApi(`tours/${businesses_id}`, 'get', params.body).then(res => {
    if (res.success) {

      if (params.search && res.count) {
        params.search.count = res.count
      }

      dispatch(add_tours(res.data, params.search))
      checkAppActions(dispatch, params, 'success')
    } else {
      dispatch(errorAlert(res))
      checkAppActions(dispatch, params, 'error')
    }

    return res
  })
}


export const fetchTourServerSide = (username, _id) => dispatch => {
  return callApi(`tours/${username}/${_id}`).then(res => {
    if (res.success) {
      dispatch(add_tour(res.data))
    } else {
      dispatch(errorAlert(res))
    }
  })
}


export const fetchTour = (businesses_id, tours_id, params = {}) => dispatch => {
  checkAppActions(dispatch, params, 'start')

  return callApi(`tours/${businesses_id}/${tours_id}`).then(res => {
    if (res.success) {
      dispatch(add_tour(res.data))
      checkAppActions(dispatch, params, 'success')
    } else {
      dispatch(errorAlert(res))
      checkAppActions(dispatch, params, 'error')
    }
  })
}


export const createTour = (businesses_id, params) => dispatch => {
  checkAppActions(dispatch, params, 'start')

  return callApi(`tours/${businesses_id}/create`, 'put', params.body).then(res => {
    if (res.success) {
      dispatch(add_tour(res.data))
      checkAppActions(dispatch, params, 'success')
    } else {
      dispatch(errorAlert(res))
      checkAppActions(dispatch, params, 'error')
    }
  })
}


export const updateTour = (businesses_id, tours_id, params) => dispatch => {
  checkAppActions(dispatch, params, 'start')

  return callApi(`tours/${businesses_id}/${tours_id}`, 'put', params.body).then(res => {
    if (res.success) {
      dispatch(update_tour(res.data, tours_id, params.body))
      checkAppActions(dispatch, params, 'success')
    } else {
      dispatch(errorAlert(res))
      checkAppActions(dispatch, params, 'error')
    }
  })
}


export const uploadImagesTour = (businesses_id, tours_id, files, prefix, params) => dispatch => {
  checkAppActions(dispatch, params, 'start')
  uploadsForS3(files, prefix, params.progressId, dispatch).then(val => {
    val.map(paths => {
      params.body.paths = paths
      dispatch(updateTour(businesses_id, tours_id, params))
    })
  })
}


export const deleteTour = (businesses_id, tours_id, params) => dispatch => {
  checkAppActions(dispatch, params, 'start')

  return callApi(`tours/${businesses_id}/${tours_id}`, 'delete').then(res => {
    if (res.success) {
      dispatch(delete_tour(tours_id))
      checkAppActions(dispatch, params, 'success')
    } else {
      dispatch(errorAlert(res))
      checkAppActions(dispatch, params, 'error')
    }
  })
}
