import React, { Fragment } from 'react'

// Components Local
import { EditorImageDisplay } from '../../../components/EditorImage'

// Components Editor
import { ImageGallery as ImgGallery } from '../../../components/EditorImage/components/ImageType'

// css
import css from './css/imageGallery.css'

// JS
import { getAlbumImages } from '../../../components/ApiSettings/js/apiSettings'

// Js
import { setStyleValue } from '../../../components/ElementStyles/components/js/setDivStyle'

// js
import { settings } from './js/imageGallery'

const ImageGallery = (props) => {
  let { lang, items, style, gallery, content, apiData, templateData, api, mode } = props
  const { classNameColumn } = settings(style)
  const name = "gallery"

  if (content && content.api) {
    const albumImages = getAlbumImages({ content, api, apiData, templateData })

    if (albumImages) {
      items = albumImages
    }

    if (mode) {
      items = [
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4 },
      ]
    }
  } else {
    items = gallery && gallery.items ? JSON.parse(gallery.items) : []
  }

  /*const xxx = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
    { id: 7 },
    { id: 8 },
    { id: 9 },
    { id: 10 },
    { id: 11 },
    { id: 12 },
  ]*/

  /*const rows = [3, 4, 5]
  let no = 0

  rows.map((row, iRow) => {
    no += row

    xxx.map((x, i) => {
      if (x.width === undefined) {
        if (i <= no-1) {
          x.width = 100/row
        }
      }
    })
  })

  console.log(xxx)*/


  return (
    <div className={`${css.gallery} gallery-jsx`}>
      { items.map((image, i) => {
        return (
          <div key={i} className={`${css.itemGallery} ${classNameColumn}`}>
            <div className="height-full box-item-jsx">
              { content.api ?
                <Fragment>
                  { mode ?
                    <div className="center width-full height-200 bg-efefef">

                    </div>
                  :
                    <ImgGallery
                      {...props}
                      gallery
                      display
                      mode={mode}
                      paths={image.paths}
                      style={style}
                    />
                  }
                </Fragment>
              :
                <EditorImageDisplay
                  lang={lang}
                  type="image-gallery"
                  location={props.location}
                  match={props.match}
                  mode={mode}
                  image={image}
                  content={content}
                  apiData={apiData}
                  index={i}
                  templateData={templateData}
                />
              }
            </div>
          </div>
        )
      }) }

      <div style={{ clear: 'both' }}></div>

      <style jsx>{`
        .gallery-jsx {
          display: flex;

          ${setStyleValue({ name, style })}
        }

        .box-item-jsx {
          ${setStyleValue({ name: 'box-item', style })}
        }

        @media (min-width: 1500px) {
          .gallery-jsx {
            ${setStyleValue({ name, style, vw: true })}
          }

          .box-item-jsx {
            ${setStyleValue({ name: 'box-item', style, vw: true })}
          }
        }

        @media (min-width: 601px) and (max-width: 1024px) {
          .gallery-jsx {
            ${setStyleValue({ device: 'tablet', name, style })}
          }

          .box-item-jsx {
            ${setStyleValue({ device: 'tablet', name: 'box-item', style })}
          }
        }

        @media (min-width: 50px) and (max-width: 600px) {
          .gallery-jsx {
            ${setStyleValue({ device: 'mobile', name, style })}
          }

          .box-item-jsx {
            ${setStyleValue({ device: 'mobile', name: 'box-item', style })}
          }
        }
      `}</style>
    </div>
  )
}

export default ImageGallery
