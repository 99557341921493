import React, { Component } from 'react'

// Components Global
import Switch from '../../../../Inputs/Switch'

// Functions
import { addStyle, removeStyle } from '../../../functions/textEditorFunctions'

// css
import css from '../css/styleSettings.css'



class SwitchStyleSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  change = (value, styleName) => {
    const { _this } = this.props

    if (value === '') {
      removeStyle(_this, styleName)
    } else {
      addStyle(_this, {
        style: styleName,
        value
      })
    }
  }

  reset = () => {
    let { _this, styleName } = this.props

    if (styleName) {
      removeStyle(_this, styleName)
    }
  }

  render() {
    let { _this, styleName, label, options, defaultValue } = this.props
    const value = _this.state.style[styleName]
    defaultValue = defaultValue || ''

    return (
      <div className="pd-10 bg-fbfbfb pd-right-0 border-radius-5">
        <table>
          <tbody>
            <tr>
              <td className="pd-0 font-0-8">{label}</td>
              <td width="80" className="pd-0 text-right">
                <Switch
                  notSave
                  id={styleName}
                  value={value || defaultValue}
                  onChange={(e) => this.change(e, styleName)}
                  labelHidden
                  style={{ height: '22px', marginTop: '-12px' }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

export default SwitchStyleSettings
