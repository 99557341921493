import React from 'react'

const Box = (props) => {
  const { icon, iconFa, title, children, className, bodyClassName } = props

  return (
    <div className={`mg-top-10 pd-top-5 bg-fff border-top-eee border-bottom-eee ${className}`}>
      <table>
        <tbody>
          <tr>
            <td width="40" className="pd-0 center">
              { iconFa ?
                <i className={`${iconFa} font-1-6`}></i>
              :
                <i className="material-icons">{icon}</i>
              }
            </td>
            <td className="pd-0">{title}</td>
          </tr>
        </tbody>
      </table>

      <div className={`border-top-eee ${bodyClassName}`}>
        { children }
      </div>
    </div>
  )
}

export default Box
