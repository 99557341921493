import React, { Component } from 'react'

// Functions
import { addStyle, removeStyle } from '../../../functions/textEditorFunctions'

// css
import css from '../css/styleSettings.css'


class ZIndexSetting extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleValueChange = (e) => {
    let { _this, styleName } = this.props
    const value = e.target.value

    styleName = styleName || 'z-index'

    if (value === '') {
      removeStyle(_this, styleName)
    } else {
      addStyle(_this, {
        style: styleName,
        value
      })
    }
  }

  reset = () => {
    let { _this, styleName } = this.props
    styleName = styleName || 'z-index'

    if (styleName) {
      removeStyle(_this, styleName)
    }
  }

  render() {
    let { _this, styleName } = this.props
    styleName = styleName || 'z-index'

    const zIndex = _this.state.style[styleName]

    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td className="pd-0 font-0-7">z-index</td>
              <td className="pd-0 font-0-7 text-right">
                <a className={css.btn} onClick={this.reset}>รีเซ็ท</a>
              </td>
            </tr>
          </tbody>
        </table>

        <div>
          <div className={css.boxInput}>
            <table>
              <tbody>
                <tr>
                  <td className="pd-0 font-0-8">
                    <input
                      style={{ textAlign: 'left' }}
                      className={`${css.input} ${css.inputBorderNone} ${css.inputFirst}`}
                      type="text"
                      onChange={this.handleValueChange}
                      value={zIndex || ''}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default ZIndexSetting
