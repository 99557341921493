import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import TextEditorLoginSocials from './TextEditorLoginSocials'
import TextEditorLoginSocialsDisplay from './TextEditorLoginSocialsDisplay'

let TextEditorLoginSocials = lazy(() => import('./TextEditorLoginSocials'))
TextEditorLoginSocials = suspenseComponent(TextEditorLoginSocials)

export {
  TextEditorLoginSocials,
  TextEditorLoginSocialsDisplay
}
