import React from 'react'
import { connect } from 'react-redux'

// Components Local
import Columns from './components/Columns'

import BlockStyle from '../components/BlockStyle'


const TextEditorColumnsDisplay = (props) => {
  const lang = props.lang || 'local'
  const content = props.content
  const columns = content.columns && JSON.parse(content.columns)
  const style = columns && columns.style ? JSON.parse(columns.style) : {}
  const className = columns && columns.class ? JSON.parse(columns.class) : []
  const items = columns && columns.items ? JSON.parse(columns.items) : []

  return (
    <BlockStyle {...props} style={style} className={className}>
      <Columns
        {...props}
        display
        lang={lang}
        className={className}
        style={style}
        items={items}
      />
    </BlockStyle>
  )
}

export default connect()(TextEditorColumnsDisplay)
