import { updateCustomer, removeCustomer, uploadImagesCustomer } from './CustomersActions'
import { updateAuthUserCustomer } from '../Auth/AuthActions'
import { closeModal, openConfirm } from '../App/AppActions'

// Functions
import { updateOrderCustomer } from '../Orders/OrdersFunctions'


const title = "รายชื่อลูกค้า"


export const handleSwitchChange = ({ props, params, toastMsg }) => {
  return (id) => {
    const { business } = props
    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businessType: business.type,
      businesses_id: business._id,
      customers_id: id
    }

    props.dispatch(updateCustomer(query, {
      body: params,
      toastMsg: toastMsg,
      loadId: id,
      confirmId: id
    }))
  }
}

const handleChangeStatus = (props, status, toastMsg) => {
  return (id) => {
    const { business, dispatch } = props
    const query = {
      authName: 'admin',
      dbNo: business.dbNo,
      businessType: business.type,
      businesses_id: business._id,
      customers_id: id
    }

    dispatch(updateCustomer(query, {
      body: { status },
      toastMsg: toastMsg,
      loadId: id,
      confirmId: id
    }))
  }
}

export const onEditModal = (id) => {
  return {
    modal: { id: id }
  }
}

export const onEdit = (url) => {
  return { url: url }
}

export const onView = (url) => {
  return { url }
}

export const onTrash = (props) => {
  return {
    label: `ลบ${title}`,
    onConfirm: handleChangeStatus(props, 'trash', `ลบ${title}นี้แล้ว`)
  }
}

export const onRestore = (props) => {
  return {
    label: `กู้คืน${title}`,
    onConfirm: handleChangeStatus(props, 'open', `กู้คืน${title}แล้ว`)
  }
}


export const onDelete = (props) => {
  const handleDelete = (props, toastMsg) => {
    return (id) => {
      const { business } = props
      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businessType: business.type,
        businesses_id: business._id,
        customers_id: id
      }

      props.dispatch(removeCustomer(query, {
        toastMsg: toastMsg,
        loadId: id,
        confirmId: id,
        props: props
      }))
    }
  }

  return {
    label: `ลบ${title}`,
    onConfirm: handleDelete(props, `ลบ${title}นี้ อย่างถาวรแล้ว`)
  }
}




// Images Main
export const uploadImage = (props) => {
  let authName

  if (props.forUser) {
    authName = "user"
  } else {
    authName = "admin"
  }

  const { business, dispatch, customer } = props
  const query = {
    authName,
    dbNo: business.dbNo,
    businessType: business.type,
    businesses_id: business._id,
    customers_id: customer._id
  }

  return (id, files, album) => {
    if (files) {
      const fileParams = {
        files,
        imagePrefix: 'customers'
      }

      dispatch(uploadImagesCustomer(query, fileParams, {
        body: {
          updateType: 'push',
          updateElement: 'images',
          album: album,
          profile: album === 'profile' ? true : false,
          cover: album === 'cover' ? true : false,
        },
        loadId: id,
        modalId: id,
        progressId: id
      }))
    }
  }
}

export const onDeleteImage = (props) => {
  let authName

  if (props.forUser) {
    authName = "user"
  } else {
    authName = "admin"
  }

  return (id) => {
    const { business, dispatch, customer } = props
    const query = {
      authName,
      dbNo: business.dbNo,
      businessType: business.type,
      businesses_id: business._id,
      customers_id: customer._id
    }

    dispatch(updateCustomer(query, {
      body: {
        updateType: 'pull-image',
        updateElement: 'images',
        deleteId: id
      },
      toastMsg: `ลบภาพนี้แล้ว`,
      loadId: id,
      confirmId: id,
      props: props
    }))
  }
}


export const deleteAddress = (props, addresses_id, customers_addresses_id) => {
  const { customer, dispatch, business, order, forUser, updateCart } = props

  let authName

  if (forUser && !order) {
    authName = "user"
  } else {
    authName = "admin"
  }

  const query = {
    authName,
    dbNo: business.dbNo,
    businessType: business.type,
    businesses_id: business._id,
    customers_id: customer._id
  }

  const actionParams = {
    body: {
      updateType: 'pull-element',
      updateElement: 'addresses',
      pull: {
        element: '_id',
        value: addresses_id
      }
    },
    loadId: addresses_id,
    confirmId: addresses_id,
    toastMsg: !order && 'ลบที่อยู่แล้ว'
  }

  let updateFn = () => updateCustomer(query, actionParams)

  dispatch(openConfirm(addresses_id, {
    title: `ลบที่อยู่นี้`,
    message: `กดปุ่ม "ลบที่อยู่" ถ้าคุณต้องการลบที่อยู่นี้`,
    icon: 'delete',
    type: 'error',
    confirmIcon: 'delete_forever',
    confirmLabel: "ลบที่อยู่",
    cancelLabel: 'ยกเลิก',
    onConfirm: () => {
      return dispatch(updateFn()).then(res => {
        if (res.success) {
          if (order) {
            if (customers_addresses_id) {
              if (addresses_id === customers_addresses_id) {
                props, res.data.customers_addresses_id = null
              }

              updateOrderCustomer(props, res.data, 'formName', 'ลบที่อยู่แล้ว')
            }
          } else {
            if (updateCart) {
              dispatch(closeModal(props.modalId))
              updateCart({ customers_addresses_id: null, formName: 'selectAddressSubmit' })
            }
          }
        }
      })
    }
  }))
}

export const deletePullElement = ({ props, _id, title, updateElement }) => {
  const { customer, business, dispatch } = props
  const query = {
    authName: 'admin',
    dbNo: business.dbNo,
    businessType: business.type,
    businesses_id: business._id,
    customers_id: customer._id
  }

  props.dispatch(openConfirm(_id, {
    title: `ลบ${title}นี้`,
    message: `กดปุ่ม "ลบ${title}" ถ้าคุณต้องการลบ${title}นี้`,
    icon: 'delete',
    type: 'error',
    confirmIcon: 'delete_forever',
    confirmLabel: `ลบ${title}`,
    cancelLabel: 'ยกเลิก',
    onConfirm: () => {
      return dispatch(updateCustomer(query, {
        body: {
          updateType: 'pull-element',
          updateElement,
          pull: {
            element: '_id',
            value: _id
          }
        },
        loadId: _id,
        confirmId: _id,
        toastMsg: `ลบ${title}แล้ว`
      }))
    }
  }))
}
