import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

// Components Global
import Icon from '../../../../Icon'
import { generateItems1 } from '../../../../DragDropMenu/functions/dragDropMenuFunctions'

// Components Local
import ListsExpand from '../ListsExpand'
import DropdownContent from '../DropdownContent'

// Components Text Editor
import LinkDisplay from '../../../components/LinkDisplay'
import { convertLinkNew } from '../../../components/LinkDisplay/js/linkDisplay'

// Js
import { setStyleValue } from '../../../components/ElementStyles/components/js/setDivStyle'

// Editor Functions
import { generateClassToStringByGroup } from '../../../functions/textEditorFunctions'
import { getApi, getApiValue } from '../../../functions/textEditorEvent'

// JS
import { fetchDatasApi, getDataStore } from '../../../components/FetchApiSettings/js/fetchApi'

// Utils
import { getURLSearchParams } from '../../../../../util/getURLSearchParams'

// css
import css from './css/lists.css'


class Lists extends Component {
  componentDidMount() {
    this.fetchDatas()

    if (this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null)
    }
  }

  fetchDatas = () => {
    const { content, apiItems } = this.props

    if (content.api) {
      fetchDatasApi(this.props)
    }
  }

  render() {
    const lang = this.props.lang || 'local'
    let { content, location, templateData, lists, items, match, apiItems, style, className, mode, pageCurrent, linkOnClick } = this.props

    if (!location) {
      if (typeof window !== 'undefined') {
        location = window.location
      }
    }

    style = style || {}

    let pathCurrent = pageCurrent && pageCurrent.path
    pathCurrent = pathCurrent === 'index' ? '/' : '/' + pathCurrent

    const listType = style && style['list-type']
    const newLine = style && style['listNewLine']
    const color = style && style['item-color']
    const hoverTextDecoration = style && style['item-hover-text-decoration']
    const hoverColor = style && style['item-hover-color']
    const activeColor = style && style['item-active-color']
    const name = "item"

    let apiForArray

    if (content.api) {
      if (apiItems) {
        items = [...items, ...apiItems]
      }

      pathCurrent = match && match.url

      if (!items) {
        const api = getApi(content, lists)

        if (mode) {
          items = [
            { name: 'demo 1'},
            { name: 'demo 2'},
            { name: 'demo 3'},
            { name: 'demo 4'},
            { name: 'demo 5'}
          ]
        } else {
          apiForArray = true
          items = getApiValue({ api, apiForArray, templateData, lang })
        }
      }
    }

    const fetchApiType = style && style['fetchApiType']
    const showTotalAll = style && style['showTotalAll']
    const expand = fetchApiType === 'folder-subfolder-product' ? true : false
    const hrScroll = style['item-hr-scroll']

    let hrUlStyle = {}, hrLiStyle = {}

    if (hrScroll) {
      hrUlStyle = {
        overflowX: 'auto',
        overflowY: 'hidden',
        whiteSpace: 'nowrap',
        width: '100%'
      }

      hrLiStyle = {
        display: 'inline-block'
      }
    }

    let itemsExpand

    if (expand) {
      itemsExpand = generateItems1({ items, folder: true })
    }



    return (
      <div className={`lists-jsx ${css.lists}`}>
        { expand ?
          <Fragment>
            { items.length !== 0 &&
              <ListsExpand
                editor
                fetchApiType={fetchApiType}
                match={match}
                name={name}
                style={style}
                lang={lang}
                mode={mode}
                lists={lists}
                content={content}
                listsExpand={itemsExpand}
                listsAll={items}
                pathType="path"
                linkStart={``}
                iconHidden={true}
                showTotalAll={showTotalAll}
              />
            }
          </Fragment>
        :
          <ul
            className="ul-jsx"
            style={{
              margin: '0',
              ...hrUlStyle
            }}>

            { items && items.map((item, i) => {

              if (typeof item === 'string') {
                item = JSON.parse(item)
              }

              let link, inlink, apiData

              if (item._id) {
                apiData = item
                link = lists && lists.link && JSON.parse(lists.link)

                if (link) {
                  inlink = convertLinkNew({ link: link.inlink, apiData, templateData, match, location })
                }

              } else {
                link = item && item.link && JSON.parse(item.link)

                if (link) {
                  inlink = convertLinkNew({ link: link.inlink, templateData, match, location })
                }
              }

              const linkShow = link && link.type

              let active = ''

              if (link && link.inlink) {
                if (listType === 'nav-menu' && inlink && pathCurrent) {
                  let urlModify = match && match.url
                  let pathCurrentModify = '/undefined'

                  // activeURLSearchOpen
                  if (style.activeURLSearchOpen && style.activeURLSearchAttributes) {
                    const attribute = style.activeURLSearchAttributes
                    const searchParams = location && getURLSearchParams(location.search)
                    const paramsModify = new URLSearchParams('')

                    if (searchParams && searchParams[`${attribute}`]) {
                      const key = attribute
                      const value = searchParams[`${attribute}`]

                      if (key) {
                        paramsModify.append(key, value)
                      }

                      if (paramsModify.toString()) {
                        urlModify = `${urlModify}?${paramsModify.toString()}`

                        if (pathCurrentModify !== '/undefined') {
                          pathCurrentModify = `${pathCurrentModify}?${paramsModify.toString()}`
                        }
                      }
                    }

                    if (urlModify && pathCurrentModify === '/undefined') {
                      active = urlModify === inlink ? 'active-jsx' : ''
                    } else {
                      active = pathCurrentModify === inlink ? 'active-jsx' : ''
                    }

                  // default
                  } else {
                    if (match && pathCurrent === '/undefined') {
                      active = match.url === inlink ? 'active-jsx' : ''
                    } else {
                      if (match && match.params && match.params.lang) {
                        let pathCurrentLang

                        if (match.params.path_1 === undefined) {
                          pathCurrentLang= `/${match.params.lang}`
                        } else {
                          pathCurrentLang = `/${match.params.lang}${pathCurrent}`
                        }

                        active = pathCurrentLang === inlink ? 'active-jsx' : ''
                      } else {
                        active = pathCurrent === inlink ? 'active-jsx' : ''
                      }
                    }
                  }
                } else if (pathCurrent && inlink === '') {
                  active = (match && match.url === '/') ? 'active-jsx' : ''
                }
              }

              const dropdown = item && item.dropdown && JSON.parse(item.dropdown)

              return (
                <Fragment>
                  <li
                    key={i}
                    className={`li-jsx ${active} ${generateClassToStringByGroup(className, 'item')}`}
                    style={{
                      float: style['item-float'],
                      ...hrLiStyle
                    }}>

                    { linkShow ?
                      <Fragment>
                        { dropdown && dropdown.status ?
                          <DropdownContent
                            {...this.props}
                            lang={lang}
                            item={item}
                            listComp={(
                              <Fragment>
                                <LinkDisplay
                                  apiForArrayValue={apiForArray}
                                  mode={mode}
                                  value={link}
                                  apiData={apiData}
                                  templateData={templateData}
                                  match={match}
                                  color={color}
                                  hoverTextDecoration={hoverTextDecoration}
                                  hoverColor={hoverColor}
                                  linkOnClick={linkOnClick}
                                  activeColor={activeColor}>

                                  <ItemDetails
                                    {...this.props}
                                    mode={mode}
                                    lang={lang}
                                    item={item}
                                    style={style}
                                    className={className}
                                    content={content}
                                    lists={lists}
                                    api={!item._id ? false : content.api}
                                    apiForArrayValue={apiForArray}
                                  />
                                </LinkDisplay>
                              </Fragment>
                            )}
                          />
                        :
                          <LinkDisplay
                            apiForArrayValue={apiForArray}
                            mode={mode}
                            value={link}
                            apiData={apiData}
                            templateData={templateData}
                            match={match}
                            color={color}
                            hoverTextDecoration={hoverTextDecoration}
                            hoverColor={hoverColor}
                            linkOnClick={linkOnClick}
                            activeColor={activeColor}>

                            <ItemDetails
                              {...this.props}
                              mode={mode}
                              lang={lang}
                              item={item}
                              style={style}
                              className={className}
                              content={content}
                              lists={lists}
                              api={!item._id ? false : content.api}
                              apiForArrayValue={apiForArray}
                            />
                          </LinkDisplay>
                        }
                      </Fragment>
                    :
                      <ItemDetails
                        {...this.props}
                        lang={lang}
                        item={item}
                        style={style}
                        className={className}
                        content={content}
                        lists={lists}
                        api={!item._id ? false : content.api}
                        dropdown={dropdown}
                      />
                    }
                  </li>

                  { newLine &&
                    <li></li>
                  }
                </Fragment>
              )
            }) }
          </ul>
        }

        <style jsx>{`
          .lists-jsx {
            text-align: ${style['lists-text-align']};
            margin: ${style['lists-margin']};
            padding: ${style['lists-padding']};
          }

          .ul-jsx {
            width: ${style['lists-width']};
            height: ${style['lists-height']};
            display: ${style['lists-ul-display']} !important;
          }

          .li-jsx {
            ${setStyleValue({ name: 'item', style })}
            /*padding: ${listType === 'nav-menu' && '0'};*/
          }

          .li-jsx > :global(a) {
            display: block;
            display: ${listType === 'nav-menu' ? 'block' : 'inline-block'};
            /*padding: ${listType === 'nav-menu' && `${style[`item-padding`]}`};*/

            white-space: ${listType === 'nav-menu' && 'nowrap'};
            overflow: ${listType === 'nav-menu' && 'hidden'};
            text-overflow: ${listType === 'nav-menu' && 'ellipsis'};
          }

          .li-jsx:hover {
            ${setStyleValue({ name: 'item-hover', style })}
          }

          .li-jsx:active {
            ${setStyleValue({ name: 'item-active-click', style })}
          }

          .active-jsx {
            ${setStyleValue({ name: 'item-active', style, event: 'active' })}
          }


          .active-jsx :global(a), .li-jsx:hover :global(a),
          .active-jsx :global(span), .li-jsx:hover :global(span),
          .active-jsx :global(i), .li-jsx:hover :global(i) {
            color: ${listType === 'nav-menu' && 'unset !important'};
          }

          .li-jsx :global(i.material-icons), .li-jsx :global(i.icon-fa) {
            font-size: ${style[`${name}-icon-size`]};
            line-height: ${style[`${name}-line-height`]};
          }

          @media (min-width: 1500px) {
            .lists-jsx {
              margin: ${style['lists-margin-vw']};
              padding: ${style['lists-padding-vw']};
            }

            .ul-jsx {
              width: ${style['lists-width-vw']};
              height: ${style['lists-height-vw']};
            }

            .li-jsx {
              ${setStyleValue({ name: 'item', style, vw: true })}
            }

            .li-jsx :global(i.material-icons), .li-jsx :global(i.icon-fa) {
              font-size: ${style[`${name}-icon-size-vw`]};
              line-height: ${style[`${name}-line-height-vw`]};
            }
          }

          @media (min-width: 601px) and (max-width: 1024px) {
            .lists-jsx {
              text-align: ${style['lists-tablet-text-align']};
              margin: ${style['lists-tablet-margin']};
              padding: ${style['lists-tablet-padding']};
            }

            .li-jsx {
              ${setStyleValue({ device: 'tablet', name: 'item', style })}
            }
          }

          @media (min-width: 50px) and (max-width: 600px) {
            .lists-jsx {
              text-align: ${style['lists-mobile-text-align']};
              margin: ${style['lists-mobile-margin']};
              padding: ${style['lists-mobile-padding']};
            }

            .li-jsx {
              ${setStyleValue({ device: 'mobile', name: 'item', style })}
            }
          }

        `}</style>
      </div>
    )
  }
}

const ItemDetails = (props) => {
  const { lists, lang, content, item, style, className, apiForArrayValue, dropdown, mode } = props
  const icon = item && item.icon && JSON.parse(item.icon)
  const iconShow = icon && icon.type && icon.name
  const listIconType = style && style['list-icon-type']
  const listParagraph = style && style['list-paragraph']

  let nameLocal = item && item[`name_local`]
  let name = item && item[`name_${lang}`] || nameLocal

  let iconStyle, iconClassName

  if (props.api) {
    const api = getApi(content, lists)
    const apiData = item, templateData = item

    name = getApiValue({ api, apiData, templateData, apiForArrayValue, lang })
  }

  if (iconShow) {
    iconStyle = {
      color: style && style['item-icon-color'],
      float: style && style['item-icon-float'],
      display: style && style['item-icon-display'],
      margin: style && style['item-icon-margin'],
      marginVw: style && style['item-icon-margin-vw'],
      fontSize: style && style['item-icon-font-size'],
      fontSizeVw: style && style['item-icon-font-size-vw']
    }
    iconClassName = `${listIconType !== 'icon-only' ? 'left i-middle' : '' } ${generateClassToStringByGroup(className, 'item-icon')}`
  }

  return (
    <Fragment>
      { !listParagraph ?
        <Fragment>
          { dropdown && dropdown.status ?
            <DropdownContent
              {...props}
              listComp={(
                <Fragment>
                  { iconShow &&
                    <Icon type={icon.type} className={iconClassName} style={iconStyle}>
                      { icon.name }
                    </Icon>
                  }

                  <span className={`list-item-jsx ${generateClassToStringByGroup(className, 'item-font')}`}>
                    { name }
                  </span>
                </Fragment>
              )}
            />
          :
            <Fragment>
              { iconShow &&
                <Icon type={icon.type} className={iconClassName} style={iconStyle}>
                  { icon.name }
                </Icon>
              }

              <span className={`list-item-jsx ${generateClassToStringByGroup(className, 'item-font')}`}>
                { name }
              </span>
            </Fragment>
          }
        </Fragment>
      :
        <table>
          <tbody>
            <tr>
              <td width="30" className="pd-0 vertical-top">
                { iconShow &&
                  <Icon type={icon.type} className={iconClassName} style={iconStyle}>
                    { icon.name }
                  </Icon>
                }
              </td>
              <td className="pd-0">
                <span className={`list-item-jsx ${generateClassToStringByGroup(className, 'item-font')}`}>
                  { name }
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      }

      <style jsx>{`
        .list-item-jsx {

        }

        @media (min-width: 1500px) {
          .list-item-jsx {

          }
        }

        @media (min-width: 601px) and (max-width: 1024px) {
          .list-item-jsx {

          }
        }

        @media (min-width: 50px) and (max-width: 600px) {
          .list-item-jsx {

          }
        }
      `}</style>
    </Fragment>
  )
}


const mapStateToProps = (store, props) => {
  return {
    site: store.sites.data[0],
    folders: store.folders.data,
    apiItems: getDataStore(store, props)
  }
}

export default connect(mapStateToProps)(Lists)
