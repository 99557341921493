import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import TextEditorImageSlider from './TextEditorImageSlider'
import TextEditorImageSliderDisplay from './TextEditorImageSliderDisplay'

let TextEditorImageSlider = lazy(() => import('./TextEditorImageSlider'))
TextEditorImageSlider = suspenseComponent(TextEditorImageSlider)

export {
  TextEditorImageSlider,
  TextEditorImageSliderDisplay
}
