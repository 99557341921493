export const getDataByKey = ({ key, data }) => {
  let value

  if (key && data) {
    const keySplit = key.split('.')

    if (keySplit.length === 0) {
      if (data[key]) {
        value = data[key]
      }

    } else {
      let newValue = data

      keySplit.map(keyValue => {
        if (newValue[keyValue] !== undefined) {
          newValue = newValue[keyValue]
          value = newValue
        } else {
          value = ''
        }
      })
    }
  }

  return value
}

export const getDataByKeyArray = ({ data, keyArray }) => {
  let dataObj = {...data}
  const { key, index, subkey } = keyArray
  dataObj = dataObj[`${key}`][index]

  return getDataByKey({ data: dataObj, key: subkey })
}

export const getJoinDataByKey = ({ data, join, joinDatas }) => {
  const { name, join_key, joinElement, key } = join
  const join_id = data[join_key]
  const selectData = joinDatas.filter(data => data.name === name)[0]

  let value

  if (selectData) {
    const item = selectData.datas.filter(data => data[joinElement] === join_id)[0]
    value = getDataByKey({ data: item, key })
  }

  return value
}
