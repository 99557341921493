export const convertObjectToUrlSearch = (object, startIndex=0, txtStart='') => {
    let str = ''

    Object.keys(object).map((obj, i) => {
        let attribute = obj
        let value = object[obj]

        if (i >= startIndex) {
            if (i === 0) {
                str = `${str}${txtStart}${attribute}=${value}`
            } else {
                str = `${str}&${attribute}=${value}`
            }
        }
    })

    return str
}

export const convertObjectToString = (object, startIndex=0, txtStart='', txtBetween='') => {
    let str = ''

    Object.keys(object).map((obj, i) => {
        let attribute = obj
        let value = object[obj]

        if (i >= startIndex) {
            if (i === startIndex) {
                str = `${str}${txtStart}${attribute}=${value}`
            } else {
                str = `${str}${txtBetween}${attribute}=${value}`
            }
        }
    })

    return str
}

export const convertObjectToStringArray = (ArrayObject, addString='') => {
    let strArray = ''

    ArrayObject.map((object, i) => {
        let strObj = convertObjectToString(object, 0, '', ',')
        let str = `[${strObj}${addString}]`

        strArray = `${strArray}${str}`
    })

    return strArray
}

export const convertStringArrayToObject = (stringArray) => {
    let array = []
    let splitStr = stringArray.split(']')

    splitStr.map((str, i) => {
        if (str !== '') {
            let strValue = str.replace('[', '')
            let object = convertStringValueToObject(strValue)

            array.push(object)
        }
    })

    return array
}

const convertStringValueToObject = (stringValue) => {
    let object = {}
    let splitStr = stringValue.split(',')

    splitStr.map((str, i) => {
        let split = str.split('=')

        if (split[0] && split[1]) {
            let attribute = split[0]
            let value = split[1]

            object[attribute] = value
        }
    })

    return object
}

