import React from 'react';
import styles from './css/modal.css'

export function ModalAlert (props) {
  const handleStay = (e) => e.stopPropagation()
  const centered = {
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: '0 auto',
    padding: '20px',
    background: '#fff',
    width: '100%',
    maxWidth: (props.width ? props.width : '300px'),
    borderRadius: '5px',
    position: 'relative'
  }
  const buttonCloss = {
    position: 'absolute',
    right: '0',
    top: '0',
    margin: '10px'
  }
  const title = {
    margin: 0,
    padding: 0,
    fontSize: '1.5rem'
  }
  const body = {
    padding: '10px 0px'
  }
  const footer = {
    paddingTop: '15px'
  }


  let data = props.data
  let errorMsgs = [

    // Admins
    { code: 'error-delete-founder', msg: 'ไม่สามารถลบผู้ก่อตั้งได้' },

    // Auth
    { code: 'unauthorized', msg: 'ไม่มีสิทธิเข้าใช้งาน กรุณาเข้าสู่ระบบ' },
    { code: 'sign-up-error', msg: 'การสมัครสมาชิกไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง' },
    { code: 'email-duplicate', msg: 'อีเมล/ชื่อผู้ใช้ นี้ใช้ในการสมัครสมาชิกแล้ว กรุณาเปลี่ยน อีเมล/ชื่อผู้ใช้ ใหม่ในการสมัครสมาชิก' },
    { code: 'provider-error', msg: 'ไม่มีผู้ให้บริการการเข้าสู่ระบบ กรุณาลองใหม่อีกครั้ง' },
    { code: 'sign-in-error', msg: 'การเข้าสู่ระบบไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง' },
    { code: 'sign-in-customer-code-error', msg: 'รหัสลูกค้าไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง' },
    { code: 'send-email-error', msg: 'ไม่สามารถส่งข้อมูลไปยังอีเมลของคุณได้ กรุณาลองใหม่อีกครั้ง' },
    { code: 'token-is-invalid', msg: 'Token การยืนยันบัญชีการใช้งานไม่ถูกต้อง' },
    { code: 'confirm-account-error', msg: 'การยืนยันบัญชีการใช้งานไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง' },
    { code: 'forgot-password-error', msg: 'ส่งข้อมูลการกำหนดรหัสผ่านใหม่ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง' },
    { code: 'email-invalid', msg: 'ไม่มีอีเมลอยู่ในระบบ กรุณากรอกอีเมลใหม่อีกครั้ง' },
    { code: 'token-is-invalid-or-has-expired', msg: 'token ไม่ถูกต้อง หรือ Token หมดอายุแล้ว' },
    { code: 'password-do-not-match', msg: 'รหัสผ่านไม่ตรงกัน กรุณาลองใหม่อีกครั้ง' },

    // Orders
    { code: 'not-enough-qty', msg: 'จำนวนสินค้าไม่เพียงพอ' },


    // Global
    { code: 'data-undefined', msg: 'ข้อมูลบางส่วนหายไป' },
    { code: 'find-service-error', msg: 'ไม่สามารถค้าหาข้อมูลจากฐานข้อมูลได้' },
    { code: 'active-datas', msg: 'ไม่สามารถลบข้อมูลนี้ได้ เนื่องจากมีการใช้ข้อมูลนี้อยู่' },
    { code: 'Failed to fetch', msg: 'ไม่สามารถเชื่อมต่อไปยังเซิร์ฟเวอร์ได้' },
    { code: 'require-body-error', msg: 'ไม่มีข้อมูลที่คุณส่งมา กรุณาลองใหม่อีกครั้ง' },
  ]

  let error = errorMsgs.filter(msg => msg.code === data.code)[0]

  return (
    <div>
      { props.open || props.showOnly ?
        <div className={`z-depth-1 ${styles.block}`} onClick={props.close} style={{ background: props.bgOverlay, zIndex: '999999' }}>
          <div style={centered} onClick={handleStay}>
            <div>
              <a style={buttonCloss} onClick={props.close} className="btn-floating btn-flat btn-large waves-effect waves-light">
                <i className="material-icons black-text">close</i>
              </a>
              { props.title ? <h1 style={title}>{props.title}</h1> : null }
              { data.type === 'error' && <i className="material-icons font-4-0 red-text text-accent-4">error_outline</i> }
              { data.type === 'success' && <i className="fa fa-check-circle-o font-4-0 green-text"></i> }
            </div>
            <div style={body}>

              { data.type === 'error' &&
                <p className="font-1-2 red-text text-accent-4">
                  { error && error.msg }
                </p>
              }

              { data.type === 'success' &&
                <p className="font-1-2 green-text">
                  { props.message }
                </p>
              }

            </div>
            <div style={footer}>
              { data.type === 'error' &&
                <a onClick={props.close} style={{ paddingLeft: '2.5rem', paddingRight: '2.5rem'}} className="waves-effect waves-light btn red accent-4">{props.buttonText}</a>
              }

              { data.type === 'success' &&
                <a onClick={props.close} style={{ paddingLeft: '2.5rem', paddingRight: '2.5rem'}} className="waves-effect waves-light btn green">{props.buttonText}</a>
              }
            </div>
          </div>
        </div>
      : null }
    </div>
  )
}

export default ModalAlert;
