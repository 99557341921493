import React from 'react'

// Components Text Editor
import BoxSettings from '../../BoxSettings'
import {
  DisplaySetting,
  OpacitySetting,
  WidthSetting,
  HeightSetting,
  LineHeightSetting,
  FontSetting,
  FontSizeSetting,
  MarginSetting,
  PaddingSetting,
  BackgroundColorSetting,
  BackgroundGradientSetting,
  BorderSetting,
  BoxShadowSetting,
  BorderRadiusSetting,
  ColorSetting,
  LineClampSetting,
  FloatSetting,
  AbsoluteSetting,
  TextAlignSetting,

} from '../../StyleSettings'


const ImageStyle = (props) => {
  const { _this, title, icon, name } = props
  const hidden = props.hidden || {}

  let settings = [
    {
      hidden: hidden.margin ? true : false,
      component: <MarginSetting _this={_this} styleName={`${name}-margin`} defaultValue="0px 0px 0px 0px" />
    },
    {
      hidden: hidden.padding ? true : false,
      component: <PaddingSetting _this={_this} styleName={`${name}-padding`} defaultValue="10px 20px 10px 20px" />
    },
    {
      hidden: hidden.display ? true : false,
      component: <DisplaySetting _this={_this} styleName={`${name}-display`} />
    },
    {
      hidden: hidden.opacity ? true : false,
      component: <OpacitySetting _this={_this} styleName={`${name}-opacity`} />
    },
    {
      hidden: hidden.width ? true : false,
      component: <WidthSetting _this={_this} styleName={`${name}-width`} />
    },
    {
      hidden: hidden.maxWidth ? true : false,
      component: <WidthSetting _this={_this} label="ความกว้างสูงสุด (max-width)" styleName={`${name}-max-width`} />
    },
    {
      hidden: hidden.minWidth ? true : false,
      component: <WidthSetting _this={_this} label="ความกว้างต่ำสุด (min-width)" styleName={`${name}-min-width`} />
    },
    {
      hidden: hidden.height ? true : false,
      component: <HeightSetting _this={_this} styleName={`${name}-height`} />
    },
    {
      hidden: hidden.border ? true : false,
      component: <BorderSetting _this={_this} styleName={`${name}-border`} />
    },
    {
      hidden: hidden.borderRadius ? true : false,
      component: <BorderRadiusSetting _this={_this} styleName={`${name}-border-radius`} />
    },
    {
      hidden: hidden.boxShadow ? true : false,
      component: <BoxShadowSetting _this={_this} styleName={`${name}-box-shadow`} />
    },
    {
      hidden: hidden.float ? true : false,
      component: <FloatSetting _this={_this} styleName={`${name}-float`} />
    },
    {
      hidden: hidden.fontSize ? true : false,
      component: <FontSizeSetting _this={_this} styleName={`${name}-font-size`} />
    },
    {
      hidden: hidden.textAlign ? true : false,
      component: <TextAlignSetting _this={_this} styleName={`${name}-text-align`} />
    },
    {
      hidden: hidden.absolute ? true : false,
      component: <AbsoluteSetting _this={_this} styleName={`${name}-position`} />
    }
  ]

  settings = settings.filter(setting => setting.hidden !== true)

  return (
    <BoxSettings title={title} icon={icon}>

      { settings.map((setting, i) => {
        return (
          <div key={i} className={`pd-10 ${i%2 === 1 ? 'bg-fbfbfb' : '' }`}>
            { setting.component }
          </div>
        )
      }) }

    </BoxSettings>
  )
}

export default ImageStyle
