import React from 'react'
import Scrollbar from 'react-perfect-scrollbar'

// Components
import TabsTop from '../TabsTop'

// css
import css from './css/layoutTabs.css'


const LayoutTabs = (props) => {
  const { items, circle, activeId, children } = props

  return (
    <TabsTop
      circle={circle}
      activeId={activeId}
      styleLink={{ borderRight: '0', borderTop: '0px solid #efefef' }}
      items={items}>

      { items.map((item, i) => {
        return (
          <div key={i} id={item.id} className={css.styleTab} style={{ bottom: '0', top: circle && 0 }}>
            <Scrollbar>
              { children[i] }
            </Scrollbar>
          </div>
        )
      }) }
    </TabsTop>
  )
}

export default LayoutTabs
