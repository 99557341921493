export const getPathActive = (props, type='pathIdFull') => {
  if (props.match) {
    const { path_1, path_2, path_3, path_4, path_5 } = props.match.params
    const pageIndex = getPageIndex(props)

    let pathActive

    if (path_1) {
      pathActive = `${path_1}`

      if (path_2) {
        pathActive = `${pathActive}/${path_2}`

        if (path_3) {
          pathActive = `${pathActive}/${path_3}`

          if (path_4) {
            pathActive = `${pathActive}/${path_4}`

            if (path_5) {
              pathActive = `${pathActive}/${path_5}`
            }
          }
        }
      }
    } else {
      pathActive = pageIndex && pageIndex[type]
    }

    return pathActive
  } else {
    return ''
  }
}

export const getPageActive = (props, type='_id') => {
  const { pages, match } = props
  const { path_1, path_2, path_3, path_4, path_5 } = match.params

  let path, page

  if (path_1) path = path_1
  if (path_2) path = path_2
  if (path_3) path = path_3
  if (path_4) path = path_4
  if (path_5) path = path_5

  if (path) {
    page = pages.filter(page => page[type] === path)[0]
  } else {
    page = getPageIndex(props)
  }

  return page
}

export const getPageIndex = (props) => {
  const { pages } = props

  return pages && pages.filter(page => page.path === 'index')[0]
}

export const getPathsParams = (params) => {
  const paths = []

  for (let key in params) {
    if (key.search("path_") !== -1) {
      if (params[key]) {
        paths.push(params[key])
      }
    }
  }

  return paths
}
