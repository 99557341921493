import modules from '../routes'

const apiCallerIndexedDB = async (endpoint, method = 'get', body) => {
  let name = endpoint.split('/')[0]
  let module = modules.filter(module => module.name === name)[0]

  return new Promise(async resolve => {
    if (module) {
      const data = await module.routes(endpoint, method, body)

      if (data.success !== true) {
        console.log(data)
      }

      resolve(data)
    } else {
      const error = {
        success: false,
        code: 'route is undefined'
      }
      console.log(error)
      resolve(error)
    }
  })
}

export default apiCallerIndexedDB
