import _ from 'lodash'
import {
  stateSearchDefault,
  stateAddDatas,
  stateAddMoreDatas,
  stateAddData,
  stateUpdate,
  stateDelete
} from '../reduxReducer'


import {
  ADDS,
  ADDS_GROUP,
  ADDS_MORE,
  ADD,
  UPDATE,
  REMOVE
} from './DatasActions'


const initialState = {
  data: [],
  search: stateSearchDefault(),
}

const ProductsReducer = (state=initialState, action) => {
  const actionDatas = action.datas
  const actionData = action.data

  switch (action.type) {
    case ADDS:
      return stateAddDatas(state, action, actionDatas)

    case ADDS_GROUP:
      let data = state.data.filter(v => v.groupId !== action.groupId)
      data = [...data, ...action.datas]
      data = _.uniqBy(data, (v) => [v._id, v.groupId].join())

      return {
        data,
        search: stateSearchDefault()
      }

    case ADDS_MORE:
      return stateAddMoreDatas(state, action, actionDatas)

    case ADD:
      const newState = stateAddData(state, action, actionData)
      newState.data = _.uniqBy(newState.data, (v) => [v._id, v.groupId].join())

      return newState

    case REMOVE:
      return stateDelete(state, action)

    case UPDATE:
      return stateUpdate(state, action, actionData)

    default:
      return state
  }
}

export default ProductsReducer
