import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import renderRoutes from '../../../../../routes/renderRoutes'

// Compoents Local
import { WidgetsDisplay } from '../../../Widgets'

// Redux Actions
import { fetchProductOneDisplay } from '../../../../../redux/Products/ProductsActions'
import { fetchArticleDisplay } from '../../../../../redux/Articles/ArticlesActions'

import PagePartsDisplay from '../../../PageParts/PagePartsDisplay'

// Values
import { productValue, getProductParams } from '../../../../../redux/Products/ProductsValues'
import { articleValue } from '../../../../../redux/Articles/ArticlesValues'

// js
import { useTemplate } from './js/siteModalDisplay'

// functions
import {
  checkAuthProfileRedirect,
  fetchProduct,
  fetchArticle,
  fetchFolder,
  fetchTag
} from '../../functions/siteFetchDatas'

// css
import css from './css/siteDisplayContainer.css'



const SiteModalDisplayContainer = (props) => {
  const [heightHeader, setHeightHeader] = useState()

  const setHeader = () => {
    let heightHeader = 0
    const navbarFixed = document.getElementById("modal-page-header-fixed-display")

    if (navbarFixed) {
      heightHeader = navbarFixed.offsetHeight + 'px'
    }

    setHeightHeader(heightHeader)
  }

  const start = () => {
    const { match, product, article, folder, tag } = props
    const { _id, pageType } = match.params
    setHeader()

    if (_id) {
      // product
      if (pageType === 'product-details') {
        if (!product) {
          fetchProduct(props, _id)
        }

      // article
      } else if (pageType === 'article-details') {
        if (!article) {
          fetchArticle(props, _id)
        }

      // folder
      } else if (pageType === 'product-categories' || pageType === 'article-categories') {
        if (!folder) {
          fetchFolder(props, _id)
        }

      // tag
      } else if (pageType === 'product-tags' || pageType === 'article-tags') {
        if (!tag) {
          fetchTag(props, _id)
        }
      }
    }
  }

  useEffect(() => {
    start()
  }, [props.location.pathname])


  const { business, setting, pages, site, singlePageData, singlePageType, match } = props
  const { page, templateData, template } = useTemplate(props)

  checkAuthProfileRedirect(props, { page, templateData })

  return (
    <Fragment>
      <div id="modal-page-container" className={css.container}>
        { page &&
          <header
            id="modal-page-header-fixed-display"
            className={css.headerFixed}
            style={{
              zIndex: '4',
              position: 'fixed',
              left: '0',
              right: '0'
            }}>

            <PagePartsDisplay
              {...props}
              groupId="page-modal-header"
              page={page}
              datas={{
                business,
                setting,
                site,
                page
              }}
            />
          </header>
        }

        { page &&
          <div
            id="modal-page-content-display"
            className={css.content}
            style={{
              top: heightHeader,
              zIndex: '3',
            }}>

            <Fragment>
              { (page && !template) || (page && template && templateData) ?
                <Fragment>
                  { renderRoutes({
                    routes: props.route.routes,
                    extraProps: {
                      business,
                      setting,
                      site,
                      pages,
                      page,
                      templateData,
                      closeWindowModal: props.closeWindowModal
                    },
                    location: props.location,
                  }) }
                </Fragment>
              :
                <h1>404 Not Found</h1>
              }
            </Fragment>
          </div>
        }
      </div>

      <div id="widgets">
        { page &&
          <WidgetsDisplay
            {...props}
            datas={{
              business,
              setting,
              site,
              pages,
              pageCurrent: page,
              singlePageData,
              singlePageType,
              templateData
            }}
          />
        }
      </div>
    </Fragment>
  )
}

SiteModalDisplayContainer.fetchData = (store, match) => {
  const { _id, pageType, dbProductsNo } = match.params
  const state = store.getState()
  const host = state.host

  if (pageType && _id) {
    // product
    if (pageType === 'product-details') {
      const params = getProductParams(_id)
      return store.dispatch(fetchProductOneDisplay(dbProductsNo || 'not-use-db', params.products_id))

    // article
    } else if (pageType === 'article-details') {
      return store.dispatch(fetchArticleDisplay(_id))

    // folder
    } else if (pageType === 'product-categories' || pageType === 'article-categories') {
      let type

      if (path_1 === 'product-categories') type = 'product'
      else if (path_1 === 'article-categories') type = "article"

      return store.dispatch(fetchFolderDisplay(_id, {
        body: { ...host, where: `type=${type}` }
      }))

    // tag
    } else if (pageType === 'product-tags' || pageType === 'article-tags') {
      let type

      if (path_1 === 'product-tags') type = 'product'
      else if (path_1 === 'article-tags') type = "article"

      return store.dispatch(fetchTagDisplay(_id, {
        body: { ...host, where: `type=${type}` }
      }))
    }
  }
}


const mapStateToProps = (store, props) => {
  const { site, customer, match } = props
  const { pageType, _id } = match.params
  let product, article, folder, tag

  if (pageType && _id) {
    // product
    if (pageType === 'product-details') {
      const params = getProductParams(_id)
      product = store.products.data.filter(product => product._id === params.products_id)[0]
      product = productValue({ product, customer, items_id: params.products_items_id })

    // article
    } else if (pageType === 'article-details') {
      article = store.articles.data.filter(article => article._id === _id)[0]
      article = articleValue({ article })

    // folder
    } else if (pageType === 'product-categories' || pageType === 'aricle-categories') {
      folder = store.folders.data.filter(folder => folder.path === _id)[0]

    // tag
    } else if (pageType === 'product-tags' || pageType === 'aricle-tags') {
      tag = store.tags.data.filter(tag => tag.path === _id)[0]
    }
  }

  return {
    app: store.app,
    host: store.host,
    auth: store.auth,
    business: store.businesses.data[0],
    setting: store.settings.data[0],
    pages: store.pages.data.filter((page => page.sites_id === site._id)),
    product,
    products: store.products.data,
    article,
    articles: store.articles.data,
    folder,
    tag
  }
}

export default connect(mapStateToProps)(SiteModalDisplayContainer)
