import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import TextEditorImage from './TextEditorImage'
import TextEditorImageDisplay from './TextEditorImageDisplay'

let TextEditorImage = lazy(() => import('./TextEditorImage'))
TextEditorImage = suspenseComponent(TextEditorImage)

export {
  TextEditorImage,
  TextEditorImageDisplay
}
