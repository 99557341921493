import React from 'react'

// Components Global
import ColorPicker from '../../../../../ColorPicker'

// css
import css from './css/inputBorder.css'


const InputBorder = (props) => {
  const {
    label, position,
    borderWidth, borderStyle, borderColor,
    borderWidthChange, borderStyleChange, borderColorChange
  } = props

  const handleBorderColorChange = (color) => {
    borderColorChange(color, position)
  }

  return (
    <div className={css.boxInput}>
      <table>
        <tbody>
          <tr>
            <td width="25%" className={`pd-0 font-0-8 center bg-eee ${css.tdFirst}`}>
              { label }
            </td>
            <td width="25%" className="pd-0 font-0-8 center">
              <input
                value={borderWidth}
                onChange={(e) => borderWidthChange(e, position)}
                className={`${css.input} ${css.inputBorderNone} ${css.inputFirst} center`}
                type="text"
              />
            </td>
            <td width="25%" className="pd-0 font-0-8 center">
              <select
                className={`browser-default ${css.input} ${css.select} ${css.inputBorderNone}`}
                onChange={(e) => borderStyleChange(e, position)}
                value={borderStyle}>

                <option value="solid">solid</option>
                <option value="dotted">dotted</option>
                <option value="dashed">dashed</option>
                <option value="double">double</option>
                <option value="hidden">hidden</option>
                <option value="ridge">ridge</option>
                <option value="inset">inset</option>
                <option value="outset">outset</option>
              </select>
            </td>

            <td width="25%" className="pd-0 center line-height-0 border-left-eee">
              <ColorPicker
                advanced
                right
                label="สีเส้นขอบ"
                onChange={handleBorderColorChange}
                styleDropdown={{ top: '35px', left: undefined, right: '0' }}
                style={{ width: '100%' }}>

                <div className={css.boxColorSeleted}>
                  <div
                    className={`${css.colorSeleted} ${css.inputLast}`}
                    style={{ background: borderColor }}
                  />
                </div>
              </ColorPicker>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default InputBorder
