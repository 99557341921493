import React, { Component } from 'react'
import Compressor from 'compressorjs'

// css
import css from './css/inputFile.css'

const qualityDefault = 0.5 //0.7, 0.3

class InputFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      maxFile: this.props.maxFile ? parseInt(this.props.maxFile) : 10,
      maxSize: this.props.maxSize ? parseInt(this.props.maxSize) : 12,
      quality: qualityDefault
    }
  }

  /*componentDidUpdate(prevProps) {
    if (prevProps.files !== this.state.files) {
      this.setState({ files: this.state.files })
    }
  }*/

  componentDidUpdate(prevProps) {
    if (prevProps.quality !== this.props.quality) {
      this.setState({ quality: this.props.quality })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.files !== this.state.files) {
      this.setState({ files: nextProps.files })
    }
  }

  handleSelect = (e) => {
    let files = e.target.files

    if (files.length <= this.state.maxFile) {
      for (let i = 0; i < files.length; i++) {
        ((file) => {
          const reader = new FileReader()
          const maxSize = 1024*1024*this.state.maxSize
          file = files[i]

          new Compressor(file, {
            quality: this.state.quality,
            maxWidth: 2500,
            maxHeight: 2500,

            success: (result) => {
              const formData = new FormData()

              // The third parameter is required for server
              formData.append('file', result, result.name)

              reader.onload = (e) => {
                if (file.type === "image/jpeg" || file.type === "image/png") {
                  if (file.size <= maxSize.toFixed(0)) {
                    if (this.props.getFiles) {
                      this.props.getFiles(result, reader.result)
                    }
                  } else {
                    alert(`ไม่สามารถอัพโหลด "${file.name}" เนื่องจาก ไฟล์ของคุณมีขนาดเกิน ${this.state.maxSize.toFixed(1)} MB`)
                  }

                } else {
                  alert(`ไม่สามารถอัพโหลด "${file.name}" เนื่องจาก ประเภทของไฟล์ไม่ถูกต้อง (รองรับเฉพาะ jpeg, png, jpg)`)
                }
              }

              reader.readAsDataURL(result)
            },
            error: (err) => {
              console.log(err.message)
            }
          })

          /*reader.onload  = (e) => {
            if (file.type === "image/jpeg" || file.type === "image/png") {

              const maxSize = 1024*1024*this.state.maxSize

              if (file.size <= maxSize.toFixed(0)) {
                if (this.props.getFiles) {
                  this.props.getFiles(file, reader.result)
                }
              } else {
                alert(`ไม่สามารถอัพโหลด "${file.name}" เนื่องจาก ไฟล์ของคุณมีขนาดเกิน ${this.state.maxSize.toFixed(1)} MB`)
              }

            } else {
              alert(`ไม่สามารถอัพโหลด "${file.name}" เนื่องจาก ประเภทของไฟล์ไม่ถูกต้อง (รองรับเฉพาะ jpeg, png, jpg)`)
            }
          }

          reader.readAsDataURL(file)*/

        })(files[i])
      }
    } else {
      alert(`คุณสามารถอัพโหลดไฟล์ได้ไม่เกิน ${this.state.maxFile} ไฟล์`)
    }


    this.refs[this.props.name].value = ""
  }

  render() {
    return (
      <div className={css.boxInput} style={this.props.style}>
        <div className="center pd-top-10 font-0-8">{this.state.quality}</div>

        <input
          accept="image/*"
          multiple={this.props.multiple}
          id={this.props.name}
          ref={this.props.name}
          type="file"
          className={`${css.input} ${this.state.files.length === 0 && this.props.required ? 'required invalid' : 'required valid'}`}
          onChange={(e) => this.handleSelect(e)}
        />

        { this.props.children }
      </div>
    )
  }
}

export default InputFiles
