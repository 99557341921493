import { fetchDatas, fetchData, createData, updateData, removeData } from '../reduxActions'
import { generateTypeName, generateTypeFunctions } from '../reduxTypes'

// Export Constants
const NAME_TYPE = ['RESERVE_TOURS', 'RESERVE_TOUR']
const name = "reserve-tours"

// Type Names and Type Functions
export const { ADDS, ADDS_MORE, ADD, UPDATE, REMOVE } = generateTypeName(NAME_TYPE)
export const { adds, adds_more, add, update, remove } = generateTypeFunctions(NAME_TYPE)

// Export Actions
export const fetchReserveTours = (businesses_id, params) => dispatch => {
  return fetchDatas({
    dispatch,
    params,
    api: `${name}/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchReserveTour = (businesses_id, _id, params = {}) => dispatch => {
  return fetchData({
    dispatch,
    params,
    api: `${name}/${businesses_id}/${_id}`,
    add: add
  })
}

export const createReserveTour = (businesses_id, params) => dispatch => {
  return createData({
    dispatch,
    params,
    api: `${name}/${businesses_id}/create`,
    add: add
  })
}

export const updateReserveTour = (businesses_id, _id, params) => dispatch => {
  return updateData({
    dispatch,
    params,
    api: `${name}/${businesses_id}/${_id}`,
    update: update,
    updateId: _id
  })
}

export const removeReserveTour = (businesses_id, _id, params) => dispatch => {
  return removeData({
    dispatch,
    params,
    api: `${name}/${businesses_id}/${_id}`,
    remove: remove,
    deleteId: _id
  })
}
