import React from 'react'

// Components Text Editor
import BtnElementManage from '../../../../components/ElementStylesManage/components/BtnElementManage'
import BadgeStyleManage from '../../../../components/ElementStylesManage/components/BadgeStyleManage'
import BlockStyleSettings from '../../../BlockStyleSettings'

// Components Local
import ButtonDisplay from '../ButtonDisplay'


const StyleSettings = (props) => {
  const { _this, mode, device } = props
  const { icon, link } = _this.state


  let name = 'button', nameBadge = 'badge'

  if (device && device !== 'desktop') {
    name = `${name}-${device}`
    nameBadge = `${nameBadge}-${device}`
  }

  return (
    <div>
      <div className="bg-fff pd-10 mg-top-10">
        <div className="font-0-9">แสดงตัวอย่าง</div>
        <div className="pd-top-10">
          <ButtonDisplay
            link={link}
            style={_this.state.style}
            icon={icon}
            name="ตัวอย่างปุ่มกด"
            mode={mode}
            className={_this.state.class}
          />
        </div>
      </div>

      <BlockStyleSettings hiddenResponsive hiddenBackground _this={_this} device={device} />

      <BtnElementManage
        {...props}
        styleHover
        styleActiveClick
        name={name}
        title="ปุ่มกด"
        icon="crop_16_9"
        hidden={{
          opacity: true
        }}
      />

      <BadgeStyleManage {...props} name={nameBadge} />
    </div>
  )
}

export default StyleSettings
