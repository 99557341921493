export const getURLSearchParams = (search) => {
  const query = search && new URLSearchParams(search)

  let data= {}

  if (query) {
    for (let params of query) {
      data[params[0]] = params[1]
    }
  }

  return data
}

export const updateURLSearchParams = ({ search, name, value }) => {
  let params = new URLSearchParams(search)
  let paramValue = params.get(name)

  if (!paramValue) {
    if (value) {
      params.append(name, value)
    }
  } else {
    if (value) {
      params.set(name, value)
    } else {
      params.delete(name)
    }
  }

  return params.toString()
}
