import cuid from 'cuid'

// Redux Actions
import {
  fetchFoldersAndParents,
  updateFolder,
  removeFolder,
  uploadImagesFolder
} from './FoldersActions'

import {
  updateProducts
} from '../Products/ProductsActions'



const title = "หมวดหมู่"

// Main
export const onEditModal = () => {
  return {
    modal: { id: 'folderUpdateModal' }
  }
}

export const onMoveModal = () => {
  return {
    modal: { id: 'folderMoveModal' }
  }
}

export const onEdit = (url) => {
  return { url }
}

export const onView = (url) => {
  return { url }
}

export const handleSwitchChange = ({ props, params, type, toastMsg }) => {
  return (id) => {
    const { business, data, dispatch } = props

    dispatch(updateFolder(business._id, data._id, {
      body: params,
      toastMsg: toastMsg,
      loadId: id,
      confirmId: id
    })).then(res => {
      if (type === 'product') {
        const query = {
          authName: 'admin',
          dbNo: business.dbNo,
          businessType: business.type,
          businesses_id: business._id
        }

        dispatch(updateProducts(query, {
          body: {
            where: `folders_id=${id}`,
            updateValue: {
              showOnWeb: params.showOnWeb
            }
          }
        }))
      }
    })
  }
}


export const onDelete = (props, params) => {
  const handleDelete = (props, toastMsg, params={}) => {
    return (id) => {
      props.dispatch(removeFolder(props.business._id, id, {
        toastMsg: toastMsg,
        loadId: id,
        confirmId: id
      }))
    }
  }

  return {
    label: `ลบ${title}`,
    onConfirm: handleDelete(props, `ลบ${title}นี้ อย่างถาวรแล้ว`, params)
  }
}


// Images Main
export const uploadImage = (props) => {
  return (id, files, album) => {
    if (files) {
      props.dispatch(uploadImagesFolder(props.business._id, props.folder._id, files, 'folders', {
        body: {
          updateType: 'push',
          updateElement: 'images',
          album: album,
          profile: album === 'profile' ? true : false,
          cover: album === 'cover' ? true : false,
        },
        loadId: id,
        modalId: id,
        progressId: id
      }))
    }
  }
}

export const onDeleteImage = (props) => {
  return (id) => {
    props.dispatch(updateFolder(props.business._id, props.folder._id, {
      body: {
        updateType: 'pull-image',
        updateElement: 'images',
        deleteId: id
      },
      toastMsg: `ลบภาพนี้แล้ว`,
      loadId: id,
      confirmId: id,
      props: props
    }))
  }
}


export const getFoldersAndParents = ({ _this, notSaveStore, parent_folders_id }) => {
  const { business, match, foldersManage } = _this.props

  let type, folders_id

  if (foldersManage) {
    type = foldersManage.type
    folders_id = match.params.folders_id

  } else {
    type = _this.props.type
    folders_id = _this.props.folders_id
  }

  let loadId = 'fetch-folders-loading'

  if (notSaveStore) {
    folders_id = parent_folders_id
    loadId = 'fetch-folders-modal-loading'
  }

  const where = `type=${type},folders_id=${folders_id || null}`

  _this.props.dispatch(fetchFoldersAndParents(business._id, folders_id, {
    body: {
      where,
      sort: `-_id`,
    },
    notSaveStore,
    loadId

  })).then(res => {
    if (res.success) {
      let parents = res.parents
      let length = parents.length
      let newParent = []

      for (let i=length-1; i>=0; i--) {
        newParent.push(parents[i])
      }

      if (notSaveStore) {
        _this.setState({ folders: res.data, parents: newParent })
      } else {
        _this.setState({ parents: newParent })
      }
    }
  })
}

export const getFoldersText = ({ folders, lang }) => {
  let text = ''

  if (folders) {
    folders.map((folder, i) => {
      if (i === 0) {
        if (folder && folder.name && folder.name[lang]) text = `${folder.name && folder.name[lang]}`
      } else {
        if (folder && folder.name && folder.name[lang]) text = `${text} > ${folder.name && folder.name[lang]}`
      }
    })
  }

  return text
}
