import { productItemValue } from '../Products/ProductsValues'
import { customerAddressValue } from '../Customers/CustomersValues'
import { businessShippingFeeValue, businessPaymentMethodValue } from '../Businesses/BusinessesValues'
import { getShippingFee } from '../Businesses/ShippingFeeValues'
import {
  getPriceProductsAllOnly,
  getProductsWeightAll as getProductsWeightAllForOrder
} from '../Orders/OrdersValues'



export const cartValue = ({ business, cart, auth, lang='local' }) => {
  if (cart) {
    let customer, address, shippingFee, paymentMethod, qty

    if (cart) {
      customer = cart.customers_id
      address = customerAddressValue({
        customer,
        address: (auth && !auth.user) && cart.shippingAddress,
        addresses_id: (!auth || (auth && auth.user)) && cart.customers_addresses_id,
        lang
      })
      shippingFee = business && businessShippingFeeValue({ business, shipping_fees_id: cart.businesses_shipping_fees_id, lang })
      paymentMethod = business && businessPaymentMethodValue({ business, payment_methods_id: cart.businesses_payment_methods_id, lang })
      qty = getQtyAll({ cart })
    }

    return {
      ...cart,
      qty,
      address,
      shippingFee,
      paymentMethod
    }
  }
}

export const getQtyAll = ({ cart }) => {
  let qty = 0

  cart && cart.items && cart.items.map(item => qty += item.qty)

  return qty
}


/* product price discounted */
export const getSalePrice = (discount, price) => {
  let salePrice

  if (discount) {
    if (discount.type === 'currency') {
      salePrice = price - discount.value

    } else if (discount.type === 'percent') {
      salePrice = price - (price * discount.value / 100)
    }
  } else {
    salePrice = price
  }

  return salePrice
}

/* price */
export const getItemPrice = (item) => {
  if (item) {
    return getSalePrice(item.discount, item.price)
  }
}

/* price total */
export const getItemPriceTotal = (item, qty) => {
  if (item) {
    let price = getItemPrice(item)

    return price * qty
  }
}

/* price item total all */
export const getPriceProduct = (item, qty) => {
  let priceProduct = getItemPriceTotal(item, qty)
  let priceTotal = priceProduct
  priceTotal = priceTotal && parseFloat(priceTotal.toFixed(2))

  return priceTotal
}

// price all
export const getPriceProductsAll = (cart) => {
  let price = 0

  if (cart) {
    const items = cart.items || []

    items.map(pd => {
      const product = pd.products_id
      const item = productItemValue({ product, products_items_id: pd.products_items_id })

      price += getPriceProduct(item, pd.qty)
    })
  }

  price = parseFloat(price.toFixed(2))

  return price
}

// prices weight all
export const getProductsWeightAll = (cart) => {
  let weight = 0

  if (cart) {
    cart.items.map(pd => {
      weight += pd.weight
    })
  }

  weight = parseFloat(weight.toFixed(2))

  return weight
}

// price shipping fee
export const getShippingFeePrice = ({ cart, order, shippingFee }) => {
  const data = order || cart
  const { address, items } = data

  if (!shippingFee) {
    shippingFee = data.shippingFee
  }

  let price = 0

  if (data && shippingFee) {
    const { geo } = address || {}
    let orderItems = items

    let priceProductsAll, productWeight

    if (order) {
      priceProductsAll = getPriceProductsAllOnly(data)
      productWeight = getProductsWeightAllForOrder(data)
      orderItems = orderItems.filter(item => item.type !== 'payment-fee' && item.type !== 'shipping-fee')
    } else {
      priceProductsAll = getPriceProductsAll(data)
      productWeight = getProductsWeightAll(data)
    }

    price = getShippingFee({ orderItems, shippingFee, productWeight, priceProductsAll, geo })
    price = price.shippingPriceTotal
  }

  price = parseFloat(price.toFixed(2))

  return price
}

export const getPriceShippingFee = (cart, shippingFee) => {
  const { address, items } = cart

  if (!shippingFee) {
    shippingFee = cart.shippingFee
  }

  let price = 0

  if (cart && shippingFee) {
    const { geo } = address || {}
    const productWeight = getProductsWeightAll(cart)
    const priceProductsAll = getPriceProductsAll(cart)
    const orderItems = items

    price = getShippingFee({ orderItems, shippingFee, productWeight, priceProductsAll, geo })
    price = price.shippingPriceTotal
  }

  price = parseFloat(price.toFixed(2))

  return price
}

// price payment fee
export const getPricePaymentFee = ({ cart, paymentMethod, shippingFee, totalBeforePaymentFee }) => {
  if (cart && !paymentMethod) {
    paymentMethod = cart.paymentMethod
  }

  if (cart && !shippingFee) {
    shippingFee = cart.shippingFee
  }

  if (!totalBeforePaymentFee) {
    totalBeforePaymentFee = getTotalBeforePaymentFee(cart, shippingFee)
  }

  let price = 0

  if (paymentMethod) {
    let { feeType, feePrice } = paymentMethod

    if (shippingFee) {
      if (paymentMethod.id === 'cashOnDelivery') {
        const { cashOnDelivery } = shippingFee

        if (cashOnDelivery && cashOnDelivery.status) {
          feeType = cashOnDelivery.feeType
          feePrice = cashOnDelivery.feePrice
        }
      }

      if (feeType === 'currency') {
        price = feePrice
      } else if (feeType === 'percent') {
        price = totalBeforePaymentFee * feePrice / 100
      }
    }
  }

  price = parseFloat(price.toFixed(2))
  price = Math.round(price)

  return price
}


//discount
export const getDiscount  = (cart) => {
  let discount = 0
  let totalPriceProducts = getPriceProductsAll(cart)

  if (cart && cart.discount) {
    if (cart.discount.type === 'currency') {
      discount = cart.discount.value
    } else if (cart.discount.type === 'percent') {
      discount = totalPriceProducts * cart.discount.value / 100
    }
  }

  discount = parseFloat(discount.toFixed(2))
  discount = Math.round(discount)

  return discount
}

// coupon
export const getCoupon = (order) => {
  return 0
}

// total after discount
export const getTotalAfterDiscount = (cart) => {
  let totalPriceProducts = getPriceProductsAll(cart)
  let discount = getDiscount(cart)
  let coupon = getCoupon(cart)
  let totalAfterDiscount = totalPriceProducts - discount - coupon

  totalAfterDiscount = parseFloat(totalAfterDiscount.toFixed(2))

  return totalAfterDiscount
}

// total before payment fee
export const getTotalBeforePaymentFee = (cart) => {
  const totalAfterDiscount = getTotalAfterDiscount(cart)
  const priceShippingFee = getPriceShippingFee(cart)

  let totalPriceAll = totalAfterDiscount + priceShippingFee

  totalPriceAll = parseFloat(totalPriceAll.toFixed(2))

  return totalPriceAll
}


// get total price all
export const getTotalPriceAll = (cart) => {
  const totalAfterDiscount = getTotalAfterDiscount(cart)
  const priceShippingFee = getPriceShippingFee(cart)
  const pricePaymentFee = getPricePaymentFee({ cart })

  let totalPriceAll = totalAfterDiscount + priceShippingFee + pricePaymentFee

  totalPriceAll = parseFloat(totalPriceAll.toFixed(2))

  return totalPriceAll
}
