import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

// actions redux
import { removeLoad } from '../../../redux/App/AppActions'

// Js
import { setStyleValue } from '../../TextEditor/components/ElementStyles/components/js/setDivStyle'

// css
import css from './css/btnJsxSubmit.css'


class BtnJsxSubmit extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    if (this.props.loadingId && this.props.load) {
      this.props.dispatch(removeLoad(this.props.loadingId))
    }
  }

  componentWillUnmount() {
    if (this.props.loadingId && this.props.load) {
      this.props.dispatch(removeLoad(this.props.loadingId))
    }
  }

  render() {
    const iconAlign = (this.props.iconAlign ? this.props.iconAlign : 'left')
    const { name, style, onClick } = this.props

    let boxIconPercent = (20/36)*100
    let height = style[`${name}-height`] || '36'
    height = parseInt(height)

    let boxIcon = (height/100)*boxIconPercent
    let boxIconMarginTop = (height-boxIcon)/2

    return(
      <div style={{ display: 'inline' }}>
        { (this.props.loadingId && this.props.load || this.props.loadTest) ?
          <div className={`${css.btn} ${css.btnLoading} btn btn-shadow-none btn-element-jsx`}>
            <div className={`${css.boxIcon} box-icon-jsx`}>
              <div className={`la-ball-clip-rotate la-sm ${css.boxIconLoading}`}>
                <div className={`${css.iconLoading}`}></div>
              </div>
            </div> {this.props.text}...
          </div>
        :
          <Fragment>
            { onClick ?
              <div
                onClick={onClick}
                data-disabled={true}
                data-hidden={this.props.btnHidden}
                className={`${css.btn} btn btn-shadow-none btn-element-jsx`}>

                { this.props.icon ?
                  <i className={`material-icons ${this.props.iconClassName || ''} ${this.props.iconAlign}`}>{this.props.icon}</i>
                :
                  <i className={`${this.props.iconFa} ${this.props.iconClassName || ''} ${iconAlign}`}></i>
                }

                {this.props.text}
              </div>
            :
              <button
                id={this.props.loadingId}
                type="submit"
                disabled={this.props.notDisabled ? undefined : true}
                data-disabled={this.props.notDisabled ? undefined : true}
                data-hidden={this.props.btnHidden}
                className={`${css.btn} btn btn-shadow-none btn-element-jsx`}>

                { this.props.icon ?
                  <i className={`material-icons ${this.props.iconClassName || ''} ${this.props.iconAlign || ''}`}>{this.props.icon}</i>
                :
                  <i className={`${this.props.iconFa} ${this.props.iconClassName || ''} ${iconAlign}`}></i>
                }

                {this.props.text}
              </button>
            }
          </Fragment>
        }

        <style jsx>{`
          .btn-element-jsx {
            ${setStyleValue({ name, style  })}
          }

          .box-icon-jsx {
            width: ${boxIcon}px;
            height: ${boxIcon}px;
            margin: ${style[`${name}-icon-margin`]};
            margin-top: ${boxIconMarginTop}px;
          }

          .btn-element-jsx:hover {
            ${setStyleValue({ name: `${name}-hover`, style  })}
          }

          .btn-element-jsx:active {
            ${setStyleValue({ name: `${name}-active-click`, style  })}
          }

          .active-jsx {
            ${setStyleValue({ name: `${name}-active`, style  })}
          }

          .btn-element-jsx > :global(i.material-icons), {
            font-size: ${style[`${name}-icon-size`]};
            margin: ${style[`${name}-icon-margin`]};
          }


          @media (min-width: 1500px) {
            .btn-element-jsx {
              ${setStyleValue({ name, style, vw: true })}
            }

            .box-icon-jsx {
              width: ${boxIcon/15}vw;
              height: ${boxIcon/15}vw;
              margin: ${style[`${name}-icon-margin-vw`]};
              margin-top: ${boxIconMarginTop/15}vw;
            }

            .btn-element-jsx > :global(i.material-icons) {
              font-size: ${style[`${name}-icon-size-vw`]};
              margin: ${style[`${name}-icon-margin-vw`]};
            }
          }

          @media (min-width: 601px) and (max-width: 1024px) {
            .btn-element-jsx {
              ${setStyleValue({ device: 'tablet', name, style })}
            }
          }

          @media (min-width: 50px) and (max-width: 600px) {
            .btn-element-jsx {
              ${setStyleValue({ device: 'mobile', name, style })}
            }
          }
        `}</style>
      </div>
    )
  }
}

const mapStateToProps = (store, props) => {
  return {
    load: store.app.loads.filter(load => load === props.loadingId)[0]
  }
}

export default connect(mapStateToProps)(BtnJsxSubmit)
