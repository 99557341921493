import { fetchData, fetchDataReplace, createData, updateData, removeData, fetchApi } from 'core/redux/reduxActions'
import { addsDatasMany } from 'core/redux/reduxActionsV2'
import { generateTypeName, generateTypeFunctions } from 'core/redux/reduxTypesV2'

import uploadsForS3 from 'core/utils/uploadsForS3'
import { checkAppActions } from 'core/redux/App/AppActions'


// Export Constants
const NAME_TYPE = ['CONVERSATION_CHANNELS', 'CONVERSATION_CHANNEL']
const name = "conversation-channels"

// Type Names and Type Functions
export const {
  ADDS, ADDS_MORE, ADD, UPDATE, REMOVE,
  ADDS_MANY, ADDS_MANY_MORE,
} = generateTypeName(NAME_TYPE)

export const {
  adds, adds_more, add, update, remove,
  adds_many, adds_many_more,
} = generateTypeFunctions(NAME_TYPE)



// Export Actions
export const countConversationChannels = (query, params = {}) => dispatch => {
  const { authName, dbNo, businesses_id } = query

  return fetchApi({
    dispatch,
    params,
    api: `${name}-count/${authName}/${dbNo}/${businesses_id}`,
  })
}

export const checkConversationChannelDuplicate = (query, params = {}) => dispatch => {
  const { businesses_id } = query

  return fetchApi({
    dispatch,
    params,
    api: `${name}-query/${businesses_id}/check-value-duplicate?${params.query}`
  })
}


export const fetchQueryConversationChannelOne = (query, params = {}) => dispatch => {
  const { authName, businesses_id, conversation_channels_id, _id } = query

  return fetchData({
    dispatch,
    params,
    api: `${name}-one/${authName}/${businesses_id}/${_id || conversation_channels_id}`,
    add: add
  })
}

export const fetchReplaceQueryConversationChannelOne = (query, params) => dispatch => {
  const { authName, businesses_id, conversation_channels_id, _id } = query

  return fetchDataReplace({
    dispatch,
    params,
    api: `${name}-one/${authName}/${businesses_id}/${_id || conversation_channels_id}`,
    update: update,
    updateId: _id || conversation_channels_id
  })
}

export const fetchQueryConversationChannelsMany = (query, params = {}) => dispatch => {
  const { authName, businesses_id } = query

  return addsDatasMany({
    dispatch,
    params,
    api: `${name}-many/${authName}/${businesses_id}`,
    adds_many,
    adds_many_more
  })
}

export const createQueryConversationChannelOne = (query, params) => dispatch => {
  const { authName, businesses_id } = query

  return createData({
    dispatch,
    params,
    api: `${name}-one/${authName}/${businesses_id}/create`,
    add: add
  })
}

export const updateQueryConversationChannelOne = (query, params) => dispatch => {
  const { authName, businesses_id, conversation_channels_id, _id } = query

  return updateData({
    dispatch,
    params,
    api: `${name}-one/${authName}/${businesses_id}/${_id || conversation_channels_id}`,
    update: update,
    updateId: _id || conversation_channels_id
  })
}

export const uploadImagesConversationChannel = (query, fileParams, params) => dispatch => {
  const { files, imagePrefix } = fileParams
  const { businesses_id } = query

  checkAppActions(dispatch, params, 'start')
  uploadsForS3({
    files,
    prefix: imagePrefix,
    progressId: params.progressId,
    dispatch,
    businesses_id
  }).then(val => {
    val.map(paths => {
      params.body.paths = paths
      dispatch(updateQueryConversationChannelOne(query, params))
    })
  })
}


export const removeConversationChannel = (query, params) => dispatch => {
  const { authName, businesses_id, conversation_channels_id, _id } = query

  return removeData({
    dispatch,
    params,
    api: `${name}-one/${authName}/${businesses_id}/${_id || conversation_channels_id}`,
    remove: remove,
    deleteId: _id || conversation_channels_id
  })
}

export const checkValueDuplicate = (query, params = {}) => dispatch => {
  const { authName, businesses_id } = query

  return fetchApi({
    dispatch,
    params,
    api: `${name}-query/${businesses_id}/check-value-duplicate`
  })
}

export const getChannelInfo = (query) => {
  const { authName, businesses_id, conversation_channels_id } = query

  return fetchApi({
    dispatch,
    params,
    api: `${name}-query/${businesses_id}/${conversation_channels_id}/check-value-duplicate`
  })
}





