// @lang

import { getAttributeItems, getDataAttributes } from '../Attributes/AttributesValues'
import { getTagsText } from '../Tags/TagsFunctions'


export const articleValue = ({ article, attribute, lang="local" }) => {
  let titleLang, avatarName, descriptionsLang
  let typeName
  let statusName, statusIcon, statusColor
  let seoTitleLang, seoDescriptionLang, seoKeywordsLang
  let attributeItems, dataAttributes, tagsText

  if (article) {
    tagsText = getTagsText({ tags: article.tags, lang })
    titleLang = article.title ? article.title[lang] : null
    avatarName = titleLang ? [titleLang] : null
    descriptionsLang = article.descriptions ? article.descriptions[lang] : null

    const { seo } = article

    if (seo) {
      seoTitleLang = seo.title ? seo.title[lang] : null
      seoDescriptionLang = seo.description ? seo.description[lang] : null
      seoKeywordsLang = seo.keywords ? seo.keywords[lang] : null
    }

    if (attribute) {
      attributeItems = getAttributeItems({ data: article, attribute, lang })
    }

    dataAttributes = getDataAttributes({ data: article, attribute })

    // typeName
    if (article.type === 'article') {
      if (lang === 'local') typeName = 'บทความ'
      else typeName = 'article'
    } else if (article.type === 'new') {
      if (lang === 'local') typeName = 'ข่าวสาร'
      else typeName = 'new'
    }

    // Status
    if (article.status === 'open') {
      if (lang === 'local') statusName = 'เปิด'
      else statusName = article.status

      statusIcon = "public"
      statusColor = "success"

    } else if (article.status === 'close') {
      if (lang === 'local') statusName = 'ปิด'
      else statusName = article.status

      statusIcon = "lock"
      statusColor = "warning"

    } else if (article.status === 'trash') {
      if (lang === 'local') statusName = 'ถังขยะ'
      else statusName = article.status

      statusIcon = "delete"
      statusColor = "error"
    }
  }

  return {
    ...article,
    titleLang,
    avatarName,
    typeName,
    tagsText,
    statusName, statusIcon, statusColor,
    descriptionsLang,
    seoTitleLang, seoDescriptionLang, seoKeywordsLang,
    attributeItems, dataAttributes
  }
}
