import React, { Component, Fragment } from 'react'


// Components Text Editor
import BoxSettings from '../BoxSettings'

import {
  SwitchStyleSettings,
  SelectStyleSettings,
  InputStyleSettings,
  TextareaStyleSettings
} from '../StyleSettings'


class ConditionSetting extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.getValue()
    }
  }

  getValue = () => {
    const { _this } = this.props
    const { style } = _this.state

    return {

    }
  }

  render() {
    const { _this } = this.props
    const { style } = _this.state
    const conditionDataType = style['conditionDataType']
    const conditionKeyAdvanced = style['conditionKeyAdvanced']

    return (
      <BoxSettings title="เงื่อนไขการแสดง" icon="visibility">
        <div className="pd-10 pd-top-0">
          <SwitchStyleSettings
            _this={_this}
            label="เปิดใช้งานเงื่อนไข"
            styleName="conditionOpen"
          />
        </div>

        <div className="pd-10 pd-top-0">
          <SwitchStyleSettings
            _this={_this}
            label="เปิดใช้งานแบบขั้นสูง"
            styleName="conditionKeyAdvanced"
          />
        </div>

        <div className="pd-10 bg-fafafa">
          <SelectStyleSettings
            label="ประเภทแหล่งดึงข้อมูล"
            _this={_this}
            styleName="conditionDataType"
            options={[
              { label: 'เลือกประเภท', value: '' },
              { label: 'ภาษา (lang)', value: 'lang' },
              { label: 'API (ค่าเดิม)', value: 'api' },
              { label: 'API Template', value: 'api-template' },
              { label: 'ข้อมูลใน Store', value: 'data-store' },
              { label: 'ข้อมูลใน URL Search', value: 'url-search' },
              { label: 'หน้าเว็บ (location)', value: 'location' },
              { label: 'หน้าเว็บ (match params)', value: 'match-params' },
            ]}
          />

          { conditionDataType === 'data-store' &&
            <div className="mg-top-10">
              <SelectStyleSettings
                label="ข้อมูลใน Store"
                _this={_this}
                styleName="conditionDataStoreName"
                options={[
                  { label: 'เลือกข้อมูล', value: '' },
                  { label: 'ข้อมูลผู้ใช้งาน (user)', value: 'user' },
                  { label: 'ข้อมูลลูกค้า (customer)', value: 'customer' },
                  { label: 'ข้อมูลธุรกิจ (business)', value: 'business' },
                  { label: 'ตะกร้า (cart)', value: 'cart' },
                ]}
              />
            </div>
          }

          { conditionDataType === 'lang' &&
            <Fragment>
              <div className="pd-top-10 bg-fafafa">
                <SelectStyleSettings
                  label="เงื่อนไข"
                  _this={_this}
                  styleName="conditionOperator"
                  options={[
                    { label: 'เลือกเงื่อนไข', value: '' },
                    { label: 'เท่ากับ (==)', value: '==' },
                    { label: 'ไม่เท่ากับ (!=)', value: '!=' },
                  ]}
                />
              </div>

              <div className="pd-top-10">
                <SelectStyleSettings
                  label="เลือกภาษา"
                  _this={_this}
                  styleName="conditionValue"
                  options={[
                    { label: 'เลือกภาษา', value: '' },
                    { label: 'ไทย (TH)', value: 'th' },
                    { label: 'อังกฤษ (EN)', value: 'en' },
                  ]}
                />
              </div>
            </Fragment>
          }
        </div>


        { conditionDataType &&
          <Fragment>
            { conditionDataType !== 'lang' &&
              <Fragment>
                <div className="pd-10">
                  { conditionKeyAdvanced ?
                    <TextareaStyleSettings
                      label="คีย์แบบเต็ม (key full)"
                      _this={_this}
                      type="text"
                      styleName="conditionKey"
                    />
                  :
                    <InputStyleSettings
                      label="คีย์แบบเต็ม (key full)"
                      _this={_this}
                      type="text"
                      styleName="conditionKey"
                    />
                  }
                </div>

                { !conditionKeyAdvanced &&
                  <Fragment>
                    <div className="pd-10 bg-fafafa">
                      <SelectStyleSettings
                        label="เงื่อนไข"
                        _this={_this}
                        styleName="conditionOperator"
                        options={[
                          { label: 'เลือกเงื่อนไข', value: '' },
                          { label: 'เท่ากับ (==)', value: '==' },
                          { label: 'ไม่เท่ากับ (!=)', value: '!=' },
                          { label: 'น้อยกว่า (<)', value: '<' },
                          { label: 'น้อยกว่าเท่ากับ (<=)', value: '<=' },
                          { label: 'มากกว่า (>)', value: '>' },
                          { label: 'มากกว่าเท่ากับ (>=)', value: '>=' },
                        ]}
                      />
                    </div>

                    <div className="pd-10">
                      <InputStyleSettings
                        label="ค่า (Value)"
                        _this={_this}
                        type="text"
                        styleName="conditionValue"
                      />
                    </div>

                    <div className="pd-10 bg-fafafa">
                      <SelectStyleSettings
                        label="ประเภทค่า"
                        _this={_this}
                        styleName="conditionValueType"
                        options={[
                          { label: 'เลือกประเภทค่า', value: '' },
                          { label: 'ตัวอักษร (string)', value: 'string' },
                          { label: 'ตัวเลข (number)', value: 'number' },
                          { label: 'จริงหรือเท็จ (boolean)', value: 'boolean' },
                          { label: 'ไม่มีค่า (undefined)', value: 'undefined' }
                        ]}
                      />
                    </div>
                  </Fragment>
                }
              </Fragment>
            }
          </Fragment>
        }
      </BoxSettings>
    )
  }
}

export default ConditionSetting

