import { 
  stateSearchDefault,
  stateAddDatas,
  stateAddMoreDatas,
  stateAddData,
  stateUpdate, 
  stateDelete 
} from '../reduxReducer'

import { 
  ADDS,
  ADDS_MORE,
  ADD,
  UPDATE,
  REMOVE
} from './BillsActions'


const initialState = { 
  data: [],
  search: stateSearchDefault(),
}

const BillsReducer = (state=initialState, action) => {
  const actionDatas = action.datas
  const actionData = action.data

  switch (action.type) {
    case ADDS:
      return stateAddDatas(state, action, actionDatas)

    case ADDS_MORE:
      return stateAddMoreDatas(state, action, actionDatas)
    
    case ADD:
      return stateAddData(state, action, actionData)
    
    case REMOVE:
      return stateDelete(state, action)

    case UPDATE:
      return stateUpdate(state, action, actionData)
    
    default:
      return state
  }
}

export default BillsReducer
