import React, { Component } from 'react'

// Components Global
import AlbumManager from '../AlbumManager'
import Section from '../Section'


class ContentPhotos extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  pushAlbum = (albums, imagePrefix) => {
    const { action, setting } = this.props
    const { business } = action.params

    let systemAlbums = []

    if (setting) {
      systemAlbums = setting.albums.filter(album => album.systemName === imagePrefix)
    }

    // loop album
    if (business && systemAlbums && systemAlbums.length !== 0) {
      systemAlbums.map(album => {
        let { code, crop, width, height, multiple } = album
        let title = album.label && album.label.local
        title = `${title} (${code})`

        if (crop && width && height) {
          crop = {
            title,
            width,
            height,
            circle: false,
            style: { width: '100%', height: 'auto' }
          }
        } else {
          crop = false
        }

        albums.push({
          name: code,
          title,
          maxFile: multiple === false ? 1 : 10,
          multiple: multiple,
          crop
        })
      })
    }

    return albums
  }

  render() {
    const { action, setting, hiddenAlbumImgAll, albumImgAllOny, notAuthName } = this.props
    const { data, business, imagePrefix, title } = action.params
    const dataAction = {
      authName: notAuthName ? undefined : 'admin',
      update: action.update,
      useQuery: action.useQuery,
      uploadImage: action.uploadImage,
      forItem: action.forItem,
      params: {
        business,
        setting,
        _id: data._id,
        imagePrefix
      }
    }

    const filterImgs = (album) => {
      let images

      if (action.forItem && action.forItemType) {
        images = action.forItem.images
      } else {
        images = data.images
      }

      if (album) {
        return images.filter(img => img.album === album)
      } else {
        return images
        //return images.filter(img => img.album !== 'profile' && img.album !== 'cover')
      }
    }

    let dataSettings

    if (business && imagePrefix) {
      if (action.forItem && action.forItemType) {
        dataSettings = business[`${action.forItemType}Settings`]
      } else {
        dataSettings = business[`${imagePrefix}Settings`]
      }
    }

    let albums = []

    if (dataSettings) {
      let profile, cover, public_

      if (action.forItem && action.forItemType) {
        profile = dataSettings.itemProfile
        cover = dataSettings.itemCover
        public_ = dataSettings.itemPublic
      } else {
        profile = dataSettings.profile
        cover = dataSettings.cover
        public_ = dataSettings.public
      }

      // profile
      if (profile && profile.status === true && !this.props.profileHidden) {
        albums.push({
          name: 'profile',
          title: `รูปโปรไฟล์${title || ''} (profile)`,
          maxFile: 1,
          multiple: false,
          crop: {
            title: 'รูปโปรไฟล์',
            width: profile.width || 980,
            height: profile.height || 980,
            circle: false,
            style: { width: '100%', height: 'auto' }
          }
        })
      }

      // cover
      if (cover && cover.status === true && !this.props.coverHidden) {
        albums.push({
          name: 'cover',
          title: `รูปหน้าปก${title || ''} (cover)`,
          maxFile: 1,
          multiple: false,
          crop: {
            title: 'รูปหน้าปก',
            width: cover.width || 980,
            height: cover.height || 380,
            circle: false,
            style: { width: '100%', height: 'auto' }
          }
        })
      }

      // public
      if (public_ && public_.status === true) {
        albums.push({
          name: 'public',
          title: `รูปภาพ${title || ''} (public)`,
          maxFile: 50,
          multiple: true,
          crop: public_.width && public_.height &&{
            title: 'รูปภาพ',
            width: public_.width || 980,
            height: public_.height || 980,
            circle: false,
            style: { width: '100%', height: 'auto' }
          }
        })
      }
    }

    albums = this.pushAlbum(albums, imagePrefix)

    if (this.props.albums) {
      albums = [...albums, ...this.props.albums]
    }

    return (
      <div className={`box`}>
        { !albumImgAllOny && albums.map((album, i) => {
          const { name, maxFile, multiple, crop, title } = album

          let size = '', uploadSelectSetImage

          if (crop) {
            size = `(${crop.width} x ${crop.height})`
          }


          if (this.props.selectItemProfileAlbums) {
            const use = this.props.selectItemProfileAlbums.filter(v => v === name)[0]

            if (use) {
              const set = business[`${action.forItemType}Settings`]
              const { itemProfile } = set

              if (itemProfile && itemProfile.status === true) {
                uploadSelectSetImage = {
                  album: 'profile',
                  multiple: false,
                  maxFile: 1,
                  crop: {
                    title: 'รูปโปรไฟล์',
                    width: itemProfile.width || 980,
                    height: itemProfile.height || 980,
                    circle: false,
                    style: { width: '100%', height: 'auto' }
                  }
                }
              }
            }
          }

          const images = filterImgs(name)

          let show = true

          /*if (this.props.profileHiddenOne) {
            if (name === 'profile' && images.length < 2)  {
              show = false
            }
          }*/

          if (show) {
            return (
              <Section key={i} title={`${title} ${size}`} mini={this.props.sectionMini} bodyClassName="pd-10">
                <AlbumManager
                  {...this.props}
                  id={name}
                  thumbnail="s"
                  images={images}
                  btnAdd={{ text: 'เพิ่มภาพ', icon: 'add', maxFile }}
                  upload={{
                    album: name,
                    multiple,
                    maxFile,
                    crop
                  }}
                  uploadSelectSetImage={uploadSelectSetImage}
                  delete
                  profile
                  action={dataAction}
                />
              </Section>
            )
          }
        }) }


        { !hiddenAlbumImgAll &&
          <Section title={`รูป${title || ''}ทั้งหมด`} mini={this.props.sectionMini} bodyClassName="pd-10">
            <AlbumManager
              {...this.props}
              id="imagesAll"
              thumbnail="s"
              images={filterImgs()}
              delete
              action={dataAction}
              btnAdd={{ text: 'เพิ่มภาพ', icon: 'add' }}
              upload={{
                album: 'editorContent',
                multiple: true,
                maxFile: 20
              }}
            />
          </Section>
        }
      </div>
    )
  }
}


export default ContentPhotos
