import React, { Component } from 'react'
import LazyLoad from 'react-lazyload'
import { getLazyLoadParams } from '../../../../../util/getLazyLoadParams'

// text
import { validateYouTubeUrl } from '../../../../TextEditor/TextEditorVideo/js/textEditorVideo'

// css
import css from './css/backgroundVideoDisplay.css'


class BackgroundVideoDisplay extends Component {
  constructor(props) {
    super(props)

		this.state = {
			aspectRatio: 16 / 9,
			videoHeight: 10
		}
  }

  componentDidMount() {
    setTimeout(() => {
      this.updateDimensions()
    }, 0);

    window.addEventListener("resize", this.updateDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions)
  }

  updateDimensions = () => {
    if (this.container) {
      const { aspectRatio } = this.state
      const containerWidth = this.container.clientWidth
      const containerHeight = this.container.clientHeight
      const containerAspectRatio = containerWidth / containerHeight

      let videoHeight = containerHeight
      let videoWidth = containerWidth
      let videoY = 0
      let videoX = 0

      if (containerAspectRatio > aspectRatio) {
        videoHeight = containerWidth / aspectRatio
        videoY = (videoHeight - containerHeight) / -2
      } else {
        videoWidth = containerHeight * aspectRatio
        videoX = (videoWidth - containerWidth) / -2
      }

      this.setState({
        videoHeight,
        videoWidth,
        videoY,
        videoX
      })
    }
	}

  render() {
    const { videoHeight, videoWidth, videoX, videoY } = this.state
    const { style } = this.props
    const video = style[`section-background-video`] && JSON.parse(style[`section-background-video`])

    let url = video && video.url
    let videoId

    if (url) {
      url = validateYouTubeUrl(url)

      if (url) {
        videoId = url.split('https://www.youtube.com/embed/')[1]
      }
    }

    const lazyLoadParams = getLazyLoadParams(this.props)

    if (url) {
      return  (
        <div ref={container => this.container = container} className={css.backgroundVideo}>
          <div
            className={css.videoWrapper}
            style={{
              width: videoWidth + 'px',
              height: videoHeight + 'px',
              top: videoY + 'px',
              left: videoX + 'px'
            }}>

            <LazyLoad {...lazyLoadParams}>
              <iframe
                frameBorder="0"
                src={`${url}?autoplay=1&mute=1&loop=1&modestbranding=1&controls=0&showinfo=0&playlist=${videoId}&rel=0&version=3&allowfullscreen=true&wmode=transparent&iv_load_policy=3&html5=1&widgetid=1&color=white`}
              />
            </LazyLoad>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

export default BackgroundVideoDisplay
