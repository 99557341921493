import React, { Fragment } from 'react'

// components local
import MenuIcon from '../MenuIcon'

// css
import css from './css/dropdownLists.css'

const DropdownLists = (props) => {
  const lang = props.lang || 'local'
  const {
    items,
    height,
    listHoverColor,
    listHoverBackground,
    listActiveColor,
    listActiveBackground,
    gotoPath,
    pageCurrent
  } = props

  let page

  if (pageCurrent) {
    page = items.filter(item => item._id === pageCurrent._id)[0]
  }


  return (
    <div className={`submenu ${css.submenu}`}>
      <ul style={{ display: 'grid' }}>
        { items && items.map(item => {
          const avtiveMenu = page && page.pathFull === item.pathFull ? 'active' : ''

          return (
            <li key={item._id} className={`${item.lists && css.dropdown}`}>

              <div className={`boxMenu ${avtiveMenu} ${css.boxMenu}`}>
                <div className={`boxName ${css.boxName}`}>
                  <a onClick={() => gotoPath(item.path)}>

                    <MenuIcon
                      item={item}
                      style={{ height, lineHeight: height }}
                    />

                    { item.name[lang] }
                  </a>
                </div>

                { item.lists &&
                  <div className={`boxIconMore ${css.boxIconMore}`}>
                    <i className="material-icons">keyboard_arrow_right</i>

                    <div className={`${css.dropdownContent}`}>
                      <DropdownLists
                        submenu
                        lang={lang}
                        items={item.lists}
                        height={height}
                        listHoverColor={listHoverColor}
                        listHoverBackground={listHoverBackground}
                        listActiveColor={listActiveColor}
                        listActiveBackground={listActiveBackground}
                        gotoPath={gotoPath}
                        pageCurrent={pageCurrent}
                      />
                    </div>
                  </div>
                }
              </div>
            </li>
          )
        }) }
      </ul>

      <style jsx>{`
        .submenu .boxMenu,
        .submenu .boxName, .submenu .boxName a, .boxName i,
        .submenu .boxIconMore, .submenu .boxIconMore > i {
          height: ${height};
          line-height: ${height};
        }
        .submenu ul > li > .boxMenu:hover {
          background-color: ${listHoverBackground};
        }
        .submenu ul > li > .boxMenu:hover > .boxName > a,
        .submenu ul > li > .boxMenu:hover > .boxName > a > i {
          color: ${listHoverColor};
        }
        .submenu ul > li > .boxMenu:hover > .boxIconMore > i {
          color: ${listHoverColor};
        }
        .submenu ul > li > .boxMenu.active {
          background-color: ${listActiveBackground};
        }
        .submenu ul > li > .boxMenu.active > .boxName > a,
        .submenu ul > li > .boxMenu.active > .boxName > a > i {
          color: ${listActiveColor};
        }
      `}</style>
    </div>
  )
}

export default DropdownLists
