import { fetchDatas, fetchData, createData, updateData, updateDataAll, removeData, fetchApi } from '../reduxActions'
import { generateTypeName, generateTypeFunctions } from '../reduxTypes'

import uploadsForS3 from '../../util/uploadsForS3'
import { checkAppActions } from '../App/AppActions'

// Export Constants
const NAME_TYPE = ['FOLDERS', 'FOLDER']
const name = "folders"

// Type Names and Type Functions
export const { ADDS, ADDS_MORE, ADD, UPDATE, REMOVE } = generateTypeName(NAME_TYPE)
export const { adds, adds_more, add, update, remove } = generateTypeFunctions(NAME_TYPE)


// *************** Others ***************
export const checkPathCreateFolder = (params = {}) => dispatch => {
  return fetchApi({
    dispatch,
    params,
    api: `${name}-query/check-path-create?${params.query}`
  })
}
// *************** Others ***************


// *************** Display ***************
export const fetchFoldersDisplay = (businesses_id, params = {}) => dispatch => {
  return fetchDatas({
    dispatch,
    params,
    api: `${name}-display/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchFolderDisplay = (folders_path, params = {}) => dispatch => {
  return fetchData({
    dispatch,
    params,
    api: `folder-display/${folders_path}`,
    add: add
  })
}

export const fetchFolderDisplayById = (folders_id, params = {}) => dispatch => {
  return fetchData({
    dispatch,
    params,
    api: `folder-display-by-id/${folders_id}`,
    add: add
  })
}
// *************** Display ***************




export const fetchFoldersAndParents = (businesses_id, folders_id, params = {}) => dispatch => {
  return fetchDatas({
    dispatch,
    params,
    api: `${name}-parents/${businesses_id}/${folders_id}`,
    add: adds,
    addMore: adds_more
  })
}



// Export Actions
export const fetchFolders = (businesses_id, params = {}) => dispatch => {
  return fetchDatas({
    dispatch,
    params,
    api: `${name}/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchFolder = (businesses_id, _id, params = {}) => dispatch => {
  return fetchData({
    dispatch,
    params,
    api: `${name}/${businesses_id}/${_id}`,
    add: add
  })
}

export const createFolder = (businesses_id, params) => dispatch => {
  return createData({
    dispatch,
    params,
    api: `${name}/${businesses_id}/create`,
    add: add,
    order: 'last'
  })
}

export const updateFolder = (businesses_id, _id, params) => dispatch => {
  return updateData({
    dispatch,
    params,
    api: `${name}/${businesses_id}/${_id}`,
    update: update,
    updateId: _id
  })
}

export const updateFoldersAll = (businesses_id, params) => dispatch => {
  return updateDataAll({
    dispatch,
    params,
    api: `${name}-update-all/${businesses_id}`,
    add: adds
  })
}

export const uploadImagesFolder = (businesses_id, _id, files, prefix, params) => dispatch => {
  checkAppActions(dispatch, params, 'start')

  return new Promise(resolve => {
    uploadsForS3(files, prefix, params.progressId, dispatch).then(val => {
      val.map(paths => {
        params.body.paths = paths

        return dispatch(updateFolder(businesses_id, _id, params)).then(res => {
          if (res.success) {
            res.paths = paths
          }

          resolve(res)
        })
      })
    })
  })
}

export const removeFolder = (businesses_id, _id, params) => dispatch => {
  return removeData({
    dispatch,
    params,
    api: `${name}/${businesses_id}/${_id}`,
    remove: remove,
    deleteId: _id
  })
}
