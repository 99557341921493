export const settings = (style) => {
  const type = style && style['type'] !== undefined ? style['type'] : 'content'
  const dotsDisabled = style && style['dotsDisabled'] !== undefined ? style['dotsDisabled'] : false
  const buttonsDisabled = style && style['buttonsDisabled'] !== undefined ? style['buttonsDisabled'] : 1

  const xxsResponsive = style && style['Responsive'] !== undefined ? style['Responsive'] : 1
  const xsResponsive = style && style['xsResponsive'] !== undefined ? style['xsResponsive'] : 1
  const smResponsive = style && style['smResponsive'] !== undefined ? style['smResponsive'] : 1
  const mdResponsive = style && style['mdResponsive'] !== undefined ? style['mdResponsive'] : 1
  const lgResponsive = style && style['lgResponsive'] !== undefined ? style['lgResponsive'] : 1
  const xlgResponsive = style && style['xlgResponsive'] !== undefined ? style['xlgResponsive'] : 1


  const autoPlay = style && style['autoPlay'] !== undefined ? style['autoPlay'] : false
  const infinite = style && style['infinite'] !== undefined ? style['infinite'] : false

  let autoPlayInterval = style && style['autoPlayInterval'] !== undefined ? parseInt(style['autoPlayInterval']) : 3
      autoPlayInterval = autoPlayInterval * 1000

  const buttonsPosition = style && style['buttonsPosition'] !== undefined ? style['buttonsPosition'] : 'middle'
  const buttonsFormat = style && style['buttonsFormat'] !== undefined ? style['buttonsFormat'] : 'inside'
  const dotsFormat = style && style['dotsFormat'] !== undefined ? style['dotsFormat'] : 'inside'

  /*xxs = (max-width: 330px)
  xs  = (min-width: 331px) and (max-width: 400px)
  sm  = (min-width: 401px) and (max-width: 600px)
  md  = (min-width: 601px) and (max-width: 1024px)
  lg  = (min-width: 1025px) and (max-width: 1200px)
  xlg = (min-width: 1201px)*/

  const responsive = {
    0: {
      items: parseInt(xxsResponsive)
    },
    331: {
      items: parseInt(xsResponsive)
    },
    401: {
      items: parseInt(smResponsive)
    },
    601: {
      items: parseInt(mdResponsive)
    },
    1025: {
      items: parseInt(lgResponsive)
    },
    1201: {
      items: parseInt(xlgResponsive)
    }
  }

  return {
    type,
    dotsDisabled,
    buttonsDisabled,
    autoPlay,
    autoPlayInterval,
    buttonsPosition,
    responsive,
    buttonsFormat,
    dotsFormat,
    infinite
  }
}
