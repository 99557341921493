import React from 'react'

// Components Text Editor
import Box from '../../../Box'
import LinkManage from '../../../LinkManage'
import { EditorImage } from '../../../EditorImage'

import {
  SwitchStyleSettings,
  SelectStyleSettings,
  ImageSizeSetting
} from '../../../StyleSettings'


const ImageSettings = (props) => {
  const { _this } = props
  const { content } = _this.props

  const handleImageChange = ({ value }) => {
    _this.setState({ paths: JSON.parse(value.paths) }, () => {
      _this.handleSubmit({ timeout: true })
    })
  }

  return (
    <div>
      <Box title="รูปภาพ" icon="photo" bodyClassName="pd-10">
        { !content.api &&
          <EditorImage
            action={_this.props.action}
            notDelete
            boxImageActive
            type="image-thumbnail"
            image={JSON.stringify({
              paths: JSON.stringify(_this.state.paths)
            })}
            imageStyle={{
              width: '100%',
              height: '165px',
              height: '135px',
              verticalAlign: 'middle'
            }}
            onChange={handleImageChange}
          />
        }

        { content.type === 'cover' &&
          <SelectStyleSettings
            label="ตำแหน่งเนื้อหา"
            _this={_this}
            styleName="cover-content-position"
            options={[
              { label: 'เลือกตำแหน่ง', value: '' },
              { label: 'บน', value: 'top' },
              { label: 'กลาง', value: 'middle' },
              { label: 'ล่าง', value: 'bottom' }
            ]}
          />
        }
      </Box>

      <Box title="ขนาดรูปภาพ" icon="photo" bodyClassName="pd-10">
        <SelectStyleSettings
          label="ขนาดรูปภาพ"
          _this={_this}
          styleName="image-type-size"
          options={[
            { label: 'เลือกขนาด', value: '' },
            { label: 'ขนาดเดิม (original)', value: 'o' },
            { label: 'ขนาดใหญ่ (large)', value: 'l' },
            { label: 'ขนาดกลาง (medium)', value: 'm' },
            { label: 'ขนาดเล็ก (small)', value: 's' },
            { label: 'ขนาดย่อ (thumbnail)', value: 't' },
            { label: 'ขนาดเล้ก 4 เหลี่ยม (square)', value: 'q' }
          ]}
        />
      </Box>

      <Box title="ขนาดรูปภาพ" icon="photo" bodyClassName="pd-10">
        <ImageSizeSetting
          paths={_this.state.paths}
          _this={_this}
        />
      </Box>

      <Box title="ปิด Lazyload" icon="loop" bodyClassName="pd-10">
        <SwitchStyleSettings
          _this={_this}
          label="ปิด Lazyload"
          styleName="lazy-disabled"
        />
      </Box>

      <Box title="เพิ่มรูปภาพในแกลลอรี่สไลด์โชว์" icon="loop" bodyClassName="pd-10">
        <SwitchStyleSettings
          _this={_this}
          label="เพิ่มรูปภาพในแกลลอรี่สไลด์โชว์"
          styleName="gallery-slideshow"
        />
      </Box>

      <Box title="แสดงภาพ Avatar เมื่อไม่มีภาพ" icon="account_box" bodyClassName="pd-10">
        <SwitchStyleSettings
          _this={_this}
          label="แสดงภาพ Avatar"
          styleName="avatar-show"
        />
      </Box>

      { (content.type === 'image' || content.type === 'cover' || content.type === 'navbar') &&
        <Box title="ลิงก์" icon="link" bodyClassName="pd-10" className="mg-bottom-10">
          <LinkManage
            saveAuto
            {...props}
            value={JSON.stringify(_this.state.link)}
          />
        </Box>
      }
    </div>
  )
}

export default ImageSettings
