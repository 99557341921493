import moment from 'moment'
import { getNumeral } from '../../util/getNumeral'
import { getAttributeItems, getDataAttributes } from '../Attributes/AttributesValues'
import { customerValue } from '../Customers/CustomersValues'
import { getItemParents, getItemImage } from './ProductsFunctions'
import { getFoldersText } from '../Folders/FoldersFunctions'


export const productValue = ({ product, attribute, items_id, lang="local", customer }) => {
  let nameLang, avatarName, descriptionsLang, descriptionsFull
  let typeName
  let subtypeName
  let statusName, statusIcon, statusColor
  let stockAdjustName, stockAdjustIcon, stockAdjustColor
  let itemChoiceName, itemChoiceIcon, itemChoiceColor, subitemChoiceNameLang
  let attributeItems, dataAttributes
  let item
  let price, discountPrice, discountPercent, salePrice
  let unitLang

  if (product) {
    /*const nameLocal = product.name ? product.name['local'] : null
    const descriptionsLocal = product.descriptions ? product.descriptions['local'] : null
    const descriptionsFullLocal = product.descriptionsFull ? product.descriptionsFull['local'] : null
    const subitemChoiceNameLocal = product.subitemChoiceName ? product.subitemChoiceName['local'] : null
    const unitLocal = product.unit ? product.unit['local'] : null*/

    const nameLocal = null
    const descriptionsLocal = null
    const descriptionsFullLocal = null
    const subitemChoiceNameLocal = null
    const unitLocal = null

    nameLang = product.name ? product.name[lang] || nameLocal : null
    descriptionsLang = product.descriptions ? product.descriptions[lang] || descriptionsLocal : null
    descriptionsFull = product.descriptionsFull ? product.descriptionsFull[lang] || descriptionsFullLocal : null
    subitemChoiceNameLang = product.subitemChoiceName ? product.subitemChoiceName[lang] || subitemChoiceNameLocal : null
    unitLang = product.unit ? product.unit[lang] || unitLocal : null
    avatarName = nameLang ? [nameLang] : null

    // if (product.customers_id && product.customers_id._id) {
    //   customer = customerValue({ customer, lang })
    // }

    if (attribute) {
      attributeItems = getAttributeItems({ data: product, attribute, lang })
    }

    dataAttributes = getDataAttributes({ data: product })
    item = getProductItemOfParam({ product, customer, items_id, lang })

    // typeName
    if (product.type === 'product') {
      if (lang === 'local') typeName = 'สินค้า'
      else typeName = 'product'
    } else if (product.type === 'service') {
      if (lang === 'local') typeName = 'บริการ'
      else typeName = 'service'
    }

    // Subtype Name
    if (product.subtype === 'product') {
      if (lang === 'local') subtypeName = 'สินค้า'
      else subtypeName = 'product'
    } else if (product.subtype === 'service') {
      if (lang === 'local') subtypeName = 'บริการ'
      else subtypeName = 'service'
    } else if (product.subtype === 'tour') {
      if (lang === 'local') subtypeName = 'โปรแกรมทัวร์'
      else subtypeName = ''
    } else if (product.subtype === 'tour-service') {
      if (lang === 'local') subtypeName = 'บริการเสริม'
      else subtypeName = ''
    } else if (product.subtype === 'tour-sales-note') {
      if (lang === 'local') subtypeName = 'โปรแกรมทัวร์'
      else subtypeName = ''
    }

    // Status
    if (product.status === 'open') {
      if (lang === 'local') statusName = 'เปิด'
      else statusName = 'open'

      statusIcon = "public"
      statusColor = "success"
    } else if (product.status === 'close') {
      if (lang === 'local') statusName = 'ปิด'
      else statusName = 'close'

      statusIcon = "lock"
      statusColor = "warning"

    } else if (product.status === 'trash') {
      if (lang === 'local') statusName = 'ถังขยะ'
      else statusName = 'trash'

      statusIcon = "delete"
      statusColor = "error"
    }

    // Stock Adjust
    if (product.stockAdjust === true) {
      if (lang === 'local') stockAdjustName = 'นับสต็อก'
      else stockAdjustName = 'count'

      stockAdjustIcon = "widgets"
      stockAdjustColor = "success"

    } else if (product.stockAdjust === false) {
      if (lang === 'local') stockAdjustName = 'ไม่นับสต็อก'
      else stockAdjustName = `don't count`

      stockAdjustIcon = "not_interested"
      stockAdjustColor = "info"
    }

    if (product.subtype === 'tour') {
      if (product.itemChoice === 'multiple') {
        if (lang === 'local') itemChoiceName = 'กำหนดวันที่เดินทาง'
        itemChoiceIcon = 'date_range'
        itemChoiceColor = "success"
      } else if (product.itemChoice === 'single') {
        if (lang === 'local') itemChoiceName = 'ไม่กำหนดวันที่เดินทาง'
        itemChoiceIcon = 'grid_off'
        itemChoiceColor = "optional"
      }
    } else {
      if (product.itemChoice === 'multiple') {
        if (lang === 'local') itemChoiceName = 'มีตัวเลือก'
        else itemChoiceName = 'multiple'

        itemChoiceIcon = 'grid_on'
        itemChoiceColor = "success"

      } else if (product.itemChoice === 'single') {
        if (lang === 'local') itemChoiceName = 'ไม่มีตัวเลือก'
        else itemChoiceName = 'single'

        itemChoiceIcon = 'grid_off'
        itemChoiceColor = "optional"
      }
    }

    price = item.price
    discountPrice = getDiscountPrice({ product, price })
    discountPercent = getDiscountPercent({ product })
    salePrice = getSalePrice({ product, price  })

    //console.log(product.folders)

    const foldersText = getFoldersText({ folders: product.folders, lang })
    const priceBetween = getPriceBetween({ product, lang })
    const subitemChoiceWebDisplayName = getSubitemChoiceWebDisplayName(product.subitemChoiceWebDisplay, lang)


    let singleItem, singleItemValue, firstItemValue

    if (product.items && product.items.length) {
      if (product.items.length <= 1) {
        singleItem = true
        singleItemValue = product.items.filter(item => item.itemChoice === 'single')[0]
        firstItemValue = singleItemValue
      } else {
        firstItemValue = product.items.filter(item => item.itemChoice === 'single')[0]
      }
    }

    return {
      ...product,
      customer,
      foldersText,
      nameLang,
      avatarName,
      typeName,
      subtypeName,
      statusName, statusIcon, statusColor,
      stockAdjustName, stockAdjustIcon, stockAdjustColor,
      itemChoiceName, itemChoiceIcon, itemChoiceColor,
      descriptionsLang,
      descriptionsFull,
      subitemChoiceNameLang,
      attributeItems,
      dataAttributes,
      item,
      discountPrice,
      discountPercent,
      salePrice,
      priceBetween,
      unitLang,
      subitemChoiceWebDisplayName,
      singleItem,
      singleItemValue,
      firstItemValue
    }
  }
}

export const getSubitemChoiceWebDisplayName = (subitemChoiceWebDisplay, lang) => {
  let subitemChoiceWebDisplayName

  if (subitemChoiceWebDisplay === 'text') {
    if (lang === 'local') subitemChoiceWebDisplayName = 'ชื่อตัวเลือก'
  } else if (subitemChoiceWebDisplay === 'img') {
    if (lang === 'local') subitemChoiceWebDisplayName = 'รูปภาพตัวเลือก'
  } else {
    if (lang === 'local') subitemChoiceWebDisplayName = 'อัตโนมัติ'
  }

  return subitemChoiceWebDisplayName
}

export const selectPriceByQty = ({ items, amount }) => {
  const selects = []

  items.map(item => {
    if (amount >= item.qty) {
      selects.push(item)
    }
  })

  let max

  if (selects.length !== 0) {
    max = Math.max.apply(Math, selects.map(v => v.qty))
  }

  const item = items.filter(item => item.qty === max)[0]

  return item
}


export const selectPriceByCustomerGroup = ({ items, customer }) => {
  let selected

  if (customer && customer && customer.affiliate && customer.affiliateApproved) {
    selected = items.filter(item => item.code === customer.group)[0]
  }

  return selected
}

const getPriceCondition = ({ item, customer }) => {
  let price = item.price

  if (customer && item && item.priceByCustomerGroup && item.priceByCustomerGroupValues.length !== 0) {
    const priceByCustomerGroup = selectPriceByCustomerGroup({
      items: item.priceByCustomerGroupValues,
      customer
    })

    if (priceByCustomerGroup && priceByCustomerGroup.price) {
      price = priceByCustomerGroup.price
    }
  }

  return price
}


const getPriceBetween = ({ product, lang }) => {
  let priceBetween

  if (product.itemChoice === 'multiple') {
    const singleItems = []

    product.items.map(item => {
      if (item.itemChoice === "single") {
        const itemSelect = getProductItemOfParam({ product, items_id: item._id, lang })

        if (itemSelect) {
          const price = itemSelect.price
          const salePrice = getSalePrice({ product, price  })

          singleItems.push({ price, salePrice })
        }
      }
    })

    if (singleItems.length !== 0) {
      priceBetween = {
        min: Math.min.apply(Math, singleItems.map(v => v.salePrice )),
        max: Math.max.apply(Math, singleItems.map(v => v.salePrice ))
      }
    }
  }

  return priceBetween
}

const getItemParentsLang = ({ items, lang }) => {
  items.map(item => {
    item.choiceNameLang = item.choiceName ? item.choiceName[lang] : null
    item.nameLang = item.name ? item.name[lang] : null
  })

  return items
}

export const getProductItem = ({ product, products_items_id }) => {
  return product.items && product.items.filter(item => item._id === products_items_id)[0]
}

export const productItemValue = ({ product, customer, item, products_items_id, lang="local" }) => {
  if (product) {
    let name, nameLang, avatarName, descriptions, subitemChoiceNameLang

    if (products_items_id) {
      item = product.items && product.items.filter(item => item._id === products_items_id)[0]
    }

    if (item) {
      name = item.name ? item.name[lang] : null
      nameLang = item.name ? item.name[lang] : null
      avatarName = name ? [name] : null
      descriptions = item.descriptions ? item.descriptions[lang] : null
      subitemChoiceNameLang = item.subitemChoiceName ? item.subitemChoiceName[lang] : null
    }

    let items = []

    if (item) {
      items = getItemParents({
        items: product.items,
        items_id: item.items_id,
        choiceName: product.subitemChoiceName,
        reorder: true
      })

      const itemLast = items.length !== 0 && items[items.length - 1]
      const choiceName = itemLast ? itemLast.subitemChoiceName : product.subitemChoiceName
      const priceCondition = getPriceCondition({ item, customer })

      // item.priceOrg = item.price
      // item.price = price
      item.priceCondition = priceCondition

      items.push({
        ...item,
        choiceName
      })
    }

    const itemParent = getItemParentsLang({ items, lang })
    const stock = productStockStatus(product, item)
    const itemImages = getItemImage({ product, item })
    const subitemChoiceWebDisplayName = getSubitemChoiceWebDisplayName(item && item.subitemChoiceWebDisplay, lang)
    const {
      itemFullNameLang,
      itemsChoiceList,
      choiceFullNameLang,
      choiceFullNameArrowLang
    } = getChoiceFullNameLang({ product, nameLang, itemParent })

    return {
      ...item,
      ...stock,
      itemImages,
      name,
      nameLang,
      itemParent,
      avatarName,
      descriptions,
      subitemChoiceNameLang,
      itemFullNameLang,
      itemsChoiceList,
      choiceFullNameLang,
      choiceFullNameArrowLang,
      subitemChoiceWebDisplayName
    }
  }
}

const getChoiceFullNameLang = ({ product, nameLang, itemParent=[] }) => {
  const itemsSingle = product && product.items && product.items.filter(item => item.itemChoice === 'single') || []
  let choiceFullNameLang = ``, choiceFullNameArrowLang = '', itemFullNameLang = '', itemsChoiceList = []

  if (product && product.itemChoice === 'multiple' || itemsSingle.length > 1) {
    itemParent.map((it, i) => {
      let name = ''

      if (it.choiceNameLang && it.nameLang) {
        name = `${it.choiceNameLang} : ${it.nameLang}`
      }

      if (i+1 !== itemParent.length) {
        name = `${name}, `
      }

      itemsChoiceList.push({
        label: it.choiceNameLang || 'ตัวเลือก',
        name: it.nameLang
      })
    })
  }

  itemsChoiceList.map((data, i) => {
    choiceFullNameLang = `${choiceFullNameLang} ${data.label} ${data.name || ' --ไม่มีชื่อ-- '}${i+1 !== itemsChoiceList.length ? ', ' : ''}`
    choiceFullNameArrowLang = `${choiceFullNameArrowLang} ${data.label} ${data.name || ' --ไม่มีชื่อ-- '}${i+1 !== itemsChoiceList.length ? ' > ' : ''}`
  })

  /*if (choiceFullNameLang === '' && itemParent.length === 1) {
    choiceFullNameLang = product.nameLang
  }

  if (choiceFullNameLang === '' && itemsSingle.length === 1) {
    choiceFullNameLang = product.nameLang
  }*/


  if (choiceFullNameLang) {
    itemFullNameLang = `${product.nameLang} ${choiceFullNameLang}`
  } else {
    itemFullNameLang = product.nameLang
  }

  return { choiceFullNameLang, choiceFullNameArrowLang, itemFullNameLang, itemsChoiceList }
}

export const haveItemsImage = (product, parent_items_id=null) => {
  const items = product.items.filter(item => item.items_id === parent_items_id)
  const images = items.filter(item => item.images.length !== 0)

  if (images.length !== 0) {
    return true
  } else {
    return false
  }
}

export const haveItemsImageProfile = (product, parent_items_id=null) => {
  const items = product.items.filter(item => item.items_id === parent_items_id)
  const images = items.filter(item => item.images.length !== 0)

  if (images.length !== 0) {
    items.map(item => {
      item.haveProfile = item.images.filter(img => img.album === 'profile' && img.profile === true)[0]
    })

    const image = items.filter(item => item.haveProfile !== undefined)[0]

    if (image) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

export const productStockStatus = (product, productItem) => {
  let name, status, color

  if (product && productItem) {
    if (product.stockAdjust) {
      if (!productItem.qty || productItem.qty === 0) {
        name = 'หมดแล้ว'
        status = 'sold-out'
        color = 'error'
      } else {
        name = `เหลือ ${getNumeral(productItem.qty, 'auto')} หน่วย`
        status = 'in-stock'
        color = 'success'
      }
    } else {
      name = `ไม่นับสต็อก`
      status = 'no-adjust'
      color = 'info'
    }
  }

  return {
    stock: {
      name,
      status,
      color
    }
  }
}

export const productDateStatus = (product, fullDate, prouctItem) => {
  const startDate = moment().format('YYYY-MM-DD')
  let bg, msg, color, status, name

  if (prouctItem) {
    if (product.stockAdjust) {
      if (!prouctItem.qty || prouctItem.qty === 0) {
        msg = 'ไม่ว่าง'
        name = 'ไม่ว่าง'
        bg = 'bg-warning'
        color = 'warning'
        status = 'busy'
      } else {
        msg = `฿${getNumeral(prouctItem.price, 'auto')}`
        name = `ว่าง ${prouctItem.qty} ที่นั่ง`
        bg = 'bg-success'
        color = 'success'
        status = 'empty'
      }
    } else {
      msg = `฿${getNumeral(prouctItem.price, 'auto')}`
      name = `ไม่นับสต็อก`
      bg = 'bg-success'
      color = 'success'
      status = 'no-adjust'
    }
  }

  if (fullDate < startDate) {
    msg = 'หมดอายุ'
    name = 'หมดอายุ'
    bg = 'bg-error'
    color = 'error'
    status = 'expire'
  }

  return { msg, name, bg, color, status }
}



export const getProductParams = (products_id) => {
  let _id, items_id

  if (products_id) {
    const params = products_id.split('-')

    if (params[0]) {
      _id = params[0]
    }

    if (params[0] && params[1]) {
      items_id = params[1]
    }
  }

  return {
    products_id: _id,
    products_items_id: items_id
  }
}


const getProductItemOfParam = ({ product, customer, items_id, lang }) => {
  let item
  const productItems = product.items || []

  if (items_id) {
    item = productItems.filter(item => item._id === items_id)[0] || {}

    if (item.itemChoice === 'multiple') {
      const itemChild = getItemChildSingle(product, item._id)

      if (itemChild) {
        item.price = itemChild.price
      }
    }

  } else {
    item = productItems.filter(item => item.itemChoice === 'single')[0] || {}
  }

  item = item && productItemValue({ product, customer, item, lang })

  return item
}

const getItemChildSingle = (product, parent_items_id) => {
  let itemChild

  if (product && parent_items_id) {
    itemChild = product.items.filter(item => item.items_id === parent_items_id)[0]

    if (itemChild && itemChild.itemChoice === 'multiple') {
      getItemChildSingle(product, itemChild._id)
    }
  }

  return itemChild
}


export const getDiscountPrice = ({ product, price }) => {
  const { discount } = product

  let discountPrice

  if (discount && discount.status) {
    if (discount.type === 'currency') {
      discountPrice = discount.value

    } else if (discount.type === 'percent') {
      discountPrice = price * discount.value / 100
    }
  }

  return discountPrice
}

export const getDiscountPercent = ({ product, price }) => {
  const { discount } = product

  let discountPercent

  if (discount && discount.status) {
    if (discount.type === 'percent') {
      discountPercent = discount.value + '%'
    }
  }

  return discountPercent
}


export const getSalePrice = ({ product, price }) => {
  const discountPrice = getDiscountPrice({ product, price })

  let salePrice

  if (discountPrice) {
    salePrice = price - discountPrice
  } else {
    salePrice = price
  }

  return salePrice
}




const items = [{
  avatarColor: "#546e7a",
  code: null,
  discount: {type: "currency", value: 0},
  images: [],
  itemChoice: "single",
  items_id: null,
  name: {},
  price: 0,
  qty: 1,
  subitemChoiceName: {},
  weight: null,
  _id: "001",
}]

export const shippingFeeValue = () => {
  return {
    _id: '01',
    status: 'open',
    type: 'service',
    subtype: 'shipping-fee',
    name: { local: 'ค่าจัดส่ง' },
    stockAdjust: false,
    itemChoice: 'single',
    items
  }
}

export const paymentFeeValue = () => {
  return {
    _id: '02',
    status: 'open',
    type: 'service',
    subtype: 'payment-fee',
    name: { local: 'ค่าบริการชำระเงิน' },
    stockAdjust: false,
    itemChoice: 'single',
    items
  }
}
