import React, { Component } from 'react'

// Components Global
import DropdownDefault from '../../DropdownDefault'
import ColorPicker from '../../ColorPicker'

// Utils
import { getTextColor } from '../../../util/getColor'


class TextShadowPicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      shiftLeftRight: 0,
      shiftUpDown: 0,
      blur: 0,
      red: 0,
      green: 0,
      blue: 0,
      opatity: 1
    }
  }

  componentDidMount() {
    this.mounted = true
    this.convertValueToState()
  }

  /*componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.convertValueToState()
    }
  }*/

  componentWillUnmount() {
    this.mounted = false
  }

  handleScale = (e, name, min, max) => {
    let value = e.target.value

    if (value !== undefined && !isNaN(value)) {
      value = parseFloat(value)

      // value น้อยกว่า ค่า max
      if (value <= max) {
        // value มากกว่า ค่า min
        if (value > min) {
          value = value
        } else {
          value = min
        }
      } else {
        if (value > max) {
          value = max
        }
      }
    } else {
      value = 0
    }

    if (isNaN(value)) {
      value = 0
    }

    this.setState({ [name]: value }, () => {
      this.generatorTextShadow()
    })
  }

  generatorTextShadow = () => {
    const { shiftLeftRight, shiftUpDown, blur, red, green, blue, opatity } = this.state
    const textShadow = `${shiftLeftRight}px ${shiftUpDown}px ${blur}px rgba(${red}, ${green}, ${blue}, ${opatity})`
    const textShadowVw = `${shiftLeftRight/15}vw ${shiftUpDown/15}vw ${blur/15}vw rgba(${red}, ${green}, ${blue}, ${opatity})`

    this.setState({ textShadow, textShadowVw }, () => {
      const { onChange, index } = this.props

      if (onChange) {
        onChange(index, textShadow, textShadowVw)
      }
    })
  }

  convertValueToState = () => {
    const { value } = this.props
    const split = value.split(' rgba(')

    let splitValue = split[0]
    let splitColor = split[1]
    let shiftLeftRight = 0, shiftUpDown = 0, blur = 0, red = 0, green = 0, blue = 0, opatity = 1

    if (splitValue) {
      const values = splitValue.split(' ')

      if (values[0]) {
        shiftLeftRight = values[0].replace('px', '')
        shiftLeftRight = parseInt(shiftLeftRight)
      }

      if (values[1]) {
        shiftUpDown = values[1].replace('px', '')
        shiftUpDown = parseInt(shiftUpDown)
      }

      if (values[2]) {
        blur = values[2].replace('px', '')
        blur = parseInt(blur)
      }
    }

    if (splitColor) {
      splitColor = splitColor.replace(')', '')
      const values = splitColor.split(',')

      if (values[0]) {
        red = values[0].replace(' ', '')
        red = parseInt(red)
      }

      if (values[1]) {
        green = values[1].replace(' ', '')
        green = parseInt(green)
      }

      if (values[2]) {
        blue = values[2].replace(' ', '')
        blue = parseInt(blue)
      }

      if (values[3]) {
        opatity = values[3].replace(' ', '')
        opatity = parseInt(opatity)
      }
    }

    this.setState({
      shiftLeftRight, shiftUpDown, blur, red, green, blue, opatity
    }, () => {
      this.generatorTextShadow()
    })
  }

  handleSelectColor = (color) => {
    this.mounted && this.setState({
      red: color && color.r || 0,
      green: color && color.g || 0,
      blue: color && color.b || 0
    }, () => {
      this.generatorTextShadow()
    })
  }

  render() {
    const { index } = this.props
    const { shiftLeftRight, shiftUpDown, blur, red, green, blue, opatity } = this.state
    const rgbString = `rgb(${red}, ${green}, ${blue})`

    return (
      <div className="mg-bottom-10">
        <table>
          <tbody>
            <tr>
              <td width="90" className="pd-0">
                <ColorPicker
                  advanced
                  rgb={{
                    r: red,
                    g: green,
                    b: blue,
                    a: 1
                  }}
                  hiddenColorAuto
                  label="สี"
                  onChange={this.handleSelectColor}>

                  <div className="width-90 height-40 border-efefef pd-3 border-radius-3">
                    <div
                      className="width-full height-full center line-height-30 font-13 pd-top-2"
                      style={{
                        background: rgbString,
                        color: getTextColor(rgbString),
                        cursor: 'pointer'
                      }}>

                        { (red !== undefined && green !== undefined && blue !== undefined) ? 'สี' : 'เลือกสี' }
                    </div>
                  </div>
                </ColorPicker>
              </td>
              <td className="pd-0 pd-left-10">
                <div className="font-13 center height-40 line-height-40 border-efefef border-radius-3">
                  ไอเทมที่ {index + 1}
                </div>
              </td>
              <td width="50" className="pd-0 pd-left-10">
                <DropdownDefault
                  styleContent={{ top: '40', right: '0', width: '300px' }}>

                  <a className="btn btn-shadow-none width-40 height-40 line-height-40">
                    <i className="material-icons color-fff font-15">edit</i>
                  </a>

                  <div className="pd-10 pd-bottom-0">
                    <table>
                      <tbody>
                        <tr className="border-bottom-1 border-bottom-efefef">
                          <td className="pd-0">
                            <div>เลื่อนซ้ายหรือขวา</div>
                            <div className="range-field pd-top-5">
                              <input
                                name="shiftLeftRight"
                                type="range"
                                onChange={(e) => this.handleScale(e, 'shiftLeftRight', -40, 40)}
                                min="-40"
                                max="40"
                                step="1"
                                value={shiftLeftRight}
                              />
                            </div>
                          </td>
                          <td width="60" className="pd-0 pd-left-10">
                            <input
                              type="text"
                              className="input-manage center mg-bottom-0"
                              onChange={(e) => this.handleScale(e, 'shiftLeftRight', -40, 40)}
                              value={shiftLeftRight}
                            />
                          </td>
                        </tr>

                        <tr className="border-bottom-1 border-bottom-efefef">
                          <td className="pd-0 pd-top-5">
                            <div>เลื่อนบนหรือล่าง</div>
                            <div className="range-field pd-top-5">
                              <input
                                name="shiftUpDown"
                                type="range"
                                onChange={(e) => this.handleScale(e, 'shiftUpDown', -40, 40)}
                                min="-40"
                                max="40"
                                step="1"
                                value={shiftUpDown}
                              />
                            </div>
                          </td>
                          <td width="50" className="pd-0 pd-left-10">
                            <input
                              type="text"
                              className="input-manage center mg-bottom-0"
                              onChange={(e) => this.handleScale(e, 'shiftUpDown', -40, 40)}
                              value={shiftUpDown}
                            />
                          </td>
                        </tr>

                        <tr className="border-bottom-1 border-bottom-efefef">
                          <td className="pd-0 pd-top-5">
                            <div>เบลอ</div>
                            <div className="range-field pd-top-5">
                              <input
                                name="blur"
                                type="range"
                                onChange={(e) => this.handleScale(e, 'blur', 0, 20)}
                                min="0"
                                max="20"
                                step="1"
                                value={blur}
                              />
                            </div>
                          </td>
                          <td width="50" className="pd-0 pd-left-10">
                            <input
                              type="text"
                              className="input-manage center mg-bottom-0"
                              onChange={(e) => this.handleScale(e, 'blur', 0, 20)}
                              value={blur}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td className="pd-0 pd-top-5">
                            <div>ความทึบแสง</div>
                            <div className="range-field pd-top-5">
                              <input
                                name="opatity"
                                type="range"
                                onChange={(e) => this.handleScale(e, 'opatity', 0, 1)}
                                min="0"
                                max="1"
                                step="0.1"
                                value={opatity}
                              />
                            </div>
                          </td>
                          <td width="50" className="pd-0 pd-left-10">
                            <input
                              type="text"
                              className="input-manage center mg-bottom-0"
                              onChange={(e) => this.handleScale(e, 'opatity', 0, 1)}
                              value={opatity}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </DropdownDefault>
              </td>


              <td width="50" className="pd-0 text-right">
                { index === 0 ?
                  <a disabled className="btn btn-shadow-none width-40 height-40 line-height-40">
                    <i className="material-icons font-15">close</i>
                  </a>
                :
                  <a onClick={() => this.props.removeItem(index)} className="btn color-fff btn-delete btn-shadow-none width-40 height-40 line-height-40">
                    <i className="material-icons color-fff font-15">close</i>
                  </a>
                }
              </td>

            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

export default TextShadowPicker
