import React from 'react'
import { connect } from 'react-redux'

// Components Global
import TextShadowCSSGenerator from '../../../../TextShadowCSSGenerator'

// Functions
import { addStyle, removeStyle } from '../../../functions/textEditorFunctions'

// Actions Redux
import { openModal } from '../../../../../redux/App/AppActions'

// css
import css from '../css/styleSettings.css'


const TextShadowSetting = (props) => {
  let { _this, styleName, name } = props
  styleName = styleName || 'text-shadow'

  const addValue = (textShadow, textShadowVw) => {
    console.log(textShadowVw)


    addStyle(_this, {
      styleMultiple: [
        { style: styleName, value: textShadow },
        { style: `${styleName}-vw`, value: textShadowVw }
      ]
    })
  }

  const reset = () => {
    if (styleName) {
      removeStyle(_this, null, {
        styleMultiple: [
          { style: styleName },
          { style: `${styleName}-vw` }
        ]
      })
    }
  }

  const textShadow = _this.state.style[styleName]
  const textShadowVw = _this.state.style[`${styleName}-vw`]
  const color = _this.state.style[`${name}-color`]
  const { id } = _this.props

  return (
    <div>
      <TextShadowCSSGenerator
        id={`textShadowCSSGenerator-${id}`}
        onChange={addValue}
        color={color}
        value={textShadow}
        valuevw={textShadowVw}
      />

      <table>
        <tbody>
          <tr>
            <td className="pd-0 font-0-7">เงาตัวอักษร (text-shadow)</td>
            <td className="pd-0 font-0-7 text-right">
              <a className={css.btn} onClick={reset}>รีเซ็ท</a>
            </td>
          </tr>
        </tbody>
      </table>

      <div className={css.boxInput}>
        <div onClick={() => props.dispatch(openModal(`textShadowCSSGenerator-${id}`))} className="text-left font-0-8 pd-7 border-radius-3" style={{ cursor: 'pointer' }}>
          { textShadow || 'ไม่มีเงาตัวอักษร'}
        </div>
      </div>
    </div>
  )
}

export default connect()(TextShadowSetting)
