import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import TextEditorShoppingCartManage from './TextEditorShoppingCartManage'
import TextEditorShoppingCartManageDisplay from './TextEditorShoppingCartManageDisplay'

let TextEditorShoppingCartManage = lazy(() => import('./TextEditorShoppingCartManage'))
TextEditorShoppingCartManage = suspenseComponent(TextEditorShoppingCartManage)

export {
  TextEditorShoppingCartManage,
  TextEditorShoppingCartManageDisplay
}
