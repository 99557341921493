import { fetchDatas, fetchData, createData, updateData, removeData, fetchApi } from '../reduxActions'
import { addsDatasMany } from 'core/redux/reduxActionsV2'
import { generateTypeName, generateTypeFunctions } from 'core/redux/reduxTypesV2'

import { checkAppActions } from '../App/AppActions'
import uploadsForS3 from 'client/core/utils/uploadsForS3'

// Export Constants
const NAME_TYPE = ['USERS', 'USER']
const name = "users"

// Type Names and Type Functions
export const {
  ADDS, ADDS_MORE, ADD, UPDATE, REMOVE,
  ADDS_MANY, ADDS_MANY_MORE,
} = generateTypeName(NAME_TYPE)

export const {
  adds, adds_more, add, update, remove,
  adds_many, adds_many_more,
} = generateTypeFunctions(NAME_TYPE)


// Display
export const checkUserDuplicate = (query, params = {}) => dispatch => {
  const { businesses_id } = query

  return fetchApi({
    dispatch,
    params,
    api: `${name}-query/${businesses_id}/check-value-duplicate?${params.query}`
  })
}


export const fetchUsers = (query, params = {}) => dispatch => {
  const { authName, businesses_id } = query

  return fetchDatas({
    dispatch,
    params,
    api: `${name}/${authName}/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}


export const fetchQueryUserOne = (query, params = {}) => dispatch => {
  const { authName, businesses_id } = query

  return fetchData({
    dispatch,
    params,
    api: `${name}-one/${authName}/${businesses_id}`,
    add: add
  })
}

export const fetchQueryUsersMany = (query, params = {}) => dispatch => {
  const { authName, businesses_id } = query

  return addsDatasMany({
    dispatch,
    params,
    api: `${name}-many/${authName}/${businesses_id}`,
    adds_many,
    adds_many_more
  })
}

export const updateQueryOneUser = (query, params) => dispatch => {
  const { authName, businesses_id, _id, users_id } = query

  return updateData({
    dispatch,
    params,
    api: `${name}-one/${authName}/${businesses_id}/${users_id || _id}`,
    update: update,
    updateId: _id
  })
}

export const createQueryCustomerOne = (query, params) => dispatch => {
  const { authName, businesses_id } = query

  return createData({
    dispatch,
    params,
    api: `${name}-one/${authName}/${businesses_id}/create`,
    add: add
  })
}



export const fetchUser = (query, params = {}) => dispatch => {
  const { authName, businesses_id, users_id } = query


  return fetchData({
    dispatch,
    params,
    api: `${name}/${authName}/${businesses_id}/${users_id}`,
    add: add
  })
}

export const createUser = (query, params) => dispatch => {
  const { authName, businesses_id } = query

  return createData({
    dispatch,
    params,
    api: `${name}/${authName}/${businesses_id}/create`,
    add: add
  })
}

export const updateUser = (query, params) => dispatch => {
  const { authName, businesses_id, users_id, _id } = query

  return updateData({
    dispatch,
    params,
    api: `${name}/${authName}/${businesses_id}/${_id || users_id}`,
    update: update,
    updateId: _id || users_id
  })
}

export const updateUserPassword = (query, params) => dispatch => {
  const { authName, businesses_id, users_id, _id } = query

  return updateData({
    dispatch,
    params,
    api: `${name}/${authName}/${businesses_id}/${_id || users_id}/reset-password`,
    update: update,
    updateId: _id || users_id
  })
}




export const uploadImagesUser = (query, fileParams, params) => dispatch => {
  const { files, imagePrefix } = fileParams

  checkAppActions(dispatch, params, 'start')
  uploadsForS3(files, imagePrefix, params.progressId, dispatch).then(val => {
    val.map(paths => {
      params.body.paths = paths

      dispatch(updateUser(query, params))
    })
  })
}


export const removeUser = (query, params) => dispatch => {
  const { authName, businesses_id, users_id, _id } = query

  return removeData({
    dispatch,
    params,
    api: `${name}/${authName}/${businesses_id}/${_id || users_id}`,
    remove: remove,
    deleteId: _id || users_id
  })
}
