
import React from "react"

// Components Global
import Photoswipe from 'components/Photoswipe'
import { LayoutsDesignDisplay } from 'components/LayoutsDesign'



const PageDisplay = (props) => {
  const { mode, singlePageData } = props
  const { business, setting, page, templateData } = props.datas

  return (
    <Photoswipe galleryId="page-gallery">
      <LayoutsDesignDisplay
        {...props}
        display
        body
        action={{
          params: {
            business,
            setting,
            data: page,
            _id: page._id,
            templateData: templateData ? templateData : singlePageData
          }
        }}
      />
    </Photoswipe>
  )
}

export default PageDisplay
