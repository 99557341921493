import { fetchDatas, fetchData, createData, updateData, removeData } from '../reduxActions'
import { generateTypeName, generateTypeFunctions, getTypePath } from '../reduxTypes'


// Export Constants
const NAME_TYPE = ['MAKE_ONE_DATAS', 'MAKE_ONE_DATA']


// export use for reducer
export const { ADDS, ADDS_MORE, ADD, UPDATE, REMOVE } = generateTypeName(NAME_TYPE)

// export use for action
export const { adds, adds_more, add, update, remove } = generateTypeFunctions(NAME_TYPE)


// Export Actions
export const fetchMakeOneDatas = (query, params = {}) => dispatch => {
  const { apiName, authName, dbNo, businessType, businesses_id } = query
  const name = apiName
  const path = getTypePath(businessType)

  return fetchDatas({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchMakeOneData = (query, params = {}) => dispatch => {
  const { apiName, authName, dbNo, businessType, businesses_id, deposits_id } = query
  const name = apiName
  const path = getTypePath(businessType)

  return fetchData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/${deposits_id}`,
    add: add
  })
}

export const createMakeOneData = (query, params) => dispatch => {
  const { apiName, authName, dbNo, businessType, businesses_id } = query
  const name = apiName
  const path = getTypePath(businessType)

  return createData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/create`,
    add: add
  })
}


export const updateMakeOneData = (query, params) => dispatch => {
  const { apiName, authName, dbNo, businessType, businesses_id, deposits_id, _id } = query
  const name = apiName
  const path = getTypePath(businessType)

  return updateData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/${_id || deposits_id}`,
    update: update,
    updateId: _id || deposits_id
  })
}

export const removeMakeOneData = (query, params) => dispatch => {
  const { apiName, authName, dbNo, businessType, businesses_id, deposits_id, _id } = query
  const name = apiName
  const path = getTypePath(businessType)

  return removeData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/${_id || deposits_id}`,
    remove: remove,
    deleteId: _id || deposits_id
  })
}
