import React, { Fragment, useMemo } from 'react'
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'

// Components Editor
import DivBlockStyle from '../DivBlockStyle'

// functions
import {
  checkUseAuth,
  generateClassToString
} from '../../functions/textEditorFunctions'

// js
import { checkCondition } from '../ConditionSetting/js/conditionSetting'
import { getDataStore } from '../../functions/textEditorEvent'
import { getEventUpdateUrlSearch } from '../UpdateUrlSearchSetting/js/updateUrlSearchSetting'


const BlockStyle = (props) => {
  let { mode, loggedIn, auth, edit, name, apiData, templateData, dataStore, location } = props
  const style = props.style || {}
  const className = props.className || []

  const useAuth = useMemo(() => checkUseAuth({ style, auth, loggedIn, mode, edit }), [
    style && style['use-auth'],
    auth && JSON.stringify(auth),
    loggedIn,
    mode
  ])

  const show = useMemo(() => checkCondition({ style, mode, apiData, templateData, dataStore, location, props }), [
    mode,
    style && JSON.stringify(style),
    apiData && JSON.stringify(apiData),
    templateData && JSON.stringify(templateData),
    dataStore && JSON.stringify(dataStore),
    location && JSON.stringify(location)
  ])
  const event = useMemo(() => getEventUpdateUrlSearch({ style, mode, props }), [
    mode,
    style && JSON.stringify(style)
  ])

  if (!name) {
    name = 'block'
  }


  return (
    <Fragment>
      { useAuth && show &&
        <DivBlockStyle
          mode={mode}
          name={name}
          edit={edit}
          event={!mode && event}
          styleJsx={style}
          type={props.type}
          className={generateClassToString(className)}
          style={{ fontSize: props.type === 'img' && '0' }}>

          { props.children }
        </DivBlockStyle>
      }
    </Fragment>
  )
}

const mapStateToProps = (store, props) => {
  return {
    auth: store.auth,
    dataStore: getDataStore({ store, props })
  }
}

export default withRouter(connect(mapStateToProps)(BlockStyle))
