import React from 'react'
import { connect } from 'react-redux'

// Components Local
import { EditorButtonDisplay } from '../components/EditorButton'
import BlockStyle from '../components/BlockStyle'


const TextEditorButtonDisplay = (props) => {
  const { lang, content, apiData, templateData, dataStore, mode, loggedIn, closeWindowModal } = props
  const buttons = content.buttons && JSON.parse(content.buttons),
        style = buttons && buttons.style ? JSON.parse(buttons.style) : {},
        className = buttons && buttons.class ? JSON.parse(buttons.class) : [],
        items = buttons && buttons.items ? JSON.parse(buttons.items) : []

  return (
    <BlockStyle {...props} style={style} className={className}>
      { items.map((button, index) => {
        return (
          <EditorButtonDisplay
            key={index}
            lang={lang}
            content={content}
            apiData={apiData}
            templateData={templateData}
            button={button}
            mode={mode}
            loggedIn={loggedIn}
            dataStore={dataStore}
            closeWindowModal={closeWindowModal}
          />
        )
      }) }
    </BlockStyle>
  )
}

export default connect()(TextEditorButtonDisplay)
