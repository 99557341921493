import React, { Component, Fragment } from 'react'

// Components Global
import RadioGroup from '../../../Inputs/RadioGroup'
import Checkbox from '../../../Inputs/Checkbox'

// css
import css from './css/linkManage.css'


class LinkManage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.getValue()
    }
  }

  getValue = () => {
    const { value } = this.props
    const link = value && JSON.parse(value)
    //const options = link && link.options && JSON.parse(link.options)

    return {
      type: link && link.type || '',
      inlink: link && link.inlink || '',
      outlink: link && link.outlink || '',
      sidebarId: link && link.sidebarId || '',
      modalId: link && link.modalId || '',
      formId: link && link.formId || '',
      functionName: link && link.functionName || '',
      options: link && link.options || {},
      inlinkNewTab: link && link.inlinkNewTab === true ? true : false,
      blockScrollToTop: link && link.blockScrollToTop === true ? true : false,
      closeWindowModal: link && link.closeWindowModal === true ? true : false,
      outlinkNewTab: link && link.outlinkNewTab === true ? true : false,

      closeWidgitType: link && link.closeWidgitType || '',
      closeWidgitList: link && link.closeWidgitList || '',

      fieldId: link && link.fieldId || '',
      fieldEvent: link && link.fieldEvent || 'open',
    }
  }

  handleTypeChange = (type) => {
    this.setState({ type }, () => {
      this.handleOnChange()
    })
  }

  handleUrlChange = (e, type) => {
    const value = e.target.value

    this.setState({ [type]: value }, () => {
      this.handleOnChange()
    })
  }

  handleOptionsChange = (e, type) => {
    const value = e.target.value
    const newOptions = {...this.state.options}
    newOptions[type] = value

    this.setState({ options: newOptions }, () => {
      this.handleOnChange()
    })
  }

  handleFieldIdChange = (e) => {
    const value = e.target.value

    this.setState({ fieldId: value }, () => {
      this.handleOnChange()
    })
  }

  handleFieldEventChange = (e) => {
    const value = e.target.value

    this.setState({ fieldEvent: value }, () => {
      this.handleOnChange()
    })
  }

  handleNewTabChange = (value, id) => {
    this.setState({ [id]: value }, () => {
      this.handleOnChange()
    })
  }

  onBlockScrollToTopChange= (value, id) => {
    this.setState({ [id]: value }, () => {
      this.handleOnChange()
    })
  }

  onCloseWindowModalChange = (value, id) => {
    this.setState({ [id]: value }, () => {
      this.handleOnChange()
    })
  }



  handleCloseWidgitTypeChange = (closeWidgitType) => {
    this.setState({ closeWidgitType }, () => {
      this.handleOnChange()
    })
  }

  handleWidgitListChange = (e) => {
    const closeWidgitList = e.target.value

    this.setState({ closeWidgitList }, () => {
      this.handleOnChange()
    })
  }

  handleOnChange = () => {
    const {
      type, inlink, outlink, sidebarId, modalId, formId,
      functionName, options, inlinkNewTab, blockScrollToTop, closeWindowModal, outlinkNewTab, closeWidgitType,
      closeWidgitList, fieldId, fieldEvent
    } = this.state

    let link = {
      type,
      inlink,
      outlink,
      sidebarId,
      modalId,
      formId,
      functionName,
      options,
      inlinkNewTab,
      blockScrollToTop,
      closeWindowModal,
      outlinkNewTab,
      closeWidgitType,
      closeWidgitList,
      fieldId, fieldEvent
    }


    if (this.props.saveAuto) {
      const { _this } = this.props

      _this.setState({ link }, () => {
        _this.handleSubmit({ timeout: true })
      })

    } else {
      if (this.props.onChange) {
        link = JSON.stringify(link)

        this.props.onChange(link, this.props.index)
      }
    }
  }



  render() {
    const {
      type, inlink, outlink, sidebarId, modalId, formId,
      functionName, options, inlinkNewTab, blockScrollToTop, closeWindowModal, outlinkNewTab,
      closeWidgitType, closeWidgitList, fieldId, fieldEvent
    } = this.state


    const radios = [
      {
        value: '',
        label: 'ไม่มีลิงก์'
      },
      {
        value: 'inlink',
        label: 'ลิงก์ภายใน',
        child: <Url
                  id="inlink"
                  checkboxId="inlinkNewTab"
                  label="ลิงก์ภายใน"
                  value={inlink}
                  newTabValue={inlinkNewTab}
                  onChange={(e) => this.handleUrlChange(e, 'inlink')}
                  onTabChange={this.handleNewTabChange}
                  onBlockScrollToTopChange={this.onBlockScrollToTopChange}
                  onCloseWindowModalChange={this.onCloseWindowModalChange}
                  blockScrollToTop
                  blockScrollToTopValue={blockScrollToTop}
                  closeWindowModal
                  closeWindowModalValue={closeWindowModal}
                />
      },
      {
        value: 'outlink',
        label: 'ลิงก์ภายนอก',
        child: <Url
                  id="outlink"
                  checkboxId="outlinkNewTab"
                  label="ลิงก์ภายนอก"
                  value={outlink}
                  newTabValue={outlinkNewTab}
                  onChange={(e) => this.handleUrlChange(e, 'outlink')}
                  onTabChange={this.handleNewTabChange}
                />
      },
      {
        value: 'sidebar',
        label: 'แทบด้านข้าง',
        child: <Url
                  hiddenNewTab
                  id="sidebarId"
                  label="ไอดีแทบด้านข้าง"
                  value={sidebarId}
                  onChange={(e) => this.handleUrlChange(e, 'sidebarId')}
                />
      },
      {
        value: 'modal',
        label: 'โมดอล',
        child: <Url
                  hiddenNewTab
                  id="modalId"
                  label="ไอดีโมดอล"
                  value={modalId}
                  onChange={(e) => this.handleUrlChange(e, 'modalId')}
                />
      },
      {
        value: 'function',
        label: 'ฟังก์ชั่น',
        child: (
              <Fragment>
                <SelectFunction
                  hiddenNewTab
                  id="functionName"
                  label="ฟังก์ชั่น"
                  value={functionName}
                  onChange={(e) => this.handleUrlChange(e, 'functionName')}
                />

                { functionName === 'share' &&
                  <ShareType label="ประเภท" value={options.shareType} onChange={(e) => this.handleOptionsChange(e, 'shareType')} />
                }
              </Fragment>
        )
      },
      {
        value: 'editField',
        label: 'เปิด-ปิด ฟิลด์',
        child: <div>
                  <SelectFieldEvent
                    id="fieldEvent"
                    label="เปิด-ปิด ฟิลด์"
                    value={fieldEvent}
                    onChange={this.handleFieldEventChange}
                  />

                  <Url
                    hiddenNewTab
                    id="fieldId"
                    label="ไอดีฟิลด์"
                    value={fieldId}
                    onChange={(e) => this.handleUrlChange(e, 'fieldId')}
                  />
                </div>
      },
      {
        value: 'submit',
        label: 'ส่งฟอร์ม (submit)',
        child: <Url
                  hiddenNewTab
                  id="formId"
                  label="ฟอร์มไอดี"
                  value={formId}
                  onChange={(e) => this.handleUrlChange(e, 'formId')}
                />
      },
    ]

    const eventRadios = [
      {
        value: '',
        label: 'ไม่มี',
      },
      {
        value: 'all',
        label: 'ปิดวิดเจ็ตทั้งหมด',
      },
      {
        value: 'list',
        label: 'ปิดวิดเจ็ตตามไอดี',
        child: <InputWidgitList
                  id="closeWidgitList"
                  label="ไอดีวิดเจ็ต"
                  value={closeWidgitList}
                  onChange={this.handleWidgitListChange}
                />
      },
    ]



    return (
      <div>
        <RadioGroup
          notSave
          id="link-type"
          value={type || ''}
          radios={radios}
          onChange={this.handleTypeChange}
          styleLabel={{
            fontSize: '0.8rem'
          }}
        />

        <div className="pd-top-10">
          <div>กิจกรรมการก่อนกดลิงค์</div>

          <RadioGroup
            notSave
            id="closeWidgitType"
            value={closeWidgitType || ''}
            radios={eventRadios}
            onChange={this.handleCloseWidgitTypeChange}
            styleLabel={{
              fontSize: '0.8rem'
            }}
          />
        </div>
      </div>
    )
  }
}

const Url = (props) => {
  return (
    <div className="pd-left-30">
      <input
        data-not-save={true}
        className={css.inputLink}
        type="text"
        value={props.value}
        onChange={props.onChange}
        placeholder={props.label}
        style={{
          border: '1px solid #ddd !important'
        }}
      />

      { !props.hiddenNewTab &&
        <Checkbox
          notSave
          id={props.checkboxId}
          size="xxs"
          label="เปิดหน้าใหม่"
          value={props.newTabValue}
          className="mg-top-5"
          labelClassName="font-0-8"
          onChange={props.onTabChange}
        />
      }

      { props.blockScrollToTop &&
        <Checkbox
          notSave
          id="blockScrollToTop"
          size="xxs"
          label="แถบเลื่อนคงที่"
          value={props.blockScrollToTopValue}
          className="mg-top-5"
          labelClassName="font-0-8"
          onChange={props.onBlockScrollToTopChange}
        />
      }

      { props.closeWindowModal &&
        <Checkbox
          notSave
          id="closeWindowModal"
          size="xxs"
          label="ปิดเพจ Modal"
          value={props.closeWindowModalValue}
          className="mg-top-5"
          labelClassName="font-0-8"
          onChange={props.onCloseWindowModalChange}
        />
      }
    </div>
  )
}

const SelectFunction = (props) => {
  return (
    <div className="pd-left-30">
      <select
        data-not-save={true}
        className={`browser-default ${css.inputLink} ${css.select}`}
        onChange={props.onChange}
        value={props.value || ''}>

        <option value="">เลือกฟังก์ชั่น</option>
        <option value="logout">ออกจากระบบ</option>
        <option value="barcode-scanner">สแกนบาร์โค้ด</option>
        <option value="share">แชร์</option>
      </select>
    </div>
  )
}

const ShareType = (props) => {
  const options = [
    'Twitter',
    'Messenger',
    'Facebook',
    'Linkedin',
    'Email',
    'Whatsapp',
    'Telegram',
    'Viber',
    'Pinterest',
    'Tumblr',
    'Line',
    'Instapaper',
    'Skype',
    'Copy'
  ]


  return (
    <div className="pd-left-30 mg-top-15">
      <select
        data-not-save={true}
        className={`browser-default ${css.inputLink} ${css.select}`}
        onChange={props.onChange}
        value={props.value || ''}>

        <option value="">เลือก{props.label}</option>

        { options.map(v => {
          return <option key={v} value={v}>{v}</option>
        }) }
      </select>
    </div>
  )
}

const SelectFieldEvent = (props) => {
  return (
    <div className="pd-left-30">
      <select
        data-not-save={true}
        className={`browser-default ${css.inputLink} ${css.select}`}
        onChange={props.onChange}
        value={props.value}>

        <option value="open">เปิดฟิลด์</option>
        <option value="close">ปิดฟิลด์</option>
      </select>
    </div>
  )
}

const InputWidgitList = (props) => {
  return (
    <div className="pd-left-30">
      <input
        data-not-save={true}
        className={css.inputLink}
        type="text"
        value={props.value}
        onChange={props.onChange}
        placeholder={props.label}
        style={{
          border: '1px solid #ddd !important'
        }}
      />
    </div>
  )
}


export default LinkManage
