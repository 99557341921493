import React from 'react'

// Components Global
import ImagesSlider from '../../ImagesSlider'

// Components Local
import { EditorImageDisplay } from '../components/EditorImage'


const TextEditorImageSliderDisplay = ({ lang, content, mode }) => {
  const imageSlider = content.imageSlider && JSON.parse(content.imageSlider),
        blockFull = content.blockFull,

        className = imageSlider.class ? JSON.parse(imageSlider.class) : [],
        style = imageSlider.style ? JSON.parse(imageSlider.style) : {},
        navStyle = imageSlider && imageSlider.navStyle ? JSON.parse(imageSlider.navStyle) : {},
        items = imageSlider && imageSlider.items ? JSON.parse(imageSlider.items) : [],

        navShow = imageSlider && imageSlider.navShow !== undefined ? imageSlider.navShow : true,
        autoPlay = imageSlider && imageSlider.autoPlay !== undefined ? imageSlider.autoPlay : true,
        duration = imageSlider && imageSlider.duration !== undefined ? imageSlider.duration : 6

  return (
    <div>
      { items.length !== 0 &&
        <ImagesSlider
          navStyle={navStyle}
          options={{
            autoPlay,
            duration,
            navShow
          }}>

          { items.map((image, i) => {
            return (
              <EditorImageDisplay
                key={i}
                type="image-slider"
                style={{ height: style['height'] || '250px' }}
                image={image}
                mode={mode}
                lazyDisabled
              />
            )
          }) }
        </ImagesSlider>
      }
    </div>
  )
}

export default TextEditorImageSliderDisplay
