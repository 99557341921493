import React from 'react'


// Components Text Editor
import BlockStyleSettings from '../../../BlockStyleSettings'
import BoxSettings from '../../../BoxSettings'
import {
  ColorSetting,
  BackgroundColorSetting,
  BorderSetting,
  BoxShadowSetting,
  BorderRadiusSetting,
  PaddingSetting
} from '../../../StyleSettings'


const StyleSettings = (props) => {
  const { _this } = props

  return (
    <div>
      <BlockStyleSettings _this={_this} />

      <BoxSettings title="ไอคอน" icon="star">
        <div>
          <div className="pd-10">
            <BorderSetting _this={_this} styleName="icon-box-border" />
          </div>

          <div className="pd-10 pd-bottom-5 bg-fbfbfb">
            <PaddingSetting _this={_this} styleName="icon-box-padding" defaultValue="0px 0px 0px 0px" />
          </div>

          <div className="pd-10">
            <BorderRadiusSetting _this={_this} styleName="icon-box-border-radius" />
          </div>

          <div className="pd-10 text-left bg-fbfbfb">
            <BoxShadowSetting _this={_this} styleName="icon-box-shadow" />
          </div>

          <div className="pd-10 text-left">
            <ColorSetting label="สีไอคอน" _this={_this} styleName="icon-color" />
          </div>

          <div className="pd-10 text-left bg-fbfbfb">
            <BackgroundColorSetting label="สีพื้นหลัง" _this={_this} styleName="icon-box-background-color" />
          </div>
        </div>
      </BoxSettings>
    </div>
  )
}

export default StyleSettings
