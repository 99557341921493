// utils
import { getDataByKey } from '../../../util/getDataByKey'


export const convertTextDataApi = ({ text, apiData, templateData, dataStore, match }) => {
  if (text) {
    const split = text.split('[+]')

    //console.log(dataStore)

    split.map(val => {
      let value

      // templateData
      if (val.search('tmp:') !== -1) {
        const key = val.replace('tmp:', '')
        const data = templateData

        value = getDataByKey({ key, data })
        value = value || ''

      // api
      } else if (val.search('api:') !== -1) {
        const key = val.replace('api:', '')
        const data = apiData

        value = getDataByKey({ key, data })
        value = value || ''

      // store
      } else if (val.search(/store-\w+:\w+/g) !== -1) {
        const store = val.split(':')
        const storeName = store && store[0] && store[0].split('-')[1]
        const key = store && store[1]
        const data = dataStore && dataStore[storeName]

        //console.log(dataStore.cart)

        value = getDataByKey({ key, data })
        value = value || ''
      }

      if (value !== undefined) {
        text = text.replace(val, value)
      }

      if (val.search('[+]') !== -1) {
        text = text.replace('[+]', '')
      }
    })
  }

  return text
}
