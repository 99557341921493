import React from 'react'
import styles from './Tooltip.css'

export function Tooltip (props) {

  const style = {
    position: 'absolute',
    right: '0',
    top: '0',
    margin: '10px'
  };

  const boxStyle = {
    display: 'inline-block'
  }
  const joinStyle = Object.assign(boxStyle, props.style)

  return (
    <div style={joinStyle}>
      <div className={`${styles.tooltip} line-height-15`} style={{ borderBottom: props.hiddenLine && '0' }}>
        {props.children}
        <span className={styles.tooltiptext} style={{ width: props.width, marginLeft: props.left }}>{props.msg}</span>
      </div>
    </div>
  );
}

export function TooltipIcon (props) {
  return (
    <div style={props.style}>
      <div lassName={`${styles.tooltip} line-height-15`} style={{ border: '0' }}>
        <i id={props.id} onClick={props.onClick} className="material-icons icon">{props.icon}</i>
        <span className={styles.tooltiptext} style={{ fontSize: '0.8rem' }}>{props.msg}</span>
      </div>
    </div>
  );
}
