import React, { Component } from 'react'

// Components Global
import LoadingIcons from '../../components/LoadingIcons'

class ImagePreload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      preload: true
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ show: true })
    }, 0)
  }

  handleOnLoad = () => {
    this.setState({ preload: false })
  }

  render() {
    return (
      <div>
        { this.state.show &&
          <img onLoad={this.handleOnLoad} width={this.props.width} height={this.props.height} src={this.props.src} />
        }

        { this.state.preload &&
          <LoadingIcons name="laBallClipRotate" size="2x" width={this.props.preWidth} height={this.props.preHeight} style={{ padding: this.props.prePadding }} />
        }
      </div>
    )
  }
}

export default ImagePreload