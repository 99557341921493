export const setLangValueFn = (params={}) => {
  const { 
    localRequired, 
    enRequired, 
    localDefault, 
    enDefault,
    localTrim,
    engTrim
  } = params

  const langSchema = {
    _id: false,
    local: { 
      type: String,
      required: localRequired || false,
      default: localDefault,
      trim: localTrim
    },
    en: { 
      type: String,
      required: enRequired || false,
      default: enDefault,
      trim: engTrim
    }
  }

  return langSchema
}


export const discountFn = () => {
  const discountSchema = {
    _id: false,
    type: { type: String, default: 'amount', enum: ['amount', 'percent'] },
    percent: { type: Number, default: 0 }, 
    amount: { type: Number, default: 0 }
  }

  return discountSchema
}

export const vatFn = () => {
  const vatSchema = {
    _id: false,
    status: { type: Boolean, default: false },
    rate: { type: Number, default: 7 },
    include: { type: Boolean, default: false },
    amount: { type: Number, default: 0 },
  }

  return vatSchema
}

export const srcFn = () => {
  const srcSchema = {
    _id: false,
    status: { type: Boolean, default: false },
    rate: { type: Number, default: 0 },
    amount: { type: Number, default: 0 },
  }

  return srcSchema
}

export const whtFn = () => {
  const whtSchema = {
    _id: false,
    status: { type: Boolean, default: false },
    rate: { type: Number, default: 0 },
    amount: { type: Number, default: 0 },
  }

  return whtSchema
}