export const getLoginStatus = (auth) => {
  const user = auth && auth.user

  let status

  if (user && user !== 'unauthorized') {
    status = true
  } else {
    status = false
  }

  return status
}
