import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

// Components Local
import ItemList from './components/ItemList'

// Components Text Editor
import LinkDisplay from '../../../components/LinkDisplay'
import { convertLink } from '../../../components/LinkDisplay/js/linkDisplay'

// Js
import { setStyle, setStyleValue, setStyleResponsive } from '../../../components/ElementStyles/components/js/setDivStyle'


// css
import css from './css/listsExpand.css'


const NewLink = (props) => {
  const { to, mode, block, apiData, stopPropagation, children, onClick } = props

  if (!stopPropagation && !block) {
    return (
      <LinkDisplay
        mode={mode}
        value={to}
        apiData={apiData}>

        <div onClick={onClick}>{ children }</div>
      </LinkDisplay>
    )
  } else {
    return (
      <a style={{ cursor: 'pointer' }} onClick={onClick}>
        { children }
      </a>
    )
  }
}


class ListsExpand extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.expandActive()
  }

  setChecked = (id, value) => {
    let newState = {}
    newState['checked_'+id] = value

    this.setState(newState, () => {
      this.checkRequired(id)
    })
  }

  handleExpand = (index) => {
    // close
    if (this.state[`expand_${index}`]) {
      this.setState({ [`expandTransition_${index}`]: false }, () => {
        setTimeout(() => {
          this.setState({ [`expand_${index}`]: false })
        }, 250)
      })

    // open
    } else {
      this.setState({ [`expand_${index}`]: true }, () => {
        setTimeout(() => {
          this.setState({ [`expandTransition_${index}`]: true })
        }, 50)
      })
    }
  }

  loopExpandActive = (_id, pages=[]) => {
    const { listsAll, listsExpand } = this.props
    const page = listsAll.filter(list => list._id === _id)[0]

    if (page) {
      pages.push(page)

      if (page.folders_id) {
        this.loopExpandActive(page.folders_id, pages)
      } else {
        pages.map((page, i) => {
          const index = listsExpand.findIndex(list => list._id === page._id)

          if (index !== -1) {
            this.handleExpand(index)
          }
        })
      }
    }
  }

  expandActive = () => {
    const { match, fetchApiType, listsAll } = this.props
    let activeValue

    if (match && fetchApiType === 'folder-subfolder-product') {
      activeValue = match.params.folders_path
    }

    const data = listsAll && listsAll.filter(list => list.path === activeValue)[0]

    if (data) {
      this.loopExpandActive(data._id)
    }
  }

  handleOnClick = ({ list, index }) => {
    const { clickParent, mode } = this.props

    if (list.lists && list.lists.length !== 0) {
      if (clickParent) {
        if (!mode && this.props.onClick) {
          this.props.onClick()
        }
      } else {
        this.handleExpand(index)
      }
    } else {
      if (!mode && this.props.onClick) {
        this.props.onClick()
      }
    }
  }

  render() {
    const lang = this.props.lang || 'local'
    const {
      name,
      match,
      fetchApiType,
      lists,
      content,
      listsExpand,
      listsAll,
      pathType,
      activeValue,
      mode,
      clickParent,
      onClick,
      style,
      iconHidden,
      model,
      childNo,
      parentPath,
      showTotalAll
    } = this.props

    let no = childNo || 0
    const totalLink = lists && lists.link && JSON.parse(lists.link)
    const totalData = {
      path: parentPath
    }
    const totalInlink = convertLink(totalLink.inlink, totalData)
    const tatalActive = match && match.url === totalInlink ? 'active-jsx' : ''

    return (
      <div>
        { no !== 0 && showTotalAll &&
          <div className={`div-element-jsx ${css.list}`}>
            <LinkDisplay
              mode={mode}
              value={totalLink}
              apiData={totalData}>

              <div className={`box-content-jsx ${css.boxContent} ${tatalActive}`}>
                <ItemList
                  childNo={no}
                  iconHidden={iconHidden}
                  lang={lang}
                  item={{
                    name: {
                      local: 'ทั้งหมด'
                    }
                  }}
                />
              </div>
            </LinkDisplay>
          </div>
        }

        { listsExpand.map((list, index) => {
          const expand = this.state[`expand_${index}`]
          const expandTransition = this.state[`expandTransition_${index}`]
          const data = listsAll.filter(item => item._id === list._id)[0]
          const stopPropagation = list.lists && list.lists.length !== 0 && !clickParent

          let link, inlink, apiData

          if (content.api) {
            apiData = list
            link = lists && lists.link && JSON.parse(lists.link)

            if (link) {
              inlink = convertLink(link.inlink, list)
            }

          } else {
            link = list && list.link && JSON.parse(list.link)

            if (link) {
              inlink = link.inlink
            }
          }

          let activeClass

          if (match && match.url === inlink && list.folders_id !== null) {
            activeClass = 'active-jsx'
          } else {
            if (mode) {
              if ('index' === list[pathType]) {
                activeClass = 'active-jsx'
              }
            }
          }

          return (
            <div key={index}>
              <div className={`div-element-jsx ${css.list}`}>
                { link ?
                  <NewLink
                    apiData={apiData}
                    to={link}
                    block={mode}
                    onClick={() => this.handleOnClick({ list, index })}
                    stopPropagation={stopPropagation}>

                    <div className={`box-content-jsx ${css.boxContent} ${activeClass}`}>
                      <ItemList
                        childNo={no}
                        expand={expand}
                        iconHidden={iconHidden}
                        lang={lang}
                        item={data}
                      />
                    </div>
                  </NewLink>
                :
                  <div
                    className={`box-content-jsx ${css.boxContent} ${activeClass}`}
                    style={{
                      left: '0'
                    }}>

                    <ItemList childNo={no} expand={expand} iconHidden={iconHidden} lang={lang} item={data} />
                  </div>
                }
              </div>

              { list.lists && list.lists.length !== 0 && expand &&
                <div
                  className={css.boxList}
                  style={{ maxHeight: expandTransition ? '1000px' :'0' }}>

                  <div
                    className={css.boxSubList}
                    style={{
                      marginLeft: 0,
                      paddingLeft: 0,
                      borderLeft: (lists.length === index+1) && '0'
                    }}>

                    <ListsExpand
                      notFirst
                      fetchApiType={fetchApiType}
                      lang={lang}
                      mode={mode}
                      model={model}
                      match={match}
                      iconHidden={iconHidden}
                      clickParent={clickParent}
                      onClick={onClick}
                      activeValue={activeValue}
                      content={content}
                      lists={lists}
                      listsExpand={list.lists}
                      listsAll={listsAll}
                      name={name}
                      style={style}
                      childNo={no + 1}
                      parentPath={list.path}
                      showTotalAll={showTotalAll}
                    />
                  </div>
                </div>
              }
            </div>
          )
        }) }

        <style jsx>{`
          .div-element-jsx {
            height: ${style[`${name}-height`]};
            line-height: ${style[`${name}-line-height`]};
          }

          .box-content-jsx {
            ${setStyle(name, style)}
          }
          .box-content-jsx:hover {
            ${setStyle(`${name}-hover`, style)}
          }
          .box-content-jsx:active {
            ${setStyle(`${name}-active-click`, style)}
          }
          .active-jsx {
            ${setStyle(`${name}-active`, style, 'active')}
          }

        `}</style>
      </div>
    )
  }
}

export default connect()(ListsExpand)
