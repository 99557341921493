import React, { Component } from 'react'
import cuid from 'cuid'

class Checkbox extends Component {
  constructor(props) {
    super(props)
    const id = this.props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, '')
    const randomId = cuid()

    this.state = {
      id: randomId+id,
      checked: this.props.value || false
    }
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this)
    }

    if (this.props.getFirstValue) {
      this.props.getFirstValue(this.state.checked, this.props.id)
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ checked: this.props.value })
    }
  }

  getValue = () => {
    return this.state.checked
  }

  handleChange = (checked, id) => {
    if (checked) {
      this.setState({ checked: false })

      if (this.props.onChange) this.props.onChange(false, id)
    } else {
      this.setState({ checked: true })

      if (this.props.onChange) this.props.onChange(true, id)
    }
  }

  render() {
    const { labelClassName, inputForSite, styleGlobal, required } = this.props

    let valid = ''

    if (required) {
      if (this.state.checked) {
        valid = 'valid'
      } else {
        valid = 'invalid'
      }
    }

    return (
      <div className={`${this.props.className || ''} ${styleGlobal ? 'box-input-global-jsx' : ''}`} style={this.props.style}>
        <input
          type="checkbox"
          className={`filled-in ${labelClassName} ${inputForSite ? 'input-site' : 'input'} ${styleGlobal ? ' input-global-jsx' : ''} input-jsx ${required ? 'required' : ''} ${valid}`}
          id={this.state.id}
          data-id={this.props.id}
          name={this.state.id}
          checked={ this.state.checked }
          value={ this.state.checked }
          onChange={ () => this.handleChange(this.state.checked, this.props.id) }
          data-not-save={this.props.notSave}
        />


        <label htmlFor={this.state.id} className={`${this.props.size} ${this.props.labelClassName} recheck-form`}>
          { this.props.img && <img src={this.props.img} width="40" /> }
          { this.props.label && <div>{this.props.label}</div> }
        </label>
      </div>
    )
  }
}

export default Checkbox
