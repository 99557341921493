import React, { Fragment } from 'react'

// css
import css from './css/section.css'

const Section = (props) => {
  return (
    <Fragment>
      { props.mini ?
        <div className={props.className}>
          <div className={`border-top-1 border-bottom-1 border-top-eee border-bottom-eee ${props.titleClassName}`}>
            <div className="height-35 font-0-9 line-height-35 pd-left-10 bg-fafafa ">
              {props.title}
            </div>
          </div>

          <div className={`${props.bodyClassName}`} style={props.style}>
            {props.children}
          </div>
        </div>
      :
        <div className={`${css.section} min-height-50`}>
          <div className={`${css.header} pd-left-10 pd-right-10 bg-fafafa height-40 line-height-40 border-top-1 border-bottom-1 border-top-eee border-bottom-eee`}>
            <span className="font-0-9">{props.title}</span>
          </div>
          <div className={`${css.body} pd-15 ${props.className}`} style={props.style}>
            {props.children}
          </div>
        </div>
      }
    </Fragment>
  )
}

export default Section
