// Redux Actions
import {
  createOrder,
  updateOrder,
  removeOrder,
  uploadImagesOrder,
  countOrdersByGroup,
  countOrdersEcommerceByGroup
} from './OrdersActions'

// Functions
import { getShippingFeePrice } from '../Carts/CartsValues'

// Actions
import { fetchBills, removeBill } from '../Bills/BillsActions'
import { closeModal, openConfirm } from '../App/AppActions'

// Values
import { paymentFeeValue, shippingFeeValue } from '../Products/ProductsValues'

const title = "บิล"
const item = 'สินค้า'


// Sales note update status
export const orderUpdateStatus = ({ props, params, deleteBill, toastMsg='ย้อนกลับแล้ว' }) => {
  const { business, order } = props
  const bills_id = order.bills_id
  const query = {
    authName: 'admin',
    dbNo: business.dbNo,
    businesses_id: business._id,
    orders_id: order._id
  }

  return (id) => {
    props.dispatch(updateOrder(query, {
      body: params,
      toastMsg,
      loadId: id,
      confirmId: id
    })).then(res => {
      if (res.success) {

        // delete bill
        if (deleteBill && bills_id) {
          const query = {
            authName: 'admin',
            dbNo: business.dbNo,
            businesses_id: business._id,
            businessType: business.type,
            bills_id
          }

          props.dispatch(removeBill(query, {
            toastMsg: 'ลบใบเสนอราคาแล้ว',
            loadId: id,
            confirmId: id
          }))
        }
      }
    })
  }
}

export const returnDeleteBill = (props) => {
  const params = {
    bills_id: 'undefined',
    status: 'open'
  }

  return {
    label: `ย้อนสถานะ`,
    onConfirm: orderUpdateStatus({ props, params, deleteBill: true })
  }
}


export const createOrderDraft = (props, params={}) => {
  params.status = "draft"

  const query = {
    authName: 'admin',
    businesses_id: props.business._id,
    dbNo: props.business.dbNo
  }

  props.dispatch(createOrder(query, {
    body: params,
    loadId: 'createOrderDraft',
    modalId: 'draftOrdersListModal',
    confirmId: 'createOrderDraft',
    props,
    dataRedirect: {
      url: `/${props.match.params.username}/sales/pos-online/:_id/main`,
      resName: 'data'
    },
    toastMsg: 'สร้างบิลใหม่แล้ว'
  }))
}

export const createOrderForSellOnline = (props, params={}) => {
  params.status = "draft"
  params.channelSales = "sell-online"
  params.shipping = true
  params.eCommerce = true

  const shippingFee = shippingFeeValue()
  const paymentFee = paymentFeeValue()

  params.items = [
    {
      qty: 1,
      price: 0,
      type: 'shipping-fee',
      product: shippingFee,
      productItem: shippingFee
    },
    {
      qty: 1,
      price: 0,
      type: 'payment-fee',
      product: paymentFee,
      productItem: paymentFee
    }
  ]

  const query = {
    authName: 'admin',
    businesses_id: props.business._id,
    dbNo: props.business.dbNo
  }

  props.dispatch(createOrder(query, {
    body: params,
    loadId: 'createOrderDraft',
    modalId: 'draftOrdersListModal',
    confirmId: 'createOrderDraft',
    props,
    dataRedirect: {
      url: `/${props.match.params.username}/sell-online/all/:_id`,
      resName: 'data'
    },
    toastMsg: 'สร้างบิลใหม่แล้ว'
  }))
}

export const onEditModal = (id) => {
  return {
    modal: { id: id }
  }
}

export const onDeleteOrderForSellOnline = (props) => {
  const handleDelete = (props, toastMsg) => {
    return (id) => {
      let redirect

      const { order, location, business, match } = props
      const { username, status } = match.params


      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businesses_id: business._id,
        orders_id: id
      }

      props.dispatch(removeOrder(query, {
        toastMsg: toastMsg,
        loadId: id,
        redirect: {
          url: `/${username}/sell-online/${status}/all${location.search}`
        },
        confirmId: id,
        props: props
      }))
    }
  }

  return {
    label: `ลบ${title}`,
    onConfirm: handleDelete(props, `ลบ${title}นี้ อย่างถาวรแล้ว`)
  }
}


export const onDelete = (props) => {
  const handleDelete = (props, toastMsg) => {
    return (id) => {
      let redirect

      const { order, business, match } = props
      const bills_id = order && order.bills_id

      if (order) {
        if (order.status === 'draft') {
          if (match.params.orders_id && match.params.orders_id === id) {
            //redirect = { url: `/${match.params.username}/sales`}
          }
        } else if (order.status === 'open') {
          //redirect = { url: `/${match.params.username}/sales/contacts`}
        }
      }

      if (bills_id) {
        const query = {
          authName: 'admin',
          dbNo: business.dbNo,
          businesses_id: business._id,
          businessType: business.type,
          bills_id
        }

        props.dispatch(removeBill(query, {
          toastMsg: 'ลบใบเสนอราคาแล้ว',
          loadId: id,
        })).then(res => {
          if (res.success) {
            const query = {
              authName: 'admin',
              dbNo: business.dbNo,
              businesses_id: business._id,
              orders_id: id
            }

            props.dispatch(removeOrder(query, {
              toastMsg: toastMsg,
              loadId: id,
              redirect: redirect,
              confirmId: id,
              props: props
            }))
          }
        })

      } else {
        const query = {
          authName: 'admin',
          dbNo: business.dbNo,
          businesses_id: business._id,
          orders_id: id
        }

        props.dispatch(removeOrder(query, {
          toastMsg: toastMsg,
          loadId: id,
          redirect: redirect,
          confirmId: id,
          props: props
        }))
      }
    }
  }

  return {
    label: `ลบ${title}`,
    onConfirm: handleDelete(props, `ลบ${title}นี้ อย่างถาวรแล้ว`)
  }
}



export const onDeleteOrderItem = (props, _id) => {
  const { business, order } = props
  const query = {
    authName: 'admin',
    dbNo: business.dbNo,
    businesses_id: business._id,
    orders_id: order._id
  }

  props.dispatch(updateOrder(query, {
    body: {
      scenario: 'deleteOrderItem',
      updateType: 'pull',
      updateElement: 'items',
      deleteId: _id
    },
    loadId: _id,
    confirmId: _id
  }))
}



export const onDeleteItem = (props) => {
  const handleDeleteItem = (props, toastMsg) => {
    return (id) => {
      const { business, order } = props
      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businesses_id: business._id,
        orders_id: order._id
      }

      props.dispatch(updateOrder(query, {
        body: {
          scenario: 'deleteOrderItem',
          updateType: 'pull',
          updateElement: 'items',
          deleteId: id
        },
        loadId: id,
        confirmId: id,
        toastMsg: toastMsg
      }))
    }
  }

  return {
    label: `ลบ${item}`,
    onConfirm: handleDeleteItem(props, `ลบ${item}นี้แล้ว`)
  }
}



export const clearOrder = (props) => {
  props.dispatch(openConfirm('clearOrder', {
    title: `ล้างหน้าจอรายการสั่งซือนี้`,
    message: `กดปุ่ม "ล้างหน้าจอ" ถ้าคุณต้องการล้างข้อมูลการสั่งซื้อของรายการนี้`,
    icon: 'assignment',
    type: 'error',
    confirmIcon: 'delete_forever',
    confirmLabel: "ล้างหน้าจอ",
    cancelLabel: 'ยกเลิก',
    onConfirm: () => {
      const { business, order } = props
      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businesses_id: business._id,
        orders_id: order._id
      }

      return props.dispatch(updateOrder(query, {
        body: {
          scenario: 'clearOrder'
        },
        loadId: 'clearOrder',
        confirmId: 'clearOrder',
        toastMsg: 'ล้างหน้าจอรายการสั่งซือนี้แล้ว'
      }))
    }
  }))
}


export const cancelInstallments = (props, fetch) => {
  props.dispatch(openConfirm('cancelInstallments', {
    title: `เปลี่ยนการชำระแบบเต็มจำนวน`,
    message: `กดปุ่ม "ยืนยัน" ถ้าคุณต้องการเปลี่ยนการชำระแบบเต็มจำนวน`,
    icon: 'swap_horiz',
    type: 'success',
    confirmIcon: 'check',
    confirmLabel: "ยืนยัน",
    cancelLabel: 'ยกเลิก',
    onConfirm: () => {
      const { business, order } = props
      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businesses_id: business._id,
        orders_id: order._id
      }

      return props.dispatch(updateOrder(query, {
        body: {
          installments: [{
            startDate: null,
            dueDate: null,
          }]
        },
        loadId: 'cancelInstallments',
        confirmId: 'cancelInstallments',
        toastMsg: 'เลือกการชำระแบบเต็มจำนวนแล้ว'
      })).then(res => {
        if (res.success && fetch) {
          fetch()
        }
      })
    }
  }))
}

export const cancelShipping = (props, fetch) => {
  props.dispatch(openConfirm('cancelShipping', {
    title: `ยกเลิกการจัดส่ง`,
    message: `กดปุ่ม "ยืนยัน" ถ้าคุณต้องการยกเลิกการจัดส่ง`,
    icon: 'local_shipping',
    type: 'delete',
    confirmIcon: 'check',
    confirmLabel: "ยืนยัน",
    cancelLabel: 'ยกเลิก',
    onConfirm: () => {
      const { business, order } = props
      const query = {
        authName: 'admin',
        dbNo: business.dbNo,
        businesses_id: business._id,
        orders_id: order._id
      }

      return props.dispatch(updateOrder(query, {
        body: {
          scenario: 'cancelShipping',
          shipping: false,
          shippingStatus: 'null',
          //shippingAddress: 'null',
          shippingMethod: 'null'
        },
        loadId: 'cancelShipping',
        confirmId: 'cancelShipping',
        toastMsg: 'ยกเลิกการจัดส่งแล้ว'
      }))
    }
  }))
}


export const addPaymentMethod = (props, pricePaymentFee, price, businesses_payment_methods_id) => {
  const { business, order, forUser, auth } = props
  let authName

  if (forUser) {
    if (auth.user) {
      authName = "user"
    } else {
      authName = "unauth"
    }
  } else {
    authName = "admin"
  }

  const query = {
    authName,
    dbNo: business.dbNo,
    businesses_id: business._id,
    orders_id: order._id
  }

  const params = {
    scenario: 'updatePaymentMethod',
    pricePaymentFee,
    price,
    businesses_payment_methods_id
  }

  return props.dispatch(updateOrder(query, {
    body: params,
    loadId: 'payment-select-loading'
  }))
}

export const addShippingType = (props, shippingMethod) => {
  const { business, order, forUser, auth } = props
  let authName

  if (forUser) {
    if (auth.user) {
      authName = "user"
    } else {
      authName = "unauth"
    }
  } else {
    authName = "admin"
  }

  const query = {
    authName,
    dbNo: business.dbNo,
    businesses_id: business._id,
    orders_id: order._id
  }

  const shippingFee = business.shippingFees.filter(shipping => shipping._id === shippingMethod._id)[0]

  if (shippingFee) {
    const priceShippingFee = getShippingFeePrice({ order, shippingFee })
    const params = {
      scenario: 'updateShippingType',
      shippingMethod: shippingFee,
      priceShippingFee
    }

    return props.dispatch(updateOrder(query, {
      body: params,
      loadId: 'addShippingType',
      fieldId: 'addShippingType',
      //toastMsg: 'เลือกประเภทการจัดส่งแล้ว'
    })).then(res => {
      if (res.success) {
        updateShippingMethod(props, shippingMethod._id)
      }
    })
  } else {
    console.log('shippingFee undefined !!!')
  }
}

export const updateShippingMethod = (props, businesses_shipping_fees_id) => {
  const { order, business, forUser, auth } = props
  const shippingFee = business.shippingFees.filter(shipping => shipping._id === businesses_shipping_fees_id)[0]
  let authName

  if (forUser) {
    if (auth.user) {
      authName = "user"
    } else {
      authName = "unauth"
    }
  } else {
    authName = "admin"
  }

  const query = {
    authName,
    dbNo: business.dbNo,
    businesses_id: business._id,
    orders_id: order._id
  }

  if (shippingFee) {
    const priceShippingFee = getShippingFeePrice({ order, shippingFee })

    props.dispatch(updateOrder(query, {
      body: {
        scenario: 'updateShippingMethod',
        shippingMethod: shippingFee,
        shipping: true,
        priceShippingFee
      },
      loadId: 'shipping-select-loading',
      //toastMsg: 'เลือกการจัดส่งแล้ว'
    }))
  } else {
    console.log('shippingFee undefined !!!')
  }
}

export const selectShippingAddress = (props, shippingAddress, formName) => {
  const { business, order, forUser, auth } = props
  let authName

  if (forUser) {
    if (auth.user) {
      authName = "user"
    } else {
      authName = "unauth"
    }
  } else {
    authName = "admin"
  }

  const query = {
    authName,
    dbNo: business.dbNo,
    businesses_id: business._id,
    orders_id: order._id
  }

  if (shippingAddress !== undefined) {
    return props.dispatch(updateOrder(query, {
      body: {
        shippingAddress
      },
      loadId: formName,
      fieldId: formName,
      toastMsg: shippingAddress && 'เลือกที่อยู่จัดส่งแล้ว'
    }))
  } else {
    console.log('ไม่มีที่อยู่ที่เลือก !!!')
  }
}



export const updateOrderCustomer = (props, customer, formName, toastMsg) => {
  const { business, order } = props
  const query = {
    authName: 'admin',
    dbNo: business.dbNo,
    businesses_id: business._id,
    orders_id: order._id
  }

  if (!customer.customers_addresses_id) {
    toastMsg || 'แก้ไขที่อยู่จัดส่งแล้ว'
  }

  return props.dispatch(updateOrder(query, {
    body: {
      customer
    },
    loadId: formName,
    fieldId: formName,
    toastMsg
  })).then(res => {
    if (res.success) {
      if (props.updateCart && customer.customers_addresses_id !== undefined) {
        props.dispatch(closeModal(props.modalId))
        props.updateCart({ customers_addresses_id: customer.customers_addresses_id, formName: 'selectAddressSubmit' })
      }
    }
  })
}



export const confirmCreateOrderDraft = (props) => {
  props.dispatch(openConfirm('createOrderDraft', {
    title: `คุณต้องการพักบิล และสร้างบิลใหม่`,
    message: `กดปุ่ม "พักบิลและสร้างบิลใหม่" ถ้าคุณต้องการพักบิล และสร้างบิลใหม่`,
    icon: 'assignment',
    type: 'warning',
    confirmIcon: 'send',
    confirmLabel: "พักบิลและสร้างบิลใหม่",
    cancelLabel: 'ยกเลิก',
    onConfirm: () => {
      return createOrderDraft(props)
    }
  }))
}


export const confirmOrderPayment = (props, params, formName, dataRedirect) => {
  if (params) {
    const { payment } = params
    const { id, installment, order, business, forUser, auth, dispatch } = props

    let authName

    if (forUser) {
      if (auth.user) {
        authName = "user"
      } else {
        authName = "unauth"
      }
    } else {
      authName = "admin"
    }

    const query = {
      authName,
      dbNo: business.dbNo,
      businesses_id: business._id,
      orders_id: order._id
    }

    let toastMsg

    if (payment.type === 'cashOnDelivery') {
      params.scenario = 'updatePaymentCashOnDelivery'
      params.pricePaymentFee = parseInt(payment.pricePaymentFee)
      toastMsg = "ชำระเงินปลายทาง"

    } else if (forUser && payment.type === 'bankTransfer' && payment.status === 'waiting-confirmed-paid') {
      params.scenario = 'updatePaymentBankTransfer'
      toastMsg = "แจ้งการชำระเงินแล้ว"

    } else if (forUser && payment.type === 'cash' && payment.status === 'waiting-confirmed-paid') {
      params.scenario = 'updatePaymentBankTransfer'
      toastMsg = "แจ้งการชำระเงินแล้ว"

    } else {
      params.scenario = 'updatePaymentSuccess'
      toastMsg = "ชำระเงินแล้ว"
    }

    dispatch(updateOrder(query, {
      body: params,
      loadId: formName,
      modalId: id || installment._id,
      props,
      dataRedirect,
      toastMsg
    })).then(res => {
      if (!forUser && res.success && payment.type !== 'cashOnDelivery') {
        const query = {
          authName: 'admin',
          businesses_id: business._id,
          businessType: business.type,
          dbNo: business.dbNo
        }

        dispatch(fetchBills(query, {
          body: {
            where: `orders_id=${order._id}`,
          },
          loadId: 'bills-loading'
        }))
      }
    })
  }
}

export const onView = (url) => {
  return { url: url }
}



// Costs
const handleDeleteCost = (props, toastMsg) => {
  const { business, order } = props
  const query = {
    authName: 'admin',
    dbNo: business.dbNo,
    businesses_id: business._id,
    orders_id: order._id
  }

  return (id) => {
    props.dispatch(updateOrder(query, {
      body: {
        updateType: 'pull',
        updateElement: 'costs',
        deleteId: id
      },
      toastMsg: toastMsg,
      loadId: id,
      confirmId: id
    }))
  }
}

export const onDeleteCost = (props) => {
  let title = "รายการ"

  return {
    label: `ลบ${title}`,
    onConfirm: handleDeleteCost(props, `ลบ${title}นี้ อย่างถาวรแล้ว`)
  }
}



// Images Main
export const uploadImage = (props) => {
  const { business, order, forUser, auth } = props

  let authName

  if (forUser) {
    if (auth.user) {
      authName = "user"
    } else {
      authName = "unauth"
    }
  } else {
    authName = "admin"
  }

  return (id, files, album) => {
    const params = {
      body: {
        updateType: 'push',
        updateElement: 'images',
        album: album,
        profile: album === 'profile' ? true : false,
        cover: album === 'cover' ? true : false,
      },
      loadId: id,
      modalId: id,
      progressId: id
    }

    const query = {
      authName,
      dbNo: business.dbNo,
      businessType: business.type,
      businesses_id: business._id,
      orders_id: order._id,
    }

    const fileParams = {
      files,
      imagePrefix: 'orders'
    }

    if (files) {
      props.dispatch(uploadImagesOrder(query, fileParams, params))
    }
  }
}

export const onDeleteImage = (props) => {
  const { business, forUser, auth, order } = props

  let authName

  if (forUser) {
    if (auth.user) {
      authName = "user"
    } else {
      authName = "unauth"
    }
  } else {
    authName = "admin"
  }

  return (id) => {
    const params = {
      body: {
        updateType: 'pull-image',
        updateElement: 'images',
        deleteId: id
      },
      toastMsg: `ลบภาพนี้แล้ว`,
      loadId: id,
      confirmId: id,
      props: props
    }

    const query = {
      authName,
      dbNo: business.dbNo,
      businesses_id: business._id,
      orders_id: order._id
    }

    props.dispatch(updateOrder(query, params))
  }
}


export const getQueryParams = ({ params, name, forUser, businesses_id, customers_id, affiliate_customers_id }) => {
  let where, or

  if (!name) {
    name = params.name
  }

  if (name === 'all') {
    where = `status=!draft,status=!canceled`

    if (customers_id) {
      where = `customers_id=${customers_id},${where}`
    }

    if (affiliate_customers_id) {
      where = `affiliate_customers_id=${affiliate_customers_id},${where}`
    }

    if (businesses_id) {
      where = `businesses_id=${businesses_id},${where}`
    }

  } else {
    if (name === 'contacts') {
      where = `status=open,paymentStatus=unpaid`

      if (customers_id) {
        where = `customers_id=${customers_id},${where}`
      }

      if (affiliate_customers_id) {
        where = `affiliate_customers_id=${affiliate_customers_id},${where}`
      }

      if (businesses_id) {
        where = `businesses_id=${businesses_id},${where}`
      }

    } else if (name === 'unpaid') {
      where = `status=confirmed,paymentStatus=unpaid`

      if (customers_id) {
        where = `customers_id=${customers_id},${where}`
      }

      if (affiliate_customers_id) {
        where = `affiliate_customers_id=${affiliate_customers_id},${where}`
      }

      if (businesses_id) {
        where = `businesses_id=${businesses_id},${where}`
      }

    } else if (name === 'waiting-confirmed-paid') {
      where = `status=confirmed,paymentStatus=waiting-confirmed-paid`

      if (customers_id) {
        where = `customers_id=${customers_id},${where}`
      }

      if (affiliate_customers_id) {
        where = `affiliate_customers_id=${affiliate_customers_id},${where}`
      }

      if (businesses_id) {
        where = `businesses_id=${businesses_id},${where}`
      }

    } else if (name === 'partial-paid') {
      where = `status=confirmed,paymentStatus=partial-paid`

      if (customers_id) {
        where = `customers_id=${customers_id},${where}`
      }

      if (affiliate_customers_id) {
        where = `affiliate_customers_id=${affiliate_customers_id},${where}`
      }


      if (businesses_id) {
        where = `businesses_id=${businesses_id},${where}`
      }

    } else if (name === 'prepare-shipping') {
      where = `status=confirmed,shippingStatus=preparing`

      if (customers_id) {
        where = `customers_id=${customers_id},${where}`
      }

      if (affiliate_customers_id) {
        where = `affiliate_customers_id=${affiliate_customers_id},${where}`
      }


      if (businesses_id) {
        where = `businesses_id=${businesses_id},${where}`
      }

    } else if (name === 'shipping-received') {
      let or1 = `status=confirmed,shippingStatus=sending`
      let or2 = `status=confirmed,shippingStatus=successed,productAccepted=!true`

      if (customers_id) {
        or1 = `customers_id=${customers_id},${or1}`
        or2 = `customers_id=${customers_id},${or2}`
      }

      if (affiliate_customers_id) {
        or1 = `affiliate_customers_id=${affiliate_customers_id},${or1}`
        or2 = `affiliate_customers_id=${affiliate_customers_id},${or2}`
      }

      if (businesses_id) {
        or1 = `businesses_id=${businesses_id},${or1}`
        or2 = `businesses_id=${businesses_id},${or2}`
      }

      if (customers_id) {
        or = JSON.stringify([ or1, or2 ])
      } else if (affiliate_customers_id) {
        or = JSON.stringify([ or1, or2 ])
      } else {
        or = JSON.stringify([ or1, or2 ])
      }

    } else if (name === 'successed') {
      let or1 = `status=confirmed,paymentStatus=paid,shipping=true,shippingStatus=successed,productAccepted=true`
      let or2 = `status=confirmed,paymentStatus=paid,shipping=false`
      let or3 = `status=confirmed,shippingStatus=sending`
      let or4 = `status=confirmed,shippingStatus=successed,productAccepted=!true`

      if (customers_id) {
        or1 = `customers_id=${customers_id},${or1}`
        or2 = `customers_id=${customers_id},${or2}`
        or3 = `customers_id=${customers_id},${or3}`
        or4 = `customers_id=${customers_id},${or4}`
      }

      if (affiliate_customers_id) {
        or1 = `affiliate_customers_id=${affiliate_customers_id},${or1}`
        or2 = `affiliate_customers_id=${affiliate_customers_id},${or2}`
        or3 = `affiliate_customers_id=${affiliate_customers_id},${or3}`
        or4 = `affiliate_customers_id=${affiliate_customers_id},${or4}`
      }

      if (businesses_id) {
        or1 = `businesses_id=${businesses_id},${or1}`
        or2 = `businesses_id=${businesses_id},${or2}`
        or3 = `businesses_id=${businesses_id},${or3}`
        or4 = `businesses_id=${businesses_id},${or4}`
      }



      if (customers_id) {
        or = JSON.stringify([ or1, or2, or3, or4 ])
      } else if (affiliate_customers_id) {
        or = JSON.stringify([ or1, or2, or3, or4 ])
      } else {
        or = JSON.stringify([ or1, or2, or3, or4 ])
      }

    } else if (name === 'refunded') {
      where = `status=refunded,paymentStatus=refund`

      if (customers_id) {
        where = `customers_id=${customers_id},${where}`
      }

      if (affiliate_customers_id) {
        where = `affiliate_customers_id=${affiliate_customers_id},${where}`
      }

      if (businesses_id) {
        where = `businesses_id=${businesses_id},${where}`
      }

    } else if (name === 'canceled') {
      where = `status=canceled,paymentStatus=unpaid`

      if (customers_id) {
        where = `customers_id=${customers_id},${where}`
      }

      if (affiliate_customers_id) {
        where = `affiliate_customers_id=${affiliate_customers_id},${where}`
      }

      if (businesses_id) {
        where = `businesses_id=${businesses_id},${where}`
      }
    }
  }

  return {
    where,
    or
  }
}


export const getQueryEcommerceParams = ({ params, name, businesses_id, customers_id }) => {
  let where, or

  if (!name) {
    name = params.name
  }

  if (name === 'all') {
    where = `status=!canceled`
  } else {
    if (name === 'draft') {
      where = `status=draft`
    } else if (name === 'unpaid') {
      where = `status=open`
    } else if (name === 'prepare') {
      where = `status=paid`
    } else if (name === 'completed') {
      where = `status=completed`
    } else if (name === 'refunded') {
      where = `status=refunded`
    } else if (name === 'canceled') {
      where = `status=canceled`
    }
  }

  if (customers_id) where = `customers_id=${customers_id},${where}`
  if (businesses_id) where = `businesses_id=${businesses_id},${where}`

  where = `${where},eCommerce=true`

  return {
    where,
    or
  }
}


export const countOrdersGroupDefault = () => {
  return {
    contacts: 0,
    unpaid: 0,
    waitingConfirmedPaid: 0,
    partialPaid: 0,
    prepareShipping: 0,
    shippingReceived: 0
  }
}

export const sellOnlineCountOrders = ({ props, customers_id, business, setCount }) => {
  const businesses_id = business._id
  const dbNo = business.dbNo
  const query = {
    dbNo
  }

  props.dispatch(countOrdersEcommerceByGroup(query, {
    body: {
      draft: getQueryEcommerceParams({ name: 'draft', customers_id, businesses_id }),
      unpaid: getQueryEcommerceParams({ name: 'unpaid', customers_id, businesses_id }),
      prepare: getQueryEcommerceParams({ name: 'prepare', customers_id, businesses_id }),
    }
  })).then(res => {
    setCount(res)
  })
}

export const countOrdersGroup = (_this, { customers_id, business, affiliate_customers_id }) => {
  const businesses_id = business._id
  const dbNo = business.dbNo
  const query = {
    dbNo
  }



  _this.props.dispatch(countOrdersByGroup(query, {
    body: {
      contacts: getQueryParams({ name: 'contacts', customers_id, businesses_id, affiliate_customers_id }),
      unpaid: getQueryParams({ name: 'unpaid', customers_id, businesses_id, affiliate_customers_id }),
      waitingConfirmedPaid: getQueryParams({ name: 'waiting-confirmed-paid', customers_id, businesses_id, affiliate_customers_id }),
      partialPaid: getQueryParams({ name: 'partial-paid', customers_id, businesses_id, affiliate_customers_id }),
      prepareShipping: getQueryParams({ name: 'prepare-shipping', customers_id, businesses_id, affiliate_customers_id }),
      shippingReceived: getQueryParams({ name: 'shipping-received', customers_id, businesses_id, affiliate_customers_id })
    }

  })).then(res => {
    _this.setState(res)
  })
}

export const ordersGroupMenu = ({ pathStart, pathAllIndex, v2, state, forUser }) => {
  let linkAll

  if (v2) {
    linkAll = `${pathStart}/all/all`
  } else {
    linkAll = pathAllIndex ? pathStart : `${pathStart}/all`
  }

  return [
    {
      name: 'ทั้งหมด',
      icon: 'library_books',
      link: linkAll,
      searchLinkActive: v2 && `${pathStart}/all/`,
      exact: true,
    },
    {
      name: 'รอยืนยัน',
      nameShort: 'รอยืนยัน',
      icon: 'chat',
      link: v2 ? `${pathStart}/contacts/all` : `${pathStart}/contacts`,
      searchLinkActive: v2 && `${pathStart}/contacts/`,
      badge: { amount: state.contacts, bg: 'red' },
    },
    {
      name: 'ยังไม่ชำระเงิน',
      nameShort: 'ยังไม่ชำระ',
      icon: 'assignment_late',
      link: v2 ? `${pathStart}/unpaid/all` : `${pathStart}/unpaid`,
      searchLinkActive: v2 && `${pathStart}/unpaid/`,
      badge: { amount: state.unpaid, bg: 'red' },
    },
    {
      name: 'รอยืนยันการชำระ',
      nameShort: 'รอยืนยันการชำระ',
      icon: 'gavel',
      link: v2 ? `${pathStart}/waiting-confirmed-paid/all` : `${pathStart}/waiting-confirmed-paid`,
      searchLinkActive: v2 && `${pathStart}/waiting-confirmed-paid/`,
      badge: { amount: state.waitingConfirmedPaid, bg: 'red' }
    },
    /*{
      name: 'ชำระเงินบางส่วน',
      nameShort: 'ชำระบางส่วน',
      icon: 'pie_chart',
      link: `${pathStart}/partial-paid`,
      badge: { amount: state.partialPaid, bg: 'red' }
    },*/

    {
      name: 'เตรียมส่ง',
      nameShort: 'เตรียมส่ง',
      icon: 'local_shipping',
      link: v2 ? `${pathStart}/prepare-shipping/all` : `${pathStart}/prepare-shipping`,
      searchLinkActive: v2 && `${pathStart}/prepare-shipping/`,
      badge: { amount: state.prepareShipping, bg: 'red' }
    },
    /*{
      name: 'ที่ต้องได้รับ',
      nameShort: 'ที่ต้องได้รับ',
      icon: 'card_giftcard',
      link: `${pathStart}/shipping-received`,
      badge: { amount: state.shippingReceived, bg: 'red' }
    },*/
    {
      name: 'สำเร็จแล้ว',
      nameShort: 'สำเร็จแล้ว',
      icon: 'check_circle',
      link: v2 ? `${pathStart}/successed/all` : `${pathStart}/successed`,
      searchLinkActive: v2 && `${pathStart}/successed/`,
    },
    {
      name: 'รายการยกเลิก',
      nameShort: 'ยกเลิก',
      title: true
    },
    {
      name: 'คืนเงินแล้ว',
      icon: 'restore_page',
      link: v2 ? `${pathStart}/refunded/all` : `${pathStart}/refunded`,
      searchLinkActive: v2 && `${pathStart}/refunded/`,
    },
    {
      name: 'ยกเลิกแล้ว',
      icon: 'cancel',
      link: v2 ? `${pathStart}/canceled/all` : `${pathStart}/canceled`,
      searchLinkActive: v2 && `${pathStart}/canceled/`,
    }
  ]
}



export const sellOnlineOrdersGroupMenu = ({ pathStart, count }) => {
  return [
    {
      name: 'ทั้งหมด',
      icon: 'library_books',
      link: `${pathStart}/all/all`,
      searchLinkActive: `${pathStart}/all/`,
      exact: true
    },
    {
      name: 'ฉบับร่าง',
      nameShort: 'ฉบับร่าง',
      icon: 'drive_file_rename_outline',
      link: `${pathStart}/draft/all`,
      searchLinkActive: `${pathStart}/draft/`,
      badge: count && { amount: count.draft, bg: 'red' }
    },
    {
      name: 'ยังไม่ชำระ',
      nameShort: 'ยังไม่ชำระ',
      icon: 'credit_card_off',
      link: `${pathStart}/unpaid/all`,
      searchLinkActive: `${pathStart}/unpaid/`,
      badge: count && { amount: count.unpaid, bg: 'red' }
    },
    {
      name: 'เตรียมส่ง',
      nameShort: 'เตรียมส่ง',
      icon: 'archive',
      link: `${pathStart}/prepare/all`,
      searchLinkActive: `${pathStart}/prepare/`,
      badge: count && { amount: count.prepare, bg: 'red' }
    },
    {
      name: 'ส่งแล้ว',
      nameShort: 'ส่งแล้ว',
      icon: 'verified',
      link: `${pathStart}/successed/all`,
      searchLinkActive: `${pathStart}/successed/`,
    },
    {
      name: 'รายการยกเลิก',
      nameShort: 'ยกเลิก',
      title: true
    },
    {
      name: 'คืนเงินแล้ว',
      icon: 'restore_page',
      link: `${pathStart}/refunded/all`,
      searchLinkActive: `${pathStart}/refunded/`
    },
    {
      name: 'ยกเลิกแล้ว',
      icon: 'highlight_off',
      link: `${pathStart}/canceled/all`,
      searchLinkActive: `${pathStart}/canceled/`
    }
  ]
}








