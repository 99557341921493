import { customerAddressValue } from '../Customers/CustomersValues'
import { businessShippingFeeValue } from '../Businesses/BusinessesValues'
import { getPricePaymentFee } from '../Carts/CartsValues'

// Utils
import { getGeoOnly } from '../../util/getGeo/getGeo'

// Values
import { customerValue } from '../../redux/Customers/CustomersValues'

// Payment Fee Installment
export const getPricePaymentFeeInstallment = ({ order, business, installment }) => {
  const paymentFee = order.items.filter(item => item.type === 'payment-fee')[0]

  let price = 0

  if (paymentFee) {
    price = installment.pricePaymentFee || 0

    return price
  } else {
    const { shippingMethod } = order

    const paymentMethod = business.paymentMethods.cashOnDelivery
    paymentMethod.id = 'cashOnDelivery'

    const shippingFee = business.shippingFees.filter(fee => fee._id === shippingMethod._id)[0]
    const totalBeforePaymentFee = installment.price

    price = getPricePaymentFee({ paymentMethod, shippingFee, totalBeforePaymentFee })

    return price
  }
}


/* price total */
export const getItemPriceTotal = (orderItem) => {
  if (orderItem) {
    let price = orderItem.price

    return price * orderItem.qty
  }
}


/* price group */
export const getPriceGroupProduct = (orderItem, orders) => {
  let price = getPriceProduct(orderItem)

  if (orders) {
    orders.map((order, i) => {
      price += getPriceProduct(order)
    })
  }

  return price
}

/* price item total all */
export const getPriceProduct = (orderItem) => {
  let priceProduct = orderItem.price * orderItem.qty
  let priceTotal = priceProduct
  priceTotal = parseFloat(priceTotal.toFixed(2))

  return priceTotal
}


export const getPriceProductsAll = (order) => {
  let price = 0

  order.items.map(item => {
    price += getPriceProduct(item)
  })

  price = parseFloat(price.toFixed(2))

  return price
}

export const getPriceProductsAllOnly = (order) => {
  let price = 0

  order.items.map(item => {
    if (item.type !== 'payment-fee' && item.type !== 'shipping-fee') {
      price += getPriceProduct(item)
    }
  })

  price = parseFloat(price.toFixed(2))

  return price
}

// prices weight all
export const getProductsWeightAll = (order) => {
  let weight = 0

  if (order) {
    order.items.map(item => {
      if (item.productItem.weight) {
        weight += item.productItem.weight
      }
    })


  }

  weight = parseFloat(weight.toFixed(2))

  return weight
}


export const getDiscount  = (order) => {
  let discount = 0
  let totalPriceProducts = getPriceProductsAll(order)

  if (order.discount.type === 'currency') {
    discount = order.discount.value
  } else if (order.discount.type === 'percent') {
    discount = totalPriceProducts * order.discount.value / 100
  }

  discount = parseFloat(discount.toFixed(2))

  return discount
}


export const getCoupon = (order) => {
  return 0
}


export const getTotalAfterDiscount = (order) => {
  let totalPriceProducts = getPriceProductsAll(order)
  let discount = getDiscount(order)
  let coupon = getCoupon(order)
  let totalAfterDiscount = totalPriceProducts - discount - coupon

  totalAfterDiscount = parseFloat(totalAfterDiscount.toFixed(2))

  return totalAfterDiscount
}


export const getSRC = (order) => {
  let serviceCharge = 0

  if (order.src && order.src.status) {
    let totalAfterDiscount = getTotalAfterDiscount(order)
    serviceCharge = getPriceSRC(totalAfterDiscount, order.src.rate)
  }

  return serviceCharge
}

export const getPriceSRC = (price, rate) => {
  let priceSRC = price * rate / 100
  priceSRC = parseFloat(priceSRC.toFixed(2))

  return priceSRC
}



export const getSubtotal  = (order) => {
  let totalAfterDiscount = getTotalAfterDiscount(order)
  let src = getSRC(order)
  let subtoal = totalAfterDiscount + src

  /*if (order.vat.include) {
    const src = order.src.status ? order.src.rate : 0
    const { priceAfterTax } = getPriceAfterTax({ totalAfterDiscount, src })

    console.log(priceAfterTax)
  }*/

  subtoal = parseFloat(subtoal.toFixed(2))

  return subtoal
}

// get vat of order
export const getVAT = (order) => {
  let subtotal = getSubtotal(order)
  let vat = 0

  if (order.vat && order.vat.status) {
    vat = getPriceVAT(subtotal, order.vat.rate, order.vat.include)
  }

  return vat
}

// get price vat
export const getPriceVAT = (price, rate, include) => {
  let priceVAT = 0

  // vat ใน
  if (include) {
    let beforeVat = price * 100 / (100 + rate)
    priceVAT = price - beforeVat

  // vat นอก
  } else {
    priceVAT = price * rate / 100
  }

  priceVAT = parseFloat(priceVAT.toFixed(2))

  return priceVAT
}

// get price before vat
export const getPriceBeforeVAT = (order) => {
  let subtotal = getSubtotal(order)
  let vat = getVAT(order)
  let beforeVat = subtotal

  if (order.vat.include) {
    beforeVat = subtotal - vat
  }

  beforeVat = parseFloat(beforeVat.toFixed(2))

  return beforeVat
}

// get wht of order
export const getWHT = (order) => {
  let priceBeforeVat = getPriceBeforeVAT(order)
  let wht = 0

  if (order.wht && order.wht.status) {
    wht = getPriceWHT(priceBeforeVat, order.wht.rate, order.wht.include)
  }

  return wht
}

// get price wht
export const getPriceWHT = (price, rate, include) => {
  let wht = price * rate / 100

  // ผู้จ่ายออกแทนให้ตลอดไป (ราคาก่อน vat x rate / 100 - rate)
  if (include) {
    wht = price * rate / (100 - rate)

  // หักจากผู้ให้บริการ (ราคาก่อน vat x rate / 100)
  } else {
    wht = price * rate / 100
  }

  wht = parseFloat(wht.toFixed(2))

  return wht
}

// get total price all
export const getTotalPriceAll = (order) => {
  let priceBeforeVat = getPriceBeforeVAT(order)
  let vat = getVAT(order)
  let wht = getWHT(order)
  let totalPriceAll = priceBeforeVat + vat - wht

  totalPriceAll = parseFloat(totalPriceAll.toFixed(2))

  return totalPriceAll
}


export const getHeightSummery = (order) => {
  let discount = getDiscount(order) > 0 ? 25 : 0
  let coupon = getCoupon(order) > 0 ? 25 : 0
  let SRC = getSRC(order) > 0 ? 25 : 0
  let VAT = getVAT(order) > 0 ? 25 : 0
  let beforeVat = getVAT(order) > 0 && order.vat.include ? 25 : 0
  let WHT = getWHT(order) > 0 ? 25 : 0

  let shippingFee = order.items.filter(item => item.productItem.subtype === 'shipping-fee')[0]
  let paymentFee = order.items.filter(item => item.productItem.subtype === 'payment-fee')[0]

  shippingFee = shippingFee ? 25 : 0
  paymentFee = paymentFee ? 25 : 0

  let height = discount+coupon+SRC+beforeVat+VAT+WHT+shippingFee+paymentFee

  return height
}


export const getTotalPriceInstallments = (order) => {
  let total = 0
  let installments = order.installments

  installments.map(installment => {
    if (installment.price) total += installment.price
  })

  return total
}

export const getTotalPricePaid = (order) => {
  let total = 0
  let installments = order.installments

  installments.map(installment => {
    if (installment.payment && installment.payment.status === 'paid') {
      if (installment.price) total += installment.price
    }
  })

  return total
}


export const getOrderNo = (order) => {
  return `B000${order.billNo}`
}


export const orderValue = ({ order, business, lang="local" }) => {
  if (order) {
    const status = getStatus(order.status)
    const useStatus = order && statusAllECommerce.filter(item => item.id === order.status)[0]
    const fullStatus = getFullStatus({ order, lang })
    const paymentStatus = getPaymentStatus(order.paymentStatus)
    const customer = order.customer
    const { shipping, shippingAddress, shippingMethod } = order
    const shippingAddressData = getShippingAddressData({ shipping, shippingAddress, lang })

    let address, shippingFee, customerDetail

    if (order.customers_id && customer) {
      address = customerAddressValue({ customer, address: shippingAddress, lang })
      customerDetail = customerValue({ customer, lang })
    } else {
      address = customerAddressValue({ address: shippingAddress, lang })
    }

    let affiliateCustomerValue

    if (order.affiliate_customers_id && order.affiliateCustomer) {
      affiliateCustomerValue = customerValue({ customer: order.affiliateCustomer, lang })
    }

    shippingFee = businessShippingFeeValue({ business, shippingFee: shippingMethod, lang })



    return {
      ...order,
      ...status,
      ...fullStatus,
      ...paymentStatus,
      useStatus,
      address,
      shippingFee,
      shippingAddressData,
      customerValue: customerDetail,
      affiliateCustomerValue
    }
  }
}


export const orderValueECommerce = ({ order, business, lang="local" }) => {
  if (order) {
    const useStatus = order && statusAllECommerce.filter(item => item.id === order.status)[0]
    const customer = order.customer
    const { shipping, shippingAddress } = order
    const shippingAddressData = getShippingAddressData({ shipping, shippingAddress, lang })

    return {
      ...order,
      useStatus,
      shippingAddressData
    }
  }
}


export const getShippingAddressData = ({ shipping, shippingAddress, lang }) => {
  let shippingAddressData

  console.log(shipping)

  if (shipping && shippingAddress) {
    const { name, tel, address, geo } = shippingAddress || {}
    const data = geo && getGeoOnly({ geo })

    shippingAddressData = {
      name: name && name[lang],
      tel: tel && tel.number,
      address: address && address[lang],
      geo: data && data.short
    }
  }

  return shippingAddressData
}


export const statusAllECommerce = [
  { no: 1, id: 'draft', name: `ร่าง`, icon: 'drive_file_rename_outline', color: '#777777' },
  { no: 2, id: 'open', name: 'ยังไม่ชำระ', icon: 'credit_card_off', color: '#ff0000' },
  { no: 3, id: 'paid', name: 'เตรียมส่ง', icon: 'archive', color: '#ffb300' },
  { no: 4, id: 'completed', name: 'ส่งแล้ว', icon: 'verified', color: '#7cb342' },
  { no: 5, id: 'canceled', name: 'ยกเลิก', icon: 'highlight_off', color: '#d50000' }
]


export const getStatus = (status, lang="local") => {
  let statusName, statusColor

  if (status === 'draft') {
    statusName = 'ฉบับร่าง'
    statusColor = ''
  } else if (status === 'open') {
    statusName = 'เปิด'
    statusColor = ''
  } else if (status === 'approved') {
    statusName = 'อนุมัติแล้ว'
    statusColor = ''
  } else if (status === 'unapproved') {
    statusName = 'ไม่อนุมัติ'
    statusColor = ''
  } else if (status === 'confirmed') {
    statusName = 'ยืนยันแล้ว'
    statusColor = ''
  } else if (status === 'completed') {
    statusName = 'เสร็จสมบูรณ์'
    statusColor = ''
  } else if (status === 'canceled') {
    statusName = 'ยกเลิกแล้ว'
    statusColor = ''
  }

  return {
    statusName,
    statusColor
  }
}

export const getFullStatus = ({ order, lang, forUser }) => {
  let fullStatus, fullStatusName, fullStatusColor, fullStatusPath

  if (order.status === 'open') {
    fullStatus = 'open'
    fullStatusName = 'รอยืนยัน'
    fullStatusColor = '#1e88e5'
    fullStatusPath = 'contacts'

  } else if (order.status === 'canceled') {
    fullStatus = 'canceled'
    fullStatusName = 'ยกเลิกแล้ว'
    fullStatusColor = '#c0c0c0'
    fullStatusPath = 'canceled'

  } else if (order.status === 'refunded') {
    fullStatus = 'refunded'
    fullStatusName = 'คืนเงินแล้ว'
    fullStatusColor = '#d500f9'
    fullStatusPath = 'refunded'

  } else if (order.status === 'confirmed') {
    const payment = getPaymentStatus(order.paymentStatus)

    if (order.shipping && (order.paymentStatus === 'waiting-cash-on-delivery' || order.paymentStatus === 'paid')) {
      const shipping = getShippingStatus(order.shippingStatus)

      if (shipping.shippingStatus === 'successed') {
        if (order.productAccepted) {
          fullStatus = 'successed'
          fullStatusName = 'สำเร็จแล้ว'
          fullStatusColor = '#7cb342'
          fullStatusPath = 'successed'

        } else {
          fullStatus = 'shipping-successed'
          fullStatusName = 'สำเร็จแล้ว' //'ที่ต้องได้รับ'
          fullStatusColor = '#7cb342' //'#aeea00'
          fullStatusPath = 'shipping-received'
        }

      } else {
        fullStatus = shipping.shippingStatus
        fullStatusName = shipping.shippingStatusName
        fullStatusColor = shipping.shippingStatusColor
        fullStatusPath = shipping.shippingStatusPath
      }


    } else {
      if (payment.paymentStatus === 'paid') {
        fullStatus = 'successed'
        fullStatusName = 'สำเร็จแล้ว'
        fullStatusColor = '#7cb342'
        fullStatusPath = 'successed'

      } else {
        fullStatus = payment.paymentStatus
        fullStatusName = payment.paymentStatusName
        fullStatusColor = payment.paymentStatusColor
        fullStatusPath = payment.paymentStatusPath
      }
    }
  }

  return {
    fullStatus,
    fullStatusName,
    fullStatusColor,
    fullStatusPath
  }
}

export const getPaymentStatus = (paymentStatus, lang="local") => {
  let paymentStatusName, paymentStatusColor, paymentStatusPath

  if (paymentStatus === 'unpaid') {
    paymentStatusName = 'ยังไม่ชำระเงิน'
    paymentStatusColor = 'red'
    paymentStatusPath = 'unpaid'

  } else if (paymentStatus === 'partial-paid') {
    paymentStatusName = 'ชำระเงินบางส่วน'
    paymentStatusColor = '#ff9800'
    paymentStatusPath = 'partial-paid'

  } else if (paymentStatus === 'partial-paid') {
    paymentStatusName = 'ชำระเงินบางส่วน'
    paymentStatusColor = '#ff9800'
    paymentStatusPath = 'partial-paid'

  } else if (paymentStatus === 'waiting-confirmed-paid') {
    paymentStatusName = 'รอยืนยันการชำระ'
    paymentStatusColor = '#ff6e40'
    paymentStatusPath = 'waiting-confirmed-paid'

  } else if (paymentStatus === 'waiting-cash-on-delivery') {
    paymentStatusName = 'รอเก็บเงินปลายทาง'
    paymentStatusColor = '#ff9800'
    paymentStatusPath = 'prepare-shipping'

  } else if (paymentStatus === 'paid') {
    paymentStatusName = 'ชำระเงินแล้ว'
    paymentStatusColor = '#7cb342'
    paymentStatusPath = 'paid'

  } else if (paymentStatus === 'refund') {
    paymentStatusName = 'คืนเงิน'
    paymentStatusColor = '#d81b60'
    paymentStatusPath = 'refund'
  }

  return {
    paymentStatus,
    paymentStatusName,
    paymentStatusColor,
    paymentStatusPath
  }
}

export const getShippingStatus = (shippingStatus, lang="local") => {
  let shippingStatusName, shippingStatusColor, shippingStatusPath

  if (shippingStatus === 'preparing') {
    shippingStatusName = 'เตรียมส่ง'
    shippingStatusColor = '#ffb300'
    shippingStatusPath = 'prepare-shipping'

  } else if (shippingStatus === 'sending') {
    shippingStatusName = 'สำเร็จแล้ว' //'ที่ต้องได้รับ'
    shippingStatusColor = '#7cb342' //'#aeea00'
    shippingStatusPath = 'successed' //'shipping-received'

  } else if (shippingStatus === 'successed') {
    shippingStatusName = 'ได้รับสินค้าแล้ว'
    shippingStatusColor = '#7cb342'
    shippingStatusPath = 'shipping-successed'
  }

  return {
    shippingStatus,
    shippingStatusName,
    shippingStatusColor,
    shippingStatusPath
  }
}


export const getPaymentStatusAll = (order) => {
  const installments = order.installments
  const paid = installments.filter(installment => installment.payment.status === 'paid')
  const unpaid = installments.filter(installment => installment.payment.status === 'unpaid')

  let status, name, color

  if (installments.length === 0) {
    status = "unpaid"
    name = "ยังไม่ชำระเงิน"
    color = "red"
  } else {
    if (installments.length === paid.length) {
      status = "paid"
      name = "ชำระเงินแล้ว"
      color = "#7cb342"
    } else {
      if (installments.length === unpaid.length) {
        status = "unpaid"
        name = "ยังไม่ชำระเงิน"
        color = "red"
      } else {
        status = "partial-paid"
        name = "ชำระเงินบางส่วน"
        color = "#ff9800"
      }
    }
  }

  return { status, name, color }
}



export const getPaymentGetway = (payment) => {
  let name

  if (payment.type === 'cash') {
    name = "เงินสด"

  } else if (payment.type === 'check') {
    name = "เช็ค"

  } else if (payment.type === 'bankTransfer') {
    name = "โอนเงิน"

  } else if (payment.type === 'creditCard' || payment.type === 'creditCard') {
    name = "บัตรเครดิต"

  } else if (payment.type === 'cashOnDelivery') {
    name = "เก็บเงินปลายทาง"
  }


  return { name }
}

export const getOrderRowsSummary = (order) => {
  let discount, coupon, src, excludingVat, vat, wht

  if (getDiscount(order) > 0) {
    discount = {
      status: true,
      value: getDiscount(order),
      rate: order.discount.type === 'percent' && order.discount.value
    }
  }

  if (getCoupon(order) > 0) coupon = { status: true, value: getCoupon(order) }
  if (getSRC(order) > 0) src = { status: true, value: getSRC(order), rate: order.src.rate }
  if (getVAT(order) > 0 && order.vat.include) excludingVat = { status: true, value: getPriceBeforeVAT(order) }
  if (getVAT(order) > 0) vat = { status: true, value: getVAT(order), rate: order.vat.rate }
  if (getWHT(order) > 0) wht = { status: true, value: getWHT(order), rate: order.wht.rate }

  return {
    discount,
    coupon,
    src,
    excludingVat,
    vat,
    wht
  }
}

export const getOrderLabelsSummary = (order) => {
  const { discount, coupon, src, excludingVat, vat, wht } = getOrderRowsSummary(order)
  const shippingFee = order.items.filter(item => item.productItem.subtype === 'shipping-fee')[0]
  const paymentFee = order.items.filter(item => item.productItem.subtype === 'payment-fee')[0]

  let priceProductsAll = getPriceProductsAll(order)
  let priceShippingFee = 0, pricePaymentFee = 0

  if (shippingFee) priceShippingFee = getItemPriceTotal(shippingFee)
  if (paymentFee) pricePaymentFee = getItemPriceTotal(paymentFee)

  const rows = [{
    type: 'productsTotal', label: 'มูลค่าสินค้า/บริการ', value: priceProductsAll - priceShippingFee - pricePaymentFee
  }]

  if (shippingFee) rows.push({ type: 'shipping-fee', label: 'ค่าจัดส่ง', value: getItemPriceTotal(shippingFee) })
  if (paymentFee) rows.push({ type: 'payment-fee', label: 'ค่าธรรมเนียม', value: getItemPriceTotal(paymentFee) })

  if (discount) rows.push({ type: 'discount', label: 'ส่วนลด', ...discount })
  if (coupon) rows.push({ type: 'coupon', label: 'คูปอง', ...coupon })
  if (src) rows.push({ type: 'src', label: 'เซอร์วิสชาร์จ', ...src })
  if (excludingVat) rows.push({ type: 'excludingVat', label: 'ราคาไม่รวมภาษีมูลค่าเพิ่ม', ...excludingVat })
  if (vat) rows.push({ type: 'vat', label: 'ภาษีมูลค่าเพิ่ม', ...vat })
  if (wht) rows.push({ type: 'wht', label: 'หักภาษี ณ ที่จ่าย', ...wht })

  return rows
}

export const getOrderInstallmentLabelsSummary = (order, installment) => {
  let price = installment.price
  let src = order.src.status ? order.src.rate : 0
  let vat = order.vat.status ? order.vat.rate : 0
  let wht = order.wht.status ? order.wht.rate : 0

  const {
    priceBeforeTax,
    priceSrc,
    priceVat,
    priceAfterVat,
    priceWht,
    priceAfterTax
  } = getPriceAfterTax({ price, src, vat, wht })

  const rows = [{
    type: 'productsTotal', label: 'มูลค่าสินค้า/บริการ', value: priceBeforeTax
  }]


  if (priceSrc) {
    rows.push({
      type: 'vat',
      label: `เซอร์วิสชาร์จ`,
      value: priceSrc,
      status: true,
      rate: src
    })
  }

  if (priceVat) {
    rows.push({
      type: 'vat',
      label: `ภาษีมูลค่าเพิ่ม`,
      value: priceVat,
      status: true,
      rate: vat
    })
  }

  if (priceWht) {
    rows.push({
      type: 'wht',
      label: `หักภาษี ณ ที่จ่าย`,
      value: priceWht,
      status: true,
      rate: wht
    })
  }

  return rows
}

// ราคาหลังหัก ณ ที่จ่าย
export const getPriceAfterTax = ({ price, src, vat, wht }) => {
  src = src || 0
  vat = vat || 0
  wht = wht || 0

  let priceBeforeTax = price * 100 / (100 + (vat - wht))
  let priceSrc = priceBeforeTax * src / 100
  let priceVat = priceBeforeTax * vat / 100
  let priceAfterVat = priceBeforeTax + priceVat
  let priceWht = priceBeforeTax * wht / 100
  let priceAfterTax = priceAfterVat - priceWht

  priceBeforeTax = parseFloat(priceBeforeTax.toFixed(2))
  priceVat = parseFloat(priceVat.toFixed(2))
  priceAfterVat = parseFloat(priceAfterVat.toFixed(2))
  priceWht = parseFloat(priceWht.toFixed(2))
  priceAfterTax = parseFloat(priceAfterTax.toFixed(2))

  if (src === 0) {
    priceSrc = undefined
  }

  if (vat === 0) {
    priceVat = undefined
    priceAfterVat = undefined
  }

  if (wht === 0) {
    priceWht = undefined
  }

  return {
    priceBeforeTax,
    priceSrc,
    priceVat,
    priceAfterVat,
    priceWht,
    priceAfterTax
  }
}


// costs
export const costsValue = (order) => {
  const costs = getCosts(order)
  const price = order.price || 0
  const qty = order.qty || 0
  const profit = price - costs

  return {
    costs,
    price,
    profit,
    costsTotal: costs * qty,
    priceTotal: price * qty,
    profitTotal: profit * qty
  }
}

export const getCosts = (order) => {
  let price = 0
  let costs = order.costs || []

  costs.map(cost => {
    price += cost.price
  })

  price = parseFloat(price.toFixed(2))

  return price
}


//



