import React, { Fragment } from 'react'
import { connect } from 'react-redux'


// Components Global
import { LayoutsDesignDisplay } from '../../../../LayoutsDesign'
import InputEditorDisplay from '../../../../Inputs/InputEditorDisplay'
import { DivElement } from '../../../../TextEditor/components/ElementStyles'


const ContentDescriptions = (props) => {
  const { contentGroupId, style, action, templateData, apiData, sites, mode } = props
  const { business, setting } = action.params
  const site = sites[0]
  const editorContentType = style['editorContentType']

  let data

  if (templateData) {
    data = templateData
  }

  let editorType, standardValue

  if (data) {
    if (editorContentType === 'advanced') {
      editorType = 'advanced'

    } else if (editorContentType === 'auto') {
      const editorStandard = data[contentGroupId] || {}
      const { type, value } = editorStandard

      if (type === 'advanced') {
        editorType = 'advanced'
      } else {
        editorType = 'standard'
        standardValue = value && value.local
      }


    } else {
      const editorStandard = data[contentGroupId] || {}

      editorType = 'standard'
      standardValue = editorStandard.local
    }
  }

  return (
    <Fragment>
      { !mode ?
        <Fragment>
          { editorType === 'advanced' && contentGroupId && data &&
            <DivElement name="advanced" style={style}>
              <LayoutsDesignDisplay
                {...props}
                display
                content
                bodyColorNone
                groupId={contentGroupId}
                action={{
                  params: {
                    business,
                    setting,
                    site,
                    data,
                    _id: data._id,
                    templateData,
                    apiData
                  }
                }}
              />
            </DivElement>
          }

          { editorType === 'standard' && contentGroupId &&
            <DivElement name="standard" style={style}>
              <InputEditorDisplay editor value={standardValue} styleJsx={style} />
            </DivElement>
          }
        </Fragment>
      :
        <div className="height-200 line-height-200 bg-efefef center">
          <div>เนื้อหาและรายละเอียด [{contentGroupId}]</div>
        </div>
      }
    </Fragment>
  )
}

const mapStateToProps = (store, props) => {
  return {
    sites: store.sites.data
  }
}

export default connect(mapStateToProps)(ContentDescriptions)
