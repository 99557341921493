import React, { Fragment } from 'react'

const Icon = (props) => {
  let { className, style, children, type, } = props
  style = style || {}

  return (
    <Fragment>
      { type === 'material-icons' &&
        <i className={`icon-jsx material-icons ${className}`} style={style}>
          {children}
        </i>
      }

      { type === 'font-awesome' &&
        <i className={`icon-jsx icon-fa ${className} ${children}`} style={style}></i>
      }

      <style jsx>{`
        .icon-jsx {
          color: ${style.color};
          font-size: ${style.fontSize};
          margin: ${style.margin};
          float: ${style.float} !important;
          display: ${style.display} !important;
        }

        @media (min-width: 1500px) {
          .icon-jsx {
            font-size: ${style.fontSizeVw};
            margin: ${style.marginVw};
          }
        }
      `}</style>
    </Fragment>
  )
}

//<Icon type="material-icons" className="" style="">add</Icon>

export default Icon
