import { checkAppActions } from '../App/AppActions'
import uploadsForS3 from '../../util/uploadsForS3'
import { fetchDatas, fetchData, createData, updateData, removeData } from '../reduxActions'
import { generateTypeName, generateTypeFunctions } from '../reduxTypes'

// Export Constants
const NAME_TYPE = ['BUSINESSES', 'BUSINESS']
const name = "businesses"

// Type Names and Type Functions
export const { ADDS, ADDS_MORE, ADD, UPDATE, REMOVE } = generateTypeName(NAME_TYPE)
export const { adds, adds_more, add, update, remove } = generateTypeFunctions(NAME_TYPE)

// Actions Other
import { adds as addAttributes } from '../Attributes/AttributesActions'
import { adds as addSettings } from '../Settings/SettingsActions'

export const fetchBusinesses = (params={}) => dispatch => {
  return fetchDatas({
    dispatch,
    params,
    api: `${name}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchBusinessesByAdmin = (params={}) => dispatch => {
  return fetchDatas({
    dispatch,
    params,
    api: `${name}-admin`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchBusinessByUsernameAndAttributes = (username, params) => dispatch => {
  return fetchData({
    dispatch,
    params,
    api: `${name}-by-username-and-attributes/${username}`,
    addMultiple: [
      { storeName: 'businesses', add },
      { storeName: 'attributes', add: addAttributes },
    ]
  })
}

export const fetchBusinessStart = (username, params) => dispatch => {
  return fetchData({
    dispatch,
    params,
    api: `${name}-start/${username}`,
    addMultiple: [
      { storeName: 'businesses', add },
      { storeName: 'attributes', add: addAttributes },
      { storeName: 'settings', add: addSettings },
    ]
  })
}

export const fetchBusinessByUsername = (username, params) => dispatch => {
  return fetchData({
    dispatch,
    params,
    api: `${name}-by-username/${username}`,
    add: add
  })
}

export const fetchBusiness = (_id, params) => dispatch => {
  return fetchData({
    dispatch,
    params,
    api: `${name}/${_id}`,
    add: add
  })
}

export const createBusiness = (params) => dispatch => {
  return createData({
    dispatch,
    params,
    api: `${name}/create`,
    add: add
  })
}

export const updateBusiness = (_id, params) => dispatch => {
  return updateData({
    dispatch,
    params,
    api: `${name}/${_id}`,
    update: update,
    updateId: _id
  })
}

export const daleteBusiness = ( _id, params) => dispatch => {
  return removeData({
    dispatch,
    params,
    api: `${name}/${_id}`,
    remove: remove,
    deleteId: _id
  })
}


export const uploadImagesBusiness = (businesses_id, files, prefix, params) => dispatch => {
  checkAppActions(dispatch, params, 'start')
  uploadsForS3(files, prefix, params.progressId, dispatch).then(val => {
    val.map(paths => {
      params.body.paths = paths
      dispatch(updateBusiness(businesses_id, params))
    })
  })
}
