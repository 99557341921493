export const getLang = ({ state, props }) => {
  const business = state.businesses.data[0]
  const { langLocal, langs } = business

  let lang = state.app.lang
  lang = lang.toLowerCase()

  return {
    lang,
    langLocal,
    langs
  }
}
