import { ADD_COUNTRIES, ADD_PROVINCES } from './GeoActions';

// Initial State
const initialState = {
  countries: [],
  provinces: []
};

const CountriesReducer = (state=initialState, action) => {
  switch (action.type) {

    case ADD_COUNTRIES:
      return { ...state, countries: action.countries }

    case ADD_PROVINCES:
      return { ...state, provinces: action.provinces }

    default:
      return state;
  }
}

// Export Reducer
export default CountriesReducer;
