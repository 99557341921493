import OrdersSchema from './modules/Orders/OrdersDBSchema'
import BillsDBSchema from './modules/ฺBills/BillsDBSchema'

const collections = {
  orders: OrdersSchema.indexable,
  bills: BillsDBSchema.indexable,
}

const schemas = [
  { coll: 'orders', validations: OrdersSchema.validations },
  { coll: 'bills', validations: BillsDBSchema.validations },
]

export {
  collections,
  schemas
}
