import React from 'react'

// Components Text Editor
import TextStyle from './TextStyle'

const TextElementManage = (props) => {
  let { name, title, styleHover, styleActive, styleActiveClick } = props

  if (!title) title = "ตัวอักษร"

  return (
    <div>
      <TextStyle {...props} title={title} />

      { styleHover &&
        <TextStyle {...props} event="hover" title={`${title}เมื่อเมาส์ชี้ (hover)`} name={`${name}-hover`} />
      }

      { styleActive &&
        <TextStyle {...props} event="active" title={`${title}เมื่อแอคทีฟ (active)`} name={`${name}-active`} />
      }

      { styleActiveClick &&
        <TextStyle {...props} event="active-click" title={`${title}เมื่อกด (click)`} name={`${name}-active-click`} />
      }
    </div>
  )
}

export default TextElementManage
