import React from 'react'

// Components Global
import ColorPicker from '../../../../ColorPicker'

// Functions
import { addStyle, removeStyle } from '../../../functions/textEditorFunctions'

// css
import cssgl from '../css/styleSettings.css'


const BackgroundColorSetting = (props) => {
  const { _this, label, styleName, textColorName, width, left, right } = props
  const { style } = _this.state
  const background = style[styleName]

  let addTextColor

  if (textColorName) {
    addTextColor = {
      style: textColorName
    }
  }

  const colorChange = (color) => {
    const tool = {
      style: styleName,
      value: color,
      addTextColor,
      removeGradient: true
    }

    addStyle(_this, tool)
  }

  const reset = () => {
    removeStyle(_this, null, {
      styleMultiple: [
        { style: `${styleName}` },
        { style: `${textColorName}` },
        { style: `background-color` }
      ]
    })
  }

  let styleDropdown

  if (right) {
    styleDropdown ={ top: '35px', left: undefined, right: '0' }
  } else {
    styleDropdown ={ top: '35px', left: 0 }
  }

  return (
    <div className={cssgl.section}>
      <table>
        <tbody>
          <tr>
            <td className="pd-0 font-0-7">{label || 'สีพื้นหลัง'}</td>
            <td className="pd-0 font-0-7 text-right">
              <a className={cssgl.btn} onClick={reset}>รีเซ็ท</a>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="mg-top-5">
        <ColorPicker
          advanced
          hiddenColorAuto
          label={label}
          color={background}
          onChange={colorChange}
          style={{ width: width || '100%' }}
          styleDropdown={styleDropdown}
          left={left}
          right={right}
        />
      </div>
    </div>
  )
}

export default BackgroundColorSetting
