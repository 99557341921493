import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import renderRoutes from '../../../../routes/renderRoutes'

// Actions Redux
import { fetchOrder } from '../../../../redux/Orders/OrdersActions'

// Components
import SiteStructure from '../../SiteStructure'
import { fetchBills } from '../../../../redux/Bills/BillsActions'


class OrderContainer extends Component {
  componentDidMount() {
    const { business,  site, auth, match } = this.props
    const { orders_id } = match.params
    const query = {
      authName: auth.user && auth.user._id ? 'user' : 'unauth',
      dbNo: business.dbNo,
      businesses_id: business._id,
      orders_id
    }

    this.props.dispatch(fetchOrder(query))
  }

  render() {
    const { route, params, site, location, order, business, setting, auth } = this.props
    const { salePage } = params

    return (
      <Fragment>
        { salePage ?
          <Fragment>
            <SiteStructure {...this.props} title="รายการสั่งซื้อ" titleLink="/customer/orders" site={site}>
              { order ?
                <Fragment>
                  { renderRoutes({
                    routes: route.routes,
                    extraProps: { site, order, business, auth },
                    location
                  }) }
                </Fragment>
              :
                <h1>404 Not Found</h1>
              }
            </SiteStructure>
          </Fragment>
        :
          <div style={{ backgroundColor: '#f5f5f5', minHeight: '100%' }}>
            { order ?
              <Fragment>
                { renderRoutes({
                  routes: route.routes,
                  extraProps: { site, order, business, setting, auth },
                  location
                }) }
              </Fragment>
            :
              <h1>404 Not Found</h1>
            }
          </div>
        }
      </Fragment>
    )
  }
}

const mapStateToProps = (store, props) => {
  const { match } = props
  const { orders_id } = match.params

  let order

  if (orders_id) {
    order = store.orders.data.filter(order => order._id === orders_id)[0]
  }

  return {
    host: store.host,
    auth: store.auth,
    site: store.sites.data[0],
    business: store.businesses.data[0],
    order
  }
}

export default connect(mapStateToProps)(OrderContainer)
