import { fetchDatas, fetchData, createData, updateData, removeData } from '../reduxActions'
import { generateTypeName, generateTypeFunctions, getTypePath } from '../reduxTypes'

import uploadsForS3 from '../../util/uploadsForS3'
import { checkAppActions } from '../App/AppActions'

// Export Constants
const NAME_TYPE = ['SETTINGS', 'SETTING']
const name = "settings"

// Type Names and Type Functions
export const { ADDS, ADDS_MORE, ADD, UPDATE, REMOVE } = generateTypeName(NAME_TYPE)
export const { adds, adds_more, add, update, remove } = generateTypeFunctions(NAME_TYPE)


// Export Actions
export const fetchSettings = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return fetchDatas({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchSetting = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id, settings_id } = query
  const path = getTypePath(businessType)

  return fetchData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/${settings_id}`,
    add: add
  })
}

export const createSetting = (query, params) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return createData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/create`,
    add: add
  })
}


export const updateSetting = (query, params) => dispatch => {
  const { authName, dbNo, businessType, businesses_id, settings_id, _id } = query
  const path = getTypePath(businessType)

  return updateData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/${_id || settings_id}`,
    update: update,
    updateId: _id || settings_id
  })
}

export const removeSetting = (query, params) => dispatch => {
  const { authName, dbNo, businessType, businesses_id, settings_id, _id } = query
  const path = getTypePath(businessType)

  return removeData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/${_id || settings_id}`,
    remove: remove,
    deleteId: _id || settings_id
  })
}

export const uploadImagesSetting = (query, fileParams, params) => dispatch => {
  const { files, imagePrefix } = fileParams

  checkAppActions(dispatch, params, 'start')
  uploadsForS3(files, imagePrefix, params.progressId, dispatch).then(val => {
    val.map(paths => {
      params.body.paths = paths
      dispatch(updateSetting(query, params))
    })
  })
}
