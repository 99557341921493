import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import TextEditorFormLogin from './TextEditorFormLogin'
import TextEditorFormLoginDisplay from './TextEditorFormLoginDisplay'

let TextEditorFormLogin = lazy(() => import('./TextEditorFormLogin'))
TextEditorFormLogin = suspenseComponent(TextEditorFormLogin)

export {
  TextEditorFormLogin,
  TextEditorFormLoginDisplay
}
