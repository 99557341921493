import React, { Fragment, useMemo } from 'react'
import { connect } from 'react-redux'

// Components Local
import ContentTag from './components/ContentTag'
import BlockStyle from '../components/BlockStyle'

// Components Editor
import LinkDisplay from '../components/LinkDisplay'

// Functions
import { generateClassToStringByGroup } from '../functions/textEditorFunctions'
import { getApi, getApiValue, getDataStore } from '../functions/textEditorEvent'



const TextEditorHeadingOrDescriptionsDisplay = (props) => {
  let { content, apiData, templateData, dataStore, lang, type, mode } = props
  let data

  if (type === 'heading') {
    data = content.heading && JSON.parse(content.heading)
  } else if (type === 'descriptions') {
    data = content.descriptions && JSON.parse(content.descriptions)
  }

  const valueLocal = data && data[`value_local`]
  const value = data && data[`value_${lang}`] || valueLocal
  const style = data && data.style ? JSON.parse(data.style) : {}
  const className = data && data.class ? JSON.parse(data.class) : []
  const link = data && data.link
  const tag = style && style['content-tag']
  const api = getApi(content, data)

  let prefix, suffix

  if (content.api && api) {
    const prefixLocal = api[`prefix_local`]
    const suffixLocal = api[`suffix_local`]

    prefix = api[`prefix_${lang}`] || prefixLocal
    suffix = api[`suffix_${lang}`] || suffixLocal
  }

  const TextComp = (
    <Fragment>
      { content.api ?
        <Fragment>
          { (api && api.key) || (api && api.keyFull) ?
            <Fragment>

              { /* prefix */}
              <Fragment>
                { (apiData || templateData || dataStore) ?
                  <Fragment>
                    { getApiValue({ api, apiData, templateData, dataStore, lang }) &&
                      prefix && prefix
                    }
                  </Fragment>
                :
                  prefix && prefix
                }
              </Fragment>


              { (apiData || templateData || dataStore) ?
                getApiValue({ api, apiData, templateData, dataStore, lang })
              :
                <Fragment>
                  { api.key &&
                    <Fragment>
                      { api.key && `[${api.key}] `}
                      { api.index && `[${api.index}] `}
                      { api.subkey && `[${api.subkey}] `}
                      { `[${api.type || 'string'}]` }
                    </Fragment>
                  }

                  { api.keyFull &&
                    <Fragment>
                      { api.keyFull && `[${api.keyFull.replace('dataAttributes.', '')}] `}
                      {/* `[${api.type || 'string'}]` */}
                    </Fragment>
                  }
                </Fragment>
              }

              { /* suffix */}
              <Fragment>
                { (apiData || templateData || dataStore) ?
                  <Fragment>
                    { getApiValue({ api, apiData, templateData, dataStore, lang }) &&
                      suffix && suffix
                    }
                  </Fragment>
                :
                  suffix && suffix
                }
              </Fragment>

            </Fragment>
          :
            'ไม่สามารถดึงข้อมูลได้'
          }
        </Fragment>
      :
        <Fragment>
          { link ? value :
            <span dangerouslySetInnerHTML={{ __html: value.replace(/\n/g, "<br />") }} />
          }
        </Fragment>
      }
    </Fragment>
  )

  const rerender = () => {
    if (api) {
      return [
        lang,
        apiData && JSON.stringify(apiData),
        templateData && JSON.stringify(templateData),
        dataStore && JSON.stringify(dataStore)]
    } else {
      return [
        lang
      ]
    }
  }


  return (
    <Fragment>
      { useMemo(() =>
        <BlockStyle {...props} style={style} className={className}>
          <ContentTag type={type} style={style} tag={tag}>
            <div className={`${generateClassToStringByGroup(className)}`}>
              { link ?
                <LinkDisplay
                  value={link}
                  mode={mode}
                  apiData={apiData}
                  templateData={templateData}>

                  { TextComp }
                </LinkDisplay>
              :
                TextComp
              }
            </div>
          </ContentTag>
        </BlockStyle>
      , rerender()) }
    </Fragment>
  )
}


const mapStateToProps = (store, props) => {
  return {
    dataStore: getDataStore({ store, props })
  }
}

export default connect(mapStateToProps)(TextEditorHeadingOrDescriptionsDisplay)
