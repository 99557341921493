export const getLazyLoadParams = (props) => {
  let overflow, offset = 200

  if (props.match && props.match.params.routeType) {
    overflow = true
    offset = 800
  }

  return {
    offset,
    overflow
  }
}
