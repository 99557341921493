import React from 'react'
import { connect } from 'react-redux'

// Components Local
import { FormLoginDisplay } from './components/FormLogin'


const TextEditorFormLoginDisplay = (props) => {
  const { content, templateData, match, action } = props
  const { business } = action.params
  const formLogin = content.formLogin && JSON.parse(content.formLogin)
  const style = formLogin && formLogin.style ? JSON.parse(formLogin.style) : {}
  const id = 'form-login-' + content._id

  const { username, sites_id } = match && match.params || {}
  const modeManage = (username && sites_id)

  return (
    <FormLoginDisplay
      id={id}
      product={templateData}
      style={style}
      modeManage={modeManage}
      business={business}
    />
  )
}

export default connect()(TextEditorFormLoginDisplay)
