import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import TextEditorColumns from './TextEditorColumns'
import TextEditorColumnsDisplay from './TextEditorColumnsDisplay'

let TextEditorColumns = lazy(() => import('./TextEditorColumns'))
TextEditorColumns = suspenseComponent(TextEditorColumns)

export {
  TextEditorColumns,
  TextEditorColumnsDisplay
}
