import React, { Fragment } from 'react'

// Components Global
import { LayoutsDesignDisplay } from '../../../../../../LayoutsDesign'


const TabContent = (props) => {
  const { itemSeleted } = props

  return (
    <Fragment>
      { itemSeleted &&
        <div id={itemSeleted.id} style={{ minHeight: '100%' }}>
          <LayoutsDesignDisplay
            {...props}
            display
            groupId={itemSeleted.id}
          />
        </div>
      }
    </Fragment>
  )
}

export default TabContent
