import React from 'react'
import config from '../../../../../config'

// Components Editor
import { EditorImage }  from '../../EditorImage'
import BackgroundPositionSetting from '../BackgroundPositionSetting'
import SelectStyleSettings from '../SelectStyleSettings'
import OpacitySetting from '../OpacitySetting'

// Functions
import { addStyle, removeStyle } from '../../../functions/textEditorFunctions'

// css
import cssgl from '../css/styleSettings.css'
import ApiSettings from '../../ApiSettings'
import SwitchStyleSettings from '../SwitchStyleSettings'


const s3Url = config.app.s3.url
const s3UrlOriginal = config.app.s3.urlOriginal


const BackgroundImageSetting = (props) => {
  const { _this, label, styleName, textColorName, width, left, right } = props
  const { style } = _this.state
  const image = style[`${styleName}-paths`]
  const paths = image && JSON.parse(image)

  const imageChange = ({ index, value, timeout }) => {
    const paths = JSON.parse(value.paths)
    const url = `url(${s3Url}/${paths.o})`

    let webp

    if (paths.o_webp) {
      webp = `url(${s3Url}/${paths.o_webp})`
    }

    addStyle(_this, {
      styleMultiple: [
        { style: `${styleName}`, value: url },
        { style: `${styleName}-webp`, value: webp },
        { style: `${styleName}-paths`, value: JSON.stringify(value) }
      ]
    })

    if (props.onChange) {
      props.onChange(paths)
    }
  }

  const reset = () => {
    removeStyle(_this, null, {
      styleMultiple: [
        { style: `${styleName}` },
        { style: `${styleName}-webp` },
        { style: `${styleName}-paths` },
      ]
    })
  }

  return (
    <div className={cssgl.section}>

      { !style['api'] &&
        <div className="pd-10">
          <table>
            <tbody>
              <tr>
                <td className="pd-0 font-0-7">{label}</td>
                <td className="pd-0 font-0-7 text-right">
                  <a className={cssgl.btn} onClick={reset}>รีเซ็ท</a>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="mg-top-5">
            { (paths && paths.o) ?
              <div className="mg-center">
                <EditorImage
                  {..._this.props}
                  notDelete
                  notActiveDelete
                  image={image}
                  type="image-thumbnail"
                  style={{ width: '100%', height: 'auto' }}
                  onChange={imageChange}
                />
              </div>
            :
              <div>
                <EditorImage
                  {..._this.props}
                  notDelete
                  notActiveDelete
                  image={image}
                  type="image-thumbnail"
                  style={{ width: '100%', height: 'auto' }}
                  onChange={imageChange}
                />
              </div>
            }
          </div>
        </div>
      }

      <div className="pd-top-10">
        <div className="pd-10 bg-fbfbfb">
          <OpacitySetting
            _this={_this}
            styleName={`${styleName}-opacity`}
          />
        </div>

        <div className="pd-10">
          <BackgroundPositionSetting
            label="background position x"
            positionType="x"
            _this={_this}
            styleName={`${styleName}-position-x`}
          />
        </div>
        <div className="pd-10 bg-fbfbfb">
          <BackgroundPositionSetting
            label="background position y"
            positionType="y"
            _this={_this}
            styleName={`${styleName}-position-y`}
          />
        </div>
        <div className="pd-10">
          <SelectStyleSettings
            _this={_this}
            label="background size"
            styleName={`${styleName}-size`}
            options={[
              { label: 'เลือก', value: '' },
              { label: 'auto', value: 'auto' },
              { label: 'length', value: 'length' },
              { label: 'cover', value: 'cover' },
              { label: 'contain', value: 'contain' },
              { label: 'initial', value: 'initial' },
              { label: 'inherit', value: 'inherit' }
            ]}
          />
        </div>
        <div className="pd-10 bg-fbfbfb">
          <SelectStyleSettings
            _this={_this}
            label="background repeat"
            styleName={`${styleName}-repeat`}
            options={[
              { label: 'เลือก', value: '' },
              { label: 'repeat', value: 'repeat' },
              { label: 'repeat-x', value: 'repeat-x' },
              { label: 'repeat-y', value: 'repeat-y' },
              { label: 'no-repeat', value: 'no-repeat' },
              { label: 'initial', value: 'initial' },
              { label: 'inherit', value: 'inherit' }
            ]}
          />
        </div>
        <div className="pd-10">
          <SelectStyleSettings
            _this={_this}
            label="background attachment"
            styleName={`${styleName}-attachment`}
            options={[
              { label: 'เลือก', value: '' },
              { label: 'scroll', value: 'scroll' },
              { label: 'fixed', value: 'fixed' },
              { label: 'local', value: 'local' },
              { label: 'initial', value: 'initial' },
              { label: 'inherit', value: 'inherit' }
            ]}
          />
        </div>

        <div className="border-left-1 border-right-1 border-left-eee border-right-eee">
          <div className="border-top-1 border-top-eee">
            <SwitchStyleSettings
              _this={_this}
              label="เปิดใช้งาน API"
              styleName="api"
            />
          </div>

          { style['api'] &&
            <ApiSettings
              _this={_this}
              contectType={props.contectType}
              type="image"
              onChange={props.apiChange}
            />
          }
        </div>
      </div>
    </div>
  )
}

export default BackgroundImageSetting
