import React from 'react'
import { connect } from 'react-redux'

// Components Local
import { SectionsListDisplay  } from './SectionsList'

// JS
import { getDataStore } from '../TextEditor/functions/textEditorEvent'


const LayoutsDesignDisplay = (props) => {
  return (
    <SectionsListDisplay {...props} />
  )
}

const mapStateToProps = (store, props) => {
  const dataStore = getDataStore({ store })

  return {
    dataStore
  }
}

export default connect(mapStateToProps)(LayoutsDesignDisplay)

