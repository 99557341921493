import React, { Component } from 'react'

// Functions
import { addStyle, removeStyle } from '../../../functions/textEditorFunctions'

// css
import css from '../css/styleSettings.css'


class PositionValueSetting extends Component {
  constructor(props) {
    super(props)
    this.state = {
      top: undefined,
      right: undefined,
      bottom: undefined,
      left: undefined
    }
  }

  componentDidMount() {
    const { _this } = this.props
    const { style } = _this.state
    const styleName = this.props.styleName || 'position'
    const top = style[`${styleName}-top`]
    const bottom = style[`${styleName}-bottom`]
    const right = style[`${styleName}-right`]
    const left = style[`${styleName}-left`]

    this.setValue({
      top,
      bottom,
      right,
      left
    })
  }

  setValue = ({ top, bottom, right, left }) => {
    top = top && parseInt(top)
    right = right && parseInt(right)
    bottom = bottom && parseInt(bottom)
    left = left && parseInt(left)

    this.setState({ top, right, bottom, left })
  }

  handleChange = (e, position) => {
    let value = parseInt(e.target.value)

    if (isNaN(value) || value === undefined || value === '') {
      value = undefined
    }

    let absolute = {
      [position]: value
    }

    this.setState(absolute, () => {
      this.addValue()
    })
  }

  addValue = () => {
    const { top, bottom, right, left } = this.state
    const { _this } = this.props
    const styleName = this.props.styleName || 'position'

    const topPx = top && `${top}px`
    const topVw = top && `${top/15}vw`

    const bottomPx = bottom && `${bottom}px`
    const bottomVw = bottom && `${bottom/15}vw`

    const rightPx = right && `${right}px`
    const rightVw = right && `${right/15}vw`

    const leftPx = left && `${left}px`
    const leftVw = left && `${left/15}vw`

    addStyle(_this, {
      styleMultiple: [
        { style: `${styleName}-top`, value: topPx },
        { style: `${styleName}-top-vw`, value: topVw},
        { style: `${styleName}-bottom`, value: bottomPx},
        { style: `${styleName}-bottom-vw`, value: bottomVw},
        { style: `${styleName}-right`, value: rightPx},
        { style: `${styleName}-right-vw`, value: rightVw},
        { style: `${styleName}-left`, value: leftPx},
        { style: `${styleName}-left-vw`, value: leftVw}
      ]
    })
  }

  reset = () => {
    const { _this } = this.props
    const styleName = this.props.styleName || 'position'

    this.setState({ top: undefined, right: undefined, bottom: undefined, left: undefined }, () => {
      removeStyle(_this, null, {
        styleMultiple: [
          { style: `${styleName}-top`},
          { style: `${styleName}-top-vw`},
          { style: `${styleName}-bottom`},
          { style: `${styleName}-bottom-vw`},
          { style: `${styleName}-right`},
          { style: `${styleName}-right-vw`},
          { style: `${styleName}-left`},
          { style: `${styleName}-left-vw`}
        ]
      })
    })
  }

  render() {
    const { top, right, bottom, left } = this.state

    return (
      <div className={css.paddingForm}>
        <table>
          <tbody>
            <tr>
              <td className="pd-0 font-0-8">ตำแหน่ง</td>
              <td className="pd-0 font-0-7 text-right">
                <a className={css.btn} onClick={this.reset}>รีเซ๊ท</a>
              </td>
            </tr>
          </tbody>
        </table>

        <div>
          <div className={css.boxInput}>
            <table>
              <tbody>
                <tr>
                  <td width="25%" className="pd-0 font-0-8 center">
                    <input
                      value={top}
                      onChange={(e) => this.handleChange(e, 'top')}
                      className={`${css.input} ${css.inputBorderNone} ${css.inputFirst}`}
                      type="text"
                    />
                  </td>
                  <td width="25%" className="pd-0 font-0-8 center">
                    <input
                      value={left}
                      onChange={(e) => this.handleChange(e, 'left')}
                      className={`${css.input} ${css.inputBorder}`}
                      type="text"
                    />
                  </td>
                  <td width="25%" className="pd-0 font-0-8 center">
                    <input
                      value={bottom}
                      onChange={(e) => this.handleChange(e, 'bottom')}
                      className={`${css.input} ${css.inputBorder}`}
                      type="text"
                    />
                  </td>
                  <td width="25%" className="pd-0 font-0-8 center">
                    <input
                      value={right}
                      onChange={(e) => this.handleChange(e, 'right')}
                      className={`${css.input} ${css.inputBorder} ${css.inputLast}`}
                      type="text"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <table>
            <tbody>
              <tr>
                <td width="25%" className="pd-0 font-0-7 center">บน</td>
                <td width="25%" className="pd-0 font-0-7 center">ซ้าย</td>
                <td width="25%" className="pd-0 font-0-7 center">ล่าง</td>
                <td width="25%" className="pd-0 font-0-7 center">ขวา</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default PositionValueSetting
