import React from 'react'

// Components Global
import Switch from '../../../Inputs/Switch'

// Components Local
import BoxSettings from '../BoxSettings'

// Components Functions
import {
  addClass,
  classSelected
} from '../../functions/textEditorFunctions'


const ResponsiveBlockManage = (props) => {
  const { _this, device } = props
  const classNames = _this.state.class || []

  const onChange = (e, name) => {
    addClass(_this, {
      className: name,
      classValue: name,
      unselect: true
    })
  }

  return (
    <div>
      <BoxSettings title={`การแสดงผลบนอุปกรณ์ (Responsive)`} icon="devices_other">
        { device === 'desktop' &&
          <div className="pd-15 pd-right-0">
            <DevicesSetting label="ซ่อนบนเดสก์ท็อป" value={classNames} name="hidden-desktop" onChange={onChange} />
          </div>
        }

        { device === 'tablet' &&
          <div className="pd-15 bg-fbfbfb pd-right-0">
            <DevicesSetting label="ซ่อนบนแท็บเล็ต" value={classNames} name="hidden-tablet" onChange={onChange} />
          </div>
        }

        { device === 'mobile' &&
          <div className="pd-15 pd-right-0">
            <DevicesSetting label="ซ่อนบนมือถือ" value={classNames} name="hidden-mobile" onChange={onChange} />
          </div>
        }
      </BoxSettings>
    </div>
  )
}


const DevicesSetting = (props) => {
  const { label, value, name, onChange } = props
  const selected = classSelected(name, value, 'main')

  return (
    <table>
      <tbody>
        <tr>
          <td className="pd-0 font-0-8">{label}</td>
          <td width="70" className="pd-0 text-right">
            <Switch
              notSave
              id={name}
              value={selected ? true : false}
              onChange={(e) => onChange(e, name)}
              labelHidden
              style={{ height: '22px', marginTop: '-12px' }}
            />
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default ResponsiveBlockManage
