import React from 'react'

// Components Global
import LoadImage from '../Images/LoadImage'
import Avatar from '../Images/Avatar'

const ProfileAvatar = (props) => {
  let images = props.images || []
  let image = images.filter(image => image.album === 'profile')[0]
  let pathFile

  return (
    <div style={{ width: '100%', height: '100%', lineHeight: '0' }}>
      { image ?
        <LoadImage
          s3Url
          circle={props.circle}
          className={props.className}
          imgStyle={props.imgStyle}
          src={ image.paths && image.paths[props.imageSize] && image.paths[props.imageSize] }
        />
      :
        <Avatar
          className={props.className}
          fontClassName={props.fontClassName || 'font-1-1'}
          color={props.color}
          texts={props.texts}
          textSize={props.textSize}
          circle={props.circle}
          styleJsx={props.styleJsx}
        />
      }
    </div>
  )
}

export default ProfileAvatar
