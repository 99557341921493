// Values
import { getProductParams, productValue } from '../../../../../../redux/Products/ProductsValues'

// functions
import { getPageTemplate } from '../../../functions/siteFetchDatas'



export const useTemplate = (props) => {
  const { pages, product, article, folder, tag, match } = props
  const { path_1, pageType, _id, folders_path, tags_path } = match.params
  const pageIndex = pages.filter(page => page.groupPageType === 'main' && page.path === 'index')[0]
  const pageProfileIndex = pages.filter(page => page.groupPageType === 'profile' && page.path === 'profile-index')[0]

  let pageTemplate

  // page narmal
  if (pageType) {
    if (pageType === 'product-details') {
      pageTemplate = getPageTemplate(props, { data: product, templateType: pageType })

    } else if (pageType === 'article-details') {
      pageTemplate = getPageTemplate(props, { data: article, templateType: pageType })

    } else if (pageType === 'product-categories') {
      pageTemplate = getPageTemplate(props, { data: folder, templateType: 'product-folder-details' })

    } else if (pageType === 'article-categories') {
      pageTemplate = getPageTemplate(props, { data: folder, templateType: 'article-folder-details' })

    } else if (pageType === 'product-tags') {
      pageTemplate = getPageTemplate(props, { data: tag, templateType: 'product-tag-details' })

    } else if (pageType === 'article-tags') {
      pageTemplate = getPageTemplate(props, { data: tag, templateType: 'article-tag-details' })
    }

  } else {
    if (path_1) {
      if (match.path === '/profile/:path_1?') {
        pageTemplate = {
          page: pages.filter(page => page.groupPageType === 'profile' && page.path === path_1)[0],
          template: false,
          templateData: undefined
        }

      } else {
        const page = pages.filter(page => page.groupPageType === 'main' && page.path === path_1)[0]
        let templateData

        if (page && page.fetchType && page.fetchId) {
          if (page.fetchType === 'product-details') {
            const params = getProductParams(_id)
            const items_id = params.products_items_id
            const product = props.products.filter(product => product._id === page.fetchId)[0]
            templateData = productValue({ product, items_id })
          }
        }

        pageTemplate = {
          page,
          template: false,
          templateData
        }
      }

    // page index
    } else {
      if (match.url === '/profile' || match.url === '/profile/') {
        pageTemplate = {
          page: pageProfileIndex,
          template: false,
          templateData: undefined
        }
      } else {
        pageTemplate = {
          page: pageIndex,
          template: false,
          templateData: undefined
        }
      }
    }
  }

  return pageTemplate
}

