import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Global
import InputFiles from '../input/InputFiles'
import InputImageCrop from '../input/InputImageCrop'
import ImagePreload from '../ImagePreload'

// css
import css from './css/dragDropFiles.css'

// Redux Actions
import { addFileProgress, removeFileProgress } from '../../redux/Progresses/ProgressesActions'

class DragDropFiles extends Component {
  constructor(props) {
    super(props)
    this.state = {
      styleBox: {},
      quality: 0.5
    }
  }

  mouseHover = () => {
    this.setState({
      styleBox: { borderColor: '#333' }
    })
  }

  mouseOut = () => {
    this.setState({
      styleBox: {}
    })
  }

  getFiles = (files, imagesUrl, base64=false) => {
    this.props.dispatch(addFileProgress(this.props.id, {
      url: imagesUrl,
      data: files,
      base64: base64,
      progress: null,
      status: null
    }))
  }

  handleClickRemove = (index) => {
    this.props.dispatch(removeFileProgress(this.props.id, index))
  }

  getFileSize = (bytes) => {
    let kb = bytes / 1024
    let mb = kb / 1024

    if (bytes < 1024) return `${bytes} B`
    if (kb < 1024) return `${kb.toFixed(1)} KB`
    if (kb >= 1024) return `${mb.toFixed(1)} MB`
  }

  qualityChange = (e) => {
    this.setState({ quality: parseFloat(e.target.value) })
  }

  render() {
    const files = this.props.progress.files
    const input = (
      <div
        style={this.state.styleBox}
        className={css.box}
        onMouseOver={this.mouseHover}
        onMouseOut={this.mouseOut}
        onDragOver={this.mouseHover}
        onDragLeave={this.mouseOut}>

        { this.props.crop ?
          <InputImageCrop
            id={`crop-${this.props.id}`}
            mainId={`${this.props.id}`}
            crop={this.props.crop}
            name={this.props.name}
            imageUrl={this.props.imageUrl}
            quality={this.props.upload.quality || this.state.quality}
            style={{ width: '100%' }}
            getFiles={this.getFiles}>

            { this.props.imageUrl ?
              <div className="height-100 line-height-100 text-center">
                <span className="font-1-2">กำลังโหลดรูป...</span>
              </div>
            :
              <div className={css.boxUpload}>
                <div>
                  <i className="material-icons mg-bottom-15">cloud_upload</i>
                  <span>ลากไฟล์รูปภาพมาวางที่นี้</span>
                  { this.props.upload.quality  && <div className="font-0-6 pd-top-10">ปรับคุณภาพระดับ {this.props.upload.quality}</div> }
                  <a className="btn mg-top-15 pd-right-15"><i className={`material-icons left`}>add</i>เพิ่มรูปภาพ</a>
                </div>
              </div>
            }
          </InputImageCrop>
        :
          <InputFiles
            files={this.props.progress.files.filter(file => file.status === null)}
            name={this.props.name}
            getFiles={this.getFiles}
            getImagesUrl={this.getImagesUrl}
            required={this.props.required}
            multiple={this.props.multiple}
            maxFile={this.props.maxFile}
            quality={this.props.upload.quality  || this.state.quality}
            style={{ width: '100%' }}>

            <div className={css.boxUpload}>
              <div>
                <i className="material-icons mg-bottom-15">cloud_upload</i>
                <span>ลากไฟล์รูปภาพมาวางที่นี้</span>
                { this.props.upload.quality  && <div className="font-0-6 pd-top-10">ปรับระดับคุณภาพ {this.props.upload.quality}</div> }
                <a className="btn mg-top-15 pd-right-15"><i className={`material-icons left`}>add</i>เพิ่มรูปภาพ</a>
              </div>
            </div>

          </InputFiles>
        }
      </div>
    )

    const options = [
      { value: 0.5 },
      { value: 0.6 },
      { value: 0.7 },
      { value: 0.8 },
      { value: 0.9 },
      { value: 1 },
    ]

    return (
      <div>
        <div className="mg-bottom-10">
          <table>
            <tbody>
              <tr>
                <td className="pd-0 vertical-bottom">ความคมชัด</td>
                <td className="pd-0 width-80 text-right">
                  { this.props.upload.quality ?
                    this.props.quality
                  :
                    <div className="center width-100 pd-top-10 font-0-8">
                      <select
                        className={`browser-default ${css.input} ${css.select}`}
                        onChange={this.qualityChange}
                        value={this.state.quality}>

                        { options.map(option => {
                          return <option key={option.value} value={option.value}>{option.value}</option>
                        }) }
                      </select>
                    </div>
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        { this.props.multiple && files.length < this.props.maxFile && input }
        { !this.props.multiple && !this.props.crop && files.length < 1 && input }
        { this.props.crop && files.length < 1 && input }

        <div>
          { files.map((file, index) => {
            return (
              <div key={index} className={css.filesList}>
                <div className={css.boxImg} >
                  <ImagePreload
                    src={file.url}
                    width="70"
                    height="70"
                    preWidth="50px"
                    preHeight="50px"
                    prePadding="10px"
                  />
                </div>
                <div className={css.boxContent}>
                  <span className={css.imgName}>รูปภาพที่ {index + 1 }</span>

                  { file.status === null &&
                    <a onClick={() => this.handleClickRemove(index) } className={`btn-floating btn-flat ${css.imgRemove}`}>
                      <i className={`material-icons i-middle`}>close</i>
                    </a>
                  }

                  { file.status === 'success' &&
                    <div className={css.iconSuccess}><i className={`material-icons i-middle`}>check_circle</i></div>
                  }

                  <div className={`progress ${css.progress}`}>
                    <div className="determinate" style={{ width: `${file.progress}%`, backgroundColor: '#7cb342' }}></div>
                  </div>
                  <span className={css.imgSize}>ขนาด <span style={{ fontSize: '0.8rem' }}>{ this.getFileSize(file.data.size) }</span></span>
                </div>
              </div>
            )
          }) }
        </div>
      </div>
    )
  }
}

export default connect()(DragDropFiles)

