import React from 'react'

// Components Global
import Icon from '../../../../../../Icon'

// css
import css from './css/tabLists.css'

// Js
import { setStyle, setStyleResponsive } from '../../../../../components/ElementStyles/components/js/setDivStyle'

// Functions
import { generateClassToStringByGroup } from '../../../../../functions/textEditorFunctions'


const TabLists = (props) => {
  const lang = props.lang || 'local'
  const { items, className, style, setActiveId, activeId } = props
  const model = style['tab-menu-position'] || 'top-left'

  let cssTabs

  if (model === 'top-left') {
    cssTabs = css.tabsTopLeft
  } else if (model === 'top-center') {
    cssTabs = css.tabsTopCenter
  } else if (model === 'top-right') {
    cssTabs = css.tabsTopRight
  } else if (model === 'top-full') {
    cssTabs = css.tabsTopFull
  } else if (model === 'left') {
    cssTabs = css.tabsLeft
  } else if (model === 'right') {
    cssTabs = css.tabsRight
  }

  return (
    <div className={`${cssTabs} tabs-jsx`}>

      { items.map((item, i) => {
        item = JSON.parse(item)

        const name = item && item[`name_${lang}`] || 'ยังไม่ระบุชื่อ...'
        const icon = item && item.icon && JSON.parse(item.icon)
        const iconType = icon && icon.type
        const iconName = icon && icon.name

        return (
          <div
            key={i}
            onClick={ () => setActiveId(item.id) }
            className={`${css.item} item-jsx ${activeId === item.id && 'active-jsx'} ${generateClassToStringByGroup(className, 'item')}`}>

            { iconType && iconName &&
              <Icon type={iconType} className="left i-middle mg-left--5">
                { iconName }
              </Icon>
            }

            { name }
          </div>
        )
      }) }

      <style jsx>{`
        .tabs-jsx {
          ${setStyle('tabs', style)}
        }
        .tabs-jsx .item-jsx {
          ${setStyle('item', style)}
        }
        .tabs-jsx .item-jsx:hover {
          ${setStyle(`item-hover`, style)}
        }
        .tabs-jsx .item-jsx:active {
          ${setStyle(`item-active-click`, style)}
        }
        .tabs-jsx .active-jsx {
          ${setStyle(`item-active`, style, 'active')}
        }
        .tabs-jsx .item-jsx-xxxx :global(i.material-icons) {
          font-size: ${style[`item-icon-size`]};
          line-height: ${style[`item-line-height`]};
        }

        @media (min-width: 1500px) {
          .tabs-jsx {
            ${setStyleResponsive('tabs', style)}
          }
          .tabs-jsx .item-jsx {
            ${setStyleResponsive('item', style)}
          }
          .tabs-jsx .item-jsx:hover {
            ${setStyleResponsive(`item-hover`, style)}
          }
          .tabs-jsx .item-jsx:active {
            ${setStyleResponsive(`item-active-click`, style)}
          }
          .tabs-jsx .active-jsx {
            ${setStyleResponsive(`item-active`, style, 'active')}
          }

          .tabs-jsx .item-jsx-xxxx :global(i.material-icons) {
            font-size: ${style[`item-icon-size-vw`]};
            line-height: ${style[`item-line-height-vw`]};
          }
        }
      `}</style>
    </div>
  )
}

export default TabLists
