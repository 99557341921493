import React, { Component } from 'react'
import config from '../../../config'

// css
import css from './css/textEditorVideo.css'

// imgs
import playButton from './img/play-button.svg'

// js
import { generateUrlVideo } from './js/textEditorVideo'

const s3Url = config.app.s3.url

class TextEditorVideoDisplay extends Component {
  constructor(props) {
    super(props)
    this.state = {
      play: false
    }
  }

  componentDidMount() {
    this.setValue()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.content.video !== this.props.content.video) {
      this.setValue()
    }
  }

  getValue = () => {
    const content = this.props.content
    const video = content.video && JSON.parse(content.video)

    return {
      type: video && video.type,
      url: video && video.url,
      autoplay: video && video.autoplay,
      rel: video && video.rel,
      modestbranding: video && video.modestbranding,
      controls: video && video.controls,
      mute: video && video.mute,
      fs: video && video.fs,
      image: video && video.image ? JSON.parse(video.image) : '',
      style: video && video.style ? JSON.parse(video.style) : {},
      class: video && video.class ? JSON.parse(video.class) : [],
    }
  }

  setValue = () => {
    this.setState({
      ...this.getValue()
    })
  }

  handlePlayVideo = () => {
    setTimeout(() => {
      this.setState({
        play: true,
        openTools: true
      })
    }, 0)
  }

  render() {
    const { image, play, autoplay } = this.state
    const paths = image && image.paths && JSON.parse(image.paths)
    const urlVideo = generateUrlVideo(this.state)

    return (
      <div>
        { urlVideo ?
          <div className={css.videoWrapper}>
            { paths &&
              <div
                className={css.cover}
                onClick={this.handlePlayVideo}
                style={{
                  backgroundImage: `url(${s3Url}/${paths.l})`,
                  visibility: (play || autoplay === 1) ? 'hidden' : 'visible',
                  opacity: (play || autoplay === 1) ? '0' : '1',
                }}>

                <img src={playButton} width="70px" />
              </div>
            }

            <iframe
              width="100%"
              height="100%"
              src={urlVideo}
              frameBorder="0"
              allowFullScreen
            />
          </div>
        :
          <div>
            { this.state.url ?
              <div className="box-middle height-100 border-error center">
                <div className="font-1-0 color-error">ลิงก์วิดีโอ Youtube ไม่ถูกต้อง</div>
              </div>
            :
              <div className="box-middle height-100 border-eee center">
                <div className="font-1-0">กรุณาเพิ่มลิงก์วิดีโอ Youtube</div>
              </div>
            }
          </div>
        }
      </div>
    )
  }
}

export default TextEditorVideoDisplay
