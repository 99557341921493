import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import TextEditorSideNavbar from './TextEditorSideNavbar'
import TextEditorSideNavbarDisplay from './TextEditorSideNavbarDisplay'

let TextEditorSideNavbar = lazy(() => import('./TextEditorSideNavbar'))
TextEditorSideNavbar = suspenseComponent(TextEditorSideNavbar)

export {
  TextEditorSideNavbar,
  TextEditorSideNavbarDisplay
}
