export const getListNumer = (_this) => {
  let searchData = _this.state.searchData
  let showPage = _this.state.showPage
  let currentPage = getCurrentPage(_this)
  let totalPage = getTotalPage(_this)

  // จำนวนหน้าที่แสดง >= หน้าทั้งหมด (ขวา)
  if (showPage >= totalPage) {
    _this.setState({ lastMore: false, firstMore: false, startNumber: 2, endNumber: totalPage - 1 })

    // จำนวนหน้าที่แสดง < หน้าทั้งหมด
  } else {

    // หน้าปัจจุบัน > จำนวนที่แสดง (ขวา)
    if (currentPage < showPage) {
      _this.setState({ lastMore: true, firstMore: false, startNumber: 2, endNumber: showPage })

      // หน้าปัจจุบัน > จำนวนที่แสดง
    } else {

      // (ซ้าย)
      if (currentPage + 3 >= totalPage) {

        _this.setState({ lastMore: false, firstMore: true, startNumber: totalPage - showPage + 1, endNumber: totalPage - 1 })

        // (กลาง)
      } else {
        let num = Math.floor((showPage - 2) / 2)

        _this.setState({ lastMore: true, firstMore: true, startNumber: currentPage - num, endNumber: currentPage + num })
      }
    }
  }
}



export const showPagination = (_this) => {
  let searchData = _this.state.searchData
  let totalPage = getTotalPage(_this)
  let showPagination

  if (totalPage > 1) {
    if (searchData && searchData.status) {
      if (searchData.data.length > 0 && !_this.props.preLoading) showPagination = true
      else showPagination = false
    } else {
      if (_this.state.totalItems > 0 && !_this.props.preLoading) showPagination = true
      else showPagination = false
    }
  } else {
    showPagination = false
  }

  return showPagination
}



export const getTotalPage = (_this) => {
  let searchData = _this.state.searchData
  let totalPage

  // for search
  if (searchData && searchData.status) {
    totalPage = _this.state.totalPageSearch || 0
  } else {
    totalPage = _this.state.totalPage
  }

  return totalPage
}




export const getCurrentPage = (_this) => {
  let searchData = _this.state.searchData
  let currentPage

  // for search
  if (searchData && searchData.status) {
    currentPage = _this.state.currentPageSearch || 1
  } else {
    currentPage = _this.state.currentPage
  }

  return currentPage
}



export const getPageNumber = (_this) => {
  let totalPage = getTotalPage(_this)
  let numberArray = Array.apply(1, Array(totalPage))
  let listNumber = []

  numberArray.map((button, i) => {
    let number = i+1

    if (number >= _this.state.startNumber && number <= _this.state.endNumber && number < totalPage) {
      listNumber.push(_this.pageNumberComponent(number))
    }
  })

  return listNumber
}