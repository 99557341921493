import React, { Component, Fragment } from 'react'
import ReactDOM from 'react-dom'
import css from './css/dropdown.css'

// Components Global
import { LayoutsDesignDisplay } from '../../../../LayoutsDesign'

// Components Global
import { LayoutsDesign } from '../../../../LayoutsDesign'

// Functions
import { getSections } from '../../../../../redux/Sites/SitesFunctions'


class DropdownContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      openTransition: false
    }
  }

  componentDidMount() {
    this.mounted = true
    document.addEventListener('mouseover', this.handleDocumentHover)
    document.addEventListener("scroll", this.handleDocumentScroll)

    setTimeout(() => {
      this.getPosition()
    }, 0)
  }

  handleDocumentScroll = () => {
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
      this.closeDropdown()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({ open: this.props.open })
    }
  }

  componentWillUnmount() {
    this.mounted = false
    document.removeEventListener('mouseover', this.handleDocumentHover)
    document.removeEventListener('scroll', this.handleDocumentScroll)
  }

  handleDocumentHover = (e) => {
    if (this.state.open) {
      if (!ReactDOM.findDOMNode(this).contains(e.target)) {
        this.closeDropdown()
      }
    }
  }

  getPosition = () => {
    const domNode = ReactDOM.findDOMNode(this.content)
    const value = domNode && domNode.getBoundingClientRect()

    if (value && this.mounted) {
      this.setState({ top: value.top, left: value.left })
    }
  }

  openDropdown = () => {
    if (!this.state.open) {
      this.getPosition()
      this.setState({ open: true }, () => {
        setTimeout(() => {
          this.setState({ openTransition: true })
        }, 100)
      })
    }
  }

  closeDropdown = () => {
    this.setState({ openTransition: false }, () => {
      setTimeout(() => {
        this.mounted && this.setState({ open: false })
      }, 300)
    })
  }

  render() {
    const styleContent = this.props.styleContent || {}
    /*const styleComp = {
      ...styleContent,
      visibility: this.state.openTransition ? 'visible' :'hidden',
      opacity: this.state.openTransition ? '1' :'0'
    }*/

    const styleComp = {
      visibility: this.state.openTransition ? 'visible' :'hidden',
      opacity: this.state.openTransition ? '1' :'0',
      top: this.state.top,
      left: this.state.left
    }

    const { item } = this.props
    const sections = item && getSections(this.props, { byGroupId: item.id })
    const position = 'bottom-left' //style['dropdown-position']


    let positionCss = css.bottomRight

    if (position === 'top-left') {
      positionCss = css.topLeft
    } else if (position === 'top-right') {
      positionCss = css.topRight
    } else if (position === 'bottom-left') {
      positionCss = css.bottomLeft
    } else if (position === 'bottom-right') {
      positionCss = css.bottomRight
    }

    return (
      <div className={`${css.boxDropdown}`}>
        <div
          className={`${css.boxClick}`}
          onMouseEnter={this.openDropdown}
          onClick={this.openDropdown}>

          { this.props.listComp}<i className="material-icons mg-left-0" style={{ float: 'right' }}>arrow_drop_down</i>
        </div>

        <div ref={elm => this.content = elm}>
          { (position === 'bottom-left' || position === 'bottom-right') &&
            <Fragment>

              { this.state.open !== false &&
                <div className={`${css.content} ${positionCss}`} style={styleComp}>
                  <LayoutsDesignDisplay
                    {...this.props}
                    sections={sections}
                    display
                    groupId={item.id}
                  />
                </div>
              }
            </Fragment>
          }
        </div>
      </div>
    )
  }
}

export default DropdownContent
