import React from 'react'
import { connect } from 'react-redux'

// Components Editor
import BlockStyle from '../components/BlockStyle'

// Components Local
import ContentDescriptions from './components/ContentDescriptions'



const TextEditorContentDescriptionsDisplay = (props) => {
  const { content } = props
  const contentDescriptions = content.contentDescriptions && JSON.parse(content.contentDescriptions)
  const style = contentDescriptions && contentDescriptions.style ? JSON.parse(contentDescriptions.style) : {}
  const className = contentDescriptions && contentDescriptions.class ? JSON.parse(contentDescriptions.class) : []
  const contentGroupId = style['contentGroupId']

  return (
    <BlockStyle {...props} style={style} className={className}>
      <ContentDescriptions {...props} style={style} contentGroupId={contentGroupId} />
    </BlockStyle>
  )
}

export default connect()(TextEditorContentDescriptionsDisplay)
