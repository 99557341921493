import { fetchDatas, fetchData, createData, updateData, removeData, fetchApi } from '../reduxActions'
import { generateTypeName, generateTypeFunctions } from '../reduxTypes'

import uploadsForS3 from '../../util/uploadsForS3'
import { checkAppActions } from '../App/AppActions'

// Export Constants
const NAME_TYPE = ['TAGS', 'TAG']
const name = "tags"

// Type Names and Type Functions
export const { ADDS, ADDS_MORE, ADD, UPDATE, REMOVE } = generateTypeName(NAME_TYPE)
export const { adds, adds_more, add, update, remove } = generateTypeFunctions(NAME_TYPE)


// *************** Others ***************
export const checkPathCreateTag = (params = {}) => dispatch => {
  return fetchApi({
    dispatch,
    params,
    api: `${name}-query/check-path-create?${params.query}`
  })
}
// *************** Others ***************


// *************** Display ***************
export const fetchTagsDisplay = (businesses_id, params = {}) => dispatch => {
  return fetchDatas({
    dispatch,
    params,
    api: `${name}-display/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchTagDisplay = (tags_path, params = {}) => dispatch => {
  return fetchData({
    dispatch,
    params,
    api: `tag-display/${tags_path}`,
    add: add
  })
}

export const fetchTagDisplayById = (tags_id, params = {}) => dispatch => {
  return fetchData({
    dispatch,
    params,
    api: `tag-display-by-id/${tags_id}`,
    add: add
  })
}
// *************** Display ***************




// Export Actions
export const fetchTags = (authName, businesses_id, params = {}) => dispatch => {
  return fetchDatas({
    dispatch,
    params,
    api: `${name}/${authName}/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchTag = (authName, businesses_id, _id, params = {}) => dispatch => {
  return fetchData({
    dispatch,
    params,
    api: `${name}/${authName}/${businesses_id}/${_id}`,
    add: add
  })
}

export const createTag = (authName, businesses_id, params) => dispatch => {
  return createData({
    dispatch,
    params,
    api: `${name}/${authName}/${businesses_id}/create`,
    add: add,
    order: 'last'
  })
}

export const updateTag = (authName,  businesses_id, _id, params) => dispatch => {
  return updateData({
    dispatch,
    params,
    api: `${name}/${authName}/${businesses_id}/${_id}`,
    update: update,
    updateId: _id
  })
}


export const uploadImagesTag = (authName, businesses_id, _id, files, prefix, params) => dispatch => {
  checkAppActions(dispatch, params, 'start')

  return new Promise(resolve => {
    uploadsForS3(files, prefix, params.progressId, dispatch).then(val => {
      val.map(paths => {
        params.body.paths = paths

        return dispatch(updateTag(authName, businesses_id, _id, params)).then(res => {
          if (res.success) {
            res.paths = paths
          }

          resolve(res)
        })
      })
    })
  })
}

export const removeTag = (authName, businesses_id, _id, params) => dispatch => {
  return removeData({
    dispatch,
    params,
    api: `${name}/${authName}/${businesses_id}/${_id}`,
    remove: remove,
    deleteId: _id
  })
}
