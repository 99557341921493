// Values
import { getProductParams, productValue } from '../../../../../../redux/Products/ProductsValues'

// functions
import { getContentGroupPage, getPageTemplate } from '../../../functions/siteFetchDatas'


const getFetchTypeTemplateData = ({ props, page, path }) => {
  let templateData

  if (page && page.fetchType && page.fetchId) {
    if (page.fetchType === 'product-details') {
      const params = getProductParams(path)
      const items_id = params.products_items_id
      const {customer} = props
      const product = props.products.filter(product => product._id === page.fetchId)[0]
      templateData = productValue({ product, customer, items_id })
    }
  }

  return templateData
}

export const useTemplate = (props) => {
  const { pages, product, article, folder, tag, singlePageData, match } = props
  const { lang, path_1, path_2, path_3, products_id, single_page_products_id, token } = match.params
  const pageIndex = pages.filter(page => page.groupPageType === 'main' && page.path === 'index')[0]
  const page404 = pages.filter(page => page.groupPageType === 'main' && page.path === 'error-404')[0]
  const pageProfileIndex = pages.filter(page => page.groupPageType === 'profile' && page.path === 'profile-index')[0]

  let pageTemplate

  if (token && match && match && match.url.search('/confirm-account-token') !== -1) {
    const page = pages.filter(page => page.groupPageType === 'main' && page.path === 'confirm-account-token')[0]

    pageTemplate = {
      page: page,
      template: false,
      templateData: undefined
    }


  // page for product
  } else if ((path_1 && path_2 === 'item' && path_3)) {
    const page = pages.filter(page => page.groupPageType === 'main' && page.path === path_1)[0]
    const templateData = getFetchTypeTemplateData({ props, page, path: path_3 })

    pageTemplate = {
      page,
      template: false,
      templateData
    }

  // page folder tag product article
  } else if (path_2 && path_2 !== 'm') {
    // folder
    if (path_1 === 'product-categories' || path_1 === 'article-categories') {
      let templateType

      if (path_1 === 'product-categories') templateType = 'product-folder-details'
      else if (path_1 === 'article-categories') templateType = 'article-folder-details'

      pageTemplate = getPageTemplate(props, { data: folder, templateType })

    // tag
    } else if (path_1 === 'product-tags' || path_1 === 'article-tags') {
      let templateType

      if (path_1 === 'product-tags') templateType = 'product-tag-details'
      else if (path_1 === 'article-tags') templateType = 'article-tag-details'

      pageTemplate = getPageTemplate(props, { data: tag, templateType })

    // product
    } else if (path_1 === 'product-details') {
      pageTemplate = getPageTemplate(props, { data: product, templateType: 'product-details' })

    // article
    } else if (path_1 === 'article-details') {
      pageTemplate = getPageTemplate(props, { data: article, templateType: 'article-details'})
    }

  // single page
  } else if (single_page_products_id) {
    pageTemplate = {
      page: singlePageData,
      template: false,
      templateData: singlePageData
    }

  // other
  } else {
    if (path_1) {

      // profile
      if (match.path === '/profile/:path_1?') {
        pageTemplate = {
          page: pages.filter(page => page.groupPageType === 'profile' && page.path === path_1)[0],
          template: false,
          templateData: undefined
        }

      // page only
      } else {
        const page = pages.filter(page => page.groupPageType === 'main' && page.path === path_1)[0]
        const templateData = getFetchTypeTemplateData({ props, page, path: page && page.fetchId })

        pageTemplate = {
          page,
          template: false,
          templateData
        }
      }

    // products manage
    } else if (products_id && match.path === '/product-manage/:products_id') {
      pageTemplate = getPageTemplate(props, { data: product, templateType: 'product-manage' })


    // page index
    } else {
      if (match.url === '/profile' || match.url === '/profile/') {
        pageTemplate = {
          page: pageProfileIndex,
          template: false,
          templateData: undefined
        }
      } else {
        const templateData = getFetchTypeTemplateData({ props, page: pageIndex, path: pageIndex.fetchId })

        pageTemplate = {
          page: pageIndex,
          template: false,
          templateData: templateData
        }
      }
    }
  }

  if (pageTemplate && !pageTemplate.page) {
    pageTemplate.page = page404
  }

  return pageTemplate || {}
}


export const setHeader = (props, setHeightHeader, page) => {
  if (!page) {
    page = useTemplate(props).page
  }

  let heightHeader

  if (page && !page.headerDefaultShow && page.headerFixedShow) {
    const navbarFixed = document.getElementById("navbar-fixed")

    if (navbarFixed && navbarFixed.offsetHeight) {
      heightHeader = navbarFixed.offsetHeight + 'px'
    }
  }


  setHeightHeader(heightHeader)
}


export const handleScroll = (props, { page }) => {
  const header1 = document.getElementById("page-header-display")
  const header2 = document.getElementById("navbar")
  const header3 = document.getElementById("navbar-fixed")
  const headerFixed = document.getElementById("page-header-fixed-display")
  const contentGroupPage = getContentGroupPage(props)

  let header

  if (header1 && header1.offsetHeight) {
    header = header1
    sticky = header1 && header1.offsetHeight
  } else if (header2 && header2.offsetHeight) {
    header = header2
    sticky = header2 && header2.offsetHeight
  } else if (header3 && header3.offsetHeight) {
    header = header3
  }

  let sticky = header && header.offsetHeight
  sticky = sticky * 1.5

  if (!page) {
    page = useTemplate(props).page
  }

  if (contentGroupPage && contentGroupPage.headerMainShow === false) {
    page = contentGroupPage
  }

  if (page && page.headerFixedShow) {
    if (headerFixed) {
      headerFixed.style.visibility = "visible"
      headerFixed.style.opacity = "1"
    }
  }

  if (page && page.headerFixedShow) {
    if (window.pageYOffset > sticky) {
      if (header) header.style.visibility = "hidden"

      if (page && page.headerDefaultShow) {
        if (headerFixed) {
          headerFixed.style.visibility = "visible"
          headerFixed.style.opacity = "1"
        }
      }

    } else {
      if (header) header.style.visibility = "visible"

      if (page && page.headerDefaultShow) {
        if (headerFixed) {
          headerFixed.style.visibility = "hidden"
          headerFixed.style.opacity = "0"
        }
      }
    }
  }
}
