import React, { Fragment } from 'react'

// Components Global
import { LayoutsDesign } from '../../../../LayoutsDesign'
import ModalEditor from '../../../../Modals/ModalEditor'

// Js
import { setStyle, setStyleResponsive } from '../../../components/ElementStyles/components/js/setDivStyle'

// Functions
import { getSections } from '../../../../../redux/Sites/SitesFunctions'

// css
import css from './css/modal.css'

const Modal = (props) => {
  const { mode, content, style } = props
  const byGroupId = content._id
  const sections = getSections(props, { byGroupId })
  const modalId = style['modal-id']
  const width = style['modal-width'] || '600px'
  const widthVw = style['modal-width-vw']

  return (
    <Fragment>
      { mode ?
        <div className={css.container}>
          <div className="pd-10 center">
            <div>ไอดี: {modalId || 'ยังไม่ระบุไอดี'}</div>
            <div>ความกว้าง: {width}</div>
          </div>

          <div
            id={`content-design-${content._id}`}
            className={`${css.modal} z-depth-1 modal-jsx`}
            style={{
              maxWidth: width
            }}>

            <LayoutsDesign
              {...props}
              display
              bodyColorNone
              groupId={byGroupId}
              sections={sections}
            />

            <style jsx>{`
              .modal-jsx {
                width: 100% !important;
                max-width: ${width};
                ${setStyle('modal', style)}
              }

              @media (min-width: 1500px) {
                .modal-jsx {
                  max-width: ${widthVw};
                  ${setStyleResponsive('modal', style)}
                }
              }
            `}</style>
          </div>
        </div>
      :
        <ModalEditor
          id={modalId}
          width={width}
          widthVw={widthVw}
          style={style}>

          <LayoutsDesign
            {...props}
            display
            bodyColorNone
            groupId={byGroupId}
            sections={sections}
          />
        </ModalEditor>
      }
    </Fragment>
  )
}

export default Modal
