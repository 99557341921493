import React, { Component } from 'react'

// Components Text Editor
import {
  setStyle,
  setStyleResponsive
} from '../../TextEditor/components/ElementStyles/components/js/setDivStyle'


// css
import css from './css/numberPicker.css'


class NumberPicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: this.props.id.replace(/\[/g, '').replace(/\]/g, '').replace(/\./g, ''),
      value: this.props.value !== undefined && !isNaN(this.props.value) ? parseInt(this.props.value) : 0,
      step: this.props.step !== undefined && !isNaN(this.props.step) ? parseFloat(this.props.step) : 1,
      max: this.props.max !== undefined && !isNaN(this.props.max) ? parseInt(this.props.max) : 10000,
      min: this.props.min !== undefined && !isNaN(this.props.min) ? parseInt(this.props.min) : 0
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.min !== this.props.min) {
      const min = this.props.min !== undefined && !isNaN(this.props.min) ? parseInt(this.props.min) : 0

      this.setState({ min })
    }

    if (prevProps.max !== this.props.max) {
      const max = this.props.max !== undefined && !isNaN(this.props.max) ? parseInt(this.props.max) : 10000

      this.setState({ max })
    }

    if (prevProps.value !== this.props.value) {
      const value = this.props.value !== undefined && !isNaN(this.props.value) ? parseInt(this.props.value) : 0

      this.setState({ value }, () => {
        if (this.props.getValue && this.props.startGetValue) {
          this.props.getValue(value, this.props.id)
        }
      })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.min !== this.state.min ||
           nextState.max !== this.state.max ||
           nextState.value !== this.state.value ||
           nextProps.min !== this.props.min ||
           nextProps.max !== this.props.max ||
           nextProps.value !== this.props.value ||
           nextProps.disabled !== this.props.disabled ||
           nextProps.styleJsx !== this.props.styleJsx
  }

  componentDidMount() {
    if (this.props.getValue) {
      this.props.getValue(this.state.value, this.props.id)
    }

    if (!this.state.value) {
      this.setState({ value: this.state.min }, () => {
        if (this.props.getValue && this.props.startGetValue) {
          this.props.getValue(this.state.value, this.props.id)
        }
      })
    }
  }

  handlePlus = () => {
    if (this.state.value < this.state.max) {
      this.setState({ value: this.state.value + this.state.step }, () => {
        if (this.props.getValue) {
          this.props.getValue(this.state.value, this.props.id)
        }
      })
    }
  }

  handleMinus = () => {
    if (this.state.value > this.state.min) {
      this.setState({ value: this.state.value - this.state.step }, () => {
        if (this.props.getValue) {
          this.props.getValue(this.state.value, this.props.id)
        }
      })
    }
  }

  handleChangeValue = (e) => {
    let value = e.target.value

    if (value !== '') {
      value = value !== undefined && !isNaN(value) ? parseInt(value) : 0

      if (value > this.state.max) {
        value = this.state.max
      }

      if (value < this.state.min) {
        value = this.state.min
      }
    }

    this.setState({ value }, () => {
      if (this.props.getValue) {
        this.props.getValue(this.state.value, this.props.id)
      }
    })
  }

  handleBlurValue = (e) => {
    let value = e.target.value
    value = value !== undefined && !isNaN(value) ? parseInt(value) : 0

    if (value > this.state.max) {
      value = this.state.max
    }

    if (value < this.state.min) {
      value = this.state.min
    }


    this.setState({ value }, () => {
      if (this.props.getValue) {
        this.props.getValue(this.state.value, this.props.id)
      }
    })
  }

  render() {
    const { value } = this.state
    let { nameJsx, styleJsx, notZero, required } = this.props


    styleJsx = styleJsx || {}

    if (this.props.clickPlus && this.props.clickMinus) {
      var clickPlus = { onClick: () => { this.handlePlus(); this.props.clickPlus(); }}
      var clickMinus = { onClick: () => { this.handleMinus(); this.props.clickMinus(); }}
    } else {
      var clickPlus = { onClick: this.handlePlus }
      var clickMinus = { onClick: this.handleMinus }
    }

    let valid

    if (notZero && value === 0) {
      valid = 'invalid'
    } else {
      if (required) {
        if (value === '') {
          valid = 'invalid'
        } else {
          valid = 'valid'
        }
      } else {
        valid = 'valid'
      }
    }

    return (
      <div className={`${css.numberPicker} ${this.props.className}`} style={this.props.style}>
        { this.props.labelFull &&
          <label style={{ fontSize: '0.9rem' }}>{this.props.labelFull}</label>
        }

        { !this.props.disabled &&
          <input
            id={this.state.id}
            ref={this.state.id}
            data-id={this.props.id}
            value={value}
            data-value-array-name={this.props.valueArrayName}
            data-not-save={this.props.notSave}
            data-child={this.props.child}
            name="number-picker"
            type="hidden"
            className={`${(notZero || required) && 'required'} ${valid}`}
          />
        }

        { !this.props.disabled ?
          <table>
            <tbody>
              <tr>
                <td className="pd-0 width-40">
                  { this.state.value > this.state.min ?
                    <div {...clickMinus} id="recheck-form" className={`${css.btnMinus} ${this.props.btnClassName || ''} btn-minus-jsx waves-red`} style={{ cursor: 'pointer' }}>
                      <i className={`${this.props.iconClassName || ''} material-icons i-middle`}>remove</i>
                    </div>
                  :
                    <div className={`${css.btnMinus} btn-minus-jsx ${css.btnDisabled} ${this.props.btnClassName || ''}`}>
                      <i className={`${this.props.iconClassName || ''} material-icons i-middle color-c0c0c0`}>remove</i>
                    </div>
                  }
                </td>

                <td className="pd-0 center">
                  { this.props.inputEdit ?
                    <input
                      type="number"
                      value={this.state.value}
                      className={`${css.inputValue} ${this.props.inputClassName || ''} input-jsx`}
                      onChange={this.handleChangeValue}
                      onBlur={this.handleBlurValue}
                    />
                  :
                    <div className={`${css.value} ${this.props.inputClassName || ''} input-jsx`}>
                      {this.state.value} {this.props.unit}
                    </div>
                  }
                </td>

                <td className="pd-0 center width-40">
                  { this.state.value < this.state.max ?
                    <div {...clickPlus} id="recheck-form" className={`${css.btnPlus} ${this.props.btnClassName || ''} btn-plus-jsx waves-green`} style={{ cursor: 'pointer' }}>
                      <i className={`${this.props.iconClassName || ''} material-icons i-middle`}>add</i>
                    </div>
                  :
                    <div className={`${css.btnPlus} btn-plus-jsx ${css.btnDisabled} ${this.props.btnClassName || ''}`}>
                      <i className={`${this.props.iconClassName || ''} material-icons i-middle color-c0c0c0`}>add</i>
                    </div>
                  }
                </td>
              </tr>
            </tbody>
          </table>
        :
          <table style={{ cursor: 'not-allowed' }}>
            <tbody>
              <tr>
                <td width="40" className="pd-0">
                  <div className={`${css.btnMinus} btn-minus-jsx ${css.btnDisabled} ${this.props.btnClassName || ''}`} style={{ cursor: 'not-allowed' }}>
                    <i className={`${this.props.iconClassName || ''} material-icons middle color-c0c0c0 font-1-3`}>remove</i>
                  </div>
                </td>

                <td className="pd-0 center bg-fafafa color-c0c0c0">
                  <div className={`${css.value} input-jsx ${this.props.inputClassName || ''}`} style={{ cursor: 'not-allowed' }}>
                    {this.state.value} {this.props.unit}
                  </div>
                </td>

                <td width="40" className="pd-0 center">
                  <div className={`${css.btnPlus} btn-plus-jsx ${css.btnDisabled} ${this.props.btnClassName || ''}`} style={{ cursor: 'not-allowed' }}>
                    <i className={`${this.props.iconClassName || ''} material-icons middle color-c0c0c0 font-1-3`}>add</i>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        }

        {/* !this.props.disabled && this.props.notZero && this.state.value === 0 &&
          <span className="color-error font-0-8">{this.props.label} ต้องไม่ว่าง</span>
        */}

        <style jsx>{`
          .input-jsx {
            ${setStyle(`${nameJsx}-input`, styleJsx)}
          }

          .btn-minus-jsx {
            ${setStyle(`${nameJsx}-btn-minus`, styleJsx)}
          }
          .btn-plus-jsx {
            ${setStyle(`${nameJsx}-btn-plus`, styleJsx)}
          }

          .btn-minus-jsx:hover {
            ${setStyle(`${nameJsx}-btn-minus-hover`, styleJsx)}
          }
          .btn-plus-jsx:hover {
            ${setStyle(`${nameJsx}-btn-plus-hover`, styleJsx)}
          }

          .btn-minus-jsx:active {
            ${setStyle(`${nameJsx}-btn-minus-active`, styleJsx)}
          }
          .btn-plus-jsx:active {
            ${setStyle(`${nameJsx}-btn-plus-active`, styleJsx)}
          }

          .btn-minus-jsx > :global(i.material-icons) {
            font-size: ${styleJsx[`${nameJsx}-btn-minus-icon-size`]};
          }
          .btn-plus-jsx > :global(i.material-icons) {
            font-size: ${styleJsx[`${nameJsx}-btn-plus-icon-size`]};
          }

          @media (min-width: 1500px) {
            .btn-minus-jsx {
              ${setStyleResponsive(`${nameJsx}-btn-minus`, styleJsx)}
            }
            .btn-plus-jsx {
              ${setStyleResponsive(`${nameJsx}-btn-plus`, styleJsx)}
            }

            .btn-minus-jsx > :global(i.material-icons) {
              font-size: ${styleJsx[`${nameJsx}-btn-plus-icon-size-vw`]};
            }
            .btn-plus-jsx > :global(i.material-icons) {
              font-size: ${styleJsx[`${nameJsx}-btn-plus-icon-size-vw`]};
            }
          }
        `}</style>
      </div>
    )
  }
}



export default NumberPicker
