import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import renderRoutes from '../../../../routes/renderRoutes'

// Components
import MenuAndContent from '../components/MenuAndContent'

// Functions
import {
  ordersGroupMenu,
  countOrdersGroup,
  countOrdersGroupDefault
}  from '../../../../redux/Orders/OrdersFunctions'


class OrdersMenuContainer extends Component {
  constructor(props) {
    super(props)
    this.state = countOrdersGroupDefault()
  }

  componentDidMount() {
    this.countOrder()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.countOrder()
    }
  }

  countOrder = () => {
    const { customer, business, match, params } = this.props
    const { affiliate } = params || {}
    let { customers_id } = match.params

    if (customer && !customers_id) {
      customers_id = customer._id
    }

    let affiliate_customers_id

    if (affiliate) {
      affiliate_customers_id = customer._id
    }

    countOrdersGroup(this, { customers_id, business, affiliate_customers_id })
  }

  render() {
    const { route, site, business, customer, auth, location, params } = this.props
    const { structure } = site
    const { pathStart, affiliate } = params || {}
    const menu = ordersGroupMenu({ pathStart: pathStart || `/customer/orders`, pathAllIndex: true, state: this.state })


    let affiliate_customers_id

    if (affiliate) {
      affiliate_customers_id = customer._id
    }

    return (
      <Fragment>
        <MenuAndContent menu={menu} color={structure && structure.color}>
          <div>
            { renderRoutes({
              routes: route.routes,
              extraProps: { site, business, customer, auth, affiliate_customers_id },
              location
            }) }
          </div>
        </MenuAndContent>
      </Fragment>
    )
  }
}

export default connect()(OrdersMenuContainer)
