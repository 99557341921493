import React, {  Component, Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'


// redux actions
import { openModal, closeModal } from '../../../redux/App/AppActions'

// css files
import css from './css/modalDefault.css'


const ModalDefault = (props) => {
  const [open, setOpen] = useState(false)
  const [openTransition, setOpenTransition] = useState(false)

  const setOverflow = (value) => {
    const elem = document.getElementById('site-container')

    if (elem) {
      document.body.style.overflow = value
    }
  }

  const bgModalClose = () => {
    const selection = window.getSelection()

    if(selection.type !== "Range") {
      setTimeout(() => {
        if (!props.blockBgModal) {
          props.dispatch(closeModal(props.id))
        }

        if (props.eventModalClose) {
          props.eventModalClose()
        }

        if (!props.blockBgModal) {
          if (props.fnCloseModal) {
            props.fnCloseModal()
          }
        }
      }, 0)
    }
  }

  const modalClose = () => {
    setTimeout(() => {
      props.dispatch(closeModal(props.id))

      if (props.eventModalClose) {
        props.eventModalClose()
      }

      if (props.fnCloseModal) {
        props.fnCloseModal()
      }
    }, 0)
  }

  const openModalFn = () => {
    setOpen(true)
    setTimeout(() => {
      setOpenTransition(true)
    }, 100)
  }

  const closeModalFn = () => {
    setOpenTransition(false)
    setTimeout(() => {
      setOpen(false)
    }, 500)
  }

  useEffect(() => {
    if (props.modal) {
      setOverflow('hidden')
      openModalFn()

      if (props.fnStartModal) {
        props.fnStartModal()
      }

    } else {
      closeModalFn()

      setTimeout(() => {
        if (props.modals.length === 0) {
          setOverflow(null)
        }
      }, 200)
    }

    return () => {
      props.modal && modalClose()
    }

  }, [props.modal])


  let { editor, width } = props
  width = width || 300

  let widthFull

  if (width === '100%') {
    widthFull = '100%'
  } else {
    width = parseInt(width)
  }

  const centered = {
    height: props.fixed ? '100%' : undefined,
  }


  let id = ""

  if (!props.disabledSelectorId) {
    if (props.uploadImages) {
      id = 'modal'
    } else {
      id = 'modal'
    }
  }

  const style = {
    zIndex: '99999',
    overflow: props.preview && 'auto',
    background: 'rgba(43, 46, 56, .9)',
    visibility: openTransition ? 'visible' :'hidden',
    opacity: openTransition ? '1' :'0'
  }

  return (
    <Fragment>
      { open &&
        <div
          id={id}
          className={`${props.fixed ? css.modalFixed : css.modalBlock} ${props.className}`}
          onClick={bgModalClose}
          style={style}>

          <div
            style={centered}
            className={`${css.centered} modal-default-jsx `}
            onClick={(e) => e.stopPropagation()}>

            { props.children }
          </div>

          <style jsx>{`
            .modal-default-jsx {
              width: ${widthFull};
              max-width: ${!editor && `${width}px`};
            }

            @media (min-width: 1500px) {
              .modal-default-jsx  {
                max-width: ${!editor && `${width/15}vw`};
              }
            }
          `}</style>
        </div>
      }
    </Fragment>
  )
}

function mapStateToProps(store, props) {
  return {
    modals: store.app.modals,
    modal: store.app.modals.filter(modal => modal.id === props.id)[0]
  }
}

export default connect(mapStateToProps)(ModalDefault)
