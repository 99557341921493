import React from 'react'

// css
import css from './css/iconLayout.css'


const IconLayout = (props) => {
  const { _this } = props
  const { layout } = _this.state

  return (
    <div className="bg-fff">
      <div
        onClick={() => _this.handleSetLayout('right')}
        className={`box-middle height-full ${css.layoutIcon}`}>

        <div className={`${layout === 'right' && css.active}`}>
          <div className="height-40">
            <div className="box-middle height-full">
              <div>
                <i className="material-icons">stars</i>
                <i className="material-icons">notes</i>
              </div>
            </div>
          </div>
          <div className="font-0-8">ชิดขวา</div>
        </div>
      </div>

      <div
        onClick={() => _this.handleSetLayout('center')}
        className={`box-middle height-full ${css.layoutIcon}`}>

        <div className={`${layout === 'center' && css.active}`}>
          <div className="height-40">
            <div className="box-middle height-full">
              <div>
                <i className="material-icons dp-block">stars</i>
                <i className="material-icons mg-top--5 dp-block">notes</i>
              </div>
            </div>
          </div>
          <div className="font-0-8">กึ่งกลาง</div>
        </div>
      </div>

      <div
        onClick={() => _this.handleSetLayout('left')}
        className={`box-middle height-full ${css.layoutIcon}`}>

        <div className={`${layout === 'left' && css.active}`}>
          <div className="height-40">
            <div className="box-middle height-full">
              <div>
                <i className="material-icons">notes</i>
                <i className="material-icons">stars</i>
              </div>
            </div>
          </div>
          <div className="font-0-8">ชิดซ้าย</div>
        </div>
      </div>

      <div style={{ clear: 'both' }} />
    </div>
  )
}

export default IconLayout
