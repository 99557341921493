import React, { Component } from 'react'
import cuid from 'cuid'

const delay = (() => {
  let timer = 0

  return (callback, ms) => {
    clearTimeout (timer)
    timer = setTimeout(callback, ms)
  }
})()

class Form extends Component {
  constructor(props) {
    super(props)

    const name = this.props.name
    const randomId = cuid()

    this.state = {
      name: randomId+name,
    }
  }

  checkInput = (time=100) => {
    delay(() => {

      const selectRequired = this.form && this.form.querySelectorAll('.required')
      const selectValid = this.form && this.form.querySelectorAll('.required.valid')
      const selectInvalid = this.form && this.form.querySelectorAll('.invalid')

      const requiredLength = selectRequired && selectRequired.length
      const validLength = selectValid && selectValid.length
      const invalidLength = selectInvalid && selectInvalid.length

      if (requiredLength === validLength && invalidLength === 0) {
        if (this.form.querySelector('button[type=submit]')) {
          this.form.querySelector('button[type=submit]').removeAttribute('disabled')
        }


        this.form.querySelector(`input#valid-${this.state.name}`).value = true

        const btnHidden = this.form.querySelector('button[data-hidden=true]')
        const btnDisabled = this.form.querySelector('button[data-disabled=true]')

        if (btnHidden) btnHidden.style.display = 'block'
        if (btnDisabled) btnDisabled.removeAttribute('disabled')

      } else {
        if (this.form) {
          if (this.form.querySelector('button[type=submit]')) {
            this.form.querySelector('button[type=submit]').setAttribute('disabled', true)
          }

          this.form.querySelector(`input#valid-${this.state.name}`).value = false

          const btnHidden = this.form.querySelector('button[data-hidden=true]')
          const btnDisabled = this.form.querySelector('button[data-disabled=true]')

          if (btnHidden) btnHidden.style.display = 'none'
          if (btnDisabled) btnDisabled.setAttribute('disabled', true)
        }
      }

      //change btn input add and review input
      if (this.form && this.form.querySelector(`input#valid-${this.state.name}`).value === 'true') {
        const btnHidden = this.form.querySelector('button[data-hidden=true]')
        const btnDisabled = this.form.querySelector('button[data-disabled=true]')

        if (btnHidden) btnHidden.style.display = 'block'
        if (btnDisabled) btnDisabled.removeAttribute('disabled')
      }
    }, time)
  }

  checkSubmit = () => {
    const elements = this.form && this.form.querySelectorAll(`input.required`)

    if (elements && elements.length !== 0) {
      for (let i = 0; i <= elements.length; i++) {
        if (elements[i]) {
          const value = elements[i].value
          const inputId = elements[i].id

          if (!value) {
            this.setInvalid(elements[i], inputId)
          }
        }
      }
    }
  }

  setInvalid = (element, inputId) => {
    element.classList.add('invalid')

    const label = this.form && this.form.querySelector(`#label-${inputId}`)
    if (label) label.classList.add('label-invalid')
  }


  checkFormNew = () => {
    if (this.form) {
      this.form.addEventListener('change', () => {
        this.checkInput()
      })

      this.form.addEventListener('keyup', () => {
        this.checkInput()
      })

      this.form.addEventListener('submit', () => {
        this.checkSubmit()
      })

      if (this.props.recheckForm) {
        this.form.addEventListener('click', () => {
          this.checkInput()
        })
      }
    }

    this.checkInput()
  }

  componentDidMount() {
    this.mounted = true

    this.checkFormNew(true)

    if (this.props.recheckForm) {
      document.addEventListener('click', this.handleDocumentClick)
    }
  }

  componentWillUnmount() {
    this.mounted = false

    if (this.props.recheckForm) {
      document.removeEventListener('click', this.handleDocumentClick)
    }
  }

  componentDidUpdate() {
    setTimeout(() => {
      this.checkFormNew()
    }, 200)
  }

  handleDocumentClick = (e) => {
    if (this.mounted) this.setState({ recheckForm: true })
  }

  render() {
    return(
      <form
        ref={form => this.form = form}
        data-formchid={this.props.formChild}
        name={this.state.name}
        onSubmit={this.props.submit}
        onChange={this.props.onChange}>

        <input id={`valid-${this.state.name}`} data-id="valid" type="hidden" ref="valid" />

        { this.props.useLabel &&
          <input
            id={`use-label-${this.state.name}`}
            data-id="useLabel"
            data-not-save="true"
            type="hidden"
            ref="useLabel"
            value="true"
          />
        }

        { this.props.children }
      </form>
    )
  }
}

export default Form
