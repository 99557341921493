import React, { Component } from 'react'

// Functions
import { addStyle, removeStyle } from '../../../functions/textEditorFunctions'

// css
import cssgl from '../css/styleSettings.css'



class TextDecorationSetting extends Component {
  fontChange = (e) => {
    const { _this } = this.props
    const style = this.props.styleName || 'text-decoration'
    const value = e.target.value

    if (value === '') {
      removeStyle(_this, style)
    } else {
      addStyle(_this, { style, value })
    }
  }

  render() {
    const { _this } = this.props
    const { style } = _this.state
    const styleName = this.props.styleName || 'text-decoration'
    const textDecoration = style && style[styleName]

    return (
      <select
        className={`browser-default ${cssgl.input} ${cssgl.select}`}
        onChange={this.fontChange}
        value={textDecoration || ''}>

        <option value="">ไม่มี</option>
        <option value="underline">ขีดเส้นใต้</option>
        <option value="overline">ขีดเส้นบน</option>
        <option value="line-through">ขีดเส้นทับ</option>
        <option value="blink">กระพริบ</option>
      </select>
    )
  }
}

export default TextDecorationSetting
