import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import TextEditorSpace from './TextEditorSpace'
import TextEditorSpaceDisplay from './TextEditorSpaceDisplay'

let TextEditorSpace = lazy(() => import('./TextEditorSpace'))
TextEditorSpace = suspenseComponent(TextEditorSpace)

export { TextEditorSpace, TextEditorSpaceDisplay }
