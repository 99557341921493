import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Global
import { Row, Column } from '../../../components/Columns'
import { Btn } from '../../../components/button/Button'
import Modal from '../../../components/Modals/Modal'
import { BtnModalUploadClose } from './BtnModalUpload'
import DragDropFiles from '../../../components/DragDropFiles'
import FormValidation from '../../../components/Form'
import BtnSubmit from '../../../components/button/BtnSubmit'

// Redux Actions
import { updateProgress } from '../../../redux/Progresses/ProgressesActions'
import { closeModal } from '../../../redux/App/AppActions'


class ModalUploadImages extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handleClickHidden = (id) => {
    setTimeout(() => {
      this.props.dispatch(closeModal(id))
      this.props.dispatch(updateProgress(id, true))
    }, 0)
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.props.upload.onUpload(
      this.props.progress.id,
      this.props.progress.files,
      this.props.progress.album
    )
  }

  render() {
    const { progress, modal } = this.props
    const filesLoadLength =  progress && progress.files.filter(file => file.status === 'load').length
    const filesSuccessLength =  progress && progress.files.filter(file => file.status === 'success').length
    const files = progress && progress.files
    const imageUrl = modal && modal.params.imageUrl

    return (
      <Modal
        uploadImages
        progresses
        id={this.props.id}
        width="550px"
        btnClose="false"
        blockBgModal>

        <Row>
          <FormValidation
            ref={this.props.id}
            name={this.props.id}
            submit={(e) => this.handleSubmit(e, this.props.id)}>

            <DragDropFiles
              required
              id={this.props.id}
              name={this.props.id}
              crop={this.props.upload.crop}
              upload={this.props.upload}
              progress={this.props.progress}
              maxFile={this.props.upload.maxFile}
              multiple={this.props.upload.multiple}
              imageUrl={imageUrl}
            />

            <Column col="column col-xxs-12 col-xs-12 mg-top-20">
              <Column col="column col-xxs-6 col-xs-6 text-align-left">

                {/* filesLoadLength === 0 && filesSuccessLength === 0 ?
                  <BtnModalUploadClose id={this.props.id}>
                    <Btn
                      icon="close"
                      text="ออก"
                      className="waves-effect btn btn-flat btn-border waves-light"
                    />
                  </BtnModalUploadClose>
                :
                  <Btn
                    onClick={() => this.handleClickHidden(this.props.id) }
                    icon="file_download"
                    text="ซ่อนรายการ"
                    className="waves-effect btn btn-flat btn-border waves-light"
                  />
                */}

                { filesLoadLength === 0 && filesSuccessLength === 0 ?
                  <BtnModalUploadClose id={this.props.id}>
                    <Btn
                      icon="close"
                      text="ออก"
                      className="waves-effect btn btn-flat btn-border waves-light"
                    />
                  </BtnModalUploadClose>
                :
                  <div className="width-5 height-5"></div>
                }

              </Column>

              <Column col="column col-xxs-6 col-xs-6 text-align-right">
                { files.length !== 0 &&
                  <BtnSubmit
                    icon="send"
                    iconAlign="left"
                    text="อัพโหลดภาพ"
                    className="btn-shadow-none btn btn-success"
                    loadingId={this.props.id}
                  />
                }
              </Column>
            </Column>

          </FormValidation>
        </Row>
      </Modal>
    )
  }
}

const mapStateToprops = (store, props) => {
  return {
    progress: store.progresses.data.filter(progress => progress.id === props.id)[0],
    modal: store.app.modals.filter(modal => modal.id === props.id)[0]
  }
}

export default connect(mapStateToprops)(ModalUploadImages)
