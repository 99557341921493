import React, { Fragment } from 'react'

// Components Global
import LayoutTabs from '../../../LayoutTabs'

// css
import css from './css/boxResponsiveSettings.css'


const BoxResponsiveSettings = (props) => {
  let { items, sidebar } = props

  if (!items) {
    items = [
      {
        id: 'desktop',
        name: 'เดสก์ท็อป',
        icon: 'desktop_windows',
        //disabled: !props.content
      },
      {
        id: 'tablet',
        name: 'แท็บเล็ต',
        icon: 'tablet',
        //disabled: !props.style
      },
      {
        id: 'mobile',
        name: 'มือถือ',
        icon: 'phone_iphone',
        //disabled: !props.api
      }
    ]
  }

  const activeId = props.activeId || 'desktop'

  return (
    <div className={css.boxSettings}>
      <div className={sidebar ? css.bodyAndClose : css.body}>
        <LayoutTabs circle items={items} activeId={activeId}>
          { items.map((item, i) => {
            return (
              <div key={i} className="pd-top-50">
                { props.children[i] }
              </div>
            )
          }) }
        </LayoutTabs>
      </div>
    </div>
  )
}

export default BoxResponsiveSettings
