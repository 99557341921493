import React, { useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'

// Components Dashboard
import { CustomerProfileAvater } from '../../../../dashboard/Customers/components/CustomersUserInterface'

// css
import css from './css/headerCustomer.css'


let navbar, navbarFixed

const HeaderCustomer = (props) => {
  const { site, customer } = props
  const { structure } = site

  const activeStyle = {
    background: structure.color,
    color: structure.colorContrast
  }

  useEffect(() => {
    navbar = document.getElementById("navbar")
    navbarFixed = document.getElementById("navbar-fixed")

    if (navbar && navbarFixed) {
      navbarFixed = undefined
    }
  }, [])


  return (
    <div className={css.header} style={{ marginTop: `${navbarFixed && navbarFixed.clientHeight}px` }}>
  		<div className={`center height-300`} style={{ backgroundColor: customer.coverColor }}>
        <div className={`${css.detail} height-300`}>
  				<div className="pd-top-80">
            <div className="mg-center width-100 height-100">
              <CustomerProfileAvater
                customer={customer}
                className="z-depth-1 circle z-depth-1 border-3 border-fff"
                fontSize="2rem"
              />
            </div>

            <div className="color-fff font-1-5 mg-top-10">{customer.firstNameLang + ' ' + customer.lastNameLang}</div>
            <div className="color-fff font-0-9 mg-top-5 mg-bottom-20">รหัสลูกค้า {customer.code}</div>
          </div>
        </div>
  		</div>

  		<div className={`container nav mg-top--20 ${css.menu}`}>
  			<nav className="white height-40 line-height-40">
			    <div className="nav-wrapper">
		      	<ul>
		      		<li className="waves-effect height-40">
                <NavLink to={`/customer/profile`} activeStyle={activeStyle}>
                  <i className="height-40 line-height-40 material-icons left hide-on-small-only">account_box</i>บัญชี
                </NavLink>
              </li>
		        	<li className="waves-effect height-40">
                <NavLink to={`/customer/orders`} activeStyle={activeStyle}>
                  <i className="height-40 line-height-40 material-icons left hide-on-small-only">receipt</i>ออเดอร์
                </NavLink>
              </li>
              <li className="waves-effect height-40">
                <NavLink to={`/customer/affiliate`} activeStyle={activeStyle}>
                  <i className="height-40 line-height-40 material-icons left hide-on-small-only">account_balance_wallet</i> ขายได้
                </NavLink>
              </li>
		        	<li className="waves-effect height-40">
                <NavLink to={`/customer/favorites`} activeStyle={activeStyle}>
                  <i className="height-40 line-height-40 material-icons left hide-on-small-only">favorite_border</i>รายการที่ชอบ
                </NavLink>
              </li>
              {/*
              <li className="waves-effect height-40">
                <NavLink to={`/customer/reviews`} activeStyle={activeStyle}>
                  <i className="height-40 line-height-40 material-icons left hide-on-small-only">star_border</i>รีวิวของฉัน
                </NavLink>
              </li>*/}
		      	</ul>
			    </div>
  			</nav>

  		</div>
  	</div>
  )
}


export default HeaderCustomer
