import React, { Fragment } from 'react'

// Components Global
import { LayoutsDesign } from '../../../../LayoutsDesign'

// Components Local
import SidebarDisplay from '../SidebarDisplay'

// Functions
import { getSections } from '../../../../../redux/Sites/SitesFunctions'

// css
import css from './css/sidebar.css'

const Sidebar = (props) => {
  const { mode, content, style } = props
  const byGroupId = content._id
  const sections = getSections(props, { byGroupId })
  const widgetId = style['widget-id']
  const position = style['sidebar-position'] || 'right'
  const width = style['sidebar-width'] || '300px'
  let positionName

  if (position === 'right') {
    positionName = 'ชิดขวา'
  } else if (position === 'left') {
    positionName = 'ชิดซ้าย'
  } else if (position === 'top') {
    positionName = 'บน'
  } else if (position === 'bottom') {
    positionName = 'ล่าง'
  }

  return (
    <Fragment>
      { mode ?
        <div className={css.container}>
          <div className="pd-10 center">
            <div>ไอดี: {style['widget-id'] || 'ยังไม่ระบุไอดี'}</div>
            <div>ตำแหน่ง: {positionName}</div>
            <div>ความกว้าง: {width}</div>
          </div>

          <div
            id={`content-design-${content._id}`}
            className={`${css.sidebar} z-depth-1`}
            style={{
              maxWidth: width,
              backgroundColor: style[`sidebar-background-color`]
            }}>

            <LayoutsDesign
              {...props}
              display
              bodyColorNone
              groupId={byGroupId}
              sections={sections}
            />
          </div>
        </div>
      :
        <SidebarDisplay
          id={widgetId}
          width={width}
          position={position}
          style={style}>

          <LayoutsDesign
            {...props}
            display
            bodyColorNone
            groupId={byGroupId}
            sections={sections}
          />
        </SidebarDisplay>
      }
    </Fragment>
  )
}

export default Sidebar
