import React from 'react'
import { lazy } from 'react'
import { connect } from 'react-redux'
import ReactSuspense from '../../../util/ReactSuspense'

// Components Local
import BlockStyle from '../components/BlockStyle'

// Redux
import { getLoginStatus } from '../../../redux/Auth/AuthFunctions'

// Components Local
const AddToCart = lazy(() => import('./components/AddToCart'))

import { getApi, getDataStore, getDataApi } from '../functions/textEditorEvent'


const TextEditorAddToCartDisplay = (props) => {
  const { content, apiData, templateData, dataStore, auth, loginStatus } = props
  const addToCart = content.addToCart && JSON.parse(content.addToCart)
  const style = addToCart && addToCart.style ? JSON.parse(addToCart.style) : {}
  const className = addToCart && addToCart.class ? JSON.parse(addToCart.class) : []

  let data = templateData

  if (content.api) {
    const api = getApi(content, addToCart)
    data = getDataApi({ api, apiData, templateData, dataStore })
  }


  return (
    <ReactSuspense>
      <BlockStyle {...props} style={style} className={className}>
        <AddToCart auth={auth} loginStatus={loginStatus} product={data} style={style} />
      </BlockStyle>
    </ReactSuspense>
  )
}

const mapStateToProps = (store, props) => {
  return {
    auth: store.auth,
    loginStatus: getLoginStatus(store.auth),
    dataStore: getDataStore({ store, props })
  }
}

export default connect(mapStateToProps)(TextEditorAddToCartDisplay)
