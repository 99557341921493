import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import SectionItem from './SectionItem'
import SectionItemDisplay from './SectionItemDisplay'

const SectionItemLazy = lazy(() => import('./SectionItem'))
const SectionItem = suspenseComponent(SectionItemLazy)


export {
  SectionItem,
  SectionItemDisplay
}
