import callApi from '../../util/apiCaller'
import uploadsForS3 from '../../util/uploadsForS3'

import {
  checkAppActions,
  errorAlert,
  httpStatus
} from '../App/AppActions'

// Export Constants
export const ADD_HOTELS = 'ADD_HOTELS'
export const ADD_HOTEL = 'ADD_HOTEL'
export const UPDATE_HOTEL = 'UPDATE_HOTEL'
export const DELETE_HOTEL = 'DELETE_HOTEL'


// Export Type
export const add_hotels = hotels => ({ type: ADD_HOTELS, hotels })
export const add_hotel = hotel => ({ type: ADD_HOTEL, hotel })
export const update_hotel = (hotel, _id, event=null) => ({ type: UPDATE_HOTEL, hotel, _id, event })
export const delete_hotel = (_id) => ({ type: DELETE_HOTEL, _id })


// Export Actions
export const fetchHotels = (businesses_id, params) => dispatch => {
  checkAppActions(dispatch, params, 'start')

  return callApi(`hotels/${businesses_id}`, 'get', params.body).then(res => {
    if (res.success) {
      dispatch(add_hotels(res.data))
      checkAppActions(dispatch, params, 'success')
    } else {
      dispatch(errorAlert(res))
      checkAppActions(dispatch, params, 'error')
    }
  })
}


export const fetchHotelNeed = (username, _id) => dispatch => {
  return callApi(`hotels/${username}/${_id}`).then(res => {
    if (res.success) {
      dispatch(add_hotel(res.data))
    } else {
      dispatch(errorAlert(res))
    }
  })
}


export const fetchHotel = (businesses_id, _id, params = {}) => dispatch => {
  checkAppActions(dispatch, params, 'start')

  return callApi(`hotels/${businesses_id}/${_id}`).then(res => {
    if (res.success) {
      dispatch(add_hotel(res.data))
      checkAppActions(dispatch, params, 'success')
    } else {
      dispatch(errorAlert(res))
      checkAppActions(dispatch, params, 'error')
    }
  })
}


export const createHotel = (businesses_id, params, autocomplete=false) => dispatch => {
  checkAppActions(dispatch, params, 'start')

  return callApi(`hotels/${businesses_id}/create`, 'put', params.body).then(res => {
    if (res.success) {
      if (!autocomplete) {
        dispatch(add_hotel(res.data))
        checkAppActions(dispatch, params, 'success')
        
      } else {
        params.body[params.body.saveKey] = res.data
        autocomplete(params.body, params.loadId)
      }

    } else {
      dispatch(errorAlert(res))
      checkAppActions(dispatch, params, 'error')
    }
  })
}


export const updateHotel = (businesses_id, hotels_id, params) => dispatch => {
  checkAppActions(dispatch, params, 'start')

  return callApi(`hotels/${businesses_id}/${hotels_id}`, 'put', params.body).then(res => {
    if (res.success) {
      dispatch(update_hotel(res.data, hotels_id, params.body))
      checkAppActions(dispatch, params, 'success')
    } else {
      dispatch(errorAlert(res))
      checkAppActions(dispatch, params, 'error')
    }
  })
}


export const uploadImagesHotel = (businesses_id, hotels_id, files, prefix, params) => dispatch => {
  checkAppActions(dispatch, params, 'start')
  uploadsForS3(files, prefix, params.progressId, dispatch).then(val => {
    val.map(paths => {
      params.body.paths = paths
      dispatch(updateHotel(businesses_id, hotels_id, params))
    })
  })
}


export const deleteHotel = (businesses_id, hotels_id, params) => dispatch => {
  checkAppActions(dispatch, params, 'start')

  return callApi(`hotels/${businesses_id}/${hotels_id}`, 'delete').then(res => {
    if (res.success) {
      dispatch(delete_hotel(hotels_id))
      checkAppActions(dispatch, params, 'success')
    } else {
      dispatch(errorAlert(res))
      checkAppActions(dispatch, params, 'error')
    }
  })
}

export const getHotel = (hotels, hotels_id, attribute) => {
  
}
