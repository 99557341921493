// Redux Actions
import {
  updateTag,
  removeTag,
  uploadImagesTag
} from './TagsActions'

const title = "แท็ก"

// Main
export const onEditModal = () => {
  return {
    modal: { id: 'tagUpdateModal' }
  }
}

export const onMoveModal = () => {
  return {
    modal: { id: 'tagMoveModal' }
  }
}

export const onEdit = (url) => {
  return { url }
}

export const onView = (url) => {
  return { url }
}

export const onDelete = (props, params) => {
  const handleDelete = (props, toastMsg, params={}) => {
    return (id) => {
      props.dispatch(removeTag('admin', props.business._id, id, {
        toastMsg: toastMsg,
        loadId: id,
        confirmId: id
      }))
    }
  }

  return {
    label: `ลบ${title}`,
    onConfirm: handleDelete(props, `ลบ${title}นี้ อย่างถาวรแล้ว`, params)
  }
}


// Images Main
export const uploadImage = (props) => {
  return (id, files, album) => {
    if (files) {
      props.dispatch(uploadImagesTag('admin', props.business._id, props.tag._id, files, 'tags', {
        body: {
          updateType: 'push',
          updateElement: 'images',
          album: album,
          profile: album === 'profile' ? true : false,
          cover: album === 'cover' ? true : false,
        },
        loadId: id,
        modalId: id,
        progressId: id
      }))
    }
  }
}

export const onDeleteImage = (props) => {
  return (id) => {
    props.dispatch(updateTag('admin', props.business._id, props.tag._id, {
      body: {
        updateType: 'pull-image',
        updateElement: 'images',
        deleteId: id
      },
      toastMsg: `ลบภาพนี้แล้ว`,
      loadId: id,
      confirmId: id,
      props: props
    }))
  }
}

export const getTagsText = ({ tags, lang }) => {
  let text = ''

  if (tags) {
    tags.map((tag, i) => {
      if (i === 0) {
        text = `${tag.name[lang]}`
      } else {
        text = `${text}, ${tag.name[lang]}`
      }
    })
  }

  return text
}
