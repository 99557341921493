import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import renderRoutes from '../../../../routes/renderRoutes'

// Actions Redux
import { fetchSiteForUser } from '../../../../redux/Sites/SitesActions'
import { fetchCartForUser } from '../../../../redux/Carts/CartsActions'
import { fetchCustomerForUser } from '../../../../redux/Customers/CustomersActions'

const AppContext = React.createContext()

const SiteContainer = (props) => {
  const [modals, setModals] = useState([])

  const openModalState = (id, params) => {
    // //const newModal = [...modals]
    // const newModal = []
    // const check = modals.filter(modal => modal.id === id)[0]

    // //console.log(check);

    // if (!check) {
    //   newModal.push({ id, params })
    //   setModals(newModal)
    // }

    const newModal = [{ id, params }]
    setModals(newModal)
  }

  const closeModalState = (id) => {
    //const newModal = [...modals]
    const newModal = []
    const index = modals.findIndex(modal => modal.id === id)

    if (index !== -1) {
      newModal.splice(index, 1)
      setModals(newModal)
    }
  }

  useEffect(() => {
    getDatasForUser()
  }, [props.auth])

  const getDatasForUser = () => {
    const { site, business, auth } = props
    const dbProductsNo = business && business.dbProductsNo || 'not-use-db'

    if (site && site.ecommerce && site.ecommerce.status === 'open') {
      props.dispatch(fetchCartForUser(dbProductsNo, site.businesses_id))
    }

    if (auth && auth.user && auth.user._id) {
      props.dispatch(fetchCustomerForUser(site.businesses_id))
    }
  }

  const { route, site, setting, location, cart } = props
  const app = {
    modals, openModalState, closeModalState
  }

  return (
    <AppContext.Provider value={app}>
      { site ?
        <Fragment>
          { renderRoutes({
            routes: route.routes,
            extraProps: { site, setting, cart },
            location
          }) }
        </Fragment>
      :
        <h1>404 Not Found</h1>
      }
    </AppContext.Provider>
  )
}


SiteContainer.fetchData = (store) => {
  const state = store.getState()
  const host = state.host

  if (host) {
    return store.dispatch(fetchSiteForUser({ body: host }))
  }
}

const mapStateToProps = (store) => {
  return {
    host: store.host,
    auth: store.auth,
    site: store.sites.data[0],
    business: store.businesses.data[0],
    setting: store.settings.data[0],
    cart: store.carts.data[0],
  }
}

export { AppContext }
export default connect(mapStateToProps)(SiteContainer)
