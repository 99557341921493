import React, { Fragment } from 'react'

// Components Global
import ColorGradientPicker from '../../../../ColorGradientPicker'

// Functions
import { addStyle, removeStyle } from '../../../functions/textEditorFunctions'

// Actions
import { openModal } from '../../../../../redux/App/AppActions'

// css
import cssgl from '../css/styleSettings.css'


const BackgroundGradientSetting = (props) => {
  const { _this, label, styleName, right, notInput } = props
  const { style } = _this.state
  const backgroundImage = style[styleName]

  const backgroundChange = (color) => {
    if (color) {
      addStyle(_this, {
        styleMultiple: [
          { style: `${styleName}`, value: color.backgroundImage },
          { style: `${styleName}-data`, value: color.backgroundImageData }
        ],
        removeBackgroundColor: true
      })
    } else {
      removeStyle(_this, null, {
        styleMultiple: [
          { style: `${styleName}` },
          { style: `${styleName}-data` },
        ]
      })
    }
  }

  const reset = () => {
    removeStyle(_this, null, {
      styleMultiple: [
        { style: `${styleName}` },
        { style: `${styleName}-data` },
      ]
    })
  }

  const handleOpenModal = (id) => {
    _this.props.dispatch(openModal(id))
  }

  let modalId

  if (props.modalId) {
    modalId = props.modalId
  } else {
    const { content, layout, columnNo } = _this.props

    if (content && content._id) {
      modalId = `modal-background-gradient-${styleName}-${content._id}`
    }

    if (layout && columnNo) {
      modalId = `modal-background-gradient-${styleName}-${columnNo}-${layout._id}`
    }

    if (layout && !columnNo) {
      modalId = `modal-background-gradient-${styleName}-${layout._id}`
    }
  }

  return (
    <Fragment>
      { modalId &&
        <Fragment>
          <ColorGradientPicker
            data={style[`${styleName}-data`]}
            onChange={backgroundChange}
            id={modalId}
          />

          { !notInput &&
            <div className={cssgl.section}>
              <table>
                <tbody>
                  <tr>
                    <td className="pd-0 font-0-8">{label || 'สีพื้นหลัง (gradient)'}</td>
                    <td className="pd-0 font-0-7 text-right">
                      <a className={cssgl.btn} onClick={reset}>รีเซ็ท</a>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="mg-top-5">
                <div
                  onClick={() => handleOpenModal(modalId)}
                  className="height-35 pd-3 border-radius-3"
                  style={{ border: '1px solid #f2f2f2', cursor: 'pointer' }}>

                  <div
                    className={`${cssgl.colorSeleted}`}
                    style={{ backgroundImage }}
                  />
                </div>
              </div>
            </div>
          }
        </Fragment>
      }
    </Fragment>
  )
}

export default BackgroundGradientSetting
