import countries from './countries/countries.json'
import provincesTH from './provinces/provincesTH.json'

export const getCountries = () => {
    return countries.filter(country => country.countryNameTh !=="")
}

export const getCountryByCode = (code) => {
    return countries.filter(country => country.countryCode === code)[0]
}

export const getProvinces = (countryCodes) => {
    return provincesTH
}

export const getProvincesByCountryCode = (countryCode) => {
    let provinces

    if (countryCode === 'TH') provinces = provincesTH[0]

    return provinces
}

export const getProvinceByProvincesId = (countryCode, provincesId) => {
    let provinces = getProvincesByCountryCode(countryCode)
    let province

    

    if (provinces) {
        province = provinces.provinces.filter(val => val.id === provincesId)[0]
    }

    return province
}

