import { lazy } from 'react'
import suspenseComponent from '../../../../../../util/suspenseComponent'

// Pages
const OrdersListPage = lazy(() => import('../../../../../../containers/dashboard/Sales/pages/OrdersListPage'))


const OrdersMenuRoutes = ({ pathStart, pathAllIndex, auth, forUser=false }) => [
  {
    path: pathAllIndex ? pathStart : `${pathStart}/all`,
    auth,
    params: { forUser, name: 'all', title: 'ทั้งหมด', icon: 'library_books' },
    component: suspenseComponent(OrdersListPage),
    exact: pathAllIndex,
  },
  {
    path: `${pathStart}/contacts`,
    auth,
    params: { forUser, name: 'contacts', title: 'คำสั่งซื้อใหม่', icon: 'chat' },
    component: suspenseComponent(OrdersListPage)
  },

  {
    path: `${pathStart}/unpaid`,
    auth,
    params: { forUser, name: 'unpaid', title: 'ยังไม่ชำระเงิน', icon: 'assignment_late' },
    component: suspenseComponent(OrdersListPage)
  },
  {
    path: `${pathStart}/waiting-confirmed-paid`,
    auth,
    params: { forUser, name: 'waiting-confirmed-paid', title: 'รอยืนยันการชำระเงินจากผู้ขาย', icon: 'gavel' },
    component: suspenseComponent(OrdersListPage)
  },
  {
    path: `${pathStart}/partial-paid`,
    exact: true,
    auth,
    params: { forUser, name: 'partial-paid', title: 'ชำระเงินบางส่วน', icon: 'pie_chart' },
    component: suspenseComponent(OrdersListPage)
  },

  {
    path: `${pathStart}/prepare-shipping`,
    exact: true,
    auth,
    params: { forUser, name: 'prepare-shipping', title: 'รอการจัดส่งจากผู้ขาย', icon: 'local_shipping' },
    component: suspenseComponent(OrdersListPage)
  },
  {
    path: `${pathStart}/shipping-received`,
    exact: true,
    auth,
    params: { forUser, name: 'shipping-received', title: 'ที่ต้องรับ', icon: 'card_giftcard' },
    component: suspenseComponent(OrdersListPage)
  },

  {
    path: `${pathStart}/successed`,
    exact: true,
    auth,
    params: { forUser, name: 'successed', title: 'เสร็จแล้ว', icon: 'assignment_turned_in' },
    component: suspenseComponent(OrdersListPage)
  },
  {
    path: `${pathStart}/refunded`,
    exact: true,
    auth,
    params: { forUser, name: 'refunded', title: 'คืนเงิน', icon: 'restore_page' },
    component: suspenseComponent(OrdersListPage)
  },
  {
    path: `${pathStart}/canceled`,
    exact: true,
    auth,
    params: { forUser, name: 'canceled', title: 'ยกเลิกแล้ว', icon: 'cancel' },
    component: suspenseComponent(OrdersListPage)
  }
]

export default OrdersMenuRoutes
