import React from 'react'

// Components Local
import { EditorIconDisplay } from '../components/EditorIcon'

// Components Editor
import BlockStyle from '../components/BlockStyle'


const TextEditorIconDisplay = (props) => {
  const { content, mode } = props
  const lang = props.lang || 'local'
  /*const icon = content.icon && JSON.parse(content.icon)
  const style = icon && icon.style ? JSON.parse(icon.style) : {}
  const className = icon && icon.class ? JSON.parse(icon.class) : []*/

  return (
    <EditorIconDisplay
      lang={lang || 'local'}
      icon={content.icon}
      iconStyle={{
        fontSize: '4rem'
      }}
    />
  )
}

export default TextEditorIconDisplay

