import React, { useEffect } from 'react'

import PhotoSwipeLightbox from './dist/photoswipe-lightbox.esm'

import './dist/photoswipe.global.css'

const Photoswipe = (props) => {
  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: '#' + props.galleryId,
      children: '.photoSwipeLightbox',
      pswpModule: () => import('./dist/photoswipe.esm'),
      loop: false
    })

    lightbox.init()

    return () => {
      lightbox.destroy()
      lightbox = null
    }
  }, [])

  return (
    <div id={props.galleryId} className="pswp-gallery" style={{ height: '100%' }}>
      { props.children }
    </div>
  )
}

export default Photoswipe
