import { fetchDatas, fetchData, createData, updateData, removeData, fetchApi } from '../reduxActions'
import { generateTypeName, generateTypeFunctions, getTypePath } from '../reduxTypes'


// Export Constants
const NAME_TYPE = ['INVENTORIES', 'INVENTORY']
const name = "inventories"

// Type Names and Type Functions
export const { ADDS, ADDS_MORE, ADD, UPDATE, REMOVE } = generateTypeName(NAME_TYPE)
export const { adds, adds_more, add, update, remove } = generateTypeFunctions(NAME_TYPE)


// Export Actions
export const countInventories = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return fetchApi({
    dispatch,
    params,
    api: `${path}${name}-count/${authName}/${dbNo}/${businesses_id}`,
  })
}

export const fetchInventoriesCount = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return fetchDatas({
    dispatch,
    params,
    api: `${path}${name}-fetch-count/${authName}/${dbNo}/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchInventoriesWarehousesCount = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return fetchDatas({
    dispatch,
    params,
    api: `${path}${name}-warehoses-fetch-count/${authName}/${dbNo}/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchInventories = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return fetchDatas({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchInventory = (query, params = {}) => dispatch => {
  const { authName, dbNo, businessType, businesses_id, inventories_id } = query
  const path = getTypePath(businessType)

  return fetchData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/${inventories_id}`,
    add: add
  })
}

export const createInventory = (query, params) => dispatch => {
  const { authName, dbNo, businessType, businesses_id } = query
  const path = getTypePath(businessType)

  return createData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/create`,
    add: add
  })
}


export const updateInventory = (query, params) => dispatch => {
  const { authName, dbNo, businessType, businesses_id, inventories_id, _id } = query
  const path = getTypePath(businessType)

  return updateData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/${_id || inventories_id}`,
    update: update,
    updateId: _id || inventories_id
  })
}

export const removeInventory = (query, params) => dispatch => {
  const { authName, dbNo, businessType, businesses_id, inventories_id, _id } = query
  const path = getTypePath(businessType)

  return removeData({
    dispatch,
    params,
    api: `${path}${name}/${authName}/${dbNo}/${businesses_id}/${_id || inventories_id}`,
    remove: remove,
    deleteId: _id || inventories_id
  })
}
