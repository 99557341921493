import React from 'react'
import { connect } from 'react-redux'

// Redux Actions
import { openModal, closeModal } from '../../../redux/App/AppActions'
import {
  addProgress,
  updateProgress,
  removeProgress,
  removeFileProgress
} from '../../../redux/Progresses/ProgressesActions'

const ModalUploadOpen = (props) => {

  const handleClickOpenModal = () => {
    if (props.progress) {
      const files = props.progress.files
      const fileSuccess = props.progress.files.filter(file => file.status === 'success')

      if (files.length === fileSuccess.length) {
        files.map((file, i) => {
          props.dispatch(removeFileProgress(props.id, 0))
        })
      }
    }

    props.dispatch(removeProgress(props.id))
    props.dispatch(addProgress(props.id, props.upload))
    props.dispatch(openModal(props.id, {
      imageUrl: props.imageUrl
    }))
  }

  return (
    <div onClick={handleClickOpenModal} className={props.className} style={{ height: '100%', minHeight: '100%' }}>
      { props.children }
    </div>
  )
}

const ModalUploadClose = (props) => {
  const handleClickCloseModal = () => {
    setTimeout(() => {
      props.dispatch(removeProgress(props.id))
      props.dispatch(closeModal(props.id))
    }, 0)
  }

  return (
    <div onClick={handleClickCloseModal} style={{ display: 'inline-block' }}>
      { props.children }
    </div>
  )
}

const mapStateToprops = (store, props) => {
  return {
    progress: store.progresses.data.filter(progress => progress.id === props.id)[0]
  }
}

export const BtnModalUploadOpen = connect(mapStateToprops)(ModalUploadOpen)
export const BtnModalUploadClose = connect()(ModalUploadClose)
