import React from 'react'

// Components Global
import Collapsible from '../../../Collapsible'


const BoxSettings = (props) => {
  return (
    <div className={`mg-top-10 ${props.className}`}>
      <Collapsible
        open={props.open}
        title={props.title}
        iconTitle={props.icon}
        titleClassName="border-bottom-eee border-top-eee bg-fff height-40 pd-top-8 font-0-9">

        <div className="bg-fff border-bottom-eee">
          { props.children }
        </div>
      </Collapsible>
    </div>
  )
}

export default BoxSettings
