import React, { Component } from 'react'

// Functions
import { addStyle, removeStyle } from '../../../../functions/textEditorFunctions'

// css
import cssgl from '../../css/styleSettings.css'



class SelectFontWeight extends Component {
  fontChange = (e) => {
    const { _this } = this.props
    const style = this.props.styleName || 'font-weight'
    const value = e.target.value

    if (value === '') {
      removeStyle(_this, style)
    } else {
      addStyle(_this, { style, value })
    }
  }

  render() {
    const { _this } = this.props
    const { style } = _this.state
    const styleName = this.props.styleName || 'font-weight'
    const fontWeight = style && style[styleName]

    return (
      <select
        className={`browser-default ${cssgl.input} ${cssgl.select}`}
        onChange={this.fontChange}
        value={fontWeight || ''}>

        <option value="">ไม่มี</option>
        <option value="bold">ตัวหนา</option>
        <option value="100">100</option>
        <option value="200">200</option>
        <option value="300">300</option>
        <option value="400">400</option>
        <option value="500">500</option>
        <option value="600">600</option>
        <option value="700">700</option>
        <option value="800">800</option>
        <option value="900">900</option>
      </select>
    )
  }
}

export default SelectFontWeight
