import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import TextEditorLine from './TextEditorLine'
import TextEditorLineDisplay from './TextEditorLineDisplay'

let TextEditorLine = lazy(() => import('./TextEditorLine'))
TextEditorLine = suspenseComponent(TextEditorLine)

export {
  TextEditorLine,
  TextEditorLineDisplay
}
