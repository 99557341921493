import React from 'react'

// Js
import { setStyleValue } from './js/setDivStyle'


const BtnElement = (props) => {
  const { name, style, className, disabled, mode, classNameActive, active } = props

  return (
    <div className={`${className || ''} ${disabled ? 'disabled-jsx' : ''} btn-element-jsx`} style={{ userSelect: 'none', position: 'relative' }}>
      { props.children }

      <style jsx>{`
        .disabled-jsx {
          background-color: #f2f2f2 !important;
          color: #ddd !important;
          border: 1px solid #eee !important;
          box-shadow: none !important;
          cursor: not-allowed !important;
        }

        .btn-element-jsx {
          ${setStyleValue({ name, style, mode })}
        }
        .btn-element-jsx:hover {
          ${setStyleValue({ name: `${name}-hover`, style, mode })}
        }
        .btn-element-jsx:active {
          ${setStyleValue({ name: `${name}-active-click`, style, mode })}
        }
        .active-jsx {
          ${setStyleValue({ name: `${name}-active`, style, mode })}
        }

        .btn-element-jsx > :global(i.material-icons) {
          font-size: ${style[`${name}-icon-size`]} !important;
          margin: ${style[`${name}-icon-margin`]} !important;
          color: ${style[`${name}-icon-color`]};
        }

        @media (min-width: 1500px) {
          .btn-element-jsx {
            ${setStyleValue({ name, style, vw: true, mode })}
          }

          .btn-element-jsx > :global(i.material-icons) {
            font-size: ${style[`${name}-icon-size-vw`]} !important;
            margin: ${style[`${name}-icon-margin-vw`]} !important;
          }
        }

        @media (min-width: 601px) and (max-width: 1024px) {
          .btn-element-jsx {
            ${setStyleValue({ device: 'tablet', name, style, mode })}
          }
        }

        @media (min-width: 50px) and (max-width: 600px) {
          .btn-element-jsx {
            ${setStyleValue({ device: 'mobile', name, style, mode })}
          }
        }
      `}</style>
    </div>
  )
}

export default BtnElement
