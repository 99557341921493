import { Dispatch } from 'react'
import { addDataOne, addsDatasMany, updateDataOne, removeDataOne } from 'core/utils/reduxManage/actions'
import { generateTypeName, generateTypeFunctions } from 'core/utils/reduxManage/generateTypes'
import { TypeQuery, TypeQueryRemove, TypeQueryUpdate } from 'core/utils/reduxManage/types'


// Export Constants
const NAME_TYPE = ['CONVERSATION_MESSAGES', 'CONVERSATION_MESSAGE']
const name = "conversation-messages"

// Type Names and Type Functions
export const {
  ADD_ONE,
  ADD_REPLACE_ONE,
  ADDS_MANY,
  ADDS_MANY_MORE,
  UPDATE_ONE,
  REMOVE_ONE
} = generateTypeName(NAME_TYPE)

export const {
  add_one,
  add_replace_one,
  adds_many,
  adds_many_more,
  update_one,
  remove_one
} = generateTypeFunctions(NAME_TYPE)


// Export Actions
export const fetchQueryConversationMessageOne = (query: TypeQuery, params: any = {}) => (dispatch: Dispatch<any>) => {
  const { authName, businesses_id } = query

  return addDataOne({
    dispatch,
    params,
    api: `${name}-one/${authName}/${businesses_id}`,
    add_one
  })
}

export const fetchQueryConversationMessagesMany = (query: TypeQuery, params: any = {}) => (dispatch: Dispatch<any>) => {
  const { authName, businesses_id } = query

  return addsDatasMany({
    dispatch,
    params,
    api: `${name}-many/${authName}/${businesses_id}`,
    adds_many,
    adds_many_more
  })
}

export const createQueryConversationMessageOne = (query: TypeQuery, params: any = {}) => (dispatch: Dispatch<any>) => {
  const { authName, businesses_id } = query

  return addDataOne({
    dispatch,
    params,
    api: `${name}-one/${authName}/${businesses_id}/create`,
    add_one
  })
}

export const updateQueryConversationMessageOne = (query: TypeQueryUpdate, params: any = {}) => (dispatch: Dispatch<any>) => {
  const { authName, businesses_id, update_id } = query

  return updateDataOne({
    dispatch,
    params,
    api: `${name}-one/${authName}/${businesses_id}/${update_id}`,
    update_one,
    updateId: update_id
  })
}

export const removeConversationMessage = (query: TypeQueryRemove, params: any = {}) => (dispatch: Dispatch<any>) => {
  const { authName, businesses_id, remove_id } = query

  return removeDataOne({
    dispatch,
    params,
    api: `${name}/${authName}/${businesses_id}/${remove_id}`,
    remove_one,
    removeId: remove_id
  })
}





