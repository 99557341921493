import { fetchDatas, fetchData, createData, updateData, removeData } from '../reduxActions'
import { generateTypeName, generateTypeFunctions } from '../reduxTypes'


// Export Constants
const NAME_TYPE = ['CARTS', 'CART']
const name = "carts"

// Type Names and Type Functions
export const { ADDS, ADDS_MORE, ADD, UPDATE, REMOVE } = generateTypeName(NAME_TYPE)
export const { adds, adds_more, add, update, remove } = generateTypeFunctions(NAME_TYPE)

// Actions
import { adds as addCustomers } from '../Customers/CustomersActions'

// Export Actions
export const fetchCartForUser = (dbProductsNo, businesses_id, params = {}) => dispatch => {
  const local_storage_users_id = localStorage.getItem('users_id')

  params.body = {
    local_storage_users_id
  }

  return fetchData({
    dispatch,
    params,
    api: `cart-for-user/${dbProductsNo}/${businesses_id}`,
    add: add,
    addRefs: [
      { element: 'customers_id', adds: addCustomers }
    ]
  })
}

export const createCartForUser = (dbProductsNo, businesses_id, params) => dispatch => {
  const local_storage_users_id = localStorage.getItem('users_id')
  params.body.local_storage_users_id = local_storage_users_id

  return createData({
    dispatch,
    params,
    api: `cart-for-user/${dbProductsNo}/${businesses_id}/create`,
    add: add
  })
}

export const updateCartForUser = (dbProductsNo, businesses_id, _id, params) => dispatch => {
  const local_storage_users_id = localStorage.getItem('users_id')
  params.body.local_storage_users_id = local_storage_users_id

  return updateData({
    dispatch,
    params,
    api: `cart-for-user/${dbProductsNo}/${businesses_id}`,
    update: update,
    updateId: _id
  })
}

export const removeCart = (dbProductsNo, businesses_id, _id, params) => dispatch => {
  return removeData({
    dispatch,
    params,
    api: `${name}/${dbProductsNo}/${businesses_id}/${_id}`,
    remove: remove,
    deleteId: _id
  })
}
