import React from 'react'
import { connect } from 'react-redux'

// Components Editor
import BlockStyle from '../components/BlockStyle'

// Components Local
import Line from './components/Line'


const TextEditorLineDisplay = (props) => {
  const { content } = props
  const line = content.line && JSON.parse(content.line)
  const style = line && line.style ? JSON.parse(line.style) : {}
  const className = line && line.class ? JSON.parse(line.class) : []

  return (
    <BlockStyle {...props} style={style} className={className}>
      <Line style={style} />
    </BlockStyle>
  )
}

export default connect()(TextEditorLineDisplay)
