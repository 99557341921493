import { lazy } from 'react'
import suspenseComponent from 'utils/suspenseComponent'

// Routes
import OrdersMenuRoutes from 'client/routes/dashboard/Businesses/routes/OrdersRoutes/routes/OrdersMenuRoutes'

import CustomerContainer from 'client/containers/websites/Customer/containers/CustomerContainer'
import CustomerMenuContainer from 'client/containers/websites/Customer/containers/CustomerMenuContainer'
import OrdersMenuContainer from 'client/containers/websites/Customer/containers/OrdersMenuContainer'

import SiteDisplayContainer from 'client/containers/websites/Sites/containers/SiteDisplayContainer'

/*import ProfilePage from 'client/containers/websites/Customer/pages/ProfilePage'
import PhotosPage from 'client/containers/websites/Customer/pages/PhotosPage'
import AddressesPage from 'client/containers/websites/Customer/pages/AddressesPage'
import CouponsPage from 'client/containers/websites/Customer/pages/CouponsPage'
import FavoritesPage from 'client/containers/websites/Customer/pages/FavoritesPage'
import ReviewsPage from 'client/containers/websites/Customer/pages/ReviewsPage'*/

// Component Pages
const ProfilePage = lazy(() => import('client/containers/websites/Customer/pages/ProfilePage'))
const PhotosPage = lazy(() => import('client/containers/websites/Customer/pages/PhotosPage'))
const AddressesPage = lazy(() => import('client/containers/websites/Customer/pages/AddressesPage'))
const CouponsPage = lazy(() => import('client/containers/websites/Customer/pages/CouponsPage'))
const FavoritesPage = lazy(() => import('client/containers/websites/Customer/pages/FavoritesPage'))
const ReviewsPage = lazy(() => import('client/containers/websites/Customer/pages/ReviewsPage'))
const TeamPage = lazy(() => import('client/containers/websites/Customer/pages/TeamPage'))



const CustomerRoutes = [
  {
    path: '/:lang(th|en)?/customer',
    component: SiteDisplayContainer,
    params: { useStatic: true },
    routes: [
      {
        path: '/:lang(th|en)?/customer',
        component: CustomerContainer,
        routes: [
          {
            path: '/:lang(th|en)?/customer/orders',
            component: OrdersMenuContainer,
            routes: OrdersMenuRoutes({ pathStart: '/customer/orders', pathAllIndex: true, forUser: true })
          },
          {
            path: '/:lang(th|en)?/customer/affiliate',
            component: OrdersMenuContainer,
            params: { pathStart: '/customer/affiliate', affiliate: true },
            routes: OrdersMenuRoutes({ pathStart: '/customer/affiliate', pathAllIndex: true, forUser: true })
          },
          {
            path: '/:lang(th|en)?/customer/favorites',
            exact: true,
            component: suspenseComponent(FavoritesPage)
          },
          {
            path: '/:lang(th|en)?/customer/reviews',
            exact: true,
            component: suspenseComponent(ReviewsPage)
          },
          {
            path: '/:lang(th|en)?/customer/profile',
            component: CustomerMenuContainer,
            routes: [
              {
                path: '/:lang(th|en)?/customer/profile',
                exact: true,
                component: suspenseComponent(ProfilePage)
              },
              {
                path: '/:lang(th|en)?/customer/profile/photos',
                exact: true,
                component: suspenseComponent(PhotosPage)
              },
              {
                path: '/:lang(th|en)?/customer/profile/addresses',
                exact: true,
                component: suspenseComponent(AddressesPage)
              },
              {
                path: '/:lang(th|en)?/customer/profile/coupons',
                exact: true,
                component: suspenseComponent(CouponsPage)
              },
              {
                path: '/:lang(th|en)?/customer/profile/team',
                exact: true,
                component: suspenseComponent(TeamPage)
              }
            ]
          },
        ]
      }
    ]
  },
  /*{
    path: '/customer',
    component: CustomerContainer,
    routes: [
      {
        path: '/customer/orders',
        component: OrdersMenuContainer,
        routes: OrdersMenuRoutes({ pathStart: '/customer/orders', pathAllIndex: true, forUser: true })
      },
      {
        path: '/customer/favorites',
        exact: true,
        component: suspenseComponent(FavoritesPage)
      },
      {
        path: '/customer/reviews',
        exact: true,
        component: suspenseComponent(ReviewsPage)
      },
      {
        path: '/customer/profile',
        component: CustomerMenuContainer,
        routes: [
          {
            path: '/customer/profile',
            exact: true,
            component: suspenseComponent(ProfilePage)
          },
          {
            path: '/customer/profile/photos',
            exact: true,
            component: suspenseComponent(PhotosPage)
          },
          {
            path: '/customer/profile/addresses',
            exact: true,
            component: suspenseComponent(AddressesPage)
          },
          {
            path: '/customer/profile/coupons',
            exact: true,
            component: suspenseComponent(CouponsPage)
          }
        ]
      },
    ]
  }*/
]

export default CustomerRoutes
