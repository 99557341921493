import { getData } from '../../ApiSettings/js/apiSettings'


export const checkCondition = ({ style, mode, apiData, templateData, dataStore, props }) => {
  //const conditionOpen = style['conditionOpen']
  const {
    conditionOpen,
    conditionDataType,
    conditionDataStoreName,
    conditionKey,
    conditionOperator,
    conditionValue,
    conditionValueType,
    conditionKeyAdvanced
  } = style || {}

  let show

  if (conditionOpen) {

    if (mode) {
      show = true

    } else {
      if ((!conditionKeyAdvanced && conditionDataType && conditionKey && conditionOperator && conditionValueType) ||
          (conditionKeyAdvanced && conditionDataType && conditionKey) ||
          (conditionDataType === 'lang' && conditionOperator && conditionValue)
         ) {


        const condition = {
          dataType: conditionDataType,
          dataStoreName: conditionDataStoreName
        }

        const data = getData({ condition, apiData, templateData, dataStore, props })


        if (data) {
          if (conditionKeyAdvanced) {
            if (eval(conditionKey)) {
              show = true
            } else {
              show = false
            }

          } else {
            let keyFull = conditionKey

            if (conditionDataType === 'lang') {
              keyFull = 'lang'
            }

            const dataKey = getDataKey({ keyFull, data })

            let value

            if (conditionValueType === 'boolean') {
              value = (conditionValue === 'true')
            } else if (conditionValueType === 'number') {
              value = parseInt(conditionValue)
            } else if (conditionValueType === 'undefined') {
              value = 'undefined'
            } else {
              value = conditionValue
            }

            if (dataKey !== undefined && value !== undefined) {
              if (conditionOperator === '==') {
                if (value === 'undefined') {
                  if (dataKey === '') show = true
                } else {
                  if (dataKey === value) show = true
                }

              } else if (conditionOperator === '!=') {
                if (value === 'undefined') {
                  if (dataKey !== '') show = true
                } else {
                  if (dataKey !== value) show = true
                }

              } else if (conditionOperator === '<') {
                if (dataKey < value) show = true
              } else if (conditionOperator === '<=') {
                if (dataKey <= value) show = true
              } else if (conditionOperator === '>') {
                if (dataKey > value) show = true
              } else if (conditionOperator === '>=') {
                if (dataKey >= value) show = true
              }
            }
          }
        } else {
          show = true
        }

      } else {
        show = true
      }
    }
  } else {
    show = true
  }

  return show
}

export const getDataKey = ({ keyFull, data }) => {
  let value

  if (keyFull && data) {
    const keySplit = keyFull.split('.')

    if (keySplit.length === 0) {
      if (data[keyFull]) {
        value = data[keyFull]
      }

    } else {
      let newValue = data

      keySplit.map(keyValue => {
        if (newValue[keyValue] !== undefined) {
          newValue = newValue[keyValue]
          value = newValue
        } else {
          value = ''
        }
      })
    }
  }

  return value
}
