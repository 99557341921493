import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

// Components Global
import { Btn, Text, MaterialIcon } from '../Btn/Btn'
import { TextMiddle } from '../Text'
import { BtnModalOpen } from '../Modals/BtnModal'
import BtnSubmit from '../button/BtnSubmit'

// css
import css from './css/noData.css'

const FetchData = (props) => {
  const ComponentItemsNone = props.componentItemsNone
  const notDatasSearch = (
    <div className="color-error center pd-10">
      <i className="material-icons font-3-0">search</i>
      <p className="font-1-2">ไม่มีรายการที่ค้นหา</p>
    </div>
  )

  return (
    <Fragment>
      { props.length === 0 ?

        <Fragment>
          { ComponentItemsNone ?
            <ComponentItemsNone />
          :
            <div className={`${css.box} ${props.className}`} style={props.style}>
              <TextMiddle height="100%" width="100%">

                <MaterialIcon
                  theme={props.theme}
                  className={css.icon} icon={props.icon}
                  style={{ color: props.iconColor }}
                />

                <Text theme={props.theme} className={props.textClassName} html={props.msg}>{props.msg}</Text>

                { props.btnText && props.modalId &&
                  <Btn theme={props.theme} modalId={props.modalId} style={{ marginTop: '20px' }}>
                    <i className="material-icons left">add</i> {props.btnText}
                  </Btn>
                }

                { props.modal &&
                  <BtnModalOpen id={props.modal.id}>
                    <a className={`mg-top-20 pd-right-20 waves-effect waves-light btn btn-shadow-none ${props.modal.className}`}>
                      <i className="material-icons left">{props.modal.icon || 'add'}</i> {props.modal.text || props.modal.name }
                    </a>
                  </BtnModalOpen>
                }

                { props.btnText && props.link &&
                  <Link to={props.link} className="waves-effect waves-light btn" style={{ marginTop: '30px' }}>
                    <i className="material-icons left">reply</i> {props.btnText}
                  </Link>
                }

                { props.btnSubmit &&
                  <BtnSubmit
                    onClick={props.btnSubmit.onClick}
                    loadingId={props.btnSubmit.loadingId}
                    icon={props.btnSubmit.icon}
                    iconClassName={props.btnSubmit.iconClassName}
                    text={props.btnSubmit.text}
                    className={`mg-top-20 btn btn-shadow-none ${props.btnSubmit.className}`}
                  />
                }

              </TextMiddle>
            </div>
          }
        </Fragment>
      :
        <Fragment>
          { props.children }

          { props.itemsSearch && props.itemsSearch.length === 0 && notDatasSearch }
        </Fragment>
      }
    </Fragment>
  )
}

export default FetchData
