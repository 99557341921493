import { updateProgress } from '../Progresses/ProgressesActions'

// Export Constants
/*export const DEFAULT = 'DEFAULT'
export const MODAL = 'MODAL'
export const MANAGEMENT = 'MANAGEMENT'
export const LAST_DATA = 'LAST_DATA'*/

export const LOADING = 'LOADING'
export const SUCCESS = 'SUCCESS'
export const TOAST = 'TOAST'
export const ALERT = 'ALERT'
export const SET_LANG = 'SET_LANG'

export const HTTP_STATUS = 'HTTP_STATUS'
export const ADD_TRANSITION = 'ADD_TRANSITION'

export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'

export const OPEN_FIELD = 'OPEN_FIELD'
export const CLOSE_FIELD = 'CLOSE_FIELD'

export const ADD_FORM = 'ADD_FORM'
export const REMOVE_FORM = 'REMOVE_FORM'

export const ADD_LOAD = 'ADD_LOAD'
export const REMOVE_LOAD = 'REMOVE_LOAD'

export const OPEN_CONFIRM = 'OPEN_CONFIRM'
export const CLOSE_CONFIRM = 'CLOSE_CONFIRM'

export const ADD_FILE_PROGRESS = 'ADD_FILE_PROGRESS'
export const UPDATE_FILE_PROGRESS = 'UPDATE_FILE_PROGRESS'
export const REMOVE_FILE_PROGRESS = 'REMOVE_FILE_PROGRESS'

export const ADD_PARAM = 'ADD_PARAM'

// Export type
//export const setDefault = () => ({ type: DEFAULT })
//export const modal = status => ({ type: MODAL, status })
//export const management = management => ({ type: MANAGEMENT, management })
//export const lastData = data => ({ type: LAST_DATA, data })

export const setLang = lang => ({ type: SET_LANG, lang })
export const loading = loading => ({ type: LOADING, loading })
export const success = success => ({ type: SUCCESS, success })
export const toast = (message, msgType='success') => ({ type: TOAST, message, msgType })
export const alertModal = data => ({ type: ALERT, data })
export const httpStatus = code => ({ type: HTTP_STATUS, code })
export const addTransition = (params) => ({ type: ADD_TRANSITION, params })

export const openModal = (id, params=null) => ({ type: OPEN_MODAL, id, params })
export const closeModal = id => ({ type: CLOSE_MODAL, id })

export const openField = id => ({ type: OPEN_FIELD, id })
export const closeField = id => ({ type: CLOSE_FIELD, id })

export const addForm = (id, form) => ({ type: ADD_FORM, id, form })
export const removeForm = id => ({ type: REMOVE_FORM, id })

export const addLoad = id => ({ type: ADD_LOAD, id })
export const removeLoad = id => ({ type: REMOVE_LOAD, id })

export const openConfirm = (id, params) => {
  if (params) {
    if (!params.title) params.title = `${params.label}`
    if (!params.message) params.message = `คุณต้องการ${params.label}นี้`
    if (!params.icon) params.icon = 'code'
    if (!params.confirmLabel) params.confirmLabel = params.label
    if (!params.cancelLabel) params.cancelLabel = 'ยกเลิก'
  }

  return { type: OPEN_CONFIRM, id, params }
}

export const closeConfirm = id => ({ type: CLOSE_CONFIRM, id })

export const addParam = (name, value) => ({ type: ADD_PARAM, name, value })


// Export Actions
export const toastShow = (message, msgType='success') => dispatch => {
  dispatch(toast({ show: true, message, msgType }))
}

export const toastHide = () => dispatch => {
  dispatch(toast({ show: false, message: null }))
}

export const errorAlert = (res) => dispatch => {
  dispatch(alertModal({ show: true, type: 'error', code: res.code }))
}

export const successAlert = message => dispatch => {
  dispatch(alertModal({ show: true, type: 'success', message: message }))
}

export const closeAlert = () => dispatch => {
  dispatch(alertModal({ show: false, type: null, message: null, link: null }))
}

export const convertUrlRedirect = (dataRedirect, res) => {
  const { url, resName } = dataRedirect
  const params = []

  const urlSplit = url.split('/')

  urlSplit.map(value => {
    if (value.search(':') !== -1) {
      const name = value.replace(':', '')
      params.push({
        name,
        param: value,
        value: res[resName][name]
      })
    }
  })

  let newUrl = url

  params.map(data => {
    newUrl = newUrl.replace(`${data.param}`, data.value)
  })

  return newUrl
}


export const checkAppActions = (dispatch, params, status, res="") => {
  if (status === 'start') {
    if (params) {
      if (params.loadId) dispatch(addLoad(params.loadId))
      if (params.stateLoading) params.stateLoading.setLoading(true)
    }

  } else if (status === 'success') {
    if (params) {
      const props = params.props
      const history = props && props.history

      if (params.fieldId) dispatch(closeField(params.fieldId))
      if (params.modalId) dispatch(closeModal(params.modalId))
      if (params.confirmId) dispatch(closeConfirm(params.confirmId))
      if (params.toastMsg) dispatch(toastShow(params.toastMsg))
      if (params.loadId) dispatch(removeLoad(params.loadId))
      if (params.stateLoading) params.stateLoading.setLoading(false)
      if (params.progressId) dispatch(updateProgress(params.progressId, true))
      if (params.httpStatus && res.code) dispatch(httpStatus(res.code))

      if (history && params.dataRedirect && res) {
        const url = convertUrlRedirect(params.dataRedirect, res)
        history.push(`${url}`)
      }

      if (params.closeWidgetAll) {
        const app = params.closeWidgetAll
        const { closeModalState } = app || {}
        const modals = app.modals

        if (modals.length !== 0) {
          modals.map(modal => {
            setTimeout(() => {
              dispatch(closeModalState(modal.id))
            }, 0);
          })
        }
      }

      if (history && params.redirect && res && res.success) {
        if (res.data) {
          const coll = params.redirect.coll
          const url = params.redirect.url
          const element = params.redirect.element
          const search = params.redirect.search || ''

          let dataElement

          if (coll && element) {
            dataElement = res[coll][element]
          } else if (!coll && element) {
            dataElement = res.data[element]
          }

          if (url && element) {
            history.push(`${url}/${dataElement}${search}`)
          } else if (!url && element) {
            history.push(`/${dataElement}${search}`)
          } else {
            history.push(`${params.redirect.url}${search}`)
          }
        }
      } else if (history && params.redirect) {
        history.push(`${params.redirect.url}`)
      }
    }

  } else if (status === 'error') {
    if (params) {
      if (res) {
        if (params.errorAlert) dispatch(errorAlert(res))
      }

      if (params.loadId) dispatch(removeLoad(params.loadId))
      if (params.stateLoading) params.stateLoading.setLoading(false)
      if (params.httpStatus && res.code) dispatch(httpStatus(res.code))
    }
  }
}
