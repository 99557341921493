import React from 'react'
import { connect } from 'react-redux'

// Components
import ModalDefault from '../ModalDefault'

// Js
import { setStyleValue } from '../../TextEditor/components/ElementStyles/components/js/setDivStyle'

// Redux Actions
import { closeModal } from '../../../redux/App/AppActions'

// css
import css from './css/modalEditor.css'


const ModalEditor = (props) => {
  const name = "modal"

  let { style } = props
  style = style || {}

  const styleBox = {
    backgroundColor: style['modal-background-color'] ? style['modal-background-color'] : '#fff',
    margin: '0 auto'
  }

  const modalClose = () => {
    props.dispatch(closeModal(props.id))
  }

  return (
    <ModalDefault editor id={props.id} width={props.width} widthVw={props.widthVw}>
      <div className={`z-depth-1 modal-editor-jsx ${css.box}`} style={styleBox}>

        { !style['modal-hidden-btn-close'] &&
          <div className={css.boxBtnClose}>
            <div onClick={modalClose} className={`${css.btnClose}`}>
              <i className="material-icons black-text">close</i>
            </div>
          </div>
        }

        { props.children }

        <style jsx>{`
          .modal-editor-jsx {
            /*width: 100% !important;*/
            overflow: hidden;

            ${setStyleValue({ name, style })}
          }

          @media (min-width: 1500px) {
            .modal-editor-jsx  {
              ${setStyleValue({ name, style, vw: true })}
            }
          }

          @media (min-width: 601px) and (max-width: 1024px) {
            .modal-editor-jsx {
              ${setStyleValue({ device: 'tablet', name, style })}
            }
          }

          @media (min-width: 50px) and (max-width: 600px) {
            .modal-editor-jsx {
              ${setStyleValue({ device: 'mobile', name, style })}
            }
          }
        `}</style>
      </div>
    </ModalDefault>
  )
}

export default connect()(ModalEditor)
