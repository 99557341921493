import React, { Component, Fragment } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import AliceCarousel from 'react-alice-carousel'

import { ObjectsEqual, ChildrenEqual } from './utils'

// Js
import { setStyleValue } from '../TextEditor/components/ElementStyles/components/js/setDivStyle'

// css
import css from './css/carousel.css'


class Carousel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
      open: true
    }
    this.slider = null
  }

  componentDidMount() {
    this.mounted = true
    if (this.props.onRef) this.props.onRef(this)

    this.reload()
  }

  componentWillUnmount() {
    this.mounted = false
    if (this.props.onRef) this.props.onRef(null)
  }

  componentDidUpdate(prevProps, nextState) {
    if (!ObjectsEqual(prevProps.settings, this.props.settings) ||
        !ChildrenEqual(prevProps.children, this.props.children)) {

      this.reload()
    }
  }

  /*shouldComponentUpdate() {
    return false
  }*/

  /*componentWillReceiveProps(nextProps) {
    if (!ObjectsEqual(nextProps.settings, this.props.settings) ||
        !ChildrenEqual(nextProps.children, this.props.children)) {

      this.reload()
    }
  }*/

  responsive = this.props.settings.responsive

  reload = () => {
    this.setState({ loading : true }, () => {
      setTimeout(() => {
        this.setState({ loading: false })
        this.responsive = this.props.settings.responsive
      }, 100)
    })
  }

  handleOnDragStart = (e) => {
    e.preventDefault()
    this.setLink(true)
  }

  handleOnSlideChange = (event) => {
    const { itemsInSlide, item } = event
    this.mounted && this.setState({ itemsInSlide, currentIndex: item })

    this.setLink(false)
  }

  setLink = (block) => {
    const { id } = this.props
    const elements = document.querySelectorAll(`#carousel-${id} a`)
    const linkPushElements = document.querySelectorAll(`#carousel-${id} #link-history-push`)

    if (elements && elements.length !== 0) {
      for (let i = 0; i <= elements.length; i++) {
        if (elements[i]) {
          if (block) {
            elements[i].setAttribute('style', 'pointer-events: none')
          } else {
            elements[i].setAttribute('style', 'pointer-events: auto')
          }
        }
      }
    }

    if (linkPushElements && linkPushElements.length !== 0) {
      for (let i = 0; i <= linkPushElements.length; i++) {
        if (linkPushElements[i]) {
          if (block) {
            linkPushElements[i].setAttribute('style', 'pointer-events: none')
          } else {
            linkPushElements[i].setAttribute('style', 'pointer-events: auto')
            linkPushElements[i].setAttribute('style', 'cursor: pointer')
          }
        }
      }
    }
  }

  render() {
    const { id, settings, style, children } = this.props
    const { loading } = this.state
    const {
      mouseDragEnabled,
      autoPlay,
      autoPlayInterval,
      autoPlayDirection,
      infinite,
      dotsDisabled,
      buttonsDisabled,
      buttonsPosition,
      buttonsFormat,
      dotsFormat
    } = settings

    return (
      <div id={`carousel-${id}`} className={`carousel-jsx carousel-${id} carousel-${id}-jsx`}>
        <div className={`${css.carousel}`}>
          { !loading && children && children.length !== 0 &&
            <Fragment>

              { this.slider && buttonsFormat === 'outside' &&
                <Fragment>
                  { (buttonsPosition === 'top-left' || buttonsPosition === 'top-right') &&
                    <div
                      style={{
                        textAlign: buttonsPosition === 'top-left' && 'left',
                        textAlign: buttonsPosition === 'top-right' && 'right',
                      }}>

                      <BtnPrev style={style} onClick={this.slider._slidePrev} />
                      <BtnNext style={style} onClick={this.slider._slideNext} />
                    </div>
                  }
                </Fragment>
              }

              <AliceCarousel
                ref={el => this.slider = el}
                mouseDragEnabled={mouseDragEnabled || true}
                autoPlay={autoPlay || false}
                autoPlayInterval={autoPlayInterval}
                autoPlayDirection="ltr"
                responsive={this.responsive}
                dotsDisabled={dotsDisabled || false}
                buttonsDisabled={true}
                infinite={infinite || false}
                onInitialized={this.handleOnSlideChange}
                onSlideChanged={this.handleOnSlideChange}
                onResized={this.handleOnSlideChange}>

                { children.map((children, i) => {
                  return (
                    <div key={i} draggable onDragStart={this.handleOnDragStart}>
                      { children }
                    </div>
                  )
                }) }
              </AliceCarousel>

              { this.slider && buttonsFormat === 'outside' &&
                <Fragment>
                  { (buttonsPosition === 'bottom-left' || buttonsPosition === 'bottom-right') &&
                    <div
                      style={{
                        textAlign: buttonsPosition === 'bottom-left' && 'left',
                        textAlign: buttonsPosition === 'bottom-right' && 'right',
                      }}>

                      <BtnPrev style={style} onClick={this.slider._slidePrev} />
                      <BtnNext style={style} onClick={this.slider._slideNext} />
                    </div>
                  }
                </Fragment>
              }


              { (this.slider && buttonsDisabled !== true) &&
                <Fragment>
                  { buttonsPosition === 'middle' ?
                    <Fragment>
                      <BtnPrev middle style={style} onClick={this.slider._slidePrev} />
                      <BtnNext middle style={style} onClick={this.slider._slideNext} />
                    </Fragment>
                  :
                    <Fragment>
                      { buttonsFormat === 'inside' &&
                        <div
                          className={css.boxNavIn}
                          style={{
                            top: (buttonsPosition === 'top-left' || buttonsPosition === 'top-right')  && '0',
                            bottom: (buttonsPosition === 'bottom-left' || buttonsPosition === 'bottom-right') && '0',
                            left: (buttonsPosition === 'top-left' || buttonsPosition === 'bottom-left') && '0',
                            right: (buttonsPosition === 'top-right' || buttonsPosition === 'bottom-right') && '0'
                          }}>

                          <BtnPrev inside style={style} position={buttonsPosition} onClick={this.slider._slidePrev} />
                          <BtnNext inside style={style} position={buttonsPosition} onClick={this.slider._slideNext} />
                        </div>
                      }
                    </Fragment>
                  }
                </Fragment>
              }

            </Fragment>
          }
        </div>

        {/* this.slider &&
          <div>
            { children.map((item, i) => {
              return (
                <a className="btn" key={i} onClick={() => this.slider._onDotClick(i)}> {i+1} </a>
              )
            }) }
          </div>
          */}

        <style jsx global>{`
          .carousel-${id}-jsx ul.alice-carousel__dots {
            margin: 0;
            position: absolute;
            left: 0;
            right: 0;
            bottom: ${dotsFormat === 'inside' ? '0' : 'unset'} !important;
          }
          .carousel-${id}-jsx .alice-carousel__dots-item {
            ${setStyleValue({ name: 'dots', style })}
          }
          .carousel-${id}-jsx .alice-carousel__dots-item:hover {
            ${setStyleValue({ name: 'dots-hover', style })}
          }
          .carousel-${id}-jsx .alice-carousel__dots-item.__active {
            ${setStyleValue({ name: 'dots-active', style })}
          }

          @media (min-width: 1500px) {
            .carousel-${id}-jsx .alice-carousel__dots-item {
              ${setStyleValue({ name: 'dots', style, vw: true })}
            }
            .carousel-${id}-jsx .alice-carousel__dots-item:hover {
              ${setStyleValue({ name: 'dots-hover', style, vw: true })}
            }
            .carousel-${id}-jsx .alice-carousel__dots-item.__active {
              ${setStyleValue({ name: 'dots-active', style, vw: true })}
            }
          }

          @media (min-width: 601px) and (max-width: 1024px) {
            .carousel-${id}-jsx .alice-carousel__dots-item {
              ${setStyleValue({ device: 'tablet', name: 'dots', style })}
            }
          }

          @media (min-width: 50px) and (max-width: 600px) {
            .carousel-${id}-jsx .alice-carousel__dots-item {
              ${setStyleValue({ device: 'mobile', name: 'dots', style })}
            }
          }
        `}</style>
      </div>
    )
  }
}


const Btn = (props) => {
  const { icon, onClick, style, inside } = props
  const name = 'buttons'

  return (
    <a className={`${css.btnNav} btn-jsx`} onClick={onClick}>
      <i className="material-icons">{icon}</i>

      <style jsx>{`
        .btn-jsx {
          ${setStyleValue({ name, style })}

          float: left;
        }
        .btn-jsx:hover {
          ${setStyleValue({ name: `${name}-hover`, style })}
        }
        .btn-jsx:active {
          ${setStyleValue({ name: `${name}-active-click`, style })}
        }
        .btn-jsx > :global(i.material-icons) {
          font-size: ${style[`${name}-icon-size`]} !important;
          color: ${style[`${name}-icon-color`]};
          margin: ${style[`${name}-icon-margin`]} !important;
        }

        @media (min-width: 1500px) {
          .btn-jsx {
            ${setStyleValue({ name, style, vw: true })}
          }

          .btn-jsx > :global(i.material-icons) {
            font-size: ${style[`${name}-icon-size-vw`]} !important;
            margin: ${style[`${name}-icon-margin-vw`]} !important;
          }
        }

        @media (min-width: 601px) and (max-width: 1024px) {
          .btn-jsx {
            ${setStyleValue({ device: 'tablet', name, style })}
          }
        }

        @media (min-width: 50px) and (max-width: 600px) {
          .btn-jsx {
            ${setStyleValue({ device: 'mobile', name, style })}
          }
        }
      `}</style>
    </a>
  )
}

const BtnPrev = (props) => {
  const { middle, inside, style } = props
  const cssStyle = {
    opacity: style['buttonsShowForever'] && 1,
    visibility: style['buttonsShowForever'] && 'visible',
  }

  return (
    <Fragment>
      { middle ?
        <div className={css.boxMiddlePrev} style={cssStyle}>
          <div className="box-middle height-full">
            <div>
              <Btn {...props} icon="keyboard_arrow_left" />
            </div>
          </div>
        </div>
      :
        <Btn {...props} icon="keyboard_arrow_left" />
      }
    </Fragment>
  )
}

const BtnNext = (props) => {
  const { middle, style } = props
  const cssStyle = {
    opacity: style['buttonsShowForever'] && 1,
    visibility: style['buttonsShowForever'] && 'visible'
  }

  return (
    <Fragment>
      { middle ?
        <div className={css.boxMiddleNext} style={cssStyle}>
          <div className="box-middle height-full">
            <div>
              <Btn {...props} icon="keyboard_arrow_right" />
            </div>
          </div>
        </div>
      :
        <Btn {...props} icon="keyboard_arrow_right" />
      }
    </Fragment>
  )
}

Carousel.defaultProps = {
  settings: {
    mouseDragEnabled: true,
    autoPlay: false,
    autoPlayInterval: 3000,
    autoPlayDirection: "ltr",
    dotsDisabled: false,
    buttonsDisabled: true,
    buttonsPosition: 'middle'
  }
}

const ccc = (props) => {
  console.log('dddd')
  return (
    <div>ddddddd</div>
  )
}

export default React.memo(Carousel)
