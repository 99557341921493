import React from 'react'
import { lazy } from 'react'
import { connect } from 'react-redux'
import ReactSuspense from '../../../util/ReactSuspense'

// Components Editor
import BlockStyle from '../components/BlockStyle'

// Redux
import { getLoginStatus } from '../../../redux/Auth/AuthFunctions'

// Components Local
const ShoppingCart = lazy(() => import('./components/ShoppingCart'))


const TextEditorShoppingCartDisplay = (props) => {
  const { content, templateData, auth, loginStatus, cart, mode, match, action } = props
  const shoppingCartManage = content.shoppingCartManage && JSON.parse(content.shoppingCartManage)
  const style = shoppingCartManage && shoppingCartManage.style ? JSON.parse(shoppingCartManage.style) : {}
  const className = shoppingCartManage && shoppingCartManage.class ? JSON.parse(shoppingCartManage.class) : []

  return (
    <ReactSuspense>
      <BlockStyle {...props} style={style} className={className}>
        <ShoppingCart
          display={mode}
          cart={cart}
          auth={auth}
          loginStatus={loginStatus}
          product={templateData}
          style={style}
          match={match}
          action={action}
          content={content}
        />
      </BlockStyle>
    </ReactSuspense>
  )
}

const mapStateToProps = (store, props) => {
  return {
    auth: store.auth,
    cart: store.carts.data[0],
    loginStatus: getLoginStatus(store.auth)
  }
}

export default connect(mapStateToProps)(TextEditorShoppingCartDisplay)
