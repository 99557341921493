import React from 'react'

// css
import css from './css/icon.css'

const Icon = (props) => {
  const { style, icon } = props
  const { type, name } = icon

  return (
    <div className={`icon-jsx ${css.boxIcon}`}>

      { icon && type === 'material-icons' &&
        <i className={`material-icons`}>
          {name}
        </i>
      }

      { icon && type === 'font-awesome' &&
        <i className={`${name}`}></i>
      }

      <style jsx>{`
        .icon-jsx {
          background-color: ${style['icon-box-background-color']};
          border-radius: ${style['icon-box-border-radius']};
          border-width: ${style['icon-box-border-width']};
          border-color: ${style['icon-box-border-color']};
          border-style: ${style['icon-box-border-style']};
          padding: ${style['icon-box-padding']};
        }
        .icon-jsx > i {
          color: ${style['icon-color']};
          font-size: ${style['icon-font-size']};
        }

        @media (min-width: 1500px) {
          .icon-jsx {
            padding: ${style['icon-box-padding-vw']} !important;
          }
          .icon-jsx > i {
            font-size: ${style['icon-font-size-vw']};
          }
        }
      `}</style>
    </div>
  )
}

export default Icon
