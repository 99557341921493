import React, { Component, Fragment } from 'react'
import config from '../../../../../config'
import LazyLoad from 'react-lazyload'

import {
  getBackgroundImage
} from '../../../../TextEditor/components/ElementStyles/components/js/setDivStyle'

// css
import css from './css/backgroundVideoDisplay.css'
import { getLazyLoadParams } from '../../../../../util/getLazyLoadParams'

const s3Url = config.app.s3.url
const s3UrlOriginal = config.app.s3.urlOriginal

class BackgroundImageDisplay extends Component {
  constructor(props) {
    super(props)
		this.state = {}
  }

  componentDidMount() {
    this.setFirstPath()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.images !== this.props.images) {
      this.setFirstPath()
    }
  }

  setFirstPath = () => {
    const { images } = this.props
    const image = images && images[0] && JSON.parse(images[0])
    const paths = image && image.paths && JSON.parse(image.paths)

    if (paths && paths.l) {
      this.setState({
        path: `url(${s3UrlOriginal}/${paths.o})`,
        opacityShow: image.opacityShow
      })
    } else {
      this.setState({ path: '' })
    }
  }

  render() {
    const { style, mode } = this.props
    const name = 'section'
    const lazyLoadParams = getLazyLoadParams(this.props)
    const background = style['section-background-image']

    const opacity = style['section-background-image-opacity']
    const backgroundColor = style['section-background-image-opacity-background-color'] || '#000'

    const opacityTablet = style['section-tablet-background-image-opacity']
    const backgroundColorTablet = style['section-tablet-background-image-opacity-background-color'] || '#000'

    const opacityMobile = style['section-mobile-background-image-opacity']
    const backgroundColorMobile = style['section-mobile-background-image-opacity-background-color'] || '#000'


    let section = (
      <div className={`section-bg-jsx ${css.backgroundImage}`}>

        <style jsx>{`
          .section-bg-jsx {
            ${getBackgroundImage({ name, style })}
          }
          .section-bg-jsx:after {
            opacity: ${opacity};
            background-color: ${opacity && backgroundColor};
          }

          @media (min-width: 601px) and (max-width: 1024px) {
            .section-bg-jsx {
              ${getBackgroundImage({ name: `${name}-tablet`, style })}
            }
            .section-bg-jsx:after {
              opacity: ${opacityTablet};
              background-color: ${opacityTablet && backgroundColorTablet};
            }
          }

          @media (min-width: 50px) and (max-width: 600px) {
            .section-bg-jsx {
              ${getBackgroundImage({ name: `${name}-mobile`, style, vw: '-vw' })}
            }
            .section-bg-jsx:after {
              opacity: ${opacityMobile};
              background-color: ${opacityMobile && backgroundColorMobile};
            }
          }
        `}</style>
      </div>
    )

    return (
      <Fragment>
        { background &&
          <Fragment>
            { mode ? section : <LazyLoad {...lazyLoadParams}>{ section }</LazyLoad> }
          </Fragment>
        }
      </Fragment>
    )
  }
}

export default BackgroundImageDisplay
