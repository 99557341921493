import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// JS
import { onSearchData } from './../InputSearch/js/InputSearchFunction'
import {
  getListNumer,
  showPagination,
  getTotalPage,
  getCurrentPage,
  getPageNumber
} from './js/Paginationfunction'

// util
import { getNumeral } from '../../util/getNumeral'

// CSS
import css from './css/pagination.css'
import { getURLSearchParams } from '../../util/getURLSearchParams'


class Pagination extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPageSearch: 1,
      itemsPerPage: this.props.itemsPerPage,
      showPage: this.props.showPage
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      const prevParams = getURLSearchParams(prevProps.location.search)
      const params = getURLSearchParams(this.props.location.search)
      const { currentPageName, currentPageSearchName } = this.getCurrentPageName(this.props)

      if (
          (prevParams[currentPageName] !== params[currentPageName]) ||
          (prevParams[currentPageSearchName] !== params[currentPageSearchName]) ||
          (JSON.stringify(prevParams.search) !== JSON.stringify(params.search))
        ) {

        this.getUrlParams(this.props.location.search)
      }
    }

    if (!this.props.notReloadForUrlChange) {
      if (prevProps.match.url !== this.props.match.url) {
        this.getUrlParams()
      }
    }

    if (this.props.dataReloadForUrlChange) {
      if (JSON.stringify(prevProps.dataReloadForUrlChange) !== JSON.stringify(this.props.dataReloadForUrlChange)) {
        this.getUrlParams(null, true)
      }
    }

    /*if (prevState.currentPage !== this.state.currentPage) {
      const params = getURLSearchParams(this.props.location.search)
      const { currentPageName, currentPageSearchName } = this.getCurrentPageName(this.props)

      if (params[currentPageName]) {
        if (params[currentPageName] !== JSON.stringify(this.state.currentPage)) {
          this.setState({ currentPage: parseInt(params[currentPageName]) || 1 }, () => {
            this.getUrlParams()
          })
        }
      }
    }*/

    // this.props.fetchAction
    if (prevProps.fetchAction.where !== this.props.fetchAction.where || prevProps.fetchAction.between !== this.props.fetchAction.between) {
      this.getUrlParams()
    }

    // for search
    if (JSON.stringify(prevProps.searchData) !== JSON.stringify(this.props.searchData)) {
      const searchData = this.props.searchData

      if (this.mounted) {
        this.setState({
          searchData: searchData,
          totalItemsSearch: searchData && searchData.count,
          totalPageSearch: Math.ceil(searchData && searchData.count / this.state.itemsPerPage)
        }, () => {
          getListNumer(this)
        })
      }
    }

    if (prevProps.foldersManage && this.props.foldersManage) {
      if (prevProps.match.params.folders_id !== this.props.match.params.folders_id) {
        this.getUrlParams()
      }
    }
  }

  componentDidMount() {
    this.mounted = true

    this.getUrlParams(this.props.location.search)

    if (this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentWillUnmount() {
    this.mounted = false

    if (this.props.onRef) {
      this.props.onRef(null)
    }
  }

  fetchItems = () => {
    this.getUrlParams()
  }

  fecthDatas = (searchValue=null) => {
    let fetch = this.props.fetchAction

    let c = getCurrentPage(this)


    if (searchValue === null) {
      let currentPage = this.state.currentPage
      let skip = currentPage > 0 ? ((currentPage-1)*this.props.itemsPerPage) : 0

      const body = {
        where: fetch.where,
        or: fetch.or,
        sort: fetch.sort,
        limit: this.props.itemsPerPage,
        skip: skip,
        countWhere: fetch.countWhere,
        countOr: fetch.countOr,
        payload: fetch.payload,
        between: fetch.between,
        groupBy: fetch.groupBy,
        unwind: fetch.unwind,
        aggregate: fetch.aggregate
      }

      if (!body.where) delete body.where
      if (!body.countWhere) delete body.countWhere
      if (!body.or) delete body.or
      if (!body.countOr) delete body.countOr
      if (!body.payload) delete body.payload
      if (!body.between) delete body.between
      if (!body.groupBy) delete body.groupBy
      if (!body.unwind) delete body.unwind
      if (!body.aggregate) delete body.aggregate

      let fecthFn
      let fecthParams = {
        body,
        loadId: !this.props.stateLoading && this.props.id,
        stateLoading: this.props.stateLoading,
        addGroupId: fetch.addGroupId,
        addGroupIdReplace: true,
        useDB: fetch.useDB
      }

      if (fetch.actionQuery) {
        fecthFn = () => fetch.action(fetch.actionQuery, fecthParams)

      } else if (fetch.authName) {
        fecthFn = () => fetch.action(fetch.authName, fetch.id, fecthParams)

      } else if (fetch.dbNo) {
        fecthFn = () => fetch.action(fetch.dbNo, fetch.id, fecthParams)

      } else {
        fecthFn = () => fetch.action(fetch.id, fecthParams)
      }

      this.props.dispatch(fecthFn()).then(res => {
        this.setTotalPage(res)
      })

    } else {
      const currentPage = this.state.currentPageSearch
      const skip = currentPage > 0 ? ((currentPage-1)*this.props.itemsPerPage) : 0
      const loadId = !this.props.stateLoading && this.props.id
      const stateLoading = this.props.stateLoading

      onSearchData(this, searchValue, fetch, { skip, loadId, stateLoading }).then(res => this.setTotalPage(res))
    }
  }

  setTotalPage = (res) => {
    if (res.success && res.count) {
      if (this.mounted) {
        this.setState({
          totalItems: res.count,
          totalPage: Math.ceil(res.count / this.state.itemsPerPage)
        }, () => {
          getListNumer(this)
        })
      }
    }
  }

  getCurrentPageName = (props) => {
    let currentPageName = 'current_page'
    let currentPageSearchName = 'current_page_search'

    if (props.currentPageName) {
      currentPageName = props.currentPageName
      currentPageSearchName = `${props.currentPageName}_search`
    }

    return {
      currentPageName,
      currentPageSearchName
    }
  }

  getUrlParams = (search, reload) => {
    let currentPage, currentPageSearch, searchValue

    if (search) {
      const urlParams = new URLSearchParams(search)
      const { currentPageName, currentPageSearchName } = this.getCurrentPageName(this.props)

      currentPage = urlParams.get(currentPageName) || 1
      currentPageSearch = urlParams.get(currentPageSearchName) || 1
      searchValue = urlParams.get('search')

    } else if (reload) {
      const urlParams = new URLSearchParams(this.props.location.search)
      const { currentPageName, currentPageSearchName } = this.getCurrentPageName(this.props)

      currentPage = urlParams.get(currentPageName) || 1
      currentPageSearch = urlParams.get(currentPageSearchName) || 1
      searchValue = null

    } else {
      currentPage = 1
      currentPageSearch =  1
      searchValue = null
    }

    if (this.mounted) {
      this.setState({
        currentPage: parseInt(currentPage),
        currentPageSearch: parseInt(currentPageSearch),
        searchValue,
        totalPage: 0
      }, () => {
        this.fecthDatas(searchValue)
      })
    }
  }


  previousPage = () => {
    const currentPage = getCurrentPage(this)
    const url = this.getUrl(currentPage-1)

    if (currentPage > 1) {
      this.goto(url)
    }
  }

  nextPage = () => {
    const currentPage = getCurrentPage(this)
    const totalPage = getTotalPage(this)
    const url = this.getUrl(currentPage+1)

    if (currentPage < totalPage) {
      this.goto(url)
    }
  }

  getUrl = (currentPage) => {
    const searchData = this.state.searchData
    const { pathname, search } = this.props.location
    let url

    if (currentPage) {
      const searchParams = new URLSearchParams(search)
      const { currentPageName, currentPageSearchName } = this.getCurrentPageName(this.props)

      if (searchData && searchData.status) {
        if (searchParams.get(currentPageName)) {
          searchParams.set(currentPageName, this.state.currentPage)
        } else {
          searchParams.append(currentPageName, this.state.currentPage)
        }

        if (searchParams.get(currentPageSearchName)) {
          searchParams.set(currentPageSearchName, currentPage)
        } else {
          searchParams.append(currentPageSearchName, currentPage)
        }

        if (searchParams.get('search')) {
          searchParams.set('search', searchData.value)
        } else {
          searchParams.append('search', searchData.value)
        }

      } else {
        if (searchParams.get(currentPageName)) {
          searchParams.set(currentPageName, currentPage)
        } else {
          searchParams.append(currentPageName, currentPage)
        }
      }

      url = `${pathname}?${searchParams.toString()}`
    } else {
      url = `${pathname}`
    }

    return url
  }

  goto = (url) => {
    const { history } = this.props

    history.push(url)

    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
  }

  pageNumberComponent = (number) => {
    const url = this.getUrl(number)
    const currentPage = getCurrentPage(this)


    return (
      <li key={number} className={`${currentPage === number && css.active}`}>
        {/*<Link to={url}>
          {number}
        </Link>*/}

        <a onClick={() => this.goto(url)}>
          {number}
        </a>
      </li>
    )
  }

  render() {
    const items = this.props.itemsData
    const searchData = this.state.searchData
    const currentPage = getCurrentPage(this)
    const totalPage = getTotalPage(this)

    let totalItems = searchData && searchData.status ? this.state.totalItemsSearch : this.state.totalItems
    let firstItem = (currentPage-1)*this.props.itemsPerPage+1
    let lastItem = currentPage*this.props.itemsPerPage <= totalItems ? currentPage*this.props.itemsPerPage : totalItems

    lastItem = lastItem <= totalItems ? lastItem : totalItems

    let itemsAmount = searchData && searchData.status ? searchData.data.length : items.length
    let currentItemsAmount = lastItem - firstItem + 1
    let itemDifference = currentItemsAmount - itemsAmount

    currentItemsAmount = currentItemsAmount - itemDifference
    totalItems = totalItems - itemDifference
    lastItem = firstItem + currentItemsAmount - 1

    return (
      <div>
        { searchData && searchData.status &&
          <div className="font-1-2 pd-left-3">
            { searchData.data.length === 0 ?
              <span>ไม่มีผลการค้นหาของ "{ searchData.value }" </span>
            :
              <span>ผลการค้นหาของ "{ searchData.value }"</span>
            }
          </div>
        }

        { !this.props.hiddenPaginationNumber &&
          <Fragment>
            { totalItems > 0 && !this.props.preLoading &&
              <div className="font-0-8 mg-bottom-10 pd-left-3">
                <span>หน้าที่ { currentPage } </span>
                { currentItemsAmount > 0 ?
                  <span>แสดง {firstItem}-{lastItem} </span>
                :
                  <span>แสดง 0 </span>
                }
                <span>จาก {getNumeral(totalItems, 0)} รายการ</span>
              </div>
            }
          </Fragment>
        }

        <div>
          { this.props.children }
        </div>

        { showPagination(this) &&
          <div className={css.box}>
            <ul className={css.pagination}>

              <li className={currentPage > 1 ? 'waves-effect' : css.disabled}>
                <a onClick={this.previousPage}>
                  <i className="material-icons i-middle">chevron_left</i>
                </a>
              </li>

              { this.pageNumberComponent(1) /* first number */ }

              { this.state.firstMore &&
                <li className={css.more}>
                  <i className="material-icons i-middle">more_horiz</i>
                </li>
              }

              { getPageNumber(this) /* start number - end number */ }

              { this.state.lastMore &&
                <li className={css.more}>
                  <i className="material-icons i-middle">more_horiz</i>
                </li>
              }

              { this.pageNumberComponent(totalPage) /* last number */ }


              <li className={currentPage < totalPage ? 'waves-effect' : css.disabled}>
                <a onClick={this.nextPage}>
                  <i className="material-icons i-middle">chevron_right</i>
                </a>
              </li>
            </ul>
          </div>
        }
      </div>
    )
  }
}

function mapStateToProps(store, props) {
  return {
    preLoading: store.app.loads.filter(load => load === props.id)[0],
  }
}

Pagination.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  searchData: PropTypes.object.isRequired,
  itemsData: PropTypes.array.isRequired,
}

export default connect(mapStateToProps)(Pagination)
