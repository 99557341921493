import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import TextEditorNavbar from './TextEditorNavbar'
import TextEditorNavbarDisplay from './TextEditorNavbarDisplay'

let TextEditorNavbar = lazy(() => import('./TextEditorNavbar'))
TextEditorNavbar = suspenseComponent(TextEditorNavbar)

export {
  TextEditorNavbar,
  TextEditorNavbarDisplay
}
