import Numeral from 'numeral'

const getInputValue = (input, s_img_files) => {
  if (input.type !== 'file' &&
      input.type !== 'radio' &&
      input.type !== 'checkbox' &&
      input.name !== 'checkbox' &&
      input.name !== 'img-file' &&
      input.name !== 'array' &&
      input.name !== 'range-between' &&
      input.name !== 'date-picker-between' &&
      !input.dataset.child) {

    if (input.name === 'price'){
      return input.value !== 'null' ? Numeral(input.value).value() : null
    } else {
      return input.value !== 'null' ? input.value : 'null'
    }
  }

  // push object value from checkbox
  if (input.type === 'checkbox') {
    if (input.value === '' || input.value === undefined) return false
    else return input.value
  }

  // push object value from checkbox
  if (input.name === 'checkbox') {
    if (input.value === '') return []
    else return input.value.split(',')
  }

  // push object value from range-between
  if (input.name === 'range-between') {
    if (input.value === '') return null
    else return input.value.split(',')
  }

  // push object value from range-between
  if (input.name === 'date-picker-between') {
    if (input.value === '') return null
    else return input.value.split(',')
  }

  // push object value from range-between
  if (input.name === 'array') {
    const value = JSON.parse(input.value)

    if (value.length === 0) return []
    else return value
  }

  // push object value from range-between
  if (input.name === 'img-file') {
    if (input.value === '') return null
    else {
      const { id, fileType, filePrefix } = input.dataset
      const dataurl = input.value

      if (id && fileType && filePrefix) {
        s_img_files.push({
          id,
          base64: dataurl,
          album: id,
          type: fileType,
          prefix: filePrefix
        })
      }
    }
  }
}

const checkInput = (input) => {
  if (input.dataset) {
    if (input.dataset.id !== 'valid' && input.dataset.id !== undefined && input.dataset.id !== '' && input.name !== 'select' && !input.dataset.notSave) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

const getArrayName = (input) => {
  let name = input.dataset.id.split('[')

  if (name.length === 2) {
    let parentName = name[0]
    let parentIndex = parseInt(name[1].split('].')[0])
    let childName = name[1].split('].')[1]

    return {
      type: 2,
      parentName: parentName,
      parentIndex: parentIndex,
      childName: childName
    }

  } else if (name.length === 3) {
    let parentName = name[0]
    let parentIndex = parseInt(name[1].split('].')[0])
    let parent2Name = name[1].split('].')[1]
    let parent2Index = parseInt(name[2].split('].')[0])
    let childName = name[2].split('].')[1]

    return {
      type: 3,
      parentName: parentName,
      parentIndex: parentIndex,
      parent2Name: parent2Name,
      parent2Index: parent2Index,
      childName: childName
    }
  } else {
    return {
      type: 1
    }
  }
}

const getInputLabel = (input) => {
  console.log(input.dataset)
}

const generateParentName = (input, params) => {
  let name = getArrayName(input)

  if (name.type === 2) {
    if (!params[name.parentName]) {
      return params[name.parentName] = []

    } else {
      if (!params[name.parentName][name.parentIndex]) {
        params[name.parentName][name.parentIndex] = {}
      }
    }
  }


  if (name.type === 3) {
    if (!params[name.parentName]) {
      return params[name.parentName] = []

    } else {
      if (!params[name.parentName][name.parentIndex]) {
        params[name.parentName][name.parentIndex] = []
      } else {
        if (!params[name.parentName][name.parentIndex][name.parent2Name]) {
          params[name.parentName][name.parentIndex][name.parent2Name] = []
        } else {
          if (!params[name.parentName][name.parentIndex][name.parent2Name][name.parent2Index]) {
            params[name.parentName][name.parentIndex][name.parent2Name][name.parent2Index] = {}
          }
        }
      }
    }
  }
}

const getFormValue = (form) => {
  let params = {}
  let arrayParams = []
  let input = form.refs.valid.form
  let valid = form.refs.valid.value
  let useLabel = form.refs.useLabel && form.refs.useLabel.value
  let s_img_files = []

  if (valid === 'true') {

    //loop get params attributes 1
    for (var key in input) {
      if (input.hasOwnProperty(key) && checkInput(input[key])) {
        generateParentName(input[key], params)
      }
    }

    //loop get params attributes 2
    for (var key in input) {
      if (input.hasOwnProperty(key) && checkInput(input[key])) {
        generateParentName(input[key], params)
      }
    }

    //loop get params attributes 3
    for (var key in input) {
      if (input.hasOwnProperty(key) && checkInput(input[key])) {
        generateParentName(input[key], params)
      }
    }

    //loop insert value
    for (var key in input) {

      if (input.hasOwnProperty(key) && checkInput(input[key])) {
        let name = getArrayName(input[key])
        let value = getInputValue(input[key], s_img_files)



        if (name.type === 3) {
          if (value || value === '') {
            params[name.parentName][name.parentIndex][name.parent2Name][name.parent2Index][name.childName] = value
          }

        } else if (name.type === 2) {
          if (value || value === '') {
            if (name.childName) {
              params[name.parentName][name.parentIndex][name.childName] = value

              if (useLabel === 'true') {
                const index = arrayParams.findIndex(arr => arr.id === name.parentName)

                if (index !== -1) {
                  arrayParams[index].value = params[name.parentName]
                } else {
                  arrayParams.push({
                    id: name.parentName,
                    label: input[key].dataset.label,
                    useAttr: input[key].dataset.useAttr,
                    useAttrId: input[key].dataset.useAttrId,
                    type: input[key].dataset.type,
                    value: params[name.parentName]
                  })
                }
              }

            } else {
              params[name.parentName][name.parentIndex] = value

              if (useLabel === 'true') {
                const index = arrayParams.findIndex(arr => arr.id === name.parentName)

                if (index !== -1) {
                  arrayParams[index].value = params[name.parentName]
                } else {
                  arrayParams.push({
                    id: name.parentName,
                    label: input[key].dataset.label,
                    useAttr: input[key].dataset.useAttr,
                    useAttrId: input[key].dataset.useAttrId,
                    type: input[key].dataset.type,
                    value: params[name.parentName]
                  })
                }
              }
            }
          }

        } else {
          if (value || value === '' || value === 0) {
            if (useLabel === 'true') {
              arrayParams.push({
                id: input[key].dataset.id,
                label: input[key].dataset.label,
                useAttr: input[key].dataset.useAttr,
                useAttrId: input[key].dataset.useAttrId,
                type: input[key].dataset.type,
                value
              })
            } else {
              params[input[key].dataset.id] = value
            }
          }
        }
      }
    }
  }

  if (valid === 'true') {
    if (useLabel === 'true') {
      if (s_img_files.length !== 0) {
        arrayParams.push({
          id: 's_img_files',
          type: 'img-file',
          value: s_img_files
        })
      }

      return arrayParams
    } else {
      if (s_img_files.length !== 0) {
        params['s_img_files'] = s_img_files
      }

      return params
    }
  } else {
    return null
  }
}

export default getFormValue
