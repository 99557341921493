export const settings = (style) => {

  let xxsColumn = style && style['xxs-column-count'] ? style['xxs-column-count'] : 1
  let xsColumn = style && style['xs-column-count'] ? style['xs-column-count'] : 1
  let smColumn = style && style['sm-column-count'] ? style['sm-column-count'] : 2
  let mdColumn = style && style['md-column-count'] ? style['md-column-count'] : 3
  let lgColumn = style && style['lg-column-count'] ? style['lg-column-count'] : 3
  let xlgColumn = style && style['xlg-column-count'] ? style['xlg-column-count'] : 3

  xxsColumn = `xxs-column-${xxsColumn}`
  xsColumn = `xs-column-${xsColumn}`
  smColumn = `sm-column-${smColumn}`
  mdColumn = `md-column-${mdColumn}`
  lgColumn = `lg-column-${lgColumn}`
  xlgColumn = `xlg-column-${xlgColumn}`

  const classNameColumn = `${xxsColumn} ${xsColumn} ${smColumn} ${mdColumn} ${lgColumn} ${xlgColumn}`


  return {
    classNameColumn
  }
}
