import React, { Component } from 'react'
import ReactDOM from 'react-dom'

class EditorContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick)
  }

  handleOpenTools = (value) => {
    this.props.onClick(value)
  }

  handleDocumentClick = (e) => {
    if (this.props.openTools) {
      if (!ReactDOM.findDOMNode(this).contains(e.target)) {
        this.handleOpenTools(false)
      }
    }
  }

  render() {
    const { className, style, children } = this.props

    return (
      <div onClick={() => this.handleOpenTools(true)} className={`${className}`} style={style}>
        { children }
      </div>
    )
  }
}

export default EditorContainer
