import React from 'react'

import { SwitchStyleSettings, InputStyleSettings } from '../StyleSettings'

const BadgeManage = (props) => {
  const { _this } = props

  return (
    <div>
      <SwitchStyleSettings
        _this={_this}
        label="แสดงป้าย"
        styleName="badge-show"
      />

      <div className="pd-10">
        <InputStyleSettings
          label="ค่าที่แสดง"
          _this={_this}
          type="text"
          styleName="badge-value"
        />
      </div>
    </div>
  )
}

export default BadgeManage
