import React from 'react'
import { connect } from 'react-redux'

// Components Local
import { LoginSocialsDisplay } from './components/LoginSocials'


const TextEditorWidgetDisplay = (props) => {
  const { content, templateData, match } = props
  const loginSocials = content.loginSocials && JSON.parse(content.loginSocials)
  const style = loginSocials && loginSocials.style ? JSON.parse(loginSocials.style) : {}
  const id = 'form-login-' + content._id

  const { username, sites_id } = match && match.params || {}
  const modeManage = (username && sites_id)

  return (
    <LoginSocialsDisplay
      id={id}
      product={templateData}
      style={style}
      modeManage={modeManage}
    />
  )
}

export default connect()(TextEditorWidgetDisplay)
