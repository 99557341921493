import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import renderRoutes from '../../../../routes/renderRoutes'

// Components
import HeaderCustomer from '../components/HeaderCustomer'

// Values
import { customerValue } from '../../../../redux/Customers/CustomersValues'


const CustomerContainer = (props) => {
  const { route, site, location, business, history, auth, customer } = props

  if (auth.user === false || !customer) {
    history.push('/')
  }

  return (
    <div style={{ backgroundColor: '#f5f5f5', minHeight: '100%' }}>

      { customer &&
        <HeaderCustomer {...props} />
      }


      <Fragment>
        { customer && renderRoutes({
          routes: route.routes,
          extraProps: { site, business, auth, customer },
          location
        }) }
      </Fragment>
    </div>
  )
}

const mapStateToProps = (store, props) => {
  const { host, auth, customers, sites, businesses } = store
  const user = auth && auth.user
  let customer = user && user.customer

  if (customer && customers && customers.data) {
    const updateCustomer = customers.data.filter(data => data._id === customer._id)[0]

    if (updateCustomer) {
      customer = updateCustomer
    }

    customer = customerValue({ customer })
  }

  return {
    host,
    auth,
    site: sites.data[0],
    business: businesses.data[0],
    customer
  }
}

export default connect(mapStateToProps)(CustomerContainer)
