import IndexedDB from '../../IndexedDB'

import getParamsValue from '../../functions/getParamsValue'
import { addItem, removeItem } from './BillsDBFunctions'

export const fetchAllColl = async (req) => {
  const { body, params } = req
  const { businesses_id } = params
  const where = getParamsValue(body.where)
  where.businesses_id = businesses_id

  return await IndexedDB.fetchMany('bills', { where })
}

export const createColl = async (req) => {
  const { body, params } = req
  const { businesses_id } = params
  const values = body
  values.businesses_id = businesses_id

  return await IndexedDB.create('bills', { values })
}

export const updateColl = async (req) => {
  const { params, body } = req
  const { scenario } = body
  const { _id } = params

  if (scenario === 'addItem') {
    return await addItem(req)
  } else if (scenario === 'removeItem') {
    return await removeItem(req)
  } else {
    return await IndexedDB.updateCondition('bills', { req })
  }
}

export const deleteColl = async (req) => {
	const { params } = req
  const { _id } = params

  return await IndexedDB.deleteOne('bills', { _id })
}
