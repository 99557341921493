import React from 'react'


// Components Text Editor
import BoxSettings from '../BoxSettings'
import ResponsiveBlockManage from '../ResponsiveBlockManage'
import DivElementManage from '../ElementStylesManage/components/DivElementManage'
import { BackgroundImageSetting } from '../StyleSettings'


const BlockStyleSettings = (props) => {
  let { _this, device, hiddenBackground, hiddenResponsive, name } = props

  if (!name) {
    name = 'block'
  }

  let title = ''

  if (device && device !== 'desktop') {
    name = `${name}-${device}`
  }

  if (props.title) {
    title = props.title
  }

  return (
    <div>
      { !hiddenResponsive &&
        <ResponsiveBlockManage _this={_this} device={device} />
      }

      { !hiddenBackground &&
        <BoxSettings title={`บล็อก${title}ภาพพื้นหลัง`} icon="photo_size_select_actual">
          <BackgroundImageSetting _this={_this} styleName={`${name}-background-image`} />
        </BoxSettings>
      }

      <DivElementManage
        {...props}
        name={`${name}`}
        title={`บล็อก${title}`}
        icon="view_compact"
        hidden={{
          opacity: true,
          lineHeight: true,
          iconSize: true,
          iconColor: true,
          iconMargin: true
        }}
      />
    </div>
  )
}

export default BlockStyleSettings
