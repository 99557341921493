import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

// Components Text Editor Local
import EditorContainer from '../EditorContainer'
import BoxTools from '../BoxTools'
import BtnTools from '../BtnTools'
import BoxSelect from '../BoxSelect'
import BlockStyle from '../BlockStyle'


class Selected extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openTools: false
    }

    this.timer = null
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  handleOpenTools = (value) => {
    if (value) {
      this.textarea && this.textarea.focus()
    }

    this.mounted && this.setState({ openTools: value })
  }

  openSidebar = (activeId) => {
    this.setState({ activeId: activeId })

    if (this.sidebar) {
      return this.sidebar.open()
    }
  }

  render() {
    const { _this, type, style, title, mode, content, styleSettings } = this.props
    const { openTools, activeId } = this.state

    return (
      <Fragment>
        { mode === 'edit' ?
          <EditorContainer
            openTools={openTools}
            onClick={this.handleOpenTools}>

            <BoxTools openTools={openTools}>
              <BtnTools
                notDelete
                title={title}
                _this={_this}
                tools={[
                  {
                    lists: [
                      { icon: 'color_lens', label: 'สไตล์', onClick: () => this.openSidebar('style') }
                    ],
                    show: true
                  }
                ]}
              />
            </BoxTools>

            <BoxSelect
              hiddenTitle
              _this={_this}
              title={title}
              name={this.props.name}
              blockName={this.props.blockName}
              type={this.props.type}
              styleSettings={styleSettings}
              apiSettings={content && content.api}
              activeId={activeId}
              onRef={sidebar => this.sidebar = sidebar}
              openTools={this.state.openTools}>

              { this.props.children }
            </BoxSelect>
          </EditorContainer>
        :
          <BlockStyle {...this.props} name={this.props.blockName || this.props.name} style={style} type={type}>
            { this.props.children }
          </BlockStyle>
        }
      </Fragment>
    )
  }
}

export default connect()(Selected)
