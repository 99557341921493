import React, { useState } from 'react'
import Bowser from 'bowser'
import config from '../../../config'

// css
import css from './css/imageCenter.css'

const imgCenter = (props) => {
  const [width, setWidth] = useState()
  const [height, setHeight] = useState()

  const onImgLoad = ({target:img}) => {
    if (props.onLoad) {
      props.onLoad()
    }

    const { srcOriginal } = getUrl()

    if (props.showImage && srcOriginal) {
      const img = new Image();
      img.src = srcOriginal

      img.onload = () => {
        setHeight(img.height)
        setWidth(img.width)
      }
    }
  }

  const boxImg = {
    textAlign: 'center',
    display: 'inline-block',
    width: '100%',
    height: '100%',
    lineHeight: '0'
  }

  let cssImgCenterSupport

  if (Bowser.chrome && Bowser.version >= '31'
     || Bowser.firefox && Bowser.version >= '36'
     || Bowser.msedge && Bowser.version >= '16'
     || Bowser.safari && Bowser.version >= '10'
     || Bowser.android && Bowser.version >= '10'
     || Bowser.ios && Bowser.version >= '10.2' ) {

    cssImgCenterSupport = css.imgCenterSupport

  } else {
    cssImgCenterSupport = css.imgCenterNotSupport
  }

  const getUrl = () => {
    const s3Url = config.app.s3.url
    const s3UrlOriginal = config.app.s3.urlOriginal

    let src, srcOriginal

    if (props.s3Url) {
      src = `${s3Url}/${props.src}`
      srcOriginal = `${s3Url}/${props.srcOriginal}`
    } else if (props.s3UrlOriginal) {
      src = `${s3UrlOriginal}/${props.src}`
      srcOriginal = `${s3UrlOriginal}/${props.srcOriginal}`
    } else {
      src = props.src
      srcOriginal = props.srcOriginal
    }

    return {
      src, srcOriginal
    }
  }

  const { src, srcOriginal } = getUrl()
  const imgTag = (
    <img
      onLoad={onImgLoad}
      draggable={props.draggable}
      className={`${props.className || ''} ${!props.imgStyle && cssImgCenterSupport} ${props.circle && 'box-circle'}`}
      onError={props.onError}
      style={props.imgStyle}
      src={src}
      alt={props.alt}
    />
  )

  return (
    <div style={boxImg}>
      { props.showImage && srcOriginal ?
        <a
          className="photoSwipeLightbox"
          href={props.srcOriginal && srcOriginal}
          data-pswp-width={width}
          data-pswp-height={height}
          target="_blank">

          { imgTag }
        </a>
      : imgTag }
    </div>
  )
}

export default imgCenter
