import React, { Component, Fragment, useMemo } from 'react'
import { connect } from 'react-redux'
import LazyLoad from 'react-lazyload'

// Components Global
import { LayoutsDesign } from '../../../../LayoutsDesign'
import CarouselSlider from '../../../../Carousel'

// Components Text Editor
import Template from '../../../TextEditorTemplate/components/Template'

// Components Local
import { ImageComponent } from '../../../components/EditorImage/components/ImageType'

// Functions
import { getSections } from '../../../../../redux/Sites/SitesFunctions'

import {
  setStyleValue,
} from '../../../components/ElementStyles/components/js/setDivStyle'

// JS
import { getAlbumImages } from '../../../components/ApiSettings/js/apiSettings'
import { settings } from '../../js/carousel.js'
import { fetchDatasApi, getDataStore } from '../../../components/FetchApiSettings/js/fetchApi'


class Carousel extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.fetchDatas()

    if (this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentDidUpdate() {

  }

  shouldComponentUpdate(prevProps) {
    const prevApiItems = prevProps.apiItems && prevProps.apiItems.length
    const apiItems = this.props.apiItems && this.props.apiItems.length
    const prevItems = prevProps.items && prevProps.items.length
    const items = this.props.items && this.props.items.length

    return prevApiItems !== apiItems ||
           prevItems !== items ||
           prevProps.match !== this.props.match
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null)
    }
  }

  fetchDatas = () => {
    const { content, style } = this.props
    const { type } = settings(style)

    if (content.api && type === 'content') {
      fetchDatasApi(this.props)
    }
  }

  render() {
    //const lang = this.props.lang || 'local'
    let { items, apiItems, content, display, mode, _this, style, api, apiData, templateData } = this.props
    const settingValue = settings(style)
    const templates_id = style['templates_id']
    const useImageStyle = style['use-image-style']


    //itemImages
    let data

    if (content.api && settingValue.type === 'content') {
      items = apiItems

    } else if (content.api && settingValue.type === 'image') {
      if (mode) {
        items = [{ _id: '001' }]

      } else {
        const albumImages = getAlbumImages({ content, api, apiData, templateData })

        if (albumImages) {
          items = albumImages
        } else {
          items = [{ _id: '001' }]
        }
      }
    }

    const maxAmout = settingValue.responsive['1201'].items
    const itemsAmout = items && items.length || 0
    const itemsFake = maxAmout - itemsAmout

    /*if (itemsFake > 0) {
      for (let i = 0; i < itemsFake; i++) {
        items.push(JSON.stringify({  _id: `fake-${i}`, fake: true  }))
      }
    }*/

    /*const styleBox = useMemo(() => setStyleValue({ name: 'box-slider', style }), [style])
    const styleItem = useMemo(() => setStyleValue({ name: 'item-slider', style }), [style])

    const styleBoxVw = useMemo(() => setStyleValue({ name: 'box-slider', style, vw: true }), [style])
    const styleItemVw = useMemo(() => setStyleValue({ name: 'item-slider', style, vw: true }), [style])

    const styleBoxTablet = useMemo(() => setStyleValue({ device: 'tablet', name: 'box-slider', style }), [style])
    const styleItemTablet = useMemo(() => setStyleValue({ device: 'tablet', name: 'item-slider', style }), [style])

    const styleBoxMobile = useMemo(() => setStyleValue({ device: 'mobile', name: 'box-slider', style }), [style])
    const styleItemMobile = useMemo(() => setStyleValue({ device: 'mobile', name: 'item-slider', style }), [style])
*/

    const styleBox = setStyleValue({ name: 'box-slider', style })
    const styleItem = setStyleValue({ name: 'item-slider', style })

    const styleBoxVw = setStyleValue({ name: 'box-slider', style, vw: true })
    const styleItemVw = setStyleValue({ name: 'item-slider', style, vw: true })

    const styleBoxTablet = setStyleValue({ device: 'tablet', name: 'box-slider', style })
    const styleItemTablet = setStyleValue({ device: 'tablet', name: 'item-slider', style })

    const styleBoxMobile = setStyleValue({ device: 'mobile', name: 'box-slider', style })
    const styleItemMobile = setStyleValue({ device: 'mobile', name: 'item-slider', style })

    return (
      <div className="carousel-jsx">

          { items && items.length !== 0 &&
            <CarouselSlider
              id={content._id}
              onRef={slider => this.slider = slider}
              settings={settingValue}
              style={style}>

              { items.map((item, i) => {

                let byGroupId, paths, itemId, apiData

                if (content.api && settingValue.type === 'content') {
                  byGroupId = content._id
                  itemId = item._id
                  apiData = item

                } else if (content.api && settingValue.type === 'image') {
                  itemId = item._id
                  paths = item.paths

                } else {
                  item = JSON.parse(item)
                  itemId = item.id
                  paths = item.paths && JSON.parse(item.paths)
                  byGroupId = item.id
                }

                let sections

                if (byGroupId) {
                  sections = getSections(this.props, { byGroupId })
                }

                return (
                  <div
                    id={item.id}
                    key={itemId}
                    className="carousel-item-jsx"
                    style={{
                      position: "relative"
                    }}>

                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        minHeight: '50px',
                        background: mode && (i%2 ? '#ddd' : '#fbfbfb'),
                        userSelect: 'none',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                      }}>

                      { settingValue.type === 'image' &&
                        <Fragment>
                          { paths && paths.o ?
                            <Fragment>
                              { useImageStyle ?
                                <ImageComponent
                                  lazyDisabled
                                  mode={mode}
                                  paths={paths}
                                  link={item.link}
                                  style={style}
                                  match={this.props.match}
                                />
                              :
                                <ImageComponent
                                  lazyDisabled
                                  mode={mode}
                                  paths={paths}
                                  link={item.link}
                                  style={{
                                    width: '100%',
                                    verticalAlign: 'middle',
                                    height: '100%',
                                    objectFit: 'contain'
                                  }}
                                  match={this.props.match}
                                />
                              }
                            </Fragment>
                          :
                            <div className="ratio-container ratio-4-3">
                              <div className="ratio-content center">
                                { (mode && api) ?
                                  <Fragment>
                                    { api.album ?
                                      <div className="font-1-5 pd-top-100">
                                        [images] [album:{api.album}]
                                        { api.album === 'custom' && <div>[albumName: {api.albumCustomName}]</div> }
                                      </div>
                                    :
                                      <div>ยังไม่ตั้งค่า API</div>
                                    }
                                  </Fragment>
                                :
                                  'ไม่มีรูปภาพ'
                                }
                              </div>
                            </div>
                          }
                        </Fragment>
                      }

                      { settingValue.type === 'content' &&
                        <Fragment>
                          { !item.fake ?
                            <Fragment>
                              { templates_id ?
                                <Template
                                  action={this.props.action}
                                  match={this.props.match}
                                  mode={this.props.mode}
                                  loggedIn={this.props.loggedIn}
                                  display
                                  apiData={apiData}
                                  groupId={byGroupId}
                                  sections={sections}
                                  templates_id={templates_id}
                                />
                              :
                                <LayoutsDesign
                                  action={this.props.action}
                                  match={this.props.match}
                                  mode={this.props.mode}
                                  loggedIn={this.props.loggedIn}
                                  display
                                  apiData={apiData}
                                  groupId={byGroupId}
                                  sections={sections}
                                />
                              }
                            </Fragment>
                          :
                            <div></div>
                          }
                        </Fragment>
                      }
                    </div>
                  </div>
                )
              }) }
            </CarouselSlider>
          }

        { !display &&
          <div className="center pd-top-40">
            <a
              onClick={() => _this.openSidebar('content')}
              className="btn-shadow-none btn btn-sm btn-white pd-right-15"
              style={{ userSelect: 'none' }}>

              { content.api ?
                <Fragment>
                  <i className="material-icons font-0-9 color-000 left">settings</i>
                  <span className="font-0-9 color-000">ตั้งค่าการดึงข้อมูล</span>
                </Fragment>
              :
                <Fragment>
                  <i className="material-icons font-0-9 color-000 left">add</i>
                  <span className="font-0-9 color-000">เพิ่มสไลด์</span>
                </Fragment>
              }
            </a>
          </div>
        }

        <style jsx>{`
          .carousel-jsx {
            ${styleBox}
          }
          .carousel-item-jsx {
            ${styleItem}
          }

          @media (min-width: 1500px) {
            .carousel-jsx {
              ${styleBoxVw}
            }
            .carousel-item-jsx {
              ${styleItemVw}
            }
          }

          @media (min-width: 601px) and (max-width: 1024px) {
            .carousel-jsx {
              ${styleBoxTablet}
            }
            .carousel-item-jsx {
              ${styleItemTablet}
            }
          }

          @media (min-width: 50px) and (max-width: 600px) {
            .carousel-jsx {
              ${styleBoxMobile}
            }
            .carousel-item-jsx {
              ${styleItemMobile}
            }
          }
        `}</style>
      </div>
    )
  }
}

const mapStateToProps = (store, props) => {
  return {
    auth: store.auth,
    apiItems: getDataStore(store, props)
  }
}

export default connect(mapStateToProps)(Carousel)
