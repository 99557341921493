import React, { Component } from 'react'

// Functions
import { addStyle, removeStyle } from '../../../functions/textEditorFunctions'

// css
import css from '../css/styleSettings.css'



class TextAlignSetting extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  change = (e) => {
    let { _this, styleName } = this.props
    const value = e.target.value

    styleName = styleName || 'text-align'

    if (value === '') {
      removeStyle(_this, styleName)
    } else {
      addStyle(_this, {
        style: styleName,
        value
      })
    }
  }

  reset = () => {
    let { _this, styleName } = this.props
    styleName = styleName || 'text-align'

    if (styleName) {
      removeStyle(_this, styleName)
    }
  }

  render() {
    let { _this, styleName } = this.props
    styleName = styleName || 'text-align'
    const textAlign = _this.state.style[styleName]

    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td className="pd-0 font-0-7">ตำแหน่งเนื้อหาแนวนอน (text-align)</td>
              <td className="pd-0 font-0-7 text-right">
                <a className={css.btn} onClick={this.reset}>ค่าเดิม</a>
              </td>
            </tr>
          </tbody>
        </table>

        <div>
          <div className={css.boxInput}>
            <table>
              <tbody>
                <tr>
                  <td width="50%" className="pd-0 font-0-8 center">
                    <select
                      className={`browser-default ${css.input} ${css.select}`}
                      onChange={this.change}
                      value={textAlign || ''}>

                      <option value="">ไม่มี</option>
                      <option value="right">ชิดขวา</option>
                      <option value="center">ตรงกลาง</option>
                      <option value="left">ชิดซ้าย</option>
                      <option value="justify">ชิดขอบ</option>

                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default TextAlignSetting
