import React from 'react'

import css from './css/btnAddImg.css'

export function BtnAddImg (props) {
  return (
    <div className={css.boxAdd} style={props.style}>
      <div className={css.boxDiv}>
        <div className={css.box}>
          <div>
            <i className="material-icons mg-top--20">{props.icon}</i>
            <span>{props.text}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BtnAddImg
