import React, { Component, Fragment } from 'react'
import config from '../../config'

// imgs
import imageNotFound from './imgs/image-not-found.png'
import imageLoding from './imgs/image-not-found.png'

const s3Url = config.app.s3.url
const s3UrlOriginal = config.app.s3.urlOriginal


class Img extends Component {
  constructor(props) {
    super(props)
    this.state = {
      src: ''
    }
  }

  componentDidMount() {
    this.getImgUrl()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src) {
      this.getImgUrl()
    }
  }

  onError = () => {
    this.setState({ src: imageNotFound })
  }

  onLoad = () => {
    this.setState({ src: imageLoding })
  }

  getImgUrl = () => {
    let src

    if (this.props.s3Url) src = `${s3Url}/${this.props.src}`
    else if (this.props.s3UrlOriginal) src = `${s3UrlOriginal}/${this.props.src}`
    else src = this.props.src

    this.setState({ src })
  }

  render() {
    let { onDragStart, onDragEnd, width, height, className, style, styleJsx } = this.props
    styleJsx = styleJsx || {}

    return (
      <Fragment>
        <img
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
          width={width}
          height={height}
          style={style}
          className={`img-jsx ${className || ''}`}
          onError={() => this.onError()}
          src={this.state.src}
        />


        <style jsx>{`
          .img-jsx {
            width: ${styleJsx.width} !important;
            height: ${styleJsx.height} !important;
            border-style: ${styleJsx.borderStyle} !important;
            border-width: ${styleJsx.borderWidth} !important;
            border-color: ${styleJsx.borderColor} !important;
            border-radius: ${styleJsx.borderRadius} !important;
          }

          @media (min-width: 1500px) {
            .img-jsx {
              width: ${styleJsx.widthVw || styleJsx.width} !important;
              height: ${styleJsx.heightVw || styleJsx.height} !important;
              border-radius: ${styleJsx.borderRadiusVw} !important;
            }
          }
        `}</style>

      </Fragment>
    )
  }
}

export default Img


/*const Img = (props) => {
  const s3Url = config.app.s3.url
  const s3UrlOriginal = config.app.s3.urlOriginal
  let src

  if (props.s3Url) src = `${s3Url}/${props.src}`
  else if (props.s3UrlOriginal) src = `${s3UrlOriginal}/${props.src}`
  else src = props.src

  console.log(this.state.src)

  return <img
    width={props.width}
    height={props.height}
    style={props.style}
    className={props.className}
    onError={() => this.onError()}
    src={src} />
}

export default Img*/
