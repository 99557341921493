import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Global
import AlbumManager from '../../../../../components/AlbumManager'
import Section from '../../../../../components/Section'

// Redux Actions
import { updateSite, uploadImagesSite } from '../../../../../redux/Sites/SitesActions'
import { fetchPages, updatePage, uploadImagesPage } from '../../../../../redux/Pages/PagesActions'


class SitePhotos extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { site } = this.props

    this.props.dispatch(fetchPages(site.businesses_id, site._id, {
      body: { sort: 'sort' },
      loadId: 'pageLoading'
    }))
  }

  render() {
    const { site, pages } = this.props

    return (
      <div className={`box`}>
        { site &&
          <Section title="รูป Favicon">
            <AlbumManager
              {...this.props}
              id="images-favicon"
              thumbnail="s"
              images={site.images.filter(img => img.album === 'favicon')}
              btnAdd={{ text: 'เพิ่มภาพ', icon: 'add', maxFile: 1 }}
              upload={{ album: 'favicon', multiple: false, maxFile: 1 }}
              delete
              action={{
                update: updateSite,
                uploadImage: uploadImagesSite,
                params: {
                  business: this.props.business,
                  _id: site._id,
                  imagePrefix: 'sites'
                }
              }}
            />
          </Section>
        }

        { site &&
          <Section title="รูปโลโก้">
            <AlbumManager
              {...this.props}
              id="images-logo"
              thumbnail="s"
              images={site.images.filter(img => img.album === 'logo')}
              btnAdd={{ text: 'เพิ่มภาพ', icon: 'add', maxFile: 1 }}
              upload={{ album: 'logo', multiple: false, maxFile: 1 }}
              delete
              action={{
                update: updateSite,
                uploadImage: uploadImagesSite,
                params: {
                  business: this.props.business,
                  _id: site._id,
                  imagePrefix: 'sites'
                }
              }}
            />
          </Section>
        }

        { site &&
          <Section title="รูปภาพไม่เจอภาพโปรไฟล์ (image not found)">
            <AlbumManager
              {...this.props}
              id="images-profile-not-found"
              thumbnail="s"
              images={site.images.filter(img => img.album === 'profile-not-found')}
              btnAdd={{ text: 'เพิ่มภาพ', icon: 'add', maxFile: 1 }}
              upload={{
                album: 'profile-not-found',
                multiple: false,
                maxFile: 1,
                crop: {
                  title: 'ภาพโปรไฟล์',
                  width: 980,
                  height: 980,
                  circle: false,
                  style: { width: '300px', height: 'auto' }
                }
               }}
              delete
              action={{
                album: 'profile-not-found',
                multiple: false,
                maxFile: 1,
                update: updateSite,
                uploadImage: uploadImagesSite,
                params: {
                  business: this.props.business,
                  _id: site._id,
                  imagePrefix: 'sites'
                }
              }}
            />
          </Section>
        }

        { site &&
          <Section title="คลังรูปภาพ">
            <AlbumManager
              {...this.props}
              id="imagesGallery-sites"
              thumbnail="s"
              images={site.images}
              btnAdd={{ text: 'เพิ่มภาพ', icon: 'add' }}
              upload={{ album: 'gallery', multiple: true, maxFile: 10 }}
              delete
              action={{
                update: updateSite,
                uploadImage: uploadImagesSite,
                params: {
                  business: this.props.business,
                  _id: site._id,
                  imagePrefix: 'sites'
                }
              }}
            />
          </Section>
        }

        { pages.map(page => {
          return (
            <Section key={page._id} title={`คลังรูปภาพหน้า ${page.name.local}`}>
              <AlbumManager
                {...this.props}
                id={`imagesGallery-page-${page._id}`}
                thumbnail="s"
                images={page.images}
                btnAdd={{ text: 'เพิ่มภาพ', icon: 'add' }}
                upload={{ album: 'gallery', multiple: true, maxFile: 10 }}
                delete
                action={{
                  update: updatePage,
                  uploadImage: uploadImagesPage,
                  params: {
                    business: this.props.business,
                    _id: page._id,
                    imagePrefix: 'pages'
                  }
                }}
              />
            </Section>
          )
        }) }
      </div>
    )
  }
}

const mapStateToProps = (store, props) => {
  return {
    site: store.sites.data.filter((site => site._id === props.sites_id))[0],
    pages: store.pages.data.filter((page => page.sites_id === props.sites_id))
  }
}

export default connect(mapStateToProps)(SitePhotos)
