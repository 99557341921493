import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import TextEditorLine from './TextEditorLine'
import TextEditorContentDescriptionsDisplay from './TextEditorContentDescriptionsDisplay'

let TextEditorContentDescriptions = lazy(() => import('./TextEditorContentDescriptions'))
TextEditorContentDescriptions = suspenseComponent(TextEditorContentDescriptions)

export {
  TextEditorContentDescriptions,
  TextEditorContentDescriptionsDisplay
}
