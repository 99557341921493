import React, { Component } from 'react'
import Siema from 'siema'

// css
import css from './css/imagesSlider.css'


class ImagesSlider extends Component {
  static defaultProps = {
    options: {
      autoPlay: false,
      navShow: true,
      loop: true,
      duration: 6
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      currentSlide: 0
    }
    this.timer = null
  }

  componentDidMount() {
    this.mounted = true
    this.init()
    if (this.props.onRef) this.props.onRef(this)
  }

  componentWillUnmount() {
    this.mounted = false
    if (this.props.onRef) this.props.onRef(null)
  }

  init = () => {
    this.siema = new Siema({
      selector: this.element,
      loop: true,
      onChange: () => {
        this.autoPlay()
        const currentSlide = this.siema.currentSlide
        this.mounted && this.setState({ currentSlide })
      }
    })

    this.autoPlay()
  }

  autoPlay = () => {
    let { autoPlay, duration } = this.props.options

    duration = (duration || 1) * 1000

    if (this.mounted && autoPlay && duration) {
      clearInterval(this.timer)
      this.timer = setInterval(() => this.next(), duration)
    } else {
      clearInterval(this.timer)
    }
  }

  prev = () => {
    this.mounted && this.siema.prev()
  }

  next = () => {
    this.mounted && this.siema.next()
  }

  goTo = (i) => {
    this.mounted && this.siema.goTo(i)
  }

  update = () => {
    this.setState({ loading : true }, () => {
      setTimeout(() => {
        this.setState({ loading: false }, () => {
          this.init()
        })
      }, 100)
    })
  }

  render() {
    const { loading, currentSlide } = this.state
    const { children, navStyle, options } = this.props
    let length = 0

    if (children) {
      if (children.length) length = children.length
      else length = 1
    }

    return (
      <div className={css.imagesSlider}>
        <div>
          { !loading &&
            <div ref={element => this.element = element}>
              { children }
            </div>
          }

          <div className={css.boxList}>
            { Array.apply(1, Array(length)).map((v, i) => {
              return (
                <div
                  key={i}
                  onClick={() => this.goTo(i)}
                  className={`${css.bot} ${currentSlide === i && css.active}`}
                  style={{ backgroundColor: navStyle && navStyle['background-color'] }}
                />
              )
            }) }
          </div>

          { options.navShow &&
            <div className={css.boxPre}>
              <div className="box-middle height-full">
                <div>
                  <a
                    onClick={this.prev}
                    className="btn-floating waves-effect waves-light"
                    style={{ backgroundColor: navStyle && navStyle['background-color'] }}>

                    <i
                      className="material-icons font-2-0"
                      style={{ color: navStyle && navStyle['color'] }}>
                      keyboard_arrow_left
                    </i>
                  </a>
                </div>
              </div>
            </div>
          }

          { options.navShow &&
            <div className={css.boxNext}>
              <div className="box-middle height-full">
                <div>
                  <a
                    onClick={this.next}
                    className="btn-floating waves-effect waves-light"
                    style={{ backgroundColor: navStyle && navStyle['background-color'] }}>

                    <i
                      className="material-icons font-2-0"
                      style={{ color: navStyle && navStyle['color'] }}>
                      keyboard_arrow_right
                    </i>
                  </a>
                </div>
              </div>
            </div>
          }

        </div>
      </div>
    )
  }
}

export default ImagesSlider
