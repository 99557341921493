import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import RowItem  from './RowItem'
import RowItemDisplay  from './RowItemDisplay'

const RowItemLazy = lazy(() => import('./RowItem'))
const RowItem = suspenseComponent(RowItemLazy)

export {
  RowItem,
  RowItemDisplay
}
