import { convertObjectToStringArray } from '../../../util/object'

export const getSearchParams = ({ fetch, value }) => {
  return convertObjectToStringArray(fetch.searchAttributes, `,value=${value}`)
}

export const onSearchData = (_this, value, fetch, options={ loadId: loadId, skip: undefined, addMore: false }) => {
  const status = value !== '' ? true : false
  const search = getSearchParams({ fetch, value })

  const body = {
    where: fetch.where,
    or: fetch.or,
    sort: fetch.sort,
    limit: fetch.limit,
    skip: options.skip,
    search,
    countWhere: fetch.countWhere,
    countOr: fetch.countOr,
    between: fetch.between,
    groupBy: fetch.groupBy,
    unwind: fetch.unwind,
    aggregate: fetch.aggregate,
  }

  if (!body.where) delete body.where
  if (!body.countWhere) delete body.countWhere
  if (!body.or) delete body.or
  if (!body.countOr) delete body.countOr
  if (!body.payload) delete body.payload
  if (!body.between) delete body.between
  if (!body.groupBy) delete body.groupBy
  if (!body.unwind) delete body.unwind
  if (!body.aggregate) delete body.aggregate

  let fecthFn
  let fecthParams = {
    body,
    loadId: options.loadId,
    addMore: options.addMore,
    search: { status, value, params: search }
  }

  if (fetch.actionQuery) {
    fecthFn = () => fetch.action(fetch.actionQuery, fecthParams)

  } else if (fetch.authName) {
    fecthFn = () => fetch.action(fetch.authName, fetch.id, fecthParams)

  } else if (fetch.dbNo) {
    fecthFn = () => fetch.action(fetch.dbNo, fetch.id, fecthParams)

  } else {
    fecthFn = () => fetch.action(fetch.id, fecthParams)
  }

  return _this.props.dispatch(fecthFn())
}
