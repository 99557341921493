import React, { Component } from 'react'

// Functions
import { addClass, removeClass } from '../../../functions/textEditorFunctions'

// css
import css from '../css/styleSettings.css'



class PositionContentSetting extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  change = (e) => {
    const { _this, className, groupName } = this.props
    const classValue = e.target.value
    const group = groupName

    if (classValue === '0') {
      removeClass(_this, className)
    } else {
      addClass(_this, {
        className,
        classValue,
        group
      })
    }
  }

  reset = () => {
    const { _this, className } = this.props

    if (className) {
      removeClass(_this, className)
    }
  }

  render() {
    const { _this, className, label } = this.props
    const classLists = _this.state.class || []

    let position = classLists.filter(val => val.name === className)[0]
    position = position ? position.value : '0'

    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td className="pd-0 font-0-7">{ label || 'ตำแหน่ง' }</td>
              <td className="pd-0 font-0-7 text-right">
                <a className={css.btn} onClick={this.reset}>รีเซ็ท</a>
              </td>
            </tr>
          </tbody>
        </table>

        <div>
          <div className={css.boxInput}>
            <table>
              <tbody>
                <tr>
                  <td width="50%" className="pd-0 font-0-8 center">
                    <select
                      className={`browser-default ${css.input} ${css.select}`}
                      onChange={this.change}
                      value={position}>

                      <option value="0">ไม่มี</option>
                      <option value="position-left">ชิดซ้าย</option>
                      <option value="position-center">กึ่งกลาง</option>
                      <option value="position-right">ชิดขวา</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default PositionContentSetting
