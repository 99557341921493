import AppWebsitesContainer from 'websites/AppWebsitesContainer'

// routes
import WebsitesRoutes from './WebsiteRoutes/WebsitesRoutes'


const routes = [
  {
    component: AppWebsitesContainer,
    routes: [
      ...WebsitesRoutes
    ]
  }
]

export default routes
