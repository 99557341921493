import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

// Components Local
import ButtonDisplay from '../ButtonDisplay'

// Components Text Editor
import LinkManage from '../../../../components/LinkManage'
import BadgeManage from '../../../../components/BadgeManage'
import IconManage from '../../../../components/IconManage'
import Box from '../../../../components/Box'
import { SwitchStyleSettings, SelectStyleSettings, InputStyleSettings } from '../../../../components/StyleSettings'

// Event
import { removeStateItem } from '../../../../functions/textEditorEvent'

// css
import css from './css/listsManage.css'


class ButtonManage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.setValue(),
    }
  }

  setValue = () => {
    const { _this, lang } = this.props
    const name = _this.state[`name_${lang}`]
    const { icon, link } = _this.state

    return {
      name: name || '',
      icon,
      link
    }
  }

  handleNameChange = (e) => {
    const name = e.target.value
    const { _this } = this.props

    this.setState({ name }, () => {
      _this.handleNameChange(name)
    })
  }

  handleIconChange = (icon) => {
    const { _this } = this.props

    this.setState({ icon }, () => {
      _this.handleIconChange(icon)
    })
  }

  handleLinkChange = (link) => {
    const { _this } = this.props

    this.setState({ link }, () => {
      _this.handleLinkChange(link)
    })
  }

  handleRemove = (index) => {
    setTimeout(() => {
      const { _this } = this.props
      removeStateItem(_this, { stateName: 'items', index })
      this.handleCloseAll()
    }, 0)
  }

  render() {
    const { _this, mode, loggedIn } = this.props
    const { name, icon, link } = this.state
    const linkValue = link && JSON.parse(link)

    return (
      <Fragment>
        <Box title="แสดงตัวอย่าง" icon="settings" bodyClassName="pd-10">
          <ButtonDisplay
            link={link}
            style={_this.state.style}
            icon={icon}
            name={name}
            mode={mode}
            loggedIn={loggedIn}
            className={_this.state.class}
          />
        </Box>

        <Box title="แสดงไอคอน" icon="settings" bodyClassName="pd-10">
          <SwitchStyleSettings
            _this={_this}
            label="แสดงไอคอนอย่างเดียว"
            styleName="btn-icon-only"
          />

          <div className="pd-top-15">
            <SwitchStyleSettings
              _this={_this}
              label="ไม่ต้องการปิดปุ่ม Disabled"
              styleName="btn-not-disabled"
            />
          </div>

          <div className="pd-top-15">
            <SelectStyleSettings
              label="ตำแหน่งไอคอน"
              _this={_this}
              styleName="btn-icon-position"
              options={[
                { label: 'เลือกตำแหน่ง', value: '' },
                { label: 'ซ้าย', value: 'left' },
                { label: 'ขวา', value: 'right' }
              ]}
            />
          </div>
        </Box>

        <Box title="แสดงไอคอนและตัวอักษรแนวตั้ง" icon="settings" bodyClassName="pd-10">
          <SwitchStyleSettings
            _this={_this}
            label="แสดงไอคอนและตัวอักษรแนวตั้ง"
            styleName="btn-icon-text-vertical"
          />
        </Box>

        <Box title="ป้าย (Badge)" icon="settings" bodyClassName="pd-10">
          <BadgeManage _this={_this} />
        </Box>

        <Box title="ปุ่มกด" icon="settings" bodyClassName="pd-10" className="mg-bottom-30">
          <div>
            <div className="font-0-8">ชื่อปุ่มกด</div>
            <input
              className={css.inputName}
              type="text"
              value={name}
              onChange={this.handleNameChange}
              placeholder="ชื่อเมนู"
              style={{
                border: '1px solid #ddd !important'
              }}
            />
          </div>

          <div className="mg-top-10">
            <div className="font-0-8">ไอคอน</div>

            <IconManage
              id={this.props.id}
              value={icon}
              onChange={this.handleIconChange}
            />
          </div>

          <div className="mg-top-15">
            <div className="font-0-8">ลิงก์</div>

            <LinkManage
              value={link}
              onChange={this.handleLinkChange}
            />
          </div>

          <div className="mg-top-10">
            <div className="font-0-8"></div>


          </div>
        </Box>

        { linkValue && linkValue.type === 'function' && linkValue.functionName === 'barcode-scanner' &&
          <Box title="สแกนบาร์โค้ด" iconFa="fas fa-barcode">
            <div className="pd-10">
              <InputStyleSettings
                label="url เปลี่ยนเส้นทาง"
                _this={_this}
                type="text"
                styleName="barcode-url-redirect"
              />
            </div>
          </Box>
        }
      </Fragment>
    )
  }
}

export default connect()(ButtonManage)
