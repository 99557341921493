import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import TextEditorCarousel from './TextEditorCarousel'
import TextEditorCarouselDisplay from './TextEditorCarouselDisplay'

let TextEditorCarousel = lazy(() => import('./TextEditorCarousel'))
TextEditorCarousel = suspenseComponent(TextEditorCarousel)

export {
  TextEditorCarousel,
  TextEditorCarouselDisplay
}
