import React, { useMemo } from 'react'
import ImgDisplay from './ImgDisplay'

class Img extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return this.props.paths.o !== nextProps.paths.o
  }

  render() {
    return <ImgDisplay {...this.props} />
  }
}

/*const Img = (props) => {
  return useMemo(() => <ImgDisplay {...props} />, [props.paths.l])
}*/

export default Img
