import React, { Component } from 'react'

import css from './css/collapsible.css'

class Collapsible extends Component {
  constructor(props) {
    super(props)
    this.state ={
      open: this.props.open || false,
      openTransition: this.props.open || false,
      height: '0px'
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.open !== this.props.open) {
      this.setClientHeight()
    }

    if (prevState.open !== this.state.open) {
      this.setClientHeight()
    }
  }

  componentDidMount() {
    this.setClientHeight()
  }

  setClientHeight = () => {
    const height = this.divElement.clientHeight

    this.setState({ height })
  }

  getClientHeight = () => {
    let height = 0

    if (this.divElement) {
      height = this.divElement.clientHeight
    }

    return height
  }

  handleClick = () => {
    const { open, openTransition } = this.state

    if (open || openTransition) {
      this.close()

    } else {
      this.open()
    }
  }

  open = () => {
    this.setState({ open: true }, () => {
      setTimeout(() => {
        this.setState({ openTransition: true })
      }, 0)

      if (this.props.onClick) {
        this.props.onClick()
      }
    })
  }

  close = () => {
    this.setState({ openTransition: false }, () => {
      setTimeout(() => {
        this.setState({ open: false })
      }, 500)

      if (this.props.onClick) {
        this.props.onClick()
      }
    })
  }



  render() {
    const { open, openTransition } = this.state
    const { className, style, children, title, iconTitle } = this.props
    const height = this.getClientHeight()

    const styleBody = {
      maxHeight: openTransition ? `${height}px` : '0px',
      overflow: openTransition ? 'visible' : 'hidden',
      opacity: openTransition ? '1' :'0',
      visibility: openTransition ? 'visible' :'hidden',
    }

    return (
      <div className={`${css.collapsible} ${className}`} style={style}>
        <div
          className={`${css.title} ${this.props.titleClassName}`}
          onClick={this.handleClick}
          style={this.props.titleStyle}>

          { !iconTitle &&
            <table>
              <tbody>
                <tr>
                  <td className="pd-0">
                    { children[0] }
                  </td>
                  <td className="pd-0 center vertical-middle pd-right-5" width="40">
                    <a className="btn btn-mini btn-shadow-none border-radius-10 btn-white">
                      <i className="material-icons">{this.state.open ? 'expand_less' : 'expand_more'}</i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          }

          { iconTitle &&
            <table>
              <tbody>
                <tr>
                  <td width="40" className="pd-0 center">
                    <i className="material-icons">{ iconTitle }</i>
                  </td>
                  <td className="pd-0">
                    { title }
                  </td>
                  <td className="pd-0 center vertical-middle" width="40">
                    <i className="material-icons">{this.state.open ? 'expand_less' : 'expand_more'}</i>
                  </td>
                </tr>
              </tbody>
            </table>
          }

        </div>

        <div className={`${css.body}`} style={styleBody}>
          <div ref={ (divElement) => this.divElement = divElement}>
            { iconTitle ? children : children[1] }
          </div>
        </div>
      </div>
    )
  }
}

export default Collapsible
