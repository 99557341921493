import { lazy } from 'react'
import suspenseComponent from 'utils/suspenseComponent'

import SiteContainer from 'client/containers/websites/Sites/containers/SiteContainer'
import SiteMainDisplayContainer from 'client/components/SitesManage/Sites/containers/SiteMainDisplayContainer/SiteMainDisplayContainer'
import SiteDisplayContainer from 'client/containers/websites/Sites/containers/SiteDisplayContainer'
import SiteModalDisplayContainer from 'client/components/SitesManage/Sites/containers/SiteModalDisplayContainer'
import OrderContainer from 'client/containers/websites/Orders/containers/OrderContainer'

// Routes
import CustomerRoutes from './routes/CustomerRoutes'

// pages
import PageDisplayPage from 'client/containers/websites/Sites/pages/PageDisplayPage'
import OmisePage from 'client/containers/websites/Payements/pages/OmisePage/OmisePage'


// Component Pages
const OrderDeatilsPage = lazy(() => import('client/containers/websites/Orders/pages/OrderDeatilsPage'))


const WebsitesRoutes = [
  {
    path: '/',
    component: SiteContainer,
    routes: [
      {
        path: '/customer/order-details/:orders_id',
        component: SiteDisplayContainer,
        routes: [
          {
            path: '/customer/order-details/:orders_id',
            component: OrderContainer,
            params: { forUser: true },
            routes: [
              {
                path: '/customer/order-details/:orders_id',
                exact: true,
                params: { forUser: true },
                component: suspenseComponent(OrderDeatilsPage)
              }
            ]
          },
        ]
      },

      {
        path: '/customer/affiliate/order-details/:orders_id',
        component: SiteDisplayContainer,
        routes: [
          {
            path: '/customer/affiliate/order-details/:orders_id',
            component: OrderContainer,
            params: { forUser: true, affiliate: true },
            routes: [
              {
                path: '/customer/affiliate/order-details/:orders_id',
                exact: true,
                params: { forUser: true, affiliate: true },
                component: suspenseComponent(OrderDeatilsPage)
              }
            ]
          },
        ]
      },

      {
        path: '/salepage/:single_page_products_id/order-details/:orders_id',
        component: OrderContainer,
        params: { forUser: true, salePage: true },
        routes: [
          {
            path: '/salepage/:single_page_products_id/order-details/:orders_id',
            exact: true,
            params: { forUser: true, salePage: true },
            component: suspenseComponent(OrderDeatilsPage)
          }
        ]
      },
      {
        path: '/salepage/:single_page_products_id/:content_group_page_id',
        component: SiteDisplayContainer,
        params: { forUser: true, contentGroupPage: true },
        routes: [
          {
            path: '/salepage/:single_page_products_id/:content_group_page_id',
            exact: true,
            params: { forUser: true, contentGroupPage: true },
            component: PageDisplayPage
          }
        ]
      },

      ...CustomerRoutes,

      {
        path: '/omise',
        component: OmisePage,
      },

      {
        path: '/product-details/:dbProductsNo/:products_id',
        component: SiteDisplayContainer,
        routes: [
          {
            path: '/product-details/:dbProductsNo/:products_id',
            exact: true,
            component: PageDisplayPage
          }
        ]
      },

      {
        path: '/product-manage/:products_id',
        component: SiteDisplayContainer,
        routes: [
          {
            path: '/product-manage/:products_id',
            exact: true,
            component: PageDisplayPage
          }
        ]
      },

      {
        path: '/salepage/:single_page_products_id',
        component: SiteDisplayContainer,
        routes: [
          {
            path: '/salepage/:single_page_products_id',
            exact: true,
            component: PageDisplayPage
          }
        ]
      },


      {
        path: '/orders/:orders_id',
        component: OrderContainer,
        routes: [
          {
            path: '/orders/:orders_id',
            exact: true,
            component: suspenseComponent(OrderDeatilsPage)
          }
        ]
      },
      {
        path: '/profile/:path_1?',
        exact: true,
        component: SiteDisplayContainer,
        routes: [
          {
            path: '/profile/:path_1?',
            exact: true,
            component: PageDisplayPage
          }
        ]
      },

      /*{
        path: '/:lang(th|en)?/profile/:path_1?',
        exact: true,
        component: SiteMainDisplayContainer,
        routes: [
          {
            path: '/:lang(th|en)?/profile/:path_1?',
            exact: true,
            component: PageDisplayPage
          }
        ]
      },*/

      {
        path: '/:lang(th|en)?/confirm-account-token/:token',
        exact: true,
        component: SiteMainDisplayContainer,
        routes: [
          {
            path: '/:lang(th|en)?/confirm-account-token/:token',
            exact: true,
            component: PageDisplayPage
          }
        ]
      },

      {
        path: '/:lang(th|en)?/:path_1?/:path_2?/:path_3?',
        component: SiteMainDisplayContainer,
        routes: [
          {
            path: '/:lang(th|en)?/:path_1?/:path_2?/:path_3?',
            params: {
              pageModal: { pathClose: '/', pathUseModal: '/m/' }
            },
            component: PageDisplayPage,
            routes: [
              {
                path: '/:lang(th|en)?/:path_1?/:path_2?/:routeType(m|s)/:pageType(product-details|article-details|product-categories)/:_id',
                component: SiteModalDisplayContainer,
                routes: [
                  {
                    path: '/:lang(th|en)?/:path_1?/:path_2?/:routeType(m|s)/:pageType(product-details|article-details|product-categories)/:_id',
                    component: PageDisplayPage,
                    exact: true,
                  }
                ]
              }
            ]
          }
        ]
      },

      /*{
        path: '/:path_1?/:path_2?/:path_3?',
        component: SiteMainDisplayContainer,
        routes: [
          {
            path: '/:path_1?/:path_2?/:path_3?',
            params: {
              pageModal: { pathClose: '/', pathUseModal: '/m/' }
            },
            component: PageDisplayPage,
            routes: [
              {
                path: '/:path_1?/:path_2?/:routeType(m|s)/:pageType(product-details|article-details|product-categories)/:_id',
                component: SiteModalDisplayContainer,
                routes: [
                  {
                    path: '/:path_1?/:path_2?/:routeType(m|s)/:pageType(product-details|article-details|product-categories)/:_id',
                    component: PageDisplayPage,
                    exact: true,
                  }
                ]
              }
            ]
          }
        ]
      }*/

    ]
  }
]

export default WebsitesRoutes
