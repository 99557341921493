import React, { Component } from 'react'


// Components Local
import InputBorder from './components/InputBorder'

// Functions
import { addStyle, removeStyle } from '../../../functions/textEditorFunctions'

// css
import css from '../css/styleSettings.css'

const defaultValue = {
  widthTop: 0,
  widthRight: 0,
  widthBottom: 0,
  widthLeft: 0,

  styleTop: 'solid',
  styleRight: 'solid',
  styleBottom: 'solid',
  styleLeft: 'solid',

  colorTop: '#ddd',
  colorRight: '#ddd',
  colorBottom: '#ddd',
  colorLeft: '#ddd',

  borderWidth: '0px 0px 0px 0px',
  borderStyle: 'solid solid solid solid',
  borderColor: '#ddd #ddd #ddd #ddd',
}

class BorderSetting extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...defaultValue
    }
  }

  findDuplicates = (arr) => {
    return arr.filter((item, index) => arr.indexOf(item) != index)
  }

  checkIndividual = () => {
    const { borderWidth, borderStyle, borderColor } = this.state
    const width = borderWidth.split(' ')
    const style = borderStyle.split(' ')
    const color = borderColor.split(' ')

    let statusBorderWidth = this.findDuplicates(width)
    let statusBorderStyle = this.findDuplicates(style)
    let statusBorderColor = this.findDuplicates(color)

    statusBorderWidth = statusBorderWidth.length === 0 ? true : false
    statusBorderStyle = statusBorderStyle.length === 0 ? true : false
    statusBorderColor = statusBorderColor.length === 0 ? true : false

    if (statusBorderWidth === true || statusBorderStyle === true || statusBorderColor === true) {
      this.setState({ individual: true })
    }
  }

  componentDidMount() {
    const { _this } = this.props
    const { style } = _this.state
    const styleName = this.props.styleName || 'border'

    const borderWidth = style[`${styleName}-width`]
    const borderStyle = style[`${styleName}-style`]
    const borderColor = style[`${styleName}-color`]

    this.setValue({ borderWidth, borderStyle, borderColor, submit: false })
  }

  splitValue = ({ value, typeValue, prefix, suffix, defaultValue, fullName }) => {
    suffix = suffix || ''

    if (value) {
      const valueSplit = value.split(' ')

      const top = valueSplit[0] ? (typeValue === 'number' ? parseInt(valueSplit[0]) : valueSplit[0]) : defaultValue
      const right = valueSplit[1] ? (typeValue === 'number' ? parseInt(valueSplit[1]) : valueSplit[1]) : defaultValue
      const bottom = valueSplit[2] ? (typeValue === 'number' ? parseInt(valueSplit[2]) : valueSplit[2]) : defaultValue
      const left = valueSplit[3] ? (typeValue === 'number' ? parseInt(valueSplit[3]) : valueSplit[3]) : defaultValue

      const valueFull = `${top}${suffix} ${right}${suffix} ${bottom}${suffix} ${left}${suffix}`

      return {
        [`${prefix}Top`]: top,
        [`${prefix}Right`]: right,
        [`${prefix}Bottom`]: bottom,
        [`${prefix}Left`]: left,
        [fullName]: valueFull
      }
    }
  }

  setValue = ({ borderWidth, borderStyle, borderColor, submit }) => {
    const width = this.splitValue({ value: borderWidth, typeValue: 'number', prefix: 'width', suffix: 'px', defaultValue: 0, fullName: 'borderWidth' })
    const style = this.splitValue({ value: borderStyle, prefix: 'style', defaultValue: 'solid', fullName: 'borderStyle' })
    const color = this.splitValue({ value: borderColor, prefix: 'color', defaultValue: '#ddd', fullName: 'borderColor' })

    const params = {
      ...width,
      ...style,
      ...color
    }

    this.setState(params, () => {
      this.checkIndividual()

      if (submit) {
        this.setBorder(this.state.border)
      }
    })
  }

  handleWidthChange = (e, position) => {
    let value = parseInt(e.target.value)
    value = value || 0

    let border

    if (position === 'all') {
      border = {
        widthTop: value,
        widthRight: value,
        widthBottom: value,
        widthLeft: value,
      }
    } else {
      border = {
        [`width${position}`]: value
      }
    }

    this.setState(border, () => {
      this.converBorder()
    })
  }

  handleStyleChange = (e, position) => {
    let value = e.target.value || 'solid'
    let border

    if (position === 'all') {
      border = {
        styleTop: value,
        styleRight: value,
        styleBottom: value,
        styleLeft: value,
      }
    } else {
      border = {
        [`style${position}`]: value
      }
    }

    this.setState(border, () => {
      this.converBorder()
    })
  }

  handleColorChange = (color, position) => {
    let border

    if (position === 'all') {
      border = {
        colorTop: color,
        colorRight: color,
        colorBottom: color,
        colorLeft: color
      }
    } else {
      border = {
        [`color${position}`]: color
      }
    }

    this.setState(border, () => {
      this.converBorder()
    })
  }

  converBorder = () => {
    const {
      widthTop, widthRight, widthBottom, widthLeft,
      styleTop, styleRight, styleBottom, styleLeft,
      colorTop, colorRight, colorBottom, colorLeft,
    } = this.state

    const borderWidth = `${widthTop}px ${widthRight}px ${widthBottom}px ${widthLeft}px`
    const borderStyle = `${styleTop} ${styleRight} ${styleBottom} ${styleLeft}`
    const borderColor = `${colorTop} ${colorRight} ${colorBottom} ${colorLeft}`

    this.setState({ borderWidth, borderStyle, borderColor }, () => {
      this.setBorder({ borderWidth, borderStyle, borderColor })
    })
  }

  handleRemoveBorder = () => {
    const { _this } = this.props
    const styleName = this.props.styleName || 'border'

    this.setState(defaultValue, () => {
      removeStyle(_this, null, {
        styleMultiple: [
          { style: `${styleName}-width` },
          { style: `${styleName}-style` },
          { style: `${styleName}-color` }
        ]
      })
    })
  }

  setBorder = ({ borderWidth, borderStyle, borderColor }) => {
    const { _this } = this.props
    const styleName = this.props.styleName || 'border'

    if (borderWidth && borderStyle && borderColor) {
      addStyle(_this, {
        styleMultiple: [
          { style: `${styleName}-width`, value: borderWidth },
          { style: `${styleName}-style`, value: borderStyle },
          { style: `${styleName}-color`, value: borderColor }
        ]
      })
    }
  }

  handleNoBorder = () => {
    this.setBorder({ borderWidth: '0px', borderStyle: 'solid', borderColor: '#ffffff' })
  }

  handleSetIndividual = (value) => {
    this.setState({ individual: value })
  }

  render() {
    const {
      widthTop, widthRight, widthBottom, widthLeft,
      styleTop, styleRight, styleBottom, styleLeft,
      colorTop, colorRight, colorBottom, colorLeft,
      individual
    } = this.state

    return (
      <div className="text-left">
        <table>
          <tbody>
            <tr>
              <td className="pd-0 font-0-7">เส้นขอบ (border)</td>
              <td className="pd-0 font-0-7 text-right">
                <a className={css.btn} onClick={this.handleRemoveBorder}>รีเซ็ต</a>
                <a className={css.btn} onClick={this.handleNoBorder}>ไม่มีเส้น</a>
                <a className={`${css.btn} ${!individual && css.active}`} onClick={() => this.handleSetIndividual(false)}>ปกติ</a>
                <a className={`${css.btn} ${individual && css.active}`} onClick={() => this.handleSetIndividual(true)}>ขั้นสูง</a>
              </td>
            </tr>
          </tbody>
        </table>

        <div>

          { !individual ?
            <InputBorder
              label="ทุกด้าน"
              position="all"
              borderWidth={widthTop}
              borderStyle={styleTop}
              borderColor={colorTop}
              borderWidthChange={this.handleWidthChange}
              borderStyleChange={this.handleStyleChange}
              borderColorChange={this.handleColorChange}
            />
          :
            <div>
              <InputBorder
                label="ด้านบน"
                position="Top"
                borderWidth={widthTop}
                borderStyle={styleTop}
                borderColor={colorTop}
                borderWidthChange={this.handleWidthChange}
                borderStyleChange={this.handleStyleChange}
                borderColorChange={this.handleColorChange}
              />

              <InputBorder
                label="ด้านขาว"
                position="Right"
                borderWidth={widthRight}
                borderStyle={styleRight}
                borderColor={colorRight}
                borderWidthChange={this.handleWidthChange}
                borderStyleChange={this.handleStyleChange}
                borderColorChange={this.handleColorChange}
              />

              <InputBorder
                label="ด้านล่าง"
                position="Bottom"
                borderWidth={widthBottom}
                borderStyle={styleBottom}
                borderColor={colorBottom}
                borderWidthChange={this.handleWidthChange}
                borderStyleChange={this.handleStyleChange}
                borderColorChange={this.handleColorChange}
              />

              <InputBorder
                label="ด้านซ้าย"
                position="Left"
                borderWidth={widthLeft}
                borderStyle={styleLeft}
                borderColor={colorLeft}
                borderWidthChange={this.handleWidthChange}
                borderStyleChange={this.handleStyleChange}
                borderColorChange={this.handleColorChange}
              />
            </div>
          }
        </div>
      </div>
    )
  }
}

export default BorderSetting
