export const attributeValue = ({ attribute, lang="local" }) => {
  const data = attribute

  let nameLang, typeName, avatarName, descriptionsLang, statusName, statusIcon, statusColor, inputTypeName, typePath

  if (data) {
    nameLang = data.name ? data.name[lang] : null
    avatarName = nameLang ? [nameLang] : null
    descriptionsLang = data.descriptions ? data.descriptions[lang] : null
    typePath = `${data.type}s`

    // Status
    if (data.status === 'open') {
      if (lang === 'local') statusName = 'เปิด'

      statusIcon = "public"
      statusColor = "success"

    } else if (data.status === 'close') {
      if (lang === 'local') statusName = 'ปิด'
      statusIcon = "lock"
      statusColor = "warning"

    } else if (data.status === 'trash') {
      if (lang === 'local') statusName = 'ถังขยะ'
      statusIcon = "delete"
      statusColor = "error"
    }

    // inputType
    if (data.inputType === 'choice') {
      if (lang === 'local') inputTypeName = 'ตัวเลือก'

    } else if (data.inputType === 'boolean') {
      if (lang === 'local') inputTypeName = 'ค่าจริงหรือเท็จ'

    } else if (data.inputType === 'string') {
      if (lang === 'local') inputTypeName = 'ตัวอักษร'
    }

    // type
    if (data.type === 'product') {
      typeName = 'สินค้า'
    } else if (data.type === 'article') {
      typeName = 'บทความ'
    } else if (data.type === 'note') {
      typeName = 'บันทึก'
    } else if (data.type === 'customer') {
      typeName = 'ลูกค้า'
    }

    return {
      ...data,
      nameLang,
      avatarName,
      statusName, statusIcon, statusColor,
      inputTypeName,
      descriptionsLang,
      typePath,
      typeName
    }
  }
}

export const attributeItemValue = ({ attribute, item, lang="local" }) => {
  let nameLang, labelLang, avatarName, inputTypeName

  if (item) {
    nameLang = item.name ? item.name[lang] : null
    labelLang = item.label ? item.label.local : null
    avatarName = item.code ? [item.code] : null

    // inputType
    if (item.inputType === 'choice') {
      if (lang === 'local') inputTypeName = 'ตัวเลือก'

    } else if (item.inputType === 'boolean') {
      if (lang === 'local') inputTypeName = 'ค่าจริงหรือเท็จ'

    } else if (item.inputType === 'string') {
      if (lang === 'local') inputTypeName = 'ตัวอักษร'

    } else if (item.inputType === 'string-lang') {
      if (lang === 'local') inputTypeName = 'ตัวอักษร (รองรับหลายภาษา)'

    } else if (item.inputType === 'text-lang') {
      if (lang === 'local') inputTypeName = 'ข้อความหลายตัวอักษร (รองรับหลายภาษา)'

    } else if (item.inputType === 'number') {
      if (lang === 'local') inputTypeName = 'ตัวเลข'

    } else if (item.inputType === 'img-file') {
      if (lang === 'local') inputTypeName = 'รูปภาพ'

    } else if (item.inputType === 'geo') {
      if (lang === 'local') inputTypeName = 'ที่อยู่'
    }
  }

  return {
    ...item,
    nameLang,
    labelLang,
    avatarName,
    inputTypeName
  }
}

export const attributeNotiValue = ({ item, lang="local" }) => {
  let nameLang, subjectLang, fromNameLang, emailBodyLang, msgBodyLang, typeName, typeIcon, statusName, statusIcon, statusColor

  if (item) {
    nameLang = item.name ? item.name[lang] : null
    subjectLang = item.subject ? item.subject[lang] : null
    fromNameLang = item.fromName ? item.fromName[lang] : null
    emailBodyLang = item.emailBody ? item.emailBody[lang] : null
    msgBodyLang = item.msgBody ? item.msgBody[lang] : null

    // Status
    if (item.status) {
      if (lang === 'local') statusName = 'เปิด'

      statusIcon = "public"
      statusColor = "success"

    } else {
      if (lang === 'local') statusName = 'ปิด'
      statusIcon = "lock"
      statusColor = "warning"
    }


    // type
    if (item.type === 'email') {
      if (lang === 'local') typeName = 'อีเมล'
      typeIcon = "fas fa-envelope"

    } else if (item.type === 'facebook') {
      if (lang === 'local') typeName = 'เฟซบุ๊ค'
      typeIcon = "fab fa-facebook-messenger"

    } else if (item.type === 'line-notify') {
      if (lang === 'local') typeName = 'ไลน์แจ้งเตือน'
      typeIcon = "fab fa-line"
    }
  }

  return {
    ...item,
    nameLang, subjectLang, fromNameLang, emailBodyLang, msgBodyLang,
    typeName, typeIcon,
    statusName, statusIcon, statusColor,
  }
}

export const attributeChoiceValue = (attribute, choice, lang="local") => {
  let nameLang, labelLang, avatarName

  if (choice) {
    nameLang = choice.name ? choice.name[lang] : null
    labelLang = choice.label ? choice.label[lang] : null
    avatarName = labelLang ? [labelLang] : null
  }

  return {
    ...choice,
    nameLang,
    labelLang,
    avatarName
  }
}

export const getAttributeItems = ({ data, attribute, lang="local" }) => {
  let attributes = data.attributes || {}

  if (typeof attributes === 'string') {
    attributes = JSON.parse(attributes)
  }

  if (!attribute) {
    attribute = data.attributes_id
  }

  let items, attributeItems = []

  if (attribute) {
    attribute = attributeValue({ attribute, lang })

    items = attribute.items || []

    items.map(item => {
      item = attributeItemValue({ attribute, item, lang })

      let valueId, code
      let value = attributes[item._id]
      let type = item.inputType



      if (item.inputType === 'choice') {
        let choice = attribute.choices.filter(choice => choice._id === value)[0]

        choice = attributeChoiceValue(attribute, choice, lang)
        value = choice && choice.nameLang
        valueId = choice && choice._id,
        code = item.code

      } else if (item.inputType === 'string-lang' || item.inputType === 'text-lang') {
        value = value
        value = value && value[lang],
        code = item.code

      } else if (item.inputType === 'string') {
        value = value
        code = item.code

      } else if (item.inputType === 'boolean') {
        value = value
        code = item.code
      }

      attributeItems.push({
        item,
        label: `${item.labelLang} (${item.code})`,
        value,
        code,
        valueId,
        type
      })
    })
  }

  return attributeItems
}

export const getDataAttributes = ({ data, attribute }) => {
  let attributes = data.attributes || {}

  if (typeof attributes === 'string') {
    attributes = JSON.parse(attributes)
  }

  if (!attribute) {
    attribute = data.attributes_id
  }

  const items = attribute && attribute.items || []
  const choices = attribute && attribute.choices || []
  const newAttributes = {}

  for (let items_id in attributes) {
    const item = items.filter(item => item._id === items_id)[0]

    if (item) {
      let newAttr

      if (item.inputType === 'choice') {
        const choice = choices.filter(choice => choice.items_id === items_id && choice._id === attributes[items_id])[0]

        newAttr = {
          label: item.label || item.name,
          code: item.code,
          value: choice ? choice.name : '',
          images: choice ? choice.images : []
        }

      } else if (item.inputType === 'boolean') {
        let valueBoolean

        if (attributes[items_id] !== undefined) {
          valueBoolean = (attributes[items_id] === 'true')
        } else {
          valueBoolean = ''
        }

        newAttr = {
          label: item.label || item.name,
          code: item.code,
          value: valueBoolean
        }

      } else {
        newAttr = {
          label: item.label || item.name,
          code: item.code,
          value: attributes[items_id] || '-'
        }
      }

      if (item.code) {
        newAttributes[`${item.code}`] = newAttr
      } else {
        newAttributes[`${item.name.local}`] = newAttr
      }
    }
  }

  return newAttributes
}


export const getNotiParams = ({ params, attribute }) => {
  let notificationParams = {}

  for (let key in params) {
    const s = key.split('attributes.')

    if (s[1]) {
      const ss = s[1].split('.')

      let id

      if (ss[0] && ss[1]) {
        id = ss[0]
      } else {
        id = s[1]
      }

      const atrrItems = attribute.items
      const item = atrrItems.filter(item => item._id === id)[0]

      if (item && item.code) {
        notificationParams[item.code] = params[key]
      }
    }
  }

  return notificationParams
}




export const getSearchAttributes = ({ attribute, lang="local" }) => {
  const atrrItems = attribute.items
  const useSearchItems = atrrItems.filter(item => item.useSearch === true)
  const searchAttributes = []

  useSearchItems.map(item => {
    let name

    if (item.inputType === 'string-lang') {
      name = `attributes.${item._id}.${lang}`

    } else if (item.inputType === 'string') {
      name = `attributes.${item._id}`
    }

    if (name) {
      searchAttributes.push({
        name,
        options: 'i'
      })
    }
  })

  return searchAttributes
}
