import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import css from './css/dropdown.css'

class DropdownDefault extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      openTransition: false
    }
  }

  componentDidMount() {
    this.mounted = true
    document.addEventListener('click', this.handleDocumentClick)
    if (this.props.onRef) this.props.onRef(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({ open: this.props.open })
    }
  }

  componentWillUnmount() {
    this.mounted = false
    document.removeEventListener('click', this.handleDocumentClick)
    if (this.props.onRef) this.props.onRef(null)
  }

  handleDocumentClick = (e) => {
    if (this.state.open) {
      if (!ReactDOM.findDOMNode(this).contains(e.target)) {
        this.closeDropdown()
      }
    }

    if (this.props.close) {
      this.closeDropdown()
    }
  }

  openDropdown = () => {
    if (this.state.open) {
      this.closeDropdown()
    } else {
      this.setState({ open: true }, () => {
        setTimeout(() => {
          this.setState({ openTransition: true })
        }, 100)

        if (this.props.getOpenValue) {
          this.props.getOpenValue(this.state.open)
        }
      })
    }
  }

  closeDropdown = () => {
    this.setState({ openTransition: false }, () => {
      setTimeout(() => {
        this.mounted && this.setState({ open: false })
      }, 500)

      if (this.props.getOpenValue) {
        this.props.getOpenValue(this.state.open)
      }
    })
  }

  render() {
    const styleContent = this.props.styleContent || {}
    const style = {
      ...styleContent,
      visibility: this.state.openTransition ? 'visible' :'hidden',
      opacity: this.state.openTransition ? '1' :'0',
      //height: this.state.openTransition ? 'auto' :'0'
    }

    return (
      <div className={css.boxDropdown} style={this.props.style}>
        <div className={css.boxClick} onClick={this.openDropdown}>
          { this.props.children[0] }
        </div>

        { this.state.open &&
          <div className={`${css.content} ${this.props.contentClassName}`} style={style}>
            { this.props.children[1] }
          </div>
        }
      </div>
    )
  }
}

export default DropdownDefault
