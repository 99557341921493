import React, { Fragment, useMemo } from 'react'

// Components Global
import { Column } from '../../Columns'

// Components Editor
import LinkDisplay from '../../TextEditor/components/LinkDisplay'
import { checkCondition } from '../../TextEditor/components/ConditionSetting/js/conditionSetting'

// Components Local
import { ContentBlockDisplay } from '../ContentBlock'

// Functions
import {
  generateClassToString
} from '../../TextEditor/functions/textEditorFunctions'

import {
  setStyleValue,
  setAnimate
} from '../../TextEditor/components/ElementStyles/components/js/setDivStyle'


// css
import css from './css/columnItem.css'


const ColumnItemDisplay = (props) => {
  const { column, mode, apiData, templateData, dataStore } = props
  const boxStyle = column.boxStyle ? JSON.parse(column.boxStyle) : {}
  const boxClass = column.boxClass ? JSON.parse(column.boxClass) : []
  const link = column.link

  const col = column && column.column ? JSON.parse(column.column) : {}
  const columnValue = `col-xxs-${col.xxs} col-xs-${col.xs} col-sm-${col.sm} col-md-${col.md} col-lg-${col.lg} col-xlg-${col.xlg}`
  const name = 'column'
  const modeStyle = !mode && 'display'

  //const show = checkCondition({ style: boxStyle, mode, apiData, templateData, dataStore, props })
  const show = useMemo(() => checkCondition({ style: boxStyle, mode, apiData, templateData, dataStore, props }), [
    mode,
    boxStyle && JSON.stringify(boxStyle),
    apiData && JSON.stringify(apiData),
    templateData && JSON.stringify(templateData),
    dataStore && JSON.stringify(dataStore),
  ])


  // style
  const styleDf = useMemo(() => setStyleValue({ name, style: boxStyle, mode }), [boxStyle && JSON.stringify(boxStyle)])
  const styleDfHover = useMemo(() => setStyleValue({ name: `${name}-hover`, style: boxStyle, mode }), [boxStyle && JSON.stringify(boxStyle)])
  const styleDfClick = useMemo(() => setStyleValue({ name: `${name}-active-click`, style: boxStyle, mode }), [boxStyle && JSON.stringify(boxStyle)])
  const styleDfVW = useMemo(() => setStyleValue({ name, style: boxStyle, vw: true, mode }), [boxStyle && JSON.stringify(boxStyle)])
  const styleTablet = useMemo(() => setStyleValue({ device: 'tablet', name, style: boxStyle, mode }), [boxStyle && JSON.stringify(boxStyle)])
  const styleMobile = useMemo(() => setStyleValue({ device: 'mobile', name, style: boxStyle, mode }), [boxStyle && JSON.stringify(boxStyle)])
  const attrAnimate = useMemo(() => setAnimate({ name, style: boxStyle }), [boxStyle && JSON.stringify(boxStyle)])


  return (
    <Fragment>
      { show &&
        <Column
          col={`column ${css.column} ${columnValue}`}
          className={column.columnClass || ''}
          style={{
            justifyContent: boxStyle['column-justify-content'],
            textAlign: boxStyle['column-text-align'],
          }}>


          { link && link !== '{}' ?
            <LinkDisplay
              mode={mode}
              value={link}
              apiData={apiData}
              templateData={templateData}>

              <div
                className={`editor-preview column-jsx ${generateClassToString(boxClass)}`}
                style={{
                  cursor: link && link.type && 'pointer'
                }}>

                  { column.items.map(item => {
                    return (
                      <ContentBlockDisplay {...props} key={item._id} block={item} />
                    )
                  }) }
              </div>
            </LinkDisplay>
          :
            <div
              {...attrAnimate}
              className={`editor-preview column-jsx ${generateClassToString(boxClass)}`}
              style={{
                cursor: link && link.type && 'pointer'
              }}>

              { column.items.map(item => {
                return (
                  <ContentBlockDisplay {...props} key={item._id} block={item} />
                )
              }) }
            </div>
          }


          <style jsx>{`
            .column-jsx {
              ${styleDf}

              margin: ${boxStyle[`${name}-margin`] || boxStyle['margin']};
              padding: ${boxStyle[`${name}-padding`] || boxStyle['padding']};
              height: ${boxStyle[`${name}-height-full`]};
            }

            .column-jsx:hover {
              ${styleDfHover}
            }

            .column-jsx:active {
              ${styleDfClick}
            }

            .column-jsx :global(.section-jsx),
            .column-jsx :global(.row-jsx),
            .column-jsx :global(.row-dp-flex) {
              height: ${boxStyle[`${name}-height-full`]};
            }

            @media (min-width: 1500px) {
              .column-jsx {
                ${styleDfVW}

                margin: ${boxStyle[`${name}-margin-vw`] || boxStyle['margin-vw']};
                padding: ${boxStyle[`${name}-padding-vw`] || boxStyle['padding-vw']};
              }
            }

            @media (min-width: 601px) and (max-width: 1024px) {
              .column-jsx {
                ${styleTablet}
              }
            }

            @media (min-width: 50px) and (max-width: 600px) {
              .column-jsx {
                ${styleMobile}
              }
            }
          `}</style>
        </Column>
      }
    </Fragment>
  )
}

export default ColumnItemDisplay
