import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import TextEditorTable from './TextEditorTable'
import TextEditorTableDisplay from './TextEditorTableDisplay'

let TextEditorTable = lazy(() => import('./TextEditorTable'))
TextEditorTable = suspenseComponent(TextEditorTable)

export { TextEditorTable, TextEditorTableDisplay }
