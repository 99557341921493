import { getData } from '../../ApiSettings/js/apiSettings'
import { getApiKeyFull } from '../../../functions/textEditorEvent';


export const getEventUpdateUrlSearch = ({ style, mode, props }) => {
  const { apiData } = props
  let event

  const {
    updateUrlSearch,
    updateUrlSearchEvent,
    updateUrlSearchKey,
    updateUrlSearchValue,
    updateUrlSearchValueType,
    mouseLeaveRemove,
  } = style || {}

  if (!mode && updateUrlSearch && updateUrlSearchEvent) {
    event = {}

    const params = {
      props,
      event: updateUrlSearchEvent,
      key: updateUrlSearchKey,
      value: updateUrlSearchValue,
      valueType: updateUrlSearchValueType
    }

    if (updateUrlSearchEvent === 'click') {
      event['onClick'] = () => updateUrl(params)

    } else if (updateUrlSearchEvent === 'mouseEnter') {
      event['onMouseEnter'] = () => updateUrl(params)

      if (mouseLeaveRemove) {
        event['onMouseLeave'] = () => updateUrl(params, true)
      }
    }
  }

  return event
}

const updateUrl = ({ props, event, key, value, valueType }, remove) => {
  const { location, history, apiData } = props
  const params = new URLSearchParams(location.search)

  if (remove) {
    if (params.get(key)) {
      params.delete(key)
    }
  } else {
    if (event === 'click' || event === 'mouseEnter') {

      if (valueType === 'index' || valueType === 'api') {
        const condition = { dataType: 'api' }
        const data = getData({ condition, apiData })

        if (data) {
          if (valueType === 'index') {
            value = data.loopIndex
          } else {
            value = getApiKeyFull({ keyFull: value, data })
          }
        }
      }

      if (value !== undefined) {
        if (params.get(key)) {
          params.set(key, value)
        } else {
          params.append(key, value)
        }
      }
    }
  }

  history.push(`${location.pathname}?${params.toString()}`)
}
