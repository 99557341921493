import React from 'react'

// Components Gobal
import DropdownSidebar from '../../../DropdownSidebar'

// Components Local
import BlockStyle from '../BlockStyle'
import BoxSettingsSideBar from '../BoxSettingsSideBar'
import ApiSettings from '../ApiSettings'
import BoxResponsiveSettings from '../BoxResponsiveSettings'
import ConditionSetting from '../ConditionSetting'
import UpdateUrlSearchSetting from '../UpdateUrlSearchSetting'

// css
import css from './css/boxSelect.css'


const BoxSelect = (props) => {
  const ContentComponent = props.contentSettings
  const StyleComponent = props.styleSettings
  const { _this, name, blockName } = props
  const { lang } = _this && _this.props || {}
  const { mode, loggedIn } = _this && _this.props || {}

  return (
    <div
      className={` editor-preview ${props.className} ${props.openTools === true ? css.boxSelect : css.boxUnselect} ${props.blockFull && 'editor-block-full'}`}
      style={props.style}>

      {/************* title **************/}
      { props.title && !props.hiddenTitle &&
        <div className={`font-0-7 ${css.title}`}>{props.title}</div>
      }
      {/************* title **************/}


      {/************* children **************/}
      { _this ?
        <BlockStyle
          {...props}
          mode={mode}
          loggedIn={loggedIn}
          edit
          name={blockName || name}
          style={_this.state.style}
          className={_this.state.className}>

          { props.children }
        </BlockStyle>
      :
        props.children
      }
      {/************* children **************/}


      {/************* side bar **************/}
      { (props.contentSettings || props.styleSettings || props.apiSettings) &&
        <DropdownSidebar
          bgBlock
          activeId={props.activeId}
          onRef={props.onRef}
          style={{ display: 'block' }}
          styleContent={{ width: '340px' }}>

          <div></div>

          <BoxSettingsSideBar
            title={`ตั้งค่า${props.title}`}
            activeId={props.activeId}
            content={props.contentSettings}
            style={props.styleSettings}
            api={props.apiSettings}
            sidebar={props.sidebar}>

            { props.contentSettings &&
              <div className="pd-bottom-20">
                <ContentComponent lang={lang} _this={props._this} />
              </div>
            }

            {/* props.styleSettings &&
              <StyleComponent _this={props._this} name={props.name} type={props.type} />
            */}

            <BoxResponsiveSettings>
              <StyleComponent lang={lang} device="desktop" _this={props._this} name={name} type={props.type} />

              <StyleComponent lang={lang} device="tablet" _this={props._this} name={`${name}-tablet`} type={props.type} />

              <StyleComponent lang={lang} device="mobile" _this={props._this} name={`${name}-mobile`} type={props.type} />
            </BoxResponsiveSettings>

            { props.apiSettings &&
              <ApiSettings lang={lang} _this={props._this} onChange={props.apiChange} />
            }

            <div>
              <div className="mg-bottom-10">
                <ConditionSetting lang={lang} _this={props._this} />
              </div>

              <div className="mg-bottom-20">
                <UpdateUrlSearchSetting lang={lang} _this={props._this} />
              </div>
            </div>

          </BoxSettingsSideBar>
        </DropdownSidebar>
      }
      {/************* side bar **************/}


    </div>
  )
}

export default BoxSelect
