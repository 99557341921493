import React, { Fragment } from 'react'
import { connect } from 'react-redux'

// Components
import ModalDefault from '../ModalDefault'
import { BtnModalClose, BtnModalOpen } from '../BtnModal'
import { BtnCircleClose } from '../../button/Button'

// Redux Actions
import { closeModal } from '../../../redux/App/AppActions'

// css
import css from './css/modal.css'

const Modal = (props) => {
  const width = props.width && parseInt(props.width)
  const widthPx = width && `${width}px`
  const widthVw = width && `${width/15}vw`

  const styleBox = {
    textAlign: (props.textAlign ? props.textAlign : 'left'),
    padding: props.padding ? props.padding : props.headerLeft ? '0' : '30px',
    background: props.background ? props.background : '#fff',
    margin: (props.center ? '0 auto' : '')
  }

  const modalClose = () => {
    setTimeout(() => {

      props.dispatch(closeModal(props.id))

      if (props.eventModalClose) {
        props.eventModalClose()
      }

      if (props.btnModalClose) {
        props.btnModalClose()
      }
    }, 0)
  }


  return (
    <ModalDefault
      id={props.id}
      eventModalClose={props.eventModalClose}
      width={props.width}
      uploadImages={props.uploadImages}
      fnStartModal={props.fnStartModal}
      fnCloseModal={props.fnStartModal}
      blockBgModal={props.blockBgModal}>

      <div className={`z-depth-1 ${css.box} box-modal-jsx`} style={styleBox}>
        { props.btnClose !== 'false' && !props.headerLeft &&
          <BtnCircleClose onClick={() => modalClose()} />
        }

        { props.headerTitle && !props.headerLeft &&
          <div className={css.header}>
            <b style={{ fontSize: '1.5rem'}}>{props.headerTitle}</b>
            <p>{props.headerDescription}</p>
          </div>
        }

        { props.headerTitle && props.headerLeft &&
          <div className={css.headerStyle} style={props.headerStyle}>
            <table>
              <tbody>
                <tr>
                  <td className="pd-10 pd-left-20 font-1-1 color-000">
                    {props.headerTitle}
                  </td>
                  <td className="pd-3 width-50">
                    <a onClick={() => modalClose()} className="btn btn-shadow-none pd-0 tect-center btn-white height-40 width-40 line-height-40 border-radius-circle border-efefef">
                      <i className="material-icons font-1-8 color-333">close</i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        }

        { props.paddingAuto ?
          <div className={css.contentPaddingAuto}>
            { props.children }
          </div>
        :
          <Fragment>{ props.children }</Fragment>
        }
      </div>

      <style jsx>{`
        .box-modal-jsx {
          max-width: ${widthPx};
        }
        @media (min-width: 1500px) {
          .box-modal-jsx {
            max-width: ${widthVw};
          }
        }
      `}</style>
    </ModalDefault>
  )
}

export default connect()(Modal)
