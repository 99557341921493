import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import TextEditorHeadingOrDescriptions from './TextEditorHeadingOrDescriptions'
import TextEditorHeadingOrDescriptionsDisplay from './TextEditorHeadingOrDescriptionsDisplay'

let TextEditorHeadingOrDescriptions = lazy(() => import('./TextEditorHeadingOrDescriptions'))
TextEditorHeadingOrDescriptions = suspenseComponent(TextEditorHeadingOrDescriptions)

export {
  TextEditorHeadingOrDescriptions,
  TextEditorHeadingOrDescriptionsDisplay
}
