import React, { useEffect, useState } from 'react'
import config from 'client/config'

// Functions
import { addStyle } from '../../../functions/textEditorFunctions'

// css
import css from '../css/styleSettings.css'


const ImageSizeSetting = (props) => {
  const [width, setWidth] = useState()
  const [height, setHeight] = useState()

  useEffect(() => {
    const { _this } = props
    const { style } = _this.state

    setWidth(style['image-size-width'])
    setHeight(style['image-size-height'])
  }, [])

  useEffect(() => {
    getSize()
  }, [
    props.paths && props.paths.o,
    props._this && props._this.state && props._this.state.style && props._this.state.style['image-type-size'],
  ])

  const getUrl = () => {
    const { _this } = props
    const { style, paths } = _this.state
    const typeSize = style['image-type-size'] || 'o'
    const s3Url = config.app.s3.url
    const url = `${s3Url}/${paths[typeSize]}`

    return url
  }

  const getSize = () => {
    const url = getUrl()

    if (url) {
      const img = new Image()
      img.src = url

      img.onload = () => {
        setSize({
          width: img.width,
          height: img.height
        })
      }
    }
  }

  const setSize = ({ width, height }) => {
    const { _this } = props

    setWidth(width)
    setHeight(height)

    addStyle(_this, {
      styleMultiple: [
        { style: 'image-size-width', value: width },
        { style: 'image-size-height', value: height },
      ]
    })
  }

  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td className="pd-0 font-0-7">ขนาดรูป (กว้าง x สูง)</td>
            <td className="pd-0 font-0-7 text-right">
              <a className={css.btn} onClick={getSize}>คำนวณ</a>
            </td>
          </tr>
        </tbody>
      </table>

      <div className='pd-top-5'>
        <table>
          <tbody>
            <tr>
              <td className="pd-0 font-0-8 pd-right-5">
                <div className={css.boxInput}>
                  <input
                    disabled
                    style={{ textAlign: 'left' }}
                    className={`${css.inputDisabled} ${css.inputBorderNone} ${css.inputFirst} color-000`}
                    type="text"
                    value={width || ''}
                  />
                </div>
              </td>
              <td className="pd-0 font-0-8 pd-left-5">
                <div className={css.boxInput}>
                  <input
                    disabled
                    style={{ textAlign: 'left' }}
                    className={`${css.inputDisabled} ${css.inputBorderNone} ${css.inputFirst} color-000`}
                    type="text"
                    value={height || ''}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ImageSizeSetting
