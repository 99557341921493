import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

// actions redux
import { removeLoad } from '../../redux/App/AppActions'


class BtnSubmit extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    if (this.props.loadingId)
      this.props.dispatch(removeLoad(this.props.loadingId))
  }

  render() {
    const iconAlign = (this.props.iconAlign ? this.props.iconAlign : 'left')
    const style = { width: '30px', float: 'left', paddingTop: (!this.props.size ? '8px' : '4px') }
    const joinStyle = Object.assign(style, this.props.style)

    let boxLoading, iconLoading, btnLoading

    if (this.props.loadingStyle) {
      boxLoading = this.props.loadingStyle
      iconLoading = { ...boxLoading, borderWidth: '2px' }
    } else {
      boxLoading = { width: '20px', height: '20px' }
      iconLoading = { ...boxLoading, borderWidth: '2px' }
    }

    if (this.props.responsive) {
      btnLoading = (
        <button
          className={`btn-load ${this.props.className}`}
          style={{ boxShadow: 'none', overflow: 'hidden', whiteSpace: 'nowrap', cursor: 'progress', opacity: '0.7'}}>

          <div className="center" style={{ whiteSpace: 'initial' }}>
            <i className={`la-ball-clip-rotate la-sm width-auto height-full mg-top-2 ${this.props.iconClassName}`}>
              <div style={iconLoading}></div>
            </i>

            <div>&nbsp;</div>
          </div>
        </button>
      )
    } else {
      btnLoading = (
        <div
          className={`${this.props.className}`}
          style={{boxShadow: 'none', overflow: 'hidden', cursor: 'progress', opacity: '0.7'}}>

          <div style={joinStyle}>
            <div className="la-ball-clip-rotate la-sm" style={boxLoading}>
              <div style={iconLoading}></div>
            </div>
          </div> {this.props.text}...
        </div>
      )
    }

    return(
      <div style={{ display: 'inline' }}>
        { (this.props.loadingId && this.props.load || this.props.loadTest) ?
          btnLoading
        :
          <button
            type="submit"
            style={this.props.style}
            onClick={this.props.onClick}
            data-disabled={true}
            data-hidden={this.props.btnHidden}
            className={`${this.props.className}`}>

            { this.props.iconLeft && this.props.iconRight ?
              <Fragment>
                { this.props.iconLeft ?
                  <i className={`material-icons ${this.props.iconLeftClassName} left`}>{this.props.iconLeft}</i>
                :
                  <i className={`${this.props.iconLeftFa} ${this.props.iconLeftClassName} left`}></i>
                }

                { this.props.text }

                { this.props.iconRight ?
                  <i className={`material-icons ${this.props.iconRightClassName} right`}>{this.props.iconRight}</i>
                :
                  <i className={`${this.props.iconRightFa} ${this.props.iconRightClassName} right`}></i>
                }
              </Fragment>
            :
              <Fragment>
                { this.props.icon ?
                  <i className={`material-icons ${this.props.iconClassName} ${this.props.iconAlign}`}>{this.props.icon}</i>
                :
                  <i className={`${this.props.iconFa} ${this.props.iconClassName} ${iconAlign}`}></i>
                }

                { this.props.text }
              </Fragment>
            }
          </button>
        }
      </div>
    )
  }
}

const mapStateToProps = (store, props) => {
  return {
    load: store.app.loads.filter(load => load === props.loadingId)[0]
  }
}

export default connect(mapStateToProps)(BtnSubmit)
