import React, { Fragment, useMemo } from 'react'
import { connect } from 'react-redux'
import LazyLoad from 'react-lazyload'

// Components local
import ItemListsApi from './components/ItemListsApi'

// Components Editor
import BlockStyle from '../components/BlockStyle'
import { getLazyLoadParams } from '../../../util/getLazyLoadParams'


const TextEditorItemListsApiDisplay = (props) => {
  const { content, mode } = props
  const lang = props.lang || 'local'
  const itemListsApi = content.itemListsApi && JSON.parse(content.itemListsApi)
  const lazyLoadParams = getLazyLoadParams(props)
  const style = itemListsApi && itemListsApi.style ? JSON.parse(itemListsApi.style) : {},
        className = itemListsApi && itemListsApi.class ? JSON.parse(itemListsApi.class) : [],
        amountColumns = itemListsApi && itemListsApi.amountColumns ? JSON.parse(itemListsApi.amountColumns) : {},
        fetchType = itemListsApi && itemListsApi.fetchType || '',
        orderBy = itemListsApi && itemListsApi.orderBy || '-_id',
        limitItems = itemListsApi && itemListsApi.limitItems || 4


  return (
    <Fragment>
      { useMemo(() =>
        <BlockStyle {...props} style={style} className={className}>
          <LazyLoad {...lazyLoadParams}>
            <ItemListsApi
              {...props}
              display
              lang={lang}
              style={style}
              content={content}
              className={className}
              mode={mode}
              fetchType={fetchType}
              orderBy={orderBy}
              limitItems={limitItems}
              amountColumns={amountColumns}
            />
          </LazyLoad>
        </BlockStyle>
      , [
        props.location && props.location.pathname,
        props.location && props.location.search
      ]) }
    </Fragment>
  )
}

export default connect()(TextEditorItemListsApiDisplay)
