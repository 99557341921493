import { lazy } from 'react'
import suspenseComponent from '../../../util/suspenseComponent'

//import TextEditorButtons from './TextEditorButtons'
import TextEditorButtonsDisplay from './TextEditorButtonsDisplay'

let TextEditorButtons = lazy(() => import('./TextEditorButtons'))
TextEditorButtons = suspenseComponent(TextEditorButtons)

export {
  TextEditorButtons,
  TextEditorButtonsDisplay
}
