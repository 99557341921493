import React from 'react'

// Components Local
import ImageStyle from './ImageStyle'

const ImageElementManage = (props) => {
  const { title, name, styleHover, styleActive, styleActiveClick } = props

  return (
    <div>
      <ImageStyle {...props} />

      { styleHover &&
        <ImageStyle {...props} event="hover" title={`${title}เมื่อเมาส์ชี้ (hover)`} name={`${name}-hover`} />
      }

      { styleActive &&
        <ImageStyle {...props} event="active" title={`${title}เมื่อแอคทีฟ (active)`} name={`${name}-active`} />
      }

      { styleActiveClick &&
        <ImageStyle {...props} event="active-click" title={`${title}เมื่อกด (click)`} name={`${name}-active-click`} />
      }
    </div>
  )
}

export default ImageElementManage
