import React, { Fragment } from 'react'

// Compoents Local
import { FooterDisplay } from '../../../../../Footer'
import { ContentGroupPageDisplay } from '../../../../../ContentGroupPage'


const PageFooter = (props) => {
  const {
    css,
    business,
    setting,
    site,
    pages,
    page,
    pageFooter,
    contentGroupPage,
    singlePageData,
    singlePageType,
    templateData
  } = props

  return (
    <Fragment>
      { pageFooter && pageFooter.footerShow &&
        <footer className={css.footer}>
          { contentGroupPage && contentGroupPage.footerMainShow === false ?
            <ContentGroupPageDisplay
              {...props}
              groupId={contentGroupPage._id + '-footer'}
              datas={{
                business,
                setting,
                site,
                pages,
                pageCurrent: page,
                singlePageData,
                singlePageType,
                templateData
              }}
            />
          :
            <FooterDisplay
              {...props}
              datas={{
                business,
                setting,
                site,
                pages,
                pageCurrent: page,
                singlePageData,
                singlePageType
              }}
            />
          }

        </footer>
      }
    </Fragment>
  )
}

export default PageFooter
