import React from 'react'

// Components Global
import Img from '../../../../../Img'
import NumberPicker from '../../../../../Inputs/NumberPicker'

// Components TextEditor
import Selected from '../../../../components/Selected'
import {
  TextElement,
  DivElement,
  BtnElement,
  BtnSubmitElement
} from '../../../../components/ElementStyles'

// Components Local
import StyleSettings from '../../StyleSettings'

// css
import css from './css/cart.css'


export const Label = (props) => {
  let { name, style, title, children } = props
  style = style || {}

  return (
    <Selected type="text" name={name} title={title} styleSettings={StyleSettings} {...props}>
      <TextElement name={name} style={style}>{children}</TextElement>
    </Selected>
  )
}

export const ButtonSubmit = (props) => {
  let { id, style, title, name, text, icon, className, disabled } = props
  style = style || {}

  return (
    <Selected type="btn" name={name} title={`ปุ่ม${text}`} styleSettings={StyleSettings} {...props}>
      <BtnSubmitElement
        id={id}
        disabled={disabled}
        name={name}
        className={className}
        text={text}
        icon={icon}
        style={style}
      />
    </Selected>
  )
}

export const BoxElement = (props) => {
  let { style, name, title, children } = props
  style = style || {}

  return (
    <Selected type="block" name={name} title={title} styleSettings={StyleSettings} {...props}>
      { children }
    </Selected>
  )
}

export const AmountNumberPicker = (props) => {
  let { style, min, max, value } = props
  style = style || {}

  return (
    <Selected type="number-picker" name="number-picker" title="อินพุดจำนวนสินค้า" styleSettings={StyleSettings} {...props}>
      <NumberPicker
        numberCenter
        notZero
        id="qty"
        value={value || min}
        min={min}
        max={max}
        step="1"
        nameJsx="number-picker"
        styleJsx={style}
      />
    </Selected>
  )
}


