import React, { Fragment } from 'react'

// Js
import { setStyle, setStyleResponsive } from '../../../components/ElementStyles/components/js/setDivStyle'


const Line = (props) => {
  const { style } = props

  return (
    <Fragment>
      <div className="box-line-jsx">
        <div className="line-jsx" style={{ display: 'inline-block' }} />
        <div style={{ clear: 'both'}} />
      </div>

      <style jsx>{`
        .box-line-jsx {
          text-align: ${style['line-text-align']};
        }
        .line-jsx {
          background-color: #ddd;
          width: 100%;
          height: 1px;

          ${setStyle('line', style)}
        }

        @media (min-width: 1500px) {
          .line-jsx {
            height: 0.0666vw;
            ${setStyleResponsive('line', style)}
          }
        }
      `}</style>

    </Fragment>
  )
}

export default Line
