import React, { Fragment, useMemo } from 'react'
import config from '../../../../../../config'

// Js
import { setStyleValue } from '../../../ElementStyles/components/js/setDivStyle'


const s3Url = config.app.s3.url
const s3UrlOriginal = config.app.s3.urlOriginal


const ImgDisplay = (props) => {
  const { paths, imageTypeSize } = props

  const getImgUrl = () => {
    const path = paths[`${imageTypeSize}`]
    const pathWebP = paths[`${imageTypeSize}_webp`]

    const src = path && `${s3Url}/${path}`
    const srcOrigin = path && `${s3UrlOriginal}/${path}`
    const srcWebP = pathWebP && `${s3Url}/${pathWebP}`

    return { src, srcOrigin, srcWebP }
  }

  const { src, srcWebP } = getImgUrl()
  let { width, height, className, style, styleJsx, logo } = props

  styleJsx = styleJsx || {}

  if (logo) {
    width = styleJsx[`image-width`] || 'auto'
    height = styleJsx[`image-height`] || '100%'
  } else {
    width = styleJsx[`image-width`] || '100%'
    height = styleJsx[`image-height`] || '100%'
  }

  const name = "image"

  const styleDefault = useMemo(() => setStyleValue({ name, style: styleJsx }), [styleJsx && JSON.stringify(styleJsx)])
  const styleDefaultVW = useMemo(() => setStyleValue({ name, style: styleJsx, vw: true }), [styleJsx && JSON.stringify(styleJsx)])
  const styleTablet = useMemo(() => setStyleValue({ device: 'tablet', name, style: styleJsx }), [styleJsx && JSON.stringify(styleJsx)])
  const styleMobile = useMemo(() => setStyleValue({ device: 'mobile', name, style: styleJsx }), [styleJsx && JSON.stringify(styleJsx)])

  const gallery = styleJsx['gallery-slideshow']


  const imageComp = (
    <picture>
      { srcWebP &&
        <source srcSet={`${srcWebP}`} type="image/webp" />
      }

      <source srcSet={`${src}`} type="image/jpeg" />

      <img
        width={width}
        height={height}
        style={style}
        className={`img-jsx ${className || ''}`}
        src={`${src}`}
      />
    </picture>
  )

  return (
    <Fragment>
      { gallery ?
        <a
          className="photoSwipeLightbox"
          href={src}
          data-pswp-width={styleJsx['image-size-width']}
          data-pswp-height={styleJsx['image-size-height']}
          target="_blank">

          { imageComp }
        </a>
      :
        imageComp
      }

      <style jsx>{`
        .img-jsx {
          ${styleDefault}

          width: ${width} !important;
          height: ${height} !important;
        }

        @media (min-width: 1500px) {
          .img-jsx {
            ${styleDefaultVW}
          }
        }

        @media (min-width: 601px) and (max-width: 1024px) {
          .img-jsx {
            ${styleTablet}
          }
        }

        @media (min-width: 50px) and (max-width: 600px) {
          .img-jsx {
            ${styleMobile}
          }
        }


        .img-jsx:hover {
          ${setStyleValue({ name: `${name}-hover`, style: styleJsx })}
        }
        .img-jsx:active {
          ${setStyleValue({ name: `${name}-active-click`, style: styleJsx })}
        }

        @media (min-width: 1500px) {
          .img-jsx {
            ${setStyleValue({ name, style: styleJsx, vw: true })}
          }
        }
      `}</style>
    </Fragment>
  )
}

export default React.memo(ImgDisplay)
