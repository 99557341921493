import React from 'react'

// Components Global
import Icon from '../../../../Icon'


const MenuIcon = (props) => {
  const { item, style } = props
  const { icon } = item

  let type = icon && icon.type
  let name = icon && icon.name

  if (!type || !name) {
    type = "material-icons"

    if (item.path === 'index') {
      name = "home"
    } else {
      name = "insert_drive_file"
    }
  }

  return (
    <Icon
      type={type}
      className="left"
      style={style}>

      {name}
    </Icon>
  )
}

export default MenuIcon
