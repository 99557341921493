import React, {  Component } from 'react'
import PropTypes from 'prop-types'

import { loaderSocial, loginFacebook, loginGoole } from './SignIn/js/socialSignIn'

class SocialSignIn extends Component {
  constructor(props){
    super(props)
    this.id =  'sl' + Math.floor(Math.random() * 0xFFFF) + ''
  }

  componentDidMount() {
    const id = this.id
    const { provider, appId, callback } = this.props

    loaderSocial({ id, provider, appId, callback })
  }

  login = () => {
    const { provider, appId, callback } = this.props
    loginFacebook({ provider, appId, callback })
  }

  signin = () => {
    const { provider, appId, callback } = this.props

    if (provider === 'Facebook') {
      loginFacebook({ provider, appId, callback })
    }

    if (provider === 'Google') {
      loginGoole({ provider, appId, callback })
    }
  }

  render(){
    /*return(
      <div id={this.id} onClick={this.props.provider === 'Facebook' ? this.login : undefined}>
        {this.props.children}
      </div>
    )*/

    return(
      <div id={this.id} onClick={this.signin} >
        {this.props.children}
      </div>
    )
  }
}

SocialSignIn.propTypes = {
  provider  : PropTypes.oneOf(['Google','Facebook']).isRequired,
  appId     : PropTypes.string.isRequired,
  children  : PropTypes.element.isRequired,
  callback  : PropTypes.func
}

export default SocialSignIn
